package com.hyperether.goodjob.repository.remote.model

import com.hyperether.goodjob.models.UserTeam
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SetPasswordResponse(
    @SerialName("_id") val id: String,
    @SerialName("fullName") val fullName: String? = null,
    @SerialName("email") val email: String? = null,
    @SerialName("phone") val phone: String? = null,
    @SerialName("zip") val zip: String? = null,
    @SerialName("country") val country: String? = null,
    @SerialName("city") val city: String? = null,
    @SerialName("address") val address: String? = null,
    @SerialName("status") val status: String? = null,
    @SerialName("workspaceId") val workspaceId: String? = null,
    @SerialName("skils") val skills: List<String>? = null,
    @SerialName("teams") val teams: List<UserTeam>? = null,
    @SerialName("upcomingLeave") val upcomingLeave: String? = null,
    @SerialName("confirmStatus") val confirmStatus: String? = null,
    @SerialName("role") val role: String? = null,
    @SerialName("hash") val hash: String? = null,
    @SerialName("type") val type: String? = null,
    @SerialName("createdAt") val createdAt: Long? = null,
    @SerialName("updatedAt") val updatedAt: Long? = null,
    @SerialName("password") val password: String? = null
)