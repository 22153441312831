package com.hyperether.goodjob.util

class FileUtil {

    fun getFileType(fileName: String): FileType {
        return when {
            fileName.endsWith(".jpeg", ignoreCase = true) ||
                    fileName.endsWith(".jpg", ignoreCase = true) ||
                    fileName.endsWith(".png", ignoreCase = true) -> FileType.Image

            fileName.endsWith(".pdf", ignoreCase = true) -> FileType.PDF
            else -> FileType.Unknown
        }
    }

    fun isImageFile(fileName: String): Boolean {
        return fileName.lowercase().endsWithAny(listOf("png", "jpg", "jpeg"))
    }

    fun String.endsWithAny(extensions: List<String>): Boolean {
        return extensions.any { this.endsWith(it, ignoreCase = true) }
    }

}