package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Site(
    @SerialName("_id") var id: String? = null,
    @SerialName("personName") var personName: String? = null,
    @SerialName("addressObject") var addressObject: AddressObject? = null,
    @SerialName("personPhone") var personPhone: String? = null,
    @SerialName("siteName") var siteName: String? = null,
    @SerialName("parentId") var parentId: String? = null,
    @SerialName("parentName") var parentName: String? = null,
    @SerialName("personEmail") var personEmail: String? = null
)