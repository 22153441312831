package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_contact
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.assign_employee
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.company
import com.hyperether.goodjob.resources.date
import com.hyperether.goodjob.resources.details
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.end_at
import com.hyperether.goodjob.resources.estimated_duration
import com.hyperether.goodjob.resources.files
import com.hyperether.goodjob.resources.full_name_contact_person
import com.hyperether.goodjob.resources.job_details
import com.hyperether.goodjob.resources.job_status
import com.hyperether.goodjob.resources.job_title
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.resources.start_at
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.step
import com.hyperether.goodjob.resources.steps
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.TextColor
import kotlinx.serialization.json.Json
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobDetails(jobId: String, viewModel: JobViewModel, navHostController: NavHostController) {

    var isAboutJobView = remember { mutableStateOf(true) }
    var selectedButtonIndex by remember { mutableStateOf(0) }
    var percentCompleted by remember { mutableStateOf(0) }

    val jobs by viewModel.jobs.collectAsState(emptyList())
    val job = jobs.find { it.id == jobId.toLong() }
    val steps = job?.steps?.let { Mapper().jsonToListSteps(it) } ?: emptyList()


    println(job.toString())


    Box(modifier = Modifier.fillMaxSize()) {

        Column {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 20.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.back_arrow),
                    contentDescription = "",
                    modifier = Modifier
                        .clickable {
                            navHostController.popBackStack()
                        }
                )

                Text(
                    text = stringResource(Res.string.job_details),
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier
                        .weight(1f)
                        .padding(start = 16.dp)
                )

                Row(
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .wrapContentWidth()
                        .padding(start = 8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.edit),
                        contentDescription = "",
                        modifier = Modifier
                            .padding()
                            .clickable {
                            }
                    )
                }
            }
            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
            Column(
                modifier = Modifier
                    .background(Color.LightGray.copy(alpha = 0.2f))
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(40.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.about_job),
                        color = if (isAboutJobView.value) BlueApp else Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(start = 40.dp)
                            .clickable {
                                isAboutJobView.value = true
                            }
                    )

                    Spacer(modifier = Modifier.weight(0.5f))

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.about_contact),
                        color = if (isAboutJobView.value) Color.Gray else BlueApp,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(end = 40.dp)
                            .clickable {
                                isAboutJobView.value = false
                            }
                    )
                }
            }
            Box(
                modifier = Modifier
                    .height(2.dp)
                    .fillMaxWidth(0.5f)
                    .background(if (isAboutJobView.value) BlueApp else BlueApp)
                    .align(if (isAboutJobView.value) Alignment.Start else Alignment.End)
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(start = 11.dp, end = 11.dp, top = 10.dp),
                horizontalArrangement = Arrangement.SpaceEvenly,
                verticalAlignment = Alignment.CenterVertically
            ) {
                val buttons = listOf(
                    stringResource(Res.string.details),
                    stringResource(Res.string.steps),
                    stringResource(Res.string.files)
                )

                buttons.forEachIndexed { index, label ->
                    Box(
                        modifier = Modifier
                            .weight(1f)
                            .padding(5.dp)
                            .border(1.dp, Color.LightGray, RoundedCornerShape(20))
                            .background(
                                color = if (selectedButtonIndex == index) BlueApp else Color.Transparent,
                                shape = RoundedCornerShape(20)
                            )
                            .clickable { selectedButtonIndex = index },
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            text = label,
                            color = if (selectedButtonIndex == index) Color.White else Color.LightGray,
                            modifier = Modifier.padding(vertical = 12.dp)
                        )
                    }
                }
            }
            if (selectedButtonIndex == 0) {
                job?.jobTitle?.let { DetailsTextItem(stringResource(Res.string.job_title), it) }
                job?.status?.let { DetailsTextItem(stringResource(Res.string.job_status), it) }
                job?.employees?.let {
                    DetailsTextItem(
                        stringResource(Res.string.assign_employee),
                        it
                    )
                }
                job?.dueDate?.let { DetailsTextItem(stringResource(Res.string.date), it) }
                Row {
                    job?.start_at?.let { DetailsTextItem(stringResource(Res.string.start_at), it) }
                    Spacer(modifier = Modifier.width(10.dp))
                    job?.end_at?.let { DetailsTextItem(stringResource(Res.string.end_at), it) }
                }
                //TODO replace this with site,company,contact person,phone, email address when update job table
                job?.contactSiteName?.let { DetailsTextItem(stringResource(Res.string.site), it) }
                job?.contactSiteName?.let { DetailsTextItem(stringResource(Res.string.company), it) }
                job?.contactSiteName?.let {
                    DetailsTextItem(
                        stringResource(Res.string.full_name_contact_person),
                        it
                    )
                }
                job?.contactSiteName?.let { DetailsTextItem(stringResource(Res.string.phone), it) }
                job?.contactSiteName?.let { DetailsTextItem(stringResource(Res.string.email_address), it) }
            } else if (selectedButtonIndex == 1) {
                Column {
                    Spacer(modifier = Modifier.height(15.dp))
                    Text(
                        text = "${percentCompleted}% completed",
                        color = Green,
                        fontWeight = FontWeight.Bold,
                        fontSize = 16.sp,
                        modifier = Modifier.padding(start = 16.dp)

                    )
                    Spacer(modifier = Modifier.height(15.dp))

                    LazyColumn(modifier = Modifier.fillMaxSize()) {
                        itemsIndexed(steps) { index, step ->
                            val stepCounter = remember { mutableStateOf(index + 1) }
                            StepsItem(step = step, number = stepCounter)
                        }
                    }
                }
            }

        }
    }
}


@Composable
fun DetailsTextItem(header: String, detailsText: String) {
    Column {
        Text(
            text = header,
            color = Color.LightGray,
            fontWeight = FontWeight.Bold,
            fontSize = 14.sp,
            modifier = Modifier.padding(start = 16.dp, end = 16.dp)

        )
        Text(
            text = detailsText,
            color = TextColor,
            fontWeight = FontWeight.Normal,
            fontSize = 14.sp,
            modifier = Modifier.padding(start = 16.dp, end = 16.dp)
        )
        Spacer(modifier = Modifier.height(12.dp))
    }
}

@Composable
fun StepsItem(step: Step, number: MutableState<Int>) {
    Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
        step.stepTitle?.let {
            Row {
                NumberCircle(
                    number = number.value,
                    onNumberChange = { newNumber ->
                        number.value = newNumber
                    }
                )
                Spacer(modifier = Modifier.width(15.dp))
                Text(
                    text = it,
                    color = Color.DarkGray,
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,

                    )
            }
        }
        step.stepDescription?.let {
            Text(
                text = it,
                color = TextColor,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp,

                )
        }
        Row {
            Text(
                text = "${stringResource(Res.string.estimated_duration)}:",
                color = Color.LightGray,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp
            )
            Spacer(modifier = Modifier.width(5.dp))
            step.estimatedDuration?.let {
                Text(
                    text = it.toString(),
                    color = TextColor,
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp,

                    )
            }
        }
        Row {
            Text(
                text = "${stringResource(Res.string.status)}:",
                color = Color.LightGray,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp
            )
            Spacer(modifier = Modifier.width(5.dp))
            //step.stepStatus?.let {
                Text(
                    text = "Completed",
                    color = Green,
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp,

                    )
           // }
        }
        Spacer(modifier = Modifier.height(15.dp))

    }
}

@Composable
fun NumberCircle(
    number: Int,
    onNumberChange: (Int) -> Unit
) {
    var currentNumber by remember { mutableStateOf(number) }

    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .size(25.dp)
            .background(color = Green, shape = CircleShape)
            .clickable {
                currentNumber += 1
                onNumberChange(currentNumber)
            }
    ) {
        Text(
            text = currentNumber.toString().padStart(2, '0'),
            color = Color.White,
            fontSize = 12.sp,
            fontWeight = FontWeight.Bold
        )
    }
}