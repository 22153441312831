package com.hyperether.goodjob.repository.remote

import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.EmployeeRequest
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobRequest
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.SiteRequest
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.remote.model.AddPlanRequest
import com.hyperether.goodjob.repository.remote.model.AddPlanResponse
import com.hyperether.goodjob.repository.remote.model.ConfirmationRequest
import com.hyperether.goodjob.repository.remote.model.ConfirmationResponse
import com.hyperether.goodjob.repository.remote.model.LoginRequest
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import com.hyperether.goodjob.repository.remote.model.RegisterRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.WorkspaceRequest
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import org.lighthousegames.logging.logging

class GoodJobApiImpl() : GoodJobApi {

    var baseURL: String = ApiConfig.baseURL
    val log = logging("GoodJobApiImpl")

    override suspend fun register(
        name: String,
        email: String,
        password: String
    ): Resource<User> {


        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/register") {
                    contentType(ContentType.Application.Json)
                    setBody(RegisterRequest(name, email, password))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to register: ${response.status}"
                println(response.bodyAsText())
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun login(email: String, password: String): Resource<LoginResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/login") {
                    contentType(ContentType.Application.Json)
                    setBody(LoginRequest(email, password))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: LoginResponse = response.body()
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to login: ${response.status}"
                println(response.bodyAsText())
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun confirmation(
        hash: String,
        email: String,
        password: String,
        onSuccess: () -> Unit,
        onError: () -> Unit
    ): Resource<ConfirmationResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/confirmation") {
                    contentType(ContentType.Application.Json)
                    setBody(ConfirmationRequest(hash))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: ConfirmationResponse = response.body()
                when (val loginResult = login(email, password)) {
                    is Resource.Success -> {
                        onSuccess()
                        Resource.Success(apiResponse)
                    }

                    is Resource.Error -> {
                        onError()
                        Resource.Error("Login failed after confirmation: ${loginResult.data}")
                    }
                }
            } else {
                onError()
                val errorMessage = "Failed to confirm email: ${response.status}"
                println(response.bodyAsText())
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun addWorkspace(
        name: String,
        workspaceType: String,
        token: String
    ): Resource<Workspace> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/workspace") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                        setBody(WorkspaceRequest(name, workspaceType))
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Workspace = response.body()
                log.d("add workspace") { "Add workspace SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to add workspace: ${response.status}"
                log.d("add workspace") { "Failed to add workspace: ${(response.bodyAsText())}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("add workspace") { "Failed to add workspace: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun getAllWorkspaces(token: String): Resource<List<Workspace>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/workspace/user-workspaces") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: List<Workspace> = response.body()
                log.d("getAllWorkspaces") { "getAllWorkspaces SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed getAllWorkspaces: ${response.status}"
                log.d("getAllWorkspaces") { "Failed to getAllWorkspaces: ${(response.bodyAsText())}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getAllWorkspaces") { "Failed to add workspace: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun getAllPlans(token: String): Resource<List<Plan>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/plan/all-plans") {
                    headers {
                        contentType(ContentType.Application.Json)
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: List<Plan> = response.body()
                log.d("getAllPlans") { "getAllPlans SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed getAllWorkspaces: ${response.status}"
                log.d("getAllPlans") { "Failed to getAllPlans: ${(response.bodyAsText())}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getAllPlans") { "Failed to getAllPlans: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun addPlan(token: String, planId: String): Resource<AddPlanResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/add-plan") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                        setBody(AddPlanRequest(planId))
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: AddPlanResponse = response.body()
                log.d("addPlan") { "addPlan SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed getAllWorkspaces: ${response.status}"
                log.d("addPlan") { "Failed to addPlan: ${(response.bodyAsText())}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("addPlan") { "Failed to addPlan: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun addEmployee(
        token: String,
        addEmployeeRequest: EmployeeRequest
    ): Resource<User> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/add-employee") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(addEmployeeRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                log.d("add employee") { "Add employee SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to add employee: ${response.status}"
                log.d("add employee") { "Failed to add employee: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("add employee") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun addContact(token: String, customer: Contact): Resource<Contact> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/contact") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(customer)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Contact = response.body()
                log.d("Add contact") { "Add contact SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to add contact: ${response.status}"
                log.d("Add contact") { "Failed to add contact: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Add contact") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllContacts(token: String): Resource<List<Contact>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/contact") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val contacts = Json.decodeFromString<List<Contact>>(response.bodyAsText())
                log.d("Get all contacts") { "Get all contacts: ${response.bodyAsText()}" }
                Resource.Success(contacts)
            } else {
                val errorMessage = "Failed to get contacts: ${response.status}"
                log.d("Get all contacts") { "Failed to get contacts: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get all contacts") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getSitesUnderContacts(
        token: String,
        siteRequest: SiteRequest
    ): Resource<List<Site>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/contact/list-sites") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(siteRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val contacts = Json.decodeFromString<List<Site>>(response.bodyAsText())
                log.d("Get sites under contacts") { response.bodyAsText() }
                Resource.Success(contacts)
            } else {
                val errorMessage = "Failed to get contacts: ${response.status}"
                log.d("Get sites under contacts") { "Failed to get contacts: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get sites under contacts") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun createJob(token: String, job: JobRequest): Resource<Job> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/job") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(job)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Job = response.body()
                log.d("Add job") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to add job: ${response.status}"
                log.d("Add job") { "FAILED: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Add job") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllEmployees(token: String): Resource<List<User>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/user") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            val json = Json {
                ignoreUnknownKeys = true
            }

            if (response.status == HttpStatusCode.OK) {
                val users = json.decodeFromString<List<User>>(response.bodyAsText())
                log.d("Get all employees") { "Success: ${response.bodyAsText()}" }
                Resource.Success(users)
            } else {
                val errorMessage = "Failed to get employees: ${response.status}"
                log.d("Get all employees") { "Failed: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get all employees") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllJobsForSpecificWorkspace(
        token: String,
        workspaceId: String
    ): Resource<List<Job>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/job/all-workspace-jobs/$workspaceId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            val responseBody = response.bodyAsText().trim()
            log.d("Response Body") { responseBody }
            val json = Json {
                ignoreUnknownKeys = true
            }

            if (responseBody.isEmpty()) {
                return Resource.Success(emptyList())
            }

            if (response.status == HttpStatusCode.OK) {
                val jobs = json.decodeFromString<List<Job>>(response.bodyAsText())
                log.d("Get all jobs") { "Success: ${response.bodyAsText()}" }
                Resource.Success(jobs)
            } else {
                val errorMessage = "Failed to get employees: ${response.status}"
                log.d("Get all jobs") { "Failed: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.e() { "Exception occurred: ${e::class.simpleName} - ${e.message}" }
            e.printStackTrace()
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun deleteJobById(token: String, id: String): Resource<String> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.delete("${baseURL}/api/v1/job/$id") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: String = response.body()
                log.d("Delete job") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to add job: ${response.status}"
                log.d("Delete job") { "FAILED: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Delete job") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun deleteEmployee(token: String, employeeId: String): Resource<String> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.delete("${baseURL}/api/v1/user/$employeeId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: String = response.body()
                log.d("Delete employee") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to delete employee: ${response.status}"
                log.d("Delete employee") { "FAILED: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Delete employee") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getEmployeeById(token: String, userId: String): Resource<User> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/user/$userId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                log.d("getEmployeeById") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to get employee: ${response.status}"
                log.d("getEmployeeById") { "FAILED: ${response.bodyAsText()}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getEmployeeById") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }
}