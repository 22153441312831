package com.hyperether.goodjob.mapper

import androidx.compose.runtime.mutableStateOf
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.database.Site
import com.hyperether.goodjob.database.Team
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.database.Workspace
import com.hyperether.goodjob.models.AddressObject
import com.hyperether.goodjob.models.Assignees
import com.hyperether.goodjob.models.AssigneesRequest
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Skills
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.models.UserTeam
import com.hyperether.goodjob.repository.remote.model.ConfirmationResponse
import com.hyperether.goodjob.repository.remote.model.FileObject
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.repository.remote.model.UpdateWorkspaceRequest
import com.hyperether.goodjob.repository.remote.model.WorkingHours
import com.hyperether.goodjob.scenes.addNew.StepData
import com.hyperether.goodjob.util.Constants
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.serializer

class Mapper {

    fun toWorkspace(workspace: Workspace): com.hyperether.goodjob.models.Workspace {
        return com.hyperether.goodjob.models.Workspace(
            _id = workspace._id,
            id = workspace.id,
            name = workspace.name,
            planId = workspace.plan,
            type = workspace.type,
            userId = workspace.user_id,
            lastAccessed = workspace.lastAccessed
        )
    }

    fun toUpdateWorkspaceRequest(workspace: com.hyperether.goodjob.models.Workspace): UpdateWorkspaceRequest {
        return UpdateWorkspaceRequest(
            name = workspace.name,
            planId = workspace.planId,
            type = workspace.type,
            userId = workspace.userId,
            lastAccessed = workspace.lastAccessed
        )
    }

    fun toWorkspaceEntity(workspace: com.hyperether.goodjob.models.Workspace): Workspace {
        return Workspace(
            _id = workspace._id,
            id = workspace.id ?: 0,
            name = workspace.name,
            plan = workspace.planId,
            type = workspace.type,
            user_id = workspace.userId,
            workspaceType = workspace.workspaceType,
            lastAccessed = workspace.lastAccessed
        )
    }

    fun toJob(job: com.hyperether.goodjob.database.Job): com.hyperether.goodjob.models.Job {
        return com.hyperether.goodjob.models.Job(
            id = job._id,
            jobTitle = job.jobTitle,
            status = job.status,
            assignees = job.assignees?.let { Mapper().jsonAsObject<Assignees>(it) },
            creatorId = job.creatorId,
            workspaceId = job.workspaceId,
            start_at = job.start_at,
            end_at = job.end_at,
            repeat = job.repeat,
            dueDate = job.dueDate,
            startDate = job.startDate,
            jobDuration = job.jobDuration,
            steps = job.steps?.let { Mapper().jsonAsList<Step>(it) } ?: emptyList(),
            userId = job.userId,
            contactSiteId = job.contactId,
            contactSiteName = job.contactSiteName,
            contactParentId = job.contactParentId,
            noteObjects = job.notes?.let { Mapper().jsonToListNotes(it) },
            fileId = job.fileId,
            files = job.files?.let { Mapper().jsonAsList<String>(it) },
            fileObjects = job.fileObjects?.let { Mapper().jsonAsList<FileObject>(it) },
            completionReport = job.completionReport
        )
    }

    fun toSite(site: Site): com.hyperether.goodjob.models.Site {
        return com.hyperether.goodjob.models.Site(
            id = site._id,
            personName = site.personName,
            addressObject = Mapper().jsonAsObject(site.personAddress),
            personPhone = site.personPhone,
            personEmail = site.personEmail,
            siteName = site.siteName,
            parentId = site.parentId,
            parentName = site.parentName
        )
    }

//    fun toJob(job: com.hyperether.goodjob.database.Job): com.hyperether.goodjob.models.Job {
//        return com.hyperether.goodjob.models.Job(
//            id = job._id,
//            jobTitle = job.jobTitle,
//            status = job.status,
//            employees = job.employees?.let { Mapper().jsonAsList<com.hyperether.goodjob.models.User>(it) } ?: emptyList(),
//            creatorId = job.creatorId,
//            workspaceId = job.workspaceId,
//            start_at = job.start_at,
//            end_at = job.end_at,
//            repeat = job.repeat,
//            dueDate = job.dueDate,
//            startDate = job.startDate,
//            jobDuration = job.jobDuration,
//            steps = job.steps?.let { Mapper().jsonAsList<com.hyperether.goodjob.models.Step>(it) } ?: emptyList(),
//            userId = job.userId,
//            contactId = job.contactId,
//            contactSiteName = job.contactSiteName,
//            contactParentId = job.contactParentId,
//            notes = job.notes,
//            fileId = job.fileId,
//            files = job.files?.let { Mapper().jsonAsList<String>(it) }
//        )
//    }

    fun toJobUpdateRequest(job: com.hyperether.goodjob.database.Job): JobRequest {
        val assignees = job?.assignees?.let { Mapper().jsonAsObject<Assignees>(it) }
        val assigneesRequest = AssigneesRequest(
            employees = assignees?.employees?.map { it?._id }?.distinct(),
            teams = assignees?.teams?.map { it?._id }?.distinct(),
        )
        return JobRequest(
            jobTitle = job.jobTitle,
            status = job.status,
            assignees = assigneesRequest,
            creatorId = job.creatorId,
            workspaceId = job.workspaceId,
            start_at = job.start_at,
            end_at = job.end_at,
            repeat = job.repeat,
            dueDate = job.dueDate,
            startDate = job.startDate,
            jobDuration = job.jobDuration,
            steps = job.steps?.let { Mapper().jsonAsList<Step>(it) } ?: emptyList(),
            userId = job.userId,
            contactSiteId = job.contactId,
            contactSiteName = job.contactSiteName,
            contactParentId = job.contactParentId,
            fileId = job.fileId,
            files = job.files?.let { Mapper().jsonAsList<String>(it) },
            noteObjects = job.notes?.let { Mapper().jsonToListNotes(it) }
        )
    }

    fun toJobUpdateRequest(job: Job): JobRequest {
        val assignees = job.assignees
        val assigneesRequest = AssigneesRequest(
            employees = assignees?.employees?.map { it?._id }?.distinct(),
            teams = assignees?.teams?.map { it?._id }?.distinct(),
        )
        return JobRequest(
            jobTitle = job.jobTitle,
            status = job.status,
            assignees = assigneesRequest,
            creatorId = job.creatorId,
            workspaceId = job.workspaceId,
            start_at = job.start_at,
            end_at = job.end_at,
            repeat = job.repeat,
            dueDate = job.dueDate,
            startDate = job.startDate,
            jobDuration = job.jobDuration,
            steps = job.steps,
            userId = job.userId,
            contactSiteId = job.contactSiteId,
            contactSiteName = job.contactSiteName,
            contactParentId = job.contactParentId,
            fileId = job.fileId,
            files = job.files,
            noteObjects = job.noteObjects
        )
    }

//    fun toContactModel(contact: Contact): com.hyperether.goodjob.models.Contact {
//        return com.hyperether.goodjob.models.Contact(
//            id = contact._id,
//            name = contact.name,
//            contactType = contact.contactType,
//            type = contact.type,
//            industry = contact.industry,
//            address = contact.address,
//            email = contact.email,
//            city = contact.city,
//            zip = contact.zip,
//            country = contact.country,
//            phone = contact.phone,
//            personName = contact.personName,
//            personEmail = contact.personEmail,
//            personAddress = contact.personAddress,
//            personPhone = contact.personPhone
//        )
//    }

//    fun toUser(user: User): com.hyperether.goodjob.models.User {
//        return com.hyperether.goodjob.models.User(
//            id = user.id,
//            fullName = user.fullName,
//            email = user.email,
//            password = user.password,
//            role = user.role,
//            workspace_ids = user.workspace_ids?.let { workspaceIdsAsList(it) }
//        )
//    }

    fun toUser(dbUser: User): com.hyperether.goodjob.models.User {
        val jsonParser = Json { ignoreUnknownKeys = true }

        return com.hyperether.goodjob.models.User(
            _id = dbUser._id,
            fullName = dbUser.fullName,
            email = dbUser.email,
            password = dbUser.password,
            confirmStatus = dbUser.confirmStatus,
            role = dbUser.role,
            hash = null,
            type = null,
            workspaceId = dbUser.workspace_ids,
            planId = dbUser.planId,
            phone = dbUser.phone,
            zip = dbUser.zip,
            country = dbUser.contry,
            city = dbUser.city,
            address = dbUser.address,
            status = dbUser.status,
            skills = dbUser.skills?.let {
                try {
                    jsonParser.decodeFromString<List<String>>(it)
                } catch (e: Exception) {
                    emptyList()
                }
            },
            teams = dbUser.teams?.let {
                try {
                    jsonParser.decodeFromString<List<UserTeam>>(it)
                } catch (e: Exception) {
                    emptyList()
                }
            },
            upcomingLeave = dbUser.upcomingLeave,
            workingHours = dbUser.workingHours?.let {
                try {
                    jsonParser.decodeFromString<WorkingHours>(it)
                } catch (e: Exception) {
                    null
                }
            }
        )
    }

    fun toDbUser(user: com.hyperether.goodjob.models.User): com.hyperether.goodjob.database.User {
        val jsonParser = Json { ignoreUnknownKeys = true }

        return com.hyperether.goodjob.database.User(
            id = 0,
            _id = user._id,
            customerId = user.customerId,
            upcomingLeave = user.upcomingLeave,
            workingHours = jsonParser.encodeToString(user.workingHours),
            fullName = user.fullName,
            email = user.email,
            password = user.password,
            confirmStatus = user.confirmStatus,
            role = user.role,
            workspace_ids = user.workspaceId,
            planId = user.planId,
            phone = user.phone,
            zip = user.zip,
            contry = user.country,
            city = user.city,
            address = user.address,
            status = user.status,
            skills = listAsJson(user.skills),
            teams = user.teams?.let {
                try {
                    jsonParser.encodeToString(it)
                } catch (e: Exception) {
                    ""
                }
            }
        )
    }

    fun toTeam(teamDb: Team): com.hyperether.goodjob.models.Team {
        return com.hyperether.goodjob.models.Team(
            _id = teamDb._id,
            name = teamDb.name,
            workspaceId = teamDb.workspaceId,
            employees = teamDb.employees?.let {
                Mapper().jsonAsList<com.hyperether.goodjob.models.User>(
                    it
                )
            } ?: emptyList(),
            type = teamDb.type,
            createdAt = teamDb.createdAt?.toLongOrNull(),
            updatedAt = teamDb.updatedAt?.toLongOrNull()
        )
    }

    fun toContact(dbContact: Contact): com.hyperether.goodjob.models.Contact {
        return com.hyperether.goodjob.models.Contact(
            id = dbContact._id,
            name = dbContact.name,
            contactType = dbContact.contactType,
            industry = dbContact.industry,
            addressObject = Mapper().jsonAsObject<AddressObject>(dbContact.address),
            email = dbContact.email,
            phone = dbContact.phone,
            personName = dbContact.personName,
            personEmail = dbContact.personEmail,
            personAddress = dbContact.personAddress,
            personPhone = dbContact.personPhone,
            workspaceId = dbContact.workspaceId,
        )
    }

//    fun loginResponseToUser(loginResponse: LoginResponse): com.hyperether.goodjob.models.User {
//        return com.hyperether.goodjob.models.User(
//            _id = loginResponse._id,
//            fullName = loginResponse.fullName,
//            email = loginResponse.email,
//            confirmStatus = loginResponse.confirmStatus,
//            role = loginResponse.role,
//            hash = loginResponse.hash,
//            type = loginResponse.type,
//            customerId = loginResponse.customerId,
//            planId = loginResponse.planId
//        )
//    }

    fun toPlan(plan: com.hyperether.goodjob.database.Plan): Plan {
        return Plan(
            id = plan.id,
            _id = plan._id,
            name = plan.name,
            price = plan.price,
            description = plan.description,
            storage = plan.storage,
            planSupport = plan.planSupport,
            limitManagers = plan.limitManagers,
            limitUsers = plan.limitUsers,
            currency = plan.currency,
            type = plan.type,
            interval = plan.interval,
            stripePriceId = plan.stripePriceId,
            stripeProductId = plan.stripeProductId,
            isPopular = if (plan.isPopular == 1L) true else false
        )
    }

    fun workspaceIdsAsJson(workspace_ids: List<String>?): String? = workspace_ids?.let {
        Json.encodeToString(ListSerializer(String.serializer()), it)
    }

    fun workspaceIdsAsList(workspace_ids: String): List<String> {
        return Json.decodeFromString<List<String>>(workspace_ids)
    }


    inline fun <reified T> listAsJson(list: List<T>?): String? = list?.let {
        Json.encodeToString(ListSerializer(serializer()), it)
    }

    inline fun <reified T> jsonAsList(json: String): List<T> {
        return Json.decodeFromString(ListSerializer(serializer()), json)
    }

    fun listAsJsonSteps(steps: List<Step?>): String {
        return Json.encodeToString(steps)
    }

    fun jsonToListSteps(jsonString: String): List<Step> {
        return Json.decodeFromString(jsonString)
    }

    fun listAsJsonEmployees(users: List<com.hyperether.goodjob.models.User?>): String {
        return Json.encodeToString(users)
    }

    fun listAsJsonNotes(list: List<Note>?): String {
        return Json.encodeToString(list)
    }

    fun jsonToListEmployees(jsonString: String): List<com.hyperether.goodjob.models.User> {
        return Json.decodeFromString(jsonString)
    }

    fun jsonToListNotes(jsonString: String): List<com.hyperether.goodjob.models.Note> {
        return Json.decodeFromString(jsonString)
    }


    fun extractValues(json: String): List<String> {
        return try {
            val teams: List<UserTeam> = Json.decodeFromString(json)
            teams.mapNotNull { it.value }
        } catch (e: Exception) {
            emptyList()
        }
    }

    fun confirmationResponseToUser(confirmationResponse: ConfirmationResponse): com.hyperether.goodjob.models.User {
        return com.hyperether.goodjob.models.User(
            _id = confirmationResponse.data?.id,
            fullName = confirmationResponse.data?.fullName,
            email = confirmationResponse.data?.email,
            confirmStatus = confirmationResponse.data?.confirmStatus,
            role = confirmationResponse.data?.role ?: Role.admin.name,
            hash = confirmationResponse.data?.hash,
            type = confirmationResponse.data?.type,
            customerId = confirmationResponse.data?.customerId
        )
    }

    fun getTeamsFromString(jsonString: String): List<UserTeam> {
        val json = Json { ignoreUnknownKeys = true }

        return try {
            json.decodeFromString<List<UserTeam>>(jsonString)
        } catch (e: Exception) {
            emptyList()
        }
    }

    fun toSkill(skills: com.hyperether.goodjob.database.Skills): Skills {
        return Skills(
            id = skills.id,
            _id = skills._id,
            name = skills.name,
            workspaceId = skills.workspaceId
        )
    }

    fun toSkill(skills: Skills): com.hyperether.goodjob.database.Skills {
        return com.hyperether.goodjob.database.Skills(
            _id = skills._id,
            id = skills.id ?: 0,
            name = skills.name,
            workspaceId = skills.workspaceId
        )
    }

    inline fun <reified T> objectAsJson(obj: T?): String? = obj?.let {
        Json.encodeToString(it)
    }

    inline fun <reified T> jsonAsObject(json: String?): T? = json?.let {
        Json.decodeFromString(it)
    }

    inline fun <reified T> jsonAsAddressObject(json: String?): T? = json?.let {
        try {
            Json.decodeFromString<T>(it)
        } catch (e: Exception) {
            println("Error decoding JSON: ${e.message}")
            null
        }
    }

    fun stepToStepData(step: Step): StepData {
        return StepData(
            title = mutableStateOf(step.stepTitle ?: ""),
            description = mutableStateOf(step.stepDescription ?: ""),
            estimation = mutableStateOf(step.estimatedDuration?.toString() ?: ""),
            stepStatus = mutableStateOf(step.stepStatus ?: Constants.STEP_STATUS_PENDING)
        )
    }

    fun toStepDataList(list: List<Step>): List<StepData> {
        return list.map { step -> stepToStepData(step) }
    }

    fun toJobUpdateStatusRequest(job: Job, status: String): JobRequest {
        val assignees = job.assignees
        val assigneesRequest = AssigneesRequest(
            employees = assignees?.employees?.map { it?._id }?.distinct(),
            teams = assignees?.teams?.map { it?._id }?.distinct(),
        )
        return JobRequest(
            jobTitle = job.jobTitle,
            status = status,
            assignees = assigneesRequest,
            creatorId = job.creatorId,
            workspaceId = job.workspaceId,
            start_at = job.start_at,
            end_at = job.end_at,
            repeat = job.repeat,
            dueDate = job.dueDate,
            startDate = job.startDate,
            jobDuration = job.jobDuration,
            steps = job.steps,
            userId = job.userId,
            contactSiteId = job.contactSiteId,
            contactSiteName = job.contactSiteName,
            contactParentId = job.contactParentId,
            fileId = job.fileId,
            files = job.files,
            noteObjects = job.noteObjects
        )
    }
}