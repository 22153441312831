package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.stringResource

@Composable
fun EditJobsDialog(
    showDialog: Boolean, jobs: List<com.hyperether.goodjob.database.Job>,
    onDelete: () -> Unit,
    onExportList: () -> Unit,
    onImportList: () -> Unit,
    onEdit: () -> Unit
) {
    if (showDialog) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp))
            //.shadow(1.dp, RoundedCornerShape(8.dp))
        ) {
            Column() {
                Text(
                    text = "${jobs.size} selected",
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    Text(
                        text = stringResource(Res.string.delete),
                        fontSize = 14.sp,
                        color = Color.Red,
                        modifier = Modifier.clickable {
                            onDelete()
                        })
                    Text(
                        text = stringResource(Res.string.export_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onExportList()
                        })
                    Text(
                        text = stringResource(Res.string.import_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onImportList()
                        })
                    Text(text = stringResource(Res.string.edit), fontSize = 14.sp, color = BlueApp,
                        modifier = Modifier.clickable {
                            onEdit()
                        })
                }
            }
        }
    }
}