package com.hyperether.planner.model

import kotlinx.datetime.LocalDateTime

data class Event(
    val jobId: String?,
    val status: EventStatus,
    val from: LocalDateTime,
    val to: LocalDateTime?,
    val title: String? = null
)