package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Note(
    @SerialName("_id") var _id: String? = null,
    @SerialName("noteText") var noteText: String? = null,
    @SerialName("createdBy") var createdBy: String? = null,
    @SerialName("profileImageFileId") var profileImageFileId: String? = null,
    @SerialName("userId") var userId: String? = null,
    @SerialName("createdAt") var createdAt: String? = null,
)