package com.hyperether.goodjob.scenes.map

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.map.MapComponent
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.model.LatLng
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.job_status_completed
import com.hyperether.goodjob.resources.job_status_on_hold
import com.hyperether.goodjob.resources.job_status_in_progress
import com.hyperether.goodjob.resources.job_status_open
import com.hyperether.goodjob.resources.places
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAction
import com.hyperether.goodjob.scenes.components.SearchField
import com.hyperether.goodjob.scenes.dashboard.map.PlacesJobCard
import com.hyperether.goodjob.scenes.jobs.components.FilterJobsDialog
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.JobStatusMap
import org.jetbrains.compose.resources.stringResource

@Composable
fun MapScreen(
    viewModel: MapViewModel,
    navHostController: NavHostController,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {
    viewModel.jobStatusMap = JobStatusMap()

    val isInSearchMode = remember { mutableStateOf(false) }
    val searchQuery = remember { mutableStateOf("") }

    Column {
        MobileHeaderSearchAction(
            title = stringResource(Res.string.places),
            action = { viewModel.showFilterDialog.value = true },
            search = {
                isInSearchMode.value = true
            },
            drawerState = drawerState,
            scope = rememberCoroutineScope(),
            user = prefsManager.getUser(),
            onCancelSearch = {
                searchQuery.value = ""
                isInSearchMode.value = false
            }
        )

        val currentPosition = remember { mutableStateOf<LatLng?>(null) }

        //todo should we hide everything if there is no jobs?

        Box(
            modifier = Modifier.fillMaxSize(),
            contentAlignment = Alignment.BottomCenter
        ) {
            Column {
                if (isInSearchMode.value) {
                    Spacer(modifier = Modifier.height(10.dp))
                    SearchField(searchQuery)
                    Spacer(modifier = Modifier.height(10.dp))
                }

                // todo this key is here to force recreation of ios map view.
                // todo tried to pass currentPosition.value in MapComponent but it doesn't work for ios
                key(viewModel.markers.size, currentPosition.value) {
                    MapComponent(viewModel.markers, currentPosition)
                }
            }

            Column(
                modifier = Modifier.fillMaxWidth().background(Color.White)
                    .padding(start = 16.dp, top = 5.dp, bottom = 5.dp)
            ) {

                val filteredJobsSearch = if (searchQuery.value.isNotBlank()) {
                    viewModel.filteredJobs.filter { job ->
                        job.jobTitle?.contains(searchQuery.value, ignoreCase = true) == true ||
                                job.contactSiteName?.contains(
                                    searchQuery.value,
                                    ignoreCase = true
                                ) == true
                    }
                } else {
                    viewModel.filteredJobs
                }
                LazyRow(modifier = Modifier.fillMaxWidth()) {
                    if (filteredJobsSearch.isNotEmpty()) {
                        items(filteredJobsSearch) { job ->
                            PlacesJobCard(
                                job.jobTitle ?: "",
                                job.status ?: "",

                                DateTimeUtil.formatJobDetailsDate(job.startDate ?: ""),
                                viewModel.sitesMap[job.id]?.siteName ?: "",
                                job.assignees?.employees?.firstOrNull()?.getName(),
                                onClick = {
                                    viewModel.sitesMap[job.id]?.addressObject?.let { addressObject ->
                                        addressObject.lat?.let { lat ->
                                            addressObject.long?.let { lon ->
                                                currentPosition.value = LatLng(lat, lon)
                                            }
                                        }
                                    }
                                }
                            )
                        }
                    }
                }
            }
        }
    }

    val employeesList = viewModel.jobList.flatMap { job ->
        job.assignees?.employees ?: emptyList()
    }
    val names = employeesList.map { it?.getName() }
    val teams = viewModel.teams.collectAsState(emptyList())
    val teamsName = teams.value.map { it.name }
    val contactAddresses = viewModel.jobList.map { job ->
        viewModel.sitesMap[job.id]?.addressObject?.address ?: ""
    }.distinct()

    if (viewModel.showFilterDialog.value) {
        FilterJobsDialog(
            viewModel.jobList.mapNotNull {
                viewModel.jobStatusMap.entries.find { entry -> entry.value == it.status }?.key
            }.distinct(),
            (names + teamsName).distinct(),
            contactAddresses,
            viewModel.jobList.mapNotNull { it.startDate }.distinct(),
            onDismiss = { viewModel.showFilterDialog.value = false },
            initialSelectedStatus = viewModel.selectedStatus.value.map { stat ->
                viewModel.jobStatusMap.entries.find { entry -> entry.value == stat }?.key ?: ""
            }.toSet(),
            initialSelectedEmployees = viewModel.selectedEmployees.value,
            initialSelectedLocations = viewModel.selectedLocation.value,
            initialSelectedSchedule = viewModel.selectedSchedule.value,
            onApply = { selectedStatus, selectedEmployees, selectedLocation, selectedSchedule ->
                viewModel.showFilterDialog.value = false
                viewModel.selectedStatus.value = selectedStatus.map { entry ->
                    viewModel.jobStatusMap[entry] ?: entry
                }.toSet()
                viewModel.selectedEmployees.value = selectedEmployees
                viewModel.selectedLocation.value = selectedLocation
                viewModel.selectedSchedule.value = selectedSchedule
                viewModel.filterJobs()
            }
        )
    }
}