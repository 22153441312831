package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.PlatformChecker
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_client
import com.hyperether.goodjob.resources.add_new
import com.hyperether.goodjob.resources.add_new_contact
import com.hyperether.goodjob.resources.add_new_employee
import com.hyperether.goodjob.resources.add_new_job
import com.hyperether.goodjob.resources.add_new_job_message
import com.hyperether.goodjob.resources.add_new_site_message
import com.hyperether.goodjob.resources.add_new_user_message
import com.hyperether.goodjob.resources.briefcase2
import com.hyperether.goodjob.resources.create_new_employee
import com.hyperether.goodjob.resources.create_new_task
import com.hyperether.goodjob.resources.file_text__1_
import com.hyperether.goodjob.resources.user_plus
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_CONTACT_ID
import com.hyperether.goodjob.util.Constants.DEFAULT_EMPLOYEE_ID
import com.hyperether.goodjob.util.Constants.DEFAULT_JOB_ID
import com.hyperether.goodjob.util.Constants.DEFAULT_SITE_ID
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNew(navHostController: NavHostController, addNewViewModel: AddNewViewModel) {

    var selectedIndex = remember { mutableStateOf(-1) }
    val errorMessage = remember { mutableStateOf("") }
    val showErrorDialog = remember { mutableStateOf(false) }

    val users by addNewViewModel.users.collectAsState(emptyList())
    val contacts by addNewViewModel.contacts.collectAsState(emptyList())
    val sites by addNewViewModel.sites.collectAsState(emptyList())

    LaunchedEffect(Unit) {
        if (!getPlatformChecker().isMobile()) {
            addNewViewModel.init()
        }
    }

    Column {
        MobileHeaderBackAndAction(
            title = stringResource(Res.string.add_new),
            back = { navHostController.popBackStack() }
        )
        Spacer(modifier = Modifier.height(15.dp))
        val contactText = stringResource(Res.string.add_new_job_message)
        val userText = stringResource(Res.string.add_new_user_message)
        val siteText = stringResource(Res.string.add_new_site_message)

        SelectableCard(
            isSelected = selectedIndex.value == 0,
            onClick = {
                if (contacts.isEmpty()) {
                    errorMessage.value = contactText
                    showErrorDialog.value = true
                } else if (users.isEmpty()) {
                    errorMessage.value = userText
                    showErrorDialog.value = true
                } else if (sites.isEmpty()) {
                    errorMessage.value = siteText
                    showErrorDialog.value = true
                } else {
                    selectedIndex.value = 0
                    if (getPlatformChecker().isMobile()) {
                        navHostController.navigate(Screen.AddNewJob.route + "/$DEFAULT_JOB_ID")
                    } else {
                        navHostController.navigate(Screen.AddNewJob.route + "?jobId=")
                    }
                    addNewViewModel.showPopUpSuccess.value = false
                }
                if (getPlatformChecker().isMobile()) {
                    if (contacts.isEmpty()) {
                        errorMessage.value = contactText
                        showErrorDialog.value = true
                    } else if (users.isEmpty()) {
                        errorMessage.value = userText
                        showErrorDialog.value = true
                    } else if (sites.isEmpty()) {
                        errorMessage.value = siteText
                        showErrorDialog.value = true
                    } else {
                        selectedIndex.value = 0
                        if (getPlatformChecker().isMobile()) {
                            navHostController.navigate(Screen.AddNewJob.route + "/$DEFAULT_JOB_ID")
                        } else {
                            navHostController.navigate(Screen.AddNewJob.route + "?jobId=")
                        }
                        addNewViewModel.showPopUpSuccess.value = false
                    }
                } else {
                    navHostController.navigate(Screen.AddNewJob.route + "?jobId=")
                }
            },
            icon = Res.drawable.file_text__1_,
            mainText = stringResource(Res.string.add_new_job),
            subText = stringResource(Res.string.create_new_task)
        )

        SelectableCard(
            isSelected = selectedIndex.value == 1,
            onClick = {
                selectedIndex.value = 1
                if (getPlatformChecker().isMobile()) {
                    navHostController.navigate(Screen.AddNewEmployee.route + "/$$DEFAULT_EMPLOYEE_ID")
                } else {
                    navHostController.navigate(Screen.AddNewEmployee.route + "?employeeId=")
                }
                addNewViewModel.showPopUpSuccess.value = false
            },
            icon = Res.drawable.user_plus,
            mainText = stringResource(Res.string.add_new_employee),
            subText = stringResource(Res.string.create_new_employee)
        )

        SelectableCard(
            isSelected = selectedIndex.value == 2,
            onClick = {
                selectedIndex.value = 2
                if (getPlatformChecker().isMobile()) {
                    navHostController.navigate(Screen.AddNewContact.route + "/$DEFAULT_CONTACT_ID")
                } else {
                    navHostController.navigate(Screen.AddNewContact.route + "?contactId=&siteId=")
                }
                addNewViewModel.showPopUpSuccess.value = false
            },
            icon = Res.drawable.briefcase2,
            mainText = stringResource(Res.string.add_new_contact),
            subText = stringResource(Res.string.add_client)
        )

        if (showErrorDialog.value) {
            ErrorDialog(
                onDismiss = { showErrorDialog.value = false },
                title = "",
                message = errorMessage.value
            )
        }

//        FilledButton(
//            onClick = {
//                if (selectedIndex.value == 0) {
//                    navHostController.navigate(Screen.AboutContact.route)
//                    addNewViewModel.showPopUpSuccess.value = false
//                } else if (selectedIndex.value == 1) {
//                    navHostController.navigate(Screen.AddNewEmployee.route)
//                    addNewViewModel.showPopUpSuccess.value = false
//                } else if (selectedIndex.value == 2) {
//                    navHostController.navigate(Screen.AddNewContact.route)
//                    addNewViewModel.showPopUpSuccess.value = false
//                }
//            },
//            modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 30.dp),
//            text = stringResource(Res.string.create),
//            isEnabled = true
//        )
    }
}

@Composable
fun SelectableCard(
    isSelected: Boolean,
    onClick: () -> Unit,
    icon: DrawableResource,
    mainText: String,
    subText: String
) {
    Card(
        shape = RoundedCornerShape(12.dp),
        border = BorderStroke(
            if (isSelected) 2.dp else 1.dp,
            if (isSelected) BlueApp else Color.LightGray
        ),
        modifier = Modifier
            .padding(start = 16.dp, end = 16.dp, top = 15.dp)
            .fillMaxWidth()
            .clickable { onClick() }
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().padding(16.dp)
        ) {

            Icon(
                painter = painterResource(icon),
                contentDescription = null,
                modifier = Modifier.size(24.dp)
            )

            Column(
                modifier = Modifier.padding(start = 8.dp)
            ) {

                Text(
                    text = mainText,
                    fontSize = 18.sp,
                    color = TextColor,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier
                )

                Spacer(modifier = Modifier.height(8.dp))

                Text(
                    text = subText,
                    fontSize = 14.sp,
                    color = Color.Gray
                )
            }
        }
    }
}