package com.hyperether.goodjob.scenes.register

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import androidx.compose.ui.window.DialogProperties
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.confirm_password
import com.hyperether.goodjob.resources.create_strong_password
import com.hyperether.goodjob.resources.new_password
import com.hyperether.goodjob.resources.save_password
import com.hyperether.goodjob.resources.set_new_password
import com.hyperether.goodjob.resources.web_left_content
import com.hyperether.goodjob.scenes.components.PasswordInputField
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.GrayBackground
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun SetNewPasswordView(
    title: String,
    passwordInput: MutableState<String>,
    confirmPasswordInput: MutableState<String>,
    onSavePassword: () -> Unit
) {

    Dialog(
        onDismissRequest = {

        },
        properties = DialogProperties(usePlatformDefaultWidth = false),
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.White)
                .verticalScroll(rememberScrollState())
        ) {
            Column(
                modifier = Modifier
                    .padding(horizontal = 16.dp, vertical = 16.dp)
                    .fillMaxWidth()
            ) {
                Text(
                    text = title,
                    fontSize = 24.sp,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Left,
                    modifier = Modifier.padding(top = 20.dp)
                )

                Text(
                    text = stringResource(Res.string.create_strong_password),
                    color = DarkGrey,
                    textAlign = TextAlign.Start,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(top = 10.dp)
                )

                PasswordInputField(
                    passwordInput,
                    stringResource(Res.string.new_password),
                    placeholder = stringResource(Res.string.new_password)
                )

                PasswordInputField(
                    confirmPasswordInput,
                    stringResource(Res.string.confirm_password),
                    placeholder = stringResource(Res.string.confirm_password)
                )

                FilledButton(
                    onClick = {
                        onSavePassword()
                    },
                    text = stringResource(Res.string.save_password),
                    modifier = Modifier.padding(top = 30.dp),
                    isEnabled = passwordInput.value == confirmPasswordInput.value
                )

            }
        }
    }
}

@Composable
fun SetPasswordWebView(
    title: String,
    passwordInput: MutableState<String>,
    confirmPasswordInput: MutableState<String>,
    onSavePassword: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxSize()
            .background(GrayBackground)
    ) {
        Image(
            modifier = Modifier.fillMaxHeight(),
            painter = painterResource(Res.drawable.web_left_content),
            contentDescription = null,
            contentScale = ContentScale.Crop,
            alignment = Alignment.CenterStart
        )
    }

    Box(
        modifier = Modifier.fillMaxSize()
        ,
        contentAlignment = Alignment.TopEnd
    ){
        Box(
            modifier = Modifier.fillMaxHeight().width(Dp(800f)).background(GrayBackground)
        ) {
            Column(
                modifier = Modifier
                    .fillMaxHeight()
                    .widthIn(min = 200.dp)
                    .padding(bottom = Dp(200f), start = 50.dp, end = 50.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                Text(
                    text = title,
                    fontSize = 24.sp,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Left,
                    modifier = Modifier.padding(top = 20.dp)
                )

                Text(
                    text = stringResource(Res.string.create_strong_password),
                    color = DarkGrey,
                    textAlign = TextAlign.Start,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(top = 10.dp)
                )

                PasswordInputField(
                    passwordInput,
                    stringResource(Res.string.new_password),
                    placeholder = stringResource(Res.string.new_password)
                )

                PasswordInputField(
                    confirmPasswordInput,
                    stringResource(Res.string.confirm_password),
                    placeholder = stringResource(Res.string.confirm_password)
                )

                FilledButton(
                    onClick = {
                        onSavePassword()
                    },
                    text = stringResource(Res.string.save_password),
                    modifier = Modifier.padding(top = 30.dp),
                    isEnabled = passwordInput.value == confirmPasswordInput.value
                )
            }
        }

    }
}