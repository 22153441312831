package com.hyperether.goodjob.navigation

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.BottomNavigation
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraph.Companion.findStartDestination
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.scenes.addNew.AboutContact
import com.hyperether.goodjob.scenes.addNew.AddNewEmployee
import com.hyperether.goodjob.scenes.addNew.AddNewJob
import com.hyperether.goodjob.scenes.addNew.AddNew
import com.hyperether.goodjob.scenes.addNew.AddNewContact
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.dashboard.DashboardScreen
import com.hyperether.goodjob.scenes.employees.EmployeeDetails
import com.hyperether.goodjob.scenes.employees.EmployeesScreen
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.jobs.JobDetails
import com.hyperether.goodjob.scenes.jobs.JobViewModel
import com.hyperether.goodjob.scenes.jobs.JobsScreen
import com.hyperether.goodjob.scenes.landing.LandingPage
import com.hyperether.goodjob.scenes.onboarding.OnboardingScreen
import com.hyperether.goodjob.scenes.pricing.PricingScreen
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterScreen
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.scheduler.SchedulerScreen
import com.hyperether.goodjob.scenes.splash.SplashScreen
import com.hyperether.goodjob.scenes.workspace.AddNewWorkspaceScreen
import com.hyperether.goodjob.scenes.workspace.WorkspaceScreen
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel

@Composable
fun NavGraph(
    navController: NavHostController,
    repository: Repository,
    modifier: Modifier,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    addNewViewModel: AddNewViewModel,
    prefsManager: PrefsManager,
    currentRoute: String
) {
    NavHost(
        navController = navController,
        startDestination = currentRoute,
        modifier = modifier
    ) {
        composable(route = Screen.Splash.route) {
            SplashScreen(navController)
        }

        composable(
            route = Screen.Onboarding.route
        ) {
            OnboardingScreen(navController, prefsManager)
        }

        composable(
            route = Screen.Register.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                RegisterScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    registerViewModel = registerViewModel,
                    navHostController = navController,
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel,
                )
            }
        }

        composable(
            route = Screen.Pricing.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                PricingScreen(isMobileOrNarrowScreen, pricingViewModel) {
                    navController.navigate(Screen.Workspace.route)
                }
            }
        }

        composable(
            route = Screen.Workspace.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                WorkspaceScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    workspaceViewModel = workspaceViewModel,
                    continueToDashboard = {
                        navController.navigate(Screen.Dashboard.route)
                    },
                    addNewWorkspace = {
                        navController.navigate(Screen.AddNewWorkspace.route)
                    })
            }
        }

        composable(
            route = Screen.AddNewWorkspace.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                AddNewWorkspaceScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    workspaceViewModel = workspaceViewModel,
                    onChooseExistingWorkspace = {
                        navController.navigate(Screen.Workspace.route)
                    }
                )
            }
        }
        composable(
            route = Screen.Dashboard.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                DashboardScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    navController
                )
            }
        }

        composable(Screen.AddNew.route) {
            AddNew(navController)
        }

        composable(Screen.AddNewEmployee.route) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                AddNewEmployee(isMobileOrNarrowScreen, navController, addNewViewModel)
            }
        }

        composable(Screen.Employees.route) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                EmployeesScreen(
                    isMobileOrNarrowScreen,
                    employeesViewModel,
                    navController,
                    prefsManager
                )
            }
        }

        val route = if (getPlatformChecker().isMobile()) {
            Screen.EmployeeDetails.route + "/{employeeId}"  // Mobile route with path parameter
        } else {
            Screen.EmployeeDetails.route + "?employeeId={employeeId}"  // Web route with query parameter
        }

        composable(route) { backStackEntry ->
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                val employeeId: String = if (getPlatformChecker().isMobile()) {
                    // For mobile, get the employeeId from the path
                    backStackEntry.arguments?.getString("employeeId") ?: ""
                } else {
                    // For web, get the employeeId from the query parameter
                    backStackEntry.arguments?.getString("employeeId") ?: ""
                }

                EmployeeDetails(
                    employeeId,
                    isMobileOrNarrowScreen,
                    employeesViewModel,
                    addNewViewModel,
                    navController
                )
            }
        }


        composable(Screen.AddNewJob.route) {
            AddNewJob(addNewViewModel, navController)
        }

        composable(Screen.Jobs.route) {
            val viewModel: JobViewModel = viewModel { JobViewModel(repository) }
            JobsScreen(viewModel, navController)
        }
        composable(Screen.AboutContact.route) {
            // val viewModel: JobViewModel = viewModel { JobViewModel(repository) }
            AboutContact(addNewViewModel, navController)
        }

        composable(Screen.AddNewContact.route) {
            AddNewContact(addNewViewModel, navController)
        }


        composable(Screen.JobDetails.route + "/{jobId}") { backStackEntry ->
            val jobId: String = backStackEntry.arguments?.getString("jobId") ?: ""
            val viewModel: JobViewModel = viewModel { JobViewModel(repository) }
            JobDetails(jobId, viewModel, navController)
        }

        composable(
            route = Screen.Landing.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || maxWidth < 600.dp
                LandingPage(isMobileOrNarrowScreen, pricingViewModel) {
                    navController.navigate(Screen.Register.route)
                }
            }
        }

        composable(Screen.Scheduler.route) {
            SchedulerScreen()
        }
    }
}

@Composable
fun Container(
    navController: NavHostController,
    repository: Repository,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    addNewViewModel: AddNewViewModel,
    prefsManager: PrefsManager,
    currentRoute: String
) {
    val isBottomNavigationBarVisible = remember { mutableStateOf(true) }
    val isWebNavigationDrawerVisible = remember { mutableStateOf(true) }

    LaunchedEffect(navController) {
        navController.addOnDestinationChangedListener { _, destination, _ ->
            isBottomNavigationBarVisible.value = when (destination.route) {
                Screen.Pricing.route,
                Screen.Register.route,
                Screen.AddNewEmployee.route,
                Screen.AddNew.route,
                Screen.Splash.route,
                Screen.Landing.route,
                Screen.Workspace.route,
                Screen.AboutContact.route,
                Screen.AddNewContact.route,
                Screen.AddNewJob.route,
                Screen.Onboarding.route -> false

                else -> true
            }
            isWebNavigationDrawerVisible.value = when (destination.route) {
                Screen.Pricing.route,
                Screen.Register.route,
                Screen.Splash.route,
                Screen.Landing.route,
                Screen.Workspace.route,
                Screen.Onboarding.route -> false

                else -> true
            }
        }
    }
    BoxWithConstraints(modifier = Modifier.fillMaxSize()) {
        if (maxWidth < 600.dp) {
            // Mobile layout
            val startRoute = if (repository.getUser() != null && repository.getUser()?.planId?.isNotEmpty() == true) {
                Screen.Workspace.route
            } else if (repository.getUser() != null && repository.getUser()?.planId?.isEmpty() == true) {
                Screen.Pricing.route
            } else {
                currentRoute
            }
            Column {
                NavGraph(
                    navController,
                    repository,
                    modifier = Modifier.weight(1f),
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel,
                    registerViewModel = registerViewModel,
                    employeesViewModel = employeesViewModel,
                    addNewViewModel = addNewViewModel,
                    prefsManager = prefsManager,
                    currentRoute = startRoute
                )
                if (isBottomNavigationBarVisible.value) {
                    BottomNavigation(backgroundColor = Color.White) {
                        AndroidNav(navController)
                    }
                }
            }
        } else {
            Row {
                if (isWebNavigationDrawerVisible.value) {
                    WebNav(navController, workspaceViewModel)
                }
                NavGraph(
                    navController,
                    repository,
                    modifier = Modifier.weight(1f),
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel,
                    registerViewModel = registerViewModel,
                    employeesViewModel = employeesViewModel,
                    addNewViewModel,
                    prefsManager = prefsManager,
                    currentRoute
                )

            }
        }
    }
}

fun NavController.safeNavigate(route: String) {
    currentDestination?.let { currentDestination ->
        if (currentDestination.route != route) {
            navigate(route) {
                popUpTo(graph.findStartDestination().route ?: Screen.Checkout.route) {
                    saveState = true
                }
                launchSingleTop = true
                restoreState = true
            }
        }
    }
}


//class NavController(
//    private val onNavigate: (String) -> Unit,
//    private val onNavigateBack: () -> Unit,
//    private val shouldNavigate: Boolean
//) :
//    NavHostController() {
//
//    init {
//        navigatorProvider.addNavigator(ComposeNavigator())
//        navigatorProvider.addNavigator(DialogNavigator())
//        this.addOnDestinationChangedListener { controller, destination, arguments ->
//            if (shouldNavigate) {
//                currentDestination?.route?.let {
//                    onNavigate(
//                        it
//                    )
//                }
//            }
//        }
//
//        if (!shouldNavigate) {
//            CoroutineScope(Dispatchers.Main).launch {
//                Router.currentRoute.collect {
//                    navigate(it)
//                }
//            }
//        }
//    }
//
//
//    override fun navigateUp(): Boolean {
//        if (shouldNavigate) {
//            onNavigateBack()
//            return super.navigateUp()
//        }
//        return false
//    }
//}
//
//@Composable
//fun rememberNavController(
//    onNavigate: (String) -> Unit,
//    onNavigateBack: () -> Unit,
//    shouldNavigate: Boolean
//): NavController {
//    return remember { NavController(onNavigate, onNavigateBack, shouldNavigate) }
//}
//
//object Router {
//    val currentRoute = MutableStateFlow("/")
//}