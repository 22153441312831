package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.ContactType
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about
import com.hyperether.goodjob.resources.add_new
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_person
import com.hyperether.goodjob.resources.contact_type
import com.hyperether.goodjob.resources.contact_type_contractor
import com.hyperether.goodjob.resources.contact_type_customer
import com.hyperether.goodjob.resources.contact_type_supplier
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.industry
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.sites
import com.hyperether.goodjob.scenes.jobs.components.JobsItem
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.util.Constants.DEFAULT_SITE_ID
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun ContactDetails(
    contactId: String,
    navHostController: NavHostController,
    viewModel: ContactsViewModel,
    isMobileView: Boolean,
    ) {
    viewModel.contactTypeMap = mapOf(
        stringResource(Res.string.contact_type_customer) to ContactType.customer.name,
        stringResource(Res.string.contact_type_supplier) to ContactType.supplier.name,
        stringResource(Res.string.contact_type_contractor) to ContactType.contractor.name
    )

    val log = logging("ContactDetails")
    val contact = remember { mutableStateOf<Contact?>(null) }
    val isAboutContactView = remember { mutableStateOf(true) }
    val isFilesView = remember { mutableStateOf(false) }
    val isSitesContactView = remember { mutableStateOf(false) }
    val isJobsContactView = remember { mutableStateOf(false) }
    val contactsMap = remember { mutableStateMapOf<String, Contact>() }

    val jobs by viewModel.jobs.collectAsState()

    LaunchedEffect(contactId) {
        viewModel.contacts.collect { contactsList ->
            contact.value = contactsList.find { it.id == contactId }
        }
    }

    LaunchedEffect(contact) {
        viewModel.fetchJobsByParentId(contactId)
        viewModel.getSitesUnderContact(SiteRequest(contactId))
        log.d("LaunchedEffect") { "contactId: $contactId details: $contact" }

        // TODO: check this, does not execute
        jobs.forEach { job ->
            job.contactParentId?.let {
                val c = viewModel.getContactById(it)
                c?.id?.let { id ->
                    contactsMap[id] = c
                }
            }
        }
    }

    println(jobs.toList().toString())

    if (isMobileView) {
        Box(modifier = Modifier.fillMaxSize()) {
            Column {
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(72.dp)
                        .padding(horizontal = 16.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "",
                        modifier = Modifier
                            .align(Alignment.CenterStart)
                            .clickable {
                                navHostController.popBackStack()
                            }
                    )

                    Text(
                        text = contact.value?.name ?: "",
                        fontSize = 18.sp,
                        color = Color.Black,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.align(Alignment.Center)
                    )

                    if (isSitesContactView.value) {
                        Row(
                            horizontalArrangement = Arrangement.End,
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier
                                .align(Alignment.CenterEnd)
                                .background(color = BlueApp, shape = RoundedCornerShape(32.dp))
                                .padding(horizontal = 12.dp, vertical = 10.dp)
                        ) {
                            Text(
                                text = stringResource(Res.string.add_new),
                                color = Color.White,
                                fontSize = 14.sp,
                                fontWeight = FontWeight.W400,
                                modifier = Modifier.clickable {
                                    if (getPlatformChecker().isMobile()) {
                                        navHostController.navigate(Screen.AddNewSite.route + "/$contactId/$DEFAULT_SITE_ID")
                                    } else {
                                        navHostController.navigate(Screen.AddNewSite.route + "?contactId=$contactId&siteId=")
                                    }
                                }
                            )
                        }
                    } else {
                        Image(
                            painter = painterResource(Res.drawable.edit),
                            contentDescription = "",
                            modifier = Modifier
                                .align(Alignment.CenterEnd)
                                .clickable {
                                    if (getPlatformChecker().isMobile()) {
                                        navHostController.navigate(Screen.AddNewContact.route + "/$contactId")
                                    } else {
                                        navHostController.navigate(Screen.AddNewContact.route + "?contactId=$contactId")
                                    }
                                }
                        )
                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )
                Column(
                    modifier = Modifier
                        .background(Color.LightGray.copy(alpha = 0.2f))
                        .padding(8.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(40.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.about),
                            color = if (isAboutContactView.value) BlueApp else Color.Gray,
                            fontWeight = if (isAboutContactView.value) FontWeight.Bold else FontWeight.Normal,
                            fontSize = 16.sp,
                            textAlign = TextAlign.Center,
                            modifier = Modifier
                                .weight(0.33f)
                                .clickable {
                                    isAboutContactView.value = true
                                    isFilesView.value = false
                                    isSitesContactView.value = false
                                    isJobsContactView.value = false
                                }
                        )

//                    androidx.compose.material3.Text(
//                        text = stringResource(Res.string.files_notes),
//                        color = if (isFilesView.value) BlueApp else Color.Gray,
//                        fontWeight = if (isFilesView.value) FontWeight.Bold else FontWeight.Normal,
//                        fontSize = 16.sp,
//                        modifier = Modifier
//                            .padding(end = 20.dp)
//                            .clickable {
//                                isAboutContactView.value = false
//                                isFilesView.value = true
//                                isSitesContactView.value = false
//                                isJobsContactView.value = false
//                            }
//                    )
//                    Spacer(modifier = Modifier.weight(0.5f))

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.sites),
                            color = if (isSitesContactView.value) BlueApp else Color.Gray,
                            fontWeight = if (isSitesContactView.value) FontWeight.Bold else FontWeight.Normal,
                            fontSize = 16.sp,
                            textAlign = TextAlign.Center,
                            modifier = Modifier
                                .weight(0.33f)
                                .clickable {
                                    isAboutContactView.value = false
                                    isFilesView.value = false
                                    isSitesContactView.value = true
                                    isJobsContactView.value = false
                                }
                        )

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.jobs),
                            color = if (isJobsContactView.value) BlueApp else Color.Gray,
                            fontWeight = if (isJobsContactView.value) FontWeight.Bold else FontWeight.Normal,
                            fontSize = 16.sp,
                            textAlign = TextAlign.Center,
                            modifier = Modifier
                                .weight(0.33f)
                                .clickable {
                                    isAboutContactView.value = false
                                    isFilesView.value = false
                                    isSitesContactView.value = false
                                    isJobsContactView.value = true
                                }
                        )
                    }
                }
                Row(
                    modifier = Modifier.fillMaxWidth().height(2.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    if (isAboutContactView.value) {
                        Box(
                            modifier = Modifier
                                .height(2.dp)
                                .weight(0.33f)
                                .fillMaxWidth(0.5f)
                                .background(BlueApp)
                                .padding()
                        )
                    }
                    Spacer(modifier = Modifier.weight(0.33f))
//                if (isFilesView.value) {
//                    Box(
//                        modifier = Modifier
//                            .height(2.dp)
//                            .width(120.dp)
//                            .padding(start = 10.dp)
//                            .fillMaxWidth(0.5f)
//                            .background(BlueApp)
//                    )
//                }
//                Spacer(modifier = Modifier.weight(0.41f))
                    if (isSitesContactView.value) {
                        Box(
                            modifier = Modifier
                                .height(2.dp)
                                .weight(0.33f)
                                //.padding(start = 10.dp)
                                .fillMaxWidth(0.5f)
                                .background(BlueApp)
                        )
                    }
                    Spacer(modifier = Modifier.weight(0.33f))
                    if (isJobsContactView.value) {
                        Box(
                            modifier = Modifier
                                .height(2.dp)
                                .weight(0.33f)
                                .fillMaxWidth(0.5f)
                                .background(BlueApp)
                        )
                    }
                }
                if (isAboutContactView.value) {
                    Spacer(modifier = Modifier.height(15.dp))
                    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
                        DetailsTextItem(
                            stringResource(Res.string.contact_name),
                            contact.value?.name ?: ""
                        )
                        DetailsTextItem(
                            stringResource(Res.string.contact_type),
                            viewModel.contactTypeMap.entries.find { item -> item.value == contact.value?.contactType }?.key
                                ?: ""
                        )
                        DetailsTextItem(
                            stringResource(Res.string.industry),
                            contact.value?.industry ?: ""
                        )
                        DetailsTextItem(
                            stringResource(Res.string.address),
                            "${contact.value?.addressObject?.address}, ${contact.value?.addressObject?.zip} ${contact.value?.addressObject?.country}"
                                ?: "",
                            BlueApp
                        )
                        DetailsTextItem(
                            stringResource(Res.string.phone),
                            contact.value?.phone ?: ""
                        )
                        DetailsTextItem(
                            stringResource(Res.string.email).capitalize(),
                            contact.value?.email ?: ""
                        )
                        Spacer(modifier = Modifier.height(16.dp))
                        Text(
                            text = stringResource(Res.string.contact_person),
                            fontSize = 18.sp,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier.padding(start = 16.dp)
                        )
                        Spacer(modifier = Modifier.height(15.dp))
                        DetailsTextItem(
                            stringResource(Res.string.contact_person),
                            contact.value?.personName ?: ""
                        )
                        Spacer(modifier = Modifier.height(10.dp))
                        DetailsTextItem(
                            stringResource(Res.string.phone),
                            contact.value?.personPhone ?: ""
                        )
                        DetailsTextItem(
                            stringResource(Res.string.email).capitalize(),
                            contact.value?.personEmail ?: ""
                        )
                    }
                }
                if (isFilesView.value) {
                    Spacer(modifier = Modifier.height(15.dp))
                }
                if (isSitesContactView.value) {
                    Spacer(modifier = Modifier.height(15.dp))
                    SitesList(viewModel.currentSitesForContact, onEditSiteClick = { siteId ->
                        if (getPlatformChecker().isMobile()) {
                            navHostController.navigate(Screen.AddNewSite.route + "/$contactId/${siteId}")
                        } else {
                            navHostController.navigate(Screen.AddNewSite.route + "?contactId=$contactId&siteId=$siteId")
                        }
                    })
                }
                if (isJobsContactView.value) {
                    if (jobs.isNotEmpty()) {
                        LazyColumn(
                            modifier = Modifier.fillMaxSize().padding(start = 8.dp, end = 16.dp)
                        ) {
                            items(jobs.takeIf { it.isNotEmpty() } ?: emptyList()) { job ->
                                JobsItem(
                                    sites = viewModel.sitesMap,
                                    job = job,
                                    selectedJobs = mutableListOf(),
                                    isInEditMode = false,
                                    onJobSelected = { job, isSelected -> },
                                    onItemClick = {},
                                )
                            }
                        }
                    }
                }
            }
        }
    } else {
        ContactDetailsWeb(navHostController, viewModel, contactId)
    }
}