package com.hyperether.goodjob.navigation

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.material.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.navigation.NavHostController
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.briefcase
import com.hyperether.goodjob.resources.calendar
import com.hyperether.goodjob.resources.dahboard
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.map
import com.hyperether.goodjob.resources.user_check
import org.jetbrains.compose.resources.painterResource
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import com.hyperether.goodjob.resources.briefcase_unselect
import com.hyperether.goodjob.resources.calendar_unselect
import com.hyperether.goodjob.resources.dahboard_unselect
import com.hyperether.goodjob.resources.employees_unselect
import com.hyperether.goodjob.resources.map_unselect
import com.hyperether.goodjob.resources.user_check_unselect

@Composable
fun AndroidNav(navController: NavHostController) {
    var selectedTabIndex = remember { mutableStateOf(0) }

    val navItems = listOf(
        NavItem(iconResId = painterResource(Res.drawable.dahboard), unselectedIconResId = painterResource(Res.drawable.dahboard_unselect), route = Screen.Dashboard.route),
        NavItem(iconResId = painterResource(Res.drawable.employees), unselectedIconResId = painterResource(Res.drawable.employees_unselect), route = Screen.Employees.route),
        NavItem(iconResId = painterResource(Res.drawable.briefcase), unselectedIconResId = painterResource(Res.drawable.briefcase_unselect), route = Screen.Dashboard.route),
        NavItem(iconResId = painterResource(Res.drawable.calendar), unselectedIconResId = painterResource(Res.drawable.calendar_unselect), route = Screen.Dashboard.route),
        NavItem(iconResId = painterResource(Res.drawable.user_check), unselectedIconResId = painterResource(Res.drawable.user_check_unselect), route = Screen.Dashboard.route),
        NavItem(iconResId = painterResource(Res.drawable.map), unselectedIconResId = painterResource(Res.drawable.map_unselect), route = Screen.Dashboard.route)
    )

    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceAround
    ) {
        navItems.forEachIndexed { index, item ->
            IconButton(onClick = {
                navController.safeNavigate(item.route)
                selectedTabIndex.value = index
            }) {
                Column(
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    val icon = if (selectedTabIndex.value == index) item.iconResId else item.unselectedIconResId
                    Image(
                        painter = icon,
                        contentDescription = null,
                    )
                }
            }
        }
    }
}

data class NavItem(val iconResId: Painter, val unselectedIconResId: Painter, val route: String)
