package com.hyperether.goodjob.models

import com.hyperether.goodjob.repository.remote.model.UserRequest
import com.hyperether.goodjob.repository.remote.model.WorkingHours
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class User(
    @SerialName("_id") var _id: String? = null,
    @SerialName("fullName") var fullName: String? = null,
    @SerialName("email") var email: String? = null,
    @SerialName("password") var password: String? = null,
    @SerialName("confirmStatus") var confirmStatus: String? = null,
    @SerialName("role") var role: String? = null,
    @SerialName("hash") var hash: String? = null,
    @SerialName("type") var type: String? = null,
    @SerialName("workspaceId") var workspaceId: String? = null,
    @SerialName("planId") var planId: String? = null,
    @SerialName("subscriptionId") var subscriptionId: String? = null,

    @SerialName("phone") var phone: String? = null,
    @SerialName("zip") var zip: String? = null,
    @SerialName("country") var country: String? = null,
    @SerialName("city") var city: String? = null,
    @SerialName("address") var address: String? = null,
    @SerialName("status") var status: String? = null,
    @SerialName("skils") var skills: List<String>? = null,
    @SerialName("teams") var teams: List<UserTeam?>? = null,
    @SerialName("createdAt") val createdAt: Long? = null,
    @SerialName("updatedAt") val updatedAt: Long? = null,
    @SerialName("customerId") val customerId: String? = null,
    @SerialName("upcomingLeave") val upcomingLeave: String? = null,
    @SerialName("workingHours") var workingHours: WorkingHours? = null
) {

    fun getName(): String {
        return this.fullName.takeIf { it?.isNotEmpty() == true } ?: this.email.orEmpty()
    }

    fun toEmployeeRequest(): UserRequest {
        return UserRequest(
            fullName = this.fullName.orEmpty(),
            email = this.email.orEmpty(),
            phone = this.phone.orEmpty(),
            zip = this.zip.orEmpty(),
            country = this.country.orEmpty(),
            city = this.city.orEmpty(),
            address = this.address.orEmpty(),
            status = this.status.orEmpty(),
            workspaceId = this.workspaceId.orEmpty(),
            skills = this.skills.orEmpty(),
            teams = this.teams?.map {
                UserTeam(
                    id = it?.id.orEmpty(),
                    value = it?.value.orEmpty()
                )
            } ?: emptyList(),
            upcomingLeave = this.upcomingLeave,
            role = this.role.orEmpty(),
            workingHours = this.workingHours
        )
    }
}