import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.ime
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.material.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Container
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.scenes.addNew.AddContactViewModel
import com.hyperether.goodjob.scenes.addNew.AddJobViewModel
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.addNew.AddUserViewModel
import com.hyperether.goodjob.scenes.employees.EmployeeDetailsViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
@Preview
fun App(
    navController: NavHostController,
    repository: Repository,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    addNewViewModel: AddNewViewModel,
    addContactViewModel: AddContactViewModel,
    addUserViewModel: AddUserViewModel,
    addJobViewModel: AddJobViewModel,
    prefsManager: PrefsManager,
    currentRoute: String = Screen.Splash.route,
    onChoosePlanClick: (Double, String) -> Unit,
    onLogout: () -> Unit,
    onUploadDocumentClick: (Boolean) -> Unit,
    onOpenPdf: (String) -> Unit
) {
    MaterialTheme {
        Box(modifier = Modifier.fillMaxSize().windowInsetsPadding(WindowInsets.ime)){
            Container(
                navController,
                repository,
                workspaceViewModel,
                pricingViewModel,
                registerViewModel,
                employeesViewModel,
                employeeDetailsViewModel,
                addNewViewModel,
                addContactViewModel,
                addUserViewModel,
                addJobViewModel,
                prefsManager,
                currentRoute,
                onChoosePlanClick,
                onLogout,
                onUploadDocumentClick,
                onOpenPdf
            )
        }
    }
}