package com.hyperether.goodjob.navigation

sealed class Screen(val route: String) {

    data object Checkout : Screen("checkout")
    data object History : Screen("history")
    data object Statistic : Screen("statistics")
    data object Returns : Screen("returns")
    data object WebNav: Screen("web_nav")
    data object Splash: Screen("splash")
    data object Scheduler: Screen("scheduler")
    data object Onboarding: Screen("onboarding")
    data object Register: Screen("register")
    data object Landing: Screen("landing")
    data object Pricing: Screen("pricing")
    data object Dashboard: Screen("dashboard")
    data object Workspace: Screen("workspace")
    data object AddNewWorkspace: Screen ("add_new_workspace")
    data object AddNew: Screen ("add_new")
    data object AddNewEmployee: Screen ("add_new_employee")
    data object Employees: Screen ("Employees")
}