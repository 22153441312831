package com.hyperether.goodjob.util

import com.hyperether.goodjob.repository.remote.model.WorkingHours
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.Month
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atTime
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime

object DateTimeUtil {

    fun formatLastAccessed(unixTimestamp: Long): String {
        // Handle possible millisecond timestamp by converting to seconds
        val adjustedTimestamp =
            if (unixTimestamp > 9999999999L) unixTimestamp / 1000 else unixTimestamp

        // Convert the Unix timestamp to a LocalDateTime
        val dateTime = Instant.fromEpochSeconds(adjustedTimestamp)
            .toLocalDateTime(TimeZone.currentSystemDefault())

        // Get the current date and time in the system's time zone
        val now = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())

        // Calculate today's and yesterday's date
        val today = now.date
        val yesterday = today.minus(DatePeriod(days = 1))

        // Format the time as HH:mm
        val timeFormatted = "${dateTime.hour.toString().padStart(2, '0')}:${
            dateTime.minute.toString().padStart(2, '0')
        }"

        // Format the date as YYYY-MM-DD
        val dateFormatted = "${dateTime.year}-${
            dateTime.monthNumber.toString().padStart(2, '0')
        }-${dateTime.dayOfMonth.toString().padStart(2, '0')}"

        // Compare and return formatted string
        return when (dateTime.date) {
            today -> "Today, $timeFormatted"
            yesterday -> "Yesterday, $timeFormatted"
            else -> "$dateFormatted, $timeFormatted"
        }
    }


    fun jobListDateFormatter(dateString: String): String {
        try {
            if (dateString.isEmpty())
                return dateString
            val parsedString = dateString.replace("[", "").replace("]", "")
            val date = parseDate(parsedString)
            val dayOfWeek = date?.dayOfWeek?.name?.lowercase()?.replaceFirstChar { it.uppercase() }
            val day = date?.dayOfMonth.toString().padStart(2, '0')
            val month = date?.month?.name?.lowercase()?.replaceFirstChar { it.uppercase() }
            val year = date?.year

            return "$dayOfWeek, $day $month $year"
        } catch (e: Exception) {
            println("Cant parse date: $dateString")
            return dateString
        }

    }

    fun formatDateRange(input: String): String {
        val monthAbbreviations = mapOf(
            "Jan" to "01", "Feb" to "02", "Mar" to "03", "Apr" to "04",
            "May" to "05", "Jun" to "06", "Jul" to "07", "Aug" to "08",
            "Sep" to "09", "Oct" to "10", "Nov" to "11", "Dec" to "12"
        )

        if (input.isBlank() || input == "Upcoming leaves") {
            return "Upcoming leaves"
        }

        try {
            val cleanInput = input.removePrefix("upcomingLeave=")

            val parts = cleanInput.split(" - ").map { it.trim() }
            if (parts.size != 2) {
                return "Invalid input format"
            }

            val startPart = parts[0]
            val endPart = parts[1]
            println("Start Part: $startPart, End Part: $endPart")

            val startSplit = startPart.split(" ")
            if (startSplit.size != 2) {
                return "Invalid input format"
            }

            val monthAbbr = startSplit[0]
            val startDay = startSplit[1].toIntOrNull()
            if (startDay == null) {
                return "Invalid start day"
            }

            val endSplit = endPart.split(",")
            if (endSplit.size != 2) {
                return "Invalid end date format"
            }

            val endDay = endSplit[0].toIntOrNull()
            if (endDay == null) {
                return "Invalid end day"
            }

            val year = endSplit[1].trim().toIntOrNull() ?: 2025

            val monthNumber = monthAbbreviations[monthAbbr]
            if (monthNumber == null) {
                return "Invalid month"
            }

            val startDate = LocalDate(year, monthNumber.toInt(), startDay)
            val endDate = LocalDate(year, monthNumber.toInt(), endDay)

            val formatter = { date: LocalDate ->
                val day = date.dayOfMonth
                val month = date.month.name.take(3).lowercase().replaceFirstChar { it.uppercase() }
                "$day $month"
            }

            return "${formatter(startDate)} - ${formatter(endDate)}, $year"
        } catch (e: Exception) {
            return "Invalid input format: ${e.message}"
        }
    }

    fun formatJobDetailsDate(inputDate: String): String {
        return try {
            val cleanedDate = inputDate.trim().removeSurrounding("[", "]")

            val instant = if (cleanedDate.contains(" ")) {
                Instant.parse(cleanedDate.replace(" ", "T"))
            } else {
                Instant.parse("${cleanedDate}T00:00:00Z")
            }

            val localDateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())

            val day = localDateTime.dayOfMonth.toString().padStart(2, '0')
            val month = localDateTime.month.name.lowercase().replaceFirstChar { it.uppercase() }
            val year = localDateTime.year

            "$day $month $year"
        } catch (e: Exception) {
            ""
        }
    }


    private fun parseDate(input: String): LocalDateTime? {
        return try {
            when {
                input.matches(Regex("\\d{4}-\\d{2}-\\d{2}")) -> {
                    // Handle ISO 8601 date (e.g., "2024-12-29")
                    LocalDate.parse(input).atTime(0, 0) // Default to midnight
                }

                input.matches(Regex("\\d+")) -> {
                    // Handle Unix timestamp (e.g., "1703827200")
                    val epochSeconds = input.toLong()
                    Instant.fromEpochSeconds(epochSeconds)
                        .toLocalDateTime(TimeZone.currentSystemDefault())
                }

                else -> null // Unsupported format
            }
        } catch (e: Exception) {
            null // Return null for invalid input
        }
    }

    fun extractDate(unixTimestamp: Long): LocalDate {
        // Handle possible millisecond timestamp by converting to seconds
        val adjustedTimestamp =
            if (unixTimestamp > 9999999999L) unixTimestamp / 1000 else unixTimestamp

        // Convert to LocalDateTime and then extract the date
        return Instant.fromEpochSeconds(adjustedTimestamp)
            .toLocalDateTime(TimeZone.currentSystemDefault())
            .date
    }

    fun formatDateForDisplay(date: LocalDate): String {
        val day = date.dayOfMonth.toString().padStart(2, '0')
        val month = date.month.name.lowercase().replaceFirstChar { it.uppercase() }
        val year = date.year
        val dayOfWeek = date.dayOfWeek.name.lowercase().replaceFirstChar { it.uppercase() }
        return "$day $month $year, $dayOfWeek"
    }

    fun groupContiguousWorkingHours(workingHours: WorkingHours?): List<Pair<String, String?>> {
        if (workingHours == null) return emptyList()

        // Map days to their schedules
        val dayScheduleMap = listOf(
            "Monday" to workingHours.monday,
            "Tuesday" to workingHours.tuesday,
            "Wednesday" to workingHours.wednesday,
            "Thursday" to workingHours.thursday,
            "Friday" to workingHours.friday,
            "Saturday" to workingHours.saturday,
            "Sunday" to workingHours.sunday
        )

        // Filter only enabled days with schedules
        val filteredDays = dayScheduleMap.filter { it.second?.enabled == true }

        // Group contiguous days with the same schedule
        val grouped = mutableListOf<Pair<List<String>, String?>>()
        var currentGroup = mutableListOf<String>()
        var currentSchedule: String? = null

        for ((day, hours) in filteredDays) {
            val schedule = hours?.let { "${it.from} - ${it.to}" }
            if (schedule == currentSchedule) {
                currentGroup.add(day)
            } else {
                if (currentGroup.isNotEmpty()) {
                    grouped.add(currentGroup to currentSchedule)
                }
                currentGroup = mutableListOf(day)
                currentSchedule = schedule
            }
        }

        // Add the final group
        if (currentGroup.isNotEmpty()) {
            grouped.add(currentGroup to currentSchedule)
        }

        return grouped.map { (days, schedule) ->
            // Convert days to "Monday - Wednesday" or just "Monday"
            val dayRange = when {
                days.size > 1 -> "${days.first()} - ${days.last()}"
                else -> days.first()
            }
            dayRange to schedule
        }
    }

    fun formatWorkingHoursText(groupedSchedule: List<Pair<String, String?>>): String {
        return groupedSchedule.joinToString("\n") { (dayRange, schedule) ->
            "$dayRange | ${schedule ?: "Closed"}"
        }
    }

    fun formatInstantToCustomFormat(instant: Instant): String {
        val localDateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())
        val month = localDateTime.month.name.take(3).lowercase().capitalize()
        val day = localDateTime.dayOfMonth.toString().padStart(2, '0')
        val hour = localDateTime.hour.toString().padStart(2, '0')
        val minute = localDateTime.minute.toString().padStart(2, '0')

        return "$month $day, ${localDateTime.year} - $hour:$minute"
    }

    fun getLastDayOfWeek(date: LocalDate): LocalDate {
        val dayOfWeek = date.dayOfWeek
        val daysUntilEndOfWeek =
            7 - dayOfWeek.ordinal  // Sunday is the last day of the week (ordinal = 6)
        return date.plus(daysUntilEndOfWeek, DateTimeUnit.DAY)
    }

    fun getLastDayOfMonth(date: LocalDate?): LocalDate? {
        if (date == null)
            return null
        val year = date.year
        val month = date.month
        val daysInMonth = daysInMonth(year, month)
        return LocalDate(year, month, daysInMonth)
    }

    fun daysInMonth(year: Int, month: Month): Int {
        return when (month) {
            Month.JANUARY, Month.MARCH, Month.MAY, Month.JULY, Month.AUGUST, Month.OCTOBER, Month.DECEMBER -> 31
            Month.APRIL, Month.JUNE, Month.SEPTEMBER, Month.NOVEMBER -> 30
            Month.FEBRUARY -> if (year.isLeapYear()) 29 else 28
            else -> 30
        }
    }

    fun Int.isLeapYear(): Boolean {
        return (this % 4 == 0 && this % 100 != 0) || (this % 400 == 0)
    }

    fun getLocalDateTimeAhead(localDateTime: LocalDateTime, daysAhead: Int): LocalDateTime {
        val timeZone = TimeZone.currentSystemDefault()
        val instant = localDateTime.toInstant(timeZone)
        val instantDaysLater = instant.plus(daysAhead, DateTimeUnit.DAY, timeZone)
        return instantDaysLater.toLocalDateTime(timeZone)
    }

    fun getLocalDateTimeMonthsAhead(localDateTime: LocalDateTime, monthsAhead: Int): LocalDateTime {
        val timeZone = TimeZone.currentSystemDefault()
        val instant = localDateTime.toInstant(timeZone)
        val instantDaysLater = instant.plus(monthsAhead, DateTimeUnit.MONTH, timeZone)
        return instantDaysLater.toLocalDateTime(timeZone)
    }
}