package com.hyperether.goodjob.scenes.jobs

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.CompletionReportRequest
import com.hyperether.goodjob.repository.remote.model.CompletionReportResponse
import com.hyperether.goodjob.repository.remote.model.FileObject
import com.hyperether.goodjob.repository.remote.model.FinishJobRequest
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.util.FileType
import com.hyperether.goodjob.util.FileUtil
import com.hyperether.goodjob.util.UploadManager
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging

class JobDetailsViewModel(
    val repository: Repository,
) : ViewModel() {
    val log = logging("JobDetailsViewModel")

    val showNotePopUp: MutableState<Boolean> = mutableStateOf(false)
    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val showNoteEditDeletePopUpMenu: MutableState<Boolean> = mutableStateOf(false)
    val noteToEditOrDelete: MutableState<Note?> = mutableStateOf(null)
    var selectedNoteId = mutableStateOf<String?>(null)
    var isEditSelectedNote: MutableState<Boolean> = mutableStateOf(false)
    val showAreYouSurePopUpDeleteNote: MutableState<Boolean> = mutableStateOf(false)

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showFileDeleteSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showFinishedJobSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showRejectedJobSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showReportSubmitSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")
    val noteInput: MutableState<String> = mutableStateOf("")

    private val _jobDetails = MutableStateFlow<Job?>(null)
    val jobDetails: StateFlow<Job?> = _jobDetails

    private val _contact = MutableStateFlow<Contact?>(null)
    val contact: StateFlow<Contact?> = _contact

    private val _imagePainters =
        MutableStateFlow<List<Pair<FileObject, BitmapPainter>>>(emptyList())
    val imagePainters: StateFlow<List<Pair<FileObject, BitmapPainter>>> = _imagePainters

    private val _pdfFilePaths = MutableStateFlow<List<Pair<FileObject, String>>>(emptyList())
    val pdfFilePaths: StateFlow<List<Pair<FileObject, String>>> = _pdfFilePaths

    private val _completionReportImagePainters =
        MutableStateFlow<List<Pair<String, BitmapPainter>>>(emptyList())
    val completionReportImagePainters: StateFlow<List<Pair<String, BitmapPainter>>> =
        _completionReportImagePainters

    private val _clientSignaturePainters =
        MutableStateFlow<List<Pair<String, BitmapPainter>>>(emptyList())
    val clientSignaturePainters: StateFlow<List<Pair<String, BitmapPainter>>> =
        _clientSignaturePainters

    val documentStatus = UploadManager.documentStatus
    val clientSignature = UploadManager.clientSignature

    // TODO: use fileIds to send completion report
    val fileIds = UploadManager.fileIds
    val fileNames = UploadManager.fileNames

    val showDocumentLoader: MutableState<Boolean> = mutableStateOf(false)
    val showSignatureLoader: MutableState<Boolean> = mutableStateOf(false)

    // Completion report
    val damageState = mutableStateOf(false)
    val damageTextState = mutableStateOf("")
    val correctFunctionalityState = mutableStateOf(false)
    val correctFunctionalityTextState = mutableStateOf("")
    val healthAndSafetyState = mutableStateOf(false)
    val healthAndSafetyTextState = mutableStateOf("")
    val additionalNotesText = mutableStateOf("")
    val clientFeedbackText = mutableStateOf("")
    val filesList = mutableStateListOf<String>()

    val showAreYouSurePopUpDeleteFile: MutableState<Boolean> = mutableStateOf(false)
    val selectedFileName = mutableStateOf<String?>(null)

    val showAreYouSurePopUpDeleteImage: MutableState<Boolean> = mutableStateOf(false)
    val selectedImageName = mutableStateOf<String?>(null)

    val selectedJob = mutableStateOf<Job?>(null)
    val contactSites = mutableStateListOf<Site>()
    var jobStatusMap: Map<String, String> = mutableMapOf()

    private val _completionReport = MutableStateFlow<CompletionReportResponse?>(null)
    val completionReport: StateFlow<CompletionReportResponse?> = _completionReport

    init {

    }

    fun loadJobDetails(jobId: String) {
        viewModelScope.launch {
            repository.getJobByIdFlow(jobId).collect {
                it?.let {
                    _jobDetails.value = it
                    _completionReport.value = Mapper().jsonAsObject<CompletionReportResponse>(it.completionReport)
                    it.contactParentId?.let { contactId ->
                        val result = repository.getContactById(contactId)
                        result?.id?.let { SiteRequest(it) }?.let { getSitesUnderContact(it) }
                        _contact.value = result
                    }
                }
            }
        }
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }
    }

    fun getSitesUnderContact(siteRequest: SiteRequest) {
        viewModelScope.launch {
            val result = repository.getSitesUnderContact(siteRequest)
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                contactSites.clear()
                contactSites.addAll(result.data ?: emptyList())
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }
    }

    fun submitCompletionReport(id: String, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val files = fileIds.value
            val result = repository.submitCompletionReport(
                CompletionReportRequest(
                    damageState.value,
                    correctFunctionalityState.value,
                    additionalNotesText.value,
                    healthAndSafetyState.value,
                    clientSignature.value,
                    clientFeedbackText.value,
                    files = files,
                    id,
                    repository.getWorkspaceId(),
                    damageTextState.value,
                    correctFunctionalityTextState.value,
                    healthAndSafetyTextState.value
                )
            )

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun finishJob(id: String, status: String, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.finishJob(FinishJobRequest(id, status))

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun updateJob(id: String, job: JobRequest, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            when (val result = repository.updateJob(id, job)) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun updateJobSteps(id: String, steps: List<Step>, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            when (val result = repository.updateJobSteps(id, steps)) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun downloadFile(fileObject: FileObject) {
        showLoader.value = true
        viewModelScope.launch {
            val fileId = fileObject.documentFileId ?: return@launch
            val existingFilePath = repository.getSavedFilePath(fileId)
            if (!existingFilePath.isNullOrBlank()) {
                log.d("downloadFile") { "File already exists: $existingFilePath" }

                when (FileUtil().getFileType(fileId)) {
                    FileType.Image -> {
                        val painter = repository.getImage(fileId)
                        if (painter != null) {
                            addImagePainter(fileObject, painter)
                        }
                    }

                    FileType.PDF -> {
                        addPdfFilePath(fileObject, existingFilePath)
                    }

                    else -> log.e { "Unsupported file type for existing file" }
                }

                showLoader.value = false
            } else {
                when (val result = repository.getFile(fileId)) {
                    is Resource.Success -> {
                        val fileType = result.data?.fileType
                        when (fileType) {
                            FileType.Image -> {
                                // val painter = repository.getImage(fileId)
                                val painter = repository.getImageFromByteArray(result.data.fileData)
                                painter?.let { addImagePainter(fileObject, it) }
                            }

                            FileType.PDF -> {
                                repository.getSavedFilePath(fileId)?.let {
                                    addPdfFilePath(fileObject, it)
                                }
                            }

                            else -> log.e { "Unsupported file type" }
                        }
                    }

                    is Resource.Error -> {
                        errorText.value = "An error occurred while loading files"
                        showPopUpError.value = true
                        showLoader.value = false
                        log.d("Download File Error") { "Error downloading file: ${result.text}" }
                    }
                }
                showLoader.value = false
            }
        }
    }

    fun deleteFileFromJob(jobId: String, fileId: String) {
        showLoader.value = true
        viewModelScope.launch {
            when (val result = repository.deleteFileFromJob(jobId, fileId)) {
                is Resource.Success -> {
                    showLoader.value = false
                    removePdfFileByName(fileId)
                    showFileDeleteSuccess.value = true
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showLoader.value = false
                    showPopUpError.value = true
                    log.d("Delete File Error") { "Error deleting file: ${result.text}" }
                }
            }
        }
    }

    fun deleteImage(jobId: String, imageName: String) {
        showLoader.value = true
        viewModelScope.launch {
            when (val result = repository.deleteFileFromJob(jobId, imageName)) {
                is Resource.Success -> {
                    showLoader.value = false
                    removeImagePainter(imageName)
                    showFileDeleteSuccess.value = true
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showLoader.value = false
                    showPopUpError.value = true
                    log.d("Delete File Error") { "Error deleting image: ${result.text}" }
                }
            }
        }
    }

    fun deleteUploadedFile(fileName: String) {
        viewModelScope.launch {
            showLoader.value = true
            when (val result = repository.deleteUploadedFile(fileName)) {
                is Resource.Success -> {
                    UploadManager.removeFileByName(fileName)
                    showLoader.value = false
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showLoader.value = false
                    showPopUpError.value = true
                }
            }
        }
    }

    fun addImagePainter(fileObject: FileObject, painter: BitmapPainter) {
        if (!_imagePainters.value.any { it.first.documentFileId == fileObject.documentFileId }) {
            _imagePainters.value += fileObject to painter
        }
    }

    fun removeImagePainter(fileId: String) {
        _imagePainters.value = _imagePainters.value.filterNot { it.first.documentFileId == fileId }
    }

    fun addPdfFilePath(fileObject: FileObject, filePath: String) {
        if (!_pdfFilePaths.value.any { it.first.documentFileId == fileObject.documentFileId }) {
            _pdfFilePaths.value += fileObject to filePath
        }
    }

    fun removePdfFileByName(fileName: String) {
        _pdfFilePaths.value =
            _pdfFilePaths.value.filterNot { it.second.substringAfterLast("/") == fileName }
    }

    fun downloadCompletionReportFile(fileId: String, onDownloaded: (BitmapPainter) -> Unit) {
        showLoader.value = true
        viewModelScope.launch {
            val existingFilePath = repository.getSavedFilePath(fileId)
            if (!existingFilePath.isNullOrBlank()) {
                log.d("downloadFile") { "File already exists: $existingFilePath" }

                when (FileUtil().getFileType(fileId)) {
                    FileType.Image -> {
                        val painter = repository.getImage(fileId)
                        if (painter != null) {
                            onDownloaded(painter)
//                            addImagePainterCompletionReport(fileId to painter)
                        }
                    }

                    else -> log.e { "Unsupported file type for existing file" }
                }

                showLoader.value = false
            } else {
                when (val result = repository.getFile(fileId)) {
                    is Resource.Success -> {
                        val fileType = result.data?.fileType
                        when (fileType) {
                            FileType.Image -> {
                                val painter = repository.getImageFromByteArray(result.data.fileData)
                                painter?.let {
                                    onDownloaded(it)
//                                    addImagePainterCompletionReport(fileId to it)
                                }
                            }

                            else -> log.e { "Unsupported file type" }
                        }
                    }

                    is Resource.Error -> {
                        errorText.value = "An error occurred while loading files"
                        showPopUpError.value = true
                        showLoader.value = false
                        log.d("Download File Error") { "Error downloading file: ${result.text}" }
                    }
                }
                showLoader.value = false
            }
        }
    }

    fun addImagePainterCompletionReport(pair: Pair<String, BitmapPainter>) {
        _completionReportImagePainters.value = _completionReportImagePainters.value.toMutableList().apply {
            if (none { it.first == pair.first }) add(pair)
        }
    }

    fun removeImagePainterCompletionReport(imageName: String) {
        _completionReportImagePainters.value =
            _completionReportImagePainters.value.filterNot { it.first == imageName }
    }

    fun addImagePainterClientSignature(pair: Pair<String, BitmapPainter>) {
        _clientSignaturePainters.value = _clientSignaturePainters.value.toMutableList().apply {
            if (none { it.first == pair.first }) add(pair)
        }
    }

    fun removeImagePainterClientSignature(imageName: String) {
        _clientSignaturePainters.value =
            _clientSignaturePainters.value.filterNot { it.first == imageName }
    }

    fun getUser(): User? {
        return repository.getUser()
    }

    fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

    fun isUserAssignedToJob(job: Job?): Boolean {
        if (job == null)
            return false
        val user = getUser()
        val userId = user?._id ?: return false
        val isEmployee = job.assignees?.employees?.any { it?._id == userId } == true

        // Check if any of the user's team IDs match the job's team IDs
        val userTeamIds = user.teams?.mapNotNull { it?.id }?.toSet() ?: emptySet()
        val jobTeamIds = job.assignees?.teams?.mapNotNull { it?._id }?.toSet() ?: emptySet()
        val isInTeam = userTeamIds.intersect(jobTeamIds).isNotEmpty()

        return isEmployee || isInTeam
    }

    fun getJobReportByJobId(jobId: String) {
        viewModelScope.launch {
            when (val result = repository.getJobReportByJobId(jobId)) {
                is Resource.Success -> {
                    _completionReport.value = result.data
                }
                is Resource.Error -> {

                }
            }
        }
    }

    fun clearCompletionReportValue() {
        _completionReport.value = null
    }
}