package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewModelScope
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_employees
import com.hyperether.goodjob.resources.all
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employee_deleted
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.no_employees_added
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.resources.user
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.SearchField
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_EMPLOYEE_ID
import com.hyperether.goodjob.util.FilterDialogStateManager
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmployeesScreen(
    isMobileView: Boolean,
    employeesViewModel: EmployeesViewModel,
    navController: NavHostController,
    prefsManager: PrefsManager,
    drawerState: DrawerState
) {

    val isAllSelected = remember { mutableStateOf(true) }
    val hideEmptyTeamsView = remember { mutableStateOf(false) }
    val isInEditMode = remember { mutableStateOf(false) }
    val isInSearchMode = remember { mutableStateOf(false) }
    val searchQuery = remember { mutableStateOf("") }
    val employees = remember { mutableStateListOf<User>() }

    val selectedEmployees = remember { mutableStateListOf<User>() }
    val filteredEmployees = remember { mutableStateListOf<User>() }
    val showPopup = remember { mutableStateOf(false) }
    val teams by employeesViewModel.teams.collectAsState(emptyList())
    val selectedTeams = remember { mutableStateOf(mapOf<String, Boolean>()) }

    LaunchedEffect(Unit) {
        if (!getPlatformChecker().isMobile()) {
            employeesViewModel.init()
        }
        FilterDialogStateManager.setDialogShowing(false)
        employeesViewModel.getAllUsersRemote()
        employeesViewModel.getTeams()
        employeesViewModel.users.collect { employeeList ->
            employees.clear()
            employees.addAll(employeeList)
            filteredEmployees.clear()
            filteredEmployees.addAll(employeeList)
        }
    }

    if (isMobileView) {
        Box(modifier = Modifier.fillMaxSize()) {
            Column {
                MobileHeaderSearchAdd(
                    title = stringResource(Res.string.employees),
                    drawerState = drawerState,
                    isInDeleteMode = !isAllSelected.value,
                    onDeleteClick = {
                        employeesViewModel.showLoader.value = true
                        val selectedTeamIds = selectedTeams.value.filterValues { it }.keys
                        val selectedTeamList = teams.filter { it._id in selectedTeamIds }
                        employeesViewModel.deleteSelectedTeams(
                            selectedTeamList,
                            onAllDeleted = {
                                employeesViewModel.showLoader.value = false
                            },
                            onError = {
                                employeesViewModel.showLoader.value = false
                            }
                        )
                    },
                    scope = rememberCoroutineScope(),
                    add = {
                        if (getPlatformChecker().isMobile()) {
                            navController.navigate(Screen.AddNewEmployee.route + "/$DEFAULT_EMPLOYEE_ID")
                        } else {
                            navController.navigate(Screen.AddNewEmployee.route + "?employeeId=")
                        }
                    },
                    search = {
                        isInSearchMode.value = true
                    },
                    onCancelSearch = {
                        isInSearchMode.value = false
                        searchQuery.value = ""
                    },
                    user = prefsManager.getUser()
                )

                if (isInSearchMode.value) {
                    Spacer(modifier = Modifier.height(15.dp))
                    SearchField(searchQuery)

                } else {
                    Column(
                        modifier = Modifier
                            .background(Color.LightGray.copy(alpha = 0.2f))
                            .padding(8.dp),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth().height(40.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceEvenly
                        ) {

                            Spacer(modifier = Modifier.weight(0.5f))
                            androidx.compose.material3.Text(
                                text = stringResource(Res.string.all),
                                color = if (isAllSelected.value) BlueApp else Color.Gray,
                                fontSize = 16.sp,
                                modifier = Modifier
                                    .clickable {
                                        isAllSelected.value = true
                                    }
                            )

                            Spacer(modifier = Modifier.weight(1f))

                            androidx.compose.material3.Text(
                                text = stringResource(Res.string.teams),
                                color = if (isAllSelected.value) Color.Gray else BlueApp,
                                fontSize = 16.sp,
                                modifier = Modifier
                                    .clickable {
                                        isAllSelected.value = false
                                        selectedEmployees.clear()
                                    }
                            )
                            Spacer(modifier = Modifier.weight(0.5f))

                        }
                    }
                    Box(
                        modifier = Modifier
                            .height(2.dp)
                            .fillMaxWidth(0.5f)
                            .background(if (isAllSelected.value) BlueApp else BlueApp)
                            .align(if (isAllSelected.value) Alignment.Start else Alignment.End)
                    )
                }
                if (isAllSelected.value) {
                    if (selectedEmployees.isEmpty()) {
                        if (!isInSearchMode.value) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(horizontal = 16.dp, vertical = 10.dp)
                            ) {
                                if (!isInEditMode.value) {
                                    Text(
                                        text = stringResource(Res.string.edit),
                                        fontSize = 14.sp,
                                        color = TextColor,
                                        modifier = Modifier
                                            .clickable {
                                                isInEditMode.value = true
                                                showPopup.value = true
                                            }
                                            .weight(1f),
                                        textAlign = TextAlign.Start
                                    )
                                } else {
                                    Text(
                                        text = stringResource(Res.string.cancel),
                                        fontSize = 14.sp,
                                        color = TextColor,
                                        modifier = Modifier
                                            .clickable {
                                                isInEditMode.value = false
                                                showPopup.value = false
                                            }
                                            .weight(1f),
                                        textAlign = TextAlign.Start
                                    )
                                }

                                if (!isInEditMode.value) {
                                    val leadingText =
                                        if (filteredEmployees.size == 1) stringResource(Res.string.user).capitalize() else stringResource(
                                            Res.string.employees
                                        )
                                    Text(
                                        text = "${stringResource(Res.string.total)}: ${filteredEmployees.size} $leadingText",
                                        fontSize = 14.sp,
                                        color = Color.Black,
                                        maxLines = 1,
                                        fontStyle = FontStyle.Italic,
                                        textAlign = TextAlign.Center
                                    )
                                }

                                Row(
                                    horizontalArrangement = Arrangement.End,
                                    verticalAlignment = Alignment.CenterVertically,
                                    modifier = Modifier.weight(1f)
                                ) {
                                    Row(
                                        modifier = Modifier.clickable {
                                            FilterDialogStateManager.setDialogShowing(true)
                                        },
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Text(
                                            text = stringResource(Res.string.filter),
                                            fontSize = 14.sp,
                                            color = TextColor,
                                            modifier = Modifier.padding(end = 8.dp),
                                            textAlign = TextAlign.End
                                        )
                                        Icon(
                                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                                            contentDescription = null,
                                            modifier = Modifier.size(20.dp)
                                        )
                                    }
                                }
                            }
                        }
                        Divider(
                            modifier = Modifier.height(2.dp)
                                .background(Color.LightGray.copy(alpha = 0.1f))
                        )
                    }
                    if (filteredEmployees.isEmpty()) {
                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.no_employees_added),
                            color = Color.Black,
                            fontSize = 24.sp,
                            textAlign = TextAlign.Center,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier.fillMaxWidth()
                                .padding(top = 60.dp, start = 16.dp, end = 16.dp)
                        )
                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.add_employees),
                            color = Color.Gray,
                            textAlign = TextAlign.Center,
                            fontSize = 16.sp,
                            modifier = Modifier.padding(top = 20.dp, start = 16.dp, end = 16.dp)
                        )
                    } else {
                        val filteredUsersSearch = if (searchQuery.value.isNotBlank()) {
                            filteredEmployees.filter { user ->
                                user.getName().contains(
                                    searchQuery.value,
                                    ignoreCase = true
                                )
                            }
                        } else {
                            filteredEmployees
                        }
                        //change with employee id
                        EmployeesList(
                            employees = filteredUsersSearch,
                            isInEditMode = isInEditMode.value,
                            selectedEmployees = selectedEmployees,
                            navHostController = navController
                        )
                    }
                } else {
                    Spacer(modifier = Modifier.height(15.dp))
                    if (teams.isEmpty() && !hideEmptyTeamsView.value) {
                        EmptyTeamsView(showAddTeam = {
                            hideEmptyTeamsView.value = true
                        })
                    } else {
                        TeamsView(
                            teams = teams,
                            selectedTeams = selectedTeams,
                            onCheckedChange = { id, isChecked ->
                                val updatedSelection = selectedTeams.value.toMutableMap()
                                updatedSelection[id] = isChecked
                                selectedTeams.value = updatedSelection
                            },
                            viewModel = employeesViewModel
                        )
                    }
                    if (hideEmptyTeamsView.value) {
                        TeamsView(
                            teams = teams,
                            selectedTeams = selectedTeams,
                            onCheckedChange = { id, isChecked ->
                                val updatedSelection = selectedTeams.value.toMutableMap()
                                updatedSelection[id] = isChecked
                                selectedTeams.value = updatedSelection
                            },
                            viewModel = employeesViewModel
                        )
                    }
                }
            }
            if (showPopup.value) {
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .background(Color.Black.copy(alpha = 0.05f))
                )

                Box(
                    modifier = Modifier
                        .align(Alignment.BottomEnd)
                ) {
                    EditDialog(
                        showDialog = showPopup.value,
                        employees = selectedEmployees,
                        onDelete = {
                            if (selectedEmployees.isNotEmpty()) {
                                employeesViewModel.showLoader.value = true
                                employeesViewModel.deleteSelectedUsers(selectedEmployees,
                                    onAllDeleted = {
                                        employeesViewModel.showPopUpSuccess.value = true
                                        employeesViewModel.showLoader.value = false
                                        selectedEmployees.clear()

                                        employeesViewModel.viewModelScope.launch {
                                            employeesViewModel.users.collect { employeeList ->
                                                employees.clear()
                                                employees.addAll(employeeList)
                                            }
                                        }
                                    },
                                    onError = {
                                        employeesViewModel.showPopUpError.value = true
                                        employeesViewModel.showLoader.value = false
                                    })
                            }
                        },
                        onExportList = {},
                        onImportList = {}
                    )
                }
            }
            if (employeesViewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { employeesViewModel.showPopUpSuccess.value = false },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.employee_deleted),
                    onPositiveBtnClicked = { employeesViewModel.showPopUpSuccess.value = false },
                    positiveBtnText = stringResource(Res.string.ok)
                )
            }
            if (employeesViewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { employeesViewModel.showPopUpError.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = employeesViewModel.errorText.value
                )
            }
            if (employeesViewModel.showLoader.value) {
                FullScreenProgressBar()
            }
            if (FilterDialogStateManager.dialogShowing.value) {
                FilterEmployeesDialog(
                    employees.mapNotNull { it.city?.trim() }.filter { it.isNotBlank() }.distinct(),
                    employees.mapNotNull { it.role }.filter { it.isNotBlank() }.distinct(),
                    employees.mapNotNull { it.teams }.flatMap { teamList ->
                        teamList.mapNotNull { team ->
                            team?.value?.takeIf { it.isNotBlank() }
                        }
                    }.distinct(),
                    employees.mapNotNull { it.status }.filter { it.isNotBlank() }.distinct(),
                    employees.flatMap { it.skills ?: emptyList() }.distinct(),
                    employeesViewModel,
                    onDismiss = {
                        FilterDialogStateManager.setDialogShowing(false)
                    },
                    onApply = { selectedCities, selectedRoles, selectedTeams, selectedStatuses, selectedSkills ->
                        FilterDialogStateManager.setDialogShowing(false)
                        employeesViewModel.selectedCities.value = selectedCities
                        employeesViewModel.selectedRoles.value = selectedRoles
                        employeesViewModel.selectedStatus.value = selectedStatuses
                        employeesViewModel.selectedTeams.value = selectedTeams
                        employeesViewModel.selectedSkills.value = selectedSkills

                        val filteredList = employeesViewModel.filterContacts(employees)
                        filteredEmployees.clear()
                        filteredEmployees.addAll(filteredList)
                    }
                )
            }
        }
    } else {
        EmployeesWebView(
            employeesViewModel = employeesViewModel,
            navController = navController
        )
    }
}