package com.hyperether.goodjob.repository.remote.model

import com.hyperether.goodjob.models.Step
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class JobStepsUpdateRequest(
    @SerialName("jobId") var jobId: String? = null,
    @SerialName("steps") var steps: List<Step?> = emptyList(),
)
