@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.hyperether.goodjob.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val about_contact: StringResource by 
      lazy { init_about_contact() }

  public val about_job: StringResource by 
      lazy { init_about_job() }

  public val access_dashboard: StringResource by 
      lazy { init_access_dashboard() }

  public val active: StringResource by 
      lazy { init_active() }

  public val add: StringResource by 
      lazy { init_add() }

  public val add_client: StringResource by 
      lazy { init_add_client() }

  public val add_new: StringResource by 
      lazy { init_add_new() }

  public val add_new_contact: StringResource by 
      lazy { init_add_new_contact() }

  public val add_new_employee: StringResource by 
      lazy { init_add_new_employee() }

  public val add_new_job: StringResource by 
      lazy { init_add_new_job() }

  public val add_new_workspace: StringResource by 
      lazy { init_add_new_workspace() }

  public val add_notes: StringResource by 
      lazy { init_add_notes() }

  public val add_steps: StringResource by 
      lazy { init_add_steps() }

  public val address: StringResource by 
      lazy { init_address() }

  public val all: StringResource by 
      lazy { init_all() }

  public val all_employees: StringResource by 
      lazy { init_all_employees() }

  public val all_rights_reserved: StringResource by 
      lazy { init_all_rights_reserved() }

  public val already_have_an_account: StringResource by 
      lazy { init_already_have_an_account() }

  public val already_have_workspace: StringResource by 
      lazy { init_already_have_workspace() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val assign_employee: StringResource by 
      lazy { init_assign_employee() }

  public val back: StringResource by 
      lazy { init_back() }

  public val built_for: StringResource by 
      lazy { init_built_for() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val choose_category: StringResource by 
      lazy { init_choose_category() }

  public val choose_plan: StringResource by 
      lazy { init_choose_plan() }

  public val choose_team: StringResource by 
      lazy { init_choose_team() }

  public val choose_the_plan: StringResource by 
      lazy { init_choose_the_plan() }

  public val choose_the_right_plan: StringResource by 
      lazy { init_choose_the_right_plan() }

  public val choose_your_workspace: StringResource by 
      lazy { init_choose_your_workspace() }

  public val city: StringResource by 
      lazy { init_city() }

  public val click_here: StringResource by 
      lazy { init_click_here() }

  public val company: StringResource by 
      lazy { init_company() }

  public val contact_information: StringResource by 
      lazy { init_contact_information() }

  public val contact_name: StringResource by 
      lazy { init_contact_name() }

  public val contact_person: StringResource by 
      lazy { init_contact_person() }

  public val contact_site: StringResource by 
      lazy { init_contact_site() }

  public val contact_type: StringResource by 
      lazy { init_contact_type() }

  public val contact_us: StringResource by 
      lazy { init_contact_us() }

  public val contacts: StringResource by 
      lazy { init_contacts() }

  public val continue_with_apple: StringResource by 
      lazy { init_continue_with_apple() }

  public val continue_with_fb: StringResource by 
      lazy { init_continue_with_fb() }

  public val continue_with_google: StringResource by 
      lazy { init_continue_with_google() }

  public val continue_with_next_step: StringResource by 
      lazy { init_continue_with_next_step() }

  public val create: StringResource by 
      lazy { init_create() }

  public val create_new_task: StringResource by 
      lazy { init_create_new_task() }

  public val create_workspace: StringResource by 
      lazy { init_create_workspace() }

  public val custom: StringResource by 
      lazy { init_custom() }

  public val daily_activities: StringResource by 
      lazy { init_daily_activities() }

  public val dashboard: StringResource by 
      lazy { init_dashboard() }

  public val date: StringResource by 
      lazy { init_date() }

  public val day_free_trial: StringResource by 
      lazy { init_day_free_trial() }

  public val dedicated_support: StringResource by 
      lazy { init_dedicated_support() }

  public val delete: StringResource by 
      lazy { init_delete() }

  public val describe_the_process: StringResource by 
      lazy { init_describe_the_process() }

  public val details: StringResource by 
      lazy { init_details() }

  public val due_date: StringResource by 
      lazy { init_due_date() }

  public val edit: StringResource by 
      lazy { init_edit() }

  public val effortless_efficiency: StringResource by 
      lazy { init_effortless_efficiency() }

  public val email: StringResource by 
      lazy { init_email() }

  public val email_address: StringResource by 
      lazy { init_email_address() }

  public val employee_details: StringResource by 
      lazy { init_employee_details() }

  public val employees: StringResource by 
      lazy { init_employees() }

  public val employment_details: StringResource by 
      lazy { init_employment_details() }

  public val end_at: StringResource by 
      lazy { init_end_at() }

  public val enter_building_number: StringResource by 
      lazy { init_enter_building_number() }

  public val enter_contact_name: StringResource by 
      lazy { init_enter_contact_name() }

  public val enter_email_address: StringResource by 
      lazy { init_enter_email_address() }

  public val enter_employee_email: StringResource by 
      lazy { init_enter_employee_email() }

  public val enter_full_name: StringResource by 
      lazy { init_enter_full_name() }

  public val enter_job_title: StringResource by 
      lazy { init_enter_job_title() }

  public val enter_step_title: StringResource by 
      lazy { init_enter_step_title() }

  public val enter_street: StringResource by 
      lazy { init_enter_street() }

  public val enter_surname: StringResource by 
      lazy { init_enter_surname() }

  public val enter_time: StringResource by 
      lazy { init_enter_time() }

  public val enter_workspace_name: StringResource by 
      lazy { init_enter_workspace_name() }

  public val enter_your_email: StringResource by 
      lazy { init_enter_your_email() }

  public val enter_zip: StringResource by 
      lazy { init_enter_zip() }

  public val error: StringResource by 
      lazy { init_error() }

  public val error_message: StringResource by 
      lazy { init_error_message() }

  public val estimated_duration: StringResource by 
      lazy { init_estimated_duration() }

  public val every_2_weeks: StringResource by 
      lazy { init_every_2_weeks() }

  public val every_day: StringResource by 
      lazy { init_every_day() }

  public val every_month: StringResource by 
      lazy { init_every_month() }

  public val every_week: StringResource by 
      lazy { init_every_week() }

  public val expand_your_team: StringResource by 
      lazy { init_expand_your_team() }

  public val export_list: StringResource by 
      lazy { init_export_list() }

  public val fast_track: StringResource by 
      lazy { init_fast_track() }

  public val fast_track_support: StringResource by 
      lazy { init_fast_track_support() }

  public val files: StringResource by 
      lazy { init_files() }

  public val filter: StringResource by 
      lazy { init_filter() }

  public val fly_high: StringResource by 
      lazy { init_fly_high() }

  public val fly_high_description: StringResource by 
      lazy { init_fly_high_description() }

  public val fly_high_feature_1: StringResource by 
      lazy { init_fly_high_feature_1() }

  public val fly_high_feature_2: StringResource by 
      lazy { init_fly_high_feature_2() }

  public val fly_high_plan_description: StringResource by 
      lazy { init_fly_high_plan_description() }

  public val fly_high_price: StringResource by 
      lazy { init_fly_high_price() }

  public val forgot_password: StringResource by 
      lazy { init_forgot_password() }

  public val free: StringResource by 
      lazy { init_free() }

  public val free_100: StringResource by 
      lazy { init_free_100() }

  public val freebie: StringResource by 
      lazy { init_freebie() }

  public val freebie_description: StringResource by 
      lazy { init_freebie_description() }

  public val freebie_feature_1: StringResource by 
      lazy { init_freebie_feature_1() }

  public val freebie_feature_2: StringResource by 
      lazy { init_freebie_feature_2() }

  public val freebie_plan_description: StringResource by 
      lazy { init_freebie_plan_description() }

  public val freebie_price: StringResource by 
      lazy { init_freebie_price() }

  public val from_small_teams: StringResource by 
      lazy { init_from_small_teams() }

  public val full_name: StringResource by 
      lazy { init_full_name() }

  public val full_name_contact_person: StringResource by 
      lazy { init_full_name_contact_person() }

  public val galactic: StringResource by 
      lazy { init_galactic() }

  public val galactic_description: StringResource by 
      lazy { init_galactic_description() }

  public val galactic_feature_1: StringResource by 
      lazy { init_galactic_feature_1() }

  public val galactic_feature_2: StringResource by 
      lazy { init_galactic_feature_2() }

  public val galactic_plan_description: StringResource by 
      lazy { init_galactic_plan_description() }

  public val galactic_price: StringResource by 
      lazy { init_galactic_price() }

  public val hello: StringResource by 
      lazy { init_hello() }

  public val high_five: StringResource by 
      lazy { init_high_five() }

  public val high_five_description: StringResource by 
      lazy { init_high_five_description() }

  public val high_five_feature_1: StringResource by 
      lazy { init_high_five_feature_1() }

  public val high_five_feature_2: StringResource by 
      lazy { init_high_five_feature_2() }

  public val high_five_plan_description: StringResource by 
      lazy { init_high_five_plan_description() }

  public val high_five_price: StringResource by 
      lazy { init_high_five_price() }

  public val hour: StringResource by 
      lazy { init_hour() }

  public val import_list: StringResource by 
      lazy { init_import_list() }

  public val inactive: StringResource by 
      lazy { init_inactive() }

  public val industryEmail: StringResource by 
      lazy { init_industryEmail() }

  public val job_details: StringResource by 
      lazy { init_job_details() }

  public val job_duration: StringResource by 
      lazy { init_job_duration() }

  public val job_status: StringResource by 
      lazy { init_job_status() }

  public val job_title: StringResource by 
      lazy { init_job_title() }

  public val jobs: StringResource by 
      lazy { init_jobs() }

  public val jobs_overview: StringResource by 
      lazy { init_jobs_overview() }

  public val join_good_job: StringResource by 
      lazy { init_join_good_job() }

  public val join_us: StringResource by 
      lazy { init_join_us() }

  public val last_accessed: StringResource by 
      lazy { init_last_accessed() }

  public val leave_details: StringResource by 
      lazy { init_leave_details() }

  public val login_register: StringResource by 
      lazy { init_login_register() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val manage_employees: StringResource by 
      lazy { init_manage_employees() }

  public val manager: StringResource by 
      lazy { init_manager() }

  public val max_size: StringResource by 
      lazy { init_max_size() }

  public val minute: StringResource by 
      lazy { init_minute() }

  public val month: StringResource by 
      lazy { init_month() }

  public val most_popular: StringResource by 
      lazy { init_most_popular() }

  public val name: StringResource by 
      lazy { init_name() }

  public val need_an_account: StringResource by 
      lazy { init_need_an_account() }

  public val never: StringResource by 
      lazy { init_never() }

  public val new_workspace: StringResource by 
      lazy { init_new_workspace() }

  public val next: StringResource by 
      lazy { init_next() }

  public val ok: StringResource by 
      lazy { init_ok() }

  public val onboarding_description1: StringResource by 
      lazy { init_onboarding_description1() }

  public val onboarding_description2: StringResource by 
      lazy { init_onboarding_description2() }

  public val only_support: StringResource by 
      lazy { init_only_support() }

  public val optimize_your: StringResource by 
      lazy { init_optimize_your() }

  public val password: StringResource by 
      lazy { init_password() }

  public val phone: StringResource by 
      lazy { init_phone() }

  public val phone_number: StringResource by 
      lazy { init_phone_number() }

  public val places: StringResource by 
      lazy { init_places() }

  public val plan: StringResource by 
      lazy { init_plan() }

  public val plan_and_assign: StringResource by 
      lazy { init_plan_and_assign() }

  public val powerful_tools: StringResource by 
      lazy { init_powerful_tools() }

  public val pricing: StringResource by 
      lazy { init_pricing() }

  public val priority_support: StringResource by 
      lazy { init_priority_support() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val provide_information: StringResource by 
      lazy { init_provide_information() }

  public val ready_for: StringResource by 
      lazy { init_ready_for() }

  public val register: StringResource by 
      lazy { init_register() }

  public val register_with: StringResource by 
      lazy { init_register_with() }

  public val repeat: StringResource by 
      lazy { init_repeat() }

  public val role: StringResource by 
      lazy { init_role() }

  public val scheduler: StringResource by 
      lazy { init_scheduler() }

  public val search: StringResource by 
      lazy { init_search() }

  public val select_dates: StringResource by 
      lazy { init_select_dates() }

  public val select_employee: StringResource by 
      lazy { init_select_employee() }

  public val select_job_status: StringResource by 
      lazy { init_select_job_status() }

  public val select_skill: StringResource by 
      lazy { init_select_skill() }

  public val select_team: StringResource by 
      lazy { init_select_team() }

  public val select_your_workspace: StringResource by 
      lazy { init_select_your_workspace() }

  public val set_up_workspace: StringResource by 
      lazy { init_set_up_workspace() }

  public val set_weekly_hours: StringResource by 
      lazy { init_set_weekly_hours() }

  public val settings: StringResource by 
      lazy { init_settings() }

  public val sign_in: StringResource by 
      lazy { init_sign_in() }

  public val sign_in_with: StringResource by 
      lazy { init_sign_in_with() }

  public val site: StringResource by 
      lazy { init_site() }

  public val skills: StringResource by 
      lazy { init_skills() }

  public val skip: StringResource by 
      lazy { init_skip() }

  public val start_at: StringResource by 
      lazy { init_start_at() }

  public val start_organizing: StringResource by 
      lazy { init_start_organizing() }

  public val start_typing_address: StringResource by 
      lazy { init_start_typing_address() }

  public val status: StringResource by 
      lazy { init_status() }

  public val step: StringResource by 
      lazy { init_step() }

  public val step_description: StringResource by 
      lazy { init_step_description() }

  public val step_title: StringResource by 
      lazy { init_step_title() }

  public val steps: StringResource by 
      lazy { init_steps() }

  public val subscribe: StringResource by 
      lazy { init_subscribe() }

  public val support: StringResource by 
      lazy { init_support() }

  public val tap_to_upload_photo: StringResource by 
      lazy { init_tap_to_upload_photo() }

  public val team: StringResource by 
      lazy { init_team() }

  public val teams: StringResource by 
      lazy { init_teams() }

  public val terms_of_service: StringResource by 
      lazy { init_terms_of_service() }

  public val today: StringResource by 
      lazy { init_today() }

  public val today_schedule: StringResource by 
      lazy { init_today_schedule() }

  public val total: StringResource by 
      lazy { init_total() }

  public val transform: StringResource by 
      lazy { init_transform() }

  public val transparent_flexible: StringResource by 
      lazy { init_transparent_flexible() }

  public val unlimited_storage: StringResource by 
      lazy { init_unlimited_storage() }

  public val unselect_all: StringResource by 
      lazy { init_unselect_all() }

  public val upcoming_leaves: StringResource by 
      lazy { init_upcoming_leaves() }

  public val upload_documents: StringResource by 
      lazy { init_upload_documents() }

  public val upload_profile_picture: StringResource by 
      lazy { init_upload_profile_picture() }

  public val use_contact_name: StringResource by 
      lazy { init_use_contact_name() }

  public val user: StringResource by 
      lazy { init_user() }

  public val users: StringResource by 
      lazy { init_users() }

  public val welcome: StringResource by 
      lazy { init_welcome() }

  public val welcome_back: StringResource by 
      lazy { init_welcome_back() }

  public val whether: StringResource by 
      lazy { init_whether() }

  public val work_schedule: StringResource by 
      lazy { init_work_schedule() }

  public val workspace_name: StringResource by 
      lazy { init_workspace_name() }

  public val workspace_type: StringResource by 
      lazy { init_workspace_type() }

  public val your_email_address: StringResource by 
      lazy { init_your_email_address() }

  public val your_full_name: StringResource by 
      lazy { init_your_full_name() }

  public val your_password: StringResource by 
      lazy { init_your_password() }

  public val zip: StringResource by 
      lazy { init_zip() }
}

public val Res.string.about_contact: StringResource
  get() = CommonMainString0.about_contact

private fun init_about_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about_contact", "about_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10, 41),
    )
)

public val Res.string.about_job: StringResource
  get() = CommonMainString0.about_job

private fun init_about_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about_job", "about_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 52, 29),
    )
)

public val Res.string.access_dashboard: StringResource
  get() = CommonMainString0.access_dashboard

private fun init_access_dashboard(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:access_dashboard", "access_dashboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 82, 48),
    )
)

public val Res.string.active: StringResource
  get() = CommonMainString0.active

private fun init_active(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:active", "active",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 131, 22),
    )
)

public val Res.string.add: StringResource
  get() = CommonMainString0.add

private fun init_add(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add", "add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 548, 15),
    )
)

public val Res.string.add_client: StringResource
  get() = CommonMainString0.add_client

private fun init_add_client(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_client", "add_client",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 154, 98),
    )
)

public val Res.string.add_new: StringResource
  get() = CommonMainString0.add_new

private fun init_add_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new", "add_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 432, 27),
    )
)

public val Res.string.add_new_contact: StringResource
  get() = CommonMainString0.add_new_contact

private fun init_add_new_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new_contact", "add_new_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 253, 43),
    )
)

public val Res.string.add_new_employee: StringResource
  get() = CommonMainString0.add_new_employee

private fun init_add_new_employee(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_new_employee", "add_new_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 297, 48),
    )
)

public val Res.string.add_new_job: StringResource
  get() = CommonMainString0.add_new_job

private fun init_add_new_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new_job", "add_new_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 346, 35),
    )
)

public val Res.string.add_new_workspace: StringResource
  get() = CommonMainString0.add_new_workspace

private fun init_add_new_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_new_workspace", "add_new_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 382, 49),
    )
)

public val Res.string.add_notes: StringResource
  get() = CommonMainString0.add_notes

private fun init_add_notes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_notes", "add_notes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 460, 29),
    )
)

public val Res.string.add_steps: StringResource
  get() = CommonMainString0.add_steps

private fun init_add_steps(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_steps", "add_steps",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 490, 29),
    )
)

public val Res.string.address: StringResource
  get() = CommonMainString0.address

private fun init_address(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:address", "address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 520, 27),
    )
)

public val Res.string.all: StringResource
  get() = CommonMainString0.all

private fun init_all(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:all", "all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 722, 15),
    )
)

public val Res.string.all_employees: StringResource
  get() = CommonMainString0.all_employees

private fun init_all_employees(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:all_employees", "all_employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 564, 41),
    )
)

public val Res.string.all_rights_reserved: StringResource
  get() = CommonMainString0.all_rights_reserved

private fun init_all_rights_reserved(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:all_rights_reserved", "all_rights_reserved",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 606, 115),
    )
)

public val Res.string.already_have_an_account: StringResource
  get() = CommonMainString0.already_have_an_account

private fun init_already_have_an_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_an_account", "already_have_an_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 738, 75),
    )
)

public val Res.string.already_have_workspace: StringResource
  get() = CommonMainString0.already_have_workspace

private fun init_already_have_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_workspace", "already_have_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 814, 66),
    )
)

public val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 881, 28),
    )
)

public val Res.string.assign_employee: StringResource
  get() = CommonMainString0.assign_employee

private fun init_assign_employee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:assign_employee", "assign_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 910, 43),
    )
)

public val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 954, 20),
    )
)

public val Res.string.built_for: StringResource
  get() = CommonMainString0.built_for

private fun init_built_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:built_for", "built_for",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 975, 125),
    )
)

public val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1101, 22),
    )
)

public val Res.string.choose_category: StringResource
  get() = CommonMainString0.choose_category

private fun init_choose_category(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_category", "choose_category",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1124, 51),
    )
)

public val Res.string.choose_plan: StringResource
  get() = CommonMainString0.choose_plan

private fun init_choose_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_plan", "choose_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1176, 35),
    )
)

public val Res.string.choose_team: StringResource
  get() = CommonMainString0.choose_team

private fun init_choose_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_team", "choose_team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1212, 39),
    )
)

public val Res.string.choose_the_plan: StringResource
  get() = CommonMainString0.choose_the_plan

private fun init_choose_the_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_the_plan", "choose_the_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1252, 87),
    )
)

public val Res.string.choose_the_right_plan: StringResource
  get() = CommonMainString0.choose_the_right_plan

private fun init_choose_the_right_plan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:choose_the_right_plan", "choose_the_right_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1340, 69),
    )
)

public val Res.string.choose_your_workspace: StringResource
  get() = CommonMainString0.choose_your_workspace

private fun init_choose_your_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:choose_your_workspace", "choose_your_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1410, 89),
    )
)

public val Res.string.city: StringResource
  get() = CommonMainString0.city

private fun init_city(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:city", "city",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1500, 20),
    )
)

public val Res.string.click_here: StringResource
  get() = CommonMainString0.click_here

private fun init_click_here(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:click_here", "click_here",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1521, 50),
    )
)

public val Res.string.company: StringResource
  get() = CommonMainString0.company

private fun init_company(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:company", "company",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1572, 27),
    )
)

public val Res.string.contact_information: StringResource
  get() = CommonMainString0.contact_information

private fun init_contact_information(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contact_information", "contact_information",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1600, 55),
    )
)

public val Res.string.contact_name: StringResource
  get() = CommonMainString0.contact_name

private fun init_contact_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_name", "contact_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1656, 36),
    )
)

public val Res.string.contact_person: StringResource
  get() = CommonMainString0.contact_person

private fun init_contact_person(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_person", "contact_person",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1693, 42),
    )
)

public val Res.string.contact_site: StringResource
  get() = CommonMainString0.contact_site

private fun init_contact_site(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_site", "contact_site",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1736, 36),
    )
)

public val Res.string.contact_type: StringResource
  get() = CommonMainString0.contact_type

private fun init_contact_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_type", "contact_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1773, 36),
    )
)

public val Res.string.contact_us: StringResource
  get() = CommonMainString0.contact_us

private fun init_contact_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_us", "contact_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1810, 34),
    )
)

public val Res.string.contacts: StringResource
  get() = CommonMainString0.contacts

private fun init_contacts(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contacts", "contacts",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1845, 28),
    )
)

public val Res.string.continue_with_apple: StringResource
  get() = CommonMainString0.continue_with_apple

private fun init_continue_with_apple(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_apple", "continue_with_apple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1874, 55),
    )
)

public val Res.string.continue_with_fb: StringResource
  get() = CommonMainString0.continue_with_fb

private fun init_continue_with_fb(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_fb", "continue_with_fb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1930, 56),
    )
)

public val Res.string.continue_with_google: StringResource
  get() = CommonMainString0.continue_with_google

private fun init_continue_with_google(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_google", "continue_with_google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1987, 56),
    )
)

public val Res.string.continue_with_next_step: StringResource
  get() = CommonMainString0.continue_with_next_step

private fun init_continue_with_next_step(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_next_step", "continue_with_next_step",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2044, 63),
    )
)

public val Res.string.create: StringResource
  get() = CommonMainString0.create

private fun init_create(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create", "create",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2281, 22),
    )
)

public val Res.string.create_new_task: StringResource
  get() = CommonMainString0.create_new_task

private fun init_create_new_task(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create_new_task", "create_new_task",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2108, 115),
    )
)

public val Res.string.create_workspace: StringResource
  get() = CommonMainString0.create_workspace

private fun init_create_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_workspace", "create_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2224, 56),
    )
)

public val Res.string.custom: StringResource
  get() = CommonMainString0.custom

private fun init_custom(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:custom", "custom",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2304, 22),
    )
)

public val Res.string.daily_activities: StringResource
  get() = CommonMainString0.daily_activities

private fun init_daily_activities(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:daily_activities", "daily_activities",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2327, 48),
    )
)

public val Res.string.dashboard: StringResource
  get() = CommonMainString0.dashboard

private fun init_dashboard(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dashboard", "dashboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2376, 29),
    )
)

public val Res.string.date: StringResource
  get() = CommonMainString0.date

private fun init_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:date", "date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2406, 20),
    )
)

public val Res.string.day_free_trial: StringResource
  get() = CommonMainString0.day_free_trial

private fun init_day_free_trial(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:day_free_trial", "day_free_trial",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2427, 66),
    )
)

public val Res.string.dedicated_support: StringResource
  get() = CommonMainString0.dedicated_support

private fun init_dedicated_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:dedicated_support", "dedicated_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2494, 69),
    )
)

public val Res.string.delete: StringResource
  get() = CommonMainString0.delete

private fun init_delete(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete", "delete",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2564, 22),
    )
)

public val Res.string.describe_the_process: StringResource
  get() = CommonMainString0.describe_the_process

private fun init_describe_the_process(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:describe_the_process", "describe_the_process",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2587, 56),
    )
)

public val Res.string.details: StringResource
  get() = CommonMainString0.details

private fun init_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:details", "details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2644, 27),
    )
)

public val Res.string.due_date: StringResource
  get() = CommonMainString0.due_date

private fun init_due_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:due_date", "due_date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2672, 28),
    )
)

public val Res.string.edit: StringResource
  get() = CommonMainString0.edit

private fun init_edit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:edit", "edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2701, 20),
    )
)

public val Res.string.effortless_efficiency: StringResource
  get() = CommonMainString0.effortless_efficiency

private fun init_effortless_efficiency(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:effortless_efficiency", "effortless_efficiency",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2722, 57),
    )
)

public val Res.string.email: StringResource
  get() = CommonMainString0.email

private fun init_email(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email", "email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2822, 21),
    )
)

public val Res.string.email_address: StringResource
  get() = CommonMainString0.email_address

private fun init_email_address(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email_address", "email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2780, 41),
    )
)

public val Res.string.employee_details: StringResource
  get() = CommonMainString0.employee_details

private fun init_employee_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:employee_details", "employee_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2844, 48),
    )
)

public val Res.string.employees: StringResource
  get() = CommonMainString0.employees

private fun init_employees(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:employees", "employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2893, 29),
    )
)

public val Res.string.employment_details: StringResource
  get() = CommonMainString0.employment_details

private fun init_employment_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:employment_details", "employment_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2923, 50),
    )
)

public val Res.string.end_at: StringResource
  get() = CommonMainString0.end_at

private fun init_end_at(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:end_at", "end_at",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2974, 22),
    )
)

public val Res.string.enter_building_number: StringResource
  get() = CommonMainString0.enter_building_number

private fun init_enter_building_number(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_building_number", "enter_building_number",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2997, 81),
    )
)

public val Res.string.enter_contact_name: StringResource
  get() = CommonMainString0.enter_contact_name

private fun init_enter_contact_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_contact_name", "enter_contact_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3079, 50),
    )
)

public val Res.string.enter_email_address: StringResource
  get() = CommonMainString0.enter_email_address

private fun init_enter_email_address(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_email_address", "enter_email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3130, 55),
    )
)

public val Res.string.enter_employee_email: StringResource
  get() = CommonMainString0.enter_employee_email

private fun init_enter_employee_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_employee_email", "enter_employee_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3186, 68),
    )
)

public val Res.string.enter_full_name: StringResource
  get() = CommonMainString0.enter_full_name

private fun init_enter_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_full_name", "enter_full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3255, 43),
    )
)

public val Res.string.enter_job_title: StringResource
  get() = CommonMainString0.enter_job_title

private fun init_enter_job_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_job_title", "enter_job_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3299, 43),
    )
)

public val Res.string.enter_step_title: StringResource
  get() = CommonMainString0.enter_step_title

private fun init_enter_step_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_step_title", "enter_step_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3343, 48),
    )
)

public val Res.string.enter_street: StringResource
  get() = CommonMainString0.enter_street

private fun init_enter_street(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_street", "enter_street",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3392, 72),
    )
)

public val Res.string.enter_surname: StringResource
  get() = CommonMainString0.enter_surname

private fun init_enter_surname(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_surname", "enter_surname",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3465, 53),
    )
)

public val Res.string.enter_time: StringResource
  get() = CommonMainString0.enter_time

private fun init_enter_time(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_time", "enter_time",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3519, 34),
    )
)

public val Res.string.enter_workspace_name: StringResource
  get() = CommonMainString0.enter_workspace_name

private fun init_enter_workspace_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_workspace_name", "enter_workspace_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3554, 72),
    )
)

public val Res.string.enter_your_email: StringResource
  get() = CommonMainString0.enter_your_email

private fun init_enter_your_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_email", "enter_your_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3627, 48),
    )
)

public val Res.string.enter_zip: StringResource
  get() = CommonMainString0.enter_zip

private fun init_enter_zip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_zip", "enter_zip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3676, 49),
    )
)

public val Res.string.error: StringResource
  get() = CommonMainString0.error

private fun init_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error", "error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3796, 21),
    )
)

public val Res.string.error_message: StringResource
  get() = CommonMainString0.error_message

private fun init_error_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error_message", "error_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3726, 69),
    )
)

public val Res.string.estimated_duration: StringResource
  get() = CommonMainString0.estimated_duration

private fun init_estimated_duration(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:estimated_duration", "estimated_duration",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3818, 50),
    )
)

public val Res.string.every_2_weeks: StringResource
  get() = CommonMainString0.every_2_weeks

private fun init_every_2_weeks(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_2_weeks", "every_2_weeks",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3869, 41),
    )
)

public val Res.string.every_day: StringResource
  get() = CommonMainString0.every_day

private fun init_every_day(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_day", "every_day",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3911, 29),
    )
)

public val Res.string.every_month: StringResource
  get() = CommonMainString0.every_month

private fun init_every_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_month", "every_month",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3941, 35),
    )
)

public val Res.string.every_week: StringResource
  get() = CommonMainString0.every_week

private fun init_every_week(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_week", "every_week",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3977, 34),
    )
)

public val Res.string.expand_your_team: StringResource
  get() = CommonMainString0.expand_your_team

private fun init_expand_your_team(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:expand_your_team", "expand_your_team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4012, 116),
    )
)

public val Res.string.export_list: StringResource
  get() = CommonMainString0.export_list

private fun init_export_list(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:export_list", "export_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4129, 35),
    )
)

public val Res.string.fast_track: StringResource
  get() = CommonMainString0.fast_track

private fun init_fast_track(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fast_track", "fast_track",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4236, 62),
    )
)

public val Res.string.fast_track_support: StringResource
  get() = CommonMainString0.fast_track_support

private fun init_fast_track_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fast_track_support", "fast_track_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4165, 70),
    )
)

public val Res.string.files: StringResource
  get() = CommonMainString0.files

private fun init_files(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:files", "files",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4299, 21),
    )
)

public val Res.string.filter: StringResource
  get() = CommonMainString0.filter

private fun init_filter(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:filter", "filter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4321, 22),
    )
)

public val Res.string.fly_high: StringResource
  get() = CommonMainString0.fly_high

private fun init_fly_high(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fly_high", "fly_high",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4788, 28),
    )
)

public val Res.string.fly_high_description: StringResource
  get() = CommonMainString0.fly_high_description

private fun init_fly_high_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_description", "fly_high_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4344, 136),
    )
)

public val Res.string.fly_high_feature_1: StringResource
  get() = CommonMainString0.fly_high_feature_1

private fun init_fly_high_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_feature_1", "fly_high_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4481, 54),
    )
)

public val Res.string.fly_high_feature_2: StringResource
  get() = CommonMainString0.fly_high_feature_2

private fun init_fly_high_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_feature_2", "fly_high_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4536, 70),
    )
)

public val Res.string.fly_high_plan_description: StringResource
  get() = CommonMainString0.fly_high_plan_description

private fun init_fly_high_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_plan_description", "fly_high_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4607, 141),
    )
)

public val Res.string.fly_high_price: StringResource
  get() = CommonMainString0.fly_high_price

private fun init_fly_high_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fly_high_price", "fly_high_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4749, 38),
    )
)

public val Res.string.forgot_password: StringResource
  get() = CommonMainString0.forgot_password

private fun init_forgot_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forgot_password", "forgot_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4817, 47),
    )
)

public val Res.string.free: StringResource
  get() = CommonMainString0.free

private fun init_free(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free", "free",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5421, 20),
    )
)

public val Res.string.free_100: StringResource
  get() = CommonMainString0.free_100

private fun init_free_100(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free_100", "free_100",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4865, 108),
    )
)

public val Res.string.freebie: StringResource
  get() = CommonMainString0.freebie

private fun init_freebie(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:freebie", "freebie",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5393, 27),
    )
)

public val Res.string.freebie_description: StringResource
  get() = CommonMainString0.freebie_description

private fun init_freebie_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_description", "freebie_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4974, 135),
    )
)

public val Res.string.freebie_feature_1: StringResource
  get() = CommonMainString0.freebie_feature_1

private fun init_freebie_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_feature_1", "freebie_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5110, 49),
    )
)

public val Res.string.freebie_feature_2: StringResource
  get() = CommonMainString0.freebie_feature_2

private fun init_freebie_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_feature_2", "freebie_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5160, 57),
    )
)

public val Res.string.freebie_plan_description: StringResource
  get() = CommonMainString0.freebie_plan_description

private fun init_freebie_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_plan_description", "freebie_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5218, 140),
    )
)

public val Res.string.freebie_price: StringResource
  get() = CommonMainString0.freebie_price

private fun init_freebie_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:freebie_price", "freebie_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5359, 33),
    )
)

public val Res.string.from_small_teams: StringResource
  get() = CommonMainString0.from_small_teams

private fun init_from_small_teams(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:from_small_teams", "from_small_teams",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5442, 232),
    )
)

public val Res.string.full_name: StringResource
  get() = CommonMainString0.full_name

private fun init_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:full_name", "full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5744, 29),
    )
)

public val Res.string.full_name_contact_person: StringResource
  get() = CommonMainString0.full_name_contact_person

private fun init_full_name_contact_person(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:full_name_contact_person", "full_name_contact_person",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5675, 68),
    )
)

public val Res.string.galactic: StringResource
  get() = CommonMainString0.galactic

private fun init_galactic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:galactic", "galactic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6218, 28),
    )
)

public val Res.string.galactic_description: StringResource
  get() = CommonMainString0.galactic_description

private fun init_galactic_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_description", "galactic_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5774, 136),
    )
)

public val Res.string.galactic_feature_1: StringResource
  get() = CommonMainString0.galactic_feature_1

private fun init_galactic_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_feature_1", "galactic_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5911, 54),
    )
)

public val Res.string.galactic_feature_2: StringResource
  get() = CommonMainString0.galactic_feature_2

private fun init_galactic_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_feature_2", "galactic_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5966, 70),
    )
)

public val Res.string.galactic_plan_description: StringResource
  get() = CommonMainString0.galactic_plan_description

private fun init_galactic_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_plan_description", "galactic_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6037, 141),
    )
)

public val Res.string.galactic_price: StringResource
  get() = CommonMainString0.galactic_price

private fun init_galactic_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:galactic_price", "galactic_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6179, 38),
    )
)

public val Res.string.hello: StringResource
  get() = CommonMainString0.hello

private fun init_hello(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hello", "hello",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6247, 21),
    )
)

public val Res.string.high_five: StringResource
  get() = CommonMainString0.high_five

private fun init_high_five(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:high_five", "high_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6690, 33),
    )
)

public val Res.string.high_five_description: StringResource
  get() = CommonMainString0.high_five_description

private fun init_high_five_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_description", "high_five_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6269, 125),
    )
)

public val Res.string.high_five_feature_1: StringResource
  get() = CommonMainString0.high_five_feature_1

private fun init_high_five_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_feature_1", "high_five_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6395, 55),
    )
)

public val Res.string.high_five_feature_2: StringResource
  get() = CommonMainString0.high_five_feature_2

private fun init_high_five_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_feature_2", "high_five_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6451, 71),
    )
)

public val Res.string.high_five_plan_description: StringResource
  get() = CommonMainString0.high_five_plan_description

private fun init_high_five_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_plan_description", "high_five_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6523, 126),
    )
)

public val Res.string.high_five_price: StringResource
  get() = CommonMainString0.high_five_price

private fun init_high_five_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:high_five_price", "high_five_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6650, 39),
    )
)

public val Res.string.hour: StringResource
  get() = CommonMainString0.hour

private fun init_hour(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hour", "hour",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6724, 20),
    )
)

public val Res.string.import_list: StringResource
  get() = CommonMainString0.import_list

private fun init_import_list(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:import_list", "import_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6745, 35),
    )
)

public val Res.string.inactive: StringResource
  get() = CommonMainString0.inactive

private fun init_inactive(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:inactive", "inactive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6781, 28),
    )
)

public val Res.string.industryEmail: StringResource
  get() = CommonMainString0.industryEmail

private fun init_industryEmail(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:industryEmail", "industryEmail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6810, 33),
    )
)

public val Res.string.job_details: StringResource
  get() = CommonMainString0.job_details

private fun init_job_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_details", "job_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6844, 35),
    )
)

public val Res.string.job_duration: StringResource
  get() = CommonMainString0.job_duration

private fun init_job_duration(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_duration", "job_duration",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6880, 36),
    )
)

public val Res.string.job_status: StringResource
  get() = CommonMainString0.job_status

private fun init_job_status(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_status", "job_status",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6917, 34),
    )
)

public val Res.string.job_title: StringResource
  get() = CommonMainString0.job_title

private fun init_job_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_title", "job_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6952, 29),
    )
)

public val Res.string.jobs: StringResource
  get() = CommonMainString0.jobs

private fun init_jobs(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:jobs", "jobs",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7024, 20),
    )
)

public val Res.string.jobs_overview: StringResource
  get() = CommonMainString0.jobs_overview

private fun init_jobs_overview(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:jobs_overview", "jobs_overview",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6982, 41),
    )
)

public val Res.string.join_good_job: StringResource
  get() = CommonMainString0.join_good_job

private fun init_join_good_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_good_job", "join_good_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7045, 41),
    )
)

public val Res.string.join_us: StringResource
  get() = CommonMainString0.join_us

private fun init_join_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_us", "join_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7087, 55),
    )
)

public val Res.string.last_accessed: StringResource
  get() = CommonMainString0.last_accessed

private fun init_last_accessed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:last_accessed", "last_accessed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7143, 41),
    )
)

public val Res.string.leave_details: StringResource
  get() = CommonMainString0.leave_details

private fun init_leave_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:leave_details", "leave_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7185, 41),
    )
)

public val Res.string.login_register: StringResource
  get() = CommonMainString0.login_register

private fun init_login_register(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_register", "login_register",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7227, 42),
    )
)

public val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7270, 22),
    )
)

public val Res.string.manage_employees: StringResource
  get() = CommonMainString0.manage_employees

private fun init_manage_employees(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:manage_employees", "manage_employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7293, 100),
    )
)

public val Res.string.manager: StringResource
  get() = CommonMainString0.manager

private fun init_manager(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:manager", "manager",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7394, 27),
    )
)

public val Res.string.max_size: StringResource
  get() = CommonMainString0.max_size

private fun init_max_size(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:max_size", "max_size",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7422, 52),
    )
)

public val Res.string.minute: StringResource
  get() = CommonMainString0.minute

private fun init_minute(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:minute", "minute",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7475, 22),
    )
)

public val Res.string.month: StringResource
  get() = CommonMainString0.month

private fun init_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:month", "month",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7498, 21),
    )
)

public val Res.string.most_popular: StringResource
  get() = CommonMainString0.most_popular

private fun init_most_popular(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:most_popular", "most_popular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7520, 36),
    )
)

public val Res.string.name: StringResource
  get() = CommonMainString0.name

private fun init_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:name", "name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7557, 20),
    )
)

public val Res.string.need_an_account: StringResource
  get() = CommonMainString0.need_an_account

private fun init_need_an_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:need_an_account", "need_an_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7578, 59),
    )
)

public val Res.string.never: StringResource
  get() = CommonMainString0.never

private fun init_never(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:never", "never",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7638, 21),
    )
)

public val Res.string.new_workspace: StringResource
  get() = CommonMainString0.new_workspace

private fun init_new_workspace(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_workspace", "new_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7660, 41),
    )
)

public val Res.string.next: StringResource
  get() = CommonMainString0.next

private fun init_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next", "next",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7702, 20),
    )
)

public val Res.string.ok: StringResource
  get() = CommonMainString0.ok

private fun init_ok(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ok", "ok",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7723, 14),
    )
)

public val Res.string.onboarding_description1: StringResource
  get() = CommonMainString0.onboarding_description1

private fun init_onboarding_description1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onboarding_description1", "onboarding_description1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7738, 139),
    )
)

public val Res.string.onboarding_description2: StringResource
  get() = CommonMainString0.onboarding_description2

private fun init_onboarding_description2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onboarding_description2", "onboarding_description2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7878, 167),
    )
)

public val Res.string.only_support: StringResource
  get() = CommonMainString0.only_support

private fun init_only_support(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:only_support", "only_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8046, 76),
    )
)

public val Res.string.optimize_your: StringResource
  get() = CommonMainString0.optimize_your

private fun init_optimize_your(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:optimize_your", "optimize_your",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8123, 169),
    )
)

public val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8293, 28),
    )
)

public val Res.string.phone: StringResource
  get() = CommonMainString0.phone

private fun init_phone(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:phone", "phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8359, 21),
    )
)

public val Res.string.phone_number: StringResource
  get() = CommonMainString0.phone_number

private fun init_phone_number(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:phone_number", "phone_number",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8322, 36),
    )
)

public val Res.string.places: StringResource
  get() = CommonMainString0.places

private fun init_places(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:places", "places",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8381, 22),
    )
)

public val Res.string.plan: StringResource
  get() = CommonMainString0.plan

private fun init_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plan", "plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8532, 20),
    )
)

public val Res.string.plan_and_assign: StringResource
  get() = CommonMainString0.plan_and_assign

private fun init_plan_and_assign(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plan_and_assign", "plan_and_assign",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8404, 127),
    )
)

public val Res.string.powerful_tools: StringResource
  get() = CommonMainString0.powerful_tools

private fun init_powerful_tools(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:powerful_tools", "powerful_tools",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8553, 42),
    )
)

public val Res.string.pricing: StringResource
  get() = CommonMainString0.pricing

private fun init_pricing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pricing", "pricing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8596, 27),
    )
)

public val Res.string.priority_support: StringResource
  get() = CommonMainString0.priority_support

private fun init_priority_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:priority_support", "priority_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8624, 68),
    )
)

public val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8693, 42),
    )
)

public val Res.string.provide_information: StringResource
  get() = CommonMainString0.provide_information

private fun init_provide_information(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:provide_information", "provide_information",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8736, 67),
    )
)

public val Res.string.ready_for: StringResource
  get() = CommonMainString0.ready_for

private fun init_ready_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ready_for", "ready_for",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8804, 61),
    )
)

public val Res.string.register: StringResource
  get() = CommonMainString0.register

private fun init_register(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register", "register",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8912, 28),
    )
)

public val Res.string.register_with: StringResource
  get() = CommonMainString0.register_with

private fun init_register_with(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register_with", "register_with",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8866, 45),
    )
)

public val Res.string.repeat: StringResource
  get() = CommonMainString0.repeat

private fun init_repeat(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:repeat", "repeat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8941, 22),
    )
)

public val Res.string.role: StringResource
  get() = CommonMainString0.role

private fun init_role(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:role", "role",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8964, 20),
    )
)

public val Res.string.scheduler: StringResource
  get() = CommonMainString0.scheduler

private fun init_scheduler(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:scheduler", "scheduler",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8985, 29),
    )
)

public val Res.string.search: StringResource
  get() = CommonMainString0.search

private fun init_search(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search", "search",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9015, 22),
    )
)

public val Res.string.select_dates: StringResource
  get() = CommonMainString0.select_dates

private fun init_select_dates(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_dates", "select_dates",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9038, 36),
    )
)

public val Res.string.select_employee: StringResource
  get() = CommonMainString0.select_employee

private fun init_select_employee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_employee", "select_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9075, 43),
    )
)

public val Res.string.select_job_status: StringResource
  get() = CommonMainString0.select_job_status

private fun init_select_job_status(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_job_status", "select_job_status",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9119, 49),
    )
)

public val Res.string.select_skill: StringResource
  get() = CommonMainString0.select_skill

private fun init_select_skill(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_skill", "select_skill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9169, 52),
    )
)

public val Res.string.select_team: StringResource
  get() = CommonMainString0.select_team

private fun init_select_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_team", "select_team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9222, 47),
    )
)

public val Res.string.select_your_workspace: StringResource
  get() = CommonMainString0.select_your_workspace

private fun init_select_your_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_your_workspace", "select_your_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9270, 57),
    )
)

public val Res.string.set_up_workspace: StringResource
  get() = CommonMainString0.set_up_workspace

private fun init_set_up_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:set_up_workspace", "set_up_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9328, 104),
    )
)

public val Res.string.set_weekly_hours: StringResource
  get() = CommonMainString0.set_weekly_hours

private fun init_set_weekly_hours(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:set_weekly_hours", "set_weekly_hours",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9433, 48),
    )
)

public val Res.string.settings: StringResource
  get() = CommonMainString0.settings

private fun init_settings(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings", "settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9482, 28),
    )
)

public val Res.string.sign_in: StringResource
  get() = CommonMainString0.sign_in

private fun init_sign_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in", "sign_in",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9552, 27),
    )
)

public val Res.string.sign_in_with: StringResource
  get() = CommonMainString0.sign_in_with

private fun init_sign_in_with(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in_with", "sign_in_with",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9511, 40),
    )
)

public val Res.string.site: StringResource
  get() = CommonMainString0.site

private fun init_site(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:site", "site",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9580, 20),
    )
)

public val Res.string.skills: StringResource
  get() = CommonMainString0.skills

private fun init_skills(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skills", "skills",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9601, 22),
    )
)

public val Res.string.skip: StringResource
  get() = CommonMainString0.skip

private fun init_skip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skip", "skip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9624, 20),
    )
)

public val Res.string.start_at: StringResource
  get() = CommonMainString0.start_at

private fun init_start_at(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:start_at", "start_at",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9645, 28),
    )
)

public val Res.string.start_organizing: StringResource
  get() = CommonMainString0.start_organizing

private fun init_start_organizing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_organizing", "start_organizing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9674, 68),
    )
)

public val Res.string.start_typing_address: StringResource
  get() = CommonMainString0.start_typing_address

private fun init_start_typing_address(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_typing_address", "start_typing_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9743, 84),
    )
)

public val Res.string.status: StringResource
  get() = CommonMainString0.status

private fun init_status(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:status", "status",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9828, 22),
    )
)

public val Res.string.step: StringResource
  get() = CommonMainString0.step

private fun init_step(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:step", "step",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9957, 20),
    )
)

public val Res.string.step_description: StringResource
  get() = CommonMainString0.step_description

private fun init_step_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:step_description", "step_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9851, 48),
    )
)

public val Res.string.step_title: StringResource
  get() = CommonMainString0.step_title

private fun init_step_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:step_title", "step_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9900, 34),
    )
)

public val Res.string.steps: StringResource
  get() = CommonMainString0.steps

private fun init_steps(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:steps", "steps",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9935, 21),
    )
)

public val Res.string.subscribe: StringResource
  get() = CommonMainString0.subscribe

private fun init_subscribe(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscribe", "subscribe",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9978, 29),
    )
)

public val Res.string.support: StringResource
  get() = CommonMainString0.support

private fun init_support(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:support", "support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10008, 47),
    )
)

public val Res.string.tap_to_upload_photo: StringResource
  get() = CommonMainString0.tap_to_upload_photo

private fun init_tap_to_upload_photo(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tap_to_upload_photo", "tap_to_upload_photo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10056, 55),
    )
)

public val Res.string.team: StringResource
  get() = CommonMainString0.team

private fun init_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:team", "team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10134, 20),
    )
)

public val Res.string.teams: StringResource
  get() = CommonMainString0.teams

private fun init_teams(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:teams", "teams",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10112, 21),
    )
)

public val Res.string.terms_of_service: StringResource
  get() = CommonMainString0.terms_of_service

private fun init_terms_of_service(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:terms_of_service", "terms_of_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10155, 48),
    )
)

public val Res.string.today: StringResource
  get() = CommonMainString0.today

private fun init_today(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:today", "today",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10251, 21),
    )
)

public val Res.string.today_schedule: StringResource
  get() = CommonMainString0.today_schedule

private fun init_today_schedule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:today_schedule", "today_schedule",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10204, 46),
    )
)

public val Res.string.total: StringResource
  get() = CommonMainString0.total

private fun init_total(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:total", "total",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10273, 21),
    )
)

public val Res.string.transform: StringResource
  get() = CommonMainString0.transform

private fun init_transform(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:transform", "transform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10295, 73),
    )
)

public val Res.string.transparent_flexible: StringResource
  get() = CommonMainString0.transparent_flexible

private fun init_transparent_flexible(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:transparent_flexible", "transparent_flexible",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10369, 96),
    )
)

public val Res.string.unlimited_storage: StringResource
  get() = CommonMainString0.unlimited_storage

private fun init_unlimited_storage(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unlimited_storage", "unlimited_storage",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10466, 49),
    )
)

public val Res.string.unselect_all: StringResource
  get() = CommonMainString0.unselect_all

private fun init_unselect_all(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unselect_all", "unselect_all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10516, 36),
    )
)

public val Res.string.upcoming_leaves: StringResource
  get() = CommonMainString0.upcoming_leaves

private fun init_upcoming_leaves(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:upcoming_leaves", "upcoming_leaves",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10553, 43),
    )
)

public val Res.string.upload_documents: StringResource
  get() = CommonMainString0.upload_documents

private fun init_upload_documents(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:upload_documents", "upload_documents",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10597, 64),
    )
)

public val Res.string.upload_profile_picture: StringResource
  get() = CommonMainString0.upload_profile_picture

private fun init_upload_profile_picture(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:upload_profile_picture", "upload_profile_picture",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10662, 62),
    )
)

public val Res.string.use_contact_name: StringResource
  get() = CommonMainString0.use_contact_name

private fun init_use_contact_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:use_contact_name", "use_contact_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10725, 72),
    )
)

public val Res.string.user: StringResource
  get() = CommonMainString0.user

private fun init_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:user", "user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10820, 20),
    )
)

public val Res.string.users: StringResource
  get() = CommonMainString0.users

private fun init_users(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:users", "users",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10798, 21),
    )
)

public val Res.string.welcome: StringResource
  get() = CommonMainString0.welcome

private fun init_welcome(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome", "welcome",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10882, 39),
    )
)

public val Res.string.welcome_back: StringResource
  get() = CommonMainString0.welcome_back

private fun init_welcome_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome_back", "welcome_back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10841, 40),
    )
)

public val Res.string.whether: StringResource
  get() = CommonMainString0.whether

private fun init_whether(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:whether", "whether",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10922, 195),
    )
)

public val Res.string.work_schedule: StringResource
  get() = CommonMainString0.work_schedule

private fun init_work_schedule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:work_schedule", "work_schedule",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11118, 41),
    )
)

public val Res.string.workspace_name: StringResource
  get() = CommonMainString0.workspace_name

private fun init_workspace_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:workspace_name", "workspace_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11160, 42),
    )
)

public val Res.string.workspace_type: StringResource
  get() = CommonMainString0.workspace_type

private fun init_workspace_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:workspace_type", "workspace_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11203, 42),
    )
)

public val Res.string.your_email_address: StringResource
  get() = CommonMainString0.your_email_address

private fun init_your_email_address(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:your_email_address", "your_email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11246, 50),
    )
)

public val Res.string.your_full_name: StringResource
  get() = CommonMainString0.your_full_name

private fun init_your_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_full_name", "your_full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11297, 42),
    )
)

public val Res.string.your_password: StringResource
  get() = CommonMainString0.your_password

private fun init_your_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_password", "your_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11340, 41),
    )
)

public val Res.string.zip: StringResource
  get() = CommonMainString0.zip

private fun init_zip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zip", "zip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11382, 35),
    )
)
