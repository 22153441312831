package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.absoluteOffset
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.chevron_left
import com.hyperether.goodjob.resources.chevron_right
import com.hyperether.goodjob.resources.clock_small
import com.hyperether.goodjob.resources.day
import com.hyperether.goodjob.resources.map_job_item
import com.hyperether.goodjob.resources.month_capitalized
import com.hyperether.goodjob.resources.user_check_job_item
import com.hyperether.goodjob.resources.week
import com.hyperether.goodjob.scenes.components.Text18Bold
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.SchedulerBgColor
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun PeriodChooser(state: MutableState<PeriodType>, changed: () -> Unit) {
    Row(modifier = Modifier.height(40.dp)) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxHeight()
                .background(
                    SchedulerBgColor,
                    shape = RoundedCornerShape(topStart = 10.dp, bottomStart = 10.dp)
                )
                .padding(horizontal = 20.dp)
                .clickable {
                    state.value = PeriodType.DAY
                    changed()
                }
        ) {
            Text(
                stringResource(Res.string.day),
                color = if (state.value == PeriodType.DAY) BlueApp else Color(146, 144, 144)
            )
        }
        Box(
            modifier = Modifier.fillMaxHeight().width(1.dp).background(Color(231, 231, 231))
        )

        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxHeight().background(
                SchedulerBgColor
            ).padding(horizontal = 20.dp).clickable {
                state.value = PeriodType.WEEK
                changed()
            }
        ) {
            Text(
                stringResource(Res.string.week),
                color = if (state.value == PeriodType.WEEK) BlueApp else Color(146, 144, 144)
            )
        }
        Box(
            modifier = Modifier.fillMaxHeight().width(1.dp).background(Color(231, 231, 231))
        )

        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxHeight().background(
                SchedulerBgColor,
                shape = RoundedCornerShape(topEnd = 10.dp, bottomEnd = 10.dp)
            ).padding(horizontal = 20.dp).clickable {
                state.value = PeriodType.MONTH
                changed()
            }
        ) {
            Text(
                stringResource(Res.string.month_capitalized),
                color = if (state.value == PeriodType.MONTH) BlueApp else Color(146, 144, 144)
            )
        }
    }
}

@Composable
fun PeriodSwitcher(
    text: String,
    prev: () -> Unit,
    next: () -> Unit,
    modifier: Modifier = Modifier
) {
    Row(
        modifier.padding(horizontal = 20.dp, vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        IconButton(
            onClick = {
                prev()
            }
        ) {
            Icon(
                painter = painterResource(Res.drawable.chevron_left),
                contentDescription = "Previous date or week"
            )
        }
        Text18Bold(text, modifier = Modifier.weight(1f))
        IconButton(
            onClick = {
                next()
            }
        ) {
            Icon(
                painter = painterResource(Res.drawable.chevron_right),
                contentDescription = "Next date or week"
            )
        }
    }
}

@Composable
fun SchedulerJobHover(
    sites: Map<String, Site>,
    job: Job,
    x: androidx.compose.ui.unit.Dp,
    y: androidx.compose.ui.unit.Dp
) {
    Box(
        modifier = Modifier
            .absoluteOffset(x, y)
            .wrapContentSize()
            .padding(16.dp)
            .background(Color.White, shape = RoundedCornerShape(12.dp))
            .border(1.dp, Color.LightGray, RoundedCornerShape(12.dp))
    ) {

        Column(modifier = Modifier.padding(12.dp)) {
            Row(
                modifier = Modifier.padding(end = 40.dp).height(30.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                job.jobTitle?.let {
                    Box(
                        modifier = Modifier.height(30.dp),
                        contentAlignment = Alignment.CenterStart
                    ) {
                        Text(
                            text = it,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp
                        )
                    }
                }
                Spacer(modifier = Modifier.width(16.dp))
                Box(
                    modifier = Modifier
                        .size(16.dp)
                        .background(
                            color = job.status?.let {
                                StatusColor().getStatusColor(it)
                            } ?: Green,
                            shape = CircleShape
                        )
                )
            }
            Spacer(modifier = Modifier.height(8.dp))
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.padding(bottom = 4.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.user_check_job_item),
                    contentDescription = null,
                )
                Spacer(modifier = Modifier.width(8.dp))
                job.id?.let { id ->
                    sites[id]?.let {
                        Text(
                            text = it.siteName ?: it.personName ?: "",
                            color = BlueApp,
                            fontSize = 14.sp,
                            lineHeight = 18.sp
                        )
                    }
                }
            }
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.padding(bottom = 4.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.map_job_item),
                    contentDescription = null,
                )
                Spacer(modifier = Modifier.width(8.dp))
                job.contactSiteId?.let {
                    Text(
                        text = sites[job.id]?.addressObject?.address ?: "",
                        color = BlueApp,
                        fontSize = 14.sp,
                        lineHeight = 18.sp
                    )
                }
            }
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.padding(bottom = 4.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.clock_small),
                    contentDescription = null,
                )
                Spacer(modifier = Modifier.width(8.dp))
                job.start_at?.let { start ->
                    job.end_at?.let { end ->
                        Text(
                            text = "$start - $end",
                            color = Color.Black,
                            fontSize = 14.sp,
                            lineHeight = 18.sp
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun SchedulerJobWeeklyPopUp(
    sites: Map<String, Site>,
    job: Job,
    onDismissRequest: () -> Unit
) {
    Dialog(onDismissRequest = onDismissRequest) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .background(Color.White, shape = RoundedCornerShape(12.dp))
                .border(1.dp, Color.LightGray, RoundedCornerShape(12.dp))
        ) {
            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.TopEnd
            ) {
                IconButton(onClick = onDismissRequest) {
                    Icon(
                        imageVector = Icons.Default.Close,
                        contentDescription = "Close",
                        tint = Color.Gray
                    )
                }
            }
            Column(
                modifier = Modifier.fillMaxWidth()
            ) {
                Row {
                    Spacer(modifier = Modifier.width(10.dp))

                    Box(
                        modifier = Modifier
                            .fillMaxWidth()

                    ) {
                        Column(modifier = Modifier.padding(12.dp)) {
                            Row(
                                modifier = Modifier.fillMaxWidth().padding(end = 40.dp)
                                    .height(30.dp),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                job.jobTitle?.let {
                                    Box(
                                        modifier = Modifier.height(30.dp),
                                        contentAlignment = Alignment.CenterStart
                                    ) {
                                        Text(
                                            text = it,
                                            color = Color.Black,
                                            fontWeight = FontWeight.Bold,
                                            fontSize = 18.sp
                                        )
                                    }
                                }
                                Spacer(modifier = Modifier.width(16.dp))
                                Box(
                                    modifier = Modifier
                                        .size(16.dp)
                                        .background(
                                            color = job.status?.let {
                                                StatusColor().getStatusColor(it)
                                            } ?: Green,
                                            shape = CircleShape
                                        )
                                )
                            }
                            Spacer(modifier = Modifier.height(8.dp))
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.padding(bottom = 4.dp)
                            ) {
                                Image(
                                    painter = painterResource(Res.drawable.user_check_job_item),
                                    contentDescription = null,
                                )
                                Spacer(modifier = Modifier.width(8.dp))
                                job.id?.let { id ->
                                    sites[id]?.let {
                                        Text(
                                            text = it.siteName ?: it.personName ?: "",
                                            color = BlueApp,
                                            fontSize = 14.sp,
                                            lineHeight = 18.sp
                                        )
                                    }
                                }
                            }
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.padding(bottom = 4.dp)
                            ) {
                                Image(
                                    painter = painterResource(Res.drawable.map_job_item),
                                    contentDescription = null,
                                )
                                Spacer(modifier = Modifier.width(8.dp))
                                job.contactSiteId?.let {
                                    Text(
                                        text = sites[job.id]?.addressObject?.address ?: "",
                                        color = BlueApp,
                                        fontSize = 14.sp,
                                        lineHeight = 18.sp
                                    )
                                }
                            }
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.padding(bottom = 4.dp)
                            ) {
                                Image(
                                    painter = painterResource(Res.drawable.clock_small),
                                    contentDescription = null,
                                )
                                Spacer(modifier = Modifier.width(8.dp))
                                job.start_at?.let { start ->
                                    job.end_at?.let { end ->
                                        Text(
                                            text = "$start - $end",
                                            color = Color.Black,
                                            fontSize = 14.sp,
                                            lineHeight = 18.sp
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}