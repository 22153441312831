package com.hyperether.goodjob.scenes.landing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Card
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.paint
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.components.InputFieldLanding
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all_rights_reserved
import com.hyperether.goodjob.resources.bg_shape_dark
import com.hyperether.goodjob.resources.check_circle
import com.hyperether.goodjob.resources.choose_plan
import com.hyperether.goodjob.resources.effortless_efficiency
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.fly_high
import com.hyperether.goodjob.resources.fly_high_description
import com.hyperether.goodjob.resources.fly_high_feature_1
import com.hyperether.goodjob.resources.fly_high_feature_2
import com.hyperether.goodjob.resources.fly_high_price
import com.hyperether.goodjob.resources.free
import com.hyperether.goodjob.resources.free_100
import com.hyperether.goodjob.resources.freebie
import com.hyperether.goodjob.resources.freebie_description
import com.hyperether.goodjob.resources.freebie_feature_1
import com.hyperether.goodjob.resources.freebie_feature_2
import com.hyperether.goodjob.resources.freebie_price
import com.hyperether.goodjob.resources.galactic
import com.hyperether.goodjob.resources.galactic_description
import com.hyperether.goodjob.resources.galactic_feature_1
import com.hyperether.goodjob.resources.galactic_feature_2
import com.hyperether.goodjob.resources.galactic_price
import com.hyperether.goodjob.resources.high_five
import com.hyperether.goodjob.resources.high_five_description
import com.hyperether.goodjob.resources.high_five_feature_1
import com.hyperether.goodjob.resources.high_five_feature_2
import com.hyperether.goodjob.resources.high_five_price
import com.hyperether.goodjob.resources.landing_img
import com.hyperether.goodjob.resources.landing_img_dark
import com.hyperether.goodjob.resources.login_register
import com.hyperether.goodjob.resources.logo_dark_landing
import com.hyperether.goodjob.resources.most_popular
import com.hyperether.goodjob.resources.optimize_your
import com.hyperether.goodjob.resources.premium_dark
import com.hyperether.goodjob.resources.pricing
import com.hyperether.goodjob.resources.privacy_policy
import com.hyperether.goodjob.resources.subscribe
import com.hyperether.goodjob.resources.terms_of_service
import com.hyperether.goodjob.resources.transform
import com.hyperether.goodjob.resources.transparent_flexible
import com.hyperether.goodjob.resources.unlimited_storage
import com.hyperether.goodjob.resources.whether
import com.hyperether.goodjob.scenes.pricing.PricingItem
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.LandingPageBg
import com.hyperether.goodjob.theme.LandingPageMainTitle
import com.hyperether.goodjob.theme.LandingPageMobileText
import com.hyperether.goodjob.theme.LandingPageNavColor
import com.hyperether.goodjob.theme.LandingPageSmallTitle
import com.hyperether.goodjob.theme.LandingPageText
import com.hyperether.goodjob.theme.PopularPricingBoxBg
import com.hyperether.goodjob.theme.PopularPricingText
import com.hyperether.goodjob.theme.PricingBoxBg
import com.hyperether.goodjob.theme.PricingBtnColor
import com.hyperether.goodjob.theme.PricingText
import com.hyperether.goodjob.theme.SplashBg
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun LandingPage(
    isMobileView: Boolean,
    pricingViewModel: PricingViewModel,
    continueToRegister: () -> Unit) {

    var enteredEmail: MutableState<String> = mutableStateOf("")
    val plans = if (getPlatformChecker().isMobile()) {
        pricingViewModel.plans.collectAsState().value
    } else {
        pricingViewModel.plans_remote.collectAsState().value.sortedBy { it.price }
    }

    val selectedPlan = remember { mutableStateOf<String?>(null) }
    val selectedPlanId = remember { mutableStateOf<String?>(null) }

    LaunchedEffect(plans) {
        if (plans.isNotEmpty()) {
            val popularPlan = plans.find { it.isPopular == true }
            selectedPlan.value = popularPlan?.name
            selectedPlanId.value = popularPlan?._id
        }
    }

    LaunchedEffect(isMobileView) {
        pricingViewModel.getAllPlans()
    }

    if (!isMobileView) {
        Box(
            modifier = Modifier
                .fillMaxHeight()
                .background(LandingPageBg)
        ) {
            Image(
                painter = painterResource(Res.drawable.bg_shape_dark),
                contentDescription = null,
                modifier = Modifier
                    .sizeIn(minWidth = 400.dp, maxWidth = 600.dp)
                    .height(800.dp)
                    .graphicsLayer(alpha = 0.07f),
                contentScale = ContentScale.Fit,
            )
            Column(
                modifier = Modifier
                    .fillMaxHeight()
                    .sizeIn(minWidth = 1200.dp, maxWidth = 2400.dp)
                    .verticalScroll(rememberScrollState()),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Box(
                    modifier = Modifier
                        .fillMaxWidth(),
                    contentAlignment = Alignment.Center,
                ) {
                    Column(
                        modifier = Modifier
                            .fillMaxHeight()
                            .align(Alignment.Center)
                            .background(Color.Transparent),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .background(LandingPageNavColor)
                                .padding(30.dp),
                            contentAlignment = Alignment.TopStart
                        ) {
                            Image(
                                alignment = Alignment.TopStart,
                                painter = painterResource(Res.drawable.logo_dark_landing),
                                contentDescription = null
                            )

                            Button(
                                onClick = {
                                    continueToRegister()
                                },
                                modifier = Modifier
                                    .align(Alignment.TopEnd)
                                    .height(50.dp)
                                    .width(150.dp)
                                    .clip(RoundedCornerShape((56.dp))),
                                colors = ButtonDefaults.buttonColors(LandingPageMainTitle),
                            ) {
                                Text(
                                    text = stringResource(Res.string.login_register),
                                    color = BlueApp,
                                    fontWeight = FontWeight.Bold
                                )
                            }
                        }

                        Spacer(modifier = Modifier.height(50.dp))

                        Row(
                            modifier = Modifier
                                .padding(30.dp)
                                .fillMaxHeight(),
                            horizontalArrangement = Arrangement.Center
                        ) {
                            Column(
                                modifier = Modifier
                                    .fillMaxHeight()
                                    .weight(1f),
                                verticalArrangement = Arrangement.Center,
                            ) {
                                Text(
                                    text = stringResource(Res.string.effortless_efficiency),
                                    fontSize = 16.sp,
                                    color = LandingPageSmallTitle
                                )
                                Spacer(modifier = Modifier.height(20.dp))

                                Text(
                                    text = stringResource(Res.string.transform),
                                    fontSize = 50.sp,
                                    lineHeight = 50.sp,
                                    color = LandingPageMainTitle,
                                    modifier = Modifier
                                        .fillMaxWidth(),
                                )
                                Text(
                                    text = stringResource(Res.string.optimize_your),
                                    fontSize = 18.sp,
                                    color = LandingPageText,
                                    modifier = Modifier
                                        .padding(top = 20.dp)
                                )
                                InputFieldLanding(
                                    isMobileView = false,
                                    input = enteredEmail,
                                    placeHolder = stringResource(Res.string.enter_your_email),
                                    modifier = Modifier
                                        .padding(top = 20.dp)
                                )
                                Spacer(modifier = Modifier.height(20.dp))
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Icon(
                                        painter = painterResource(Res.drawable.check_circle),
                                        tint = Color.White,
                                        contentDescription = "Feature icon",
                                        modifier = Modifier
                                            .size(16.dp)
                                    )
                                    Text(
                                        text = stringResource(Res.string.free_100),
                                        color = LandingPageText,
                                        modifier = Modifier
                                            .padding(start = 20.dp)
                                    )
                                }
                            }
                            Image(
                                modifier = Modifier
                                    .weight(1f),
                                alignment = Alignment.TopEnd,
                                painter = painterResource(Res.drawable.landing_img_dark),
                                contentDescription = null
                            )
                        }

                        Spacer(modifier = Modifier.height(40.dp))

                        Column(
                            modifier = Modifier
                                .padding(30.dp)
                                .fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            Text(
                                text = stringResource(Res.string.pricing),
                                fontSize = 18.sp,
                                color = LandingPageSmallTitle,
                                textAlign = TextAlign.Center
                            )
                            Spacer(modifier = Modifier.height(20.dp))

                            // Pricing Section Title
                            Text(
                                text = stringResource(Res.string.transparent_flexible),
                                fontSize = 50.sp,
                                lineHeight = 50.sp,
                                fontWeight = FontWeight.Bold,
                                textAlign = TextAlign.Center,
                                color = LandingPageMainTitle,
                                modifier = Modifier.padding(vertical = 16.dp)
                            )
                            Text(
                                text = stringResource(Res.string.whether),
                                fontSize = 16.sp,
                                color = LandingPageText,
                                textAlign = TextAlign.Center
                            )

                            // Spacer for additional separation
                            Spacer(modifier = Modifier.height(60.dp))

                            // Pricing Cards Container
                            Box(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(horizontal = 80.dp)
                                    .shadow(8.dp, shape = RoundedCornerShape(26.dp))
                                    .clip(RoundedCornerShape(26.dp))
                                    .background(PricingBoxBg),
                            ) {
                                Row(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(16.dp),
                                    horizontalArrangement = Arrangement.SpaceEvenly
                                ) {
                                    plans.forEach { plan ->
                                        PricingOption(
                                            price = "$${plan.price}",
                                            planName = plan.name.orEmpty(),
                                            description = plan.description.orEmpty(),
                                            features = listOf(
                                                plan.storage.orEmpty(),
                                                plan.planSupport.orEmpty(),
                                                plan.limits.orEmpty()
                                            ),
                                            continueToRegister = {
                                                plan._id?.let {
                                                    // TODO: save to prefs and on login add plan
                                                    continueToRegister()
                                                }
                                            },
                                            isPopular = plan.isPopular!!,
                                            width = 300.dp
                                        )
                                    }
                                }
                            }
                        }
                    }
                }

                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(color = LandingPageNavColor)
                        .padding(vertical = 20.dp)
                ) {
                    Text(
                        text = stringResource(Res.string.all_rights_reserved),
                        fontSize = 15.sp,
                        color = LandingPageMainTitle,
                        textAlign = TextAlign.End,
                        modifier = Modifier.weight(4f)
                    )
                    Text(
                        text = stringResource(Res.string.privacy_policy),
                        fontSize = 15.sp,
                        color = LandingPageMainTitle,
                        textAlign = TextAlign.End,
                        modifier = Modifier.weight(1f)
                    )
                    Text(
                        text = stringResource(Res.string.terms_of_service),
                        fontSize = 15.sp,
                        color = LandingPageMainTitle,
                        textAlign = TextAlign.End,
                        modifier = Modifier
                            .weight(1f)
                            .padding(end = 20.dp)
                    )
                }
            }
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .background(LandingPageBg)
                .sizeIn(minWidth = 1200.dp, maxWidth = 2400.dp)
                .verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth(),
                contentAlignment = Alignment.TopStart
            ) {
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(LandingPageNavColor)
                        .padding(15.dp),
                    contentAlignment = Alignment.TopStart
                ) {
                    Image(
                        alignment = Alignment.TopStart,
                        painter = painterResource(Res.drawable.logo_dark_landing),
                        contentDescription = null
                    )
                    Button(
                        onClick = {
                            continueToRegister()
                        },
                        modifier = Modifier
                            .align(Alignment.TopEnd)
                            .height(50.dp)
                            .width(150.dp)
                            .clip(RoundedCornerShape((56.dp))),
                        colors = ButtonDefaults.buttonColors(SplashBg),
                    ) {
                        Text(
                            text = stringResource(Res.string.login_register),
                            color = LandingPageMainTitle,
                            fontWeight = FontWeight.Bold
                        )
                    }
                }
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .padding(top = 100.dp),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Column(
                        modifier = Modifier
                            .paint(
                                painterResource(Res.drawable.landing_img),
                                alpha = 0.2f
                            )
                            .fillMaxSize()
                            .padding(top = 30.dp, start = 20.dp, end = 20.dp),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text(
                            text = stringResource(Res.string.effortless_efficiency),
                            fontSize = 16.sp,
                            color = LandingPageSmallTitle,
                        )
                        Spacer(modifier = Modifier.height(20.dp))

                        Text(
                            text = stringResource(Res.string.transform),
                            fontSize = 50.sp,
                            fontWeight = FontWeight.Bold,
                            lineHeight = 50.sp,
                            color = LandingPageMainTitle,
                            modifier = Modifier
                                .fillMaxWidth(),
                        )
                        Text(
                            text = stringResource(Res.string.optimize_your),
                            fontSize = 18.sp,
                            color = LandingPageMobileText,
                            modifier = Modifier
                                .padding(top = 20.dp)
                        )
                        InputFieldLanding(
                            isMobileView = true,
                            input = enteredEmail,
                            placeHolder = stringResource(Res.string.enter_your_email),
                            modifier = Modifier
                                .padding(top = 20.dp)
                        )
                    }
                    Button(
                        onClick = {
                            // TODO
                        },
                        modifier = Modifier
                            .padding(horizontal = 20.dp)
                            .height(50.dp)
                            .fillMaxWidth()
                            .clip(RoundedCornerShape((56.dp)))
                            .shadow(8.dp),
                        colors = ButtonDefaults.buttonColors(BlueApp),
                    ) {
                        Text(
                            text = stringResource(Res.string.subscribe),
                            color = LandingPageMainTitle
                        )
                    }
                    Row(
                        modifier = Modifier.fillMaxWidth()
                            .padding(start = 20.dp, end = 20.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Icon(
                            painter = painterResource(Res.drawable.check_circle),
                            tint = Color.White,
                            contentDescription = "Feature icon",
                            modifier = Modifier
                                .size(16.dp)
                        )
                        Text(
                            text = stringResource(Res.string.free_100),
                            color = LandingPageText,
                            modifier = Modifier
                                .padding(start = 20.dp)
                        )
                    }
                    Spacer(modifier = Modifier.height(100.dp))

                    Text(
                        text = stringResource(Res.string.pricing),
                        fontSize = 18.sp,
                        color = LandingPageSmallTitle,
                        textAlign = TextAlign.Center
                    )
                    // Pricing Section Title
                    Text(
                        text = stringResource(Res.string.transparent_flexible),
                        fontSize = 50.sp,
                        color = LandingPageMainTitle,
                        lineHeight = 50.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.padding(vertical = 16.dp)
                    )
                    Text(
                        text = stringResource(Res.string.whether),
                        fontSize = 16.sp,
                        color = LandingPageMobileText,
                        textAlign = TextAlign.Center
                    )

                    // Spacer for additional separation
                    Spacer(modifier = Modifier.height(20.dp))

                    // Pricing Cards Container
                    plans.forEach { plan ->
                        PricingOption(
                            price = if (plan.price == 0.0) stringResource(Res.string.free) else "$${plan.price}",
                            planName = plan.name.orEmpty(),
                            description = plan.description.orEmpty(),
                            features = listOf(
                                plan.storage.orEmpty(),
                                plan.planSupport.orEmpty(),
                                plan.limits.orEmpty()
                            ),
                            continueToRegister = {
                                // todo save to prefs and continue
                                continueToRegister()
                            },
                            isPopular = plan.isPopular!!,
                            width = 500.dp
                        )
                        Spacer(modifier = Modifier.height(20.dp))
                    }

                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(LandingPageNavColor)
                    ) {
                        Text(
                            text = stringResource(Res.string.all_rights_reserved),
                            fontSize = 15.sp,
                            color = LandingPageMainTitle,
                            textAlign = TextAlign.Center,
                            modifier = Modifier.fillMaxWidth().padding(bottom = 10.dp)
                        )
                        Row {
                            Text(
                                text = stringResource(Res.string.privacy_policy),
                                fontSize = 15.sp,
                                color = LandingPageMainTitle,
                                textAlign = TextAlign.Start,
                                modifier = Modifier.weight(1f)
                            )
                            Text(
                                text = stringResource(Res.string.terms_of_service),
                                fontSize = 15.sp,
                                color = LandingPageMainTitle,
                                textAlign = TextAlign.End,
                                modifier = Modifier
                                    .weight(1f)
                                    .padding(end = 20.dp, bottom = 20.dp)
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun PricingOption(
    price: String,
    planName: String,
    description: String,
    features: List<String>,
    isPopular: Boolean = false,
    continueToRegister: () -> Unit,
    width: Dp
) {
    Box(
        modifier = if (isPopular) Modifier
            .shadow(8.dp, shape = RoundedCornerShape(26.dp))
            .clip(RoundedCornerShape((26.dp)))
            .width(width)
            .background(
                shape = RoundedCornerShape(26.dp),
                color = PopularPricingBoxBg
            )
            .paint(
                painter = painterResource(Res.drawable.premium_dark),
                alignment = Alignment.CenterEnd
            )
            .padding(start = 20.dp, end = 20.dp)
        else {
            Modifier
                .width(width)
                .background(
                    color = PricingBoxBg,
                    shape = RoundedCornerShape(12.dp)
                )
                .padding(16.dp)
        }
    ) {
        Column {
            Box(
            ) {
                Column(
                    modifier = if (isPopular) Modifier
                        .fillMaxWidth()
                        .align(Alignment.CenterStart)
                        .padding(top = 15.dp, bottom = 20.dp, end = 15.dp)
                    else Modifier
                        .fillMaxWidth()
                        .padding(bottom = 64.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    if (isPopular) {
                        Text(
                            text = stringResource(Res.string.most_popular),
                            fontSize = 10.sp,
                            color = Color.White,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                            modifier = Modifier
                                .background(PricingBoxBg, shape = RoundedCornerShape(26.dp))
                                .padding(horizontal = 16.dp, vertical = 4.dp)
                                .align(Alignment.End),
                        )
                    }
                    Text(
                        text = buildAnnotatedString {
                            val parts = price.split("/")
                            val mainPrice = parts.getOrNull(0) ?: ""
                            val period = parts.getOrNull(1) ?: ""

                            withStyle(
                                style = SpanStyle(
                                    fontSize = 36.sp,
                                    fontWeight = FontWeight.Bold,
                                    color = if (isPopular) PopularPricingText else PricingText
                                )
                            ) {
                                append(mainPrice)
                            }

                            if (period.isNotEmpty()) {
                                withStyle(
                                    style = SpanStyle(
                                        fontSize = 17.sp,
                                        color = if (isPopular) PopularPricingText else PricingText
                                    )
                                ) {
                                    append(" /$period")
                                }
                            }
                        },
                        modifier = Modifier
                            .padding(top = 8.dp, bottom = 15.dp)
                            .align(Alignment.Start)
                    )
                    Text(
                        text = planName,
                        fontSize = 28.sp,
                        fontWeight = FontWeight.SemiBold,
                        color = if (isPopular) PopularPricingText else PricingText,
                        modifier = Modifier
                            .padding(top = 4.dp, bottom = 4.dp)
                            .align(Alignment.Start)
                    )
                    Text(
                        text = description,
                        fontSize = 15.sp,
                        color = if (isPopular) PopularPricingText else PricingText,
                        modifier = Modifier
                            .padding(top = 8.dp, bottom = 20.dp)
                            .align(Alignment.Start)
                    )
                    features.forEach { feature ->
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 4.dp)
                                .align(Alignment.Start),
                            verticalAlignment = Alignment.CenterVertically // Aligns icon and text vertically centered
                        ) {
                            Icon(
                                painter = painterResource(Res.drawable.check_circle), // Replace with your drawable resource ID
                                contentDescription = "Feature icon",
                                tint = if (isPopular) LandingPageBg.copy(alpha = 0.5f) else Color.White.copy(
                                    alpha = 0.5f
                                ),
                                modifier = Modifier.size(16.dp) // Adjust size as needed
                            )

                            Spacer(modifier = Modifier.width(4.dp)) // Adds space between icon and text

                            Text(
                                text = feature,
                                fontSize = 15.sp,
                                color = if (isPopular) PopularPricingText else PricingText
                            )
                        }
                    }
                }
            }
            Box(
                contentAlignment = Alignment.BottomCenter
            ) {
                Button(
                    onClick = { continueToRegister() },
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .padding(8.dp)
                        .height(50.dp)
                        .fillMaxWidth()
                        .clip(RoundedCornerShape((56.dp))),
                    colors = ButtonDefaults.buttonColors(
                        backgroundColor = if (isPopular) BlueApp else PricingBtnColor,
                        contentColor = BlueApp
                    )
                ) {
                    Text(
                        fontWeight = FontWeight.Bold,
                        text = stringResource(Res.string.choose_plan),
                        color = PricingText
                    )
                }
            }
        }
    }
}