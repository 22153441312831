package com.hyperether.goodjob.scenes.dashboard

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.check_soon
import com.hyperether.goodjob.resources.dashboard
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.job
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.jobs_overview
import com.hyperether.goodjob.resources.no_tasks_assigned
import com.hyperether.goodjob.resources.no_tasks_created
import com.hyperether.goodjob.resources.today_schedule
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.dashboard.map.DashboardMapViewModel
import com.hyperether.goodjob.scenes.jobs.components.FilterJobsDialog
import com.hyperether.goodjob.scenes.jobs.components.JobsList
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.util.FilterDialogStateManager
import com.hyperether.goodjob.util.JobStatusMap
import com.hyperether.planner.ui.DashboardDayPlanner
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun DashboardScreen(
    isMobileView: Boolean,
    navHostController: NavHostController,
    viewModel: DashboardViewModel,
    dashboardMapViewModel: DashboardMapViewModel,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {

    viewModel.jobStatusMap = JobStatusMap()

    LaunchedEffect(Unit) {
        FilterDialogStateManager.setDialogShowing(false)
    }

    var isJobsOverviewSelected = remember { mutableStateOf(true) }

    val selectedJobs = remember { mutableStateListOf<Job>() }

    if (isMobileView) {
        Column {

            MobileHeaderSearchAdd(
                title = stringResource(Res.string.dashboard),
                drawerState = drawerState,
                scope = rememberCoroutineScope(),
                add = { navHostController.navigate(Screen.AddNew.route) },
                search = {
                    navHostController.navigate(Screen.DashboardMapScreen.route)
                },
                searchOnMap = true,
                user = prefsManager.getUser()
            )

            Column(
                modifier = Modifier
                    .background(Color.LightGray.copy(alpha = 0.2f))
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(40.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.today_schedule),
                        color = if (isJobsOverviewSelected.value) Color.Gray else BlueApp,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(start = 30.dp)
                            .clickable {
                                isJobsOverviewSelected.value = false
                            }
                    )

                    Spacer(modifier = Modifier.weight(0.5f))

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.jobs_overview),
                        color = if (isJobsOverviewSelected.value) BlueApp else Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(end = 30.dp)
                            .clickable {
                                isJobsOverviewSelected.value = true
                            }
                    )
                }
            }
            Box(
                modifier = Modifier
                    .height(2.dp)
                    .fillMaxWidth(0.5f)
                    .background(if (isJobsOverviewSelected.value) BlueApp else BlueApp)
                    .align(if (isJobsOverviewSelected.value) Alignment.End else Alignment.Start)
            )
            if (viewModel.isEmployee() && viewModel.jobList.size == 0) {
                androidx.compose.material3.Text(
                    text = stringResource(Res.string.no_tasks_created),
                    color = Color.Black,
                    fontSize = 24.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.fillMaxWidth()
                        .padding(top = 60.dp, start = 16.dp, end = 16.dp)
                )
                androidx.compose.material3.Text(
                    text = stringResource(Res.string.check_soon),
                    color = Color.Gray,
                    textAlign = TextAlign.Center,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(top = 20.dp, start = 16.dp, end = 16.dp)
                )
            } else if (!viewModel.isEmployee() && viewModel.jobList.size == 0) {
                androidx.compose.material3.Text(
                    text = stringResource(Res.string.no_tasks_created),
                    color = Color.Black,
                    fontSize = 24.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.fillMaxWidth()
                        .padding(top = 60.dp, start = 16.dp, end = 16.dp)
                )
                androidx.compose.material3.Text(
                    text = stringResource(Res.string.no_tasks_assigned),
                    color = Color.Gray,
                    textAlign = TextAlign.Center,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(top = 20.dp, start = 16.dp, end = 16.dp)
                )
            } else {
                if (isJobsOverviewSelected.value) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = 16.dp, vertical = 16.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        val leadingText =
                            if (viewModel.filteredJobs.size == 1) stringResource(Res.string.job) else stringResource(
                                Res.string.jobs
                            )
                        Text(
                            text = "Total: ${viewModel.filteredJobs.size} $leadingText",
                            fontSize = 14.sp,
                            color = Color.Gray,
                            modifier = Modifier.weight(1f),
                            textAlign = TextAlign.Start
                        )
                        Row(verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier.clickable {
                                //dashboardViewModel.showFilterDialog.value = true
                                FilterDialogStateManager.setDialogShowing(true)
                            }) {
                            Text(
                                text = stringResource(Res.string.filter),
                                fontSize = 14.sp,
                                color = Color.Gray
                            )
                            Icon(
                                painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                                contentDescription = "",
                                modifier = Modifier
                                    .padding(start = 8.dp)
                                    .size(20.dp)
                            )
                        }

                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )
                if (isJobsOverviewSelected.value) {
                    JobsList(
                        viewModel.sites,
                        viewModel.filteredJobs,
                        selectedJobs,
                        isInEditMode = viewModel.isInEditMode.value,
                        navHostController
                    )
                } else {
//                    Row(
//                        modifier = Modifier.fillMaxWidth(),
//                        verticalAlignment = Alignment.CenterVertically,
//                        horizontalArrangement = Arrangement.Center
//                    ) {
//                        IconButton(onClick = {}) {
//                            Icon(
//                                painter = painterResource(Res.drawable.chevron_left),
//                                contentDescription = "Left Icon"
//                            )
//                        }
//                        Text(
//                            text = stringResource(Res.string.today),
//                            modifier = Modifier.padding(horizontal = 8.dp),
//                            textAlign = TextAlign.Center,
//                            style = TextStyle(fontSize = 16.sp, color = Color.DarkGray)
//                        )
//                        IconButton(onClick = {}) {
//                            Icon(
//                                painter = painterResource(Res.drawable.chevron_right),
//                                contentDescription = "Right Icon"
//                            )
//                        }
                    //                   }
                    DashboardDayPlanner(dashboardEvents = viewModel.dashboardEventList)
                    //SchedulerDayPlanner(listOfPlans = planList.toMutableList())

                }
            }
        }
        val employeesList = viewModel.jobList.flatMap { job ->
            job.assignees?.employees ?: emptyList()
        }.distinctBy { it?._id }
        val names = employeesList.map { it?.getName() }
        val teams = viewModel.teams.collectAsState(emptyList())
        val teamsName = teams.value.map { it.name }
        val contactAddresses = viewModel.jobList.mapNotNull { job ->
            viewModel.sites[job.id]?.addressObject?.address
        }.distinct()
        if (FilterDialogStateManager.dialogShowing.value) {
            FilterJobsDialog(
                viewModel.jobList.mapNotNull {
                    viewModel.jobStatusMap.entries.find { entry -> entry.value == it.status }?.key
                }.distinct(),
                (names + teamsName).distinct(),
                contactAddresses,
                viewModel.jobList.mapNotNull { it.startDate }.distinct(),
                onDismiss = { FilterDialogStateManager.setDialogShowing(false) },
                initialSelectedStatus = viewModel.selectedStatus.value.map { stat ->
                    viewModel.jobStatusMap.entries.find { entry -> entry.value == stat }?.key ?: ""
                }.toSet(),
                initialSelectedEmployees = viewModel.selectedEmployees.value,
                initialSelectedLocations = viewModel.selectedLocation.value,
                initialSelectedSchedule = viewModel.selectedSchedule.value,
                onApply = { selectedStatus, selectedEmployees, selectedLocation, selectedSchedule ->
                    FilterDialogStateManager.setDialogShowing(false)
                    viewModel.selectedStatus.value = selectedStatus.map { entry ->
                        viewModel.jobStatusMap[entry] ?: entry
                    }.toSet()
                    viewModel.selectedEmployees.value = selectedEmployees
                    viewModel.selectedLocation.value = selectedLocation
                    viewModel.selectedSchedule.value = selectedSchedule
                    viewModel.filterJobs()
                }
            )
        }
    } else {
        DashboardScreenWebView(navHostController, dashboardMapViewModel)
    }

}