package com.hyperether.planner.ui

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.DrawScope
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.hyperether.planner.model.Event
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import com.hyperether.planner.util.CircleWithInitial
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.LocalDate
import kotlinx.datetime.isoDayNumber
import kotlinx.datetime.plus

@Composable
fun WeekPlanner(
    listOfPlans: MutableList<Plan>,
    completedColor: Color,
    pendingColor: Color,
    delayedColor: Color,
    celWidth: Int = 150,
    lastDay: Int = 8,
    spacer: Int = 5,
    weekStart: LocalDate,
    weekEnd: LocalDate,
    borderPathColor: Color = Color(231, 231, 231),
    namesColor: Color = Color(146, 144, 144),
    dateColor: Color = Color(83, 82, 82),
    namesIconsShown: Boolean = false,
    onEventClicked: (Event) -> Unit,
    onEventHovered: (Event?) -> Unit
) {
    var celWidthDp by remember { mutableStateOf(celWidth) }
    val tableWidth by remember { mutableStateOf((7 * celWidthDp).dp) }

    if (listOfPlans.size < 10) {
        while (listOfPlans.size < 11)
            listOfPlans.addAll(listOf(Plan("", mutableListOf())))
    }

    val horizontalScrollState = rememberScrollState()
    val verticalScrollState = rememberScrollState()

    Box(
        modifier = Modifier
            .fillMaxHeight()
            .pointerInput(Unit) {
                detectDragGestures { change, dragAmount ->
                    change.consume()
                    CoroutineScope(Dispatchers.Default).launch {
                        println("Y drag = ${dragAmount.y.toInt()}")
                        println("Y state = ${verticalScrollState.value}")
                        println("X drag = ${dragAmount.x.toInt()}")
                        println("X state = ${horizontalScrollState.value}")

                        verticalScrollState.scrollTo(verticalScrollState.value - dragAmount.y.toInt())

                        horizontalScrollState.scrollTo(horizontalScrollState.value - dragAmount.x.toInt())
                    }
                }
            }
            .background(Color.White)
            .border(1.dp, color = borderPathColor),
        contentAlignment = Alignment.TopStart
    ) {

        Row {
            //Names
            Column(
                modifier = Modifier.verticalScroll(verticalScrollState)
                    .background(Color.Transparent)
                    .border(1.dp, borderPathColor)
            ) {
                Box(
                    modifier = Modifier.size(height = 50.dp, width = 10.dp)
                        .background(Color.Transparent)
                )
                repeat(listOfPlans.size) { index ->
                    if (index == 0) {
                        Spacer(modifier = Modifier.height(12.dp))
                    } else {
                        Spacer(modifier = Modifier.height(33.dp))
                    }
                    Box(
                        modifier = Modifier.height(50.dp)
                            .width(if (namesIconsShown) 150.dp else 100.dp)
                            .background(Color.Transparent), contentAlignment = Alignment.Center
                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            if (namesIconsShown && listOfPlans[index].person.isNotEmpty()) {
                                CircleWithInitial(listOfPlans[index].person.uppercase())
                                Spacer(Modifier.width(8.dp))
                            }
                            Text(
                                listOfPlans[index].person,
                                textAlign = TextAlign.Center,
                                color = namesColor,
                                overflow = TextOverflow.Ellipsis
                            )
                        }

                    }
                }
            }

            val pathEffect =
                PathEffect.dashPathEffect(floatArrayOf(5f, 5f), phase = 0f)

            Column(
                modifier = Modifier
                    .background(Color.Transparent)
            ) {
                //days
                Row(
                    modifier = Modifier
                        .height(50.dp)
                        .horizontalScroll(horizontalScrollState),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    repeat(8) { index ->
                        Box(
                            modifier = Modifier
                                .width(celWidthDp.dp)
                                .height(50.dp)
                                .drawBehind {
                                    drawLine(
                                        color = borderPathColor,
                                        start = Offset(x = 0f, y = size.height),
                                        end = Offset(x = size.width, y = size.height),
                                        strokeWidth = 1.dp.toPx()
                                    )
                                    drawLine(
                                        color = borderPathColor,
                                        start = Offset(x = size.width, y = 0f),
                                        end = Offset(x = size.width, y = size.height),
                                        pathEffect = pathEffect,
                                        strokeWidth = 1.dp.toPx()
                                    )
                                },
                            contentAlignment = Alignment.Center
                        ) {
                            Text(
                                formatDateToDayMonth(weekStart.plus(DatePeriod(days = index))),
                                textAlign = TextAlign.Center,
                                color = dateColor
                            )
                        }
                    }
                }

                //content
                Column(
                    modifier = Modifier
                        .horizontalScroll(horizontalScrollState)
                        .verticalScroll(verticalScrollState),
                ) {
                    val textMeasurer = rememberTextMeasurer()
                    repeat(listOfPlans.size) { rowIndex ->
                        if (rowIndex == 0) {
                            Canvas(
                                modifier = Modifier
                                    .size(width = tableWidth, height = 12.dp)
                            ) {
                                drawVerticalLines(celWidthDp.dp, borderPathColor, pathEffect)
                            }
                        } else {
                            Canvas(
                                modifier = Modifier
                                    .size(width = tableWidth, height = 33.dp)
                            ) {
                                drawVerticalLines(celWidthDp.dp, borderPathColor, pathEffect)
                            }
                        }
                        val mapOfEvents = mutableMapOf<Int, MutableList<Event>>()

                        listOfPlans[rowIndex].events?.forEach {
                            if (mapOfEvents[it.from.dayOfWeek.isoDayNumber] == null) {
                                mapOfEvents[it.from.dayOfWeek.isoDayNumber] = mutableListOf()
                            }

                            mapOfEvents[it.from.dayOfWeek.isoDayNumber]?.add(it)
                        }

                        Canvas(
                            modifier = Modifier.size(width = tableWidth, height = 50.dp)
                                .pointerInput(Unit) {
                                    awaitPointerEventScope {
                                        while (true) {
                                            // Await the next pointer event
                                            val event = awaitPointerEvent()
                                            var isThereHoveredEvent = false
                                            val offset =
                                                event.changes.firstOrNull()?.position
                                            if (offset== null) {
                                                isThereHoveredEvent = false
                                                onEventHovered(null)
                                                continue
                                            }
                                            // Check if the pointer hovers over any rectangle
                                            mapOfEvents.forEach { dayEvent ->
                                                dayEvent.value.forEachIndexed { index, event ->
                                                    val numOfEvents = dayEvent.value.size
                                                    val rectSpacingAdd = if (numOfEvents == 1) {
                                                        2 * spacer.dp.toPx()
                                                    } else {
                                                        2 * spacer.dp.toPx() + spacer.dp.toPx() / numOfEvents
                                                    }
                                                    val rectSize =
                                                        celWidth.dp.toPx() / numOfEvents - (numOfEvents + 1) * (spacer.dp.toPx() / numOfEvents)

                                                    val startX =
                                                        tableWidth.toPx() / 7 * (dayEvent.key - 1) + spacer.dp.toPx()
                                                    val rectTopLeft = Offset(
                                                        startX + index * (rectSize + spacer.dp.toPx()),
                                                        0.dp.toPx()
                                                    )
                                                    val rectBottomRight = Offset(
                                                        rectTopLeft.x + rectSize,
                                                        rectTopLeft.y + 50.dp.toPx()
                                                    )

                                                    if (offset.x in rectTopLeft.x..rectBottomRight.x &&
                                                        offset.y in rectTopLeft.y..rectBottomRight.y
                                                    ) {
                                                        // Perform action for the hovered event
                                                        onEventHovered(event)
                                                        isThereHoveredEvent = true
                                                    }


                                                }
                                            }
                                            if (!isThereHoveredEvent) {
                                                onEventHovered(null)
                                            }
                                        }
                                    }
                                }
                                .pointerInput(Unit) {
                                    detectTapGestures { offset ->
                                        // Check if the tap falls within any of the rectangles
                                        mapOfEvents.forEach { dayEvent ->
                                            dayEvent.value.forEachIndexed { index, event ->
                                                val numOfEvents = dayEvent.value.size
                                                val rectSpacingAdd = if (numOfEvents == 1) {
                                                    2 * spacer.dp.toPx()
                                                } else {
                                                    2 * spacer.dp.toPx() + spacer.dp.toPx() / numOfEvents
                                                }
                                                val rectSize =
                                                    celWidth.dp.toPx() / numOfEvents - (numOfEvents + 1) * (spacer.dp.toPx() / numOfEvents)

                                                val startX =
                                                    tableWidth.toPx() / 7 * (dayEvent.key - 1) + spacer.dp.toPx()
                                                val rectTopLeft = Offset(
                                                    startX + index * (rectSize + spacer.dp.toPx()),
                                                    0.dp.toPx()
                                                )
                                                val rectBottomRight = Offset(
                                                    rectTopLeft.x + rectSize,
                                                    rectTopLeft.y + 50.dp.toPx()
                                                )

                                                // Check if the tap is within the rectangle
                                                if (offset.x in rectTopLeft.x..rectBottomRight.x &&
                                                    offset.y in rectTopLeft.y..rectBottomRight.y
                                                ) {
                                                    // Perform action for the clicked event
                                                    println("Clicked on event: $event")
                                                    onEventClicked(event)
                                                }
                                            }
                                        }
                                    }
                                }
                        ) {
                            //lines
                            for (i in 1..16) {
                                drawLine(
                                    borderPathColor,
                                    start = Offset(celWidthDp.dp.toPx() * i, 0f),
                                    end = Offset(
                                        celWidthDp.dp.toPx() * i,
                                        50.dp.toPx()
                                    ),
                                    pathEffect = pathEffect,
                                    strokeWidth = 1.dp.toPx()
                                )
                            }


                            //plans
                            mapOfEvents.forEach {
                                val numOfEvents = it.value.size
                                var rectSpacingAdd = if (numOfEvents == 1) {
                                    2 * spacer.dp.toPx()
                                } else {
                                    2 * spacer.dp.toPx() + spacer.dp.toPx() / numOfEvents
                                }
                                val rectSize =
                                    celWidth.dp.toPx() / numOfEvents - (numOfEvents + 1) * (spacer.dp.toPx() / numOfEvents)

//                                for(blok 1…brojblokova)
//                                offset = spacing + (spaicn + blok_size) * (blok -1)
//                                blocksize = (total - (spaacing *( bloknum + 1)) ) / bloknum

                                // counter starts from 0, so -1
                                val startX =
                                    tableWidth.toPx() / 7 * (it.key - 1) + spacer.dp.toPx()
                                var topLeft = Offset(startX, 0.dp.toPx())
                                it.value.forEachIndexed() { index, event ->
                                    val color = when (event.status) {
                                        EventStatus.COMPLETED -> completedColor
                                        EventStatus.PENDING -> pendingColor
                                        EventStatus.DELAYED -> delayedColor
                                        EventStatus.IN_PROGRESS -> pendingColor
                                        EventStatus.REJECTED -> delayedColor
                                    }

                                    drawRoundRect(
                                        color = color,
                                        topLeft = topLeft,
                                        size = Size(rectSize, 50.dp.toPx()),
                                        cornerRadius = CornerRadius(10f, 10f)
                                    )

                                    topLeft =
                                        Offset(
                                            topLeft.x + rectSize + spacer.dp.toPx(),
                                            0.dp.toPx()
                                        )

                                }


//                                val minX1 = it.from.hour * 60 + it.from.minute
//                                val x1 =
//                                    (tableWidth * (minX1 - (startHour * 60))) / (1440 - (startHour * 60))
//
//                                val minX2 = it.to.hour * 60 + it.to.minute
//                                val x2 =
//                                    (tableWidth * (minX2 - (startHour * 60))) / (1440 - (startHour * 60))
//
//                                val topLeft = Offset(x1.toPx(), 0.dp.toPx())
//                                val size = Size(width = (x2 - x1).toPx(), height = 50.dp.toPx())
//
//                                drawRoundRect(
//                                    color = color,
//                                    topLeft = topLeft,
//                                    size = size,
//                                    cornerRadius = CornerRadius(10f, 10f)
//                                )
//                                val measuredText =
//                                    textMeasurer.measure(
//                                        AnnotatedString(it.title ?: ""),
//                                        constraints = Constraints.fixedWidth(size.width.toInt()),
//                                        overflow = TextOverflow.Ellipsis,
//                                        style = TextStyle(
//                                            fontSize = 18.sp,
//                                            color = Color.Black,
//                                            textAlign = TextAlign.Center
//                                        ),
//                                        maxLines = 1
//                                    )
//                                val textOffsetX =
//                                    topLeft.x + (size.width - measuredText.size.width) / 2
//                                val textOffsetY =
//                                    topLeft.y + 50.dp.toPx() / 2 - measuredText.size.height / 2
//                                drawText(
//                                    measuredText,
//                                    topLeft = Offset(
//                                        textOffsetX,
//                                        textOffsetY
//                                    )
//                                )
                            }
                        }
                    }
                }

            }
        }


        //TODO make font size screen depended
//        Box(
//            modifier = Modifier.fillMaxSize()
//                .padding(horizontal = 20.dp, vertical = 12.dp),
//            contentAlignment = Alignment.BottomCenter
//        ) {
//            Row(
//                modifier = Modifier.shadow(elevation = 2.dp, shape = RoundedCornerShape(10.dp))
//                    .clip(RoundedCornerShape(10.dp))
//                    .background(Color.White).padding(20.dp),
//                verticalAlignment = Alignment.CenterVertically,
//                horizontalArrangement = Arrangement.SpaceBetween
//            ) {
//                Box(modifier = Modifier.size(20.dp).clip(CircleShape).background(completedColor))
//                Text(
//                    "Completed",
//                    textAlign = TextAlign.Center,
//                    modifier = Modifier.padding(horizontal = 12.dp),
//                    fontSize = 12.sp
//                )
//
//                Box(modifier = Modifier.size(20.dp).clip(CircleShape).background(pendingColor))
//                Text(
//                    "Pending",
//                    textAlign = TextAlign.Center,
//                    modifier = Modifier.padding(horizontal = 12.dp),
//                    fontSize = 12.sp
//                )
//
//                Box(modifier = Modifier.size(20.dp).clip(CircleShape).background(delayedColor))
//                Text(
//                    "Delayed",
//                    textAlign = TextAlign.Center,
//                    modifier = Modifier.padding(horizontal = 12.dp),
//                    fontSize = 12.sp
//                )
//            }
//        }
    }
}

fun formatDateToDayMonth(date: LocalDate): String {
    val day = date.dayOfMonth.toString().padStart(2, '0') // Ensure two digits for day
    val month =
        date.month.ordinal.plus(1).toString()
            .padStart(2, '0') // Ensure two digits for month
    return "$day/$month"
}

fun DrawScope.drawVerticalLines(
    celWidthDp: Dp,
    borderColor: Color,
    pathEffect: PathEffect
) {
    for (i in 1..16) {
        drawLine(
            color = borderColor,
            start = Offset(celWidthDp.toPx() * i, 0f),
            end = Offset(celWidthDp.toPx() * i, size.height),
            pathEffect = pathEffect,
            strokeWidth = 1.dp.toPx()
        )
    }
}