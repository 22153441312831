package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all_employees
import com.hyperether.goodjob.resources.employee_deleted
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.WebUserListView
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.DashboardBg
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun EmployeesWebView(
    employeesViewModel: EmployeesViewModel,
    navController: NavHostController
) {
    val log = logging("EmployeesWebView")
    val itemsPerPage = 5
    val currentPage = remember { mutableStateOf(1) }

    var searchText = remember { mutableStateOf("") }
    var searchTextTeam = remember { mutableStateOf("") }
    val sortBy = remember { mutableStateOf<String?>(null) }

    val showFilterDialog = remember { mutableStateOf(false) }
    val isAllSelected = remember { mutableStateOf(true) }
    val isInEditMode = remember { mutableStateOf(false) }
    val isInSearchMode = remember { mutableStateOf(false) }
    val searchQuery = remember { mutableStateOf("") }
    val employees = employeesViewModel.users.collectAsState()

    val selectedEmployees = remember { mutableStateListOf<User>() }
    val filteredEmployees = remember { mutableStateListOf<User>() }
    val showPopup = remember { mutableStateOf(false) }
//    val teams by employeesViewModel.teams_remote.collectAsState(emptyList())
    val selectedTeams = remember { mutableStateOf(mapOf<String, Boolean>()) }
    val currentEmployees = derivedStateOf {
        employees.value.drop((currentPage.value - 1) * itemsPerPage).take(itemsPerPage)
    }
    LaunchedEffect(Unit) {
       employeesViewModel.init()
//        employeesViewModel.getAllUsersRemote()
//        employeesViewModel.employees_remote.collect { employeeList ->
//            employees.clear()
//            employees.addAll(employeeList)
//            filteredEmployees.clear()
//            filteredEmployees.addAll(employeeList)
//            for (e in employees)
//                log.d("EmployeesScreen") { " after collect EMPLOYEES: $e" }
//            for (e in filteredEmployees)
//                log.d("EmployeesScreen") { " after collect filteredEmployees: $e" }
//
//        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
    ) {
        WebViewHeader(
            isAddNewUser = true,
            isBackAvailable = false,
            title = stringResource(Res.string.employees),
            isImportAndAddVisible = true,
            navHostController = navController
        )
        Spacer(modifier = Modifier.height(Dp(21f)))
        StatsSection(teams = employeesViewModel.teamsRemote.size, employeesNumber = employeesViewModel.employees_remote.value.size)

        Spacer(modifier = Modifier.height(Dp(13f)))

        // Employee List
        WebUserListView(
            isEmployeeList = true,
            title = stringResource(Res.string.all_employees),
            allItems = employeesViewModel.employees,
            filteredItems = currentEmployees.value,
            sortBy = sortBy,
            searchText = searchText,
            searchTextTeam = searchTextTeam,
            currentPage = currentPage,
            itemsPerPage = itemsPerPage,
            showFilterDialog = showFilterDialog,
            deleteItemById = { id -> employeesViewModel.deleteEmployeesById(id) },
            saveSelectedItem = { id ->
                log.d("EmployeesScreen") { "SELECTED EMPLOYEE: $id" }
                navController.navigate(Screen.EmployeeDetails.route + "?employeeId=${id}")
            },
            teams = employeesViewModel.teamsRemote,
            selectedTeams = selectedTeams,
            onTeamCreated = { team ->
                employeesViewModel.createTeam(team)
            },
            onCheckedChange = { id, isChecked ->
                val updatedSelection = selectedTeams.value.toMutableMap()
                updatedSelection[id] = isChecked
                selectedTeams.value = updatedSelection
            },
            onDeleteTeamClick = {
                employeesViewModel.showLoader.value = true
                val selectedTeamIds = selectedTeams.value.filterValues { it }.keys
                val selectedTeamList =
                    employeesViewModel.teamsRemote.filter { it._id in selectedTeamIds }
                employeesViewModel.deleteSelectedTeams(
                    selectedTeamList,
                    onAllDeleted = {
                        employeesViewModel.showLoader.value = false
                    },
                    onError = {
                        employeesViewModel.showLoader.value = false
                    }
                )
            }
        )
    }

    if (employeesViewModel.showPopUpSuccess.value) {
        PopUpDialog(
            onDismiss = { employeesViewModel.showPopUpSuccess.value = false },
            title = stringResource(Res.string.success).uppercase(),
            message = stringResource(Res.string.employee_deleted),
            onPositiveBtnClicked = { employeesViewModel.showPopUpSuccess.value = false },
            positiveBtnText = stringResource(Res.string.ok)
        )
    }
    if (employeesViewModel.showPopUpError.value) {
        ErrorDialog(
            onDismiss = { employeesViewModel.showPopUpError.value = false },
            title = stringResource(Res.string.error).uppercase(),
            message = employeesViewModel.errorText.value
        )
    }
    if (employeesViewModel.showLoader.value) {
        FullScreenProgressBar()
    }

    if (showFilterDialog.value) {
//        FilterEmployeesDialogWeb(
//            cities,
//            roles,
//            teams,
//            statuses,
//            skills,
//            onDismiss = { showFilterDialog.value = false },
//            onApply = { selectedCities, selectedRoles, selectedTeams, selectedStatuses, selectedSkills ->
//                log.d("Applied Filter") { "Applied Filters: Cities=$selectedCities, Roles=$selectedRoles, Teams=$selectedTeams, Statuses=$selectedStatuses, Skills=$selectedSkills" }
////                filteredEmployees.clear()
////                val list = employees.map { Mapper().mapDbUserToSerializableUser(it) }
////                filteredEmployees.addAll(
////                    list.filter { employee ->
////                        (selectedCities.isEmpty() || selectedCities.contains(employee.city)) &&
////                                (selectedRoles.isEmpty() || selectedRoles.contains(employee.role)) // &&
//////                                (selectedTeams.isEmpty() || selectedTeams.contains(employee.teams)) &&
//////                                (selectedStatuses.isEmpty() || selectedStatuses.contains(employee.status)) &&
//////                                (selectedSkills.isEmpty() || selectedSkills.any { skill -> skill in employee.skills })
////                    }
////                )
//                showFilterDialog.value = false
//            }
//        )
    }
}