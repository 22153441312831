package com.hyperether.goodjob.scenes.components

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.selection.TextSelectionColors
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.search
import com.hyperether.goodjob.resources.search_users
import org.jetbrains.compose.resources.stringResource

@Composable
fun SearchField(
    searchQuery: MutableState<String>
) {

    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        TextField(
            value = searchQuery.value,
            onValueChange = { searchQuery.value = it },
            placeholder = {
                Text(
                    stringResource(Res.string.search),
                    color = Color.LightGray
                )
            },
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 16.dp, end = 16.dp)
                .border(1.dp, Color.Gray, RoundedCornerShape(25.dp))
                .clip(RoundedCornerShape(25.dp)),
            trailingIcon = {
                Icon(
                    imageVector = Icons.Default.Search,
                    contentDescription = "Search Icon"
                )
            },
            singleLine = true,
            colors = TextFieldDefaults.colors(
                unfocusedContainerColor = Color.Transparent,
                focusedContainerColor = Color.Transparent,
                selectionColors = TextSelectionColors(
                    backgroundColor = Color.Transparent,
                    handleColor = Color.Transparent
                )
            )
        )
    }
}