@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.hyperether.goodjob.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val admin_svgrepo_com_1: DrawableResource by 
      lazy { init_admin_svgrepo_com_1() }

  public val app_logo: DrawableResource by 
      lazy { init_app_logo() }

  public val apple: DrawableResource by 
      lazy { init_apple() }

  public val avatar_female: DrawableResource by 
      lazy { init_avatar_female() }

  public val avatar_male: DrawableResource by 
      lazy { init_avatar_male() }

  public val back_arrow: DrawableResource by 
      lazy { init_back_arrow() }

  public val background_shape: DrawableResource by 
      lazy { init_background_shape() }

  public val baseline_arrow_drop_down_24: DrawableResource by 
      lazy { init_baseline_arrow_drop_down_24() }

  public val baseline_file_present_24: DrawableResource by 
      lazy { init_baseline_file_present_24() }

  public val baseline_person_24: DrawableResource by 
      lazy { init_baseline_person_24() }

  public val bg_shape_dark: DrawableResource by 
      lazy { init_bg_shape_dark() }

  public val briefcase: DrawableResource by 
      lazy { init_briefcase() }

  public val briefcase2: DrawableResource by 
      lazy { init_briefcase2() }

  public val briefcase_unselect: DrawableResource by 
      lazy { init_briefcase_unselect() }

  public val building: DrawableResource by 
      lazy { init_building() }

  public val calendar: DrawableResource by 
      lazy { init_calendar() }

  public val calendar__1_: DrawableResource by 
      lazy { init_calendar__1_() }

  public val calendar_unselect: DrawableResource by 
      lazy { init_calendar_unselect() }

  public val check_circle: DrawableResource by 
      lazy { init_check_circle() }

  public val check_circle_1: DrawableResource by 
      lazy { init_check_circle_1() }

  public val check_mark: DrawableResource by 
      lazy { init_check_mark() }

  public val chevron_left: DrawableResource by 
      lazy { init_chevron_left() }

  public val chevron_right: DrawableResource by 
      lazy { init_chevron_right() }

  public val city_icon: DrawableResource by 
      lazy { init_city_icon() }

  public val city_icon_24: DrawableResource by 
      lazy { init_city_icon_24() }

  public val clipboard_small: DrawableResource by 
      lazy { init_clipboard_small() }

  public val clock: DrawableResource by 
      lazy { init_clock() }

  public val clock_small: DrawableResource by 
      lazy { init_clock_small() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val contact_ic: DrawableResource by 
      lazy { init_contact_ic() }

  public val contact_type: DrawableResource by 
      lazy { init_contact_type() }

  public val contact_type_24: DrawableResource by 
      lazy { init_contact_type_24() }

  public val contacts_mail: DrawableResource by 
      lazy { init_contacts_mail() }

  public val contacts_map_pin: DrawableResource by 
      lazy { init_contacts_map_pin() }

  public val contacts_share: DrawableResource by 
      lazy { init_contacts_share() }

  public val country_icon: DrawableResource by 
      lazy { init_country_icon() }

  public val country_icon_24: DrawableResource by 
      lazy { init_country_icon_24() }

  public val dahboard: DrawableResource by 
      lazy { init_dahboard() }

  public val dahboard_unselect: DrawableResource by 
      lazy { init_dahboard_unselect() }

  public val dropdown_arrow: DrawableResource by 
      lazy { init_dropdown_arrow() }

  public val edit: DrawableResource by 
      lazy { init_edit() }

  public val email_ic: DrawableResource by 
      lazy { init_email_ic() }

  public val email_send_icon: DrawableResource by 
      lazy { init_email_send_icon() }

  public val email_send_icon_24: DrawableResource by 
      lazy { init_email_send_icon_24() }

  public val employees: DrawableResource by 
      lazy { init_employees() }

  public val employees_job_item: DrawableResource by 
      lazy { init_employees_job_item() }

  public val employees_small: DrawableResource by 
      lazy { init_employees_small() }

  public val employees_unselect: DrawableResource by 
      lazy { init_employees_unselect() }

  public val fb: DrawableResource by 
      lazy { init_fb() }

  public val file_text__1_: DrawableResource by 
      lazy { init_file_text__1_() }

  public val filter_svgrepo_com_1: DrawableResource by 
      lazy { init_filter_svgrepo_com_1() }

  public val google: DrawableResource by 
      lazy { init_google() }

  public val handshake_thin_svgrepo_com_1: DrawableResource by 
      lazy { init_handshake_thin_svgrepo_com_1() }

  public val ic_actions_paper_check: DrawableResource by 
      lazy { init_ic_actions_paper_check() }

  public val ic_active_card: DrawableResource by 
      lazy { init_ic_active_card() }

  public val ic_back: DrawableResource by 
      lazy { init_ic_back() }

  public val ic_calendar: DrawableResource by 
      lazy { init_ic_calendar() }

  public val ic_check_white: DrawableResource by 
      lazy { init_ic_check_white() }

  public val ic_checkbox: DrawableResource by 
      lazy { init_ic_checkbox() }

  public val ic_leave: DrawableResource by 
      lazy { init_ic_leave() }

  public val ic_logout: DrawableResource by 
      lazy { init_ic_logout() }

  public val ic_logout_selected: DrawableResource by 
      lazy { init_ic_logout_selected() }

  public val ic_mail: DrawableResource by 
      lazy { init_ic_mail() }

  public val ic_map_pin: DrawableResource by 
      lazy { init_ic_map_pin() }

  public val ic_members_card: DrawableResource by 
      lazy { init_ic_members_card() }

  public val ic_microsoft: DrawableResource by 
      lazy { init_ic_microsoft() }

  public val ic_phone: DrawableResource by 
      lazy { init_ic_phone() }

  public val ic_search: DrawableResource by 
      lazy { init_ic_search() }

  public val ic_settings: DrawableResource by 
      lazy { init_ic_settings() }

  public val ic_settings_selected: DrawableResource by 
      lazy { init_ic_settings_selected() }

  public val ic_skills: DrawableResource by 
      lazy { init_ic_skills() }

  public val ic_teams_card: DrawableResource by 
      lazy { init_ic_teams_card() }

  public val ic_user: DrawableResource by 
      lazy { init_ic_user() }

  public val ic_users: DrawableResource by 
      lazy { init_ic_users() }

  public val ic_work_scheduler: DrawableResource by 
      lazy { init_ic_work_scheduler() }

  public val icon: DrawableResource by 
      lazy { init_icon() }

  public val landing_img: DrawableResource by 
      lazy { init_landing_img() }

  public val landing_img_dark: DrawableResource by 
      lazy { init_landing_img_dark() }

  public val layout: DrawableResource by 
      lazy { init_layout() }

  public val location_plus: DrawableResource by 
      lazy { init_location_plus() }

  public val log_out__1_: DrawableResource by 
      lazy { init_log_out__1_() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val logo_dark_landing: DrawableResource by 
      lazy { init_logo_dark_landing() }

  public val logo_good_job: DrawableResource by 
      lazy { init_logo_good_job() }

  public val mail: DrawableResource by 
      lazy { init_mail() }

  public val map: DrawableResource by 
      lazy { init_map() }

  public val map_job_item: DrawableResource by 
      lazy { init_map_job_item() }

  public val map_pin: DrawableResource by 
      lazy { init_map_pin() }

  public val map_small: DrawableResource by 
      lazy { init_map_small() }

  public val map_unselect: DrawableResource by 
      lazy { init_map_unselect() }

  public val notification: DrawableResource by 
      lazy { init_notification() }

  public val notification_unselected: DrawableResource by 
      lazy { init_notification_unselected() }

  public val onboarding_image1: DrawableResource by 
      lazy { init_onboarding_image1() }

  public val onboarding_image2: DrawableResource by 
      lazy { init_onboarding_image2() }

  public val pass_ic: DrawableResource by 
      lazy { init_pass_ic() }

  public val password_not_visible: DrawableResource by 
      lazy { init_password_not_visible() }

  public val password_visible: DrawableResource by 
      lazy { init_password_visible() }

  public val phone: DrawableResource by 
      lazy { init_phone() }

  public val phone_24: DrawableResource by 
      lazy { init_phone_24() }

  public val photo_upload_area: DrawableResource by 
      lazy { init_photo_upload_area() }

  public val pin_cancelled: DrawableResource by 
      lazy { init_pin_cancelled() }

  public val pin_completed: DrawableResource by 
      lazy { init_pin_completed() }

  public val pin_pending: DrawableResource by 
      lazy { init_pin_pending() }

  public val plus: DrawableResource by 
      lazy { init_plus() }

  public val premium: DrawableResource by 
      lazy { init_premium() }

  public val premium_dark: DrawableResource by 
      lazy { init_premium_dark() }

  public val profile_pic_placeholder: DrawableResource by 
      lazy { init_profile_pic_placeholder() }

  public val small_gray_map: DrawableResource by 
      lazy { init_small_gray_map() }

  public val status_check_icon: DrawableResource by 
      lazy { init_status_check_icon() }

  public val team: DrawableResource by 
      lazy { init_team() }

  public val trash: DrawableResource by 
      lazy { init_trash() }

  public val upload_cloud: DrawableResource by 
      lazy { init_upload_cloud() }

  public val user: DrawableResource by 
      lazy { init_user() }

  public val user_check: DrawableResource by 
      lazy { init_user_check() }

  public val user_check_job_item: DrawableResource by 
      lazy { init_user_check_job_item() }

  public val user_check_small: DrawableResource by 
      lazy { init_user_check_small() }

  public val user_check_unselect: DrawableResource by 
      lazy { init_user_check_unselect() }

  public val user_plus: DrawableResource by 
      lazy { init_user_plus() }

  public val web_left_content: DrawableResource by 
      lazy { init_web_left_content() }

  public val world_2_svgrepo_com_1: DrawableResource by 
      lazy { init_world_2_svgrepo_com_1() }

  public val zip_icon: DrawableResource by 
      lazy { init_zip_icon() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("admin_svgrepo_com_1", CommonMainDrawable0.admin_svgrepo_com_1)
  map.put("app_logo", CommonMainDrawable0.app_logo)
  map.put("apple", CommonMainDrawable0.apple)
  map.put("avatar_female", CommonMainDrawable0.avatar_female)
  map.put("avatar_male", CommonMainDrawable0.avatar_male)
  map.put("back_arrow", CommonMainDrawable0.back_arrow)
  map.put("background_shape", CommonMainDrawable0.background_shape)
  map.put("baseline_arrow_drop_down_24", CommonMainDrawable0.baseline_arrow_drop_down_24)
  map.put("baseline_file_present_24", CommonMainDrawable0.baseline_file_present_24)
  map.put("baseline_person_24", CommonMainDrawable0.baseline_person_24)
  map.put("bg_shape_dark", CommonMainDrawable0.bg_shape_dark)
  map.put("briefcase", CommonMainDrawable0.briefcase)
  map.put("briefcase2", CommonMainDrawable0.briefcase2)
  map.put("briefcase_unselect", CommonMainDrawable0.briefcase_unselect)
  map.put("building", CommonMainDrawable0.building)
  map.put("calendar", CommonMainDrawable0.calendar)
  map.put("calendar__1_", CommonMainDrawable0.calendar__1_)
  map.put("calendar_unselect", CommonMainDrawable0.calendar_unselect)
  map.put("check_circle", CommonMainDrawable0.check_circle)
  map.put("check_circle_1", CommonMainDrawable0.check_circle_1)
  map.put("check_mark", CommonMainDrawable0.check_mark)
  map.put("chevron_left", CommonMainDrawable0.chevron_left)
  map.put("chevron_right", CommonMainDrawable0.chevron_right)
  map.put("city_icon", CommonMainDrawable0.city_icon)
  map.put("city_icon_24", CommonMainDrawable0.city_icon_24)
  map.put("clipboard_small", CommonMainDrawable0.clipboard_small)
  map.put("clock", CommonMainDrawable0.clock)
  map.put("clock_small", CommonMainDrawable0.clock_small)
  map.put("compose_multiplatform", CommonMainDrawable0.compose_multiplatform)
  map.put("contact_ic", CommonMainDrawable0.contact_ic)
  map.put("contact_type", CommonMainDrawable0.contact_type)
  map.put("contact_type_24", CommonMainDrawable0.contact_type_24)
  map.put("contacts_mail", CommonMainDrawable0.contacts_mail)
  map.put("contacts_map_pin", CommonMainDrawable0.contacts_map_pin)
  map.put("contacts_share", CommonMainDrawable0.contacts_share)
  map.put("country_icon", CommonMainDrawable0.country_icon)
  map.put("country_icon_24", CommonMainDrawable0.country_icon_24)
  map.put("dahboard", CommonMainDrawable0.dahboard)
  map.put("dahboard_unselect", CommonMainDrawable0.dahboard_unselect)
  map.put("dropdown_arrow", CommonMainDrawable0.dropdown_arrow)
  map.put("edit", CommonMainDrawable0.edit)
  map.put("email_ic", CommonMainDrawable0.email_ic)
  map.put("email_send_icon", CommonMainDrawable0.email_send_icon)
  map.put("email_send_icon_24", CommonMainDrawable0.email_send_icon_24)
  map.put("employees", CommonMainDrawable0.employees)
  map.put("employees_job_item", CommonMainDrawable0.employees_job_item)
  map.put("employees_small", CommonMainDrawable0.employees_small)
  map.put("employees_unselect", CommonMainDrawable0.employees_unselect)
  map.put("fb", CommonMainDrawable0.fb)
  map.put("file_text__1_", CommonMainDrawable0.file_text__1_)
  map.put("filter_svgrepo_com_1", CommonMainDrawable0.filter_svgrepo_com_1)
  map.put("google", CommonMainDrawable0.google)
  map.put("handshake_thin_svgrepo_com_1", CommonMainDrawable0.handshake_thin_svgrepo_com_1)
  map.put("ic_actions_paper_check", CommonMainDrawable0.ic_actions_paper_check)
  map.put("ic_active_card", CommonMainDrawable0.ic_active_card)
  map.put("ic_back", CommonMainDrawable0.ic_back)
  map.put("ic_calendar", CommonMainDrawable0.ic_calendar)
  map.put("ic_check_white", CommonMainDrawable0.ic_check_white)
  map.put("ic_checkbox", CommonMainDrawable0.ic_checkbox)
  map.put("ic_leave", CommonMainDrawable0.ic_leave)
  map.put("ic_logout", CommonMainDrawable0.ic_logout)
  map.put("ic_logout_selected", CommonMainDrawable0.ic_logout_selected)
  map.put("ic_mail", CommonMainDrawable0.ic_mail)
  map.put("ic_map_pin", CommonMainDrawable0.ic_map_pin)
  map.put("ic_members_card", CommonMainDrawable0.ic_members_card)
  map.put("ic_microsoft", CommonMainDrawable0.ic_microsoft)
  map.put("ic_phone", CommonMainDrawable0.ic_phone)
  map.put("ic_search", CommonMainDrawable0.ic_search)
  map.put("ic_settings", CommonMainDrawable0.ic_settings)
  map.put("ic_settings_selected", CommonMainDrawable0.ic_settings_selected)
  map.put("ic_skills", CommonMainDrawable0.ic_skills)
  map.put("ic_teams_card", CommonMainDrawable0.ic_teams_card)
  map.put("ic_user", CommonMainDrawable0.ic_user)
  map.put("ic_users", CommonMainDrawable0.ic_users)
  map.put("ic_work_scheduler", CommonMainDrawable0.ic_work_scheduler)
  map.put("icon", CommonMainDrawable0.icon)
  map.put("landing_img", CommonMainDrawable0.landing_img)
  map.put("landing_img_dark", CommonMainDrawable0.landing_img_dark)
  map.put("layout", CommonMainDrawable0.layout)
  map.put("location_plus", CommonMainDrawable0.location_plus)
  map.put("log_out__1_", CommonMainDrawable0.log_out__1_)
  map.put("logo", CommonMainDrawable0.logo)
  map.put("logo_dark_landing", CommonMainDrawable0.logo_dark_landing)
  map.put("logo_good_job", CommonMainDrawable0.logo_good_job)
  map.put("mail", CommonMainDrawable0.mail)
  map.put("map", CommonMainDrawable0.map)
  map.put("map_job_item", CommonMainDrawable0.map_job_item)
  map.put("map_pin", CommonMainDrawable0.map_pin)
  map.put("map_small", CommonMainDrawable0.map_small)
  map.put("map_unselect", CommonMainDrawable0.map_unselect)
  map.put("notification", CommonMainDrawable0.notification)
  map.put("notification_unselected", CommonMainDrawable0.notification_unselected)
  map.put("onboarding_image1", CommonMainDrawable0.onboarding_image1)
  map.put("onboarding_image2", CommonMainDrawable0.onboarding_image2)
  map.put("pass_ic", CommonMainDrawable0.pass_ic)
  map.put("password_not_visible", CommonMainDrawable0.password_not_visible)
  map.put("password_visible", CommonMainDrawable0.password_visible)
  map.put("phone", CommonMainDrawable0.phone)
  map.put("phone_24", CommonMainDrawable0.phone_24)
  map.put("photo_upload_area", CommonMainDrawable0.photo_upload_area)
  map.put("pin_cancelled", CommonMainDrawable0.pin_cancelled)
  map.put("pin_completed", CommonMainDrawable0.pin_completed)
  map.put("pin_pending", CommonMainDrawable0.pin_pending)
  map.put("plus", CommonMainDrawable0.plus)
  map.put("premium", CommonMainDrawable0.premium)
  map.put("premium_dark", CommonMainDrawable0.premium_dark)
  map.put("profile_pic_placeholder", CommonMainDrawable0.profile_pic_placeholder)
  map.put("small_gray_map", CommonMainDrawable0.small_gray_map)
  map.put("status_check_icon", CommonMainDrawable0.status_check_icon)
  map.put("team", CommonMainDrawable0.team)
  map.put("trash", CommonMainDrawable0.trash)
  map.put("upload_cloud", CommonMainDrawable0.upload_cloud)
  map.put("user", CommonMainDrawable0.user)
  map.put("user_check", CommonMainDrawable0.user_check)
  map.put("user_check_job_item", CommonMainDrawable0.user_check_job_item)
  map.put("user_check_small", CommonMainDrawable0.user_check_small)
  map.put("user_check_unselect", CommonMainDrawable0.user_check_unselect)
  map.put("user_plus", CommonMainDrawable0.user_plus)
  map.put("web_left_content", CommonMainDrawable0.web_left_content)
  map.put("world_2_svgrepo_com_1", CommonMainDrawable0.world_2_svgrepo_com_1)
  map.put("zip_icon", CommonMainDrawable0.zip_icon)
}

public val Res.drawable.admin_svgrepo_com_1: DrawableResource
  get() = CommonMainDrawable0.admin_svgrepo_com_1

private fun init_admin_svgrepo_com_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:admin_svgrepo_com_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/admin_svgrepo_com_1.xml", -1, -1),
    )
)

public val Res.drawable.app_logo: DrawableResource
  get() = CommonMainDrawable0.app_logo

private fun init_app_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/app_logo.xml", -1, -1),
    )
)

public val Res.drawable.apple: DrawableResource
  get() = CommonMainDrawable0.apple

private fun init_apple(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:apple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/apple.xml", -1, -1),
    )
)

public val Res.drawable.avatar_female: DrawableResource
  get() = CommonMainDrawable0.avatar_female

private fun init_avatar_female(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_female",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/avatar_female.png", -1, -1),
    )
)

public val Res.drawable.avatar_male: DrawableResource
  get() = CommonMainDrawable0.avatar_male

private fun init_avatar_male(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_male",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/avatar_male.png", -1, -1),
    )
)

public val Res.drawable.back_arrow: DrawableResource
  get() = CommonMainDrawable0.back_arrow

private fun init_back_arrow(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:back_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/back_arrow.xml", -1, -1),
    )
)

public val Res.drawable.background_shape: DrawableResource
  get() = CommonMainDrawable0.background_shape

private fun init_background_shape(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:background_shape",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/background_shape.png", -1, -1),
    )
)

public val Res.drawable.baseline_arrow_drop_down_24: DrawableResource
  get() = CommonMainDrawable0.baseline_arrow_drop_down_24

private fun init_baseline_arrow_drop_down_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_arrow_drop_down_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/baseline_arrow_drop_down_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_file_present_24: DrawableResource
  get() = CommonMainDrawable0.baseline_file_present_24

private fun init_baseline_file_present_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_file_present_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/baseline_file_present_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_person_24: DrawableResource
  get() = CommonMainDrawable0.baseline_person_24

private fun init_baseline_person_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_person_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/baseline_person_24.xml", -1, -1),
    )
)

public val Res.drawable.bg_shape_dark: DrawableResource
  get() = CommonMainDrawable0.bg_shape_dark

private fun init_bg_shape_dark(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:bg_shape_dark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/bg_shape_dark.png", -1, -1),
    )
)

public val Res.drawable.briefcase: DrawableResource
  get() = CommonMainDrawable0.briefcase

private fun init_briefcase(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:briefcase",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/briefcase.xml", -1, -1),
    )
)

public val Res.drawable.briefcase2: DrawableResource
  get() = CommonMainDrawable0.briefcase2

private fun init_briefcase2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:briefcase2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/briefcase2.xml", -1, -1),
    )
)

public val Res.drawable.briefcase_unselect: DrawableResource
  get() = CommonMainDrawable0.briefcase_unselect

private fun init_briefcase_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:briefcase_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/briefcase_unselect.xml", -1, -1),
    )
)

public val Res.drawable.building: DrawableResource
  get() = CommonMainDrawable0.building

private fun init_building(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:building",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/building.xml", -1, -1),
    )
)

public val Res.drawable.calendar: DrawableResource
  get() = CommonMainDrawable0.calendar

private fun init_calendar(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:calendar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/calendar.xml", -1, -1),
    )
)

public val Res.drawable.calendar__1_: DrawableResource
  get() = CommonMainDrawable0.calendar__1_

private fun init_calendar__1_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:calendar__1_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/calendar__1_.xml", -1, -1),
    )
)

public val Res.drawable.calendar_unselect: DrawableResource
  get() = CommonMainDrawable0.calendar_unselect

private fun init_calendar_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:calendar_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/calendar_unselect.xml", -1, -1),
    )
)

public val Res.drawable.check_circle: DrawableResource
  get() = CommonMainDrawable0.check_circle

private fun init_check_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:check_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/check_circle.xml", -1, -1),
    )
)

public val Res.drawable.check_circle_1: DrawableResource
  get() = CommonMainDrawable0.check_circle_1

private fun init_check_circle_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:check_circle_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/check_circle_1.xml", -1, -1),
    )
)

public val Res.drawable.check_mark: DrawableResource
  get() = CommonMainDrawable0.check_mark

private fun init_check_mark(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:check_mark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/check_mark.xml", -1, -1),
    )
)

public val Res.drawable.chevron_left: DrawableResource
  get() = CommonMainDrawable0.chevron_left

private fun init_chevron_left(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chevron_left",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/chevron_left.xml", -1, -1),
    )
)

public val Res.drawable.chevron_right: DrawableResource
  get() = CommonMainDrawable0.chevron_right

private fun init_chevron_right(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chevron_right",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/chevron_right.xml", -1, -1),
    )
)

public val Res.drawable.city_icon: DrawableResource
  get() = CommonMainDrawable0.city_icon

private fun init_city_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:city_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/city_icon.xml", -1, -1),
    )
)

public val Res.drawable.city_icon_24: DrawableResource
  get() = CommonMainDrawable0.city_icon_24

private fun init_city_icon_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:city_icon_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/city_icon_24.xml", -1, -1),
    )
)

public val Res.drawable.clipboard_small: DrawableResource
  get() = CommonMainDrawable0.clipboard_small

private fun init_clipboard_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:clipboard_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/clipboard_small.xml", -1, -1),
    )
)

public val Res.drawable.clock: DrawableResource
  get() = CommonMainDrawable0.clock

private fun init_clock(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clock",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/clock.xml", -1, -1),
    )
)

public val Res.drawable.clock_small: DrawableResource
  get() = CommonMainDrawable0.clock_small

private fun init_clock_small(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clock_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/clock_small.xml", -1, -1),
    )
)

public val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

public val Res.drawable.contact_ic: DrawableResource
  get() = CommonMainDrawable0.contact_ic

private fun init_contact_ic(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:contact_ic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/contact_ic.xml", -1, -1),
    )
)

public val Res.drawable.contact_type: DrawableResource
  get() = CommonMainDrawable0.contact_type

private fun init_contact_type(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contact_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/contact_type.xml", -1, -1),
    )
)

public val Res.drawable.contact_type_24: DrawableResource
  get() = CommonMainDrawable0.contact_type_24

private fun init_contact_type_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contact_type_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/contact_type_24.xml", -1, -1),
    )
)

public val Res.drawable.contacts_mail: DrawableResource
  get() = CommonMainDrawable0.contacts_mail

private fun init_contacts_mail(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contacts_mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/contacts_mail.xml", -1, -1),
    )
)

public val Res.drawable.contacts_map_pin: DrawableResource
  get() = CommonMainDrawable0.contacts_map_pin

private fun init_contacts_map_pin(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contacts_map_pin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/contacts_map_pin.xml", -1, -1),
    )
)

public val Res.drawable.contacts_share: DrawableResource
  get() = CommonMainDrawable0.contacts_share

private fun init_contacts_share(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contacts_share",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/contacts_share.xml", -1, -1),
    )
)

public val Res.drawable.country_icon: DrawableResource
  get() = CommonMainDrawable0.country_icon

private fun init_country_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:country_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/country_icon.xml", -1, -1),
    )
)

public val Res.drawable.country_icon_24: DrawableResource
  get() = CommonMainDrawable0.country_icon_24

private fun init_country_icon_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:country_icon_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/country_icon_24.xml", -1, -1),
    )
)

public val Res.drawable.dahboard: DrawableResource
  get() = CommonMainDrawable0.dahboard

private fun init_dahboard(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:dahboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/dahboard.xml", -1, -1),
    )
)

public val Res.drawable.dahboard_unselect: DrawableResource
  get() = CommonMainDrawable0.dahboard_unselect

private fun init_dahboard_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:dahboard_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/dahboard_unselect.xml", -1, -1),
    )
)

public val Res.drawable.dropdown_arrow: DrawableResource
  get() = CommonMainDrawable0.dropdown_arrow

private fun init_dropdown_arrow(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:dropdown_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/dropdown_arrow.xml", -1, -1),
    )
)

public val Res.drawable.edit: DrawableResource
  get() = CommonMainDrawable0.edit

private fun init_edit(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/edit.xml", -1, -1),
    )
)

public val Res.drawable.email_ic: DrawableResource
  get() = CommonMainDrawable0.email_ic

private fun init_email_ic(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:email_ic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/email_ic.xml", -1, -1),
    )
)

public val Res.drawable.email_send_icon: DrawableResource
  get() = CommonMainDrawable0.email_send_icon

private fun init_email_send_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:email_send_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/email_send_icon.xml", -1, -1),
    )
)

public val Res.drawable.email_send_icon_24: DrawableResource
  get() = CommonMainDrawable0.email_send_icon_24

private fun init_email_send_icon_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:email_send_icon_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/email_send_icon_24.xml", -1, -1),
    )
)

public val Res.drawable.employees: DrawableResource
  get() = CommonMainDrawable0.employees

private fun init_employees(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/employees.xml", -1, -1),
    )
)

public val Res.drawable.employees_job_item: DrawableResource
  get() = CommonMainDrawable0.employees_job_item

private fun init_employees_job_item(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:employees_job_item",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/employees_job_item.xml", -1, -1),
    )
)

public val Res.drawable.employees_small: DrawableResource
  get() = CommonMainDrawable0.employees_small

private fun init_employees_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:employees_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/employees_small.xml", -1, -1),
    )
)

public val Res.drawable.employees_unselect: DrawableResource
  get() = CommonMainDrawable0.employees_unselect

private fun init_employees_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:employees_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/employees_unselect.xml", -1, -1),
    )
)

public val Res.drawable.fb: DrawableResource
  get() = CommonMainDrawable0.fb

private fun init_fb(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:fb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/fb.xml", -1, -1),
    )
)

public val Res.drawable.file_text__1_: DrawableResource
  get() = CommonMainDrawable0.file_text__1_

private fun init_file_text__1_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:file_text__1_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/file_text__1_.xml", -1, -1),
    )
)

public val Res.drawable.filter_svgrepo_com_1: DrawableResource
  get() = CommonMainDrawable0.filter_svgrepo_com_1

private fun init_filter_svgrepo_com_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:filter_svgrepo_com_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/filter_svgrepo_com_1.xml", -1, -1),
    )
)

public val Res.drawable.google: DrawableResource
  get() = CommonMainDrawable0.google

private fun init_google(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/google.xml", -1, -1),
    )
)

public val Res.drawable.handshake_thin_svgrepo_com_1: DrawableResource
  get() = CommonMainDrawable0.handshake_thin_svgrepo_com_1

private fun init_handshake_thin_svgrepo_com_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:handshake_thin_svgrepo_com_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/handshake_thin_svgrepo_com_1.xml", -1, -1),
    )
)

public val Res.drawable.ic_actions_paper_check: DrawableResource
  get() = CommonMainDrawable0.ic_actions_paper_check

private fun init_ic_actions_paper_check(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_actions_paper_check",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_actions_paper_check.xml", -1, -1),
    )
)

public val Res.drawable.ic_active_card: DrawableResource
  get() = CommonMainDrawable0.ic_active_card

private fun init_ic_active_card(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_active_card",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_active_card.xml", -1, -1),
    )
)

public val Res.drawable.ic_back: DrawableResource
  get() = CommonMainDrawable0.ic_back

private fun init_ic_back(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_back.xml", -1, -1),
    )
)

public val Res.drawable.ic_calendar: DrawableResource
  get() = CommonMainDrawable0.ic_calendar

private fun init_ic_calendar(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_calendar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_calendar.xml", -1, -1),
    )
)

public val Res.drawable.ic_check_white: DrawableResource
  get() = CommonMainDrawable0.ic_check_white

private fun init_ic_check_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_check_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_check_white.xml", -1, -1),
    )
)

public val Res.drawable.ic_checkbox: DrawableResource
  get() = CommonMainDrawable0.ic_checkbox

private fun init_ic_checkbox(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_checkbox",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_checkbox.xml", -1, -1),
    )
)

public val Res.drawable.ic_leave: DrawableResource
  get() = CommonMainDrawable0.ic_leave

private fun init_ic_leave(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_leave",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_leave.xml", -1, -1),
    )
)

public val Res.drawable.ic_logout: DrawableResource
  get() = CommonMainDrawable0.ic_logout

private fun init_ic_logout(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_logout",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_logout.xml", -1, -1),
    )
)

public val Res.drawable.ic_logout_selected: DrawableResource
  get() = CommonMainDrawable0.ic_logout_selected

private fun init_ic_logout_selected(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_logout_selected",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_logout_selected.xml", -1, -1),
    )
)

public val Res.drawable.ic_mail: DrawableResource
  get() = CommonMainDrawable0.ic_mail

private fun init_ic_mail(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_mail.xml", -1, -1),
    )
)

public val Res.drawable.ic_map_pin: DrawableResource
  get() = CommonMainDrawable0.ic_map_pin

private fun init_ic_map_pin(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_map_pin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_map_pin.xml", -1, -1),
    )
)

public val Res.drawable.ic_members_card: DrawableResource
  get() = CommonMainDrawable0.ic_members_card

private fun init_ic_members_card(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_members_card",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_members_card.xml", -1, -1),
    )
)

public val Res.drawable.ic_microsoft: DrawableResource
  get() = CommonMainDrawable0.ic_microsoft

private fun init_ic_microsoft(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_microsoft",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_microsoft.xml", -1, -1),
    )
)

public val Res.drawable.ic_phone: DrawableResource
  get() = CommonMainDrawable0.ic_phone

private fun init_ic_phone(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_phone.xml", -1, -1),
    )
)

public val Res.drawable.ic_search: DrawableResource
  get() = CommonMainDrawable0.ic_search

private fun init_ic_search(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_search",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_search.xml", -1, -1),
    )
)

public val Res.drawable.ic_settings: DrawableResource
  get() = CommonMainDrawable0.ic_settings

private fun init_ic_settings(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_settings.xml", -1, -1),
    )
)

public val Res.drawable.ic_settings_selected: DrawableResource
  get() = CommonMainDrawable0.ic_settings_selected

private fun init_ic_settings_selected(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_settings_selected",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_settings_selected.xml", -1, -1),
    )
)

public val Res.drawable.ic_skills: DrawableResource
  get() = CommonMainDrawable0.ic_skills

private fun init_ic_skills(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_skills",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_skills.xml", -1, -1),
    )
)

public val Res.drawable.ic_teams_card: DrawableResource
  get() = CommonMainDrawable0.ic_teams_card

private fun init_ic_teams_card(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_teams_card",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_teams_card.xml", -1, -1),
    )
)

public val Res.drawable.ic_user: DrawableResource
  get() = CommonMainDrawable0.ic_user

private fun init_ic_user(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_user.xml", -1, -1),
    )
)

public val Res.drawable.ic_users: DrawableResource
  get() = CommonMainDrawable0.ic_users

private fun init_ic_users(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_users",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_users.xml", -1, -1),
    )
)

public val Res.drawable.ic_work_scheduler: DrawableResource
  get() = CommonMainDrawable0.ic_work_scheduler

private fun init_ic_work_scheduler(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_work_scheduler",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/ic_work_scheduler.xml", -1, -1),
    )
)

public val Res.drawable.icon: DrawableResource
  get() = CommonMainDrawable0.icon

private fun init_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/icon.xml", -1, -1),
    )
)

public val Res.drawable.landing_img: DrawableResource
  get() = CommonMainDrawable0.landing_img

private fun init_landing_img(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:landing_img",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/landing_img.xml", -1, -1),
    )
)

public val Res.drawable.landing_img_dark: DrawableResource
  get() = CommonMainDrawable0.landing_img_dark

private fun init_landing_img_dark(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:landing_img_dark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/landing_img_dark.xml", -1, -1),
    )
)

public val Res.drawable.layout: DrawableResource
  get() = CommonMainDrawable0.layout

private fun init_layout(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:layout",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/layout.xml", -1, -1),
    )
)

public val Res.drawable.location_plus: DrawableResource
  get() = CommonMainDrawable0.location_plus

private fun init_location_plus(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:location_plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/location_plus.xml", -1, -1),
    )
)

public val Res.drawable.log_out__1_: DrawableResource
  get() = CommonMainDrawable0.log_out__1_

private fun init_log_out__1_(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:log_out__1_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/log_out__1_.xml", -1, -1),
    )
)

public val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/logo.xml", -1, -1),
    )
)

public val Res.drawable.logo_dark_landing: DrawableResource
  get() = CommonMainDrawable0.logo_dark_landing

private fun init_logo_dark_landing(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_dark_landing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/logo_dark_landing.xml", -1, -1),
    )
)

public val Res.drawable.logo_good_job: DrawableResource
  get() = CommonMainDrawable0.logo_good_job

private fun init_logo_good_job(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_good_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/logo_good_job.xml", -1, -1),
    )
)

public val Res.drawable.mail: DrawableResource
  get() = CommonMainDrawable0.mail

private fun init_mail(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/mail.xml", -1, -1),
    )
)

public val Res.drawable.map: DrawableResource
  get() = CommonMainDrawable0.map

private fun init_map(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:map",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map.xml", -1, -1),
    )
)

public val Res.drawable.map_job_item: DrawableResource
  get() = CommonMainDrawable0.map_job_item

private fun init_map_job_item(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:map_job_item",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map_job_item.xml", -1, -1),
    )
)

public val Res.drawable.map_pin: DrawableResource
  get() = CommonMainDrawable0.map_pin

private fun init_map_pin(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:map_pin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map_pin.xml", -1, -1),
    )
)

public val Res.drawable.map_small: DrawableResource
  get() = CommonMainDrawable0.map_small

private fun init_map_small(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:map_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map_small.xml", -1, -1),
    )
)

public val Res.drawable.map_unselect: DrawableResource
  get() = CommonMainDrawable0.map_unselect

private fun init_map_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:map_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map_unselect.xml", -1, -1),
    )
)

public val Res.drawable.notification: DrawableResource
  get() = CommonMainDrawable0.notification

private fun init_notification(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:notification",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/notification.xml", -1, -1),
    )
)

public val Res.drawable.notification_unselected: DrawableResource
  get() = CommonMainDrawable0.notification_unselected

private fun init_notification_unselected(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:notification_unselected",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/notification_unselected.xml", -1, -1),
    )
)

public val Res.drawable.onboarding_image1: DrawableResource
  get() = CommonMainDrawable0.onboarding_image1

private fun init_onboarding_image1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:onboarding_image1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/onboarding_image1.png", -1, -1),
    )
)

public val Res.drawable.onboarding_image2: DrawableResource
  get() = CommonMainDrawable0.onboarding_image2

private fun init_onboarding_image2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:onboarding_image2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/onboarding_image2.png", -1, -1),
    )
)

public val Res.drawable.pass_ic: DrawableResource
  get() = CommonMainDrawable0.pass_ic

private fun init_pass_ic(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pass_ic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/pass_ic.xml", -1, -1),
    )
)

public val Res.drawable.password_not_visible: DrawableResource
  get() = CommonMainDrawable0.password_not_visible

private fun init_password_not_visible(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:password_not_visible",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/password_not_visible.xml", -1, -1),
    )
)

public val Res.drawable.password_visible: DrawableResource
  get() = CommonMainDrawable0.password_visible

private fun init_password_visible(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:password_visible",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/password_visible.xml", -1, -1),
    )
)

public val Res.drawable.phone: DrawableResource
  get() = CommonMainDrawable0.phone

private fun init_phone(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/phone.xml", -1, -1),
    )
)

public val Res.drawable.phone_24: DrawableResource
  get() = CommonMainDrawable0.phone_24

private fun init_phone_24(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:phone_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/phone_24.xml", -1, -1),
    )
)

public val Res.drawable.photo_upload_area: DrawableResource
  get() = CommonMainDrawable0.photo_upload_area

private fun init_photo_upload_area(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:photo_upload_area",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/photo_upload_area.png", -1, -1),
    )
)

public val Res.drawable.pin_cancelled: DrawableResource
  get() = CommonMainDrawable0.pin_cancelled

private fun init_pin_cancelled(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:pin_cancelled",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/pin_cancelled.xml", -1, -1),
    )
)

public val Res.drawable.pin_completed: DrawableResource
  get() = CommonMainDrawable0.pin_completed

private fun init_pin_completed(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:pin_completed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/pin_completed.xml", -1, -1),
    )
)

public val Res.drawable.pin_pending: DrawableResource
  get() = CommonMainDrawable0.pin_pending

private fun init_pin_pending(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pin_pending",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/pin_pending.xml", -1, -1),
    )
)

public val Res.drawable.plus: DrawableResource
  get() = CommonMainDrawable0.plus

private fun init_plus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/plus.xml", -1, -1),
    )
)

public val Res.drawable.premium: DrawableResource
  get() = CommonMainDrawable0.premium

private fun init_premium(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:premium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/premium.xml", -1, -1),
    )
)

public val Res.drawable.premium_dark: DrawableResource
  get() = CommonMainDrawable0.premium_dark

private fun init_premium_dark(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:premium_dark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/premium_dark.xml", -1, -1),
    )
)

public val Res.drawable.profile_pic_placeholder: DrawableResource
  get() = CommonMainDrawable0.profile_pic_placeholder

private fun init_profile_pic_placeholder(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_pic_placeholder",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/profile_pic_placeholder.xml", -1, -1),
    )
)

public val Res.drawable.small_gray_map: DrawableResource
  get() = CommonMainDrawable0.small_gray_map

private fun init_small_gray_map(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:small_gray_map",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/small_gray_map.xml", -1, -1),
    )
)

public val Res.drawable.status_check_icon: DrawableResource
  get() = CommonMainDrawable0.status_check_icon

private fun init_status_check_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:status_check_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/status_check_icon.png", -1, -1),
    )
)

public val Res.drawable.team: DrawableResource
  get() = CommonMainDrawable0.team

private fun init_team(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/team.xml", -1, -1),
    )
)

public val Res.drawable.trash: DrawableResource
  get() = CommonMainDrawable0.trash

private fun init_trash(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:trash",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/trash.xml", -1, -1),
    )
)

public val Res.drawable.upload_cloud: DrawableResource
  get() = CommonMainDrawable0.upload_cloud

private fun init_upload_cloud(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:upload_cloud",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/upload_cloud.xml", -1, -1),
    )
)

public val Res.drawable.user: DrawableResource
  get() = CommonMainDrawable0.user

private fun init_user(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user.xml", -1, -1),
    )
)

public val Res.drawable.user_check: DrawableResource
  get() = CommonMainDrawable0.user_check

private fun init_user_check(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_check",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_check.xml", -1, -1),
    )
)

public val Res.drawable.user_check_job_item: DrawableResource
  get() = CommonMainDrawable0.user_check_job_item

private fun init_user_check_job_item(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_check_job_item",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_check_job_item.xml", -1, -1),
    )
)

public val Res.drawable.user_check_small: DrawableResource
  get() = CommonMainDrawable0.user_check_small

private fun init_user_check_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_check_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_check_small.xml", -1, -1),
    )
)

public val Res.drawable.user_check_unselect: DrawableResource
  get() = CommonMainDrawable0.user_check_unselect

private fun init_user_check_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_check_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_check_unselect.xml", -1, -1),
    )
)

public val Res.drawable.user_plus: DrawableResource
  get() = CommonMainDrawable0.user_plus

private fun init_user_plus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_plus.xml", -1, -1),
    )
)

public val Res.drawable.web_left_content: DrawableResource
  get() = CommonMainDrawable0.web_left_content

private fun init_web_left_content(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:web_left_content",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/web_left_content.xml", -1, -1),
    )
)

public val Res.drawable.world_2_svgrepo_com_1: DrawableResource
  get() = CommonMainDrawable0.world_2_svgrepo_com_1

private fun init_world_2_svgrepo_com_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:world_2_svgrepo_com_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/world_2_svgrepo_com_1.xml", -1, -1),
    )
)

public val Res.drawable.zip_icon: DrawableResource
  get() = CommonMainDrawable0.zip_icon

private fun init_zip_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:zip_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/zip_icon.xml", -1, -1),
    )
)
