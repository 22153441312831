package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewModelScope
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.all
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.icon
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.plus
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.PopUpDialog
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun EmployeesScreen(
    isMobileView: Boolean,
    employeesViewModel: EmployeesViewModel,
    navController: NavHostController,
    prefsManager: PrefsManager
) {
    val log = logging("EmployeesScreen")

    var isAllSelected = remember { mutableStateOf(true) }
    val isInEditMode = remember { mutableStateOf(false) }
    val employees = remember { mutableStateListOf<com.hyperether.goodjob.database.User>() }
    val filteredEmployees = remember { mutableStateListOf<User>() }

    val selectedEmployees = remember { mutableStateListOf<com.hyperether.goodjob.database.User>() }
    var showPopup = remember { mutableStateOf(false) }
    var searchText = remember { mutableStateOf("") }
    val sortBy = remember { mutableStateOf<String?>(null) }

    val showFilterDialog = remember { mutableStateOf(false) }
    // TODO get real data
    val cities = listOf("Basel", "Zurich", "Bern", "Houston", "Miami")
    val roles = listOf("Manager", "Technician", "Administrator")
    val teams = listOf("Sales Team", "Support Team", "Technical Team")
    val statuses = listOf("Active", "Inactive", "On Leave")
    val skills = listOf("Electrical Installation", "Solar Panel Installation", "Wiring and Cabling")

    val currentPage = remember { mutableStateOf(1) }

    val itemsPerPage = 5

    LaunchedEffect(Unit) {
        employeesViewModel.getAllUsersRemote()
        employeesViewModel.employees.collect { employeeList ->
            employees.clear()
            employees.addAll(employeeList)
        }
        employeesViewModel.employees_remote.collect { list ->
            filteredEmployees.clear()
            filteredEmployees.addAll(list)
        }
    }

    val currentEmployees = derivedStateOf {
        filteredEmployees.drop((currentPage.value - 1) * itemsPerPage).take(itemsPerPage)
    }


    if (showFilterDialog.value) {
        FilterEmployeesDialog(
            cities,
            roles,
            teams,
            statuses,
            skills,
            onDismiss = { showFilterDialog.value = false },
            onApply = { selectedCities, selectedRoles, selectedTeams, selectedStatuses, selectedSkills ->
                log.d("Applied Filter") { "Applied Filters: Cities=$selectedCities, Roles=$selectedRoles, Teams=$selectedTeams, Statuses=$selectedStatuses, Skills=$selectedSkills" }
//                filteredEmployees.clear()
//                val list = employees.map { Mapper().mapDbUserToSerializableUser(it) }
//                filteredEmployees.addAll(
//                    list.filter { employee ->
//                        (selectedCities.isEmpty() || selectedCities.contains(employee.city)) &&
//                                (selectedRoles.isEmpty() || selectedRoles.contains(employee.role)) // &&
////                                (selectedTeams.isEmpty() || selectedTeams.contains(employee.teams)) &&
////                                (selectedStatuses.isEmpty() || selectedStatuses.contains(employee.status)) &&
////                                (selectedSkills.isEmpty() || selectedSkills.any { skill -> skill in employee.skills })
//                    }
//                )
                showFilterDialog.value = false
            }
        )
    }
    if (isMobileView) {
        Box(modifier = Modifier.fillMaxSize()) {
            Column {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp, vertical = 20.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.baseline_person_24),
                        contentDescription = "Avatar",
                        modifier = Modifier
                            .size(48.dp)
                            .clip(CircleShape)
                            .background(Color.LightGray)
                    )

                    Text(
                        text = stringResource(Res.string.employees),
                        fontSize = 18.sp,
                        color = Color.Black,
                        textAlign = TextAlign.Center,
                        modifier = Modifier
                            .weight(1f)
                            .padding(start = 16.dp)
                    )

                    Row(
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .wrapContentWidth()
                            .padding(start = 8.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.icon),
                            contentDescription = "",
                            modifier = Modifier
                                .padding(end = 20.dp)
                        )
                        Image(
                            painter = painterResource(Res.drawable.plus),
                            contentDescription = "",
                            modifier = Modifier
                                .padding()
                                .clickable {
                                    navController.navigate(Screen.AddNewEmployee.route)
                                }
                        )
                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )
                Column(
                    modifier = Modifier
                        .background(Color.LightGray.copy(alpha = 0.2f))
                        .padding(8.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(40.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.all),
                            color = if (isAllSelected.value) BlueApp else Color.Gray,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(start = 40.dp)
                                .clickable {
                                    isAllSelected.value = true
                                }
                        )

                        Spacer(modifier = Modifier.weight(0.5f))

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.teams),
                            color = if (isAllSelected.value) Color.Gray else BlueApp,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(end = 40.dp)
                                .clickable {
                                    isAllSelected.value = false
                                    selectedEmployees.clear()
                                }
                        )
                    }
                }
                Box(
                    modifier = Modifier
                        .height(2.dp)
                        .fillMaxWidth(0.5f)
                        .background(if (isAllSelected.value) BlueApp else BlueApp)
                        .align(if (isAllSelected.value) Alignment.Start else Alignment.End)
                )
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp, vertical = 10.dp)
                ) {
                    if (!isInEditMode.value) {
                        Text(
                            text = stringResource(Res.string.edit),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier
                                .clickable {
                                    isInEditMode.value = true
                                    showPopup.value = true
                                }
                                .weight(1f),
                            textAlign = TextAlign.Start
                        )
                    } else {
                        Text(
                            text = stringResource(Res.string.cancel),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier
                                .clickable {
                                    isInEditMode.value = false
                                    showPopup.value = false
                                }
                                .weight(1f),
                            textAlign = TextAlign.Start
                        )
                    }

                    if (!isInEditMode.value) {
                        Text(
                            text = "${stringResource(Res.string.total)}:${employees.size} Employees",
                            fontSize = 14.sp,
                            color = Color.Black,
                            maxLines = 1,
                            fontStyle = FontStyle.Italic,
                            textAlign = TextAlign.Center
                        )
                    }

                    Row(
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.weight(1f)
                    ) {
                        Text(
                            text = stringResource(Res.string.filter),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier.padding(end = 8.dp),
                            textAlign = TextAlign.End
                        )
                        Icon(
                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                            contentDescription = null,
                            modifier = Modifier.size(20.dp)
                        )
                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )

                //change with employee id
                EmployeesList(
                    employees = employees,
                    isInEditMode = isInEditMode.value,
                    selectedEmployees = selectedEmployees,
                    navHostController = navController
                )
            }
            if (showPopup.value) {
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .background(Color.Black.copy(alpha = 0.05f))
                )

                Box(
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .fillMaxWidth()
                ) {
                    EditDialog(
                        showDialog = showPopup.value,
                        employees = selectedEmployees,
                        onDelete = {
                            employeesViewModel.showLoader.value = true
                            employeesViewModel.deleteSelectedUsers(selectedEmployees,
                                onAllDeleted = {
                                    employeesViewModel.showPopUpSuccess.value = true
                                    employeesViewModel.showLoader.value = false
                                    employeesViewModel.viewModelScope.launch {
                                        employeesViewModel.employees.collect { employeeList ->
                                            employees.clear()
                                            employees.addAll(employeeList)
                                        }
                                    }
                                },
                                onError = {
                                    employeesViewModel.showPopUpError.value = true
                                    employeesViewModel.showLoader.value = false
                                })
                        },
                        onEdit = {},
                        onExportList = {},
                        onImportList = {}
                    )
                }
            }
            if (employeesViewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { employeesViewModel.showPopUpSuccess.value = false },
                    title = "SUCCESS",
                    message = "Employee deleted successfully"
                )
            }
            if (employeesViewModel.showPopUpError.value) {
                PopUpDialog(
                    onDismiss = { employeesViewModel.showPopUpError.value = false },
                    title = "ERROR",
                    message = "There was an error while deleting employee"
                )
            }
            if (employeesViewModel.showLoader.value) {
                FullScreenProgressBar(showProgressBar = employeesViewModel.showLoader)
            }
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .background(DashboardBg)
        ) {
            // Top section
            Row(
                modifier = Modifier
                    .background(Color.White)
                    .fillMaxWidth()
                    .drawBehind {
                        val strokeWidth = 1.dp.toPx()
                        drawLine(
                            color = BorderColor,
                            start = Offset(0f, size.height - strokeWidth / 2),
                            end = Offset(size.width, size.height - strokeWidth / 2),
                            strokeWidth = strokeWidth
                        )
                    }
                    .padding(start = 70.dp, end = 70.dp, top = 10.dp, bottom = 10.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = stringResource(Res.string.employees),
                    fontSize = 32.sp,
                    fontWeight = FontWeight.Bold,
                    lineHeight = 35.sp,
                    modifier = Modifier.weight(1f)
                )
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = stringResource(Res.string.import_list),
                        fontSize = 16.sp,
                        modifier = Modifier.padding(end = 16.dp)
                    )
                    Button(
                        onClick = {
                            navController.navigate(Screen.AddNew.route)
                        },
                        modifier = Modifier
                            .padding(horizontal = 32.dp, vertical = 16.dp),
                        colors = ButtonDefaults.buttonColors(BlueApp),
                        shape = RoundedCornerShape(32.dp)
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Icon(
                                imageVector = Icons.Default.Add,
                                contentDescription = stringResource(Res.string.add),
                                tint = Color.White,
                                modifier = Modifier.size(20.dp)
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            Text(
                                text = stringResource(Res.string.add),
                                color = Color.White
                            )
                        }
                    }
                }
            }
            Spacer(modifier = Modifier.height(30.dp))
            // Statistics section
            StatsSection()

            Spacer(modifier = Modifier.height(16.dp))

            // Employee List
            EmployeeList(
                allEmployees = filteredEmployees,
                employeeList = currentEmployees.value,
                sortBy = sortBy,
                searchText = searchText,
                currentPage,
                itemsPerPage,
                showFilterDialog,
                deleteEmployeeById = { id -> employeesViewModel.deleteEmployeesById(id) },
                saveSelectedEmployee = { id ->
                    log.d("EmployeesScreen") {"SELECTED EMPLOYEE: $id"}
                    navController.navigate(Screen.EmployeeDetails.route + "?employeeId=${id}")
                }
            )
        }
    }
}