package com.hyperether.goodjob.scenes.notifications

import androidx.compose.foundation.layout.Column
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.notifications
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import org.jetbrains.compose.resources.stringResource

@Composable
fun NotificationsScreen(
    viewModel: NotificationsViewModel,
    navHostController: NavHostController,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {
    Column {
        MobileHeaderSearchAdd(
            title = stringResource(Res.string.notifications),
            drawerState = drawerState,
            scope = rememberCoroutineScope(),
            add = { navHostController.navigate(Screen.AddNew.route) },
            search = {},
            user = prefsManager.getUser()
        )

    }

}