package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.UserTeam
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.active
import com.hyperether.goodjob.resources.add_new_employee
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.admin
import com.hyperether.goodjob.resources.briefcase
import com.hyperether.goodjob.resources.briefcase_unselect
import com.hyperether.goodjob.resources.building
import com.hyperether.goodjob.resources.calendar__1_
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.choose_team
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.country
import com.hyperether.goodjob.resources.date_error
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.employee
import com.hyperether.goodjob.resources.employee_details
import com.hyperether.goodjob.resources.enter_city
import com.hyperether.goodjob.resources.enter_country
import com.hyperether.goodjob.resources.enter_full_name
import com.hyperether.goodjob.resources.enter_text
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.enter_zip_code
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.ic_calendar
import com.hyperether.goodjob.resources.ic_leave
import com.hyperether.goodjob.resources.ic_mail
import com.hyperether.goodjob.resources.ic_map_pin
import com.hyperether.goodjob.resources.ic_skills
import com.hyperether.goodjob.resources.ic_user
import com.hyperether.goodjob.resources.ic_users
import com.hyperether.goodjob.resources.ic_work_scheduler
import com.hyperether.goodjob.resources.inactive
import com.hyperether.goodjob.resources.mail
import com.hyperether.goodjob.resources.manager
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.profile_pic_placeholder
import com.hyperether.goodjob.resources.role
import com.hyperether.goodjob.resources.select_dates
import com.hyperether.goodjob.resources.select_skill
import com.hyperether.goodjob.resources.set_weekly_hours
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.start_typing_address
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.upcoming_leaves
import com.hyperether.goodjob.resources.upload_profile_picture
import com.hyperether.goodjob.resources.work_schedule
import com.hyperether.goodjob.resources.world_2_svgrepo_com_1
import com.hyperether.goodjob.resources.zip
import com.hyperether.goodjob.resources.zip_employee
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeMultiSelectField
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeTeamInputField
import com.hyperether.goodjob.theme.AddEmployeeInputColor
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.EmployeeDetailsText
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewEmployeeWeb(
    viewModel: AddUserViewModel,
    navHostController: NavHostController,
    showProgressBar: MutableState<Boolean>,
    showErrorDialog: MutableState<Boolean>
) {

    val teams by viewModel.teams.collectAsState(emptyList())
    val availableTeamNames = teams.map { it.name ?: "" }
    val dropdownTeamsItemsState = remember { mutableStateOf(emptyList<String>()) }

    val selectedSkills by viewModel.selectedSkills.collectAsState(emptyList())
    val dropdownSkills by viewModel.availableSkills.collectAsState(emptyList())
    val preselectedSkills by viewModel.preselectedSkills.collectAsState(emptyList())

    val selectedSkillNamesState = remember { mutableStateOf(selectedSkills) }
    val preselectedSkillNamesState = remember { mutableStateOf(preselectedSkills) }
    val dropdownSkillNamesState = remember { mutableStateOf(dropdownSkills.map { it.name ?: "" }) }

    val roles = listOf(
        stringResource(Res.string.admin),
        stringResource(Res.string.manager), stringResource(Res.string.employee)
    )

    val statuses = listOf(
        stringResource(Res.string.active),
        stringResource(Res.string.inactive)
    )

    val focusManager = LocalFocusManager.current
    val focusRequester = FocusRequester()
    val showWorkingHoursView = remember { mutableStateOf(false) }


    LaunchedEffect(availableTeamNames, teams) {
        dropdownTeamsItemsState.value = availableTeamNames
    }

    LaunchedEffect(selectedSkills) {
        selectedSkillNamesState.value = selectedSkills
    }

    LaunchedEffect(preselectedSkills) {
        preselectedSkillNamesState.value = preselectedSkills
    }

    LaunchedEffect(dropdownSkills) {
        dropdownSkillNamesState.value = dropdownSkills.map { it.name ?: "" }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
            .verticalScroll(rememberScrollState())
    ) {
        Row(
            modifier = Modifier
                .background(Color.White)
                .fillMaxWidth()
                .drawBehind {
                    val strokeWidth = 1.dp.toPx()
                    drawLine(
                        color = BorderColor,
                        start = Offset(0f, size.height - strokeWidth / 2),
                        end = Offset(size.width, size.height - strokeWidth / 2),
                        strokeWidth = strokeWidth
                    )
                }
                .padding(start = 70.dp, end = 70.dp, top = 20.dp, bottom = 20.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Text(
                text = stringResource(Res.string.add_new_employee),
                fontSize = 32.sp,
                fontWeight = FontWeight.Bold,
                lineHeight = 35.sp,
            )
        }
        Spacer(modifier = Modifier.height(30.dp))

        // Form container
        Box(
            modifier = Modifier
                .padding(horizontal = 40.dp)
                .background(Color.White)
                .fillMaxWidth()
                .border(1.dp, BorderColor)
                .padding(30.dp)
        ) {
            Column {
                Text(
                    text = stringResource(Res.string.employee_details),
                    fontSize = 27.sp,
                    lineHeight = 30.sp,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold
                )
                Spacer(modifier = Modifier.height(40.dp))
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    // Form inputs
                    Column(
                        modifier = Modifier.weight(1f),
                        verticalArrangement = Arrangement.spacedBy(16.dp)
                    ) {
                        LaunchedEffect(true) {
                            focusRequester.requestFocus()
                        }
                        CustomDropdownMenu(
                            iconRes = Res.drawable.briefcase_unselect,
                            labelText = stringResource(Res.string.status),
                            items = statuses,
                            selectedValue = viewModel.status.value,
                            onValueSelected = { viewModel.status.value = it },
                            displayText = { it },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )

                        CustomDropdownMenu(
                            iconRes = Res.drawable.briefcase_unselect,
                            labelText = stringResource(Res.string.role),
                            items = roles,
                            selectedValue = viewModel.roleSelected.value,
                            onValueSelected = { viewModel.roleSelected.value = it },
                            displayText = { it },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.ic_user,
                            labelText = stringResource(Res.string.full_name),
                            placeholderText = stringResource(Res.string.enter_full_name),
                            inputValue = viewModel.fullNameInput.value,
                            onValueChange = {
                                viewModel.fullNameInput.value = ""
                                viewModel.fullNameInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.ic_mail,
                            labelText = stringResource(Res.string.email),
                            placeholderText = stringResource(Res.string.enter_your_email),
                            inputValue = viewModel.emailInput.value,
                            onValueChange = {
                                viewModel.emailInput.value = ""
                                viewModel.emailInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomNumberInputRowEmployee(
                            painterResource = Res.drawable.phone,
                            labelText = stringResource(Res.string.phone),
                            placeholderText = "+1 555 123 4567",
                            inputValue = viewModel.phoneInput.value,
                            onValueChange = {
                                viewModel.phoneInput.value = ""
                                viewModel.phoneInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.ic_map_pin,
                            labelText = stringResource(Res.string.address),
                            placeholderText = stringResource(Res.string.start_typing_address),
                            inputValue = viewModel.addressInput.value,
                            onValueChange = {
                                viewModel.addressInput.value = ""
                                viewModel.addressInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.building,
                            labelText = stringResource(Res.string.city),
                            placeholderText = stringResource(Res.string.enter_city),
                            inputValue = viewModel.cityInput.value,
                            onValueChange = {
                                viewModel.cityInput.value = ""
                                viewModel.cityInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.world_2_svgrepo_com_1,
                            labelText = stringResource(Res.string.country),
                            placeholderText = stringResource(Res.string.enter_country),
                            inputValue = viewModel.countryInput.value,
                            onValueChange = {
                                viewModel.countryInput.value = ""
                                viewModel.countryInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.mail,
                            labelText = stringResource(Res.string.zip_employee),
                            placeholderText = stringResource(Res.string.enter_zip_code),
                            inputValue = viewModel.zipInput.value,
                            onValueChange = {
                                viewModel.zipInput.value = ""
                                viewModel.zipInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        Spacer(modifier = Modifier.height(10.dp))
                        Divider(modifier = Modifier.height(1.dp).background(Color.LightGray.copy(alpha = 0.1f)))
                        Spacer(modifier = Modifier.height(10.dp))


                        TeamsDropdownMenu(
                            itemsFromDb = teams,
                            selectedTeam = viewModel.teamInput.value,
                            onItemSelected = {
                                viewModel.teamInput.value = it
                                println("SelectedTeam: " + viewModel.teamInput.value)
                            },
                            onAddNewItem = { name ->
                                viewModel.createTeam(name) { newTeam ->
                                    viewModel.teamInput.value = newTeam
                                }
                            },
                            iconRes = Res.drawable.ic_users,
                            labelText = stringResource(Res.string.teams),
                            selectedValue = viewModel.selectedTeam.value ?: UserTeam()
                        )
//                        CustomDropdownMenu(
//                            iconRes = Res.drawable.ic_users,
//                            labelText = stringResource(Res.string.teams),
//                            items = itemsTeams,
//                            selectedValue = viewModel.selectedTeam.value ?: UserTeam(),
//                            onValueSelected = { viewModel.selectedTeam.value = it },
//                            displayText = { team -> team.value ?: chooseTeam },
//                            focusManager = focusManager,
//                            modifier = Modifier.focusRequester(focusRequester)
//                        )
                        val skills = stringResource(Res.string.skills)
                        SkillsDropdownMenu(
                            selectedItems = selectedSkillNamesState,
                            onItemSelected = { newSelectedItems ->
                                selectedSkillNamesState.value = newSelectedItems
                                viewModel.setSelectedSkills(newSelectedItems)
                            },
                            dropdownItems = dropdownSkillNamesState,
                            preselectedItems = preselectedSkillNamesState,
                            onAddNewValue = { name -> viewModel.addNewSkill(name) },
                            iconRes = Res.drawable.ic_skills,
                            labelText = stringResource(Res.string.skills),
                            selectedValue = viewModel.selectedSkill.value ?: skills
                        )
//                        CustomDropdownMenu(
//                            iconRes = Res.drawable.ic_skills,
//                            labelText = stringResource(Res.string.skills),
//                            items = itemsSkills,
//                            selectedValue = viewModel.selectedSkill.value ?: skills,
//                            onValueSelected = { viewModel.selectedSkill.value = it },
//                            displayText = { it },
//                            focusManager = focusManager,
//                            modifier = Modifier.focusRequester(focusRequester)
//                        )
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 8.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Image(
                                    painter = painterResource(Res.drawable.ic_work_scheduler),
                                    contentDescription = null,
                                )
                                Text(
                                    text = stringResource(Res.string.work_schedule),
                                    color = EmployeeDetailsText,
                                    fontSize = 14.sp,
                                    modifier = Modifier.padding(start = 20.dp)
                                )
                            }

                            Row(
                                modifier = Modifier
                                    .weight(2f)
                                    .padding(end = 16.dp),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    text = stringResource(Res.string.set_weekly_hours),
                                    color = BlueApp,
                                    fontSize = 14.sp,
                                    fontWeight = FontWeight.Bold,
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .clickable {
                                            showWorkingHoursView.value = true
                                        }
                                )
                            }
                        }
                        if (showWorkingHoursView.value) {
                            Box(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .height(400.dp)
                            ) {
                                WorkingHoursView(viewModel)
                            }
                        }

                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 8.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Image(
                                    painter = painterResource(Res.drawable.ic_leave),
                                    contentDescription = null
                                )
                                Text(
                                    text = stringResource(Res.string.upcoming_leaves),
                                    color = EmployeeDetailsText,
                                    fontSize = 14.sp,
                                    modifier = Modifier.padding(start = 20.dp)
                                )
                            }

                            OutlinedButton(
                                onClick = {  viewModel.isCalendar2Visible.value = true },
                                border = BorderStroke(1.dp, AddEmployeeInputColor),
                                shape = RoundedCornerShape(12),
                                colors = androidx.compose.material3.ButtonDefaults.outlinedButtonColors(
                                    contentColor = Color.White, containerColor = Color.Transparent
                                ),
                                modifier = Modifier
                                    .weight(2f)
                                    .height(50.dp)
                            ) {
                                Row(
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.SpaceBetween,
                                    modifier = Modifier.fillMaxWidth()
                                ) {
                                    Text(
                                        text = viewModel.upcomingLeaves.value,
                                        color = EmployeeDetailsText,
                                        fontSize = 14.sp,
                                        textAlign = TextAlign.Start,
                                        modifier = Modifier.weight(5f).padding(top = 5.dp).offset(x = (-10).dp)
                                    )
                                    Icon(
                                        painter = painterResource(Res.drawable.calendar__1_),
                                        contentDescription = "",
                                        modifier = Modifier.offset(x = 10.dp)

                                    )
                                }
                            }

                        }
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 16.dp) // Add some space before buttons
                        ) {
                            Column(
                                modifier = Modifier.fillMaxWidth(),
                                verticalArrangement = Arrangement.spacedBy(16.dp) // Space between buttons
                            ) {
                                Button(
                                    onClick = {
                                        showProgressBar.value = true
                                        viewModel.addEmployee(
                                            onSuccess = {
                                                showProgressBar.value = false
                                                navHostController.popBackStack()
                                            },
                                            onError = {
                                                showProgressBar.value = false
                                                showErrorDialog.value = true
                                            })
                                    },
                                    modifier = Modifier
                                        .fillMaxWidth(),
                                    shape = RoundedCornerShape(56.dp),
                                    colors = ButtonDefaults.buttonColors(BlueApp),
                                ) {
                                    Text(
                                        modifier = Modifier.padding(vertical = 8.dp),
                                        text = stringResource(Res.string.add_new_employee),
                                        color = Color.White,
                                    )
                                }
                                androidx.compose.material.OutlinedButton(
                                    onClick = {
                                        navHostController.popBackStack()
                                    },
                                    border = BorderStroke(1.dp, EmployeeDetailsText),
                                    modifier = Modifier
                                        .fillMaxWidth(),
                                    shape = RoundedCornerShape(25.dp),
                                    colors = ButtonDefaults.outlinedButtonColors(
                                        backgroundColor = Color.White,
                                        contentColor = Color.Black
                                    ),
                                ) {
                                    Text(
                                        modifier = Modifier.padding(vertical = 8.dp),
                                        text = stringResource(Res.string.cancel),
                                        color = Color.Black,
                                    )
                                }
                            }
                        }
                        if (viewModel.isCalendar2Visible.value) {
                            val error = stringResource(Res.string.date_error)
                            CustomCalendarView(
                                isAddEmployee = true,
                                onDismiss = { viewModel.isCalendar2Visible.value = false },
                                onDateSelect = { selectedDates ->
                                    if (selectedDates.isNotEmpty()) {
                                        val sortedDates = selectedDates.sorted()
                                        val start = sortedDates.first()
                                        val end = sortedDates.last()

                                        fun formatDate(date: LocalDate): String {
                                            val monthAbbreviation = date.month.name.lowercase().take(3)
                                                .replaceFirstChar { it.uppercase() }
                                            return "$monthAbbreviation ${date.dayOfMonth}"
                                        }

                                        val formattedRange =
                                            if (start.month == end.month && start.year == end.year) {
                                                "${formatDate(start)} - ${end.dayOfMonth}, ${end.year}"
                                            } else {
                                                "${formatDate(start)} - ${formatDate(end)}, ${end.year}"
                                            }

                                        viewModel.upcomingLeaves.value = formattedRange
                                    }
                                },
                                showErrorMessage = {
                                    viewModel.showPopUpError.value = true
                                    viewModel.errorText.value = error
                                }
                            )
                        }
                    }

                    Spacer(modifier = Modifier.width(30.dp))
                    // Profile picture
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier
                    ) {
                        Box(
                            modifier = Modifier
                                .padding(10.dp),
                            contentAlignment = Alignment.Center
                        ) {
                            Image(
                                painterResource(Res.drawable.profile_pic_placeholder),
                                contentDescription = null
                            )
                        }
                        Spacer(modifier = Modifier.height(10.dp))
                        OutlinedButton(
                            border = BorderStroke(1.dp, BlueApp),
                            shape = RoundedCornerShape(4.dp),
                            onClick = {
                                // TODO
                            }) {
                            Text(
                                text = stringResource(Res.string.upload_profile_picture),
                                color = BlueApp,
                                fontSize = 14.sp
                            )
                        }
                    }
                }
            }

            Spacer(modifier = Modifier.height(20.dp))
        }
    }
}