package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Employee
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new
import com.hyperether.goodjob.resources.add_new_employee
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.create
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.enter_employee_email
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.enter_surname
import com.hyperether.goodjob.resources.enter_zip
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.tap_to_upload_photo
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.resources.zip
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.RegisterInputField
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewEmployee(navHostController: NavHostController, employeesViewModel: EmployeesViewModel) {

    val fullNameInput = remember { mutableStateOf("") }
    val phoneInput = remember { mutableStateOf("") }
    val emailInput = remember { mutableStateOf("") }
    val zipCountryInput = remember { mutableStateOf("") }
    val addressInput = remember { mutableStateOf("") }
    val status = remember { mutableStateOf("") }
    val skills = remember { mutableStateOf("") }
    val team = remember { mutableStateOf("") }


    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 20.dp)
        ) {
            Image(
                painter = painterResource(Res.drawable.back_arrow),
                contentDescription = "back",
                modifier = Modifier.clickable {
                    navHostController.popBackStack()
                }
            )

            Spacer(modifier = Modifier.weight(1f))

            Text(
                text = stringResource(Res.string.add_new_employee),
                fontSize = 18.sp,
                color = TextColor,
                textAlign = TextAlign.Center
            )

            Spacer(modifier = Modifier.weight(1f))
        }
        Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
        Spacer(modifier = Modifier.height(15.dp))

        Image(
            painter = painterResource(Res.drawable.baseline_person_24),
            contentDescription = "Avatar",
            modifier = Modifier
                .size(80.dp)
                .clip(CircleShape)
                .background(BlueApp)
                .align(Alignment.CenterHorizontally)
        )

        Text(
            text = stringResource(Res.string.tap_to_upload_photo),
            fontSize = 14.sp,
            color = TextColor,
            textAlign = TextAlign.Center,
            fontStyle = FontStyle.Italic,
            modifier = Modifier.align(Alignment.CenterHorizontally)
        )

        Column(modifier = Modifier.padding(horizontal = 16.dp)) {
            RegisterInputField(
                fullNameInput,
                stringResource(Res.string.full_name),
                placeholder = stringResource(Res.string.enter_surname)
            )
            RegisterInputField(
                phoneInput,
                stringResource(Res.string.phone),
                placeholder = "+134232525235"
            )
            RegisterInputField(
                emailInput,
                stringResource(Res.string.email_address),
                placeholder = stringResource(Res.string.enter_employee_email)
            )
            RegisterInputField(
                zipCountryInput,
                stringResource(Res.string.zip),
                placeholder = stringResource(Res.string.enter_zip)
            )
            RegisterInputField(
                addressInput,
                stringResource(Res.string.address),
                placeholder = stringResource(Res.string.enter_street)
            )
            val items = listOf("Active", "Inactive")
            AddEmployeeInputField(
                status,
                stringResource(Res.string.status),
                placeholder = "",
                dropdownItems = items
            )

            val itemsSkills = listOf("Skill1", "Skill2")
            AddEmployeeInputField(
                skills,
                stringResource(Res.string.skills),
                placeholder = "",
                dropdownItems = itemsSkills
            )

            val itemsTeams = listOf("Sales Team", "Support Team", "Technical Team")
            AddEmployeeInputField(
                team,
                stringResource(Res.string.team),
                placeholder = "",
                dropdownItems = itemsTeams
            )

            val employee = Employee(
                full_name = fullNameInput.value,
                email = emailInput.value,
                address = addressInput.value,
                city_zip_country = zipCountryInput.value,
                status = status.value,
                team = team.value,
                avatar = "",
                role = "user",
                phone_number = phoneInput.value
            )

            FilledButton(
                onClick = {
                    employeesViewModel.insertEmployee(employee)
                },
                modifier = Modifier.padding(top = 30.dp),
                text = stringResource(Res.string.add),
                isEnabled = true
            )
        }

    }

}


@Composable
fun AddEmployeeInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<String>
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),

            trailingIcon = {
                Icon(
                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                    contentDescription = "Dropdown",
                    modifier = Modifier.clickable { expanded.value = !expanded.value }
                )
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(onClick = {
                    text.value = item
                    expanded.value = false
                }, text = {
                    Text(item)
                }
                )
            }
        }
    }
}