package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.PaintingStyle
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Repeat
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.add_more
import com.hyperether.goodjob.resources.add_new_job
import com.hyperether.goodjob.resources.add_notes
import com.hyperether.goodjob.resources.add_steps
import com.hyperether.goodjob.resources.are_you_sure
import com.hyperether.goodjob.resources.assign_employee
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.baseline_file_present_24
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.confirmation
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_site
import com.hyperether.goodjob.resources.date_error
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.delete_job
import com.hyperether.goodjob.resources.due_date
import com.hyperether.goodjob.resources.end_at
import com.hyperether.goodjob.resources.enter_job_title
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.every_2_weeks
import com.hyperether.goodjob.resources.every_day
import com.hyperether.goodjob.resources.every_month
import com.hyperether.goodjob.resources.every_week
import com.hyperether.goodjob.resources.files
import com.hyperether.goodjob.resources.files_notes
import com.hyperether.goodjob.resources.job_added
import com.hyperether.goodjob.resources.job_duration
import com.hyperether.goodjob.resources.job_status
import com.hyperether.goodjob.resources.job_title
import com.hyperether.goodjob.resources.job_updated
import com.hyperether.goodjob.resources.max_size
import com.hyperether.goodjob.resources.never
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.only_support
import com.hyperether.goodjob.resources.provide_information
import com.hyperether.goodjob.resources.repeat
import com.hyperether.goodjob.resources.select_job_status
import com.hyperether.goodjob.resources.select_team_or_user
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.resources.start_at
import com.hyperether.goodjob.resources.start_date
import com.hyperether.goodjob.resources.steps
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.trash
import com.hyperether.goodjob.resources.try_another_document
import com.hyperether.goodjob.resources.update_job
import com.hyperether.goodjob.resources.upload_cloud
import com.hyperether.goodjob.resources.upload_documents
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.WebViewHeaderWithRightContent
import com.hyperether.goodjob.scenes.jobs.AppInputFieldWithUpdate
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.DurationInputField
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BlueBackground
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants
import com.hyperether.goodjob.util.Constants.DEFAULT_JOB_ID
import com.hyperether.goodjob.util.JobStatusMap
import com.hyperether.goodjob.util.UploadManager
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun AddNewJobScreenWeb(
    jobId: String?,
    viewModel: AddJobViewModel,
    navHostController: NavHostController,
    onUploadDocumentClick: (Boolean) -> Unit
) {

    val log = logging("AddNewJobScreenWeb")
    val users by viewModel.users.collectAsState(emptyList())
    val teams by viewModel.teams.collectAsState(emptyList())
    var selectedIndex by remember { mutableStateOf(0) }

    val job by viewModel.selectedJob.collectAsState()

    val startDateString = stringResource(Res.string.start_date)
    val dueDateString = stringResource(Res.string.due_date)
    val startAtString = stringResource(Res.string.start_at)
    val endAtString = stringResource(Res.string.end_at)
    val jobStatusString = stringResource(Res.string.job_status)
    viewModel.startDateInputPlaceholder.value = startDateString
    viewModel.dueDatePlaceholder.value = dueDateString
    viewModel.startInputPlaceholder.value = startAtString
    viewModel.endInputPlaceholder.value = endAtString
    viewModel.jobStatusPlaceholderValue.value = jobStatusString

    viewModel.jobStatusMap = JobStatusMap()

    viewModel.repeatMap = mapOf(
        stringResource(Res.string.never) to Repeat.never.name,
        stringResource(Res.string.every_day) to Repeat.every_day.name,
        stringResource(Res.string.every_week) to Repeat.every_week.name,
        stringResource(Res.string.every_2_weeks) to Repeat.every_2_weeks.name,
        stringResource(Res.string.every_month) to Repeat.every_month.name
//    stringResource(Res.string.custom) to Repeat.custom.name
    )

    LaunchedEffect(Unit) {
        if (!getPlatformChecker().isMobile()) {
            log.d("AddNewJobScreenWeb") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            viewModel.init()
        }
    }

    LaunchedEffect(Unit) {
        viewModel.getContactsForWeb()
    }

    LaunchedEffect(jobId) {
        log.d("AddNewJobScreenWeb") { "jobId: $jobId" }
        when (jobId) {
            null -> viewModel.clearJobValues()
            DEFAULT_JOB_ID -> viewModel.clearJobValues()
            "" -> viewModel.clearJobValues()
            else -> viewModel.getJobById(jobId)
        }
    }

    LaunchedEffect(job) {
        val contact = viewModel.contactListWeb.firstOrNull { it.id == job?.contactParentId }
        contact?.id?.let { SiteRequest(it) }?.let { viewModel.getSitesUnderContact(it) }
        val site = viewModel.contactSites.firstOrNull { it.parentId == contact?.id }
        log.d("AddNewJobScreenWeb") { "jobId: $jobId, job: $job" }
        log.d("AddNewJobScreenWeb") { "contact: $contact" }
        log.d("AddNewJobScreenWeb") { "site: $site" }
        if (jobId != DEFAULT_JOB_ID && jobId != "") {
            job.let { job1 ->
                if (job1 != null) {
                    viewModel.jobTitleInput.value = job1.jobTitle ?: viewModel.jobTitleInput.value
                    viewModel.jobStatusValue.value =
                        viewModel.jobStatusMap.entries.find { it.value == job1.status }?.key
                            ?: viewModel.jobStatusValue.value
                    viewModel.startDateInputValue.value =
                        job1.startDate.takeIf { it?.isNotEmpty() == true } ?: startDateString
                    viewModel.startInputValue.value =
                        job1.start_at.takeIf { it?.isNotEmpty() == true } ?: startAtString
                    viewModel.endInputValue.value =
                        job1.end_at.takeIf { it?.isNotEmpty() == true } ?: endAtString
                    viewModel.dueDateValue.value =
                        job1.dueDate.takeIf { it?.isNotEmpty() == true } ?: dueDateString
                    viewModel.repeatValue.value =
                        viewModel.repeatMap.entries.find { it.value == job1.repeat }?.key
                            ?: viewModel.repeatValue.value
                    viewModel.contactName.value = contact?.name ?: viewModel.contactName.value
                    viewModel.contactSite.value = site?.siteName ?: viewModel.contactSite.value
                    viewModel.contactParentId.value =
                        job1.contactParentId ?: viewModel.contactParentId.value
                    viewModel.contactSiteId.value =
                        job1.contactSiteId ?: viewModel.contactSiteId.value
                    viewModel.selectedSiteName.value =
                        job1.contactSiteName ?: viewModel.selectedSiteName.value
                    job1.assignees?.let { assignees ->
                        viewModel.preselectedUsers.clear()
                        assignees.employees?.let { employeeObjects ->
                            val employeesIds = employeeObjects.mapNotNull { it?._id }.distinct()
                            val userList = users.filter { user -> employeesIds.contains(user._id) }
                            viewModel.preselectedUsers.addAll(userList)
                        }
                        viewModel.jobSelectedEmployeeList.value = viewModel.preselectedUsers

                        viewModel.preselectedTeamsJob.clear()
                        assignees.teams?.let { teamObjects ->
                            val ids = teamObjects.mapNotNull { it?._id }.distinct()
                            val teamList = teams.filter { team -> ids.contains(team._id) }
                            viewModel.preselectedTeamsJob.addAll(teamList)
                        }
                        viewModel.jobSelectedTeamList.value = viewModel.preselectedTeamsJob
                    }
                    viewModel.durationText.value =
                        job1.jobDuration?.toString() ?: viewModel.jobDurationValue.value.toString()
                    viewModel.notesValue.value = job1.notes ?: viewModel.notesValue.value
                    viewModel.notesListValue.value =
                        job1.noteObjects ?: viewModel.notesListValue.value
                    job1.fileObjects?.let { files ->
                        if (files.isNotEmpty()) {
                            UploadManager.removeAll()
                            for (file in files) {
                                file?.let { fileObject ->
                                    fileObject.documentFileId?.let { it1 ->
                                        UploadManager.addFile(
                                            it1
                                        )
                                    }
                                    fileObject.documentFileId?.let { it1 ->
                                        UploadManager.addFileId(
                                            it1
                                        )
                                    }
                                }
                            }
                        }
                    } ?: viewModel.fileIds.value

                    viewModel.steps.clear()
                    viewModel.steps.addAll(
                        if (job1.steps.isEmpty()) {
                            viewModel.steps
                        } else {
                            Mapper().toStepDataList(job1.steps.filterNotNull())
                        }
                    )
                }
            }
        } else {
            viewModel.clearJobValues()
            viewModel.startDateInputValue.value = startDateString
            viewModel.dueDateValue.value = dueDateString
            viewModel.startInputValue.value = startAtString
            viewModel.endInputValue.value = endAtString
            viewModel.jobStatusValue.value = jobStatusString
        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
            .padding(bottom = 40.dp)
    ) {
        WebViewHeaderWithRightContent(
            isBackAvailable = true,
            onBackClick = { navHostController.popBackStack() },
            title = if (jobId == DEFAULT_JOB_ID || jobId == "") stringResource(Res.string.add_new_job) else stringResource(
                Res.string.update_job
            ),
        ) {
            Box(
                modifier = Modifier.width(200.dp).height(50.dp).clip(RoundedCornerShape(25.dp))
                    .background(BlueApp)
                    .clickable {
                        if (jobId == DEFAULT_JOB_ID || jobId == "") {
                            viewModel.addJob(
                                onSuccess = {
                                    viewModel.showLoader.value = false
                                    viewModel.showPopUpSuccess.value = true
                                },
                                onError = {
                                    viewModel.showLoader.value = false
                                    viewModel.showPopUpError.value = true
                                })
                        } else {
                            jobId?.let {
                                viewModel.updateJob(
                                    it,
                                    onSuccess = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpSuccess.value = true
                                    },
                                    onError = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpError.value = true
                                    })
                            }
                        }
                    },
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = if (jobId == DEFAULT_JOB_ID || jobId == "") stringResource(Res.string.add_new_job) else stringResource(
                        Res.string.update_job
                    ),
                    color = Color.White,
                    fontSize = 15.sp,
                    fontWeight = FontWeight.Normal
                )
            }
        }
        Spacer(modifier = Modifier.height(Dp(15f)))
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = Dp(75f), end = Dp(75f), top = Dp(40f))
                .border(width = Dp(1f), color = RegisterBorder)
                .background(color = Color.White)
        ) {
            Row(modifier = Modifier.fillMaxWidth()) {
                Column(
                    modifier = Modifier.width(Dp(210f)).fillMaxHeight().padding(top = 10.dp)
                        .drawBehind {
                            drawLine(
                                color = RegisterBorder,
                                strokeWidth = 1.dp.toPx(),
                                start = Offset(size.width, 0f),
                                end = Offset(size.width, size.height)
                            )
                        }) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(Dp(48f))
                            .then(
                                if (selectedIndex == 0)
                                    Modifier.background(BlueBackground).drawBehind {
                                        drawLine(
                                            color = BlueApp,
                                            strokeWidth = 6.dp.toPx(),
                                            start = Offset(3.dp.toPx(), 0f),
                                            end = Offset(3.dp.toPx(), size.height)
                                        )
                                    }
                                else Modifier)
                            .clickable { selectedIndex = 0 },
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                            textAlign = TextAlign.Start,
                            text = stringResource(Res.string.about_job),
                            color = if (selectedIndex == 0) BlueApp else TextColor
                        )
                    }

                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(Dp(48f))
                            .then(
                                if (selectedIndex == 1)
                                    Modifier.background(BlueBackground).drawBehind {
                                        drawLine(
                                            color = BlueApp,
                                            strokeWidth = 6.dp.toPx(),
                                            start = Offset(3.dp.toPx(), 0f),
                                            end = Offset(3.dp.toPx(), size.height)
                                        )
                                    }
                                else Modifier)
                            .clickable { selectedIndex = 1 },
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                            textAlign = TextAlign.Start,
                            text = stringResource(Res.string.steps),
                            color = if (selectedIndex == 1) BlueApp else TextColor
                        )
                    }

                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(Dp(48f))
                            .then(
                                if (selectedIndex == 2)
                                    Modifier.background(BlueBackground).drawBehind {
                                        drawLine(
                                            color = BlueApp,
                                            strokeWidth = 6.dp.toPx(),
                                            start = Offset(3.dp.toPx(), 0f),
                                            end = Offset(3.dp.toPx(), size.height)
                                        )
                                    }
                                else Modifier)
                            .clickable { selectedIndex = 2 },
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                            textAlign = TextAlign.Start,
                            text = stringResource(Res.string.files_notes),
                            color = if (selectedIndex == 2) BlueApp else TextColor
                        )
                    }

                }

                Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
                    if (selectedIndex == 0) {
                        Column(
                            modifier = Modifier.padding(start = 16.dp, end = 16.dp).width(Dp(700f))
                        ) {
                            androidx.compose.material3.Text(
                                text = stringResource(Res.string.about_job),
                                color = Color.Black,
                                fontSize = 24.sp,
                                fontWeight = FontWeight.Bold,
                                modifier = Modifier.padding(top = 10.dp)
                            )
                            //TODO fix contacts and sites
                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.contact_name),
                                    modifier = Modifier.weight(1f)
                                )
                                AboutContactDropdown(
                                    viewModel.contactName,
                                    stringResource(Res.string.contact_name),
                                    placeholder = stringResource(Res.string.contact_name),
                                    dropdownItems = viewModel.contactListWeb,
                                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                                    onContactSelected = { contactId ->
                                        viewModel.contactParentId.value = contactId
                                        viewModel.contactSite.value = ""
                                        viewModel.getSitesUnderContact(SiteRequest(contactId))
                                    },
                                    modifier = Modifier.weight(3f),
                                    paddingTop = 0.dp
                                )
                            }

                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.contact_site),
                                    modifier = Modifier.weight(1f)
                                )
                                AboutSiteDropdown(
                                    viewModel.contactSite,
                                    stringResource(Res.string.contact_site),
                                    placeholder = stringResource(Res.string.site),
                                    dropdownItems = viewModel.contactSites,
                                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                                    onSiteSelected = { site ->
                                        viewModel.selectedSiteName.value = site.siteName.toString()
                                        viewModel.contactSiteId.value = site.id.toString()
                                    },
                                    modifier = Modifier.weight(3f),
                                    paddingTop = 0.dp
                                )
                            }
                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.job_title),
                                    modifier = Modifier.weight(1f)
                                )
                                AppInputField(
                                    viewModel.jobTitleInput,
                                    stringResource(Res.string.job_title),
                                    placeholder = stringResource(Res.string.enter_job_title),
                                    paddingTop = 0.dp,
                                    modifier = Modifier.weight(3f)
                                )
                            }
                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.job_status),
                                    modifier = Modifier.weight(1f)
                                )
                                AddNewJobDropdownInput(
                                    viewModel.jobStatusValue,
                                    stringResource(Res.string.job_status),
                                    placeholder = stringResource(Res.string.select_job_status),
                                    dropdownItems = viewModel.jobStatusMap,
                                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                                    modifier = Modifier.weight(3f)
                                )
                            }
                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.assign_employee),
                                    modifier = Modifier.weight(1f)
                                )
                                MultiSelectDropdown(
                                    viewModel.assignEmployeeValue,
                                    stringResource(Res.string.assign_employee),
                                    placeholder = stringResource(Res.string.select_team_or_user),
                                    users = users,
                                    teams = teams,
                                    preSelectedUsers = viewModel.preselectedUsers,
                                    preSelectedTeams = viewModel.preselectedTeamsJob,
                                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                                    onContactSelected = { employee, teams ->
                                        viewModel.jobSelectedEmployeeList.value = employee
                                        viewModel.jobSelectedTeamList.value = teams
                                    },
                                    modifier = Modifier.weight(3f),
                                    paddingTop = 0.dp
                                )
                            }
                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.start_date),
                                    modifier = Modifier.weight(1f)
                                )
                                OutlinedCalendarInput(
                                    onClick = {
                                        viewModel.isCalendar1Visible.value = true
                                    },
                                    input = viewModel.startDateInputValue,
                                    modifier = Modifier.weight(3f)
                                )
                            }

                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.start_at),
                                    modifier = Modifier.weight(1f)
                                )
                                Row(
                                    modifier = Modifier.weight(3f),
                                    horizontalArrangement = Arrangement.SpaceBetween
                                ) {
                                    OutlinedButton(
                                        onClick = { viewModel.isTimePicker1Visible.value = true },
                                        border = BorderStroke(1.dp, Color.DarkGray),
                                        shape = RoundedCornerShape(8),
                                        colors = ButtonDefaults.outlinedButtonColors(
                                            contentColor = Color.White,
                                            containerColor = Color.Transparent
                                        ),
                                        modifier = Modifier
                                            .height(73.dp)
                                            .weight(1f).padding(top = 15.dp)
                                    ) {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text(
                                                text = viewModel.startInputValue.value,
                                                color = Color.DarkGray,
                                                fontSize = 13.sp,
                                                textAlign = TextAlign.Start,
                                                modifier = Modifier.weight(4f).padding(top = 5.dp)
                                                    .offset(x = (-10).dp)
                                            )
                                        }
                                    }

                                    Spacer(modifier = Modifier.width(8.dp))

                                    OutlinedButton(
                                        onClick = { viewModel.isTimePicker2Visible.value = true },
                                        border = BorderStroke(1.dp, Color.DarkGray),
                                        shape = RoundedCornerShape(8),
                                        colors = ButtonDefaults.outlinedButtonColors(
                                            contentColor = Color.White,
                                            containerColor = Color.Transparent
                                        ),
                                        modifier = Modifier
                                            .height(73.dp)
                                            .weight(1f).padding(top = 15.dp)
                                    ) {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text(
                                                text = viewModel.endInputValue.value,
                                                color = Color.DarkGray,
                                                fontSize = 13.sp,
                                                textAlign = TextAlign.Start,
                                                modifier = Modifier.weight(4f).padding(top = 5.dp)
                                                    .offset(x = (-10).dp)
                                            )
                                        }
                                    }
                                }
                            }

                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.repeat),
                                    modifier = Modifier.weight(1f)
                                )
                                AddNewJobDropdownInput(
                                    viewModel.repeatValue,
                                    stringResource(Res.string.repeat),
                                    placeholder = stringResource(Res.string.never),
                                    dropdownItems = viewModel.repeatMap,
                                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                                    modifier = Modifier.weight(3f),
                                    paddingTop = 0.dp
                                )
                            }

                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.due_date),
                                    modifier = Modifier.weight(1f)
                                )
                                OutlinedCalendarInput(
                                    onClick = {
                                        viewModel.isCalendar2Visible.value = true
                                    },
                                    viewModel.dueDateValue,
                                    modifier = Modifier.weight(3f),
                                )
                            }

                            Row(
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    stringResource(Res.string.job_duration),
                                    modifier = Modifier.weight(1f)
                                )
                                DurationInputField(
                                    viewModel.durationText,
                                    stringResource(Res.string.job_duration),
                                    placeholder = viewModel.durationText.value,
                                    modifier = Modifier.weight(3f),
                                    paddingTop = 0.dp
                                )
                            }
                            if (jobId != DEFAULT_JOB_ID && jobId != "")
                                Text(
                                    color = Color.Red,
                                    text = stringResource(Res.string.delete_job),
                                    modifier = Modifier.clickable {
                                        viewModel.showAreYouSurePopUpDeleteJob.value = true
                                        viewModel.selectedJobIdToDelete.value = jobId
                                    }
                                )
                        }
                    } else if (selectedIndex == 1) {
                        Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
                            Spacer(modifier = Modifier.height(15.dp))

                            Text(
                                text = stringResource(Res.string.add_steps),
                                fontSize = 18.sp,
                                color = TextColor,
                                textAlign = TextAlign.Center
                            )
                            Spacer(modifier = Modifier.height(15.dp))

                            StepInputFields(viewModel.steps)
                        }
                    } else {
                        Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
                            Text(
                                text = stringResource(Res.string.files),
                                fontSize = 18.sp,
                                color = TextColor,
                                textAlign = TextAlign.Center
                            )
                            if (jobId == DEFAULT_JOB_ID || jobId == "") {
                                NoteInputField(
                                    text = viewModel.notesValue,
                                    stringResource(Res.string.add_notes),
                                    placeholder = stringResource(Res.string.provide_information)
                                )
                            } else {
                                Column {
                                    for (note in viewModel.notesListValue.value) {
                                        if (!note.noteText.isNullOrBlank()) {
                                            AppInputFieldWithUpdate(
                                                note = note,
                                                onNoteChange = { updatedNote ->
                                                    val updatedNotes =
                                                        viewModel.notesListValue.value.toMutableList()
                                                            .apply {
                                                                val index = indexOf(note)
                                                                if (index != -1) {
                                                                    set(index, updatedNote)
                                                                }
                                                            }
                                                    viewModel.notesListValue.value = updatedNotes
                                                },
                                                label = stringResource(Res.string.add_notes),
                                                placeholder = stringResource(Res.string.provide_information)
                                            )
                                        }
                                    }
                                }
                            }
                            Spacer(modifier = Modifier.height(15.dp))
                            Box(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .wrapContentHeight(unbounded = false)
                                    .defaultMinSize(minHeight = 200.dp)
                                    .align(Alignment.CenterHorizontally)
                                    .drawBehind {
                                        val paint = Paint().apply {
                                            color = BlueApp
                                            style = PaintingStyle.Stroke
                                            strokeWidth = 2.dp.toPx()
                                            pathEffect =
                                                PathEffect.dashPathEffect(
                                                    floatArrayOf(10f, 10f),
                                                    0f
                                                )
                                        }
                                        drawIntoCanvas { canvas ->
                                            canvas.drawRoundRect(
                                                left = 0f,
                                                top = 0f,
                                                right = size.width,
                                                bottom = size.height,
                                                radiusX = 16.dp.toPx(),
                                                radiusY = 16.dp.toPx(),
                                                paint = paint
                                            )
                                        }
                                    },
                                contentAlignment = Alignment.Center
                            ) {
                                Column(
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center,
                                    modifier = Modifier.fillMaxSize().clickable {
                                        onUploadDocumentClick(false)
                                    }
                                ) {
                                    if (jobId != DEFAULT_JOB_ID || jobId != "") {
                                        Image(
                                            painter = painterResource(Res.drawable.baseline_file_present_24),
                                            contentDescription = "",
                                            modifier = Modifier.padding(top = 24.dp).size(40.dp)
                                        )
                                        Column(
                                            modifier = Modifier
                                                .padding(horizontal = 10.dp)
                                        ) {
                                            for (fileName in viewModel.fileNames.value) {
                                                Row(
                                                    horizontalArrangement = Arrangement.SpaceBetween,
                                                    verticalAlignment = Alignment.CenterVertically,
                                                    modifier = Modifier.fillMaxWidth()
                                                ) {
                                                    Text(
                                                        text = fileName ?: "",
                                                        color = DarkGrey,
                                                        fontWeight = FontWeight.Normal,
                                                        style = MaterialTheme.typography.body1,
                                                        maxLines = 1,
                                                        overflow = TextOverflow.Ellipsis,
                                                        modifier = Modifier
                                                            .weight(1f)
                                                            .padding(end = 8.dp)
                                                    )

                                                    Image(
                                                        painter = painterResource(Res.drawable.trash),
                                                        contentDescription = null,
                                                        modifier = Modifier
                                                            .size(20.dp)
                                                            .clickable {
                                                                // TODO: not sure
                                                                fileName?.let {
                                                                    UploadManager.removeFileByName(
                                                                        it
                                                                    )
                                                                }
                                                            }
                                                    )
                                                }
                                                Spacer(modifier = Modifier.height(10.dp))
                                            }
                                        }
                                        Spacer(modifier = Modifier.height(12.dp))
                                        Text(
                                            text = stringResource(Res.string.add_more),
                                            color = BlueApp,
                                            fontWeight = FontWeight.Bold,
                                            fontSize = 18.sp,
                                            modifier = Modifier
                                                .padding(bottom = 24.dp)
                                                .clickable {
                                                    onUploadDocumentClick(false)
                                                }
                                        )
                                    } else {
                                        if (viewModel.documentStatus.value == "") {
                                            Image(
                                                painter = painterResource(Res.drawable.upload_cloud),
                                                contentDescription = "",
                                                modifier = Modifier.size(40.dp)
                                            )

                                            Spacer(modifier = Modifier.height(8.dp))

                                            Text(
                                                text = stringResource(Res.string.upload_documents),
                                                color = Color.Black,
                                                fontWeight = FontWeight.Bold
                                            )

                                            Spacer(modifier = Modifier.height(4.dp))

                                            Text(
                                                text = stringResource(Res.string.max_size),
                                                color = Color.LightGray
                                            )
                                        } else if (viewModel.documentStatus.value == Constants.DOCUMENT_LOADING) {
                                            viewModel.showDocumentLoader.value = true
                                            if (viewModel.showDocumentLoader.value) {
                                                CircularProgressIndicator(
                                                    modifier = Modifier
                                                        .size(25.dp)
                                                        .padding(end = 5.dp),
                                                    color = BlueApp,
                                                    strokeWidth = 2.dp
                                                )
                                            }
                                        } else if (viewModel.documentStatus.value == Constants.DOCUMENT_UPLOAD_ERROR) {
                                            viewModel.showDocumentLoader.value = false
                                            Text(
                                                text = stringResource(Res.string.try_another_document),
                                                color = Color.Gray,
                                                fontWeight = FontWeight.Normal
                                            )
                                        } else {
                                            viewModel.showDocumentLoader.value = false
                                            Image(
                                                painter = painterResource(Res.drawable.baseline_file_present_24),
                                                contentDescription = "",
                                                modifier = Modifier.padding(top = 24.dp).size(40.dp)
                                            )
                                            Column(
                                                modifier = Modifier
                                                    .padding(horizontal = 10.dp)
                                            ) {
                                                for (fileName in viewModel.fileNames.value) {
                                                    Row(
                                                        horizontalArrangement = Arrangement.SpaceBetween,
                                                        verticalAlignment = Alignment.CenterVertically,
                                                        modifier = Modifier.fillMaxWidth()
                                                    ) {
                                                        Text(
                                                            text = fileName ?: "",
                                                            color = DarkGrey,
                                                            fontWeight = FontWeight.Normal,
                                                            style = MaterialTheme.typography.body1,
                                                            maxLines = 1,
                                                            overflow = TextOverflow.Ellipsis,
                                                            modifier = Modifier
                                                                .weight(1f)
                                                                .padding(end = 8.dp)
                                                        )

                                                        Image(
                                                            painter = painterResource(Res.drawable.trash),
                                                            contentDescription = null,
                                                            modifier = Modifier
                                                                .size(20.dp)
                                                                .clickable {
                                                                    fileName?.let {
                                                                        viewModel.deleteUploadedFile(
                                                                            it
                                                                        )
                                                                    }
                                                                }
                                                        )
                                                    }
                                                    Spacer(modifier = Modifier.height(10.dp))
                                                }
                                            }
                                            Spacer(modifier = Modifier.height(12.dp))
                                            Text(
                                                text = stringResource(Res.string.add_more),
                                                color = BlueApp,
                                                fontWeight = FontWeight.Bold,
                                                fontSize = 18.sp,
                                                modifier = Modifier
                                                    .padding(bottom = 24.dp)
                                                    .clickable {
                                                        onUploadDocumentClick(false)
                                                    }
                                            )
                                        }
                                    }
                                }
                            }

                            Text(
                                text = stringResource(Res.string.only_support),
                                fontSize = 12.sp,
                                color = Color.LightGray,
                                textAlign = TextAlign.Center
                            )
                        }
                    }


                    if (viewModel.isTimePicker1Visible.value) {
                        CustomTimePicker(
                            time = viewModel.startInputValue,
                            showTimePicker = viewModel.isTimePicker1Visible,
                            onTimeSelected = { viewModel.startInputValue.value = it })
                    }
                    if (viewModel.isTimePicker2Visible.value) {
                        CustomTimePicker(
                            time = viewModel.endInputValue,
                            showTimePicker = viewModel.isTimePicker2Visible,
                            onTimeSelected = {
                                viewModel.endInputValue.value = it
                                //viewModel.calculateDuration()
                            })
                    }
                    val error = stringResource(Res.string.date_error)
                    if (viewModel.isCalendar1Visible.value) {
                        CustomCalendarView(
                            isAddEmployee = false,
                            onDismiss = { viewModel.isCalendar1Visible.value = false },
                            onDateSelect = {
                                if (it.size > 0) {
                                    viewModel.startDateInputValue.value = it.get(0).toString()
                                }
                            },
                            showErrorMessage = {
                                viewModel.showPopUpError.value = true
                                viewModel.errorText.value = error
                            })
                    }
                    if (viewModel.isCalendar2Visible.value) {
                        CustomCalendarView(
                            isAddEmployee = false,
                            onDismiss = { viewModel.isCalendar2Visible.value = false },
                            onDateSelect = {
                                if (it.size > 0) {
                                    viewModel.dueDateValue.value = it.get(0).toString()
                                }
                            },
                            showErrorMessage = {
                                viewModel.showPopUpError.value = true
                                viewModel.errorText.value = error
                            })
                    }
                    if (viewModel.showPopUpSuccess.value) {
                        PopUpDialog(
                            onDismiss = {
                                viewModel.showPopUpSuccess.value = false
                                navHostController.popBackStack()
                                navHostController.popBackStack()
                            },
                            title = stringResource(Res.string.success).uppercase(),
                            message = if (jobId == DEFAULT_JOB_ID || jobId == "") stringResource(Res.string.job_added) else stringResource(
                                Res.string.job_updated
                            ),
                            onPositiveBtnClicked = {
                                viewModel.showPopUpSuccess.value = false
                                navHostController.popBackStack()
                                navHostController.popBackStack()
                            },
                            positiveBtnText = stringResource(Res.string.ok)
                        )
                    }
                    if (viewModel.showPopUpError.value) {
                        ErrorDialog(
                            onDismiss = { viewModel.showPopUpError.value = false },
                            title = stringResource(Res.string.error).uppercase(),
                            message = viewModel.errorText.value
                        )
                    }
                    if (viewModel.showLoader.value) {
                        FullScreenProgressBar()
                    }

                    if (viewModel.showAreYouSurePopUpDeleteJob.value) {
                        PopUpDialog(
                            onDismiss = { viewModel.showAreYouSurePopUpDeleteJob.value = false },
                            title = stringResource(Res.string.confirmation),
                            message = stringResource(Res.string.are_you_sure),
                            onPositiveBtnClicked = {
                                viewModel.selectedJobIdToDelete.value?.let { id ->
                                    viewModel.deleteJob(id,
                                        onSuccess = {
                                            navHostController.popBackStack()
                                            navHostController.popBackStack()
                                        },
                                        onError = {

                                        })
                                }
                                viewModel.showAreYouSurePopUpDeleteJob.value = false
                            },
                            onNegativeBtnClicked = {
                                viewModel.showAreYouSurePopUpDeleteJob.value = false
                            },
                            positiveBtnText = stringResource(Res.string.delete),
                            negativeBtnText = stringResource(Res.string.cancel)
                        )
                    }
                }
            }
        }
    }
}
