package com.hyperether.goodjob.repository.remote.model

import com.hyperether.goodjob.models.User
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AddPlanResponse(
    @SerialName("message") val message: String,
    @SerialName("user") val user: User
)