package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.clear_all
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.role
import com.hyperether.goodjob.resources.skill
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.scenes.components.FilterSectionMobile
import com.hyperether.goodjob.scenes.components.toggleSelection
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun FilterEmployeesDialog(
    cityList: List<String>,
    roleList: List<String>,
    teamList: List<String>,
    statusList: List<String>,
    skillList: List<String>,
    viewModel: EmployeesViewModel,
    onDismiss: () -> Unit,
    onApply: (Set<String>, Set<String>, Set<String>, Set<String>, Set<String>) -> Unit
) {
    var selectedRoles by remember { mutableStateOf(setOf<String>()) }
    var selectedStatuses by remember { mutableStateOf(setOf<String>()) }
    var selectedCities by remember { mutableStateOf(setOf<String>()) }
    var selectedTeams by remember { mutableStateOf(setOf<String>()) }
    var selectedSkills by remember { mutableStateOf(setOf<String>()) }


    Box(modifier = Modifier.fillMaxSize()) {

        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.Black.copy(alpha = 0.5f))
                .clickable(
                    onClick = {},
                    indication = null,
                    interactionSource = remember { MutableInteractionSource() })
        ) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .align(Alignment.Center)
                    .background(Color.White)
                    .verticalScroll(rememberScrollState())
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "Back icon",
                        modifier = Modifier
                            .weight(1f)
                            .offset(x = (-30).dp)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                onApply(
                                    selectedCities,
                                    selectedRoles,
                                    selectedTeams,
                                    selectedStatuses,
                                    selectedSkills
                                )
                                onDismiss()
                            }
                    )

                    Text(
                        text = stringResource(Res.string.filter),
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontSize = 20.sp,
                        modifier = Modifier
                            .weight(3f)
                            .align(Alignment.CenterVertically)
                    )

                    Text(
                        text = stringResource(Res.string.clear_all),
                        textAlign = TextAlign.End,
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier
                            .weight(1f)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                viewModel.clearFilterValues()
                            }
                    )
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )

                Column(modifier = Modifier.padding(start = 16.dp)) {
                    if (roleList.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(15.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.role),
                            options = roleList,
                            selectedOptions = selectedRoles,
                            onOptionSelected = { value ->
                                selectedRoles = toggleSelection(selectedRoles, value)
                            }
                        )
                    }

                    if (statusList.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(10.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.status),
                            options = statusList,
                            selectedOptions = selectedStatuses,
                            onOptionSelected = { value ->
                                selectedStatuses = toggleSelection(selectedStatuses, value)
                            }
                        )
                    }

                    if (cityList.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(10.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.city),
                            options = cityList,
                            selectedOptions = selectedCities,
                            onOptionSelected = { value ->
                                selectedCities = toggleSelection(selectedCities, value)
                            }
                        )
                    }

                    if (teamList.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(10.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.team),
                            options = teamList,
                            selectedOptions = selectedTeams,
                            onOptionSelected = { value ->
                                selectedTeams = toggleSelection(selectedTeams, value)
                            }
                        )
                    }

                    if (skillList.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(10.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.skill),
                            options = skillList,
                            selectedOptions = selectedSkills,
                            onOptionSelected = { value ->
                                selectedSkills = toggleSelection(selectedSkills, value)
                            }
                        )
                    }
                }
            }
        }
    }
}
