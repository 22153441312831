package com.hyperether.goodjob.scenes.profile

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.UserStatus
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.admin
import com.hyperether.goodjob.resources.employee
import com.hyperether.goodjob.resources.manager
import com.hyperether.goodjob.scenes.components.CircleWithInitialUser
import com.hyperether.goodjob.theme.SubtitleColor
import com.hyperether.goodjob.util.UserStatusHelper
import org.jetbrains.compose.resources.stringResource

@Composable
fun ProfileHeaderView(user: User?) {
    val roleMap = mapOf(
        stringResource(Res.string.admin) to Role.admin.name,
        stringResource(Res.string.manager) to Role.manager.name,
        stringResource(Res.string.employee) to Role.employee.name,
    )

    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 2.dp
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            CircleWithInitialUser(user, size = 50.dp)

            Spacer(modifier = Modifier.width(16.dp))

            Column(
                modifier = Modifier.weight(1f)
            ) {
                user?.getName()?.let {
                    Text(
                        text = it,
                        fontWeight = FontWeight.Bold,
                        fontSize = 14.sp
                    )
                }
                Spacer(modifier = Modifier.height(4.dp))
                user?.role?.let {
                    Text(
                        text = roleMap.entries.find { role ->
                            role.value.equals(
                                it,
                                ignoreCase = true
                            )
                        }?.key ?: "",
                        color = SubtitleColor,
                        fontSize = 14.sp
                    )
                }
            }

            Text(
                text =  UserStatusHelper.getStatusText(confirmStatus = user?.confirmStatus, status = user?.status),
                modifier = Modifier.align(Alignment.CenterVertically)
            )
        }
    }
}

@Composable
fun ProfileDetails(title: String, info: String) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 2.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = title,
                fontWeight = FontWeight.Bold,
                fontSize = 16.sp
            )
            Text(
                text = info,
                fontSize = 14.sp,
                color = SubtitleColor,
            )

        }
    }
}

