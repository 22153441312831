package com.hyperether.goodjob

import App
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import androidx.navigation.NavHostController
import androidx.navigation.compose.rememberNavController
import com.hyperether.goodjob.database.DriverFactory
import com.hyperether.goodjob.database.SharedDatabase
import com.hyperether.goodjob.database.WorkspaceDaoImpl
import com.hyperether.goodjob.managers.FileManager
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.navigation.availableRoutes
import com.hyperether.goodjob.repository.WebRepositoryImpl
import com.hyperether.goodjob.repository.prefs.Preferences
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.ClientProvider
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import com.hyperether.goodjob.repository.remote.model.AddCardRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SetPasswordRequest
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.join_good_job
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.password_set
import com.hyperether.goodjob.resources.password_updated
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.scenes.addNew.AddContactViewModel
import com.hyperether.goodjob.scenes.addNew.AddJobViewModel
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.addNew.AddUserViewModel
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.employees.EmployeeDetailsViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.register.SetNewPasswordView
import com.hyperether.goodjob.scenes.register.SetPasswordWebView
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import com.hyperether.goodjob.util.Constants
import com.hyperether.goodjob.util.UploadManager
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.skiko.wasm.onWasmReady
import org.lighthousegames.logging.logging
import org.w3c.dom.url.URLSearchParams
import web.navigator.navigator

private var token: MutableState<String?> = mutableStateOf(null)
private var setPasswordHash: MutableState<String?> = mutableStateOf(null)
private var errorText = mutableStateOf("")
private var showSetNewPasswordView = mutableStateOf(false)
private var showSetPasswordView = mutableStateOf(false)
private lateinit var repository: WebRepositoryImpl
private lateinit var navController: NavHostController
private var passwordInput = mutableStateOf("")
private var confirmPasswordInput = mutableStateOf("")
private var showErrorDialog = mutableStateOf(false)
private var showSuccessDialog = mutableStateOf(false)
private var showPasswordSetSuccessDialog = mutableStateOf(false)
private lateinit var navigateFun: (route: String) -> Unit

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    val sharedDatabase = SharedDatabase(DriverFactory())
    val workspaceDao = WorkspaceDaoImpl(sharedDatabase)
    val mapper = Mapper()
    val queryParams = URLSearchParams(document.location?.search ?: "")
    //todo check if hash is for register or forgot password
    val path = window.location.pathname
    val url = window.location.href
    var hash = queryParams.get("hash")

    val prefsManager = PrefsManager(Preferences())
    ClientProvider.initialize(prefsManager) {
        // todo logout
    }
    val goodJobApiImpl = GoodJobApiImpl()
    val fileManager = FileManager()

    repository = WebRepositoryImpl(prefsManager, goodJobApiImpl, fileManager)
    val workspaceViewModel = WorkspaceViewModel(repository)

    val registerViewModel = RegisterViewModel(repository)
    val employeesViewModel = EmployeesViewModel(repository)
    val employeeDetailsViewModel = EmployeeDetailsViewModel(repository)
    val addNewViewModel = AddNewViewModel(repository)
    val addContactViewModel = AddContactViewModel(repository)
    val addJobViewModel = AddJobViewModel(repository)
    val addUserViewModel = AddUserViewModel(repository)
    val log = logging("WEB MAIN")
    var paymentIntentId = ""

    onWasmReady {
        CanvasBasedWindow("Good job") {
            navController = rememberNavController()
            val pricingViewModel = PricingViewModel(repository) {
                navController.navigate(Screen.Workspace.route)
            }
            App(
                navController = navController,
                repository = repository,
                workspaceViewModel = workspaceViewModel,
                pricingViewModel = pricingViewModel,
                registerViewModel = registerViewModel,
                employeesViewModel = employeesViewModel,
                employeeDetailsViewModel = employeeDetailsViewModel,
                addNewViewModel = addNewViewModel,
                addContactViewModel = addContactViewModel,
                addJobViewModel = addJobViewModel,
                addUserViewModel = addUserViewModel,
                prefsManager = prefsManager,
                currentRoute = getCurrentRoute(),
                onChoosePlanClick = { amount, currency ->

                    val customerId = prefsManager.getUser()?.customerId ?: return@App
                    pricingViewModel.getEphemeralKey(
                        EphemeralKeyRequest(customerId = customerId),
                        onSuccess = { key ->
                            localStorage.setItem("amount", amount.toString())
                            localStorage.setItem("currency", currency)
                            pricingViewModel.choosePlan(
                                choosePlanRequest = ChoosePlanRequest(
                                    amount = amount,
                                    currency = currency,
                                    prefsManager.getUser()?.customerId
                                ),
                                onSuccess = { response ->
                                    paymentIntentId = response.paymentIntent.toString()
                                    key.ephemeralKey?.secret?.let { emphemralKey ->
                                        response.clientSecret?.let { clientSecret ->
                                            localStorage.setItem("client_secret", clientSecret)
                                            localStorage.setItem("ephemeral_key", emphemralKey)
                                            println("Storing amount in localStorage: $amount")
                                            js("runStripe();")
                                        }
                                    }

                                },
                                onError = { errorMessage ->
                                    println("Choose Plan Error: $errorMessage")
                                    pricingViewModel.error.value = Resource.Error(errorMessage)
                                }
                            )
                        },
                        onError = { errorMessage ->
                            println("Ephemeral Key Error: $errorMessage")
                            pricingViewModel.error.value = Resource.Error(errorMessage)
                        }
                    )
                },
                onLogout = {

                },
                onUploadDocumentClick = {},
                onOpenPdf = {}
            )
            handleDeepLink(
                url,
                hash,
                path,
                prefsManager,
                navigate = { route ->
                    navController.navigate(route)
                }
            )
            navController.addOnDestinationChangedListener { _, destination, _ ->
                val newRoute = destination.route!!.split("?")[0]
                if (window.history.state != newRoute) {
                    window.history.pushState(newRoute, "", newRoute)
                }
                println("Provera navigacije, prelazak na sledecu rutu $destination")
            }

            LaunchedEffect(Unit) {
                val listener: (org.w3c.dom.events.Event) -> Unit = {
                    val currentRoute = getCurrentRoute()
                    window.history.replaceState(currentRoute, "", currentRoute)
                    navController.navigate(currentRoute)
                }
                window.addEventListener("popstate", listener)
            }

            LaunchedEffect(Unit) {
                delay(3000)
                window.asDynamic().onPaymentSuccess = { paymentMethodId: String ->
                    pricingViewModel.addCard(AddCardRequest(
                        prefsManager.getUser()?.customerId,
                        paymentMethodId,
                        paymentIntentId,
                        pricingViewModel.selectedPlanId.value
                    ),
                        onSuccess = {
                            navController.navigate(Screen.Workspace.route)


                        },
                        onError = {

                        })
                }
            }


            if (showSetPasswordView.value) {
                SetPasswordWebView(
                    title = stringResource(Res.string.join_good_job),
                    passwordInput = passwordInput,
                    confirmPasswordInput = confirmPasswordInput,
                    onSavePassword = {
                        CoroutineScope(Dispatchers.Main).launch {
                            val result = repository.setPassword(
                                SetPasswordRequest(
                                    password = passwordInput.value,
                                    confirm_password = confirmPasswordInput.value,
                                    hash = setPasswordHash.value
                                )
                            )
                            when (result) {
                                is Resource.Success -> {
                                    showSetPasswordView.value = false
                                    showPasswordSetSuccessDialog.value = true
                                    hash = null
                                    //todo check if it ios and open app store
                                }

                                is Resource.Error -> {
                                    showSetPasswordView.value = false
                                    showErrorDialog.value = true
                                    errorText.value = result.text.toString()
                                }
                            }
                            showSetPasswordView.value = false
                        }
                    })
            }
            if (showErrorDialog.value) {
                ErrorDialog(
                    onDismiss = { showErrorDialog.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = errorText.value
                )
            }
            if (showSuccessDialog.value) {
                PopUpDialog(
                    onDismiss = { showSuccessDialog.value = false },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.password_updated),
                    onPositiveBtnClicked = { showSuccessDialog.value = false },
                    positiveBtnText = stringResource(Res.string.ok).uppercase()
                )
            }
            if (showPasswordSetSuccessDialog.value) {
                PopUpDialog(
                    onDismiss = {
                        showPasswordSetSuccessDialog.value = false
                        if (navigator.userAgent.contains("iPhone") || navigator.userAgent.contains(
                                "iPad"
                            )
                        ) {
                            //todo set it when it is published
                            //todo check if just go to ios app it will contain hash in url
                            window.location.href = "https://apps.apple.com/app/id123456789?referrer=12345"
                        }
                    },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.password_set),
                    onPositiveBtnClicked = { showPasswordSetSuccessDialog.value = false },
                    positiveBtnText = stringResource(Res.string.ok).uppercase()
                )
            }
        }
    }
}

fun getCurrentRoute(): String {
    val route = window.location.pathname.replace("/", "")
    if (route.isNotEmpty() && availableRoutes.contains(route)) {
        return route
    }
    return "splash"
}

fun handleDeepLink(
    uri: String,
    hash: String?,
    path: String,
    prefsManager: PrefsManager,
    navigate: (route: String) -> Unit
) {
    navigateFun = navigate
    CoroutineScope(Dispatchers.Default).launch {
        hash?.let {
            if (navigator.userAgent.contains("Android")) {
                window.location.href =
                    "https://play.google.com/store/apps/details?id=com.hyperether.goodjob&referrer=${path}hash$it";
                return@launch
            }
//            else if (navigator.userAgent.contains("iPhone") || navigator.userAgent.contains("iPad")) {
//                //todo finish for ios
//                window.location.href = "https://apps.apple.com/app/id123456789?referrer=12345"
//                return@launch
//            }
            delay(1000L)

            token.value = if (uri.toString().contains("token=")) {
                uri.toString().substringAfter("token=", "").substringBefore("&")
            } else {
                logout(navigate)
                null
            }
            if (hash != null && uri.toString().contains("set-password")) {
                repository.logoutUser()
                setPasswordHash.value = hash
                showSetPasswordView.value = true
            }
            if (hash != null && uri.toString().contains("confirm-registration")) {
                repository.logoutUser()
                val result = repository.confirmation(hash)
                if (result is Resource.Success) {
                    handleNavigation(prefsManager, navigate)
                }
            }
            if (!token.value.isNullOrEmpty() && uri.toString().contains("reset")) {
                showSetNewPasswordView.value = true
            }
        } ?: run {
            delay(1000L)
            handleNavigation(prefsManager, navigate)
        }
    }
}

private fun logout(navigate: (route: String) -> Unit) {
    CoroutineScope(Dispatchers.Main).launch {
        //todo check this
        navigate(Screen.Register.route)
        repository.logoutUser()
        // TODO: clear view models?
//            addNewViewModel.removeSkills()
        UploadManager.removeAll()
        navController.navigate(Screen.Register.route) {
            popUpTo(Screen.Register.route) {
                inclusive = true
            }
            launchSingleTop = true
        }
    }
}

fun handleNavigation(
    prefsManager: PrefsManager,
    navigate: (route: String) -> Unit
) {

    println("get user role test: ${prefsManager.getUser()?.role}")
    println("get user test: ${prefsManager.getUser()}")
    if (prefsManager.getUser()?.role.equals(Role.employee.name, ignoreCase = true) ||
        prefsManager.getUser()?.role.equals(Role.manager.name, ignoreCase = true)
    ) {
        if (prefsManager.getUser() != null) {
            if (prefsManager.getWorkspaceId().isNotEmpty()) {
                navigate(Screen.Dashboard.route)
            } else {
                navigate(Screen.Workspace.route)
            }
        } else {
            navigate(Screen.Register.route)
        }
    } else {
        if (!prefsManager.isOnboardingShown()) {
            navigate(Screen.Landing.route)
            prefsManager.onBoardingShown(true)
        } else if (prefsManager.getUser() != null) {
            if (prefsManager.getUser()?.planId.isNullOrEmpty()) {
                // admin without plan:
                if (prefsManager.getUser()?.workspaceId.isNullOrEmpty()) {
                    // does not belong to workspace:
                    navigate(Screen.Pricing.route)
                } else if (prefsManager.getWorkspaceId().isEmpty()) {
                    // has workspace but not set
                    navigate(Screen.Workspace.route)
                } else {
                    // had workspace and set it:
                    navigate(Screen.Dashboard.route)
                }
            } else if (prefsManager.getWorkspaceId().isEmpty()) {
                // plan set, workspace not set:
                navigate(Screen.Workspace.route)
            } else {
                // plan set, workspace set:
                navigate(Screen.Dashboard.route)
            }
        } else {
            navigate(Screen.Register.route)
        }
    }
}