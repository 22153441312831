package com.hyperether.goodjob.scenes.contacts.site

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.AddressObject
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_new_site
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.enter_contact_name
import com.hyperether.goodjob.resources.enter_email_address
import com.hyperether.goodjob.resources.enter_site_name
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.site_deleted
import com.hyperether.goodjob.resources.site_name
import com.hyperether.goodjob.resources.site_updated
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.trash
import com.hyperether.goodjob.resources.update_site
import com.hyperether.goodjob.scenes.components.AddressInputField
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.contacts.ContactsViewModel
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.util.Constants
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun AddNewSite(
    contactId: String,
    siteId: String,
    viewModel: ContactsViewModel,
    navHostController: NavHostController,
    isMobileView: Boolean,
    ) {
    val site by viewModel.site.collectAsState()
    val contact by viewModel.contact.collectAsState()
    val log = logging("AddNewSiteScreen")

    LaunchedEffect(siteId) {
        viewModel.init()
        viewModel.fetchSiteById(siteId)
        viewModel.fetchContactById(contactId)
    }
    log.d("AddNewSiteScreen") { "Site: id:$siteId $site" }
    log.d("AddNewSiteScreen") { "Contact: $contact" }

    LaunchedEffect(site) {
        if (siteId != Constants.DEFAULT_SITE_ID) {
            site.let {
                if (it != null) {
                    viewModel.siteNameInput.value = site?.siteName ?: viewModel.siteNameInput.value
                    viewModel.siteContactNameInput.value =
                        site?.personName ?: viewModel.siteContactNameInput.value
                    viewModel.siteEmailInput.value =
                        site?.personEmail ?: viewModel.siteEmailInput.value
                    val addressObject: AddressObject? = site?.addressObject
                    val address = addressObject?.address ?: viewModel.siteAddressInput.value
                    viewModel.siteAddressInput.value = address
                    viewModel.sitePhoneInput.value =
                        site?.personPhone ?: viewModel.sitePhoneInput.value
                }
            }
        } else {
            viewModel.clearSiteValues()
        }
    }

    val textSiteUpdated = stringResource(Res.string.site_updated)
    val textSiteDeleted = stringResource(Res.string.site_deleted)

    val title = if (siteId == Constants.DEFAULT_SITE_ID) {
        stringResource(Res.string.add_new_site)
    } else {
        stringResource(Res.string.update_site)
    }

    Column {
        if(isMobileView) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 20.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.back_arrow),
                    contentDescription = "",
                    modifier = Modifier
                        .clickable {
                            navHostController.popBackStack()
                        }
                )

                Text(
                    text = title,
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier
                        .weight(1f)
                    //.padding(start = 16.dp)
                )
                if (siteId != Constants.DEFAULT_SITE_ID) {
                    Image(
                        painter = painterResource(Res.drawable.trash),
                        contentDescription = "",
                        modifier = Modifier
                            .clickable {
                                viewModel.showLoader.value = true
                                viewModel.deleteSite(siteId,
                                    onSuccess = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpSuccess.value = true
                                        viewModel.successApiText.value = textSiteDeleted
                                    },
                                    onError = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpError.value = true

                                    })
                            }
                    )
                }
            }

            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))

            Column(
                modifier = Modifier.padding(start = 16.dp, end = 16.dp).verticalScroll(
                    rememberScrollState()
                )
            ) {
                AppInputField(
                    viewModel.siteNameInput,
                    stringResource(Res.string.site_name),
                    placeholder = stringResource(Res.string.enter_site_name),
                )

                AppInputField(
                    viewModel.siteContactNameInput,
                    stringResource(Res.string.contact_name),
                    placeholder = stringResource(Res.string.enter_contact_name),
                )

                AppInputField(
                    viewModel.siteEmailInput,
                    stringResource(Res.string.email_address),
                    placeholder = stringResource(Res.string.enter_email_address)
                )

                AddressInputField(
                    text = viewModel.siteAddressInput,
                    label = stringResource(Res.string.address),
                    placeholder = stringResource(Res.string.enter_street),
                    onSearch = { viewModel.findPlace(it) },
                    onSelect = {
                        viewModel.siteAddressInput.value = it.formattedAddress
                        viewModel.place.value = it
                    },
                    places = viewModel.places,
                    modifier = Modifier
                )

                PhoneInputField(
                    viewModel.sitePhoneInput,
                    stringResource(Res.string.phone),
                    placeholder = "+1 345 2525 252",
                )

                Spacer(modifier = Modifier.height(30.dp))
                val newSite = Site(
                    siteName = viewModel.siteNameInput.value,
                    parentName = contact?.name,
                    parentId = contactId,
                    addressObject = AddressObject(
                        address = viewModel.siteAddressInput.value,
                        lat = viewModel.place.value?.location?.latitude,
                        long = viewModel.place.value?.location?.longitude
                    ),
                    personName = viewModel.siteContactNameInput.value,
                    personPhone = viewModel.sitePhoneInput.value,
                    personEmail = viewModel.siteEmailInput.value
                )
                FilledButton(
                    onClick = {
                        // Validate inputs before proceeding
                        val errorMessage = when {
                            viewModel.siteAddressInput.value.isBlank() -> "Address is required."
                            else -> null
                        }

                        if (errorMessage != null) {
                            viewModel.showPopUpError.value = true
                            viewModel.errorText.value = errorMessage
                        } else {
                            viewModel.showLoader.value = true
                            if (siteId == Constants.DEFAULT_SITE_ID) {
                                viewModel.addSite(newSite)
                            } else {
                                viewModel.updateSite(siteId, newSite,
                                    onSuccess = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpSuccess.value = true
                                        viewModel.successApiText.value = textSiteUpdated
                                    },
                                    onError = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpError.value = true

                                    })
                            }
                        }
                    },
                    text = title,
                    isEnabled = true
                )

                Spacer(modifier = Modifier.height(15.dp))

                androidx.compose.material.OutlinedButton(
                    onClick = {
                        navHostController.popBackStack()
                    },
                    modifier = Modifier.fillMaxWidth().height(50.dp),
                    border = BorderStroke(1.dp, BlueApp),
                    shape = RoundedCornerShape(50),
                    colors = androidx.compose.material.ButtonDefaults.outlinedButtonColors(
                        contentColor = Color.White,
                        backgroundColor = Color.Transparent
                    ),
                ) {
                    Text(
                        text = stringResource(Res.string.cancel),
                        maxLines = 1,
                        fontSize = 12.5.sp,
                        color = BlueApp,
                        letterSpacing = (-0.02).sp
                    )
                }
                if (viewModel.showPopUpSuccess.value) {
                    PopUpDialog(
                        onDismiss = {
                            viewModel.showPopUpSuccess.value = false
                            navHostController.popBackStack()
                        },
                        title = stringResource(Res.string.success).uppercase(),
                        message = viewModel.successApiText.value,
                        positiveBtnText = stringResource(Res.string.ok).uppercase(),
                        onPositiveBtnClicked = {
                            viewModel.showPopUpSuccess.value = false
                            navHostController.popBackStack()
                        }
                    )
                }
                if (viewModel.showPopUpError.value) {
                    ErrorDialog(
                        onDismiss = { viewModel.showPopUpError.value = false },
                        title = stringResource(Res.string.error).uppercase(),
                        message = viewModel.errorText.value
                    )
                }
                if (viewModel.showLoader.value) {
                    FullScreenProgressBar()
                }
            }
        } else {
            AddNewSiteWeb(viewModel, navHostController, contactId, siteId)
        }
    }
}