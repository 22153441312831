package com.hyperether.goodjob.scenes.workspace

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import org.lighthousegames.logging.logging

class WorkspaceViewModel(
    val repository: Repository
) : ViewModel() {

    val log = logging("WorkspaceViewModel")
    val workspaces = mutableStateListOf<Workspace>()

    var workspaceId: MutableState<String> = mutableStateOf("")

    private val _currentPlan = mutableStateOf<Plan?>(null)
    val currentPlan: State<Plan?> = _currentPlan

    init {
        if (getPlatformChecker().isMobile()) {
            log.d("WorkspaceViewModel") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            getWorkspaces()
        }
    }

    fun addNewWorkspace(workspace: Workspace) {
        viewModelScope.launch {
            val result = workspace.name?.let {
                workspace.workspaceType?.let { it1 ->
                    repository.addWorkspace(
                        it,
                        it1
                    )
                }
            }
            if (result?.data != null && getPlatformChecker().isMobile()) {
                repository.insertWorkspace(result.data)
                getWorkspaces()
            }
        }
    }

    fun getWorkspaces() {
        viewModelScope.launch {
            if (isSuperAdmin()) {
                when (repository.getAllWorkspaces()) {
                    is Resource.Success -> {
                        getAllWorkspacesLocal()
                    }

                    is Resource.Error -> {
                        // Handle error state if needed
                    }
                }
            } else {
                when (repository.getUserWorkspaces()) {
                    is Resource.Success -> {
                        getAllWorkspacesLocal()
                    }

                    is Resource.Error -> {
                        // Handle error state if needed
                    }
                }
            }
        }
    }

    private suspend fun getAllWorkspacesLocal() {
        repository.getWorkspacesFlow().collect {
            workspaces.clear()
            workspaces.addAll(it)
        }
    }

    fun getAllJobs() {
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                val result = repository.getAllJobsForWorkspace()

                when (result) {
                    is Resource.Success -> {
                        println(result)
                    }

                    is Resource.Error -> {
                        println(result)
                    }
                }
            }
        }
    }

    fun getUser(): User? {
        return repository.getUser()
    }

    fun fetchCurrentPlan() {
        val user = getUser()
        if (user == null) {
            _currentPlan.value = null
            return
        }

        viewModelScope.launch {
            // todo fix null
            user.planId?.let {
                val plan = repository.getPlanById(it)
                _currentPlan.value = plan
            } ?: run {
                repository.getWorkspacesFlow().collect { list ->
                    if (list.isNotEmpty()) {
                        val workspace = list.get(0)
                        workspace.planId?.let {
                            val plan = repository.getPlanById(it)
                            _currentPlan.value = plan
                        }
                    }
                }
            }
        }
    }

    fun saveWorkspaceId(workspaceId: String) {
        return repository.saveWorkspaceId(workspaceId)
    }

    fun updateLastAccessed() {
        viewModelScope.launch {
            val lastAccessed =
                Clock.System.now().toEpochMilliseconds().toString()
            repository.updateWorkspaceLastAccessedById(lastAccessed, workspaceId.value)
        }
    }

    fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

    fun isSuperAdmin(): Boolean {
        return repository.getUser()?.role.equals(Role.superAdmin.name, ignoreCase = true)
    }
}