package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName

data class Employee(
    @SerialName("id") var id: Int? = 0,
    @SerialName("full_name") var full_name: String? = null,
    @SerialName("address") var address: String? = null,
    @SerialName("city_zip_country") var city_zip_country: String? = null,
    @SerialName("email") var email: String? = null,
    @SerialName("phone_number") var phone_number: String? = null,
    @SerialName("status") var status: String? = null,
    @SerialName("team") var team: String? = null,
    @SerialName("avatar") var avatar: String? = null,
    @SerialName("role") var role: String? = null
)