package com.hyperether.goodjob.scenes.jobs.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.theme.DarkGrey

@Composable
fun JobDetailsView(
    title: String,
    firstDescriptionText: String,
    firstDetailText: String,
    secondDetailText: String,
    secondDescriptionText: String,
    thirdDetailText: String,
    thirdDescriptionText: String
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 16.dp, end = 16.dp, top = 12.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = title,
                fontWeight = FontWeight.W700,
                fontSize = 16.sp
            )

            Text(
                text = buildAnnotatedString {
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = DarkGrey
                        )
                    ) {
                        append(firstDescriptionText)
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black
                        )
                    ) {
                        append(firstDetailText)
                    }
                },
                fontSize = 14.sp
            )

            Text(
                text = buildAnnotatedString {
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = DarkGrey
                        )
                    ) {
                        append(secondDescriptionText)
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black
                        )
                    ) {
                        append(secondDetailText)
                    }
                },
                fontSize = 14.sp,
            )

            Text(
                text = buildAnnotatedString {
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = DarkGrey
                        )
                    ) {
                        append(thirdDescriptionText)
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black
                        )
                    ) {
                        append(thirdDetailText)
                    }
                },
                fontSize = 14.sp,
            )
        }
    }
}