package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Team(
    @SerialName("_id") var _id: String? = null,
    @SerialName("name") var name: String? = null,
    @SerialName("workspaceId") var workspaceId: String? = null,
    @SerialName("employees") var employees: List<User>? = emptyList(),
    @SerialName("type") var type: String? = null,
    @SerialName("createdAt") var createdAt: Long? = null,
    @SerialName("updatedAt") var updatedAt: Long? = null,
)