package com.hyperether.goodjob.scenes.employees

import androidx.compose.animation.core.tween
import androidx.compose.animation.expandHorizontally
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInHorizontally
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.PlatformChecker
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.contact_information
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employee_details
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.leave_details
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmployeesItem(
    employee: User,
    isInEditMode: Boolean,
    onEmployeeSelected: (User, Boolean) -> Unit,
    onItemClick: () -> Unit
) {
    var isChecked = remember { mutableStateOf(false) }

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp)
            .clickable {
                onItemClick()
            }
    ) {
        if (isInEditMode) {
            RoundedCornerCheckbox(
                isChecked = isChecked.value,
                onCheckedChange = { checked ->
                    isChecked.value = checked
                    onEmployeeSelected(employee, checked)
                }
            )
        }

        Spacer(modifier = Modifier.width(15.dp))
        Image(
            painter = painterResource(Res.drawable.baseline_person_24),
            contentDescription = null,
            modifier = Modifier
                .size(40.dp)
                .clip(CircleShape)
                .background(Color.LightGray.copy(alpha = 0.3f))
                .align(Alignment.Top)
        )

        Spacer(modifier = Modifier.width(8.dp))

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 8.dp)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 4.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                employee.fullName?.let {
                    Text(
                        text = it,
                        fontSize = 16.sp,
                        fontWeight = FontWeight.Bold
                    )
                }
                Spacer(modifier = Modifier.width(15.dp))
                employee.email?.let {
                    Text(
                        text = it,
                        fontSize = 14.sp,
                        color = Color.LightGray
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.team),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                val team = employee.teams?.let { Mapper().extractValues(it) }
                team?.firstOrNull()?.let {
                    Text(
                        text = it,
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                }

            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.phone),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                employee.phone?.let {
                    Text(
                        text = it.toString(),
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                }
            }
        }
    }
}

@Composable
fun EditDialog(
    showDialog: Boolean, employees: List<User>, onDelete: () -> Unit, onExportList: () -> Unit,
    onImportList: () -> Unit,
    onEdit: () -> Unit
) {
    if (showDialog) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp))
            //.shadow(1.dp, RoundedCornerShape(8.dp))
        ) {
            Column() {
                Text(
                    text = "${employees.size} selected",
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    Text(
                        text = stringResource(Res.string.delete),
                        fontSize = 14.sp,
                        color = Color.Red,
                        modifier = Modifier.clickable {
                            onDelete()
                        })
                    Text(
                        text = stringResource(Res.string.export_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onExportList()
                        })
                    Text(
                        text = stringResource(Res.string.import_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onImportList()
                        })
                    Text(text = stringResource(Res.string.edit), fontSize = 14.sp, color = BlueApp,
                        modifier = Modifier.clickable {
                            onEdit()
                        })
                }
            }
        }
    }
}

@Composable
fun RoundedCornerCheckbox(
    isChecked: Boolean,
    onCheckedChange: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
    size: Float = 24f,
    checkedColor: Color = BlueApp,
    uncheckedColor: Color = Color.Gray,
    checkmarkColor: Color = Color.White,
) {
    val density = LocalDensity.current
    val duration = 200

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .heightIn(48.dp)
            .clickable { onCheckedChange(!isChecked) }
    ) {
        Box(
            modifier = Modifier
                .size(size.dp)
                .border(
                    width = 1.5.dp,
                    color = if (isChecked) checkedColor else uncheckedColor,
                    shape = CircleShape
                ),
            contentAlignment = Alignment.Center
        ) {
            androidx.compose.animation.AnimatedVisibility(
                visible = isChecked,
                enter = slideInHorizontally(animationSpec = tween(duration)) {
                    with(density) { (size * -0.5).dp.roundToPx() }
                } + expandHorizontally(
                    expandFrom = Alignment.Start,
                    animationSpec = tween(duration)
                ),
                exit = fadeOut()
            ) {
                Box(
                    modifier = Modifier
                        .size(size.dp)
                        .background(color = checkedColor, shape = CircleShape),
                    contentAlignment = Alignment.Center
                ) {
                    androidx.compose.material3.Icon(
                        Icons.Default.Check,
                        contentDescription = null,
                        tint = checkmarkColor
                    )
                }
            }
        }
    }
}

@Composable
fun EmployeesList(
    employees: List<com.hyperether.goodjob.database.User>,
    selectedEmployees: MutableList<com.hyperether.goodjob.database.User>,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize()
    ) {
        items(employees) { employee ->
            EmployeesItem(
                employee = employee,
                isInEditMode = isInEditMode,
                onEmployeeSelected = { selectedEmployee, isSelected ->
                    if (isSelected) {
                        selectedEmployees.add(selectedEmployee)
                    } else {
                        selectedEmployees.remove(selectedEmployee)
                    }
                },
                onItemClick = {
                    navHostController.navigate(Screen.EmployeeDetails.route + "/${employee.id}")
                }
            )
            Divider(
                //modifier = Modifier.padding(),
                color = Color.Gray,
                thickness = 0.5.dp
            )
        }
    }
}

@Composable
fun EmployeeHeaderView(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                painter = painterResource(Res.drawable.baseline_person_24),
                contentDescription = "Rounded Image",
                modifier = Modifier
                    .size(50.dp)
                    .clip(CircleShape)
                    .background(Color.LightGray)

            )

            Spacer(modifier = Modifier.width(16.dp))

            Column(
                modifier = Modifier.weight(1f)
            ) {
                user?.fullName?.let {
                    Text(
                        text = it,
                        fontWeight = FontWeight.Bold,
                        fontSize = 14.sp
                    )
                }
                user?.role?.let {
                    Text(
                        text = it,
                        color = Color.Gray,
                        fontSize = 14.sp
                    )
                }
            }

            Text(
                text = "",
                modifier = Modifier.align(Alignment.CenterVertically)
            )
        }
    }
}

@Composable
fun EmployeeDetailsView(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.contact_information),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            Text(
                text = "",
                fontSize = 14.sp,
                color = Color.Gray,
            )

            Text(
                    text = user?.teams?.let { Mapper().extractValues(it) }.toString(),
                    color = Color.Gray,
                    fontSize = 14.sp
                )
            user?.skills?.let {
                Text(
                    text = it,
                    color = Color.Gray,
                    fontSize = 14.sp
                )
            }

        }
    }
}

@Composable
fun EmployeeContactsView(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.employee_details),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            user?.email?.let {
                Text(
                    text = it,
                    fontSize = 14.sp,
                    color = Color.Gray,
                )
            }
            user?.phone?.let {
                Text(
                    text = it,
                    color = Color.Gray,
                    fontSize = 14.sp
                )
            }
            user?.address?.let {
                Text(
                    text = it,
                    color = Color.Gray,
                    fontSize = 14.sp
                )
            }

        }
    }
}

@Composable
fun EmployeeLeaveDetails(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.leave_details),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            Text(
                    text = "November 10 - November 15, 2024",
                    fontSize = 14.sp,
                    color = Color.Gray,
                )

        }
    }
}

