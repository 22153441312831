package com.hyperether.goodjob

import kotlinx.browser.window


class PlatformCheckerJs: PlatformChecker {

    override fun isMobile(): Boolean {
        val userAgent = js("navigator.userAgent") as String
        return userAgent.contains("Mobi") || userAgent.contains("Android") || window.innerWidth <= 768
    }
}

actual fun getPlatformChecker(): PlatformChecker {
    return PlatformCheckerJs()
}