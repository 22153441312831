package com.hyperether.goodjob

import com.hyperether.planner.model.DashboardEvent
import com.hyperether.planner.model.Event
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import kotlinx.datetime.LocalDateTime

val planList = listOf(
    Plan(
        "Nikola", listOf(
            Event(
                status = EventStatus.COMPLETED,
                from = LocalDateTime.parse("2024-10-24T10:19:44"),
                to = LocalDateTime.parse("2024-10-24T12:19:44"),
                title = "Implement planner"
            )
        )
    ),
    Plan(
        "Nikola Hadzic", listOf(
            Event(
                status = EventStatus.COMPLETED,
                from = LocalDateTime.parse("2024-10-24T10:19:44"),
                to = LocalDateTime.parse("2024-10-24T10:40:44"),
                title = "Implement scheduler"
            )
        )
    ),
    Plan(
        "Jelena", listOf(
            Event(
                status = EventStatus.DELAYED,
                from = LocalDateTime.parse("2024-10-24T13:19:44"),
                to = LocalDateTime.parse("2024-10-24T15:34:44"),
                title = "Implement calendar"
            )
        )
    ),
    Plan(
        "Sandra", listOf(
            Event(
                status = EventStatus.PENDING,
                from = LocalDateTime.parse("2024-10-24T16:10:44"),
                to = LocalDateTime.parse("2024-10-24T18:50:44"),
                title = "Implement planner"
            )
        )
    ),

    Plan(
        "Slobodan", listOf(
            Event(
                status = EventStatus.PENDING,
                from = LocalDateTime.parse("2024-10-24T14:10:44"),
                to = LocalDateTime.parse("2024-10-24T16:50:44"),
                title = "Make quick scrum start of the project"
            )
        )
    ),

    Plan(
        "Petar", listOf(
            Event(
                status = EventStatus.COMPLETED,
                from = LocalDateTime.parse("2024-10-24T11:10:44"),
                to = LocalDateTime.parse("2024-10-24T18:50:44"),
                title = "Implement Calendar"
            )
        )
    )
)


val planListDashboard = listOf(
            DashboardEvent(
                status = EventStatus.COMPLETED,
                from = LocalDateTime.parse("2024-10-24T08:00:00"),
                to = LocalDateTime.parse("2024-10-24T09:00:59"),
                title = "Midnight Review"
            ),
            DashboardEvent(
                status = EventStatus.PENDING,
                from = LocalDateTime.parse("2024-10-24T10:00:00"),
                to = LocalDateTime.parse("2024-10-24T12:00:59"),
                title = "Project Brainstorm"
            ),
            DashboardEvent(
                status = EventStatus.DELAYED,
                from = LocalDateTime.parse("2024-10-24T12:00:00"),
                to = LocalDateTime.parse("2024-10-24T12:35:59"),
                title = "Overnight Debugging"
            ),
            DashboardEvent(
                status = EventStatus.COMPLETED,
                from = LocalDateTime.parse("2024-10-24T13:00:00"),
                to = LocalDateTime.parse("2024-10-24T13:45:59"),
                title = "Midnight Review"
            ),
            DashboardEvent(
                status = EventStatus.COMPLETED,
                from = LocalDateTime.parse("2024-10-24T15:00:00"),
                to = LocalDateTime.parse("2024-10-24T16:00:59"),
                title = "End of Day Wrap-Up"
            ),
            DashboardEvent(
                status = EventStatus.COMPLETED,
                from = LocalDateTime.parse("2024-10-24T16:00:00"),
                to = LocalDateTime.parse("2024-10-24T18:59:59"),
                title = "Test"
            )
        )