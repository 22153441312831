package com.hyperether.goodjob.navigation

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.avatar_male
import com.hyperether.goodjob.resources.briefcase
import com.hyperether.goodjob.resources.briefcase_unselect
import com.hyperether.goodjob.resources.calendar
import com.hyperether.goodjob.resources.calendar_unselect
import com.hyperether.goodjob.resources.contacts
import com.hyperether.goodjob.resources.dahboard
import com.hyperether.goodjob.resources.dahboard_unselect
import com.hyperether.goodjob.resources.dashboard
import com.hyperether.goodjob.resources.dropdown_arrow
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.employees_unselect
import com.hyperether.goodjob.resources.ic_logout
import com.hyperether.goodjob.resources.ic_logout_selected
import com.hyperether.goodjob.resources.ic_settings
import com.hyperether.goodjob.resources.ic_settings_selected
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.logout
import com.hyperether.goodjob.resources.map
import com.hyperether.goodjob.resources.map_unselect
import com.hyperether.goodjob.resources.places
import com.hyperether.goodjob.resources.scheduler
import com.hyperether.goodjob.resources.settings
import com.hyperether.goodjob.resources.user_check
import com.hyperether.goodjob.resources.user_check_unselect
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.PricingText
import com.hyperether.goodjob.theme.SelectedIconTint
import com.hyperether.goodjob.theme.SplashBg
import com.hyperether.goodjob.theme.UnselectedIconTint
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun WebNav(
    navController: NavHostController,
    workspaceViewModel: WorkspaceViewModel,
    repository: Repository
) {

    var selectedTabIndex = remember { mutableStateOf(0) }
    val expandedDropdown = remember { mutableStateOf(false) }
    val selectedWorkspace = remember { mutableStateOf<String?>(null) }
    val workspaces = workspaceViewModel.workspaces
    val scope = rememberCoroutineScope()

    navController.addOnDestinationChangedListener { controller, destination, arguments ->
        when (destination.route) {
            Screen.Dashboard.route -> selectedTabIndex.value = 0
            Screen.Employees.route -> selectedTabIndex.value = 1
            Screen.Jobs.route -> selectedTabIndex.value = 2
            Screen.Scheduler.route -> selectedTabIndex.value = 3
            Screen.Contacts.route -> selectedTabIndex.value = 4
            Screen.Map.route -> selectedTabIndex.value = 5
            Screen.ProfileSettings.route -> selectedTabIndex.value = 6
        }
    }

    LaunchedEffect(workspaces) {
        if (workspaces.isNotEmpty()) {
            selectedWorkspace.value = workspaces.first().name
        }
    }
    val navItems = listOf(
        WebNavItem(
            name = stringResource(Res.string.dashboard),
            iconResId = painterResource(Res.drawable.dahboard),
            unselectedIconResId = painterResource(
                Res.drawable.dahboard_unselect
            ),
            route = Screen.Dashboard.route
        ),
        WebNavItem(
            name = stringResource(Res.string.employees),
            iconResId = painterResource(Res.drawable.employees),
            unselectedIconResId = painterResource(
                Res.drawable.employees_unselect
            ),
            route = Screen.Employees.route
        ),
        WebNavItem(
            name = stringResource(Res.string.jobs),
            iconResId = painterResource(Res.drawable.briefcase),
            unselectedIconResId = painterResource(
                Res.drawable.briefcase_unselect
            ),
            route = Screen.Jobs.route
        ),
        WebNavItem(
            name = stringResource(Res.string.scheduler),
            iconResId = painterResource(Res.drawable.calendar),
            unselectedIconResId = painterResource(
                Res.drawable.calendar_unselect
            ),
            route = Screen.Scheduler.route
        ),
        WebNavItem(
            name = stringResource(Res.string.contacts),
            iconResId = painterResource(Res.drawable.user_check),
            unselectedIconResId = painterResource(
                Res.drawable.user_check_unselect
            ),
            route = Screen.Contacts.route
        ),
        WebNavItem(
            name = stringResource(Res.string.places),
            iconResId = painterResource(Res.drawable.map),
            unselectedIconResId = painterResource(
                Res.drawable.map_unselect
            ),
            route = Screen.Map.route
        ),
        WebNavItem(
            name = stringResource(Res.string.settings),
            iconResId = painterResource(Res.drawable.ic_settings_selected),
            unselectedIconResId = painterResource(
                Res.drawable.ic_settings
            ),
            route = Screen.ProfileSettings.route
        ),
        WebNavItem(
            name = stringResource(Res.string.logout),
            iconResId = painterResource(Res.drawable.ic_logout_selected),
            unselectedIconResId = painterResource(
                Res.drawable.ic_logout
            ),
            route = Screen.Landing.route,
            onClick = {
                scope.launch {
                    repository.logoutUser()
                }
            }
        )
    )

    Column(
        modifier = Modifier
            .fillMaxHeight()
            .width(Dp(310f))
            .background(SplashBg)
            .verticalScroll(rememberScrollState()),
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        // Profile Section
        Column(
            modifier = Modifier.padding(Dp(24f)),
            horizontalAlignment = Alignment.Start
        ) {
            Row {
                Image(
                    painter = painterResource(Res.drawable.avatar_male),
                    contentDescription = "Profile Picture",
                    modifier = Modifier
                        .size(Dp(56f))
                        .background(SplashBg)
                )
                Spacer(modifier = Modifier.width(Dp(12f)))
                Column {
                    Text(
                        text = workspaceViewModel.getUser()?.getName() ?: "",
                        fontWeight = FontWeight.W700,
                        color = PricingText,
                        fontSize = Sp(18f)
                    )
                    Text(
                        text = workspaceViewModel.getUser()?.email ?: "",
                        color = UnselectedIconTint,
                        fontSize = Sp(13f),
                        fontWeight = FontWeight.W400
                    )
                }
            }
            Spacer(modifier = Modifier.height(Dp(44f)))
            // TODO: uncomment when we get support for multiple workspaces
//            WorkspaceTypeDropdown(
//                selectedType = selectedWorkspace.value ?: "",
//                onTypeSelected = { selected ->
//                    selectedWorkspace.value = selected
//                    expandedDropdown.value = false
//                },
//                expanded = expandedDropdown.value,
//                onExpandToggle = { expandedDropdown.value = it },
//                modifier = Modifier.width(250.dp),
//                workspaces = workspaces
//            )
        }


        // Navigation Items
        Column(
            modifier = Modifier.padding(horizontal = Dp(24f)),
            verticalArrangement = Arrangement.spacedBy(Dp(12f))
        ) {
            navItems.subList(0, 6).forEachIndexed { index, item ->
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(
                            shape = RoundedCornerShape(Dp(8f)),
                            color = if (selectedTabIndex.value == index) {
                                Color.White.copy(alpha = 0.08f)
                            } else {
                                Color.Transparent
                            }
                        )
                        .padding(Dp(10f))
                        .height(Dp(56f))
                        .clickable {
                            navController.navigate(item.route)
                            selectedTabIndex.value = index
                        },
                    contentAlignment = Alignment.CenterStart
                ) {
                    Row(
                        horizontalArrangement = Arrangement.Start,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        val icon =
                            if (selectedTabIndex.value == index) item.iconResId else item.unselectedIconResId
                        Icon(
                            modifier = Modifier
                                .width(Dp(24f))
                                .height(Dp(24f)),
                            painter = icon,
                            contentDescription = null,
                            tint = if (selectedTabIndex.value == index) SelectedIconTint else UnselectedIconTint
                        )
                        Spacer(modifier = Modifier.width(Dp(16f)))
                        Text(
                            text = item.name,
                            fontSize = Sp(16f),
                            fontWeight = if (selectedTabIndex.value == index) FontWeight.W700 else FontWeight.W400,
                            color = if (selectedTabIndex.value == index) SelectedIconTint else UnselectedIconTint
                        )
                    }
                }
            }
        }

        // Spacer between main items and bottom items
        Spacer(modifier = Modifier.weight(1f))

        // Settings and Logout
        Column(
            modifier = Modifier.padding(horizontal = Dp(24f)),
            verticalArrangement = Arrangement.spacedBy(Dp(12f))
        ) {
            navItems.subList(6, 8).forEachIndexed { index, item ->
                val actualIndex = index + 6
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(
                            shape = RoundedCornerShape(Dp(8f)),
                            color = if (selectedTabIndex.value == actualIndex) {
                                Color.White.copy(alpha = 0.08f)
                            } else {
                                Color.Transparent
                            }
                        )
                        .padding(Dp(10f))
                        .height(Dp(56f))
                        .clickable {
                            navController.navigate(item.route)
                            selectedTabIndex.value = actualIndex
                            if (actualIndex == 7) {
                                item.onClick?.invoke()
                            }
                        },
                    contentAlignment = Alignment.CenterStart
                ) {
                    Row(
                        horizontalArrangement = Arrangement.Start,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        val icon =
                            if (selectedTabIndex.value == actualIndex) item.iconResId else item.unselectedIconResId
                        Icon(
                            modifier = Modifier
                                .width(Dp(24f))
                                .height(Dp(24f)),
                            painter = icon,
                            tint = if (selectedTabIndex.value == actualIndex) SelectedIconTint else UnselectedIconTint,
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(Dp(16f)))
                        Text(
                            text = item.name,
                            fontSize = Sp(16f),
                            fontWeight = if (selectedTabIndex.value == actualIndex) FontWeight.W700 else FontWeight.W400,
                            color = if (selectedTabIndex.value == actualIndex) SelectedIconTint else UnselectedIconTint
                        )
                    }
                }
            }
            Spacer(modifier = Modifier.height(Dp(32f)))
        }
    }
}

@Composable
fun WorkspaceTypeDropdown(
    selectedType: String,
    onTypeSelected: (String) -> Unit,
    expanded: Boolean,
    onExpandToggle: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
    workspaces: List<Workspace>
) {
    Column {
        Box(
            modifier = modifier
                .border(1.dp, DarkGrey, RoundedCornerShape(8.dp))
                .padding(16.dp)
                .fillMaxWidth() // Ensures the box takes the full width
                .clickable { onExpandToggle(true) },
            contentAlignment = Alignment.CenterStart
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween, // Space between text and icon
                modifier = Modifier.fillMaxWidth() // Make row fill the width
            ) {
                Text(
                    text = selectedType,
                    color = PricingText,
                    textAlign = TextAlign.Start,
                    fontSize = 14.sp,
                    modifier = Modifier.weight(1f) // Pushes icon to the end
                )
                Icon(
                    painter = painterResource(Res.drawable.dropdown_arrow),
                    contentDescription = "Dropdown arrow",
                    tint = DarkGrey,
                    modifier = Modifier // No weight on the icon keeps it at the end
                )
            }
        }
        DropdownMenu(
            modifier = Modifier.background(SplashBg).width(250.dp),
            expanded = expanded,
            onDismissRequest = { onExpandToggle(false) },
        ) {
            workspaces.forEach { workspace ->
                DropdownMenuItem(
                    modifier = Modifier.fillMaxWidth(),
                    text = { Text(text = workspace.name ?: "test", color = PricingText) },
                    onClick = { workspace.name?.let { onTypeSelected(it) } },
                )
            }
        }
    }
}

data class WebNavItem(
    val name: String,
    val iconResId: Painter,
    val unselectedIconResId: Painter,
    val route: String,
    val onClick: (() -> Unit)? = null
)