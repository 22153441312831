package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Repeat
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.planner.model.Event
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import com.hyperether.planner.util.EventStatusMapper
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.daysUntil
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime
import kotlinx.datetime.todayIn

class SchedulerViewModel(val repository: Repository) : ViewModel() {

    val initialLoad = mutableStateOf(true)
    var periodState = mutableStateOf(PeriodType.DAY)
    var currentPeriod = mutableStateOf("Today")
    private var selectedDay: LocalDate = Clock.System.todayIn(TimeZone.currentSystemDefault())
    private val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
    var listOfPlans = mutableStateListOf<Plan>()
    var jobClicked: MutableState<Boolean> = mutableStateOf(false)

    val selectedJobWeekly: MutableState<Job?> = mutableStateOf(null)
    var selectedJobWeeklyHover = mutableStateOf(false)
    var hoveredEvent = mutableStateOf<Event?>(null)
    var position = mutableStateOf(Position(0.dp, 0.dp))
    val sites = mutableStateMapOf<String, Site>()
    var currentJobs = mutableStateListOf<Job>()
    var monthSelectedDay = mutableStateOf<Int?>(null)

    var weekSelectionStart = mutableStateOf(
        Clock.System.now()
            .toLocalDateTime(TimeZone.currentSystemDefault()).date.minus(DatePeriod(days = today.dayOfWeek.ordinal))
    )
    var weekSelectionEnd = mutableStateOf(
        Clock.System.now()
            .toLocalDateTime(TimeZone.currentSystemDefault()).date.plus(DatePeriod(days = DayOfWeek.SUNDAY.ordinal - today.dayOfWeek.ordinal))
    )
    var monthSelectionLocalDate =
        mutableStateOf(Clock.System.todayIn(TimeZone.currentSystemDefault()))

    private val _userState = MutableStateFlow<List<User>?>(null)
    val userState: StateFlow<List<User>?> get() = _userState

    val userStateWeb = mutableStateOf<List<User>>(emptyList())

    val jobList = mutableStateListOf<Job>()
    val sitesMap = mutableStateMapOf<String, Site>()

    private val _teams = MutableStateFlow<List<com.hyperether.goodjob.models.Team>>(emptyList())
    val teams: StateFlow<List<com.hyperether.goodjob.models.Team>> = _teams
    val showFilterDialog: MutableState<Boolean> = mutableStateOf(false)

    var selectedStatus = mutableStateOf(setOf<String>())
    var selectedEmployees = mutableStateOf(setOf<String>())
    var selectedTeams = mutableStateOf(setOf<String>())
    var selectedLocation = mutableStateOf(setOf<String>())
    var selectedSchedule = mutableStateOf(setOf<String>())
    val filterApplied: MutableState<Boolean> = mutableStateOf(false)

    private val _jobsState = MutableStateFlow<List<Job>?>(null)
    val jobsState: StateFlow<List<Job>?> get() = _jobsState

    val jobsStateWeb = mutableStateOf<List<Job>>(emptyList())

    var jobStatusMap: Map<String, String> = mutableMapOf()

    init {
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }
        CoroutineScope(Dispatchers.Default).launch {
            val filteredJobs = mutableListOf<Job>()
            repository.getJobsFlow()?.collect {
                if (it.isNotEmpty()) {
                    jobList.clear()
                    filteredJobs.clear()
                    val siteMap = mutableMapOf<String, Site>()
                    it.forEach { job ->
                        job.contactSiteId?.let {
                            val site = getSiteById(it)
                            site?.id?.let { id -> siteMap[job.id!!] = site }
                        }
                        jobList.add(job)
                        filteredJobs.add(job)
                        _jobsState.value = filteredJobs
                    }
                    sitesMap.clear()
                    sitesMap.putAll(siteMap)
                }
            }
        }
        viewModelScope.launch {
            repository.getJobsFlow()?.collect { list ->
                _jobsState.value = list
                jobsStateWeb.value = list
            }
        }
    }

    suspend fun getJobsWeb() {
        repository.getJobsFlow()?.collect {
            jobsStateWeb.value = it
        }
    }

    fun parseSchedule(jobs: List<Job>?, users: List<User>?) {
        listOfPlans.clear()
        currentJobs.clear()
        monthSelectedDay.value = null
        val jobsForEvents: List<Job> =
            jobs?.filter { job ->
                var startDateStartTime: LocalDateTime? = null
                try {
                    val dt = job.startDate + "T" + job.start_at
                    startDateStartTime = LocalDateTime.parse(dt)
                } catch (e: Exception) {
                    return@filter false
                }
                var startDateEndTime: LocalDateTime? = null
                try {
                    val dt = job.startDate + "T" + job.end_at
                    startDateEndTime = LocalDateTime.parse(dt)
                } catch (e: Exception) {
                    return@filter false
                }

                var dueDate: LocalDateTime? = null
                try {
                    val dt = job.dueDate + "T" + job.end_at
                    dueDate = LocalDateTime.parse(dt)
                } catch (e: Exception) {
//                    return@filter false
                }
                println("Due date is ok i think: ${dueDate}")

                when (periodState.value) {
                    PeriodType.DAY -> {
                        if (dueDate != null) {
                            startDateStartTime.date <= selectedDay && selectedDay <= dueDate.date
                        } else {
                            when (job.repeat) {
                                Repeat.never.name -> {
                                    startDateStartTime.date == selectedDay
                                }

                                Repeat.every_day.name,
                                Repeat.every_week.name,
                                Repeat.every_month.name,
                                Repeat.every_2_weeks.name,
                                Repeat.custom.name -> {
                                    startDateStartTime.date <= selectedDay
                                }

                                else -> {
                                    false
                                }
                            }
                        }
                    }

                    PeriodType.WEEK -> {
                        if (dueDate != null) {
                            (startDateStartTime.date <= weekSelectionEnd.value) &&
                                    (dueDate.date >= weekSelectionStart.value)
                        } else {
                            startDateStartTime.date < weekSelectionEnd.value
                        }
                    }

                    PeriodType.MONTH -> {
                        println("startDateStartTime.month  ${startDateStartTime.month}")
                        println("monthSelectionLocalDate.value.month  ${monthSelectionLocalDate.value.month}")
                        println("dueDate.month  ${dueDate?.month}")
                        println("monthSelectionLocalDate.value.month  ${monthSelectionLocalDate.value.month}")
                        println("startDateStartTime.month <= monthSelectionLocalDate.value.month  ${startDateStartTime.month <= monthSelectionLocalDate.value.month}")
                        dueDate?.let {
                            println("dueDate.month >= monthSelectionLocalDate.value.month  ${it.month >= monthSelectionLocalDate.value.month}")
                        }

                        if (dueDate != null) {
                            (startDateStartTime.month <= monthSelectionLocalDate.value.month &&
                                    dueDate.month >= monthSelectionLocalDate.value.month)
                        } else {
                            startDateStartTime.month <= monthSelectionLocalDate.value.month
                        }
                    }
                }
            }?.map {
                it
            } ?: run {
                emptyList()
            }
        jobsForEvents.forEach { job ->
            var startDateStartTime: LocalDateTime? = null
            try {
                val dt = job.startDate + "T" + job.start_at
                startDateStartTime = LocalDateTime.parse(dt)
            } catch (e: Exception) {
                return@forEach
            }
            var startDateEndTime: LocalDateTime? = null
            try {
                val dt = job.startDate + "T" + job.end_at
                startDateEndTime = LocalDateTime.parse(dt)
            } catch (e: Exception) {
                return@forEach
            }
            var dueDate: LocalDateTime? = null
            try {
                val dt = job.dueDate + "T" + job.end_at
                dueDate = LocalDateTime.parse(dt)
            } catch (e: Exception) {
//                    return@filter false
            }
            job.assignees?.let { assignees ->
                // Process employees
                assignees.employees?.let { userList ->
                    userList.forEach { user ->
                        user?.getName()?.let { username ->
                            startDateStartTime?.let { startDate ->
                                addEventForName(
                                    job,
                                    startDate,
                                    startDateEndTime!!,
                                    dueDate,
                                    username
                                )
                            }
                        }
                    }
                }

                // Process teams
                assignees.teams?.let { teamList ->
                    teamList.forEach { team ->
                        val teamName = team?.name ?: "Unnamed Team"
                        startDateStartTime?.let { startDate ->
                            addEventForName(job, startDate, startDateEndTime!!, dueDate, teamName)
                        }
                    }
                }

                if (assignees.employees.isNullOrEmpty() && assignees.teams.isNullOrEmpty()) {
                    val unnamedPlan = Plan(
                        person = "Unassigned",
                        events = arrayListOf(
                            Event(
                                job.id,
                                job.status?.let { EventStatusMapper().eventStatus(it) }
                                    ?: EventStatus.PENDING,
                                startDateStartTime!!,
                                startDateEndTime,
                                job.jobTitle
                            )
                        )
                    )
                    listOfPlans.add(unnamedPlan)
                }
            }
        }
    }

    private fun addEventForName(
        job: Job,
        startDateStartTime: LocalDateTime,
        startDateEndTime: LocalDateTime,
        dueDateTime: LocalDateTime?,
        assigneeName: String
    ) {
        when (periodState.value) {
            PeriodType.DAY -> {
                when (job.repeat) {
                    Repeat.never.name,
                    Repeat.every_day.name -> {
                        addEventForAssignee(job, startDateStartTime, startDateEndTime, assigneeName)
                    }

                    Repeat.every_week.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        if (eventDateStartTime.date <= selectedDay) {
                            val daysBetween = eventDateStartTime.date.daysUntil(selectedDay)
                            val isWeekBetween = daysBetween % 7 == 0
                            if (isWeekBetween) {
                                val weekBetween = daysBetween / 7
                                eventDateStartTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateStartTime,
                                        weekBetween * 7
                                    )
                                eventDateEndTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateEndTime,
                                        weekBetween * 7
                                    )

                                var endDate = dueDateTime?.date
                                if (endDate == null || endDate >= selectedDay)
                                    addEventForAssignee(
                                        job,
                                        eventDateStartTime,
                                        eventDateEndTime,
                                        assigneeName
                                    )
                            }
                        }
                    }

                    Repeat.every_2_weeks.name -> {
                        var endDate = dueDateTime?.date
                        if (endDate == null || endDate >= selectedDay) {
                            var eventDateStartTime = startDateStartTime
                            var eventDateEndTime = startDateEndTime
                            if (eventDateStartTime.date <= selectedDay) {
                                val daysBetween = eventDateStartTime.date.daysUntil(selectedDay)
                                val is2WeekBetween = daysBetween % (7 * 2) == 0
                                if (is2WeekBetween) {
                                    val weekBetween = daysBetween / (7 * 2)
                                    eventDateStartTime =
                                        DateTimeUtil.getLocalDateTimeAhead(
                                            eventDateStartTime,
                                            weekBetween * 7 * 2
                                        )
                                    eventDateEndTime =
                                        DateTimeUtil.getLocalDateTimeAhead(
                                            eventDateEndTime,
                                            weekBetween * 7 * 2
                                        )

                                    if (endDate == null || eventDateStartTime.date <= endDate)
                                        addEventForAssignee(
                                            job,
                                            eventDateStartTime,
                                            eventDateEndTime,
                                            assigneeName
                                        )
                                }
                            }
                        }
                    }

                    Repeat.every_month.name -> {
                        var endDate = dueDateTime?.date
                        if (endDate == null || endDate >= selectedDay) {
                            var eventDateStartTime = startDateStartTime
                            var eventDateEndTime = startDateEndTime
                            if (eventDateStartTime.date <= selectedDay) {
                                val monthDiff =
                                    selectedDay.month.ordinal - startDateStartTime.month.ordinal
                                if (monthDiff >= 0) {
                                    eventDateStartTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                        eventDateStartTime, monthDiff
                                    )
                                    eventDateEndTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                        eventDateEndTime, monthDiff
                                    )

                                    if (eventDateStartTime.dayOfMonth == startDateStartTime.dayOfMonth
                                        && eventDateStartTime.date == selectedDay
                                    ) {
                                        if (endDate == null || eventDateStartTime.date <= endDate)
                                            addEventForAssignee(
                                                job,
                                                eventDateStartTime,
                                                eventDateEndTime,
                                                assigneeName
                                            )
                                    }
                                }
                            }
                        }
                    }
                }
            }

            PeriodType.WEEK -> {
                when (job.repeat) {
                    Repeat.never.name -> {
                        addEventForAssignee(job, startDateStartTime, startDateEndTime, assigneeName)
                    }

                    Repeat.every_day.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        val firstDayOfWeek = weekSelectionStart.value
                        if (eventDateStartTime.date < firstDayOfWeek) {
                            val dayDiff = firstDayOfWeek - eventDateStartTime.date
                            eventDateStartTime =
                                DateTimeUtil.getLocalDateTimeAhead(eventDateStartTime, dayDiff.days)
                            eventDateEndTime =
                                DateTimeUtil.getLocalDateTimeAhead(eventDateEndTime, dayDiff.days)
                        }

                        val lastDayOfWeek = weekSelectionEnd.value
                        var endDate = dueDateTime?.date
                        if (endDate == null || endDate > lastDayOfWeek)
                            endDate = lastDayOfWeek
                        endDate?.let {
                            var currentDate = eventDateStartTime.date
                            while (currentDate <= endDate) {
                                addEventForAssignee(
                                    job,
                                    eventDateStartTime,
                                    eventDateEndTime,
                                    assigneeName
                                )
                                eventDateStartTime =
                                    DateTimeUtil.getLocalDateTimeAhead(eventDateStartTime, 1)
                                eventDateEndTime =
                                    DateTimeUtil.getLocalDateTimeAhead(eventDateEndTime, 1)
                                currentDate = currentDate.plus(1, DateTimeUnit.DAY)
                            }
                        }
                    }

                    Repeat.every_week.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        val firstDayOfWeek = weekSelectionStart.value
                        val lastDayOfWeek = weekSelectionEnd.value
                        if (eventDateStartTime.date < firstDayOfWeek) {
                            val daysBetween = eventDateStartTime.date.daysUntil(lastDayOfWeek)
                            val weekBetween = daysBetween / 7

                            eventDateStartTime =
                                DateTimeUtil.getLocalDateTimeAhead(
                                    eventDateStartTime,
                                    weekBetween * 7
                                )
                            eventDateEndTime =
                                DateTimeUtil.getLocalDateTimeAhead(
                                    eventDateEndTime,
                                    weekBetween * 7
                                )
                        }
                        var endDate = dueDateTime?.date
                        if (endDate == null || endDate >= eventDateEndTime.date)
                            addEventForAssignee(
                                job,
                                eventDateStartTime,
                                eventDateEndTime,
                                assigneeName
                            )
                    }

                    Repeat.every_2_weeks.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        val firstDayOfWeek = weekSelectionStart.value
                        val lastDayOfWeek = weekSelectionEnd.value
                        if (eventDateStartTime.date < lastDayOfWeek) {
                            val daysBetween = eventDateStartTime.date.daysUntil(lastDayOfWeek)
                            val weekBetween = daysBetween / (7 * 2)
                            if (daysBetween < 7 || (daysBetween / 7) % 2 == 0) {
                                eventDateStartTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateStartTime,
                                        weekBetween * 7 * 2
                                    )
                                eventDateEndTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateEndTime,
                                        weekBetween * 7 * 2
                                    )
                                var endDate = dueDateTime?.date
                                if (endDate == null || endDate >= eventDateEndTime.date)
                                    addEventForAssignee(
                                        job,
                                        eventDateStartTime,
                                        eventDateEndTime,
                                        assigneeName
                                    )
                            }
                        }
                    }

                    Repeat.every_month.name -> {
                        var endDate = dueDateTime?.date
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        val lastDayOfWeek = weekSelectionEnd.value
                        if (endDate == null || eventDateStartTime.date < lastDayOfWeek) {
                            val firstDayOfWeek = weekSelectionStart.value
                            val lastDayOfWeek = weekSelectionEnd.value
                            val monthDiffStart =
                                firstDayOfWeek.month.ordinal - startDateStartTime.month.ordinal
                            val monthDiffEnd =
                                lastDayOfWeek.month.ordinal - startDateStartTime.month.ordinal
                            if (monthDiffStart >= 0 || monthDiffEnd >= 0) {
                                val monthDiff = maxOf(monthDiffStart, monthDiffEnd)
                                eventDateStartTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                    eventDateStartTime, monthDiff
                                )
                                eventDateEndTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                    eventDateEndTime, monthDiff
                                )

                                if (eventDateStartTime.dayOfMonth == startDateStartTime.dayOfMonth &&
                                    eventDateStartTime.date > firstDayOfWeek &&
                                    eventDateStartTime.date < lastDayOfWeek
                                ) {
                                    if (endDate == null || eventDateStartTime.date <= endDate)
                                        addEventForAssignee(
                                            job,
                                            eventDateStartTime,
                                            eventDateEndTime,
                                            assigneeName
                                        )
                                }
                            }
                        }
                    }
                }
            }

            PeriodType.MONTH -> {
                when (job.repeat) {
                    Repeat.never.name -> {
                        addEventForAssignee(job, startDateStartTime, startDateEndTime, assigneeName)
                    }

                    Repeat.every_day.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        var currentDate = startDateStartTime.date
                        val endDate = dueDateTime?.date
                            ?: DateTimeUtil.getLastDayOfMonth(monthSelectionLocalDate.value)
                        endDate?.let {
                            while (currentDate <= endDate) {
                                addEventForAssignee(
                                    job,
                                    eventDateStartTime,
                                    eventDateEndTime,
                                    assigneeName
                                )
                                eventDateStartTime =
                                    DateTimeUtil.getLocalDateTimeAhead(eventDateStartTime, 1)
                                eventDateEndTime =
                                    DateTimeUtil.getLocalDateTimeAhead(eventDateEndTime, 1)
                                currentDate = currentDate.plus(1, DateTimeUnit.DAY)
                            }
                        }
                    }

                    Repeat.every_week.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        val endDate = dueDateTime?.date
                            ?: DateTimeUtil.getLastDayOfMonth(monthSelectionLocalDate.value)
                        endDate?.let {
                            val monthDiff =
                                monthSelectionLocalDate.value.month.ordinal - eventDateStartTime.month.ordinal
                            eventDateStartTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                eventDateStartTime, monthDiff
                            )
                            eventDateEndTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                eventDateEndTime, monthDiff
                            )

                            var currentDate = eventDateStartTime.date
                            while (currentDate <= endDate) {
                                if (endDate >= eventDateEndTime.date)
                                    addEventForAssignee(
                                        job,
                                        eventDateStartTime,
                                        eventDateEndTime,
                                        assigneeName
                                    )

                                eventDateStartTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateStartTime,
                                        7
                                    )
                                eventDateEndTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateEndTime,
                                        7
                                    )
                                currentDate = currentDate.plus(7, DateTimeUnit.DAY)
                            }
                        }
                    }

                    Repeat.every_2_weeks.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        val endDate = dueDateTime?.date
                            ?: DateTimeUtil.getLastDayOfMonth(monthSelectionLocalDate.value)
                        endDate?.let {
                            val monthDiff =
                                monthSelectionLocalDate.value.month.ordinal - eventDateStartTime.month.ordinal
                            eventDateStartTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                eventDateStartTime, monthDiff
                            )
                            eventDateEndTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                eventDateEndTime, monthDiff
                            )

                            var currentDate = eventDateStartTime.date
                            while (currentDate <= endDate) {
                                if (endDate >= eventDateEndTime.date)
                                    addEventForAssignee(
                                        job,
                                        eventDateStartTime,
                                        eventDateEndTime,
                                        assigneeName
                                    )

                                eventDateStartTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateStartTime,
                                        7 * 2
                                    )
                                eventDateEndTime =
                                    DateTimeUtil.getLocalDateTimeAhead(
                                        eventDateEndTime,
                                        7 * 2
                                    )
                                currentDate = currentDate.plus(7 * 2, DateTimeUnit.DAY)
                            }
                        }
                    }

                    Repeat.every_month.name -> {
                        var eventDateStartTime = startDateStartTime
                        var eventDateEndTime = startDateEndTime
                        val endDate = dueDateTime?.date
                            ?: DateTimeUtil.getLastDayOfMonth(monthSelectionLocalDate.value)
                        endDate?.let {
                            val monthDiff =
                                monthSelectionLocalDate.value.month.ordinal - eventDateStartTime.month.ordinal
                            eventDateStartTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                eventDateStartTime, monthDiff
                            )
                            eventDateEndTime = DateTimeUtil.getLocalDateTimeMonthsAhead(
                                eventDateEndTime, monthDiff
                            )
                            if (endDate >= eventDateEndTime.date)
                                addEventForAssignee(
                                    job,
                                    eventDateStartTime,
                                    eventDateEndTime,
                                    assigneeName
                                )
                        }
                    }
                }
            }
        }

    }

    private fun addEventForAssignee(
        job: Job,
        eventDateStartTime: LocalDateTime,
        eventDateEndTime: LocalDateTime?,
        assigneeName: String
    ) {
        val plan = listOfPlans.firstOrNull { it.person == assigneeName }
        val newEvent = Event(
            job.id,
            job.status?.let { EventStatusMapper().eventStatus(it) } ?: EventStatus.PENDING,
            eventDateStartTime,
            eventDateEndTime,
            job.jobTitle
        )
        if (plan != null) {
            plan.events.add(newEvent)
        } else {
            listOfPlans.add(
                Plan(
                    person = assigneeName,
                    events = arrayListOf(newEvent)
                )
            )
        }
    }

    fun previous() {
        when (periodState.value) {
            PeriodType.DAY -> {
                selectedDay = selectedDay.minus(1, DateTimeUnit.DAY)
            }

            PeriodType.WEEK -> {
                weekSelectionStart.value = weekSelectionStart.value.minus(DatePeriod(days = 7))
                weekSelectionEnd.value = weekSelectionEnd.value.minus(DatePeriod(days = 7))
            }

            PeriodType.MONTH -> {
                monthSelectionLocalDate.value =
                    monthSelectionLocalDate.value.minus(DatePeriod(months = 1))
            }
        }
    }

    fun next() {
        when (periodState.value) {
            PeriodType.DAY -> {
                selectedDay = selectedDay.plus(1, DateTimeUnit.DAY)
            }

            PeriodType.WEEK -> {
                weekSelectionStart.value = weekSelectionStart.value.plus(DatePeriod(days = 7))
                weekSelectionEnd.value = weekSelectionEnd.value.plus(DatePeriod(days = 7))
            }

            PeriodType.MONTH -> {
                monthSelectionLocalDate.value =
                    monthSelectionLocalDate.value.plus(DatePeriod(months = 1))
            }
        }
    }

    fun periodChanged() {
        when (periodState.value) {
            PeriodType.DAY -> {
                if (selectedDay == today) {
                    currentPeriod.value = "Today"
                } else {
                    currentPeriod.value = getCurrentDayString()
                }
            }

            PeriodType.WEEK -> {
                currentPeriod.value = getCurrentWeekString()
            }

            PeriodType.MONTH -> {
                currentPeriod.value = getCurrentMonthString()
            }
        }
    }

    fun getCurrentDayString(): String {
        val date = selectedDay
        val month = date.month.name.take(3).lowercase().capitalize() // Abbreviation (e.g., "Sep")
        val day = date.dayOfMonth
        val year = date.year

        return "$day $month $year"
    }

    fun getCurrentWeekString(): String {
        return getFormattedWeekString(weekSelectionStart.value, weekSelectionEnd.value)
    }

    fun getFormattedWeekString(start: LocalDate, end: LocalDate): String {
        val startMonth =
            start.month.name.take(3).lowercase().capitalize() // Abbreviation (e.g., "Sep")
        val endMonth = end.month.name.take(3).lowercase().capitalize() // Abbreviation (e.g., "Sep")
        val startDay = start.dayOfMonth
        val endDay = end.dayOfMonth
        val startYear = start.year
        val endYear = end.year

        return if (startMonth == endMonth && startYear == endYear) {
            // Same month and year: "1–8 Sep, 2024"
            "$startDay–$endDay $startMonth, $startYear"
        } else if (startYear == endYear) {
            // Same year, different months: "10 Sep - 11 Oct 2024"
            "$startDay $startMonth - $endDay $endMonth $startYear"
        } else {
            // Different years: "30 Dec 2024 - 1 Jan 2025"
            "$startDay $startMonth $startYear - $endDay $endMonth $endYear"
        }
    }

    fun getCurrentMonthString(): String {
        val month = monthSelectionLocalDate.value.month.name.take(3).lowercase()
            .replaceFirstChar { it.uppercase() } // e.g., "January"
        val year = monthSelectionLocalDate.value.year
        return "$month, $year"
    }

    fun filterCurrentJobs(eventList: List<Event>) {
        val jobIdsInEvents = eventList.map { it.jobId }.toSet()
        currentJobs.clear()
        currentJobs.addAll(_jobsState.value?.filter { it.id in jobIdsInEvents } ?: emptyList())
    }

    private suspend fun getSiteById(id: String): Site? {
        return repository.getSiteById(id)
    }

    fun updateSite(job: Job) {
        viewModelScope.launch {
            val contactMap = mutableMapOf<String, Contact>()
            val siteMap = mutableMapOf<String, Site>()
            job.contactParentId?.let {
                val contact = repository.getContactById(it)
                contact?.id?.let { id ->
                    contactMap[id] = contact
                }
            }
            job.contactSiteId?.let {
                val site = getSiteById(it)
                site?.id?.let { id -> siteMap[job.id!!] = site }
            }
            sites.clear()
            sites.putAll(siteMap)
        }
    }

    fun filterJobs() {
        val filteredJobs = mutableListOf<Job>()
        filteredJobs.clear()
        if (selectedStatus.value.isNullOrEmpty() &&
            selectedEmployees.value.isNullOrEmpty() &&
            selectedSchedule.value.isNullOrEmpty() &&
            selectedLocation.value.isNullOrEmpty()
        ) {
            filteredJobs.addAll(jobList)
        } else {

            val selectedSitesIds = selectedLocation.value.flatMap { selectedAddress ->
                sitesMap.filterValues { site ->
                    site.addressObject?.address.equals(selectedAddress, ignoreCase = true)
                }.values.map { it.id }
            }
            filteredJobs.addAll(jobList.filter { job ->
                selectedStatus.value.any { query ->
                    job.status?.contains(query, ignoreCase = true) == true
                } || selectedEmployees.value.any { query ->
                    job.assignees?.employees?.any { employee ->
                        employee?.getName()?.contains(query, ignoreCase = true) == true
                    } == true
                } || selectedSchedule.value.any { query ->
                    job.startDate?.contains(query, ignoreCase = true) == true
                } || selectedSitesIds.contains(job.contactSiteId)
            })
        }
        _jobsState.value = filteredJobs
        parseSchedule(jobsState.value, userState.value)
    }

    private fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

}