package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.FilterOption

@Composable
fun FilterEmployeesDialog(
    cities: List<String>,
    roles: List<String>,
    teams: List<String>,
    statuses: List<String>,
    skills: List<String>,
    onDismiss: () -> Unit,
    onApply: (Set<String>, Set<String>, Set<String>, Set<String>, Set<String>) -> Unit
) {
    var selectedCities by remember { mutableStateOf(setOf<String>()) }
    var selectedRoles by remember { mutableStateOf(setOf<String>()) }
    var selectedTeams by remember { mutableStateOf(setOf<String>()) }
    var selectedStatuses by remember { mutableStateOf(setOf<String>()) }
    var selectedSkills by remember { mutableStateOf(setOf<String>()) }

    Dialog(onDismissRequest = onDismiss) {
        Surface(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .verticalScroll(rememberScrollState()),
            shape = MaterialTheme.shapes.medium,
            color = Color.White
        ) {
            Column(
                modifier = Modifier
                    .padding(16.dp)
                    .fillMaxWidth()
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(16.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = "Filter",
                            fontSize = 18.sp,
                            fontWeight = FontWeight.Bold
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        Text(
                            text = "Clear all",
                            fontSize = 18.sp,
                            color = BlueApp,
                            modifier = Modifier.clickable {
                                selectedCities = emptySet()
                                selectedRoles = emptySet()
                                selectedTeams = emptySet()
                                selectedStatuses = emptySet()
                                selectedSkills = emptySet()
                            }
                        )
                    }
                    IconButton(onClick = onDismiss) {
                        Icon(
                            imageVector = Icons.Default.Close,
                            tint = FilterOption,
                            contentDescription = "Close"
                        )
                    }
                }

                Spacer(modifier = Modifier.height(16.dp))

                FilterSection(
                    title = "City",
                    options = cities,
                    selectedOptions = selectedCities,
                    onOptionSelected = { city ->
                        selectedCities = toggleSelection(selectedCities, city)
                    }
                )
                FilterSection(
                    title = "Role",
                    options = roles,
                    selectedOptions = selectedRoles,
                    onOptionSelected = { role ->
                        selectedRoles = toggleSelection(selectedRoles, role)
                    }
                )
                FilterSection(
                    title = "Team",
                    options = teams,
                    selectedOptions = selectedTeams,
                    onOptionSelected = { team ->
                        selectedTeams = toggleSelection(selectedTeams, team)
                    }
                )
                FilterSection(
                    title = "Status",
                    options = statuses,
                    selectedOptions = selectedStatuses,
                    onOptionSelected = { status ->
                        selectedStatuses = toggleSelection(selectedStatuses, status)
                    }
                )
                FilterSection(
                    title = "Skill",
                    options = skills,
                    selectedOptions = selectedSkills,
                    onOptionSelected = { skill ->
                        selectedSkills = toggleSelection(selectedSkills, skill)
                    }
                )

                Spacer(modifier = Modifier.height(16.dp))

                // Action Buttons
                Row(
                    horizontalArrangement = Arrangement.spacedBy(8.dp),
                    modifier = Modifier.fillMaxWidth()
                ) {
                    OutlinedButton(
                        onClick = {
                            onDismiss()
                        },
                        modifier = Modifier.weight(1f)
                    ) {
                        Text("Cancel", color = Color.Black)
                    }
                    Button(
                        onClick = {
                            onApply(selectedCities, selectedRoles, selectedTeams, selectedStatuses, selectedSkills)
                            onDismiss()
                        },
                        modifier = Modifier.weight(1f),
                        colors = ButtonDefaults.buttonColors(
                            containerColor = BlueApp
                        )
                    ) {
                        Text("Confirm", color = Color.White)
                    }
                }
            }
        }
    }
}

@Composable
fun FilterSection(
    title: String,
    options: List<String>,
    selectedOptions: Set<String>,
    onOptionSelected: (String) -> Unit
) {
    Column(modifier = Modifier.fillMaxWidth().padding(start = 20.dp)) {
        Text(text = title, fontSize = 20.sp, fontWeight = FontWeight.Bold)
        options.forEach { option ->
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Checkbox(
                    checked = selectedOptions.contains(option),
                    onCheckedChange = { onOptionSelected(option) },
                    colors = CheckboxDefaults.colors(
                        checkedColor = BlueApp
                    )
                )
                Text(text = option, fontSize = 16.sp, color = FilterOption)
            }
        }
    }
}

fun toggleSelection(selected: Set<String>, option: String): Set<String> {
    return if (selected.contains(option)) {
        selected - option // Deselect if it's already selected
    } else {
        selected + option // Select if it's not already selected
    }
}
