package com.hyperether.goodjob.scenes.workspace

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.isWebMobileView
import com.hyperether.goodjob.log
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.access_dashboard
import com.hyperether.goodjob.resources.choose_your_workspace
import com.hyperether.goodjob.resources.create_workspace
import com.hyperether.goodjob.resources.last_accessed
import com.hyperether.goodjob.resources.logo_good_job
import com.hyperether.goodjob.resources.never
import com.hyperether.goodjob.resources.new_workspace
import com.hyperether.goodjob.resources.plan
import com.hyperether.goodjob.resources.plus
import com.hyperether.goodjob.resources.role
import com.hyperether.goodjob.resources.select_your_workspace
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.LightGrey
import com.hyperether.goodjob.util.DateTimeUtil
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun WorkspaceScreen(
    isMobileView: Boolean,
    workspaceViewModel: WorkspaceViewModel,
    continueToDashboard: () -> Unit,
    addNewWorkspace: () -> Unit
) {

    val selectedWorkspace = remember { mutableStateOf<String?>(null) }

    LaunchedEffect(Unit) {
        if (!getPlatformChecker().isMobile()) {
            workspaceViewModel.getWorkspaces()
        }
    }

    LaunchedEffect(workspaceViewModel.getUser()) {
        if (workspaceViewModel.getUser() != null) {
            workspaceViewModel.fetchCurrentPlan()
        }
    }

    LaunchedEffect(workspaceViewModel.workspaces) {
        workspaceViewModel.getWorkspaces()
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .verticalScroll(rememberScrollState())
            .padding(16.dp),
        horizontalAlignment = if (isMobileView) Alignment.Start else Alignment.CenterHorizontally
    ) {
        if (!isMobileView) {
            LogoHeader()
            Spacer(modifier = Modifier.height(Dp(40f)))
        }
        TitleSection(isMobileView)

        Spacer(modifier = Modifier.height(30.dp))

        workspaceViewModel.getUser()?.let { user ->
            WorkspaceList(
                user,
                workspaceViewModel.currentPlan.value,
                isMobileView,
                workspaces = workspaceViewModel.workspaces,
                selectedWorkspace.value,
                onWorkspaceSelected = { selectedWorkspace.value = it },
                onWorkspaceSelectedId = {
                    workspaceViewModel.workspaceId.value = it
                }
            )
        }
        if (!isMobileView)
            Spacer(modifier = Modifier.height(Dp(32f)))
        else
            Spacer(modifier = Modifier.height(30.dp))

        FilledButton(
            onClick = {
                workspaceViewModel.workspaceId.value.let { workspaceViewModel.saveWorkspaceId(it) }
                continueToDashboard()
                workspaceViewModel.getAllJobs()
                workspaceViewModel.updateLastAccessed()
            },
            isEnabled = selectedWorkspace.value != null,
            text = stringResource(Res.string.access_dashboard),
            modifier = Modifier.width(if (!isMobileView) Dp(530f) else 530.dp)
        )

        if (!isMobileView) {
            Spacer(modifier = Modifier.height(Dp(30f)))
        } else {
            Spacer(modifier = Modifier.height(30.dp))
        }

        if (workspaceViewModel.workspaces.isEmpty()) {
            DividerLine(isMobileView)
            CreateWorkspaceSection(isMobileView, addNewWorkspace)
        }
    }
}

@Composable
fun LogoHeader() {
    Box(
        modifier =
        if (!isWebMobileView()) Modifier
            .fillMaxWidth()
            .padding(start = Dp(56f), top = Dp(32f))
        else
            Modifier
                .fillMaxWidth()
                .padding(bottom = 70.dp),
        contentAlignment = Alignment.TopStart
    ) {
        Image(
            painter = painterResource(Res.drawable.logo_good_job),
            contentDescription = null,
            modifier = if (!isWebMobileView()) Modifier.height(Dp(38f))
                .width(Dp(145f)) else Modifier
        )
    }
}

@Composable
fun TitleSection(isMobileView: Boolean) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = if (isMobileView) Alignment.Start else Alignment.CenterHorizontally
    ) {
        if (isMobileView)
            Spacer(modifier = Modifier.height(50.dp))
        Text(
            fontWeight = FontWeight.Bold,
            text = stringResource(Res.string.select_your_workspace),
            color = Color.Black,
            textAlign = if (!isMobileView) TextAlign.Center else TextAlign.Start,
            fontSize = if (!isMobileView) Sp(40f) else 24.sp,
            lineHeight = if (!isMobileView) Sp(50f) else 24.sp
        )
        Spacer(modifier = Modifier.height(15.dp))
        Text(
            text = stringResource(Res.string.choose_your_workspace),
            color = DarkGrey,
            textAlign = TextAlign.Start,
            fontSize = if (!isMobileView) Sp(18f) else 16.sp,
            lineHeight = if (!isMobileView) Sp(24f) else 16.sp
        )
    }
}

@Composable
fun WorkspaceList(
    user: User,
    plan: Plan? = null,
    isMobileView: Boolean,
    workspaces: List<Workspace>,
    selectedWorkspace: String?,
    onWorkspaceSelected: (String) -> Unit,
    onWorkspaceSelectedId: (String) -> Unit,
) {
    workspaces.forEach { workspace ->
        WorkspaceItem(
            isMobileView = isMobileView,
            title = workspace.name ?: "",
            role = user.role?.lowercase()?.capitalize()
                ?: "",
            lastAccessed = workspace.lastAccessed?.let {
                try {
                    it.toLong()
                        .let { it1 -> DateTimeUtil.formatLastAccessed(it1) }
                } catch (e: Exception) {
                    log.d("lastAccessed parser error") { "ERROR: ${e.message}" }
                    stringResource(Res.string.never)
                }

            }
                ?: stringResource(Res.string.never),
            plan = plan?.name ?: "",
            isSelected = selectedWorkspace == workspace.name,
            onSelect = {
                onWorkspaceSelected(workspace.name!!)
                workspace._id?.let { onWorkspaceSelectedId(it) }
            }
        )
        Spacer(modifier = Modifier.height(if (isMobileView) 16.dp else Dp(16f)))
    }
}

@Composable
fun DividerLine(isMobileView: Boolean) {
    Spacer(
        modifier = Modifier
            .height(if (isMobileView) 2.dp else Dp(1f))
            .background(LightGrey)
            .width(if (isMobileView) 530.dp else Dp(530f))
    )
}

@Composable
fun CreateWorkspaceSection(
    isMobileView: Boolean,
    addNewWorkspace: () -> Unit
) {
    Spacer(modifier = Modifier.height(if (isMobileView) 20.dp else Dp(24f)))
    Text(
        text = stringResource(Res.string.create_workspace),
        color = Color.Black,
        fontWeight = FontWeight.Bold,
        textAlign = if (!isMobileView) TextAlign.Center else TextAlign.Start,
        fontSize = if (isMobileView) 18.sp else Sp(18f)
    )
    Spacer(modifier = Modifier.height(if (isMobileView) 10.dp else Dp(12f)))
    Row(
        modifier = Modifier.clickable { addNewWorkspace() },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            painter = painterResource(Res.drawable.plus),
            contentDescription = null
        )
        Text(
            text = stringResource(Res.string.new_workspace),
            color = BlueApp,
            textAlign = TextAlign.Center,
            fontSize = if (isMobileView) 16.sp else Sp(16f),
            modifier = Modifier.padding(start = if (isMobileView) 15.dp else Dp(8f))
        )
    }
}


@Composable
fun WorkspaceItem(
    isMobileView: Boolean,
    title: String,
    role: String,
    lastAccessed: String,
    plan: String,
    isSelected: Boolean,
    onSelect: () -> Unit
) {
    val commonModifier = if (isMobileView) Modifier
        .fillMaxWidth() else Modifier.width(Dp(530f))
    Box(
        modifier = commonModifier
            .border(
                width = if (isMobileView) {
                    if (isSelected) 2.dp else 1.dp
                } else {
                    if (isSelected) Dp(2f) else Dp(1f)
                },
                color = if (isSelected) BlueApp else Color.LightGray,
                shape = RoundedCornerShape(if (isMobileView) 8.dp else Dp(8f))
            )
            .background(Color.White, shape = RoundedCornerShape(if (isMobileView) 8.dp else Dp(8f)))
            .clickable { onSelect() }
            .padding(if (isMobileView) 16.dp else Dp(16f))
    ) {
        Column {
            Text(
                text = title,
                fontSize = if (isMobileView) 18.sp else Sp(18f),
                color = Color.Black,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Left
            )
            Row(modifier = Modifier.padding(top = if (isMobileView) 5.dp else Dp(8f))) {
                Text(
                    text = stringResource(Res.string.role) + ": ",
                    color = DarkGrey,
                    textAlign = TextAlign.Center,
                    fontSize = if (isMobileView) 14.sp else Sp(14f),
                    lineHeight = if (isMobileView) 14.sp else Sp(24f)
                )
                Text(
                    text = role,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    fontSize = if (isMobileView) 14.sp else Sp(14f),
                    lineHeight = if (isMobileView) 14.sp else Sp(24f)
                )
            }
            Row(modifier = Modifier.padding(top = if (isMobileView) 5.dp else Dp(2f))) {
                Text(
                    text = stringResource(Res.string.last_accessed),
                    color = DarkGrey,
                    textAlign = TextAlign.Center,
                    fontSize = if (isMobileView) 14.sp else Sp(14f),
                    lineHeight = if (isMobileView) 14.sp else Sp(24f)
                )
                Text(
                    text = lastAccessed,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    fontSize = if (isMobileView) 14.sp else Sp(14f),
                    lineHeight = if (isMobileView) 14.sp else Sp(24f)
                )
            }
            Row(modifier = Modifier.padding(top = if (isMobileView) 5.dp else Dp(2f))) {
                Text(
                    text = stringResource(Res.string.plan),
                    color = DarkGrey,
                    textAlign = TextAlign.Center,
                    fontSize = if (isMobileView) 14.sp else Sp(14f),
                    lineHeight = if (isMobileView) 14.sp else Sp(24f)
                )
                Text(
                    text = plan,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    fontSize = if (isMobileView) 14.sp else Sp(14f),
                    lineHeight = if (isMobileView) 14.sp else Sp(24f)
                )
            }
        }
    }
}
