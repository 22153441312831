package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TeamRequest(
    @SerialName("name") var name: String? = null,
    @SerialName("workspaceId") var workspaceId: String? = null,
    @SerialName("employees") var employees: List<String>? = null,
)