package com.hyperether.goodjob.scenes.workspace

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.Repository
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class WorkspaceViewModel(
    val repository: Repository
) : ViewModel() {

    private val _workspaces = MutableStateFlow<List<Workspace>>(emptyList())
    val workspaces: StateFlow<List<Workspace>> = _workspaces

    init {
        viewModelScope.launch {
            repository.getWorkspaces().collect { workspaceList ->
                _workspaces.value = workspaceList
            }
        }
    }


    fun insertWorkspace(workspace: Workspace) {
        viewModelScope.launch {
            // TODO: uncomment whn
//            val result = workspace.name?.let {
//                workspace.type?.let { it1 ->
//                    repository.addWorkspace(
//                        it,
//                        it1
//                    )
//                }
//            }
//            if (result?.data != null) {
                repository.insertWorkspace(workspace)
//            }
        }
    }
}