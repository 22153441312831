package com.hyperether.goodjob.scenes.pricing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.paint
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.toSize
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.is4K
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.check_circle
import com.hyperether.goodjob.resources.choose_plan
import com.hyperether.goodjob.resources.dedicated_support
import com.hyperether.goodjob.resources.fast_track_support
import com.hyperether.goodjob.resources.fly_high_plan_description
import com.hyperether.goodjob.resources.freebie_plan_description
import com.hyperether.goodjob.resources.galactic_plan_description
import com.hyperether.goodjob.resources.high_five_plan_description
import com.hyperether.goodjob.resources.manager
import com.hyperether.goodjob.resources.managers
import com.hyperether.goodjob.resources.month
import com.hyperether.goodjob.resources.most_popular
import com.hyperether.goodjob.resources.premium_dark
import com.hyperether.goodjob.resources.priority_support
import com.hyperether.goodjob.resources.support
import com.hyperether.goodjob.resources.unlimited_storage
import com.hyperether.goodjob.resources.users
import com.hyperether.goodjob.resources.you_chose
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.CardBg
import com.hyperether.goodjob.theme.LandingPageBg
import com.hyperether.goodjob.theme.PopularPricingBoxBg
import com.hyperether.goodjob.theme.PopularPricingText
import com.hyperether.goodjob.theme.PricingBoxBg
import com.hyperether.goodjob.theme.PricingBtnColor
import com.hyperether.goodjob.theme.PricingText
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun PricingItem(
    isChecked: Boolean,
    onClick: () -> Unit,
    planName: String,
    planPrice: String,
    isExistingPlan: Boolean,
    currentPlanExists: Boolean,
    isMostPopular: Boolean,
    modifier: Modifier = Modifier
) {
    Box {
        Box(
            modifier = modifier
                .fillMaxWidth()
                .padding(top = 4.dp, bottom = 4.dp)
                .border(1.dp, Color.LightGray, shape = RoundedCornerShape(12.dp))
                .background(Color.White, shape = RoundedCornerShape(12.dp))
                .padding(8.dp),
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
//                .background(Color.White, shape = RoundedCornerShape(12.dp))
//                .border(1.dp, Color.LightGray, shape = RoundedCornerShape(12.dp))
                    .padding(12.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                CustomCheckbox(
                    isChecked = isChecked,
                    onClick = onClick,
                    modifier = Modifier.padding(start = 12.dp)
                )

                Spacer(modifier = Modifier.width(8.dp))

                Text(
                    text = planName,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier
                        .weight(1f)
                        .padding(start = 10.dp)
                )

                Row {
                    Text(
                        text = planPrice,
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        color = Color.DarkGray
                    )
                    if (planName != Plan.Freebie.name) {
                        Text(
                            text = stringResource(Res.string.month),
                            fontSize = 14.sp,
                            color = Color.DarkGray
                        )
                        Spacer(modifier = Modifier.width(9.dp))
                    } else {
                        Spacer(modifier = Modifier.width(9.dp))
                    }
                }
            }

            if (isChecked) {
                if (planName == Plan.Freebie.name) {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.freebie_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 1 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                } else if (planName.contains("Five")) {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.high_five_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 5 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.priority_support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                } else if (planName == Plan.Galactic.name) {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.fly_high_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 10 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.fast_track_support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                } else {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.galactic_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 20 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.dedicated_support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                }

            }
        }
        if (isExistingPlan) {
            Box(
                modifier = Modifier
                    .align(Alignment.TopEnd)
                    .offset(x = (-16).dp)
                    .background(BlueApp, shape = RoundedCornerShape(30.dp))
                    .padding(horizontal = 12.dp, vertical = 4.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = stringResource(Res.string.you_chose).uppercase(),
                    fontSize = 12.sp,
                    color = Color.White,
                    fontWeight = FontWeight.Bold
                )
            }
        } else if (isMostPopular && !currentPlanExists) {
            Box(
                modifier = Modifier
                    .align(Alignment.TopEnd)
                    .offset(x = (-16).dp)
                    .background(BlueApp, shape = RoundedCornerShape(30.dp))
                    .padding(horizontal = 12.dp, vertical = 4.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = stringResource(Res.string.most_popular),
                    fontSize = 12.sp,
                    color = Color.White,
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }
}

@Composable
fun PlanDetailsView(
    textDescription: String,
    firstTextBenefits: String,
    secondTextBenefits: String,
    thirdTextBenefits: String
) {

    Column(
        modifier = Modifier.padding(top = 40.dp, start = 20.dp)
    ) {
        Text(
            text = textDescription,
            color = Color.Gray,
            lineHeight = 20.sp,
            textAlign = TextAlign.Left,
            fontSize = 14.sp,
            // modifier = Modifier.padding(top = 8.dp)
        )
        Row(modifier = Modifier.padding(top = 10.dp)) {
            Image(
                painter = painterResource(Res.drawable.check_circle),
                contentDescription = "check",
                modifier = Modifier
                    .padding(top = 5.dp)
            )
            Text(
                text = firstTextBenefits,
                color = Color.Black,
                textAlign = TextAlign.Center,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 12.dp, top = 3.dp)
            )

        }
        Row {
            Image(
                painter = painterResource(Res.drawable.check_circle),
                contentDescription = "check",
                modifier = Modifier
                    .padding(top = 5.dp)
            )
            Text(
                text = secondTextBenefits,
                color = Color.Black,
                textAlign = TextAlign.Center,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 12.dp, top = 3.dp)
            )

        }
        Row {
            Image(
                painter = painterResource(Res.drawable.check_circle),
                contentDescription = "check",
                modifier = Modifier
                    .padding(top = 5.dp)
            )
            Text(
                text = thirdTextBenefits,
                color = Color.Black,
                textAlign = TextAlign.Center,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 12.dp, top = 3.dp)
            )
        }
    }
}

@Composable
fun CustomCheckbox(
    isChecked: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = modifier
            .size(24.dp)
            .clip(CircleShape)
            .background(if (isChecked) Color(0xFF34618E) else Color.White)
            .border(1.dp, Color.LightGray, CircleShape)
            .clickable(onClick = onClick),
        contentAlignment = Alignment.Center
    ) {
        if (isChecked) {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "Checked",
                tint = Color.White,
                modifier = Modifier.size(16.dp)
            )
        }
    }
}

@Composable
fun PricingOption(
    isLanding: Boolean,
    isMobileView: Boolean,
    price: String,
    planName: String,
    description: String,
    features: List<String>,
    isPopular: Boolean = false,
    continueToRegister: () -> Unit,
    width: Float
) {
    Box(
        modifier = Modifier
            .width(
                if (!isMobileView) {
                    Dp(312f)
                } else {
                    (width).dp
                }
            )
            .height(if (!isMobileView) Dp(480f) else Dp(400f))
            .let { baseModifier ->
                if (isPopular) {
                    baseModifier
                        .shadow(Dp(10f), shape = RoundedCornerShape(Dp(20f)))
                        .clip(RoundedCornerShape(Dp(20f)))
                        .background(
                            shape = RoundedCornerShape(Dp(20f)),
                            color = PopularPricingBoxBg
                        )
                        .paint(
                            painter = painterResource(Res.drawable.premium_dark),
                            alignment = Alignment.CenterEnd
                        )
                        .padding(
                            if (!isMobileView) {
                                PaddingValues(
                                    top = Dp(20f),
                                    start = Dp(40f),
                                    end = Dp(40f),
                                    bottom = Dp(40f)
                                )
                            } else {
                                PaddingValues(
                                    top = Dp(13f),
                                    start = Dp(24f),
                                    end = Dp(24f),
                                    bottom = Dp(24f)
                                )
                            }
                        )
                } else {
                    baseModifier
                        .background(
                            color = if (isMobileView) CardBg else Color.Transparent,
                            shape = RoundedCornerShape(Dp(26f)),
                        )
                        .padding(if (!isMobileView) Dp(40f) else Dp(24f))
                }
            }
    ) {
        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.SpaceBetween,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Column(
                modifier = if (isPopular) Modifier
                    .fillMaxWidth()
                else Modifier
                    .fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                if (isPopular) {
                    Box(
                        modifier = Modifier
                            .height(Dp(28f))
                            .width(Dp(122f))
                            .background(
                                PricingBoxBg,
                                shape = RoundedCornerShape(Dp(13f))
                            )
                            .align(Alignment.End),
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            text = stringResource(Res.string.most_popular),
                            fontSize = Sp(10f),
                            lineHeight = Sp(12f),
                            color = Color.White,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                        )
                    }
                }
                Text(
                    text = buildAnnotatedString {
                        val parts = price.split("/")
                        val mainPrice = when (val priceString = parts.getOrNull(0)) {
                            "Free" -> "0$"
                            else -> priceString ?: ""
                        }
                        val period = parts.getOrNull(1) ?: "month"

                        withStyle(
                            style = SpanStyle(
                                fontSize = Sp(36f),
                                fontWeight = FontWeight.W700,
                                color = if (isPopular) PopularPricingText else PricingText
                            )
                        ) {
                            append(mainPrice)
                        }

                        withStyle(
                            style = SpanStyle(
                                fontSize = Sp(17f),
                                fontWeight = FontWeight.W400,
                                color = if (isPopular) PopularPricingText else PricingText
                            )
                        ) {
                            append(" /$period")
                        }
                    },
                    modifier = Modifier
                        .align(Alignment.Start)
                )
                Spacer(modifier = Modifier.height(Dp(20f)))
                Text(
                    text = planName,
                    fontSize = Sp(28f),
                    fontWeight = FontWeight.W700,
                    color = if (isPopular) PopularPricingText else PricingText,
                    modifier = Modifier
                        .align(Alignment.Start)
                )
                Spacer(modifier = Modifier.height(Dp(15f)))
                Text(
                    text = description,
                    lineHeight = Sp(18f),
                    fontWeight = FontWeight.W400,
                    fontSize = Sp(15f),
                    color = if (isPopular) PopularPricingText else PricingText,
                    modifier = Modifier
                        .align(Alignment.Start)
                )
                Spacer(modifier = Modifier.height(Dp(19f)))
                features.forEach { feature ->
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .align(Alignment.Start),
                        verticalAlignment = Alignment.CenterVertically // Aligns icon and text vertically centered
                    ) {
                        Icon(
                            painter = painterResource(Res.drawable.check_circle), // Replace with your drawable resource ID
                            contentDescription = "Feature icon",
                            tint = if (isPopular) LandingPageBg.copy(alpha = 0.5f) else Color.White.copy(
                                alpha = 0.5f
                            ),
                            modifier = Modifier.size(Dp(20f))
                        )

                        Spacer(modifier = Modifier.width(10.dp))

                        Text(
                            text = feature,
                            fontSize = Sp(15f),
                            lineHeight = Sp(18f),
                            color = if (isPopular) PopularPricingText else PricingText,
                            fontWeight = FontWeight.W400
                        )
                    }
                    Spacer(modifier = Modifier.height(Dp(20f)))
                }
            }

            if (!isLanding) {
                Button(
                    onClick = { continueToRegister() },
                    modifier = Modifier
                        .height(Dp(46f))
                        .then(
                            if (isMobileView) Modifier.fillMaxWidth()
                            else Modifier.width(Dp(207f))
                        )
                        .clip(RoundedCornerShape(Dp(50f))),
                    colors = ButtonDefaults.buttonColors(
                        backgroundColor = if (isPopular) BlueApp else PricingBtnColor,
                        contentColor = BlueApp
                    )
                ) {
                    Text(
                        fontWeight = FontWeight.W700,
                        text = stringResource(Res.string.choose_plan),
                        color = PricingText,
                        fontSize = Sp(15f)
                    )
                }
            }
        }
    }
}

@Composable
fun PricingBoxWebView(
    isLanding: Boolean,
    priceBoxSize: MutableState<Size>,
    plans: List<com.hyperether.goodjob.models.Plan>,
    savePlanLocally: (String) -> Unit,
    continueToRegister: (String, String, Double) -> Unit
) {

    Box(
        modifier = Modifier
            .background(
                PricingBoxBg,
                shape = RoundedCornerShape(Dp(26f))
            )
            .onSizeChanged { size ->
                priceBoxSize.value = size.toSize()
            }
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth(),
            contentAlignment = Alignment.Center // Ensures the popular card is centered in the container
        ) {
            // Card Row
            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween // Equal spacing
            ) {
                plans.forEach { plan ->
                    if (plan.isPopular == true) {
                        Spacer(
                            modifier = Modifier.width(Dp(292f))
                        )
                    } else {
                        PricingOption(
                            isLanding = isLanding,
                            price = "$${plan.price}",
                            planName = plan.name.orEmpty(),
                            description = plan.description.orEmpty(),
                            features = listOf(
                                plan.storage.orEmpty(),
                                plan.planSupport.orEmpty(),
                                plan.limitManagers.toString() + " " + stringResource(Res.string.managers) + "+" +
                                        plan.limitUsers + " " + stringResource(Res.string.users)
                            ),
                            continueToRegister = {
                                plan._id?.let {
                                    savePlanLocally(it)
                                    plan.currency?.let { currency ->
                                        plan.price?.let { price ->
                                            continueToRegister(
                                                it,
                                                currency, price
                                            )
                                        }
                                    }
                                }
                            },
                            isPopular = plan.isPopular ?: false,
                            width = priceBoxSize.value.width,
                            isMobileView = false
                        )
                    }
                }
            }

            // Overlay "Popular" Card
            plans.firstOrNull { it.isPopular == true }?.let { popularPlan ->
                Box(
                    modifier = Modifier
                        .align(Alignment.CenterEnd)
                        .padding(start = 32.dp)
                        .width(Dp(312f))
                        .offset(
                            y = (-Dp(50f)),
                            x = (-Dp(392f))
                        )
                ) {
                    PricingOption(
                        isLanding = isLanding,
                        price = "$${popularPlan.price}",
                        planName = popularPlan.name.orEmpty(),
                        description = popularPlan.description.orEmpty(),
                        features = listOf(
                            popularPlan.storage.orEmpty(),
                            popularPlan.planSupport.orEmpty(),
                            popularPlan.limitManagers.toString() + " " + stringResource(Res.string.managers) + "+" +
                                    popularPlan.limitUsers + " " + stringResource(Res.string.users)
                        ),
                        continueToRegister = {
                            popularPlan._id?.let {
                                savePlanLocally(it)
                                popularPlan.currency?.let { currency ->
                                    popularPlan.price?.let { price ->
                                        continueToRegister(
                                            it,
                                            currency, price
                                        )
                                    }
                                }
                            }
                        },
                        isPopular = true,
                        width = priceBoxSize.value.width,
                        isMobileView = false
                    )
                }
            }
        }
    }
}