package com.hyperether.planner.ui

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import com.hyperether.planner.util.CircleWithInitial
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

@Composable
fun SchedulerDayPlanner(
    listOfPlans: MutableList<Plan>,
    completedColor: Color,
    pendingColor: Color,
    delayedColor: Color,
    celWidth: Int = 100,
    startHour: Int = 8,
    borderPathColor: Color = Color(231, 231, 231),
    borderColor: Color = Color(239, 239, 239),
    namesColor: Color = Color(146, 144, 144),
    hoursColor: Color = Color(83, 82, 82),
    namesIconsShown: Boolean = false,
) {
    var celWidthDp by remember { mutableStateOf(celWidth) }
    val tableWidth by remember { mutableStateOf(((24 - startHour) * celWidthDp).dp) }

    if (listOfPlans.size < 10) {
        while (listOfPlans.size < 11)
            listOfPlans.addAll(listOf(Plan("", mutableListOf())))
    }

    val horizontalScrollState = rememberScrollState()
    val verticalScrollState = rememberScrollState()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .pointerInput(Unit) {

                detectDragGestures { change, dragAmount ->
                    change.consume()
                    CoroutineScope(Dispatchers.Default).launch {
                        println("Y drag = ${dragAmount.y.toInt()}")
                        println("Y state = ${verticalScrollState.value}")
                        println("X drag = ${dragAmount.x.toInt()}")
                        println("X state = ${horizontalScrollState.value}")

                        verticalScrollState.scrollTo(verticalScrollState.value - dragAmount.y.toInt())

                        horizontalScrollState.scrollTo(horizontalScrollState.value - dragAmount.x.toInt())
                    }
                }
            }
            .fillMaxHeight()
            .background(Color.White)
            .drawBehind {
                drawLine(
                    color = borderColor,
                    strokeWidth = 2.dp.toPx(),
                    start = Offset(0f, 0f),
                    end = Offset(size.width, 0f)
                )
            },
        contentAlignment = Alignment.TopStart
    ) {

        Row {
            //Names
            Column(
                modifier = Modifier.verticalScroll(verticalScrollState)
                    .background(Color.Transparent)
                    .border(1.dp, borderColor)
            ) {
                Box(
                    modifier = Modifier.size(height = 50.dp, width = 10.dp)
                        .background(Color.Transparent)
                )
                repeat(listOfPlans.size) { index ->
                    if (index == 0) {
                        Spacer(modifier = Modifier.height(12.dp))
                    } else {
                        Spacer(modifier = Modifier.height(50.dp))
                    }
                    Box(
                        modifier = Modifier.height(50.dp)
                            .width(if (namesIconsShown) 150.dp else 100.dp)
                            .background(Color.Transparent), contentAlignment = Alignment.Center
                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            if (namesIconsShown && listOfPlans[index].person.isNotEmpty()) {
                                CircleWithInitial(listOfPlans[index].person.uppercase())
                                Spacer(Modifier.width(8.dp))
                            }
                            Text(
                                listOfPlans[index].person,
                                textAlign = TextAlign.Center,
                                color = namesColor,
                                overflow = TextOverflow.Ellipsis
                            )
                        }

                    }
                }
            }

            val pathEffect =
                PathEffect.dashPathEffect(floatArrayOf(5f, 5f), phase = 0f)

            Column(
                modifier = Modifier
                    .background(Color.Transparent)
            ) {

                //Hours
                Row(
                    modifier = Modifier.height(50.dp)
                        .horizontalScroll(horizontalScrollState),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    repeat(24 - startHour + 1) { index ->
                        Box(
                            modifier = Modifier
                                .width(celWidthDp.dp)
                                .height(50.dp)
                                .drawBehind {
                                    drawLine(
                                        color = borderColor,
                                        start = Offset(x = 0f, y = size.height),
                                        end = Offset(x = size.width, y = size.height),
                                        strokeWidth = 1.dp.toPx()
                                    )
                                    drawLine(
                                        color = borderPathColor,
                                        start = Offset(x = size.width, y = 0f),
                                        end = Offset(x = size.width, y = size.height),
                                        pathEffect = pathEffect,
                                        strokeWidth = 1.dp.toPx()
                                    )
                                },
                            contentAlignment = Alignment.Center
                        ) {
                            Text(
                                "${index + startHour}:00",
                                textAlign = TextAlign.Center,
                                color = hoursColor
                            )
                        }
                    }
                }

                //content
                Column(
                    modifier = Modifier
                        .horizontalScroll(horizontalScrollState)
                        .verticalScroll(verticalScrollState)
                ) {
                    val textMeasurer = rememberTextMeasurer()
                    repeat(listOfPlans.size) { rowIndex ->
                        if (rowIndex == 0) {
                            Canvas(modifier = Modifier.width(tableWidth).height(12.dp)) {
                                for (i in 0..16) {
                                    if (i > 0)
                                        drawLine(
                                            borderPathColor,
                                            start = Offset(celWidthDp.dp.toPx() * i, 0f),
                                            end = Offset(celWidthDp.dp.toPx() * i, 12.dp.toPx()),
                                            pathEffect = pathEffect,
                                            strokeWidth = 1.dp.toPx()
                                        )
                                }
                            }
                        } else {
                            Canvas(modifier = Modifier.width(tableWidth).height(50.dp)) {
                                for (i in 0..16) {
                                    if (i > 0)
                                        drawLine(
                                            borderPathColor,
                                            start = Offset(celWidthDp.dp.toPx() * i, 0f),
                                            end = Offset(celWidthDp.dp.toPx() * i, 50.dp.toPx()),
                                            pathEffect = pathEffect,
                                            strokeWidth = 1.dp.toPx()
                                        )
                                }
                            }
                        }
                        Canvas(modifier = Modifier.size(width = tableWidth, height = 50.dp)) {

                            //lines
                            for (i in 0..16) {
                                if (i > 0)
                                    drawLine(
                                        borderPathColor,
                                        start = Offset(celWidthDp.dp.toPx() * i, 0f),
                                        end = Offset(celWidthDp.dp.toPx() * i, 50.dp.toPx()),
                                        pathEffect = pathEffect,
                                        strokeWidth = 1.dp.toPx()
                                    )
                            }

                            //plans
                            if (listOfPlans.isNotEmpty()) {
                                listOfPlans[rowIndex].events.forEach {
                                    val color = when (it.status) {
                                        EventStatus.COMPLETED -> completedColor
                                        EventStatus.PENDING -> pendingColor
                                        EventStatus.IN_PROGRESS -> pendingColor
                                        EventStatus.DELAYED -> delayedColor
                                        EventStatus.REJECTED -> delayedColor
                                    }
                                    val minX1 = it.from.hour * 60 + it.from.minute
                                    val x1 = maxOf(
                                        0f,
                                        (tableWidth.toPx() * (minX1 - (startHour * 60))) / (1440 - (startHour * 60))
                                    )

                                    val minX2 =
                                        it.to?.let { to -> to.hour * 60 + to.minute }
                                            ?: (24 * 60)
                                    val x2 = maxOf(
                                        0f,
                                        (tableWidth.toPx() * (minX2 - (startHour * 60))) / (1440 - (startHour * 60))
                                    )

                                    val width = maxOf(0f, x2 - x1)
                                    val topLeft = Offset(x1, 0f)
                                    val size = Size(width, 50.dp.toPx())

                                    drawRoundRect(
                                        color = color,
                                        topLeft = topLeft,
                                        size = size,
                                        cornerRadius = CornerRadius(10f, 10f)
                                    )
                                    val measuredText =
                                        textMeasurer.measure(
                                            AnnotatedString(it.title ?: ""),
                                            constraints = Constraints.fixedWidth(size.width.toInt()),
                                            overflow = TextOverflow.Ellipsis,
                                            style = TextStyle(
                                                fontSize = 18.sp,
                                                color = Color.Black,
                                                textAlign = TextAlign.Center
                                            ),
                                            maxLines = 1
                                        )
                                    val textOffsetX =
                                        topLeft.x + (size.width - measuredText.size.width) / 2
                                    val textOffsetY =
                                        topLeft.y + 50.dp.toPx() / 2 - measuredText.size.height / 2
                                    drawText(
                                        measuredText,
                                        topLeft = Offset(
                                            textOffsetX,
                                            textOffsetY
                                        )
                                    )
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}