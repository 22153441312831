package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FileObject(
    @SerialName("documentFileId") var documentFileId: String? = null,
    @SerialName("createdBy") var createdBy: String? = null,
    @SerialName("profileImageFileId") var profileImageFileId: String? = null,
    @SerialName("createdAt") var createdAt: String? = null,
    @SerialName("userId") var userId: String? = null
)