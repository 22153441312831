package com.hyperether.goodjob.scenes.pricing

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.OutlinedButton
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.before_you_proceed
import com.hyperether.goodjob.resources.cancel_subscription
import com.hyperether.goodjob.resources.contacting_support_for_assistance
import com.hyperether.goodjob.resources.continue_cancellation
import com.hyperether.goodjob.resources.downgrading_to_lower
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.keep_my_subscription
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.team_members_linked
import com.hyperether.goodjob.resources.what_happens
import com.hyperether.goodjob.resources.you_will_lose
import com.hyperether.goodjob.resources.your_data_will
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.stringResource

@Composable
fun CancelSubscriptionScreen(
    pricingViewModel: PricingViewModel,
    navHostController: NavHostController
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .background(Color.White)
    ) {
        Column {
            MobileHeaderBackAndAction(
                title = stringResource(Res.string.cancel_subscription),
                back = { navHostController.popBackStack() },
                actionEnabled = false
            )
            ScrollableContent(pricingViewModel, navHostController)
        }
    }
}

@Composable
fun ScrollableContent(
    pricingViewModel: PricingViewModel,
    navHostController: NavHostController
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .verticalScroll(rememberScrollState())
    ) {
        SubscriptionDetails(pricingViewModel, navHostController)
    }
}

@Composable
fun SubscriptionDetails(
    pricingViewModel: PricingViewModel,
    navHostController: NavHostController
) {
    Column(modifier = Modifier.padding(top = 24.dp, start = 16.dp, end = 16.dp)) {
        // Title
        Text(
            stringResource(Res.string.cancel_subscription),
            color = Color(0xFF111111),
            fontSize = 24.sp,
            fontWeight = FontWeight.W700,
            modifier = Modifier.padding(bottom = 19.dp)
        )
        // Section Title
        Text(
            stringResource(Res.string.what_happens),
            color = Color(0xFF111111),
            fontSize = 16.sp,
            fontWeight = FontWeight.W700,
            modifier = Modifier.padding(bottom = 14.dp)
        )
        // Bulleted Points
        Column(modifier = Modifier.padding(bottom = 30.dp)) {
            BulletPointText(stringResource(Res.string.you_will_lose))
            BulletPointText(stringResource(Res.string.your_data_will))
            BulletPointText(stringResource(Res.string.team_members_linked))
        }
        // Section Title
        Text(
            stringResource(Res.string.before_you_proceed),
            color = Color(0xFF111111),
            fontSize = 16.sp,
            fontWeight = FontWeight.W700,
            modifier = Modifier.padding(bottom = 14.dp)
        )
        // Bulleted Points
        Column(modifier = Modifier.padding(bottom = 30.dp)) {
            BulletPointText(stringResource(Res.string.downgrading_to_lower))
            BulletPointText(stringResource(Res.string.contacting_support_for_assistance))
        }
        // Buttons
        androidx.compose.material3.OutlinedButton(
            onClick = {
                navHostController.popBackStack()
            },
            border = BorderStroke(1.dp, BlueApp),
            colors = androidx.compose.material3.ButtonDefaults.outlinedButtonColors(
                contentColor = Color.White, containerColor = Color.Transparent
            ),
            shape = RoundedCornerShape(25.dp),
            modifier = Modifier.fillMaxWidth().height(50.dp),

            ) {
            Text(stringResource(Res.string.keep_my_subscription), color = BlueApp)
        }
        Spacer(modifier = Modifier.height(40.dp))
        Box(
            modifier = Modifier.fillMaxWidth()
                .height(50.dp)
                .clickable {
                    pricingViewModel.cancelSubscription()
                },
            contentAlignment = Alignment.Center
        ) {
            Text(
                stringResource(Res.string.continue_cancellation),
                color = Color.Gray,
                fontSize = 16.sp
            )
        }

        if (pricingViewModel.showPopUpSuccess.value) {
            PopUpDialog(
                onDismiss = {
                    pricingViewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                },
                title = stringResource(Res.string.success).uppercase(),
                message = pricingViewModel.messageText.value,
                positiveBtnText = stringResource(Res.string.ok),
                onPositiveBtnClicked = {
                    pricingViewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                }
            )
        }
        if (pricingViewModel.showPopUpError.value) {
            ErrorDialog(
                onDismiss = { pricingViewModel.showPopUpError.value = false },
                title = stringResource(Res.string.error).uppercase(),
                message = pricingViewModel.messageText.value
            )
        }
    }
}

@Composable
fun BulletPointText(text: String) {
    Row(modifier = Modifier.padding(bottom = 8.dp)) {
        Text(
            "•",
            fontSize = 16.sp,
            color = Color(0xFF111111),
            modifier = Modifier.padding(end = 8.dp)
        )
        Text(text, fontSize = 16.sp, color = Color(0xFF111111))
    }
}


@Composable
fun CancellationActions() {
    OutlinedButton(
        onClick = { println("Pressed!") },
        border = BorderStroke(0.dp, Color.Transparent),
        colors = ButtonDefaults.outlinedButtonColors(backgroundColor = Color.Transparent),
        contentPadding = PaddingValues(),
        modifier = Modifier
            .padding(bottom = 46.dp, start = 16.dp, end = 16.dp)
            .border(0.dp, Color(0xFF34618E), RoundedCornerShape(32.dp))
            .clip(RoundedCornerShape(32.dp))
            .fillMaxWidth()
    ) {
        Text(
            "Keep my subscription",
            color = Color(0xFF34618E),
            fontSize = 16.sp,
            modifier = Modifier.padding(vertical = 16.dp)
        )
    }
    Text(
        "Continue Cancellation",
        color = Color(0xFF535252),
        fontSize = 16.sp,
        textAlign = TextAlign.Center,
        modifier = Modifier.padding(bottom = 114.dp)
    )
}
