package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.map.MapComponent
import com.hyperether.goodjob.map.Marker
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.CompletionReportStatus
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.remote.model.CompletionReportResponse
import com.hyperether.goodjob.repository.remote.model.LatLng
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.completed
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.job_details
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.jobs.components.JobDetailsAssigneesViewWeb
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BlueBackground
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.JobStatusMap
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun JobDetailsWeb(
    navHostController: NavHostController,
    viewModel: JobDetailsViewModel,
    jobId: String,
    onOpenPdf: (String) -> Unit,
    onUploadDocumentClick: (Boolean) -> Unit
) {

    val log = logging("JobDetailsWeb")
    var selectedIndex by remember { mutableStateOf(0) }

    viewModel.jobStatusMap = JobStatusMap()

    var percentCompleted by remember { mutableStateOf(0) }

    val job by viewModel.jobDetails.collectAsState()
    val contact by viewModel.contact.collectAsState()

    LaunchedEffect(Unit) {
        viewModel.loadJobDetails(jobId)
    }
    LaunchedEffect(job) {
        job?.id?.let {
            viewModel.clearCompletionReportValue()
            viewModel.getJobReportByJobId(it)
        }
        val fileObjectsList = job?.fileObjects
        fileObjectsList?.let {
            if (it.isNotEmpty())
                for (fileObject in it)
                    fileObject?.let {
                        viewModel.downloadFile(fileObject)
                    }
        }
    }

    Column(
        modifier = Modifier.fillMaxSize().background(DashboardBg)
    ) {
        WebViewHeader(
            isAddNewJob = false,
            isBackAvailable = true,
            onBackClick = { navHostController.popBackStack() },
            isAddNewContact = true,
            title = job?.jobTitle ?: "",
            isImportAndAddVisible = false,
            navHostController = navHostController
        )
        Spacer(modifier = Modifier.height(Dp(15f)))
        Box(
            modifier = Modifier.fillMaxSize().background(color = Color(0xfffcfcfc))
                .padding(start = Dp(75f), end = Dp(75f), top = Dp(15f))
        ) {

            Box(
                modifier = Modifier
                    //.align(alignment = Alignment.TopCenter)
                    .fillMaxWidth().requiredHeight(height = Dp(773f))
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth().requiredHeight(height = Dp(789f))
                        .background(color = Color.White)
                        .border(width = Dp(1f), color = RegisterBorder)
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.Top,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        Column(modifier = Modifier.weight(1f)) {
                            Column(
                                modifier = Modifier.width(Dp(210f)).fillMaxHeight()
                                    .padding(top = 10.dp).drawBehind {
                                    }) {
                                Box(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .height(Dp(48f))
                                        .then(
                                            if (selectedIndex == 0)
                                                Modifier.background(BlueBackground).drawBehind {
                                                    drawLine(
                                                        color = BlueApp,
                                                        strokeWidth = 6.dp.toPx(),
                                                        start = Offset(3.dp.toPx(), 0f),
                                                        end = Offset(3.dp.toPx(), size.height)
                                                    )
                                                }
                                            else Modifier)
                                        .clickable { selectedIndex = 0 },
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                                        textAlign = TextAlign.Start,
                                        text = stringResource(Res.string.job_details),
                                        color = if (selectedIndex == 0) BlueApp else TextColor
                                    )
                                }

                                Box(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .height(Dp(48f))
                                        .then(
                                            if (selectedIndex == 1)
                                                Modifier.background(BlueBackground).drawBehind {
                                                    drawLine(
                                                        color = BlueApp,
                                                        strokeWidth = 6.dp.toPx(),
                                                        start = Offset(3.dp.toPx(), 0f),
                                                        end = Offset(3.dp.toPx(), size.height)
                                                    )
                                                }
                                            else Modifier)
                                        .clickable { selectedIndex = 1 },
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                                        textAlign = TextAlign.Start,
                                        text = "Job steps",
                                        color = if (selectedIndex == 1) BlueApp else TextColor
                                    )
                                }

                                Box(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .height(Dp(48f))
                                        .then(
                                            if (selectedIndex == 2)
                                                Modifier.background(BlueBackground).drawBehind {
                                                    drawLine(
                                                        color = BlueApp,
                                                        strokeWidth = 6.dp.toPx(),
                                                        start = Offset(3.dp.toPx(), 0f),
                                                        end = Offset(3.dp.toPx(), size.height)
                                                    )
                                                }
                                            else Modifier)
                                        .clickable { selectedIndex = 2 },
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                                        textAlign = TextAlign.Start,
                                        text = "Files and notes",
                                        color = if (selectedIndex == 2) BlueApp else TextColor
                                    )
                                }
                                Box(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .height(Dp(48f))
                                        .then(
                                            if (selectedIndex == 3)
                                                Modifier.background(BlueBackground).drawBehind {
                                                    drawLine(
                                                        color = BlueApp,
                                                        strokeWidth = 6.dp.toPx(),
                                                        start = Offset(3.dp.toPx(), 0f),
                                                        end = Offset(3.dp.toPx(), size.height)
                                                    )
                                                }
                                            else Modifier)
                                        .clickable { selectedIndex = 3 },
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                                        textAlign = TextAlign.Start,
                                        text = "Task Completion Report",
                                        color = if (selectedIndex == 3) BlueApp else TextColor
                                    )

                                }

                            }
                        }

                        Divider(
                            modifier = Modifier.width(1.dp).fillMaxHeight()
                                .background(RegisterBorder)
                        )
                        Column(modifier = Modifier.fillMaxWidth().weight(5f)) {
                            Box(
                                modifier = Modifier.fillMaxSize()
                            ) {
                                if (selectedIndex == 0) {
                                    Column(
                                        modifier = Modifier.fillMaxSize().padding(16.dp)
                                    ) {
                                        Row(
                                            modifier = Modifier.fillMaxWidth(),
                                            horizontalArrangement = Arrangement.SpaceBetween
                                        ) {
                                            Text(
                                                fontSize = 27.sp,
                                                fontWeight = FontWeight.Bold,
                                                text = stringResource(Res.string.about_job),
                                            )
                                            Image(
                                                painter = painterResource(Res.drawable.edit),
                                                contentDescription = "",
                                                modifier = Modifier
                                                    .padding(end = 16.dp)
                                                    .clickable {
                                                        if (job != null) {
                                                            if (getPlatformChecker().isMobile()) {
                                                                navHostController.navigate(Screen.AddNewJob.route + "/${job?.id}")
                                                            } else {
                                                                navHostController.navigate(Screen.AddNewJob.route + "?jobId=${job?.id}")
                                                            }
                                                        }
                                                    }
                                            )
                                        }
                                        Spacer(modifier = Modifier.height(24.dp))

                                        // Job Title & Status
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text(
                                                text = job?.jobTitle ?: "",
                                                fontSize = 24.sp,
                                                fontWeight = FontWeight.Bold
                                            )
                                            Spacer(modifier = Modifier.width(24.dp))
                                            job?.status?.let { StatusBadge(it) }
                                        }

                                        Spacer(modifier = Modifier.height(8.dp))
                                        JobDetailsAssigneesViewWeb(job)
                                        Spacer(modifier = Modifier.height(8.dp))
                                        val address =
                                            viewModel.contactSites.firstOrNull { it.id == job?.contactSiteId }
                                        Text(
                                            text = address?.addressObject?.address
                                                ?: "",
                                            color = BlueApp,
                                            fontSize = Sp(16f)
                                        )
                                        Spacer(modifier = Modifier.height(8.dp))
                                        // Map Placeholder
                                        Box(
                                            modifier = Modifier.fillMaxWidth().height(82.dp)
                                                .background(BorderColor)
                                        ) {
                                            val lat = address?.addressObject?.lat ?: 0.0
                                            val long = address?.addressObject?.long ?: 0.0
                                            MapComponent(
                                                listOf(Marker(lat, long, "Site Address")),
                                                mutableStateOf(
                                                    LatLng(0.0, 0.0)
                                                )
                                            )
                                        }
                                        Spacer(modifier = Modifier.height(8.dp))
                                        job?.let { JobScheduleSection(it) }
                                        Spacer(modifier = Modifier.height(16.dp))
                                        contact?.let { ContactDetailsSection(it) }
                                    }
                                } else if (selectedIndex == 1) {
                                    val steps = job?.steps
                                    if (steps?.isNotEmpty() == true) {
                                        if (steps[0]?.stepTitle?.isNotEmpty() == true) {
                                            Column {
                                                Spacer(modifier = Modifier.height(15.dp))
                                                Text(
                                                    text = "${percentCompleted}% " + stringResource(
                                                        Res.string.completed
                                                    ),
                                                    color = job?.status?.let {
                                                        StatusColor().getStatusColor(
                                                            it
                                                        )
                                                    }
                                                        ?: Green,
                                                    fontWeight = FontWeight.W900,
                                                    fontSize = 16.sp,
                                                    modifier = Modifier.padding(start = 16.dp)

                                                )
                                                Spacer(modifier = Modifier.height(15.dp))

                                                LazyColumn(modifier = Modifier.fillMaxSize()) {
                                                    if (steps.isNotEmpty()) {
                                                        itemsIndexed(steps.takeIf { it.isNotEmpty() }
                                                            ?: emptyList()) { index, step ->
                                                            step?.let {
                                                                val stepCounter =
                                                                    remember { mutableStateOf(index + 1) }
                                                                StepsItem(step = step,
                                                                    isAssignedToCurrentUser = viewModel.isUserAssignedToJob(
                                                                        job
                                                                    ),
                                                                    number = stepCounter,
                                                                    onStepUpdateCallback = { stepState ->
                                                                        step.stepStatus = stepState
                                                                        viewModel.updateJobSteps(
                                                                            jobId,
                                                                            steps = steps.filterNotNull(),
                                                                            onSuccess = {
                                                                                viewModel.loadJobDetails(
                                                                                    jobId
                                                                                )
                                                                            },
                                                                            onError = {})
                                                                    })
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else if (selectedIndex == 2) {
                                    job?.let {
                                        NotesAndDocumentsView(
                                            isWebView = true,
                                            images = viewModel.imagePainters.value,
                                            documents = viewModel.pdfFilePaths.value,
                                            job = it,
                                            isEmployee = viewModel.isEmployee(),
                                            onAddNoteClick = {
                                                viewModel.showNotePopUp.value = true
                                            },
                                            onOpenPdfClick = { fileName ->
                                                onOpenPdf(fileName)
                                            },
                                            onDeleteFileClick = { fileName ->
                                                viewModel.selectedFileName.value = fileName
                                                viewModel.selectedJob.value = it
                                                viewModel.showAreYouSurePopUpDeleteFile.value = true
                                            },
                                            onDeleteImageClick = { imageName ->
                                                viewModel.selectedImageName.value = imageName
                                                viewModel.selectedJob.value = it
                                                viewModel.showAreYouSurePopUpDeleteImage.value =
                                                    true
                                            },
                                            onMenuClicked = { note: Note ->
                                                if (viewModel.selectedNoteId.value == note._id && viewModel.showNoteEditDeletePopUpMenu.value) {
                                                    viewModel.showNoteEditDeletePopUpMenu.value =
                                                        false
                                                    viewModel.selectedNoteId.value = null
                                                } else {
                                                    viewModel.selectedNoteId.value = note._id
                                                    viewModel.noteToEditOrDelete.value = note
                                                    viewModel.showNoteEditDeletePopUpMenu.value =
                                                        true
                                                }
                                            },
                                            onEditNote = { note ->
                                                viewModel.noteInput.value = note.noteText.orEmpty()
                                                viewModel.showNotePopUp.value = true
                                                viewModel.isEditSelectedNote.value = true
                                                viewModel.selectedNoteId.value = note._id
                                            },
                                            onDeleteNote = {
                                                viewModel.showAreYouSurePopUpDeleteNote.value = true
                                                viewModel.selectedNoteId.value = null
                                            },
                                            isPopupVisible = { note ->
                                                viewModel.selectedNoteId.value == note._id && viewModel.showNoteEditDeletePopUpMenu.value
                                            }
                                        )
                                    }
                                } else if (selectedIndex == 3) {
                                    if (viewModel.isUserAssignedToJob(job)) {
                                        log.d("XXX") {
                                            "isUserAssignedToJob: ${
                                                viewModel.isUserAssignedToJob(
                                                    job
                                                )
                                            }"
                                        }
                                        job?.let {
                                            var report: CompletionReportResponse? = null
                                            job?.completionReport?.let {
                                                report =
                                                    Mapper().jsonAsObject<CompletionReportResponse>(
                                                        job?.completionReport
                                                    )
                                            }
                                            log.d("XXX") { "report: ${report}" }

                                            if (report == null || report?.status == CompletionReportStatus.rejected.name) {
                                                log.d("XXX") { "report: ${report}, reporrt status: ${report?.status}" }

                                                if (report != null) {
                                                    viewModel.damageState.value =
                                                        report?.damage == true
                                                    viewModel.healthAndSafetyState.value =
                                                        report?.healthAndSafety == true
                                                    viewModel.correctFunctionalityState.value =
                                                        report?.correctFunctionality == true
                                                    viewModel.additionalNotesText.value =
                                                        report?.additionalNotes ?: ""
                                                    viewModel.clientFeedbackText.value =
                                                        report?.clientFeedback ?: ""
                                                    viewModel.filesList.clear()
                                                    report?.files?.let {
                                                        viewModel.filesList.addAll(it)
                                                    }
                                                }
                                                CompletionReportSubmit(
                                                    viewModel,
                                                    navHostController,
                                                    job!!,
                                                    onUploadDocumentClick
                                                )
                                            } else {
                                                log.d("XXX") { " ELSE; report: ${report}, reporrt status: ${report?.status}" }
                                                CompletionReportOverview(
                                                    viewModel,
                                                    navHostController,
                                                    job!!
                                                )
                                            }
                                        }
                                    } else {
                                        log.d("XXX") {
                                            "isUserAssignedToJob: ${
                                                viewModel.isUserAssignedToJob(
                                                    job
                                                )
                                            }"
                                        }
                                        job?.let {
                                            CompletionReportOverview(
                                                viewModel,
                                                navHostController,
                                                it
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}