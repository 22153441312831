package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.choose_team
import com.hyperether.goodjob.resources.daily_activities
import com.hyperether.goodjob.resources.details
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.employee_details
import com.hyperether.goodjob.resources.enter_full_name
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.error_message
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.ic_leave
import com.hyperether.goodjob.resources.ic_mail
import com.hyperether.goodjob.resources.ic_map_pin
import com.hyperether.goodjob.resources.ic_skills
import com.hyperether.goodjob.resources.ic_user
import com.hyperether.goodjob.resources.ic_users
import com.hyperether.goodjob.resources.ic_work_scheduler
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.start_typing_address
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.upcoming_leaves
import com.hyperether.goodjob.resources.work_schedule
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.addNew.CustomDropdownMenu
import com.hyperether.goodjob.scenes.addNew.CustomInputRowEmployee
import com.hyperether.goodjob.scenes.addNew.CustomNumberInputRowEmployee
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.PopUpDialog
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun EmployeeDetails(
    employeeId: String,
    isMobileView: Boolean,
    viewModel: EmployeesViewModel,
    addNewViewModel: AddNewViewModel,
    navController: NavHostController
) {
    val log = logging("EmployeeDetails")
    log.d("EmployeeDetails") { "Employee ID: $employeeId" }

    if (isMobileView) {
        val employees by viewModel.employees.collectAsState(emptyList())
        val employee = employees.find { it.id == employeeId.toLong() }

        println(employee.toString())


        Box(modifier = Modifier.fillMaxSize()) {

            Column {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp, vertical = 20.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "",
                        modifier = Modifier
                            .clickable {
                                navController.popBackStack()
                            }
                    )

                    Text(
                        text = stringResource(Res.string.employee_details),
                        fontSize = 18.sp,
                        color = Color.Black,
                        textAlign = TextAlign.Center,
                        modifier = Modifier
                            .weight(1f)
                            .padding(start = 16.dp)
                    )

                    Row(
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .wrapContentWidth()
                            .padding(start = 8.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.edit),
                            contentDescription = "",
                            modifier = Modifier
                                .padding()
                                .clickable {
                                }
                        )
                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )
                Column(
                    modifier = Modifier
                        .background(Color.LightGray.copy(alpha = 0.2f))
                        .padding(8.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(40.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.details),
                            color = if (viewModel.isDetailsView.value) BlueApp else Color.Gray,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(start = 40.dp)
                                .clickable {
                                    viewModel.isDetailsView.value = true
                                }
                        )

                        Spacer(modifier = Modifier.weight(0.5f))

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.daily_activities),
                            color = if (viewModel.isDetailsView.value) Color.Gray else BlueApp,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(end = 40.dp)
                                .clickable {
                                    viewModel.isDetailsView.value = false
                                }
                        )
                    }
                }
                Box(
                    modifier = Modifier
                        .height(2.dp)
                        .fillMaxWidth(0.5f)
                        .background(if (viewModel.isDetailsView.value) BlueApp else BlueApp)
                        .align(if (viewModel.isDetailsView.value) Alignment.Start else Alignment.End)
                )
                if (viewModel.isDetailsView.value) {
                    EmployeeHeaderView(employee)
                    EmployeeContactsView(employee)
                    EmployeeDetailsView(employee)
                    EmployeeLeaveDetails(employee)
                } else {

                }
            }
        }
    } else {
        log.d("EmployeeDetails") { "is web view" }
        val selectedEmployee = remember { mutableStateOf(User()) }
        val showProgressBar = remember { mutableStateOf(false) }
        val showErrorDialog = remember { mutableStateOf(false) }

        val focusManager = LocalFocusManager.current
        val focusRequester = FocusRequester()

        val selectedCard = remember { mutableStateOf("Details") }
        val isEditView = remember { mutableStateOf(false) }

        LaunchedEffect(Unit) {
            log.d("EmployeeDetails") { "LaunchedEffect" }
            addNewViewModel.getEmployeeById(employeeId)
            addNewViewModel.selected_employee_remote.collect {
                log.d("EmployeeDetails") { "selected employee: ${it._id}" }
                selectedEmployee.value = it
                addNewViewModel.fullNameInput.value = it.fullName ?: ""
                addNewViewModel.addressInput.value = it.address ?: ""
                addNewViewModel.phoneInput.value = it.phone ?: ""
                addNewViewModel.emailInput.value = it.email ?: ""
                addNewViewModel.team.value = it.teams?.get(0)?.value ?: ""
            }
        }

        // TODO: get Teams
        val itemsTeams = listOf(
            Team(id = "1", value = "Sales Team"),
            Team(id = "2", value = "Support Team"),
            Team(id = "3", value = "Technical Team")
        )

        val itemsSkills = listOf("Skill1", "Skill2")


        Column(
            modifier = Modifier
                .fillMaxSize()
                .background(DashboardBg)
                .verticalScroll(rememberScrollState())
        ) {
            MainTitle(
                title = selectedEmployee.value.fullName ?: ""
            ) {
                navController.popBackStack()
            }
            Spacer(modifier = Modifier.height(30.dp))

            // Form container
            Box(
                modifier = Modifier
                    .padding(horizontal = 40.dp)
                    .background(Color.White)
                    .fillMaxWidth()
                    .border(1.dp, BorderColor)
            ) {
                Row {
                    DetailsCards(
                        modifier = Modifier.weight(1f),
                        selectedCard = selectedCard
                    )
                    Column(
                        modifier = Modifier
                            .weight(5f)
                            .padding(horizontal = 40.dp)
                            .drawBehind {
                                drawLine(
                                    color = BorderColor,
                                    start = Offset(0f, 0f),
                                    end = Offset(0f, size.height),
                                    strokeWidth = 1f
                                )
                            }
                            .padding(horizontal = 40.dp, vertical = 40.dp)

                    ) {
                        Row(
                            modifier = Modifier
                                .fillMaxWidth(),
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Text(
                                text = stringResource(Res.string.details),
                                fontSize = 27.sp,
                                lineHeight = 30.sp,
                                color = Color.Black,
                                fontWeight = FontWeight.Bold
                            )
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.clickable {
                                    isEditView.value = !isEditView.value
                                }
                            ) {
                                if (!isEditView.value) {
                                    Icon(
                                        painter = painterResource(Res.drawable.edit),
                                        contentDescription = stringResource(Res.string.edit),
                                        tint = Color(0xFF515151),
                                        modifier = Modifier.size(24.dp)
                                    )
                                    Spacer(modifier = Modifier.width(4.dp))
                                }
                                Text(
                                    text = if (isEditView.value) stringResource(Res.string.cancel) else stringResource(
                                        Res.string.edit
                                    ),
                                    color = if (isEditView.value) Color.Red else Color(0xFF515151),
                                    fontSize = 18.sp
                                )
                            }

                        }
                        Spacer(modifier = Modifier.height(40.dp))
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            // Form inputs
                            if (isEditView.value) {
                                Column(
                                    modifier = Modifier.weight(1f),
                                    verticalArrangement = Arrangement.spacedBy(16.dp)
                                ) {
                                    LaunchedEffect(true) {
                                        focusRequester.requestFocus()
                                    }
                                    CustomInputRowEmployee(
                                        painterResource = Res.drawable.ic_user,
                                        labelText = stringResource(Res.string.full_name),
                                        placeholderText = stringResource(Res.string.enter_full_name),
                                        inputValue = addNewViewModel.fullNameInput.value,
                                        onValueChange = {
                                            addNewViewModel.fullNameInput.value = ""
                                            addNewViewModel.fullNameInput.value = it
                                        },
                                        focusManager = focusManager,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomInputRowEmployee(
                                        painterResource = Res.drawable.ic_map_pin,
                                        labelText = stringResource(Res.string.address),
                                        placeholderText = stringResource(Res.string.start_typing_address),
                                        inputValue = addNewViewModel.addressInput.value,
                                        onValueChange = {
                                            addNewViewModel.addressInput.value = ""
                                            addNewViewModel.addressInput.value = it
                                        },
                                        focusManager = focusManager,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomNumberInputRowEmployee(
                                        painterResource = Res.drawable.phone,
                                        labelText = stringResource(Res.string.phone),
                                        placeholderText = "+1 555 123 4567",
                                        inputValue = addNewViewModel.phoneInput.value,
                                        onValueChange = {
                                            addNewViewModel.phoneInput.value = ""
                                            addNewViewModel.phoneInput.value = it
                                        },
                                        focusManager = focusManager,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomInputRowEmployee(
                                        painterResource = Res.drawable.ic_mail,
                                        labelText = stringResource(Res.string.email),
                                        placeholderText = stringResource(Res.string.enter_your_email),
                                        inputValue = addNewViewModel.emailInput.value,
                                        onValueChange = {
                                            addNewViewModel.emailInput.value = ""
                                            addNewViewModel.emailInput.value = it
                                        },
                                        focusManager = focusManager,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    val chooseTeam = stringResource(Res.string.choose_team)
                                    CustomDropdownMenu(
                                        iconRes = Res.drawable.ic_users,
                                        labelText = stringResource(Res.string.teams),
                                        items = itemsTeams,
                                        selectedValue = addNewViewModel.selectedTeam.value
                                            ?: Team(),
                                        onValueSelected = {
                                            addNewViewModel.selectedTeam.value = it
                                        },
                                        displayText = { team -> team.value ?: chooseTeam },
                                        focusManager = focusManager,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    val skills = stringResource(Res.string.skills)
                                    CustomDropdownMenu(
                                        iconRes = Res.drawable.ic_skills,
                                        labelText = stringResource(Res.string.skills),
                                        items = itemsSkills,
                                        selectedValue = addNewViewModel.selectedSkill.value
                                            ?: skills,
                                        onValueSelected = {
                                            addNewViewModel.selectedSkill.value = it
                                        },
                                        displayText = { it },
                                        focusManager = focusManager,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )

                                    WorkSchedulerInput()

                                    UpcomingLeave()
                                    SaveOrCancelBtnVertical(isEditView)
                                }
                            } else {
                                Column(
                                    modifier = Modifier.weight(1f),
                                    verticalArrangement = Arrangement.spacedBy(2.dp)
                                ) {
                                    LaunchedEffect(true) {
                                        focusRequester.requestFocus()
                                    }
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.ic_user,
                                        labelText = stringResource(Res.string.full_name),
                                        displayText = addNewViewModel.fullNameInput.value,
                                        textColor = Color.Black,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.ic_map_pin,
                                        labelText = stringResource(Res.string.address),
                                        displayText = addNewViewModel.addressInput.value,
                                        textColor = BlueApp,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.phone,
                                        labelText = stringResource(Res.string.phone),
                                        displayText = addNewViewModel.phoneInput.value,
                                        textColor = Color.Black,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.ic_mail,
                                        labelText = stringResource(Res.string.email),
                                        displayText = addNewViewModel.emailInput.value,
                                        textColor = Color.Black,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    Divider(
                                        modifier = Modifier.height(1.dp)
                                            .background(Color.LightGray.copy(alpha = 0.1f))
                                    )
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.ic_users,
                                        labelText = stringResource(Res.string.teams),
                                        displayText = addNewViewModel.selectedTeam.value?.value
                                            ?: "",
                                        textColor = Color.Black,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.ic_skills,
                                        labelText = stringResource(Res.string.skills),
                                        displayText = addNewViewModel.selectedSkill.value ?: "",
                                        textColor = Color.Black,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.ic_work_scheduler,
                                        labelText = stringResource(Res.string.work_schedule),
                                        displayText = "", // TODO
                                        textColor = Color.Black,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                    CustomDisplayRowEmployee(
                                        painterResource = Res.drawable.ic_leave,
                                        labelText = stringResource(Res.string.upcoming_leaves),
                                        displayText = "", // TODO
                                        textColor = Color.Black,
                                        modifier = Modifier.focusRequester(focusRequester)
                                    )
                                }
                            }

                            Spacer(modifier = Modifier.width(30.dp))
                            ProfilePictureSection(
                                isEditView,
                                btnText = "Upload new image",
                            ) {
                                // TODO: upload new image
                            }
                        }
                    }
                }

                Spacer(modifier = Modifier.height(20.dp))
            }
        }
        if (showErrorDialog.value) {
            val error = stringResource(Res.string.error)
            val message = stringResource(Res.string.error_message)
            PopUpDialog(
                onDismiss = {
                    showErrorDialog.value = false
                },
                title = error,
                message = message
            )
        }
        if (showProgressBar.value) {
            FullScreenProgressBar(showProgressBar = showProgressBar)
        }
    }
}