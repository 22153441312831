package com.hyperether.goodjob.navigation

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.BottomNavigation
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraph.Companion.findStartDestination
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.scenes.addNew.AddNewEmployee
import com.hyperether.goodjob.scenes.addNew.AddNewJobEmployee
import com.hyperether.goodjob.scenes.checkouts.CheckoutScreen
import com.hyperether.goodjob.scenes.checkouts.CheckoutsViewModel
import com.hyperether.goodjob.scenes.dashboard.DashboardScreen
import com.hyperether.goodjob.scenes.employees.EmployeesScreen
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.history.HistoryScreen
import com.hyperether.goodjob.scenes.history.HistoryScreenViewModel
import com.hyperether.goodjob.scenes.landing.LandingPage
import com.hyperether.goodjob.scenes.onboarding.OnboardingScreen
import com.hyperether.goodjob.scenes.pricing.PricingScreen
import com.hyperether.goodjob.scenes.register.RegisterScreen
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.scheduler.SchedulerScreen
import com.hyperether.goodjob.scenes.splash.SplashScreen
import com.hyperether.goodjob.scenes.workspace.AddNewWorkspaceScreen
import com.hyperether.goodjob.scenes.workspace.WorkspaceScreen
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel

@Composable
fun NavGraph(navController: NavHostController, repository: Repository, modifier: Modifier) {
    NavHost(
        navController = navController,
        startDestination = Screen.Splash.route,
        modifier = modifier
    ) {
        composable(route = Screen.Checkout.route) {
            val viewModel: CheckoutsViewModel =
                viewModel { CheckoutsViewModel(repository) }
            CheckoutScreen(
                navController, viewModel = viewModel
            )
        }

        composable(route = Screen.Splash.route) {
            SplashScreen()
        }

        composable(
            route = Screen.Onboarding.route
        ) {
            OnboardingScreen(navController)
        }

        composable(
            route = Screen.Register.route
        ) {
            val viewModel: RegisterViewModel = viewModel { RegisterViewModel(repository) }
            RegisterScreen(registerViewModel = viewModel, navHostController = navController)
        }

        composable(
            route = Screen.Pricing.route
        ) {
            BoxWithConstraints {
                if (getPlatformChecker().isMobile() || maxWidth < 600.dp) {
                    PricingScreen(true) {
                        navController.navigate(Screen.Workspace.route)
                    }
                } else {
                    PricingScreen(false) {
                        navController.navigate(Screen.Workspace.route)
                    }
                }
            }
        }

        composable(
            route = Screen.Workspace.route
        ) {
            val viewModel = WorkspaceViewModel(repository)
            BoxWithConstraints {
                if (getPlatformChecker().isMobile() || maxWidth < 600.dp) {
                    WorkspaceScreen(
                        true,
                        workspaceViewModel = viewModel,
                        continueToDashboard = {
                            navController.navigate(Screen.Dashboard.route)
                        },
                        addNewWorkspace = {
                            navController.navigate(Screen.AddNewWorkspace.route)
                        })
                } else {
                    WorkspaceScreen(
                        false,
                        workspaceViewModel = viewModel,
                        continueToDashboard = {
                            navController.navigate(Screen.Dashboard.route)
                        },
                        addNewWorkspace = {
                            navController.navigate(Screen.AddNewWorkspace.route)
                        })
                }
            }
        }

        composable(
            route = Screen.AddNewWorkspace.route
        ) {
            val viewModel: WorkspaceViewModel = viewModel { WorkspaceViewModel(repository) }
            BoxWithConstraints {
                if (getPlatformChecker().isMobile() || maxWidth < 600.dp) {
                    AddNewWorkspaceScreen(
                        isMobileView = true,
                        workspaceViewModel = viewModel,
                        onChooseExistingWorkspace = {
                            navController.navigate(Screen.Workspace.route)
                        })
                } else {
                    AddNewWorkspaceScreen(
                        isMobileView = false,
                        workspaceViewModel = viewModel,
                        onChooseExistingWorkspace = {
                            navController.navigate(Screen.Workspace.route)
                        })
                }
            }
        }
        composable(
            route = Screen.Dashboard.route
        ) {
            DashboardScreen(navController)
        }

        composable(Screen.AddNew.route) {
            AddNewJobEmployee(navController)
        }

        composable(Screen.AddNewEmployee.route) {
            val viewModel: EmployeesViewModel = viewModel { EmployeesViewModel(repository) }
            AddNewEmployee(navController, viewModel)
        }

        composable(Screen.Employees.route) {
            val viewModel: EmployeesViewModel = viewModel { EmployeesViewModel(repository) }
            EmployeesScreen(viewModel)
        }

        composable(
            route = Screen.Landing.route
        ) {
            BoxWithConstraints {
                if (getPlatformChecker().isMobile() || maxWidth < 600.dp) {
                    LandingPage(true) {
                        navController.navigate(Screen.Register.route)
                    }
                } else {
                    LandingPage(false) {
                        navController.navigate(Screen.Register.route)
                    }
                }
            }
        }

        composable(
            route = Screen.History.route,
        ) {
            val viewModel: HistoryScreenViewModel =
                viewModel { HistoryScreenViewModel(repository) }
            HistoryScreen(
                viewModel = viewModel,
                navController
            )
        }

        composable(Screen.Scheduler.route) {
            SchedulerScreen()
        }

        composable(route = Screen.Statistic.route) {
            Text("Statistics screen")
        }

        composable(route = Screen.Returns.route) {
            Text("Returns screen")
        }

        composable(route = Screen.WebNav.route) {
            WebNavScreen(navController)
        }
    }
}

@Composable
fun Container(
    navController: NavHostController,
    content: @Composable (Modifier) -> Unit,
    repository: Repository
) {
    val isBottomNavigationBarVisible = remember { mutableStateOf(true) }

    LaunchedEffect(navController) {
        navController.addOnDestinationChangedListener { _, destination, _ ->
            isBottomNavigationBarVisible.value = when (destination.route) {
                Screen.Pricing.route,
                Screen.Register.route,
                Screen.AddNewEmployee.route,
                Screen.AddNew.route,
                Screen.Splash.route,
                Screen.Onboarding.route -> false

                else -> true
            }
        }
    }
    BoxWithConstraints(modifier = Modifier.fillMaxSize()) {
        if (maxWidth < 600.dp) {
            // Mobile layout
            Column {
                NavGraph(navController, repository, modifier = Modifier.weight(1f))
                if (isBottomNavigationBarVisible.value) {
                    BottomNavigation(backgroundColor = Color.White) {
                        AndroidNav(navController)
                    }
                }
            }
        } else {
            // Web or larger screen layout
            WebNav(navController, content)
        }
    }
}

fun NavController.safeNavigate(route: String) {
    currentDestination?.let { currentDestination ->
        if (currentDestination.route != route) {
            navigate(route) {
                popUpTo(graph.findStartDestination().route ?: Screen.Checkout.route) {
                    saveState = true
                }
                launchSingleTop = true
                restoreState = true
            }
        }
    }
}


//class NavController(
//    private val onNavigate: (String) -> Unit,
//    private val onNavigateBack: () -> Unit,
//    private val shouldNavigate: Boolean
//) :
//    NavHostController() {
//
//    init {
//        navigatorProvider.addNavigator(ComposeNavigator())
//        navigatorProvider.addNavigator(DialogNavigator())
//        this.addOnDestinationChangedListener { controller, destination, arguments ->
//            if (shouldNavigate) {
//                currentDestination?.route?.let {
//                    onNavigate(
//                        it
//                    )
//                }
//            }
//        }
//
//        if (!shouldNavigate) {
//            CoroutineScope(Dispatchers.Main).launch {
//                Router.currentRoute.collect {
//                    navigate(it)
//                }
//            }
//        }
//    }
//
//
//    override fun navigateUp(): Boolean {
//        if (shouldNavigate) {
//            onNavigateBack()
//            return super.navigateUp()
//        }
//        return false
//    }
//}
//
//@Composable
//fun rememberNavController(
//    onNavigate: (String) -> Unit,
//    onNavigateBack: () -> Unit,
//    shouldNavigate: Boolean
//): NavController {
//    return remember { NavController(onNavigate, onNavigateBack, shouldNavigate) }
//}
//
//object Router {
//    val currentRoute = MutableStateFlow("/")
//}