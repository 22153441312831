package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CompletionReportResponse(
    @SerialName("damage") val damage: Boolean?,
    @SerialName("correctFunctionality") val correctFunctionality: Boolean?,
    @SerialName("additionalNotes") val additionalNotes: String?,
    @SerialName("healthAndSafety") val healthAndSafety: Boolean?,
    @SerialName("clientSignature") val clientSignature: String?,
    @SerialName("clientFeedback") val clientFeedback: String?,
    @SerialName("files") var files: List<String>? = null,
    @SerialName("jobId") val jobId: String,
    @SerialName("workspaceId") val workspaceId: String,
    @SerialName("userId") val userId: String,
    @SerialName("type") val type: String,
    @SerialName("status") val status: String,
    @SerialName("damageDescription") var damageDescription: String? = null,
    @SerialName("reasonForIncompleteInspection") var reasonForIncompleteInspection: String? = null,
    @SerialName("unmetSafetyRequirements") var unmetSafetyRequirements: String? = null
)