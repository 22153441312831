package com.hyperether.goodjob.scenes.splash

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.logo


@Composable
fun SplashScreen() {

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(Color(0xFF10283F)),
        contentAlignment = Alignment.Center
    ) {
        Image(
            painter = org.jetbrains.compose.resources.painterResource(Res.drawable.logo),
            contentDescription = null
        )
    }
}