package com.hyperether.goodjob.scenes.jobs.components

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.navigation.Screen

@Composable
fun JobsList(
    sites: Map<String, Site>,
    jobs: List<Job>,
    selectedJobs: MutableList<Job>,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize().padding(start = 8.dp, end = 16.dp)
    ) {
        if(jobs.isNotEmpty()) {
            items(jobs.takeIf { it.isNotEmpty() } ?: emptyList()) { job ->
                JobsItem(
                    sites,
                    job = job,
                    isInEditMode = isInEditMode,
                    selectedJobs,
                    onJobSelected = { selectedJob, isSelected ->
                        if (isSelected) {
                            selectedJobs.add(selectedJob)
                        } else {
                            selectedJobs.remove(selectedJob)
                        }
                    },
                    onItemClick = {
                        if (getPlatformChecker().isMobile()) {
                            navHostController.navigate(Screen.JobDetails.route + "/${job.id}")
                        } else {
                            navHostController.navigate(Screen.JobDetails.route + "?jobId=${job.id}")
                        }
                    },
                )
            }
        }
    }
}