package com.hyperether.goodjob.database

class SharedDatabase(private val databaseDriverFactory: DriverFactory) {
    private var database: AppDatabase? = null
    suspend fun initDatabase(): AppDatabase {
        if (database == null) {
            database = AppDatabase(databaseDriverFactory.createDriver())
        }
        return database ?: throw IllegalStateException("Failed to initialize database")
    }

    fun getDatabase(): AppDatabase? {
        return database
    }

    suspend operator fun <R> invoke(block: suspend (AppDatabase) -> R): R {
        val db = initDatabase()
        return block(db)
    }
}