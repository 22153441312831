package com.hyperether.goodjob.scenes.employees

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.models.Employee
import com.hyperether.goodjob.repository.Repository
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.launch

class EmployeesViewModel(
    val repository: Repository,
) : ViewModel() {


    fun insertEmployee(employee: Employee){
        viewModelScope.launch {
            repository.insertEmployee(employee)
        }
    }

    fun deleteEmployeeById(ids: List<Long>){
        viewModelScope.launch {
            repository.deleteEmployeesByIds(ids)
        }
    }

    val employees: Flow<List<com.hyperether.goodjob.database.Employee>> = repository.getEmployees()

}