package com.hyperether.goodjob.navigation

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material.icons.filled.Refresh
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController

@Composable
fun WebNav(navController: NavHostController, content: @Composable (Modifier) -> Unit) {
    var currentRoute by remember { mutableStateOf(Screen.Checkout.route) }
    navController.addOnDestinationChangedListener { _, destination, _ ->
        currentRoute = destination.route.toString()
    }
    Column {
        if (currentRoute != Screen.WebNav.route) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(20.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                IconButton({
                    navController.safeNavigate(route = Screen.WebNav.route)
                }) {
                    Icon(
                        modifier = Modifier.size(40.dp),
                        imageVector = Icons.Default.Menu,
                        contentDescription = "Menu"
                    )
                }

                Text(
                    currentRoute,
                    fontSize = 24.sp,
                    fontWeight = FontWeight.Bold
                )

                IconButton({

                }) {
                    Icon(
                        modifier = Modifier.size(40.dp),
                        imageVector = Icons.Default.Refresh,
                        contentDescription = "Refresh"
                    )
                }

            }
        }
        content(Modifier.padding(20.dp))
    }
}


@Composable
fun WebNavScreen(navController: NavHostController) {
    Column(Modifier.padding(20.dp)) {
        IconButton({
            navController.popBackStack()
        }) {
            Icon(Icons.Default.ArrowBack, "Back")
        }

        IconButton({
            navController.safeNavigate(Screen.Checkout.route)
        }) {
            Column(
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text("Checkouts", fontSize = 20.sp, fontWeight = FontWeight.Bold)
            }

        }

        IconButton({
            navController.safeNavigate(Screen.History.route)
        }) {
            Column(
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text("History", fontSize = 20.sp, fontWeight = FontWeight.Bold)
            }
        }

        IconButton({
            navController.safeNavigate(Screen.Statistic.route)
        }) {
            Column(
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text("Statistic", fontSize = 20.sp, fontWeight = FontWeight.Bold)
            }
        }

        IconButton({
            navController.safeNavigate(Screen.Returns.route)
        }) {
            Column(
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text("Returns", fontSize = 20.sp, fontWeight = FontWeight.Bold)
            }
        }
    }
}