package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.hyperether.goodjob.planList
import com.hyperether.planner.ui.SchedulerDayPlanner

@Composable
fun SchedulerScreen(modifier: Modifier = Modifier) {
    SchedulerDayPlanner(listOfPlans = planList.toMutableList())
}