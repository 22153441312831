package com.hyperether.goodjob.util

import androidx.compose.ui.graphics.Color
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.RedInactive

class StatusColor {

    fun getStatusColor(status: String): Color {
        return when (status) {
            JobStatus.draft.name -> Color.Gray
            JobStatus.open.name -> Color(0xFF00a6ff)
            JobStatus.in_progress.name -> Color(0xFFE78400)
            JobStatus.on_hold.name -> Color(0xFFE7C500)
            JobStatus.pendingApproval.name -> Color(0xFF990ac4)
            JobStatus.completed.name -> Green
            JobStatus.cancelled.name -> RedInactive
            else -> Green
        }
    }
}