package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class DayHours(
    @SerialName("enabled") val enabled: Boolean? = null,
    @SerialName("from") val from: String? = null,
    @SerialName("to") val to: String? = null
)