package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Checkbox
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.map.MapComponent
import com.hyperether.goodjob.map.Marker
import com.hyperether.goodjob.repository.remote.model.LatLng
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_this_contact
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_contact
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.briefcase
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.city_icon
import com.hyperether.goodjob.resources.city_icon_24
import com.hyperether.goodjob.resources.contact_added_successfully
import com.hyperether.goodjob.resources.contact_deleted
import com.hyperether.goodjob.resources.contact_deteils
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_person
import com.hyperether.goodjob.resources.contact_type
import com.hyperether.goodjob.resources.contact_type_24
import com.hyperether.goodjob.resources.contact_updated_successfully
import com.hyperether.goodjob.resources.country
import com.hyperether.goodjob.resources.country_icon
import com.hyperether.goodjob.resources.country_icon_24
import com.hyperether.goodjob.resources.delete_contact
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.email_ic
import com.hyperether.goodjob.resources.email_send_icon
import com.hyperether.goodjob.resources.email_send_icon_24
import com.hyperether.goodjob.resources.enter_contact_name
import com.hyperether.goodjob.resources.enter_email_address
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.ic_mail
import com.hyperether.goodjob.resources.industry
import com.hyperether.goodjob.resources.map_pin
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.phone_24
import com.hyperether.goodjob.resources.save_changes
import com.hyperether.goodjob.resources.select_industry
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.update
import com.hyperether.goodjob.resources.use_contact_name
import com.hyperether.goodjob.resources.user
import com.hyperether.goodjob.resources.zip_employee
import com.hyperether.goodjob.resources.zip_icon
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeInputField
import com.hyperether.goodjob.scenes.components.AddressInputField
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.ZipInputField
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_CONTACT_ID
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewContactWebScreen(
    navHostController: NavHostController,
    viewModel: AddContactViewModel,
    contactId: String?
) {

    val contact = viewModel.contactWeb

    LaunchedEffect(contactId) {
        when (contactId) {
            null -> viewModel.clearContactValues()
            DEFAULT_CONTACT_ID -> viewModel.clearContactValues()
            else -> viewModel.getContactById(contactId)
        }
    }

    LaunchedEffect(Unit) {
        viewModel.getSites()
        if (contactId != null) {
            viewModel.fetchWebContactById(contactId)
        }
    }
    println("CONTACT" + contact.value.toString())
    println("CONTACT_ID" + contactId)

    LaunchedEffect(contact) {
        delay(1000)
        if (contactId != DEFAULT_CONTACT_ID) {
            contact.let {
                if (it != null) {
                    viewModel.contactNameNewContact.value =
                        it.value?.name ?: viewModel.contactNameNewContact.value
                    viewModel.contactType.value =
                        viewModel.contactTypeMap.entries.find { item -> item.value == it.value?.contactType }?.key
                            ?: viewModel.contactType.value
                    viewModel.industry.value = it.value?.industry ?: viewModel.industry.value
                    viewModel.contactCity.value =
                        it.value?.addressObject?.city ?: viewModel.contactCity.value
                    viewModel.contactCountry.value =
                        it.value?.addressObject?.country ?: viewModel.contactCountry.value
                    viewModel.contactAddress.value =
                        it.value?.addressObject?.address ?: viewModel.contactAddress.value
                    viewModel.contactZip.value =
                        it.value?.addressObject?.zip ?: viewModel.contactZip.value
                    viewModel.phoneNewContact.value =
                        it.value?.phone ?: viewModel.phoneNewContact.value
                    viewModel.emailNewContact.value =
                        it.value?.email ?: viewModel.emailNewContact.value
                    viewModel.contactPersonName.value =
                        it.value?.personName ?: viewModel.contactPersonName.value
                    viewModel.contactPersonEmail.value =
                        it.value?.personEmail ?: viewModel.contactPersonEmail.value
                    viewModel.contactPersonPhone.value =
                        it.value?.personPhone ?: viewModel.contactPersonPhone.value
                }
            }
        } else {
            viewModel.clearContactValues()
        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
            .verticalScroll(rememberScrollState())
            .padding(bottom = 40.dp)
    ) {
        val headerTitle = if (contactId == DEFAULT_CONTACT_ID || contactId == "") {
            stringResource(Res.string.add_new_contact)
        } else {
            contact.value?.name
        }
        WebViewHeader(
            isAddNewJob = false,
            isBackAvailable = false,
            title = headerTitle ?: "",
            isImportAndAddVisible = false,
            navHostController = navHostController
        )
        Spacer(modifier = Modifier.height(Dp(15f)))
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = Dp(75f), end = Dp(75f), top = Dp(40f))
                .border(width = Dp(1f), color = RegisterBorder)
                .background(color = Color.White)
                .padding(start = Dp(20f), top = Dp(40f))
                .padding(horizontal = 16.dp)
                .padding(end = Dp(40f))
        ) {

            val titleText = if (contactId == DEFAULT_CONTACT_ID || contactId == "") {
                stringResource(Res.string.contact_deteils)
            } else {
                stringResource(Res.string.about_this_contact)
            }
            Text(
                text = titleText,
                fontSize = Sp(24f),
                fontWeight = FontWeight.W700,
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(color = Color.White)
                    .padding(end = Dp(20f))
            ) {
                Column(
                    modifier = Modifier.weight(1f).padding(end = Dp(20f))
                ) {
                    Column(modifier = Modifier, verticalArrangement = Arrangement.spacedBy(10.dp)) {
                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.contact_type_24),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.contact_type))
                            }
                            Box(modifier = Modifier.weight(3f)) {
                                AddEmployeeInputField(
                                    viewModel.contactType,
                                    stringResource(Res.string.contact_type),
                                    placeholder = "",
                                    dropdownItems = viewModel.contactTypeMap,
                                    keyboardInputEnabled = false,
                                    topPadding = 0.dp
                                )
                            }

                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.user),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.contact_name))
                            }
                            Box(modifier = Modifier.weight(3f)) {
                                AppInputField(
                                    viewModel.contactNameNewContact,
                                    stringResource(Res.string.contact_name),
                                    placeholder = stringResource(Res.string.enter_contact_name),
                                    paddingTop = 0.dp
                                )
                            }
                        }


                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier.offset(x = (-12).dp).padding(top = 5.dp)
                        ) {
                            Checkbox(
                                checked = viewModel.isCheckedContactPerson.value,
                                onCheckedChange = {
                                    viewModel.isCheckedContactPerson.value = it
                                },
                                modifier = Modifier.clip(RoundedCornerShape(50))
                            )
                            Spacer(modifier = Modifier.width(5.dp))
                            Text(
                                text = stringResource(Res.string.use_contact_name),
                                color = TextColor,
                                fontSize = 12.sp
                            )
                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.email_send_icon_24),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.email_address))
                            }

                            Box(modifier = Modifier.weight(3f)) {
                                AppInputField(
                                    viewModel.emailNewContact,
                                    stringResource(Res.string.email_address),
                                    placeholder = stringResource(Res.string.enter_email_address),
                                    enabled = contactId == DEFAULT_CONTACT_ID,
                                    paddingTop = 0.dp
                                )
                            }
                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.phone_24),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.phone))
                            }
                            Box(modifier = Modifier.weight(3f)) {
                                PhoneInputField(
                                    viewModel.phoneNewContact,
                                    stringResource(Res.string.phone),
                                    placeholder = "+134232525235",
                                    paddingTop = 0.dp
                                )
                            }
                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.map_pin),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.address))
                            }

                            Box(modifier = Modifier.weight(3f)) {
                                AddressInputField(
                                    textCountry = viewModel.contactCountry,
                                    textCity = viewModel.contactCity,
                                    text = viewModel.contactAddress,
                                    label = stringResource(Res.string.address),
                                    placeholder = stringResource(Res.string.enter_street),
                                    modifier = Modifier,
                                    onSearch = { viewModel.findPlace(it) },
                                    onSelect = {
                                        viewModel.contactAddress.value = it.formattedAddress
                                        viewModel.place = it
                                    },
                                    places = viewModel.places,
                                    paddingTop = 0.dp
                                )
                            }
                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.briefcase),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.industry))
                            }
                            Box(modifier = Modifier.weight(3f)) {
                                AddEmployeeInputField(
                                    viewModel.industry,
                                    stringResource(Res.string.industry),
                                    placeholder = stringResource(Res.string.select_industry),
                                    dropdownItems = mapOf(),
                                    keyboardInputEnabled = true,
                                    topPadding = 0.dp
                                )
                            }
                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.city_icon_24),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.city))
                            }

                            Box(modifier = Modifier.weight(3f)) {
                                AppInputField(
                                    viewModel.contactCity,
                                    stringResource(Res.string.city),
                                    placeholder = stringResource(Res.string.city),
                                    paddingTop = 0.dp
                                )
                            }
                        }
                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.country_icon_24),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.country))
                            }

                            Box(modifier = Modifier.weight(3f)) {
                                AppInputField(
                                    viewModel.contactCountry,
                                    stringResource(Res.string.country),
                                    placeholder = stringResource(Res.string.country),
                                    paddingTop = 0.dp
                                )
                            }
                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.zip_icon),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.zip_employee))
                            }

                            Box(modifier = Modifier.weight(3f)) {
                                ZipInputField(
                                    viewModel.contactZip,
                                    stringResource(Res.string.zip_employee),
                                    placeholder = stringResource(Res.string.zip_employee),
                                    paddingTop = 0.dp
                                )
                            }
                        }

                        Spacer(modifier = Modifier.height(15.dp))
                        if (viewModel.isCheckedContactPerson.value) {
                            Divider(
                                modifier = Modifier.height(1.dp).fillMaxWidth()
                                    .background(Color.LightGray.copy(alpha = 0.1f))
                                    .padding(vertical = 10.dp)
                            )

                            Row(
                                modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Row(
                                    verticalAlignment = Alignment.CenterVertically,
                                    modifier = Modifier.weight(1f)
                                ) {
                                    Icon(
                                        painter = painterResource(Res.drawable.user),
                                        "icon"
                                    )
                                    Spacer(modifier = Modifier.width(4.dp))
                                    Text(stringResource(Res.string.contact_name))
                                }
                                Box(modifier = Modifier.weight(3f)) {
                                    AppInputField(
                                        viewModel.contactPersonName,
                                        stringResource(Res.string.contact_name),
                                        placeholder = stringResource(Res.string.enter_contact_name),
                                        paddingTop = 0.dp
                                    )
                                }
                            }
                            Row(
                                modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Row(
                                    verticalAlignment = Alignment.CenterVertically,
                                    modifier = Modifier.weight(1f)
                                ) {
                                    Icon(
                                        painter = painterResource(Res.drawable.email_send_icon_24),
                                        "icon"
                                    )
                                    Spacer(modifier = Modifier.width(4.dp))
                                    Text(stringResource(Res.string.email_address))
                                }
                                Box(modifier = Modifier.weight(3f)) {
                                    AppInputField(
                                        viewModel.contactPersonEmail,
                                        stringResource(Res.string.email_address),
                                        placeholder = stringResource(Res.string.enter_email_address),
                                        paddingTop = 0.dp
                                    )
                                }
                            }
                            Row(
                                modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Row(
                                    verticalAlignment = Alignment.CenterVertically,
                                    modifier = Modifier.weight(1f)
                                ) {
                                    Icon(
                                        painter = painterResource(Res.drawable.phone_24),
                                        "icon"
                                    )
                                    Spacer(modifier = Modifier.width(4.dp))
                                    Text(stringResource(Res.string.phone))
                                }
                                Box(modifier = Modifier.weight(3f)) {
                                    PhoneInputField(
                                        viewModel.contactPersonPhone,
                                        stringResource(Res.string.phone),
                                        placeholder = "+123456789",
                                        paddingTop = 0.dp
                                    )
                                }
                            }
                        }
                        Spacer(modifier = Modifier.height(25.dp))

                        FilledButton(
                            onClick = {
                                viewModel.showLoader.value = true
                                if (contactId == DEFAULT_CONTACT_ID || contactId == "") {
                                    viewModel.addContact(onSuccess = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpSuccess.value = true
                                    }, onError = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpError.value = true
                                    })
                                } else {
                                    viewModel.updateContact(onSuccess = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpSuccess.value = true
                                    }, onError = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpError.value = true
                                    })
                                }
                            },
                            text = if (contactId == DEFAULT_CONTACT_ID || contactId == "") stringResource(
                                Res.string.add
                            ) else stringResource(
                                Res.string.save_changes
                            ),
                            isEnabled = true
                        )
                        Spacer(modifier = Modifier.height(25.dp))
                        if (viewModel.showPopUpSuccess.value) {
                            PopUpDialog(onDismiss = {
                                viewModel.showPopUpSuccess.value = false
                                navHostController.popBackStack()
                            },
                                title = stringResource(Res.string.success).uppercase(),
                                message = if (contactId == DEFAULT_CONTACT_ID) stringResource(Res.string.contact_added_successfully) else stringResource(
                                    Res.string.contact_updated_successfully
                                ),
                                positiveBtnText = stringResource(Res.string.ok),
                                onPositiveBtnClicked = {
                                    viewModel.showPopUpSuccess.value = false
                                    navHostController.popBackStack()
                                })
                        }
                        if (viewModel.showPopUpSuccessDeleted.value) {
                            PopUpDialog(onDismiss = {
                                viewModel.showPopUpSuccessDeleted.value = false
                                navHostController.popBackStack()
                            },
                                title = stringResource(Res.string.success).uppercase(),
                                message = stringResource(Res.string.contact_deleted),
                                positiveBtnText = stringResource(Res.string.ok),
                                onPositiveBtnClicked = {
                                    viewModel.showPopUpSuccessDeleted.value = false
                                    navHostController.popBackStack()
                                })
                        }
                        if (viewModel.showPopUpError.value) {
                            ErrorDialog(
                                onDismiss = { viewModel.showPopUpError.value = false },
                                title = stringResource(Res.string.error).uppercase(),
                                message = viewModel.errorText.value
                            )
                        }
                        if (viewModel.showLoader.value) {
                            FullScreenProgressBar()
                        }
                    }
                }
                val offset =
                    if (contactId?.isNotEmpty() == true && contactId != DEFAULT_CONTACT_ID) {
                        (-10).dp
                    } else {
                        0.dp
                    }
                val padding =
                    if (contactId?.isNotEmpty() == true && contactId != DEFAULT_CONTACT_ID) {
                        0.dp
                    } else {
                        20.dp
                    }
                Column(
                    modifier = Modifier.width(Dp(400f)).height(Dp(800f)).offset(y = offset)
                        .padding(top = padding)
                ) {
                    if (contactId?.isNotEmpty() == true && contactId != DEFAULT_CONTACT_ID) {
                        Text(
                            text = stringResource(Res.string.delete_contact),
                            color = Color.Red,
                            fontSize = 18.sp,
                            modifier = Modifier.align(Alignment.End).padding(bottom = 10.dp)
                                .clickable {
                                    viewModel.showLoader.value = true
                                    viewModel.deleteContact(contactId, onSuccess = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpSuccessDeleted.value = true
                                    }, onError = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpError.value = true
                                    })
                                }
                        )
                    }
                    MapComponent(
                        listOf(Marker(contact.value?.addressObject?.lat ?: 0.0, contact.value?.addressObject?.long ?: 0.0, "Nikola")), mutableStateOf(
                            LatLng(0.0, 0.0)
                        )
                    )
                }
            }
        }
    }
}