import androidx.compose.material.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Container
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.navigation.NavGraph
import org.jetbrains.compose.ui.tooling.preview.Preview
import com.hyperether.goodjob.repository.Repository

@Composable
@Preview
fun App(navController: NavHostController, repository: Repository) {
    MaterialTheme {
        if(getPlatformChecker().isMobile()) {
            Container(navController, content = {}, repository)
        } else {
            NavGraph(navController, repository, modifier = Modifier)
        }
    }
}