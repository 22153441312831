package com.hyperether.goodjob.util

import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

object UploadManager {
    private val _fileNames = mutableStateOf(listOf<String?>())
    private val _fileIds = mutableStateOf(listOf<String?>())

    val fileNames: State<List<String?>> get() = _fileNames
    val fileIds: State<List<String?>> get() = _fileIds

    private val _documentStatus = mutableStateOf("")
    private val _signatureStatus = mutableStateOf("")
    private val _fileName = mutableStateOf("")
    private val _clientSignatureFileName = mutableStateOf("")
    private val _fileId = mutableStateOf("")
    private val _clientSignature = mutableStateOf("")

    val documentStatus: State<String> get() = _documentStatus
    val signatureStatus: State<String> get() = _signatureStatus
    val fileName: State<String> get() = _fileName
    val fileId: State<String> get() = _fileId
    //TODO use for completion report request
    val clientSignature: State<String> get() = _clientSignature
    val clientSignatureFileName: State<String> get() = _clientSignatureFileName

    private val _showDilaog = mutableStateOf(false)
    val showDilaog: State<Boolean> get() = _showDilaog

    private val _imageSelected = MutableStateFlow<Boolean>(false)
    val imageSelected: StateFlow<Boolean> get() = _imageSelected

    private val _isSignatureIos = MutableStateFlow<Boolean>(false)
    val isSignatureIos: StateFlow<Boolean> get() = _isSignatureIos

    private val _imageByteArray = MutableStateFlow<ByteArray?>(null)
    val imageByteArray: StateFlow<ByteArray?> get() = _imageByteArray

    private val _iosFileName = MutableStateFlow<String?>(null)
    val iosFileName: StateFlow<String?> get() = _iosFileName

    fun setIosFileName(fileName: String) {
        _iosFileName.value = fileName
    }

    fun setImage(byteArray: ByteArray) {
        _imageByteArray.value = byteArray
    }

    fun setFileName(name: String) {
        _fileName.value = name
    }

    fun setDocumentStatus(status: String) {
        _documentStatus.value = status
    }

    fun setSignatureStatus(status: String) {
        _signatureStatus.value = status
    }

    fun setFileId(id: String) {
        _fileId.value = id
    }

    fun setClientSignature(signature: String) {
        _clientSignature.value = signature
    }

    fun setClientSignatureFileName(name: String) {
        _clientSignatureFileName.value = name
    }

    fun addFile(name: String) {
        _fileNames.value += name
    }

    fun addFileId(id: String) {
        _fileIds.value += id
    }

    fun setShowDialog(value: Boolean) {
        _showDilaog.value = value
    }

    fun setImageSelected(value: Boolean) {
        _imageSelected.value = value
    }

    fun setIsSignatureIos(value: Boolean) {
        _isSignatureIos.value = value
    }

    fun removeFileByName(fileName: String) {
        val nameIndex = _fileNames.value.indexOf(fileName)
        val idIndex = _fileIds.value.indexOf(fileName)
        if (nameIndex != -1) {
            _fileNames.value = _fileNames.value.toMutableList().apply { removeAt(nameIndex) }
        }
        if (idIndex != -1) {
            _fileIds.value = _fileIds.value.toMutableList().apply { removeAt(idIndex) }
        }
    }

    fun removeAll() {
        _fileIds.value = emptyList()
        _fileNames.value = emptyList()
    }
}