package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.util.Constants
import com.hyperether.goodjob.util.UploadManager
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging

class AddNewViewModel(val repository: Repository) : ViewModel() {

    val log = logging("AddNewViewModel")

    private val _users = MutableStateFlow<List<User>>(emptyList())
    val users: StateFlow<List<User>> = _users

    private val _contacts = MutableStateFlow<List<Contact>>(emptyList())
    val contacts: StateFlow<List<Contact>> = _contacts

    private val _sites = MutableStateFlow<List<Site>>(emptyList())
    val sites: StateFlow<List<Site>> = _sites

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)

    init {
        if (getPlatformChecker().isMobile()) {
            log.d("getContactsFlow AddNewJobScreen") { "init isMobile: ${getPlatformChecker().isMobile()}" }
          init()
        }
    }

    fun init() {
        viewModelScope.launch {
            repository.getTeams()
        }

        viewModelScope.launch {
            repository.getContactsFlow().collect {
                _contacts.value = it
            }
        }

        viewModelScope.launch {
            repository.getUsersFlow().collect {
                _users.value = it
            }
        }

        viewModelScope.launch {
            repository.getSitesFlow().collect {
                _sites.value = it
            }
        }
    }

    fun uploadFile(fileName: String, byteArray: ByteArray, isSignature: Boolean) {
        showLoader.value = true
        viewModelScope.launch {
            val result = repository.uploadFile(byteArray, fileName)
            if (isSignature) {
                UploadManager.setSignatureStatus(Constants.DOCUMENT_LOADING)
            } else {
                UploadManager.setDocumentStatus(Constants.DOCUMENT_LOADING)
            }
            when (result) {
                is Resource.Success -> {
                    if (isSignature) {
                        result.data?.fileId?.let { UploadManager.setClientSignature(it) }
                        UploadManager.setSignatureStatus(Constants.DOCUMENT_UPLOADED)
                    } else {
                        UploadManager.setDocumentStatus(Constants.DOCUMENT_UPLOADED)
                        result?.data?.fileId?.let { UploadManager.addFileId(it) }
                    }
                    println(result)
                    showLoader.value = false
                }

                is Resource.Error -> {
                    showLoader.value = false
                    println(result)
                    UploadManager.setDocumentStatus(Constants.DOCUMENT_UPLOAD_ERROR)
                    UploadManager.setSignatureStatus(Constants.DOCUMENT_UPLOAD_ERROR)
                }
            }
        }
    }
}