package com.hyperether.goodjob.scenes.contacts

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Place
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging

class ContactsViewModel(
    val repository: Repository,
) : ViewModel() {

    val log = logging("ContactsViewModel")

    val showEditPopUp: MutableState<Boolean> = mutableStateOf(false)
    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpSuccessDeleted: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")
    val successApiText: MutableState<String> = mutableStateOf("")
    val siteNameInput: MutableState<String> = mutableStateOf("")
    val contactNameInput: MutableState<String> = mutableStateOf("")
    val addressSiteInput: MutableState<String> = mutableStateOf("")
    val phoneSiteInput: MutableState<String> = mutableStateOf("")
    val siteEmailInput: MutableState<String> = mutableStateOf("")
    val siteContactNameInput: MutableState<String> = mutableStateOf("")
    val siteAddressInput: MutableState<String> = mutableStateOf("")
    var place: MutableState<Place?> = mutableStateOf(null)
    val sitePhoneInput: MutableState<String> = mutableStateOf("")

    val currentSitesForContact = mutableStateListOf<Site>()

    val sitesMap = mutableStateMapOf<String, Site>()

    private val _contacts = MutableStateFlow<List<Contact>>(emptyList())
    val contacts: StateFlow<List<Contact>> = _contacts

    //web states
    val contactList = mutableStateListOf<Contact>()
    val remoteContact = mutableStateOf<Contact?>(null)
    val jobList = mutableStateListOf<Job>()
    val remoteSite = mutableStateOf<Site?>(null)


    private val _sites = MutableStateFlow<List<Site>>(emptyList())
    val sites: StateFlow<List<Site>> = _sites

    private val _site = MutableStateFlow<Site?>(null)
    val site: StateFlow<Site?> get() = _site
    private val _contact = MutableStateFlow<Contact?>(null)
    val contact: StateFlow<Contact?> get() = _contact

    val showFilterDialog = mutableStateOf(false)

    val selectedContacts = mutableStateListOf<Contact>()
    var selectedCities = mutableStateOf(setOf<String>())
    var selectedIndustries = mutableStateOf(setOf<String>())
    var selectedContactTypes = mutableStateOf(setOf<String>())

    private val _jobs = MutableStateFlow<List<Job>>(emptyList())
    val jobs: StateFlow<List<Job>> = _jobs

    var places = mutableStateListOf<Place>()
    var contactTypeMap: Map<String, String> = mutableMapOf()

    init {
        if (getPlatformChecker().isMobile()) {
            log.d("getContactsFlow ContactsViewModel") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            init()
        }
    }

    fun init() {
        //if (getPlatformChecker().isMobile()) {
        getContactsByWorkspaceId()
        // }
        getSites()

        viewModelScope.launch {
            repository.getContactsFlow().collect { newContacts ->
                _contacts.value = newContacts
                selectedContacts.clear()
                selectedContacts.addAll(newContacts.distinctBy { it.id })
            }
        }
        viewModelScope.launch {
            repository.getJobsFlow()?.collect {
                if (it.isNotEmpty()) {
                    val siteMap = mutableMapOf<String, Site>()
                    it.forEach { job ->
                        job.contactSiteId?.let {
                            val site = repository.getSiteById(it)
                            site?.id?.let { id -> siteMap[job.id!!] = site }
                        }
                    }
                    jobList.clear()
                    jobList.addAll(it)
                    sitesMap.clear()
                    sitesMap.putAll(siteMap)
                }
            }
        }
    }

    private suspend fun getSiteById(id: String): Site? {
        return repository.getSiteById(id)
    }

    fun fetchSiteById(id: String) {
        viewModelScope.launch {
            _site.value = getSiteById(id)
            remoteSite.value = getSiteById(id)
        }
    }

    fun fetchContactById(id: String) {
        viewModelScope.launch {
            _contact.value = getContactById(id)
            remoteContact.value = getContactById(id)
        }
    }

    fun getSites() {
        viewModelScope.launch {
            repository.getSitesFlow().collect { siteList ->
                _sites.value = siteList
            }
        }
    }

    fun fetchJobsByParentId(parentId: String) {
        viewModelScope.launch {
            repository.getJobsByContactIdFlow(parentId).collect {
                _jobs.value = it
            }
        }
    }

    fun addSite(site: Site) {
        viewModelScope.launch {
            when (val result = repository.addSite(site)) {
                is Resource.Success -> {
                    showLoader.value = false
                    showPopUpSuccess.value = true
                    successApiText.value = "Site added successfully"
                    println("Site added successfully")
                }

                is Resource.Error -> {
                    showLoader.value = false
                    showPopUpError.value = true
                    errorText.value = result.text ?: "An unknown error occurred"
                    println("Error while adding site")
                }
            }
        }
    }

    fun updateSite(siteId: String, site: Site, onSuccess: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch {
            when (val result = repository.updateSite(siteId, site)) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    onError(result.text ?: "An unknown error occurred")
                    errorText.value = result.text ?: "An unknown error occurred"
                }
            }
        }
    }

    fun clearSiteValues() {
        siteNameInput.value = ""
        siteEmailInput.value = ""
        siteContactNameInput.value = ""
        siteAddressInput.value = ""
        sitePhoneInput.value = ""

    }

    fun getSitesUnderContact(siteRequest: SiteRequest) {
        viewModelScope.launch {
            val result = repository.getSitesUnderContact(siteRequest)
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                currentSitesForContact.clear()
                currentSitesForContact.addAll(result.data ?: emptyList())
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }
    }

    fun deleteSelectedContacts(
        selectedContacts: List<Contact>,
        onAllDeleted: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            try {
                selectedContacts.forEachIndexed { index, job ->
                    val isLastJob = index == selectedContacts.size - 1

                    job.id?.let { id ->
                        val result = repository.deleteContactById(id)
                        if (result is Resource.Success && isLastJob) {
                            onAllDeleted()
                        } else if (result is Resource.Error) {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError(id)
                            return@launch
                        }
                    }
                }
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }

    fun deleteSite(
        id: String,
        onSuccess: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            try {
                val result = repository.deleteSiteById(id)
                if (result is Resource.Success) {
                    _sites.value = _sites.value.filter { it.id != id }
                    onSuccess()
                } else if (result is Resource.Error) {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError(errorText.value)
                    return@launch
                }
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun getContactById(id: String): Contact? {
        return repository.getContactById(id)
    }

    fun filterContacts() {
        selectedContacts.clear()
        if (selectedCities.value.isEmpty() &&
            selectedIndustries.value.isEmpty() &&
            selectedContactTypes.value.isEmpty()
        ) {
            selectedContacts.addAll(contacts.value)
        } else {
            selectedContacts.addAll(contacts.value.filter { contact ->
                selectedCities.value.any { query ->
                    contact.addressObject?.city?.contains(query, ignoreCase = true) == true
                } || selectedIndustries.value.any { query ->
                    contact.industry?.contains(query, ignoreCase = true) == true
                } || selectedContactTypes.value.any { query ->
                    contact.contactType?.contains(query, ignoreCase = true) == true
                }
            })
        }
    }

    fun getContactsByWorkspaceId() {
        viewModelScope.launch {
            val result = repository.getContactsByWorkspaceId()
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                contactList.clear()
                result.data?.let { contactList.addAll(it) }
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }
    }

    fun findPlace(name: String) {
        viewModelScope.launch {
            val list = repository.findPLace(name)
            places.clear()
            places.addAll(list?.toList() ?: emptyList())
        }
    }

}