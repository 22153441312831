package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.models.AddressObject
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Place
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class AddContactViewModel(val repository: Repository) : ViewModel() {

    private val _selectedContactToEdit = MutableStateFlow<Contact?>(null)
    val selectedContactToEdit: StateFlow<Contact?> = _selectedContactToEdit

    val sites = mutableStateListOf<Site>()
    val contactWeb = mutableStateOf<Contact?>(null)

    val isCheckedContactPerson: MutableState<Boolean> = mutableStateOf(false)
    val contactType: MutableState<String> = mutableStateOf("")
    val industry: MutableState<String> = mutableStateOf("")
    val contactAddress: MutableState<String> = mutableStateOf("")
    var place: Place? = null
    val contactNameNewContact: MutableState<String> = mutableStateOf("")
    val contactZip: MutableState<String> = mutableStateOf("")
    val contactCity: MutableState<String> = mutableStateOf("")
    val contactCountry: MutableState<String> = mutableStateOf("")
    val phoneNewContact: MutableState<String> = mutableStateOf("")
    val emailNewContact: MutableState<String> = mutableStateOf("")
    val contactPersonName: MutableState<String> = mutableStateOf("")
    val contactPersonEmail: MutableState<String> = mutableStateOf("")
    val contactPersonPhone: MutableState<String> = mutableStateOf("")
    val selectedContact: MutableState<String> = mutableStateOf("")

    var places = mutableStateListOf<Place>()

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpSuccessDeleted: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")

    val steps = mutableStateListOf(StepData())
    val showErrorDialog = mutableStateOf(false)

    var contactTypeMap: Map<String, String> = mutableMapOf()

    init {
    }

    fun getSites() {
        CoroutineScope(Dispatchers.Default).launch {
            repository.getSitesFlow().collect {
                sites.clear()
                sites.addAll(it)
            }
        }
    }

    fun clearContactValues() {
        isCheckedContactPerson.value = false
        contactType.value = ""
        industry.value = ""
        contactAddress.value = ""
        contactNameNewContact.value = ""
        contactZip.value = ""
        contactCity.value = ""
        contactCountry.value = ""
        phoneNewContact.value = ""
        emailNewContact.value = ""
        contactPersonName.value = ""
        contactPersonEmail.value = ""
        contactPersonPhone.value = ""
        selectedContact.value = ""
        place = null
    }

    fun addContact(onSuccess: () -> Unit, onError: () -> Unit) {

        val errorMessage = when {
            contactNameNewContact.value.isBlank() -> "Contact name is required."
            contactType.value.isBlank() -> "Contact type is required."
            industry.value.isBlank() -> "Industry is required."
            contactAddress.value.isBlank() -> "Address is required."
            place == null -> "Address is not chosen."
            emailNewContact.value.isEmpty() -> "Email is required."
            contactCity.value.isBlank() -> "City is required."
            contactCountry.value.isBlank() -> "Country is required."
            contactZip.value.isBlank() -> "Zip code is required."
            phoneNewContact.value.isBlank() -> "Phone is required."
            contactPersonName.value.isBlank() && isCheckedContactPerson.value -> "Contact person name is required."
            contactPersonEmail.value.isBlank() && isCheckedContactPerson.value -> "Contact person email is required."
            contactPersonPhone.value.isBlank() && isCheckedContactPerson.value -> "Contact person phone is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return
        }

        val contact = Contact(
            name = contactNameNewContact.value,
            contactType = contactTypeMap[contactType.value],
            industry = industry.value,
            addressObject = AddressObject(
                address = contactAddress.value,
                city = contactCity.value,
                country = contactCountry.value,
                zip = contactZip.value,
                lat = place?.location?.latitude,
                long = place?.location?.longitude
            ),
            email = emailNewContact.value,
            phone = phoneNewContact.value,
            personName = if (isCheckedContactPerson.value) contactPersonName.value else contactNameNewContact.value,
//            personAddress = if (isCheckedContactPerson.value) emailNewContact.value else contactPersonEmail.value,
            personEmail = if (isCheckedContactPerson.value) contactPersonEmail.value else emailNewContact.value,
            personPhone = if (isCheckedContactPerson.value) contactPersonPhone.value else phoneNewContact.value,
            workspaceId = repository.getWorkspaceId()
        )
        viewModelScope.launch {
            val result = repository.addContact(contact)

            when (result) {
                is Resource.Success -> {
                    result.data?.id?.let { addDefaultSite(it) }
                    clearContactValues()
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun updateContact(onSuccess: () -> Unit, onError: () -> Unit) {

        val errorMessage = when {
            contactNameNewContact.value.isBlank() -> "Contact name is required."
            contactType.value.isBlank() -> "Contact type is required."
            industry.value.isBlank() -> "Industry is required."
            contactAddress.value.isBlank() -> "Address is required."
            emailNewContact.value.isEmpty() -> "Email is required."
            contactCity.value.isBlank() -> "City is required."
            contactCountry.value.isBlank() -> "Country is required."
            contactZip.value.isBlank() -> "Zip code is required."
            phoneNewContact.value.isBlank() -> "Phone is required."
            contactPersonName.value.isBlank() && !isCheckedContactPerson.value -> "Contact person name is required."
            contactPersonEmail.value.isBlank() && !isCheckedContactPerson.value -> "Contact person email is required."
            contactPersonPhone.value.isBlank() && !isCheckedContactPerson.value -> "Contact person phone is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return
        }

        val contact = Contact(
            name = contactNameNewContact.value,
            contactType = contactTypeMap[contactType.value],
            industry = industry.value,
            addressObject = AddressObject(
                address = contactAddress.value,
                city = contactCity.value,
                country = contactCountry.value,
                zip = contactZip.value,
            ),
            email = emailNewContact.value,
            phone = phoneNewContact.value,
            personName = if (isCheckedContactPerson.value) contactNameNewContact.value else contactPersonName.value,
            personAddress = if (isCheckedContactPerson.value) contactAddress.value else contactAddress.value,
            personEmail = if (isCheckedContactPerson.value) emailNewContact.value else contactPersonEmail.value,
            personPhone = if (isCheckedContactPerson.value) phoneNewContact.value else contactPersonPhone.value,
            workspaceId = repository.getWorkspaceId()
        )
        viewModelScope.launch {
            if (selectedContactToEdit.value != null) {
                selectedContactToEdit.value?.id?.let { id ->
                    when (val result = repository.updateContact(id, contact.toContactRequest())) {
                        is Resource.Success -> {
                            if (sites.none { it.parentId == id }) {
                                result.data?.id?.let { addDefaultSite(it) }
                            }
                            clearContactValues()
                            onSuccess()
                        }

                        is Resource.Error -> {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError()
                        }
                    }
                }
            } else {
                contactWeb.value?.id?.let { id ->
                    when (val result = repository.updateContact(id, contact.toContactRequest())) {
                        is Resource.Success -> {
                            if (sites.none { it.parentId == id }) {
                                result.data?.id?.let { addDefaultSite(it) }
                            }
                            clearContactValues()
                            onSuccess()
                        }

                        is Resource.Error -> {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError()
                        }
                    }
                }
            }
        }
    }

    suspend fun addDefaultSite(id: String) {
        val site = Site(
            parentId = id,
            siteName = contactNameNewContact.value,
            personName = if (isCheckedContactPerson.value) contactNameNewContact.value else contactPersonName.value,
            personEmail = if (isCheckedContactPerson.value) emailNewContact.value else contactPersonEmail.value,
            personPhone = if (isCheckedContactPerson.value) phoneNewContact.value else contactPersonPhone.value,
            parentName = contactNameNewContact.value,
            addressObject = AddressObject(
                address = contactAddress.value,
                lat = place?.location?.latitude,
                long = place?.location?.longitude
            )
        )
        when (val result = repository.addSite(site)) {
            is Resource.Success -> {
                println("Site added successfully")
            }

            is Resource.Error -> {
                errorText.value = result.text ?: "An unknown error occurred"
                println("Error while adding site")
            }
        }
    }

    fun findPlace(name: String) {
        viewModelScope.launch {
            val list = repository.findPLace(name)
            places.clear()
            places.addAll(list?.toList() ?: emptyList())
        }
    }

    fun getContactById(contactId: String) {
        viewModelScope.launch {
            repository.getContactsFlow().collect { contacts ->
                _selectedContactToEdit.value = contacts.firstOrNull { it.id == contactId }
            }
        }
    }

    fun fetchWebContactById(id: String) {
        viewModelScope.launch {
            contactWeb.value = repository.getContactById(id)
        }
    }

    fun deleteContact(
        id: String,
        onSuccess: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            val result = repository.deleteContactById(id)
            if (result is Resource.Success) {
                onSuccess()
            } else if (result is Resource.Error) {
                errorText.value = result.text ?: "An unknown error occurred"
                onError(id)
                return@launch
            }
        }


    }

}