package com.hyperether.goodjob.managers

import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.compose.ui.graphics.toComposeImageBitmap
import org.jetbrains.skia.Data
import org.jetbrains.skia.Image

actual class FileManager {
    actual fun saveFile(fileName: String, data: ByteArray): String {
        return ""
    }

    actual fun getFilePath(fileName: String): String? {
      return null
    }

    actual fun getBitmap(fileName: String): BitmapPainter? {
        return null
    }

    actual fun deleteFile(fileName: String): Boolean {
      return true
    }

    actual fun getBitmapFromByteArray(byteArray: ByteArray): BitmapPainter? {
        val skiaData = Data.makeFromBytes(byteArray)
        val skiaImage = Image.makeFromEncoded(skiaData.bytes)
        return BitmapPainter(skiaImage.toComposeImageBitmap())
    }
}