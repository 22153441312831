package com.hyperether.goodjob.scenes.addNew.components

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.painterResource

@Composable
fun AddEmployeeMultiSelectField(
    selectedItems: MutableState<List<String>>,
    onItemSelected: (List<String>) -> Unit,
    label: String,
    placeholder: String,
    dropdownItems: MutableState<List<String>>,
    preselectedItems: MutableState<List<String>>,
    onAddNewValue: (String) -> Unit
) {
    val expanded = remember { mutableStateOf(false) }
    val newItemText = remember { mutableStateOf("") }
    val focusManager = LocalFocusManager.current
    val focusRequester = FocusRequester()

    LaunchedEffect(preselectedItems.value) {
        preselectedItems.value.forEach { preselectedItem ->
            if (preselectedItem in dropdownItems.value && preselectedItem !in selectedItems.value) {
                selectedItems.value += preselectedItem
                onItemSelected(selectedItems.value)
            }
        }
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        Column {
            OutlinedTextField(
                value = selectedItems.value.joinToString(", ") + newItemText.value,
                onValueChange = {
                    newItemText.value = it
                },
                label = { Text(label, fontSize = 14.sp, color = TextColor) },
                placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
                modifier = Modifier
                    .fillMaxWidth(),
                trailingIcon = {
                    Icon(
                        painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                        contentDescription = "Dropdown",
                        modifier = Modifier.clickable { expanded.value = !expanded.value }
                    )
                },
                readOnly = selectedItems.value.isNotEmpty(),
                keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Done),
                keyboardActions = KeyboardActions(
                    onDone = {
                        if (newItemText.value.isNotBlank()) {
                            val newItem = newItemText.value.trim()
                            // Add the new item to the dropdown list and select it
                            if (newItem !in dropdownItems.value) {
                                dropdownItems.value += newItem
                            }
                            selectedItems.value += newItem
                            newItemText.value = ""

                            onAddNewValue(newItem)
                            focusManager.moveFocus(FocusDirection.Next)
                        }
                    }
                )
            )
        }

        // Dropdown menu for skills selection
        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.value.forEach { item ->
                DropdownMenuItem(onClick = {
                    if (item !in selectedItems.value) {
                        selectedItems.value += item
                    }
                    expanded.value = false
                }, text = {
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Checkbox(
                            checked = item in selectedItems.value,
                            onCheckedChange = { isChecked ->
                                selectedItems.value = if (isChecked) {
                                    selectedItems.value + item
                                } else {
                                    selectedItems.value - item
                                }
                                onItemSelected(selectedItems.value)
                            },
                            colors = CheckboxDefaults.colors(
                                checkedColor = BlueApp,
                                uncheckedColor = com.hyperether.goodjob.theme.Divider
                            )
                        )
                        Text(item, fontSize = 14.sp)
                    }
                })
            }
        }
    }
}