package com.hyperether.goodjob.scenes.jobs.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.assigned_to
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.scenes.components.CircleWithInitial
import com.hyperether.goodjob.scenes.components.CircleWithInitialUser
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobDetailsAssigneesView(job: Job?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 16.dp, end = 16.dp, top = 16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
            ) {
                Text(
                    fontSize = 18.sp,
                    fontWeight = FontWeight.W700,
                    text = stringResource(Res.string.assigned_to),
                )
            }

            val users = job?.assignees?.employees
            if (users?.isNotEmpty() == true) {
                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        fontSize = 14.sp,
                        text = stringResource(Res.string.employees),
                        fontWeight = FontWeight.W500
                    )
                }

                users.forEach { user ->
                    Spacer(modifier = Modifier.height(8.dp))
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        CircleWithInitialUser(user)
                        Spacer(modifier = Modifier.width(8.dp))
                        Text(
                            fontSize = 14.sp,
                            text = user?.getName().orEmpty(),
                            fontWeight = FontWeight.W500
                        )
                    }
                }
            }

            val teams = job?.assignees?.teams
            if (teams?.isNotEmpty() == true) {
                Spacer(modifier = Modifier.height(8.dp))
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        fontSize = 14.sp,
                        text = stringResource(Res.string.teams),
                        fontWeight = FontWeight.W500
                    )
                }

                teams.forEach { team ->
                    Spacer(modifier = Modifier.height(8.dp))
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        CircleWithInitial(team?.name)
                        Spacer(modifier = Modifier.width(8.dp))
                        Text(
                            fontSize = 14.sp,
                            text = team?.name ?: "",
                            fontWeight = FontWeight.W500
                        )
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun JobDetailsAssigneesViewWeb(job: Job?) {
    val users = job?.assignees?.employees
    if (users?.isNotEmpty() == true) {
        Spacer(modifier = Modifier.height(Dp(8f)))

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                fontSize = Sp(18f),
                text = stringResource(Res.string.employees),
                fontWeight = FontWeight.Bold
            )
        }

        FlowRow(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.spacedBy(Dp(24f))
        ) {
            users.forEach { employee ->
                employee?.let {
                    Row(
                        modifier = Modifier
                            .padding(Dp(4f)),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        CircleWithInitial(name = it.getName())
                        Spacer(modifier = Modifier.width(Dp(8f)))
                        Text(
                            text = it.getName(),
                            fontSize = Sp(16f),
                            fontWeight = FontWeight.Bold
                        )
                    }
                }
            }
        }
    }

    val teams = job?.assignees?.teams
    if (teams?.isNotEmpty() == true) {
        Spacer(modifier = Modifier.height(Dp(8f)))

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                fontSize = Sp(18f),
                text = stringResource(Res.string.teams),
                fontWeight = FontWeight.Bold
            )
        }

        teams.forEach { team ->
            Spacer(modifier = Modifier.height(Dp(8f)))
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                team?.let {
                    CircleWithInitial(name = it.name)
                    Spacer(modifier = Modifier.width(Dp(8f)))
                    Text(text = it.name ?: "", fontSize = Sp(16f), fontWeight = FontWeight.Bold)
                }
            }
        }
    }
}