package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import com.hyperether.goodjob.repository.remote.model.DayHours
import org.jetbrains.compose.resources.StringResource

data class DaySchedule(
    val dayName: StringResource,
    val isChecked: MutableState<Boolean>,
    val startTime: MutableState<String>,
    val endTime: MutableState<String>,
    val isStartTimePickerVisible: MutableState<Boolean>,
    val isEndTimePickerVisible: MutableState<Boolean>
) {
    fun toDayHours(): DayHours {
        return DayHours(
            enabled = isChecked.value,
            from = if (isChecked.value) startTime.value else null,
            to = if (isChecked.value) endTime.value else null
        )
    }

}