package com.hyperether.goodjob.scenes.employees

import androidx.compose.animation.core.tween
import androidx.compose.animation.expandHorizontally
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInHorizontally
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.text.selection.TextSelectionColors
import androidx.compose.material.Card
import androidx.compose.material.Checkbox
import androidx.compose.material.CheckboxDefaults
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.remote.model.WorkingHours
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_team
import com.hyperether.goodjob.resources.contact_information
import com.hyperether.goodjob.resources.create_your_first_team
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.employment_details
import com.hyperether.goodjob.resources.leave_details
import com.hyperether.goodjob.resources.no_hours_available
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.search_to_find
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.start_by_adding
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.scenes.components.CircleWithInitialUser
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.SubtitleColor
import com.hyperether.goodjob.util.DateTimeUtil.formatWorkingHoursText
import com.hyperether.goodjob.util.DateTimeUtil.groupContiguousWorkingHours
import com.hyperether.goodjob.util.UserStatusHelper
import com.hyperether.goodjob.util.UserStatusHelper.Companion.getStatusColorBasedOnConfirmStatus
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmployeesItem(
    isChecked: Boolean,
    employee: User,
    isInEditMode: Boolean,
    onEmployeeSelected: (User, Boolean) -> Unit,
    onItemClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .background(color = getStatusColorBasedOnConfirmStatus(employee.confirmStatus))
            .padding(top = 16.dp, bottom = 16.dp, end = 7.dp)
            .clickable {
                onItemClick()
            }
    ) {
        if (isInEditMode) {
            RoundedCornerCheckbox(
                modifier = Modifier.padding(start = 16.dp),
                isChecked = isChecked,
                onCheckedChange = { checked ->
                    onEmployeeSelected(employee, checked)
                }
            )
        }

        Spacer(modifier = Modifier.width(15.dp))
//        Image(
//            painter = painterResource(Res.drawable.baseline_person_24),
//            contentDescription = null,
//            modifier = Modifier
//                .size(40.dp)
//                .clip(CircleShape)
//                .background(Color.LightGray.copy(alpha = 0.3f))
//                .align(Alignment.Top)
//        )
        CircleWithInitialUser(employee, size = 40.dp)

        Spacer(modifier = Modifier.width(8.dp))

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 8.dp)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 8.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = employee.getName(),
                    fontSize = 16.sp,
                    fontWeight = FontWeight.Bold
                )
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 4.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = UserStatusHelper.getStatusText(employee.confirmStatus, employee.status),
                    fontSize = 12.sp,
                    color = Color.LightGray
                )
            }

            val team = employee.teams?.mapNotNull { it?.value }
            if (team?.isNotEmpty() == true) {
                Spacer(modifier = Modifier.height(4.dp))
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.team),
                        contentDescription = null,
                        modifier = Modifier.size(20.dp),
                        tint = Color.Gray
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    team.firstOrNull()?.let {
                        Text(
                            text = it,
                            fontSize = 14.sp,
                            color = Color.Gray
                        )
                    }
                }
            }

            if (employee.phone?.isNotEmpty() == true) {
                Spacer(modifier = Modifier.height(4.dp))
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.phone),
                        contentDescription = null,
                        modifier = Modifier.size(20.dp),
                        tint = Color.Gray
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    employee.phone?.let {
                        Text(
                            text = it,
                            fontSize = 14.sp,
                            color = Color.Gray
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun EditDialog(
    showDialog: Boolean,
    employees: List<User>,
    onDelete: () -> Unit,
    onExportList: () -> Unit,
    onImportList: () -> Unit
) {
    if (showDialog) {
        Card(
            modifier = Modifier
                .width(300.dp)
                .padding(horizontal = 16.dp, vertical = 28.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp)),
            elevation = 4.dp
        ) {
            Column() {
                Text(
                    text = "${employees.size} selected",
                    fontWeight = FontWeight.W400,
                    color = Color.Gray,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    Text(
                        text = stringResource(Res.string.delete),
                        fontSize = 14.sp,
                        color = Color.Red,
                        modifier = Modifier.clickable {
                            onDelete()
                        })
//                    Text(
//                        text = stringResource(Res.string.export_list),
//                        fontSize = 14.sp,
//                        color = BlueApp,
//                        modifier = Modifier.clickable {
//                            onExportList()
//                        })
//                    Text(
//                        text = stringResource(Res.string.import_list),
//                        fontSize = 14.sp,
//                        color = BlueApp,
//                        modifier = Modifier.clickable {
//                            onImportList()
//                        })
                }
            }
        }
    }
}

@Composable
fun RoundedCornerCheckbox(
    isChecked: Boolean,
    onCheckedChange: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
    size: Float = 24f,
    checkedColor: Color = BlueApp,
    uncheckedColor: Color = Color.Gray,
    checkmarkColor: Color = Color.White,
) {
    val density = LocalDensity.current
    val duration = 200

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .heightIn(48.dp)
            .clickable { onCheckedChange(!isChecked) }
    ) {
        Box(
            modifier = Modifier
                .size(size.dp)
                .border(
                    width = 1.5.dp,
                    color = if (isChecked) checkedColor else uncheckedColor,
                    shape = CircleShape
                ),
            contentAlignment = Alignment.Center
        ) {
            androidx.compose.animation.AnimatedVisibility(
                visible = isChecked,
                enter = slideInHorizontally(animationSpec = tween(duration)) {
                    with(density) { (size * -0.5).dp.roundToPx() }
                } + expandHorizontally(
                    expandFrom = Alignment.Start,
                    animationSpec = tween(duration)
                ),
                exit = fadeOut()
            ) {
                Box(
                    modifier = Modifier
                        .size(size.dp)
                        .background(color = checkedColor, shape = CircleShape),
                    contentAlignment = Alignment.Center
                ) {
                    androidx.compose.material3.Icon(
                        Icons.Default.Check,
                        contentDescription = null,
                        tint = checkmarkColor
                    )
                }
            }
        }
    }
}

@Composable
fun EmployeesList(
    employees: List<User>,
    selectedEmployees: MutableList<User>,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {
    val checkedStates = remember { mutableStateMapOf<String, Boolean>() }
    LazyColumn(
        modifier = Modifier.fillMaxSize()
    ) {
        if (employees.isNotEmpty()) {
            items(employees.takeIf { it.isNotEmpty() } ?: emptyList()) { employee ->
                EmployeesItem(
                    employee = employee,
                    isInEditMode = isInEditMode,
                    isChecked = checkedStates[employee._id] ?: false,
                    onEmployeeSelected = { selectedEmployee, isSelected ->
                        checkedStates[employee._id!!] = isSelected
                        if (isSelected) {
                            selectedEmployees.add(selectedEmployee)
                        } else {
                            selectedEmployees.remove(selectedEmployee)
                        }
                    },
                    onItemClick = {
                        if (getPlatformChecker().isMobile()) {
                            navHostController.navigate(Screen.EmployeeDetails.route + "/${employee._id}")
                        } else {
                            navHostController.navigate(Screen.EmployeeDetails.route + "?employeeId=${employee._id}")
                        }
                    }
                )
                Divider(
                    //modifier = Modifier.padding(),
                    color = Color.Gray,
                    thickness = 0.5.dp
                )
            }
        }
    }
    LaunchedEffect(selectedEmployees.isEmpty()) {
        if (selectedEmployees.isEmpty()) {
            checkedStates.clear()
        }
    }
}

@Composable
fun EmployeeHeaderView(user: User?, viewModel: EmployeesViewModel) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
//            Image(
//                painter = painterResource(Res.drawable.baseline_person_24),
//                contentDescription = "Rounded Image",
//                modifier = Modifier
//                    .size(50.dp)
//                    .clip(CircleShape)
//                    .background(Color.LightGray)
//
//            )
            CircleWithInitialUser(user, size = 50.dp)

            Spacer(modifier = Modifier.width(16.dp))

            Column(
                modifier = Modifier.weight(1f)
            ) {
                user?.getName()?.let {
                    Text(
                        text = it,
                        fontWeight = FontWeight.Bold,
                        fontSize = 14.sp
                    )
                }
                user?.role?.let {
                    Text(
                        text = viewModel.roleMap.entries.find { role -> role.value == it }?.key
                            ?: "",
                        color = Color.Gray,
                        fontSize = 14.sp
                    )
                }
            }

            Text(
                text = "",
                modifier = Modifier.align(Alignment.CenterVertically)
            )
        }
    }
}

@Composable
fun EmployeeDetailsView(user: User?) {
    val workingHours: WorkingHours? = user?.workingHours
    val workingHoursText = workingHours?.let {
        val groupedSchedule = groupContiguousWorkingHours(it)
        formatWorkingHoursText(groupedSchedule)
    } ?: stringResource(Res.string.no_hours_available)

    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.employment_details),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            Text(
                text = workingHoursText,
                fontSize = 14.sp,
                color = Color.Gray,
            )

            Text(
                text = buildAnnotatedString {

                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Gray,
                            fontSize = 14.sp
                        )
                    ) {
                        append(stringResource(Res.string.team) + ": ")
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black,
                            fontSize = 14.sp
                        )
                    ) {
                        val teams = user?.teams ?: emptyList()
                        for ((index, team) in teams.withIndex()) {
                            append(team?.value)
                            if (index != teams.size - 1) {
                                append(", ")
                            }
                        }
                    }
                }
            )
            Text(
                text = buildAnnotatedString {
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Gray,
                            fontSize = 14.sp
                        )
                    ) {
                        append(stringResource(Res.string.skills) + ": ")
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black,
                            fontSize = 14.sp
                        )
                    ) {
                        val skills =
                            user?.skills?.filter { it.isNotEmpty() }?.joinToString(", ") ?: " "
                        append(skills)
                    }
                }
            )
        }
    }
}

@Composable
fun EmployeeContactsView(
    isProfileView: Boolean = false,
    user: User?
) {
    Card(
        modifier = if (isProfileView) Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp) else Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = if (isProfileView) 2.dp else 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.contact_information),
                fontWeight = FontWeight.Bold,
                fontSize = if (isProfileView) 16.sp else 14.sp
            )
            user?.email?.let {
                Text(
                    text = it,
                    fontSize = 14.sp,
                    color = if (isProfileView) SubtitleColor else Color.Gray,
                )
            }
            user?.phone?.let {
                Text(
                    text = it,
                    color = if (isProfileView) SubtitleColor else Color.Gray,
                    fontSize = 14.sp
                )
            }
            user?.address?.let {
                Text(
                    text = it,
                    color = if (isProfileView) SubtitleColor else Color.Gray,
                    fontSize = 14.sp
                )
            }
        }
    }
}

@Composable
fun EmployeeLeaveDetails(user: User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.leave_details),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            Text(
                text = user?.upcomingLeave ?: "",
                fontSize = 14.sp,
                color = Color.Gray,
            )

        }
    }
}

@Composable
fun RowWithTextAndCheckbox(text: String, checkedValue: MutableState<Boolean>) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(35.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = text,
            fontSize = 14.sp,
            modifier = Modifier
                .weight(1f)
                .align(Alignment.CenterVertically)
        )

        Checkbox(
            checked = checkedValue.value,
            onCheckedChange = {
                checkedValue.value = it
            },
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = Color.Gray,
                checkmarkColor = Color.White
            ),
            modifier = Modifier.align(Alignment.CenterVertically)
        )
    }
}

@Composable
fun EmptyTeamsView(showAddTeam: () -> Unit) {

    Column(
        modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 50.dp).fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            text = stringResource(Res.string.create_your_first_team),
            fontSize = 24.sp,
            color = Color.Black,
            fontWeight = FontWeight.Bold,
            textAlign = TextAlign.Center

        )
        Spacer(modifier = Modifier.height(15.dp))
        Text(
            text = stringResource(Res.string.start_by_adding),
            fontSize = 16.sp,
            textAlign = TextAlign.Center
        )
        Spacer(modifier = Modifier.height(15.dp))
        FilledButton(
            onClick = {
                showAddTeam()
            },
            text = stringResource(Res.string.add_team),
            isEnabled = true
        )

    }
}

@Composable
fun TeamsView(
    teams: List<Team>,
    selectedTeams: MutableState<Map<String, Boolean>>,
    onCheckedChange: (String, Boolean) -> Unit,
    viewModel: EmployeesViewModel
) {

    var searchQuery by remember { mutableStateOf("") }
    val filteredTeams = if (searchQuery.isNotBlank()) {
        teams.filter { team ->
            team.name?.contains(searchQuery, ignoreCase = true) == true
        }
    } else {
        teams
    }
    val text = if (teams.isEmpty()) {
        stringResource(Res.string.add_team)
    } else {
        stringResource(Res.string.search_to_find)
    }

    Column(
        modifier = Modifier.fillMaxSize()
    ) {
        TextField(
            value = searchQuery,
            onValueChange = { searchQuery = it },
            placeholder = {
                Text(
                    text,
                    color = Color.LightGray
                )
            },
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 16.dp, end = 16.dp)
                .border(1.dp, Color.Gray, RoundedCornerShape(25.dp))
                .clip(RoundedCornerShape(25.dp)),
            trailingIcon = {
                if (teams.isNotEmpty()) {
                    Icon(
                        imageVector = Icons.Default.Search,
                        contentDescription = "Search Icon"
                    )
                }
            },
            singleLine = true,
            keyboardOptions = KeyboardOptions.Default.copy(
                imeAction = ImeAction.Done
            ),
            keyboardActions = KeyboardActions(
                onDone = {
                    if (searchQuery.isNotEmpty()) {
                        viewModel.createTeam(searchQuery)
                    }
                }
            ),
            colors = TextFieldDefaults.colors(
                unfocusedContainerColor = Color.Transparent,
                focusedContainerColor = Color.Transparent,
                selectionColors = TextSelectionColors(
                    backgroundColor = Color.Transparent,
                    handleColor = Color.Transparent
                )
            )
        )

        LazyColumn(
            modifier = Modifier.fillMaxSize()
        ) {
            if (filteredTeams.isNotEmpty()) {
                items(filteredTeams.takeIf { it.isNotEmpty() } ?: emptyList()) { team ->
                    TeamItem(
                        text = team.name.orEmpty(),
                        isChecked = selectedTeams.value[team._id] == true,
                        onCheckedChange = { isChecked ->
                            team._id?.let { onCheckedChange(it, isChecked) }
                        }
                    )
                }
            }
        }
    }
}

@Composable
fun TeamItem(
    text: String,
    isChecked: Boolean,
    onCheckedChange: (Boolean) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 8.dp, end = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Checkbox(
            checked = isChecked,
            onCheckedChange = onCheckedChange,
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = Color.Gray,
                checkmarkColor = Color.White
            )
        )
        Spacer(modifier = Modifier.width(8.dp))
        Text(
            text = text,
            modifier = Modifier.weight(1f)
        )
    }
}

@Composable
fun TeamItemWeb(
    text: String,
    isChecked: Boolean,
    onCheckedChange: (Boolean) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(70.dp)
            .padding(start = 8.dp, end = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Spacer(modifier = Modifier.width(25.dp))
        Checkbox(
            checked = isChecked,
            onCheckedChange = onCheckedChange,
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = Color.Gray,
                checkmarkColor = Color.White
            )
        )
        Spacer(modifier = Modifier.width(25.dp))
        Text(
            text = text,
            modifier = Modifier.weight(1f),
            color = Color.Black,
            fontWeight = FontWeight.Bold
        )

    }
}

