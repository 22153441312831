package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.AlertDialog
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.FocusManager
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.calendar__1_
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.clock_small
import com.hyperether.goodjob.resources.continue_with_next_step
import com.hyperether.goodjob.resources.describe_the_process
import com.hyperether.goodjob.resources.dropdown_arrow
import com.hyperether.goodjob.resources.enter_step_title
import com.hyperether.goodjob.resources.enter_time
import com.hyperether.goodjob.resources.estimated_duration
import com.hyperether.goodjob.resources.hour
import com.hyperether.goodjob.resources.minute
import com.hyperether.goodjob.resources.step
import com.hyperether.goodjob.resources.step_description
import com.hyperether.goodjob.resources.step_title
import com.hyperether.goodjob.resources.trash
import com.hyperether.goodjob.scenes.register.RegisterInputField
import com.hyperether.goodjob.theme.AddEmployeeInputColor
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.EmployeeDetailsText
import com.hyperether.goodjob.theme.TextColor
import io.wojciechosak.calendar.config.SelectionMode
import io.wojciechosak.calendar.config.rememberCalendarState
import io.wojciechosak.calendar.range.RangeConfig
import io.wojciechosak.calendar.range.UnderlineIllustrator
import io.wojciechosak.calendar.utils.Pallete
import io.wojciechosak.calendar.utils.today
import io.wojciechosak.calendar.view.HorizontalCalendarView
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.plus
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewJobDropdownInput(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<String>,
    painter: Painter?,
    modifier: Modifier = Modifier
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),

            trailingIcon = {
                if (painter != null) {
                    Icon(
                        painter = painter,
                        contentDescription = "Dropdown",
                        modifier = Modifier.clickable { expanded.value = !expanded.value }
                    )
                }
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(onClick = {
                    text.value = item
                    expanded.value = false
                }, text = {
                    Text(item)
                }
                )
            }
        }
    }
}

@Composable
fun EmployeesDropdownInput(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<User>,
    onContactSelected: (User) -> Unit,
    painter: Painter?,
    modifier: Modifier = Modifier
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),

            trailingIcon = {
                if (painter != null) {
                    Icon(
                        painter = painter,
                        contentDescription = "Dropdown",
                        modifier = Modifier.clickable { expanded.value = !expanded.value }
                    )
                }
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(onClick = {
                    if (item != null) {
                        text.value = item.fullName.toString()
                        onContactSelected(item)
                    }
                    expanded.value = false
                }, text = {
                    if (item != null) {
                        item.fullName?.let { Text(it) }
                    }
                }
                )
            }
        }
    }
}

@Composable
fun AboutContactDropdown(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<Contact>,
    painter: Painter?,
    onContactSelected: (String) -> Unit,
    modifier: Modifier = Modifier
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier.fillMaxWidth(),
            trailingIcon = {
                if (painter != null) {
                    Icon(
                        painter = painter,
                        contentDescription = "Dropdown",
                        modifier = Modifier.clickable { expanded.value = !expanded.value }
                    )
                }
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(
                    onClick = {
                        text.value = item.name.orEmpty()
                        item._id?.let { onContactSelected(it) }
                        expanded.value = false
                    },
                    text = { Text(item.name.orEmpty()) }
                )
            }
        }
    }
}

@Composable
fun AboutSiteDropdown(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<Site>,
    onSiteSelected: (Site) -> Unit,
    painter: Painter?,
    modifier: Modifier = Modifier
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),

            trailingIcon = {
                if (painter != null) {
                    Icon(
                        painter = painter,
                        contentDescription = "Dropdown",
                        modifier = Modifier.clickable { expanded.value = !expanded.value }
                    )
                }
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(onClick = {
                    text.value = item.siteName.toString()
                    item?.let { onSiteSelected(it) }
                    expanded.value = false
                }, text = {
                    Text(item.siteName.toString())
                }
                )
            }
        }
    }
}


@Composable
fun CustomTimePicker(
    time: MutableState<String>,
    onTimeSelected: (String) -> Unit,
    showTimePicker: MutableState<Boolean>
) {
    var hour = remember { mutableStateOf("") }
    var minute = remember { mutableStateOf("") }

    val hourFocusRequester = remember { FocusRequester() }
    val minuteFocusRequester = remember { FocusRequester() }

    var isHourFocused = remember { mutableStateOf(false) }
    var isMinuteFocused = remember { mutableStateOf(false) }
    val isValidTime = remember { mutableStateOf(true) }

    AlertDialog(
        onDismissRequest = { showTimePicker.value = false },
        title = {
            Text(
                text = stringResource(Res.string.enter_time),
                modifier = Modifier.padding(start = 8.dp),
                color = Color.Gray,
                fontWeight = FontWeight.Bold,
                fontSize = 12.sp
            )
        },
        buttons = {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(8.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(
                    painter = painterResource(Res.drawable.clock_small),
                    contentDescription = "Time Icon",
                    modifier = Modifier.size(24.dp).offset(x = 20.dp)
                )
                Row {
                    TextButton(onClick = { showTimePicker.value = false }) {
                        Text(text = stringResource(Res.string.cancel), color = Color.Red)
                    }
                    TextButton(
                        onClick = {
                            if (isValidTime.value) {
                                val formattedHour = hour.value.padStart(2, '0')
                                val formattedMinute = minute.value.padStart(2, '0')
                                time.value = "$formattedHour:$formattedMinute"
                                onTimeSelected(time.value)
                                showTimePicker.value = false
                            }
                        },
                    ) {
                        Text(text = "OK", color = BlueApp)
                    }
                }
            }
        },
        text = {
            Column(
                modifier = Modifier.padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.fillMaxWidth().height(75.dp)
                ) {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier.weight(1f)
                    ) {
                        OutlinedTextField(
                            value = hour.value,
                            onValueChange = { newHour ->
                                hour.value = newHour.takeIf { it.length <= 2 } ?: hour.value
                                isValidTime.value = isValidTime(hour.value, minute.value)
                                if (newHour.length == 2) {
                                    minuteFocusRequester.requestFocus()
                                }
                            },
                            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                            modifier = Modifier
                                .weight(1f)
                                .focusRequester(hourFocusRequester)
                                .onFocusChanged { focusState ->
                                    isHourFocused.value = focusState.isFocused
                                }
                                .background(Color.Blue.copy(alpha = 0.05f))
                                .border(
                                    width = if (isHourFocused.value) 1.dp else 0.dp,
                                    color = if (isHourFocused.value) BlueApp else Color.Transparent,
                                    shape = RoundedCornerShape(4.dp)
                                ),
                            singleLine = true
                        )
                        Text(
                            stringResource(Res.string.hour),
                            color = Color.Gray,
                            fontSize = 12.sp,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier.align(Alignment.Start)
                        )
                    }

                    Text(
                        text = ":",
                        fontSize = 40.sp,
                        color = Color.Black,
                        modifier = Modifier.padding(bottom = 30.dp)
                    )

                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier.weight(1f)
                    ) {
                        OutlinedTextField(
                            value = minute.value,
                            onValueChange = { newMinute ->
                                minute.value = newMinute.takeIf { it.length <= 2 } ?: minute.value
                                isValidTime.value = isValidTime(hour.value, newMinute)
                                if (newMinute.length == 2) {
                                    hourFocusRequester.requestFocus()
                                }
                            },
                            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                            modifier = Modifier
                                .weight(1f)
                                .focusRequester(minuteFocusRequester)
                                .onFocusChanged { focusState ->
                                    isMinuteFocused.value = focusState.isFocused
                                }
                                .background(Color.Blue.copy(alpha = 0.05f))
                                .border(
                                    width = if (isMinuteFocused.value) 2.dp else 0.dp,
                                    color = if (isMinuteFocused.value) BlueApp else Color.Transparent,
                                    shape = RoundedCornerShape(4.dp)
                                ),
                            singleLine = true
                        )
                        Text(
                            stringResource(Res.string.minute),
                            color = Color.Gray,
                            fontSize = 12.sp,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier.align(Alignment.Start)
                        )
                    }
                }
            }
        }
    )
}

private fun isValidTime(hour: String?, minute: String?): Boolean {
    return hour?.toIntOrNull() in 0..23 && minute?.toIntOrNull() in 0..59
}

@Composable
fun StepInputFields(steps: MutableList<StepData>) {

    Column(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        steps.forEachIndexed { index, step ->
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .shadow(2.dp, shape = RoundedCornerShape(7.dp))
                    .clip(RoundedCornerShape(7.dp))
                    .background(Color.White)
                    .padding(16.dp)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "${index + 1}. ${stringResource(Res.string.step)}",
                        fontSize = 18.sp,
                        color = Color.Black,
                        modifier = Modifier.weight(1f)
                    )
                    if (index > 0) {
                        Image(
                            painter = painterResource(Res.drawable.trash),
                            contentDescription = null,
                            modifier = Modifier.clickable {
                                steps.removeAt(index)
                            }
                        )
                    }
                }
                RegisterInputField(
                    step.title,
                    "${index + 1} ${stringResource(Res.string.step_title)}",
                    placeholder = stringResource(Res.string.enter_step_title)
                )
                RegisterInputField(
                    step.description,
                    "${index + 1} ${stringResource(Res.string.step_description)}",
                    placeholder = stringResource(Res.string.describe_the_process)
                )
                RegisterInputField(
                    step.estimation,
                    stringResource(Res.string.estimated_duration),
                    placeholder = "30 min"
                )
            }

            Spacer(modifier = Modifier.height(10.dp))
        }

        Text(
            text = "+ ${stringResource(Res.string.continue_with_next_step)}",
            fontSize = 16.sp,
            color = BlueApp,
            textAlign = TextAlign.Center,
            modifier = Modifier
                .clickable {
                    steps.add(StepData())
                }
                .padding(8.dp)
        )
    }
}

data class StepData(
    val title: MutableState<String> = mutableStateOf(""),
    val description: MutableState<String> = mutableStateOf(""),
    val estimation: MutableState<String> = mutableStateOf(""),
    val stepStatus: MutableState<String> = mutableStateOf("")
)

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun CustomCalendarView(onDismiss: () -> Unit, onDateSelect: (LocalDate) -> Unit) {

    Dialog(
        onDismissRequest = { onDismiss() },
    ) {
        val startDate = LocalDate.today()
        val selectedDates = remember { mutableStateListOf<LocalDate>() }

        Column(
            modifier = Modifier
                .background(Color.White)
                .size(360.dp),
        ) {
            HorizontalCalendarView(startDate = startDate) { monthOffset ->
                RangeCalendarView(
                    config =
                    rememberCalendarState(
                        startDate = startDate,
                        monthOffset = monthOffset,
                        selectedDates = selectedDates,
                    ),
                    selectionMode = determineSelectionMode(selectedDates),
                    onDateSelected = {
                        selectedDates.clear()
                        selectedDates.addAll(it)
                        onDateSelect(it.first())
                        onDismiss()
                    },
                    rangeConfig = RangeConfig(rangeIllustrator = UnderlineIllustrator(Pallete.LightGreen)),
                )
            }
            Spacer(modifier = Modifier.height(20.dp))
//            if (selectedDates.size == 2) {
//                androidx.compose.material3.Text("From:\n${selectedDates.last()} to ${selectedDates.first()}")
//            }
        }
    }
}

private fun determineSelectionMode(selectedDates: List<LocalDate>): SelectionMode {
    return when {
        selectedDates.size == 1 -> SelectionMode.Single
        isConsecutiveDates(selectedDates) -> SelectionMode.Range
        else -> SelectionMode.Multiply()
    }
}

private fun isConsecutiveDates(dates: List<LocalDate>): Boolean {
    if (dates.size < 2) return false
    val sortedDates = dates.sorted()
    return sortedDates.zipWithNext().all { (current, next) ->
        next == current.plus(1, DateTimeUnit.DAY)
    }
}

@Composable
fun OutlinedCalendarInput(onClick: () -> Unit, input: MutableState<String>) {
    OutlinedButton(
        onClick = { onClick() },
        border = BorderStroke(1.dp, Color.DarkGray),
        shape = RoundedCornerShape(8),
        colors = ButtonDefaults.outlinedButtonColors(
            contentColor = Color.White, containerColor = Color.Transparent
        ),
        modifier = Modifier
            .fillMaxWidth()
            .height(73.dp)
            .padding(top = 15.dp)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = input.value,
                color = Color.DarkGray,
                fontSize = 13.sp,
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(5f).padding(top = 5.dp).offset(x = (-10).dp)
            )
            Icon(
                painter = painterResource(Res.drawable.calendar__1_),
                contentDescription = "",
                modifier = Modifier.offset(x = 10.dp)

            )
        }
    }
}

@Composable
fun AddEmployeeTeamField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<Team>,
    onTeamSelected: (Team) -> Unit
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),
            trailingIcon = {
                Icon(
                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                    contentDescription = "Dropdown",
                    modifier = Modifier.clickable { expanded.value = !expanded.value }
                )
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { team ->
                DropdownMenuItem(onClick = {
                    text.value = team.value ?: ""
                    expanded.value = false
                    onTeamSelected(team)  // Pass the selected team
                }, text = {
                    Text(team.value ?: "")
                })
            }
        }
    }
}

@Composable
fun CustomInputRowEmployee(
    painterResource: DrawableResource,
    labelText: String,
    placeholderText: String,
    inputValue: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    placeholderColor: Color = AddEmployeeInputColor,
    focusManager: FocusManager
) {
    Row(
        modifier = modifier
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .then(modifier)
            .fillMaxWidth()
            .padding(vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(painterResource),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = textColor,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }
        OutlinedTextField(
            value = inputValue,
            onValueChange = onValueChange,
            textStyle = TextStyle(fontSize = 14.sp),
            placeholder = {
                Text(
                    text = placeholderText,
                    color = placeholderColor,
                    fontSize = 14.sp
                )
            },
            modifier = Modifier
                .weight(2f)
                .border(
                    width = 1.dp,
                    color = placeholderColor,
                    shape = RoundedCornerShape(4.dp)
                )
        )
    }
}

@Composable
fun CustomNumberInputRowEmployee(
    painterResource: DrawableResource,
    labelText: String,
    placeholderText: String,
    inputValue: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    placeholderColor: Color = AddEmployeeInputColor,
    focusManager: FocusManager
) {
    Row(
        modifier = modifier
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }
                    else -> false
                }
            }
            .then(modifier)
            .fillMaxWidth()
            .padding(vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(painterResource),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = textColor,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }
        OutlinedTextField(
            value = inputValue,
            onValueChange = { newValue ->
                // Allow only numeric characters or "+" as the first character
                if (newValue.all { it.isDigit() || it == '+' } &&
                    (newValue.count { it == '+' } <= 1 && (newValue.indexOf('+') <= 0))) {
                    onValueChange(newValue)
                }
            },
            textStyle = TextStyle(fontSize = 14.sp),
            placeholder = {
                Text(
                    text = placeholderText,
                    color = placeholderColor,
                    fontSize = 14.sp
                )
            },
            modifier = Modifier
                .weight(2f)
                .border(
                    width = 1.dp,
                    color = placeholderColor,
                    shape = RoundedCornerShape(4.dp)
                ),
            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Phone)
        )
    }
}


@Composable
fun <T> CustomDropdownMenu(
    iconRes: DrawableResource,
    labelText: String,
    items: List<T>,
    selectedValue: T,
    onValueSelected: (T) -> Unit,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    placeholderColor: Color = AddEmployeeInputColor,
    displayText: (T) -> String,
    focusManager: FocusManager
) {
    var expanded = remember { mutableStateOf(false) }
    var selectedOption = remember { mutableStateOf(selectedValue) }
    val density = LocalDensity.current
    val inputFieldWidth = remember { mutableStateOf(0.dp) }

    Row(
        modifier = modifier
            .fillMaxWidth()
            .padding(vertical = 8.dp)
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .then(modifier),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(iconRes),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = textColor,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }

        Box(
            modifier = Modifier
                .weight(2f)
                .border(
                    width = 1.dp,
                    color = placeholderColor,
                    shape = RoundedCornerShape(4.dp)
                )
                .onGloballyPositioned { coordinates ->
                    inputFieldWidth.value = with(density) { coordinates.size.width.toDp() }
                }
                .clickable { expanded.value = true }
        ) {
            Text(
                text = displayText(selectedOption.value),
                color = if (selectedOption == selectedValue) placeholderColor else textColor,
                fontSize = 14.sp,
                modifier = Modifier.padding(12.dp)
            )
            Icon(
                painter = painterResource(Res.drawable.dropdown_arrow),
                contentDescription = null,
                tint = Color(0xFF676767),
                modifier = Modifier
                    .align(Alignment.CenterEnd)
                    .padding(end = 20.dp)
            )

            DropdownMenu(
                expanded = expanded.value,
                onDismissRequest = { expanded.value = false }
            ) {
                items.forEach { option ->
                    DropdownMenuItem(
                        modifier = Modifier.width(inputFieldWidth.value),
                        onClick = {
                            selectedOption.value = option
                            onValueSelected(option)
                            expanded.value = false
                        },
                        text = {
                            Text(displayText(option))
                        }
                    )
                }
            }
        }
    }
}

@Composable
fun <T> CustomDropdown(
    placeholderColor: Color,
    items: List<T>,
    selectedValue: T,
    displayText: (T) -> String,
    textColor: Color,
    onValueSelected: (T) -> Unit,
    modifier: Modifier
) {
    val expanded = remember { mutableStateOf(false) }
    val selectedOption = remember { mutableStateOf(selectedValue) }
    val inputFieldWidth = remember { mutableStateOf(0.dp) }
    val density = LocalDensity.current

    Row(
        modifier = modifier
            .border(
                width = 1.dp,
                color = placeholderColor,
                shape = RoundedCornerShape(8.dp)
            )
            .onGloballyPositioned { coordinates ->
                inputFieldWidth.value = with(density) { coordinates.size.width.toDp() }
            }
            .clickable { expanded.value = true }
            .padding(horizontal = 12.dp, vertical = 8.dp),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = displayText(selectedOption.value),
            color = if (selectedOption == selectedValue) placeholderColor else textColor,
            fontSize = 14.sp,
            modifier = Modifier
                .padding(end = 20.dp, start = 10.dp)
                .align(Alignment.CenterVertically)
        )
        Icon(
            painter = painterResource(Res.drawable.dropdown_arrow),
            contentDescription = null,
            tint = Color(0xFF676767),
            modifier = Modifier
                .padding(end = 20.dp)
                .align(Alignment.CenterVertically)
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false },
            modifier = Modifier.width(inputFieldWidth.value)
        ) {
            items.forEach { option ->
                DropdownMenuItem(
                    onClick = {
                        selectedOption.value = option
                        onValueSelected(option)
                        expanded.value = false
                    },
                    text = { Text(displayText(option)) }
                )
            }
        }
    }
}