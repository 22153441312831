package com.hyperether.planner.ui

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.planner.model.DashboardEvent
import com.hyperether.planner.model.DashboardPlan
import com.hyperether.planner.model.EventStatus
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant


@Composable
fun DashboardDayPlanner(
    dashboardEvents: MutableList<DashboardEvent>,
    completedColor: Color = Color(172, 229, 185),
    pendingColor: Color = Color(235, 231, 116),
    delayedColor: Color = Color(229, 172, 172),
    celWidth: Int = 100,
    startHour: Int = 8
) {
    var celWidthDp by remember { mutableStateOf(celWidth) }
    val tableWidth by remember { mutableStateOf(((24 - startHour) * celWidthDp).dp) }

    // Ensure that listOfPlans has at least 10 items (with default events if needed)
//    if (dashboardEvents.size < 10) {
//        while (dashboardEvents.size < 10) {
//            dashboardEvents.add(DashboardEvent(
//                status = EventStatus.PENDING,
//                from = LocalDateTime(2024, 10, 24, 8, 0), // Oct 24, 2024 at 08:00
//                to = LocalDateTime(2024, 10, 24, 9, 0) ,// Oct 24, 2024 at 09:00
//                title = "Empty Event"
//            ))
//        }
//    }

    val horizontalScrollState = rememberScrollState()
    val verticalScrollState = rememberScrollState()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .pointerInput(Unit) {
                detectDragGestures { change, dragAmount ->
                    change.consume()
                    CoroutineScope(Dispatchers.Default).launch {
                        verticalScrollState.scrollTo(verticalScrollState.value - dragAmount.y.toInt())
                        horizontalScrollState.scrollTo(horizontalScrollState.value - dragAmount.x.toInt())
                    }
                }
            }
            .fillMaxSize()
            .background(Color.White)
            .border(1.dp, color = Color.Black),
        contentAlignment = Alignment.TopStart
    ) {

        Row {

            // Left column with time slots
            Column(
                modifier = Modifier.verticalScroll(verticalScrollState)
                    .background(Color.Transparent)
            ) {
                val listOfHours = generateHourlyTimeSlots(startHour)
                listOfHours.forEach { dashboardEvent ->
                    Box(
                        modifier = Modifier
                            .height(50.dp)
                            .width(80.dp)
                            .background(Color.Transparent),
                        contentAlignment = Alignment.Center
                    ) {
                        Text(dashboardEvent.time, textAlign = TextAlign.Center)
                    }
                }
            }

            val density = LocalDensity.current
            val hourHeightDp = 50.dp
            val textMeasurer = rememberTextMeasurer()

            // Column with events
            Column(
                modifier = Modifier
                    .horizontalScroll(horizontalScrollState)
                    .verticalScroll(verticalScrollState)
            ) {
                Canvas(
                    modifier = Modifier
                        .offset(y = (-20).dp)
                        .size(
                            width = tableWidth,
                            height = hourHeightDp * (24 - startHour)
                        )
                ) {
                    with(density) {
                        val hourHeight = hourHeightDp.toPx()
                        val completedXOffset = 0.dp.toPx()
                        val pendingXOffset = celWidthDp.dp.toPx()
                        val delayedXOffset = (celWidthDp * 2).dp.toPx()
                        val eventWidth = celWidthDp.dp.toPx()
                        val pathEffect = PathEffect.dashPathEffect(floatArrayOf(5f, 5f), phase = 0f)

                        // Draw hour lines
                        for (i in 0..24 - startHour) {
                            drawLine(
                                color = Color.Gray,
                                start = Offset(0f, i * hourHeight),
                                end = Offset(size.width, i * hourHeight),
                                pathEffect = pathEffect
                            )
                        }

                        // Draw events
                        dashboardEvents.forEachIndexed { index, event ->
//                            event?.forEach { plan ->
                            val (xOffset, color) = when (event.status) {
                                EventStatus.COMPLETED -> (completedXOffset + index * eventWidth) to completedColor
                                EventStatus.PENDING -> (pendingXOffset + index * eventWidth) to pendingColor
                                EventStatus.DELAYED -> (delayedXOffset + index * eventWidth) to delayedColor
                                EventStatus.REJECTED -> (pendingXOffset + index * eventWidth) to delayedColor
                                EventStatus.IN_PROGRESS -> (pendingXOffset + index * eventWidth) to pendingColor
                            }

                            // Calculate Y position based on start time
                            val startHourRelative = event.from.hour - startHour + 1
                            val startMinuteRelative = event.from.minute
                            val topLeftY =
                                (startHourRelative * hourHeight) + (startMinuteRelative * hourHeight / 60)

                            // Calculate event box height based on duration
                            val durationMinutes = calculateDurationInMinutes(event.from, event.to)
                            val eventHeightForDuration = (hourHeight * durationMinutes) / 60f

                            // Draw the event box
                            drawRoundRect(
                                color = color,
                                topLeft = Offset(xOffset, topLeftY),
                                size = Size(
                                    width = eventWidth,
                                    height = eventHeightForDuration
                                ),
                                cornerRadius = CornerRadius(10f, 10f)
                            )

                            // Draw the event title centered within the event box
                            val measuredText = textMeasurer.measure(
                                AnnotatedString(event.title ?: ""),
                                constraints = Constraints.fixedWidth(eventWidth.toInt()),
                                overflow = TextOverflow.Ellipsis,
                                style = TextStyle(
                                    fontSize = 18.sp,
                                    color = Color.Black,
                                    textAlign = TextAlign.Center
                                ),
                                maxLines = 1
                            )

                            // Position the title within the event box
                            val textOffsetX =
                                xOffset + (eventWidth - measuredText.size.width) / 2
                            val textOffsetY =
                                topLeftY + (eventHeightForDuration - measuredText.size.height) / 2
                            drawText(measuredText, topLeft = Offset(textOffsetX, textOffsetY))
//                            }
                        }
                    }
                }
            }
        }
    }
}

fun generateHourlyTimeSlots(startTme: Int): List<DashboardPlan> {
    val timeSlots = mutableListOf<DashboardPlan>()
    for (hour in startTme until 24) {
        val formattedTime = "${hour.toString().padStart(2, '0')}:00"
        timeSlots.add(DashboardPlan(time = formattedTime, listOf()))
    }
    return timeSlots
}

fun calculateDurationInMinutes(from: LocalDateTime, to: LocalDateTime): Long {
    val fromInstant = from.toInstant(TimeZone.currentSystemDefault())
    val toInstant = to.toInstant(TimeZone.currentSystemDefault())

    val duration = toInstant - fromInstant

    return duration.inWholeMinutes
}
