package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Checkbox
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.ContactType
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_contact
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_type
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.enter_contact_name
import com.hyperether.goodjob.resources.enter_email_address
import com.hyperether.goodjob.resources.enter_zip
import com.hyperether.goodjob.resources.industryEmail
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.photo_upload_area
import com.hyperether.goodjob.resources.tap_to_upload_photo
import com.hyperether.goodjob.resources.use_contact_name
import com.hyperether.goodjob.resources.zip
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.PopUpDialog
import com.hyperether.goodjob.scenes.register.RegisterInputField
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewContact(addNewViewModel: AddNewViewModel, navHostController: NavHostController) {

    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 20.dp)
        ) {
            Image(
                painter = painterResource(Res.drawable.back_arrow),
                contentDescription = "back",
                modifier = Modifier.clickable {
                    navHostController.popBackStack()
                }
            )

            Spacer(modifier = Modifier.weight(1f))

            Text(
                text = stringResource(Res.string.add_new_contact),
                fontSize = 18.sp,
                color = TextColor,
                textAlign = TextAlign.Center
            )

            Spacer(modifier = Modifier.weight(1f))
        }
        Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
        Spacer(modifier = Modifier.height(15.dp))

        Image(
            painter = painterResource(Res.drawable.photo_upload_area),
            contentDescription = "Avatar",
            modifier = Modifier
                .size(80.dp)
                .clip(CircleShape)
                .background(BlueApp)
                .align(Alignment.CenterHorizontally)
        )

        Text(
            text = stringResource(Res.string.tap_to_upload_photo),
            fontSize = 14.sp,
            color = TextColor,
            textAlign = TextAlign.Center,
            fontStyle = FontStyle.Italic,
            modifier = Modifier.align(Alignment.CenterHorizontally)
        )

        Column(modifier = Modifier.padding(horizontal = 16.dp)) {
            RegisterInputField(
                addNewViewModel.contactNameNewContact,
                stringResource(Res.string.contact_name),
                placeholder = stringResource(Res.string.enter_contact_name)
            )
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.offset(x = (-12).dp).padding(top = 5.dp)
            ) {
                Checkbox(
                    checked = addNewViewModel.isCheckedContactPerson.value,
                    onCheckedChange = {
                        addNewViewModel.isCheckedContactPerson.value = it
                    },
                    modifier = Modifier
                        .clip(RoundedCornerShape(50))
                )
                Spacer(modifier = Modifier.width(5.dp))
                Text(
                    text = stringResource(Res.string.use_contact_name),
                    color = TextColor,
                    fontSize = 12.sp
                )
            }
            val itemsType = listOf(
                ContactType.Customer.name,
                ContactType.Supplier.name,
                ContactType.Contractor.name
            )
            AddEmployeeInputField(
                addNewViewModel.contactType,
                stringResource(Res.string.contact_type),
                placeholder = "",
                dropdownItems = itemsType
            )
            AddEmployeeInputField(
                addNewViewModel.industry,
                stringResource(Res.string.industryEmail),
                placeholder = "",
                dropdownItems = emptyList()
            )
            RegisterInputField(
                addNewViewModel.contactAddress,
                stringResource(Res.string.address),
                placeholder = "Enter street name and building number"
            )
            RegisterInputField(
                addNewViewModel.zipCountryNewContact,
                stringResource(Res.string.zip),
                placeholder = stringResource(Res.string.enter_zip)
            )
            RegisterInputField(
                addNewViewModel.phoneNewContact,
                stringResource(Res.string.phone),
                placeholder = "+134232525235"
            )
            RegisterInputField(
                addNewViewModel.emailNewContact,
                stringResource(Res.string.email_address),
                placeholder = stringResource(Res.string.enter_email_address)
            )
            Spacer(modifier = Modifier.height(15.dp))

            Text(
                text = "Contact person",
                fontSize = 18.sp,
                color = TextColor,
                fontWeight = FontWeight.SemiBold,
                textAlign = TextAlign.Center
            )
            RegisterInputField(
                addNewViewModel.contactPersonName,
                stringResource(Res.string.contact_name),
                placeholder = stringResource(Res.string.enter_contact_name)
            )
            RegisterInputField(
                addNewViewModel.contactPersonEmail,
                stringResource(Res.string.email_address),
                placeholder = stringResource(Res.string.enter_email_address)
            )
            RegisterInputField(
                addNewViewModel.contactPersonPhone,
                stringResource(Res.string.phone),
                placeholder = "+123456789"
            )
            Spacer(modifier = Modifier.height(25.dp))
            val contact = Contact(
                name = addNewViewModel.contactNameNewContact.value,
                contactType = addNewViewModel.contactType.value,
                type = "contact",
                industry = addNewViewModel.industry.value,
                address = addNewViewModel.contactAddress.value,
                email = addNewViewModel.emailNewContact.value,
                city = addNewViewModel.zipCountryNewContact.value,
                country = "",
                zip = 21000,
                phone = addNewViewModel.phoneNewContact.value,
                personName = addNewViewModel.contactPersonName.value,
                personAddress = addNewViewModel.contactPersonEmail.value,
                personPhone = addNewViewModel.contactPersonPhone.value
            )
            FilledButton(
                onClick = {
                    addNewViewModel.showLoader.value = true
                    addNewViewModel.addContact(contact,
                        onSuccess = {
                            addNewViewModel.showLoader.value = false
                            addNewViewModel.showPopUpSuccess.value = true
                        }, onError = {
                            addNewViewModel.showLoader.value = false
                            addNewViewModel.showPopUpError.value = true
                        })

                },
                text = stringResource(Res.string.add),
                isEnabled = true
            )
            Spacer(modifier = Modifier.height(25.dp))
            if (addNewViewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { addNewViewModel.showPopUpSuccess.value = false },
                    title = "SUCCESS",
                    message = "Contact added successfully"
                )
            }
            if (addNewViewModel.showPopUpError.value) {
                PopUpDialog(
                    onDismiss = { addNewViewModel.showPopUpError.value = false },
                    title = "ERROR",
                    message = "There was an error while adding contact"
                )
            }
            if (addNewViewModel.showLoader.value) {
                FullScreenProgressBar(showProgressBar = addNewViewModel.showLoader)
            }
        }
    }
}