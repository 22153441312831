package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.EmployeeRequest
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobRequest
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.SiteRequest
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class AddNewViewModel(val repository: Repository) : ViewModel() {

    /*Add new employee*/
    var contactName: MutableState<String> = mutableStateOf("")
    var contactSite: MutableState<String> = mutableStateOf("")
    val fullNameInput: MutableState<String> = mutableStateOf("")
    val phoneInput: MutableState<String> = mutableStateOf("")
    val emailInput: MutableState<String> = mutableStateOf("")
    val zipCountryInput: MutableState<String> = mutableStateOf("")
    val addressInput: MutableState<String> = mutableStateOf("")
    val status: MutableState<String> = mutableStateOf("")
    val skills: MutableState<String> = mutableStateOf("")
    val team: MutableState<String> = mutableStateOf("")
    val selectedTeam: MutableState<Team?> = mutableStateOf(null)
    val selectedSkill: MutableState<String?> = mutableStateOf(null)
    val upcomingLeaves: MutableState<String> = mutableStateOf("")

    /*Add new job*/
    val jobTitleInput: MutableState<String> = mutableStateOf("")
    val jobStatusValue: MutableState<String> = mutableStateOf("")
    val assignEmployeeValue: MutableState<String> = mutableStateOf("")
    val dateInputValue: MutableState<String> = mutableStateOf("Date")
    val startInputValue: MutableState<String> = mutableStateOf("Start at")
    val endInputValue: MutableState<String> = mutableStateOf("End at")
    val repeatValue: MutableState<String> = mutableStateOf("")
    val dueDateValue: MutableState<String> = mutableStateOf("Due date")
    val jobDurationValue: MutableState<Int> = mutableStateOf(0)
    val notesValue: MutableState<String> = mutableStateOf("")
    val contactId: MutableState<String> = mutableStateOf("")
    val selectedSiteName: MutableState<String> = mutableStateOf("")
    val contactParentId: MutableState<String> = mutableStateOf("")
    val selectedEmployee: MutableState<User?> = mutableStateOf(null)
    val durationText: MutableState<String> = mutableStateOf("")
    val durationInMinutes: MutableState<Int> = mutableStateOf(0)


    /*Add new customer*/
    val isCheckedContactPerson: MutableState<Boolean> = mutableStateOf(false)
    val contactType: MutableState<String> = mutableStateOf("")
    val industry: MutableState<String> = mutableStateOf("")
    val contactAddress: MutableState<String> = mutableStateOf("")
    val contactNameNewContact: MutableState<String> = mutableStateOf("")
    val zipCountryNewContact: MutableState<String> = mutableStateOf("")
    val phoneNewContact: MutableState<String> = mutableStateOf("")
    val emailNewContact: MutableState<String> = mutableStateOf("")
    val contactPersonName: MutableState<String> = mutableStateOf("")
    val contactPersonEmail: MutableState<String> = mutableStateOf("")
    val contactPersonPhone: MutableState<String> = mutableStateOf("")
    val selectedContact: MutableState<String> = mutableStateOf("")


    val isTimePicker1Visible: MutableState<Boolean> = mutableStateOf(false)
    val isTimePicker2Visible: MutableState<Boolean> = mutableStateOf(false)
    val isCalendar1Visible: MutableState<Boolean> = mutableStateOf(false)
    val isCalendar2Visible: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)

    val steps = mutableStateListOf(StepData())

    private val _contactSites = mutableStateOf<List<Site>>(emptyList())
    val contactSites: State<List<Site>> get() = _contactSites

    private val _selected_employee_remote = MutableStateFlow(com.hyperether.goodjob.models.User())
    val selected_employee_remote: StateFlow<com.hyperether.goodjob.models.User> = _selected_employee_remote

    fun insertJob(job: Job){
        viewModelScope.launch {
            repository.insertJob(job)
        }
    }

    val contacts: Flow<List<com.hyperether.goodjob.database.Contact>> = repository.getContactsFromLocal()
    val employees: Flow<List<com.hyperether.goodjob.database.User>> = repository.getUsers()



    fun addEmployee(user: EmployeeRequest, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.addEmployee(user)

            when (result) {
                is Resource.Success -> {
                    println("User added successfully")
                    onSuccess()
                }
                is Resource.Error -> {
                    println("Error while adding user")
                    onError()
                }
            }
        }
    }

    fun addJob(job: JobRequest, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.createJob(job)

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }
                is Resource.Error -> {
                    onError()
                }
            }
        }
    }

    fun getAllContacts() {
        viewModelScope.launch {
            val result = repository.getAllContacts()

            when (result) {
                is Resource.Success -> {
                    println(result)
                }
                is Resource.Error -> {
                    println(result)
                }
            }
        }
    }

    fun getSitesUnderContact(siteRequest: SiteRequest) {
        viewModelScope.launch {
            val result = repository.getSitesUnderContact(siteRequest)
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                _contactSites.value = result.data ?: emptyList()
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }
    }

    fun addContact(contact: Contact, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.addContact(contact)

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }
                is Resource.Error -> {
                    onError()
                }
            }
        }
    }

    fun getWorkspaceId() : String = repository.getWorkspaceId()

    fun getUser(): com.hyperether.goodjob.models.User? {
        return repository.getUser()
    }

    fun getEmployeeById(id: String) {
        viewModelScope.launch {
            when (val result = repository.getEmployeeById(id)) {
                is Resource.Success -> {
                    result.data?.let { _selected_employee_remote.value = it }
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    fun calculateDuration() {
        try {
            val (startHour, startMinute) = startInputValue.value.split(":").map { it.toInt() }
            val (endHour, endMinute) = endInputValue.value.split(":").map { it.toInt() }

            val startTotalMinutes = startHour * 60 + startMinute
            val endTotalMinutes = endHour * 60 + endMinute

            val totalMinutes = endTotalMinutes - startTotalMinutes

            durationInMinutes.value = totalMinutes
            durationText.value = "${totalMinutes / 60}h ${totalMinutes % 60}min"
        } catch (e: Exception) {
            durationText.value = "Invalid time format"
            durationInMinutes.value = 0
        }
    }
}