package com.hyperether.goodjob.navigation

sealed class Screen(val route: String) {

    data object Checkout : Screen("checkout")
    data object History : Screen("history")
    data object Statistic : Screen("statistics")
    data object Returns : Screen("returns")
    data object WebNav: Screen("web_nav")
    data object Splash: Screen("splash")
    data object Scheduler: Screen("scheduler")
    data object Onboarding: Screen("onboarding")
    data object Register: Screen("register")
    data object Landing: Screen("landing")
    data object Pricing: Screen("pricing")
    data object Dashboard: Screen("dashboard")
    data object Workspace: Screen("workspace")
    data object AddNewWorkspace: Screen ("add_new_workspace")
    data object AddNew: Screen ("add_new")
    data object AddNewEmployee: Screen ("add_new_employee")
    data object AddNewJob: Screen ("add_new_job")
    data object AboutContact: Screen ("about_contact")
    data object AddNewContact: Screen ("add_new_contact")
    data object Employees: Screen ("employees")
    data object EmployeeDetails: Screen ("employee_details")
    data object Jobs: Screen ("jobs")
    data object JobDetails: Screen ("job_details")
}

val availableRoutes = listOf(
    "checkout",
    "history",
    "statistics",
    "returns",
    "web_nav",
    "splash",
    "scheduler",
    "onboarding",
    "register",
    "landing",
    "pricing",
    "dashboard",
    "workspace",
    "add_new_workspace",
    "add_new",
    "add_new_employee",
    "add_new_job",
    "about_contact",
    "add_new_contact",
    "employees",
    "jobs",
    "job_details",
    "employee_details"
)
