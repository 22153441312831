package com.hyperether.goodjob.scenes.components.drawer

import androidx.compose.runtime.Composable
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.ic_actions_paper_check
import com.hyperether.goodjob.resources.log_out__1_
import com.hyperether.goodjob.resources.logout
import com.hyperether.goodjob.resources.photo_upload_area
import com.hyperether.goodjob.resources.profile_settings
import com.hyperether.goodjob.resources.subscription
import com.hyperether.goodjob.resources.user
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.stringResource

data class DrawerItem(
    val title: String, val painter: DrawableResource
)

@Composable
fun getDrawerItems(repository: Repository): List<DrawerItem> {
    val drawerList = mutableListOf<DrawerItem>()
    val user = repository.getUser()
    drawerList.add(
        DrawerItem(
            title = user?.getName().orEmpty(),
            painter = Res.drawable.photo_upload_area,
        )
    )

    drawerList.add(
        DrawerItem(
            title = stringResource(Res.string.profile_settings), painter = Res.drawable.user
        )
    )

    if (user?.role.equals(Role.superAdmin.name, ignoreCase = true) ||
        user?.role.equals(Role.admin.name, ignoreCase = true) ||
        user?.role.equals(Role.manager.name, ignoreCase = true)
    ) {
        drawerList.addAll(
            listOf(
//                DrawerItem(
//                    title = stringResource(Res.string.workspace_settings),
//                    painter = Res.drawable.layout
//                ),
//                DrawerItem(
//                    title = stringResource(Res.string.roles_permission),
//                    painter = Res.drawable.admin_svgrepo_com_1
//                ),
                DrawerItem(
                    title = stringResource(Res.string.subscription),
                    painter = Res.drawable.ic_actions_paper_check
                )
            )
        )
    }

    drawerList.add(
        DrawerItem(
            title = stringResource(Res.string.logout), painter = Res.drawable.log_out__1_
        )
    )

    return drawerList
}