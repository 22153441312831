package com.hyperether.goodjob.scenes.jobs.components

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Text
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.dropdown_arrow
import com.hyperether.goodjob.resources.name
import com.hyperether.goodjob.scenes.jobs.JobDetailsViewModel
import com.hyperether.goodjob.util.JobStatusMap
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobDetailsHeaderView(job: Job?, viewModel: JobDetailsViewModel) {
    val jobStatusMapAdmin = JobStatusMap()
    val selectedOption = remember {
        mutableStateOf(
            viewModel.jobStatusMap.entries.find { entry -> entry.value == job?.status }?.key ?: jobStatusMapAdmin.entries.find { entry -> entry.value == job?.status }?.key ?: ""
        )
    }
    val boxColor = remember {
        mutableStateOf(
            StatusColor().getStatusColor(
                viewModel.jobStatusMap[selectedOption.value] ?: ""
            )
        )
    }
    LaunchedEffect(job?.status) {
        boxColor.value = StatusColor().getStatusColor(
            job?.status ?: ""
        )
        selectedOption.value =
            viewModel.jobStatusMap.entries.find { entry -> entry.value == job?.status }?.key ?: jobStatusMapAdmin.entries.find { entry -> entry.value == job?.status }?.key ?: ""
    }

    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 16.dp, end = 16.dp, top = 16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
        ) {
            Column(
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    fontSize = 18.sp,
                    fontWeight = FontWeight.W700,
                    text = job?.jobTitle ?: stringResource(Res.string.name)
                )
                Spacer(modifier = Modifier.height(16.dp))
                DropdownJobStatus(
                    allStatusMap = jobStatusMapAdmin,
                    jobStatusMap = viewModel.jobStatusMap,
                    selectedValue = selectedOption,
                    onValueSelected = { selectedValue ->
                        job?.id?.let {
                            viewModel.updateJob(
                                it,
                                Mapper().toJobUpdateStatusRequest(
                                    job,
                                    viewModel.jobStatusMap[selectedValue] ?: ""
                                ),
                                onSuccess = { selectedOption.value = selectedValue },
                                onError = {}
                            )
                        }
                    },
                    boxColor = boxColor,
                    modifier = Modifier,
                    isEmployee = viewModel.isEmployee()
                )
            }
        }
    }
}

@Composable
fun DropdownJobStatus(
    allStatusMap: Map<String, String>,
    jobStatusMap: Map<String, String>,
    selectedValue: MutableState<String>,
    boxColor: MutableState<Color>,
    onValueSelected: (String) -> Unit,
    modifier: Modifier,
    isEmployee: Boolean
) {
    val expanded = remember { mutableStateOf(false) }
    val inputFieldWidth = remember { mutableStateOf(0.dp) }
    val density = LocalDensity.current

    val jobCompleted = allStatusMap[selectedValue.value] == JobStatus.completed.name
    val isPendingApproval = allStatusMap[selectedValue.value] == JobStatus.pendingApproval.name

    val isDropdownDisabled = jobCompleted || (isEmployee && isPendingApproval)

    val handleExpandedState = {
        if (!isDropdownDisabled) {
            expanded.value = !expanded.value
        }
    }

    Row(
        modifier = modifier
            .height(40.dp)
            .width(160.dp)
            .background(color = boxColor.value, shape = RoundedCornerShape(24))
            .onGloballyPositioned { coordinates ->
                inputFieldWidth.value = with(density) { coordinates.size.width.toDp() }
            }
            .clickable(enabled = !isDropdownDisabled) { handleExpandedState() }
            .padding(horizontal = 12.dp, vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .weight(1f)
                .padding(start = 4.dp)
        ) {
            Text(
                text = selectedValue.value,
                color = Color.White,
                fontSize = 12.sp
            )
        }

        if (!isDropdownDisabled) {
            Icon(
                painter = painterResource(Res.drawable.dropdown_arrow),
                contentDescription = null,
                tint = Color.White,
                modifier = Modifier
                    .padding(start = 8.dp)
                    .size(9.dp)
            )
        }
    }

    if (!isDropdownDisabled) {
        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false },
            modifier = Modifier.width(inputFieldWidth.value)
        ) {
            jobStatusMap.keys.forEach { key ->
                DropdownMenuItem(
                    onClick = {
                        selectedValue.value = key
                        expanded.value = false
                        onValueSelected(key)
                    },
                    text = {
                        Text(
                            text = key,
                            softWrap = true
                        )
                    }
                )
            }
        }
    }
}