package com.hyperether.goodjob.util

import androidx.compose.runtime.Composable
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.job_status_cancelled
import com.hyperether.goodjob.resources.job_status_completed
import com.hyperether.goodjob.resources.job_status_draft
import com.hyperether.goodjob.resources.job_status_in_progress
import com.hyperether.goodjob.resources.job_status_on_hold
import com.hyperether.goodjob.resources.job_status_open
import com.hyperether.goodjob.resources.job_status_pending_approval
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobStatusMap(): Map<String, String> {
    return mapOf(
        stringResource(Res.string.job_status_draft) to JobStatus.draft.name,
        stringResource(Res.string.job_status_open) to JobStatus.open.name,
        stringResource(Res.string.job_status_in_progress) to JobStatus.in_progress.name,
        stringResource(Res.string.job_status_on_hold) to JobStatus.on_hold.name,
        stringResource(Res.string.job_status_pending_approval) to JobStatus.pendingApproval.name,
        stringResource(Res.string.job_status_cancelled) to JobStatus.cancelled.name,
        stringResource(Res.string.job_status_completed) to JobStatus.completed.name
    )
}

@Composable
fun JobStatusMapEmployee(): Map<String, String> {
    return mapOf(
        stringResource(Res.string.job_status_in_progress) to JobStatus.in_progress.name,
        stringResource(Res.string.job_status_on_hold) to JobStatus.on_hold.name,
        stringResource(Res.string.job_status_pending_approval) to JobStatus.pendingApproval.name
    )
}