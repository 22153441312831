package com.hyperether.goodjob.scenes.jobs

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.models.EmployeeRequest
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch

class JobViewModel(
    val repository: Repository,
) : ViewModel() {


    val showEditPopUp: MutableState<Boolean> = mutableStateOf(false)
    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)

    val jobs: Flow<List<com.hyperether.goodjob.database.Job>> = repository.getJobs()


    fun deleteSelectedJobs(selectedJobs: List<com.hyperether.goodjob.database.Job>, onAllDeleted: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch {
            try {
                selectedJobs.forEachIndexed { index, job ->
                    val isLastJob = index == selectedJobs.size - 1

                    job._id?.let { id ->
                        val result = repository.deleteJobById(id)
                        if (result is Resource.Success && isLastJob) {
                            onAllDeleted()
                        } else if (result is Resource.Error) {
                            onError(id)
                            return@launch
                        }
                    }
                }
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }

    fun deleteJob(id: String, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.deleteJobById(id)

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }
                is Resource.Error -> {
                    onError()
                }
            }
        }
    }
}