package com.hyperether.planner.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.hyperether.planner.model.Event
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format.DayOfWeekNames
import kotlinx.datetime.isoDayNumber

@Composable
fun ScheduleMonthPlannerWeb(
    listOfPlans: MutableList<Plan>,
    month: Int,
    year: Int,
    completedColor: Color,
    pendingColor: Color,
    delayedColor: Color,
    selectedDay: State<Int?>,
    borderPathColor: Color = Color(231, 231, 231),
    onEventClicked: (Event) -> Unit,
    onEventHovered: (Event?) -> Unit
) {
    val list = mutableStateListOf<CalendarField>()
    val numberOfCells = 35
    val eventMap = groupEventsByDay(listOfPlans)

    val currentMonthDays = monthDays(year, month)
    val firstDayOfWeek = LocalDate(year, month, 1).dayOfWeek
    val prevMonth: Int
    val prevYear: Int
    if (month == 1) {
        prevMonth = 12
        prevYear = year - 1
    } else {
        prevMonth = month - 1
        prevYear = year
    }
    val previousMonthDays = monthDays(prevYear, prevMonth)

    val startOffset = (firstDayOfWeek.isoDayNumber - 1) % 7

    for (i in 0 until startOffset) {
        list.add(CalendarField(previousMonthDays - startOffset + i + 1, false))
    }
    for (i in 1..currentMonthDays) {
        list.add(CalendarField(i, true))
    }
    val remainingCells = numberOfCells - list.size
    for (i in 1..remainingCells) {
        list.add(CalendarField(i, false))
    }

    Column {
        Box(modifier = Modifier.fillMaxWidth().height(1.dp).background(borderPathColor))
        Row(
            modifier = Modifier.fillMaxWidth().background(Color(0xFFF6F7F9))
                .padding(vertical = 10.dp)
        ) {
            Text(
                DayOfWeekNames.ENGLISH_ABBREVIATED.names[0].uppercase(),
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(1f).padding(start = 10.dp),
                color = Color(0xFF6F7C8E),
                fontWeight = FontWeight.Bold
            )
            Text(
                DayOfWeekNames.ENGLISH_ABBREVIATED.names[1].uppercase(),
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(1f).padding(start = 10.dp),
                color = Color(0xFF6F7C8E),
                fontWeight = FontWeight.Bold
            )
            Text(
                DayOfWeekNames.ENGLISH_ABBREVIATED.names[2].uppercase(),
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(1f).padding(start = 10.dp),
                color = Color(0xFF6F7C8E),
                fontWeight = FontWeight.Bold
            )
            Text(
                DayOfWeekNames.ENGLISH_ABBREVIATED.names[3].uppercase(),
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(1f).padding(start = 10.dp),
                color = Color(0xFF6F7C8E),
                fontWeight = FontWeight.Bold
            )
            Text(
                DayOfWeekNames.ENGLISH_ABBREVIATED.names[4].uppercase(),
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(1f).padding(start = 10.dp),
                color = Color(0xFF6F7C8E),
                fontWeight = FontWeight.Bold
            )
            Text(
                DayOfWeekNames.ENGLISH_ABBREVIATED.names[5].uppercase(),
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(1f).padding(start = 10.dp),
                color = Color(0xFF6F7C8E),
                fontWeight = FontWeight.Bold
            )
            Text(
                DayOfWeekNames.ENGLISH_ABBREVIATED.names[6].uppercase(),
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(1f).padding(start = 10.dp),
                color = Color(0xFF6F7C8E),
                fontWeight = FontWeight.Bold
            )
        }

        LazyVerticalGrid(
            columns = GridCells.Fixed(7),
            modifier = Modifier.fillMaxWidth()
        ) {
            if (list.isNotEmpty()) {
                itemsIndexed(list.takeIf { it.isNotEmpty() } ?: emptyList()) { index, day ->
                    Box(
                        modifier = Modifier.fillMaxWidth().aspectRatio(1.3f)
                            .border(1.dp, color = borderPathColor)
                    ) {
                        Column {
                            Box(
                                modifier = Modifier.size(40.dp),
                                contentAlignment = Alignment.Center
                            ) {
                                val selected =
                                    selectedDay.value == day.day && index > startOffset - 1 && index < list.size - remainingCells
                                Box(
                                    modifier = Modifier
                                        .size(30.dp),
                                    contentAlignment = Alignment.Center // Centers the content within the Box
                                ) {
                                    Text(
                                        text = "${day.day}",
                                        color = Color.Black,
                                        textAlign = TextAlign.Center // Aligns the text horizontally
                                    )
                                }
                            }
                            if (day.fromCurrentMonth) {
                                val eventList: List<Event>? = eventMap.get(day.day)
                                if (eventList?.isNotEmpty() == true) {
                                    Column(
                                        modifier = Modifier.padding(10.dp).verticalScroll(
                                            rememberScrollState()
                                        )
                                    ) {
                                        eventList.forEach { event ->
                                            val modifier =
                                                Modifier.clickable {
                                                    onEventClicked(event)
                                                }
                                                    .pointerInput(Unit) {
                                                        awaitPointerEventScope {
                                                            while (true) {
                                                                val pe = awaitPointerEvent()
                                                                val pointer =
                                                                    pe.changes.firstOrNull()
                                                                pointer?.let {
                                                                    if (it.position.x > 5 && it.position.x < (size.width - 5) &&
                                                                        it.position.y > 5 && it.position.y < (size.height - 5))
                                                                    {
                                                                        onEventHovered(event)
                                                                    } else {
                                                                        onEventHovered(null)
                                                                    }
                                                                }

                                                            }
                                                        }
                                                    }
                                            when (event.status) {
                                                EventStatus.DELAYED,
                                                EventStatus.REJECTED -> {
                                                    Box(
                                                        modifier = modifier.fillMaxWidth()
                                                            .height(40.dp)
                                                            .clip(RoundedCornerShape(8.dp))
                                                            .background(delayedColor)
                                                            .padding(4.dp),
                                                        contentAlignment = Alignment.Center
                                                    ) {
                                                        Text(
                                                            event.title ?: "",
                                                            maxLines = 1,
                                                            overflow = TextOverflow.Ellipsis
                                                        )
                                                    }
                                                }

                                                EventStatus.COMPLETED -> {
                                                    Box(
                                                        modifier = modifier.fillMaxWidth()
                                                            .height(40.dp)
                                                            .clip(RoundedCornerShape(8.dp))
                                                            .background(completedColor)
                                                            .padding(4.dp),
                                                        contentAlignment = Alignment.Center
                                                    ) {
                                                        Text(
                                                            event.title ?: "",
                                                            maxLines = 1,
                                                            overflow = TextOverflow.Ellipsis
                                                        )
                                                    }
                                                }

                                                EventStatus.PENDING,
                                                EventStatus.IN_PROGRESS -> {
                                                    Box(
                                                        modifier = modifier.fillMaxWidth()
                                                            .height(40.dp)
                                                            .clip(RoundedCornerShape(8.dp))
                                                            .background(pendingColor)
                                                            .padding(4.dp),
                                                        contentAlignment = Alignment.Center
                                                    ) {
                                                        Text(
                                                            event.title ?: "",
                                                            maxLines = 1,
                                                            overflow = TextOverflow.Ellipsis
                                                        )
                                                    }
                                                }
                                            }
                                            Spacer(Modifier.height(4.dp))
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
