package com.hyperether.goodjob.scenes.employees

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.UserTeam
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class EmployeeDetailsViewModel(val repository: Repository) : ViewModel() {
    val fullNameInput: MutableState<String> = mutableStateOf("")
    val phoneInput: MutableState<String> = mutableStateOf("")
    val emailInput: MutableState<String> = mutableStateOf("")
    val zipCountryInput: MutableState<String> = mutableStateOf("")
    val addressInput: MutableState<String> = mutableStateOf("")
    val status: MutableState<String> = mutableStateOf("")
    val skills: MutableState<String> = mutableStateOf("")
    val team: MutableState<String> = mutableStateOf("")
    val selectedTeam: MutableState<UserTeam?> = mutableStateOf(null)
    val selectedSkill: MutableState<String?> = mutableStateOf(null)
    val upcomingLeaves: MutableState<String> = mutableStateOf("")

    private val _selected_employee_remote = MutableStateFlow(User())
    val selected_employee_remote: StateFlow<User> = _selected_employee_remote

    fun getEmployeeById(id: String) {
        viewModelScope.launch {
            when (val result = repository.getUserById(id)) {
                is Resource.Success -> {
                    result.data?.let { _selected_employee_remote.value = it }
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    fun updateEmployeeById(
        userId: String,
        onSuccess: () -> Unit,
        onError: () -> Unit
    ) {
        val employee = User(
            fullName = fullNameInput.value,
            email = emailInput.value,
            address = addressInput.value,
            city = zipCountryInput.value,
            status = status.value,
            teams = listOf(selectedTeam.value),
            role = "employee",
            phone = phoneInput.value,
            skills = listOf(selectedSkill.value ?: ""),
        )
        viewModelScope.launch {
            when (val result = repository.updateUser(userId, employee.toEmployeeRequest())) {
                is Resource.Success -> {
                    result.data?.let { _selected_employee_remote.value = it }
                    println("User updated successfully")
                    onSuccess()
                }

                is Resource.Error -> {
                    println("Error while adding user")
                    onError()
                }
            }
        }
    }
}