package com.hyperether.goodjob.repository.remote

import kotlinx.browser.window

actual object ApiConfig {
    actual val baseURL: String
        get() {
            return if (window.location.origin.contains("localhost")) {
                "https://dev.goodjob.hyperether.com"
            } else {
                window.location.origin
            }
        }
}