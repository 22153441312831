package com.hyperether.goodjob.scenes.dashboard.map


import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.runtime.Composable
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.map.MapComponent
import com.hyperether.goodjob.repository.remote.model.LatLng
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.places
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import org.jetbrains.compose.resources.stringResource

@Composable
fun DashboardMapScreen(
    viewModel: DashboardMapViewModel,
    navHostController: NavHostController,
) {

    val currentPosition = remember { mutableStateOf<LatLng?>(null) }

    Column {
        MobileHeaderBackAndAction(title = stringResource(Res.string.places),
            actionEnabled = true,
            back = { navHostController.popBackStack() }) {

        }

        Box(
            modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.BottomCenter
        ) {
            key(viewModel.markers.size, currentPosition.value) {
                MapComponent(
                    viewModel.markers, currentPosition
                )
            }

            LazyRow(modifier = Modifier.fillMaxWidth()) {
                items(viewModel.jobList) { job ->
                    MapJobCard(job.jobTitle ?: "",
                        viewModel.sitesMap[job.id]?.addressObject?.address ?: "",
                        job.status ?: "",
                        modifier = Modifier.clickable {
                            viewModel.sitesMap[job.id]?.addressObject?.let { addressObject ->
                                addressObject.lat?.let { lat ->
                                    addressObject.long?.let { lon ->
                                        currentPosition.value = LatLng(lat, lon)
                                    }
                                }
                            }
                        })
                }
            }
        }
    }
}