package com.hyperether.planner.model

import kotlinx.datetime.LocalDateTime

data class DashboardPlan(
    val time: String,
    val events: List<DashboardEvent>?
)

data class DashboardEvent(
    val status: EventStatus,
    val from: LocalDateTime,
    val to: LocalDateTime,
    val title: String? = null
)