package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.report_submitted
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import org.jetbrains.compose.resources.stringResource

@Composable
fun CompletionReportSubmit(
    viewModel: JobDetailsViewModel,
    navHostController: NavHostController,
    job: Job,
    onUploadDocumentClick: (Boolean) -> Unit
) {
    val images = remember { mutableStateListOf<String>() }
    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        CompletionReportSubmitView(
            viewModel,
            submit = {
                viewModel.showLoader.value = true
                job.id?.let {
                    viewModel.submitCompletionReport(it, onSuccess = {
                        viewModel.showLoader.value = false
                        viewModel.showReportSubmitSuccess.value = true
                        viewModel.loadJobDetails(it)
                    }, onError = {
                        viewModel.showLoader.value = false
                        viewModel.showPopUpError.value = true
                    })
                }
            },
            images,
            onUploadDocumentClick = onUploadDocumentClick
        )
        if (viewModel.showReportSubmitSuccess.value) {
            PopUpDialog(
                onDismiss = {
                    viewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                },
                title = stringResource(Res.string.success).uppercase(),
                message = stringResource(Res.string.report_submitted),
                onPositiveBtnClicked = {
                    viewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                },
                positiveBtnText = stringResource(Res.string.ok)
            )
        }
        if (viewModel.showPopUpError.value) {
            ErrorDialog(
                onDismiss = { viewModel.showPopUpError.value = false },
                title = stringResource(Res.string.error).uppercase(),
                message = viewModel.errorText.value
            )
        }
        if (viewModel.showLoader.value) {
            FullScreenProgressBar()
        }
    }
}