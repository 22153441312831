package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.CompletionReportStatus
import com.hyperether.goodjob.repository.remote.model.CompletionReportResponse
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.adding_proof
import com.hyperether.goodjob.resources.additional_notes_report
import com.hyperether.goodjob.resources.approve
import com.hyperether.goodjob.resources.client_feedback
import com.hyperether.goodjob.resources.client_signature
import com.hyperether.goodjob.resources.employee_comments
import com.hyperether.goodjob.resources.final_inspection
import com.hyperether.goodjob.resources.health_confirmation
import com.hyperether.goodjob.resources.no
import com.hyperether.goodjob.resources.no_damage
import com.hyperether.goodjob.resources.reject
import com.hyperether.goodjob.resources.status_check
import com.hyperether.goodjob.resources.was_damage_caused
import com.hyperether.goodjob.resources.were_safety_met
import com.hyperether.goodjob.resources.yes
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.GrayBackgroundWarning
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun CompletionReportOverviewView(
    viewModel: JobDetailsViewModel,
    completionReportResponse: CompletionReportResponse?,
    isEmployee: Boolean,
    jobFinished: Boolean,
    onApproveClick: () -> Unit,
    onRejectClick: () -> Unit
) {
    val completionReportImages = viewModel.completionReportImagePainters.collectAsState()
    val clientSignatureImages = viewModel.clientSignaturePainters.collectAsState()

    LaunchedEffect(Unit) {
        viewModel.fileIds.value.let {
            if (viewModel.fileIds.value.isNotEmpty())
                for (fileId in it)
                    fileId?.let { it1 ->
                        viewModel.downloadCompletionReportFile(
                            it1,
                            onDownloaded = { painter ->
                                viewModel.addImagePainterCompletionReport(fileId to painter)
                            })
                    }
        }
        viewModel.clientSignature.value.let {
            if (it.isNotEmpty())
                viewModel.downloadCompletionReportFile(it,
                    onDownloaded = { bitmapPainter ->
                        viewModel.addImagePainterClientSignature(it to bitmapPainter)
                    })
        }
    }
    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp)
    ) {
        if (completionReportResponse != null &&
            completionReportResponse.status != CompletionReportStatus.rejected.name
        ) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .align(Alignment.BottomCenter),
                verticalArrangement = Arrangement.spacedBy(16.dp)
            ) {
                // Title
                Text(
                    text = stringResource(Res.string.adding_proof),
                    style = MaterialTheme.typography.h6,
                    modifier = Modifier.padding(bottom = 16.dp)
                )

                if (!getPlatformChecker().isMobile()) {
                    FlowRow(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.spacedBy(16.dp),
                        verticalArrangement = Arrangement.spacedBy(16.dp)
                    ) {
                        completionReportImages.value.let {
                            for ((imageName, imagePainter) in it) {
                                Column(
                                    modifier = Modifier.width(200.dp)
                                ) {
                                    ImageRow(
                                        isDeleteVisible = false,
                                        isEmployee = isEmployee,
                                        imageName = imageName,
                                        imagePainter = imagePainter,
                                        onDeleteImageClick = {

                                        }
                                    )
                                }
                            }
                        }
                    }
                } else {
                    // Images Row
                    Column(
                        modifier = Modifier.fillMaxWidth(),
                        verticalArrangement = Arrangement.spacedBy(16.dp)
                    ) {
                        completionReportImages.value.let {
                            for ((imageName, imagePainter) in it) {
                                Row(
                                    horizontalArrangement = Arrangement.spacedBy(8.dp),
                                    modifier = Modifier.fillMaxWidth()
                                ) {
                                    ImageRow(
                                        isDeleteVisible = false,
                                        isEmployee = viewModel.isEmployee(),
                                        imageName = imageName,
                                        imagePainter = imagePainter,
                                        onDeleteImageClick = { }
                                    )
                                }
                                Spacer(modifier = Modifier.height(4.dp))
                            }
                        }
                    }
                }

                Spacer(modifier = Modifier.height(16.dp))

                // Status Check Section
                SectionTitle(stringResource(Res.string.status_check))
                if (completionReportResponse.damage == true) {
                    Column() {
                        IssueReportView(stringResource(Res.string.yes))
                        Text(
                            text = completionReportResponse.damageDescription ?: "",
                            style = MaterialTheme.typography.body1,
                            color = Color.Gray,
                            modifier = Modifier.padding(vertical = 8.dp)
                                .background(GrayBackgroundWarning)
                        )
                    }
                } else {
                    CheckBoxItem(
                        question = stringResource(Res.string.was_damage_caused),
                        answer = stringResource(Res.string.no_damage),
                        modifier = Modifier.offset(x = (-10).dp),
                        checked = completionReportResponse.damage == false,
                        onCheckedChange = { }
                    )
                }

                if (completionReportResponse.correctFunctionality == false) {
                    Column() {
                        Text(
                            text = stringResource(Res.string.final_inspection),
                            style = MaterialTheme.typography.body1,
                            modifier = Modifier.padding(vertical = 8.dp)
                        )
                        IssueReportView(stringResource(Res.string.no))
                        Text(
                            text = completionReportResponse.reasonForIncompleteInspection ?: "",
                            style = MaterialTheme.typography.body1,
                            modifier = Modifier.padding(vertical = 8.dp)
                                .background(GrayBackgroundWarning)
                        )
                    }
                } else {
                    CheckBoxItem(
                        question = stringResource(Res.string.final_inspection),
                        answer = stringResource(Res.string.yes),
                        checked = true,
                        modifier = Modifier.offset(x = (-10).dp),
                        onCheckedChange = { }
                    )
                }

                // Client Feedback Section
                SectionTitle(stringResource(Res.string.client_feedback))
                Text(
                    "${stringResource(Res.string.client_feedback)}:",
                    style = MaterialTheme.typography.subtitle1
                )
                Text(
                    text = completionReportResponse.clientFeedback ?: "",
                    style = MaterialTheme.typography.body1,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(vertical = 8.dp)
                )
                Text(stringResource(Res.string.client_signature))
                Spacer(modifier = Modifier.height(8.dp))
                // Placeholder for Signature
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(150.dp)
                        .border(1.dp, Color.Gray)
                ) {
//                    Text(
//                        text = "Signature",
//                        modifier = Modifier.align(Alignment.Center),
//                        style = MaterialTheme.typography.caption,
//                        color = Color.Gray
//                    )

                    Row(
                        horizontalArrangement = Arrangement.spacedBy(8.dp),
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        clientSignatureImages.value.let {
                            for ((imageName, imagePainter) in it) {
                                ImageRow(
                                    isEmployee = viewModel.isEmployee(),
                                    imageName = imageName,
                                    imagePainter = imagePainter,
                                    onDeleteImageClick = { }
                                )
//                                Spacer(modifier = Modifier.height(4.dp))
                            }
                        }
                    }
                }

                Spacer(modifier = Modifier.height(16.dp))

                // Additional Notes Section
                SectionTitle(stringResource(Res.string.additional_notes_report))
                Text(
                    text = stringResource(Res.string.employee_comments),
                    style = MaterialTheme.typography.subtitle1
                )
                Text(
                    text = completionReportResponse.additionalNotes ?: "",
                    style = MaterialTheme.typography.body1,
                    modifier = Modifier.padding(vertical = 8.dp)
                )

                // Health and Safety Confirmation Section
                SectionTitle(stringResource(Res.string.health_confirmation))
                if (completionReportResponse.healthAndSafety == false) {
                    Column() {
                        Text(
                            text = stringResource(Res.string.were_safety_met),
                            style = MaterialTheme.typography.body1,
                            modifier = Modifier.padding(vertical = 8.dp)
                        )
                        IssueReportView(stringResource(Res.string.no))
                        Text(
                            text = completionReportResponse.unmetSafetyRequirements ?: "",
                            style = MaterialTheme.typography.body1,
                            modifier = Modifier.padding(vertical = 8.dp)
                                .background(GrayBackgroundWarning)
                        )
                    }
                } else {
                    CheckBoxItem(
                        question = stringResource(Res.string.were_safety_met),
                        answer = stringResource(Res.string.yes),
                        checked = true,
                        modifier = Modifier.offset(x = (-10).dp),
                        onCheckedChange = { }
                    )
                }

                if (!isEmployee && !jobFinished) {
                    FilledButton(
                        onClick = { onApproveClick() },
                        modifier = Modifier.fillMaxWidth(),
                        text = stringResource(Res.string.approve)
                    )
                    androidx.compose.material.OutlinedButton(
                        onClick = {
                            onRejectClick()
                        },
                        modifier = Modifier.fillMaxWidth().height(50.dp),
                        border = BorderStroke(1.dp, BlueApp),
                        shape = RoundedCornerShape(50),
                        colors = androidx.compose.material.ButtonDefaults.outlinedButtonColors(
                            contentColor = Color.White,
                            backgroundColor = Color.Transparent
                        ),
                    ) {
                        Text(
                            text = stringResource(Res.string.reject),
                            maxLines = 1,
                            fontSize = 15.sp,
                            color = BlueApp
                        )
                    }
                }
            }
        }
    }
}