package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.ContactType
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_a_contact
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.check_soon
import com.hyperether.goodjob.resources.contact
import com.hyperether.goodjob.resources.contact_deleted
import com.hyperether.goodjob.resources.contact_type_contractor
import com.hyperether.goodjob.resources.contact_type_customer
import com.hyperether.goodjob.resources.contact_type_supplier
import com.hyperether.goodjob.resources.contacts
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.no_contacts_available
import com.hyperether.goodjob.resources.no_tasks_created
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.SearchField
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_CONTACT_ID
import com.hyperether.goodjob.util.FilterDialogStateManager
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ContactsScreen(
    navHostController: NavHostController,
    isMobileView: Boolean,
    viewModel: ContactsViewModel,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {
    viewModel.contactTypeMap = mapOf(
        stringResource(Res.string.contact_type_customer) to ContactType.customer.name,
        stringResource(Res.string.contact_type_supplier) to ContactType.supplier.name,
        stringResource(Res.string.contact_type_contractor) to ContactType.contractor.name
    )

    val contacts = viewModel.contacts.collectAsState(emptyList())
    val sites = viewModel.sites.collectAsState(emptyList())
    val selectedContacts = remember { mutableStateListOf<Contact>() }
    val isInSearchMode = remember { mutableStateOf(false) }
    val searchQuery = remember { mutableStateOf("") }

    LaunchedEffect(Unit) {
        FilterDialogStateManager.setDialogShowing(false)
        if (!getPlatformChecker().isMobile()) {
            viewModel.init()
        }
    }

    if (isMobileView) {
        Box(modifier = Modifier.fillMaxSize()) {

            Column {
                MobileHeaderSearchAdd(
                    title = stringResource(Res.string.contacts),
                    drawerState = drawerState,
                    scope = rememberCoroutineScope(),
                    add = {
                        if (getPlatformChecker().isMobile()) {
                            navHostController.navigate(Screen.AddNewContact.route + "/$DEFAULT_CONTACT_ID")
                        } else {
                            navHostController.navigate(Screen.AddNewContact.route + "?contactId=")
                        }
                    },
                    search = {
                        isInSearchMode.value = true
                    },
                    onCancelSearch = {
                        isInSearchMode.value = false
                        searchQuery.value = ""
                    },
                    user = prefsManager.getUser()
                )
                if (viewModel.selectedContacts.size > 0) {
                    if (isInSearchMode.value) {
                        Spacer(modifier = Modifier.height(15.dp))
                        SearchField(searchQuery)
                    } else {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier.fillMaxWidth()
                                .padding(horizontal = 16.dp, vertical = 10.dp)
                        ) {
                            if (!viewModel.isInEditMode.value) {
                                Text(
                                    text = stringResource(Res.string.edit),
                                    fontSize = 14.sp,
                                    color = TextColor,
                                    modifier = Modifier.clickable {
                                        viewModel.isInEditMode.value = true
                                        viewModel.showEditPopUp.value = true
                                    }.weight(1f),
                                    textAlign = TextAlign.Start
                                )
                            } else {
                                Text(
                                    text = stringResource(Res.string.cancel),
                                    fontSize = 14.sp,
                                    color = TextColor,
                                    modifier = Modifier.clickable {
                                        viewModel.isInEditMode.value = false
                                        viewModel.showEditPopUp.value = false
                                        selectedContacts.clear()
                                    }.weight(1f),
                                    textAlign = TextAlign.Start
                                )
                            }

                            if (!viewModel.isInEditMode.value) {
                                val leadingText =
                                    if (viewModel.selectedContacts.size == 1) stringResource(Res.string.contact) else stringResource(
                                        Res.string.contacts
                                    )
                                Text(
                                    text = "${stringResource(Res.string.total)}: ${viewModel.selectedContacts.size} $leadingText",
                                    fontSize = 14.sp,
                                    color = Color.Black,
                                    maxLines = 1,
                                    fontStyle = FontStyle.Italic,
                                    textAlign = TextAlign.Center
                                )
                            }

                            Row(
                                horizontalArrangement = Arrangement.End,
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Row(
                                    modifier = Modifier.clickable {
                                        FilterDialogStateManager.setDialogShowing(true)
                                    }, verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Text(
                                        text = stringResource(Res.string.filter),
                                        fontSize = 14.sp,
                                        color = TextColor,
                                        modifier = Modifier.padding(end = 8.dp),
                                        textAlign = TextAlign.End
                                    )
                                    Icon(
                                        painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                                        contentDescription = null,
                                        modifier = Modifier.size(20.dp)
                                    )
                                }
                            }
                        }
                        Divider(
                            modifier = Modifier.height(2.dp)
                                .background(Color.LightGray.copy(alpha = 0.1f))
                        )
                    }
                }
                if (viewModel.selectedContacts.isEmpty()) {
                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.no_contacts_available),
                        color = Color.Black,
                        fontSize = 24.sp,
                        textAlign = TextAlign.Center,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.fillMaxWidth()
                            .padding(top = 60.dp, start = 16.dp, end = 16.dp)
                    )
                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.add_a_contact),
                        color = Color.Gray,
                        textAlign = TextAlign.Center,
                        fontSize = 16.sp,
                        modifier = Modifier.padding(top = 20.dp, start = 16.dp, end = 16.dp)
                    )
                } else {
                    val filteredContactsSearch = if (searchQuery.value.isNotBlank()) {
                        viewModel.selectedContacts.filter { contact ->
                            contact.name?.contains(
                                searchQuery.value,
                                ignoreCase = true
                            ) == true || contact.personName?.contains(
                                searchQuery.value, ignoreCase = true
                            ) == true || contact.addressObject?.address?.contains(
                                searchQuery.value, ignoreCase = true
                            ) == true
                        }
                    } else {
                        viewModel.selectedContacts
                    }
                    ContactsList(
                        contacts = filteredContactsSearch,
                        contactSites = sites.value,
                        selectedContacts = selectedContacts,
                        viewModel.isInEditMode.value,
                        navHostController
                    )
                }

            }
            if (viewModel.showEditPopUp.value) {
                Box(
                    modifier = Modifier.fillMaxSize().background(Color.Black.copy(alpha = 0.05f))
                )

                Box(
                    modifier = Modifier.align(Alignment.BottomEnd)
                ) {
                    EditContactsDialog(showDialog = viewModel.showEditPopUp.value,
                        contactList = selectedContacts,
                        onDelete = {
                            if (selectedContacts.isNotEmpty()) {
                                viewModel.showLoader.value = true
                                viewModel.deleteSelectedContacts(selectedContacts, onAllDeleted = {
                                    viewModel.isInEditMode.value = false
                                    viewModel.showPopUpSuccess.value = true
                                    viewModel.showEditPopUp.value = false
                                    viewModel.showLoader.value = false
                                }, onError = {
                                    viewModel.isInEditMode.value = false
                                    viewModel.showPopUpError.value = true
                                    viewModel.showLoader.value = false
                                    viewModel.showEditPopUp.value = false
                                })
                            }
                        },
                        onExportList = {},
                        onImportList = {})
                }
            }
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { viewModel.showPopUpSuccess.value = false },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.contact_deleted),
                    onPositiveBtnClicked = { viewModel.showPopUpSuccess.value = false },
                    positiveBtnText = stringResource(Res.string.ok)
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
        }
    } else {
        ContactsWeb(navHostController, viewModel)
    }

    if (FilterDialogStateManager.dialogShowing.value) {
        FilterContactsDialog(contacts.value.mapNotNull { it.addressObject?.city }.distinct(),
            contacts.value.mapNotNull { it.industry }.distinct(),
            contacts.value.mapNotNull { it.contactType }.distinct().map { contactType ->
                viewModel.contactTypeMap.entries.find { item -> item.value == contactType }?.key
                    ?: contactType
            },
            onDismiss = {
                FilterDialogStateManager.setDialogShowing(false)
            },
            onApply = { selectedCities, selectedIndustries, selectedContactTypes ->
                FilterDialogStateManager.setDialogShowing(false)
                viewModel.selectedCities.value = selectedCities
                viewModel.selectedIndustries.value = selectedIndustries
                viewModel.selectedContactTypes.value = selectedContactTypes.map { contactType ->
                    viewModel.contactTypeMap[contactType] ?: contactType
                }.toSet()
                viewModel.filterContacts()
            })
    }
}