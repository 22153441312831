package com.hyperether.goodjob.scenes.dashboard.map

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.map.Marker
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.repository.Repository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

class DashboardMapViewModel(val repository: Repository) : ViewModel() {

    val markers = mutableStateListOf<Marker>()
    val jobList = mutableStateListOf<Job>()
    val sitesMap = mutableStateMapOf<String, Site>()

    init {
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }
        CoroutineScope(Dispatchers.Default).launch {
            repository.getJobsFlow()?.collect {
                if (it.isNotEmpty()) {
                    jobList.clear()
                    val siteMap = mutableMapOf<String, Site>()
                    it.forEach { job ->
                        job.contactSiteId?.let {
                            val site = getSiteById(it)
                            site?.id?.let { id -> siteMap[job.id!!] = site }
                        }
                        jobList.add(job)
                    }
                    sitesMap.clear()
                    sitesMap.putAll(siteMap)
                    jobList.forEach {
                        sitesMap[it.id]?.addressObject?.let { addressObject ->
                            addressObject.lat?.let { lat ->
                                addressObject.long?.let { lon ->
                                    markers.add(
                                        Marker(
                                            lat,
                                            lon,
                                            it.jobTitle ?: "",
                                            it.status ?: ""
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

    private suspend fun getSiteById(id: String): Site? {
        return repository.getSiteById(id)
    }
}