package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Card
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewModelScope
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import com.hyperether.goodjob.database.Employee
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.clock_small
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.employees_job_item
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.icon
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.map
import com.hyperether.goodjob.resources.map_job_item
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.plus
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.resources.user_check
import com.hyperether.goodjob.resources.user_check_job_item
import com.hyperether.goodjob.scenes.employees.RoundedCornerCheckbox
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.PopUpDialog
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobsScreen(viewModel: JobViewModel, navHostController: NavHostController) {


    val selectedJobs = remember { mutableStateListOf<com.hyperether.goodjob.database.Job>() }

    val jobs = remember { mutableStateListOf<com.hyperether.goodjob.database.Job>() }


    LaunchedEffect(Unit) {
        viewModel.jobs.collect { jobsList ->
            jobs.clear()
            jobs.addAll(jobsList)
        }
    }
    println(jobs.toList())

    Box(modifier = Modifier.fillMaxSize()) {

        Column {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 20.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.baseline_person_24),
                    contentDescription = "Avatar",
                    modifier = Modifier
                        .size(48.dp)
                        .clip(CircleShape)
                        .background(Color.LightGray)
                )

                Text(
                    text = stringResource(Res.string.jobs),
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier
                        .weight(1f)
                        .padding(start = 16.dp)
                )

                Row(
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .wrapContentWidth()
                        .padding(start = 8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.icon),
                        contentDescription = "",
                        modifier = Modifier
                            .padding(end = 20.dp)
                    )
                    Image(
                        painter = painterResource(Res.drawable.plus),
                        contentDescription = "",
                        modifier = Modifier
                            .padding()
                            .clickable {
                                navHostController.navigate(Screen.AboutContact.route)
                            }
                    )
                }
            }
            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 10.dp)
            ) {
                if (!viewModel.isInEditMode.value) {
                    Text(
                        text = stringResource(Res.string.edit),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier
                            .clickable {
                                viewModel.isInEditMode.value = true
                                viewModel.showEditPopUp.value = true
                            }
                            .weight(1f),
                        textAlign = TextAlign.Start
                    )
                } else {
                    Text(
                        text = stringResource(Res.string.cancel),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier
                            .clickable {
                                viewModel.isInEditMode.value = false
                                viewModel.showEditPopUp.value = false
                                selectedJobs.clear()
                            }
                            .weight(1f),
                        textAlign = TextAlign.Start
                    )
                }

                if (!viewModel.isInEditMode.value) {
                    Text(
                        text = "${stringResource(Res.string.total)}: ${jobs.size}",
                        fontSize = 14.sp,
                        color = Color.Black,
                        maxLines = 1,
                        fontStyle = FontStyle.Italic,
                        textAlign = TextAlign.Center
                    )
                }

                Row(
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.weight(1f)
                ) {
                    Text(
                        text = stringResource(Res.string.filter),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier.padding(end = 8.dp),
                        textAlign = TextAlign.End
                    )
                    Icon(
                        painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                        contentDescription = null,
                        modifier = Modifier.size(20.dp)
                    )
                }
            }
            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))

            JobsList(
                jobs,
                selectedJobs,
                isInEditMode = viewModel.isInEditMode.value,
                navHostController
            )
        }
        if (viewModel.showEditPopUp.value) {
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .background(Color.Black.copy(alpha = 0.05f))
            )

            Box(
                modifier = Modifier
                    .align(Alignment.BottomCenter)
                    .fillMaxWidth()
            ) {
                EditJobsDialog(
                    showDialog = viewModel.showEditPopUp.value,
                    jobs = selectedJobs,
                    onDelete = {
                        viewModel.showLoader.value = true
                        viewModel.deleteSelectedJobs(selectedJobs,
                            onAllDeleted = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpSuccess.value = true
                                viewModel.viewModelScope.launch {
                                    viewModel.jobs.collect { jobsList ->
                                        jobs.clear()
                                        jobs.addAll(jobsList)
                                    }
                                }
                            },
                            onError = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpError.value = true
                            })
                    },
                    onEdit = {},
                    onExportList = {},
                    onImportList = {}
                )
            }
        }
        if (viewModel.showPopUpSuccess.value) {
            PopUpDialog(
                onDismiss = { viewModel.showPopUpSuccess.value = false },
                title = "SUCCESS",
                message = "Job deleted successfully"
            )
        }
        if (viewModel.showPopUpError.value) {
            PopUpDialog(
                onDismiss = { viewModel.showPopUpError.value = false },
                title = "ERROR",
                message = "There was an error while deleting job"
            )
        }
        if (viewModel.showLoader.value) {
            FullScreenProgressBar(showProgressBar = viewModel.showLoader)
        }
    }
}

@Composable
fun JobsList(
    jobs: List<com.hyperether.goodjob.database.Job>,
    selectedJobs: MutableList<com.hyperether.goodjob.database.Job>?,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize()
    ) {
        items(jobs) { job ->
            JobsItem(
                job = job,
                isInEditMode = isInEditMode,
                onJobSelected = { selectedJob, isSelected ->
                    if (isSelected) {
                        selectedJobs?.add(selectedJob)
                    } else {
                        selectedJobs?.remove(selectedJob)
                    }
                },
                onItemClick = {
                    navHostController.navigate(Screen.JobDetails.route + "/${job.id}")
                },
            )
        }
    }
}

@Composable
fun JobsItem(
    job: com.hyperether.goodjob.database.Job,
    isInEditMode: Boolean,
    onJobSelected: (com.hyperether.goodjob.database.Job, Boolean) -> Unit,
    onItemClick: () -> Unit
) {
    var isChecked = remember { mutableStateOf(false) }

    Column(modifier = Modifier.fillMaxWidth().padding(16.dp).clickable { onItemClick() }) {
        job.dueDate?.let {
            Text(
                text = it,
                color = Color.Gray,
                fontSize = 12.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(bottom = 8.dp)
            )
        }
        Row {
            if (isInEditMode) {
                RoundedCornerCheckbox(
                    isChecked = isChecked.value,
                    onCheckedChange = { checked ->
                        isChecked.value = checked
                        onJobSelected(job, checked)
                    }
                )
            }
            Spacer(modifier = Modifier.width(10.dp))

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .border(1.dp, Color.Gray, RoundedCornerShape(8.dp))
            ) {
                Column(modifier = Modifier.padding(12.dp)) {
                    job.jobTitle?.let {
                        Text(
                            text = it,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            modifier = Modifier.padding(bottom = 8.dp)
                        )
                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.user_check_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.repeat?.let {
                            Text(
                                text = it,
                                color = BlueApp,
                            )
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.map_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.contactSiteName?.let {
                            Text(
                                text = it,
                                color = BlueApp,
                            )
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.clock_small),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.start_at?.let { start ->
                            job.end_at?.let { end ->
                                Text(
                                    text = "$start - $end",
                                    color = Color.Black,
                                    fontWeight = FontWeight.Bold
                                )
                            }
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.employees_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.employees?.let {
                            Text(
                                text = it,
                                color = BlueApp,
                            )
                        }
                    }
                }
            }
        }
    }
}