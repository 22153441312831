package com.hyperether.goodjob.scenes.jobs.components

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.assignees
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.clear_all
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.location
import com.hyperether.goodjob.resources.schedule
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.scenes.components.FilterSectionMobile
import com.hyperether.goodjob.scenes.components.toggleSelection
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun FilterJobsDialog(
    status: List<String>,
    employees: List<String?>,
    locations: List<String?>,
    schedule: List<String?>,
    initialSelectedStatus: Set<String>,
    initialSelectedEmployees: Set<String>,
    initialSelectedLocations: Set<String>,
    initialSelectedSchedule: Set<String>,
    onDismiss: () -> Unit,
    onApply: (status: Set<String>, employees: Set<String>, locations: Set<String>, schedule: Set<String>) -> Unit
) {
    // Initialize state with the current selections
    var selectedStatus by remember { mutableStateOf(initialSelectedStatus) }
    var selectedEmployees by remember { mutableStateOf(initialSelectedEmployees) }
    var selectedLocations by remember { mutableStateOf(initialSelectedLocations) }
    var selectedSchedule by remember { mutableStateOf(initialSelectedSchedule) }

    Box(modifier = Modifier.fillMaxSize()) {

        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.Black.copy(alpha = 0.5f))
                .clickable(
                    onClick = {},
                    indication = null,
                    interactionSource = remember { MutableInteractionSource() })
        ) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .align(Alignment.Center)
                    .background(Color.White)
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    androidx.compose.material.Icon(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "Back icon",
                        modifier = Modifier
                            .weight(1f)
                            .offset(x = (-30).dp)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                // Pass selected options when applying filters
                                onApply(
                                    selectedStatus,
                                    selectedEmployees,
                                    selectedLocations,
                                    selectedSchedule
                                )
                                onDismiss()
                            }
                    )

                    androidx.compose.material.Text(
                        text = stringResource(Res.string.filter),
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontSize = 20.sp,
                        modifier = Modifier
                            .weight(3f)
                            .align(Alignment.CenterVertically)
                    )

                    androidx.compose.material.Text(
                        text = stringResource(Res.string.clear_all),
                        textAlign = TextAlign.End,
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier
                            .weight(1f)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                selectedStatus = emptySet()
                                selectedLocations = emptySet()
                                selectedEmployees = emptySet()
                                selectedSchedule = emptySet()
                            }
                    )
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )

                Column(
                    modifier = Modifier.padding(start = 16.dp).fillMaxSize().verticalScroll(
                        rememberScrollState()
                    )
                ) {
                    if (status.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(15.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.status),
                            options = status,
                            selectedOptions = selectedStatus,
                            onOptionSelected = { option ->
                                selectedStatus = toggleSelection(selectedStatus, option)
                            }
                        )
                    }

                    if (employees.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(10.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.assignees),
                            options = employees,
                            selectedOptions = selectedEmployees,
                            onOptionSelected = { option ->
                                selectedEmployees = toggleSelection(selectedEmployees, option)
                            }
                        )
                    }

                    if (locations.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(10.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.location),
                            options = locations,
                            selectedOptions = selectedLocations,
                            onOptionSelected = { option ->
                                selectedLocations = toggleSelection(selectedLocations, option)
                            }
                        )
                    }

                    if (schedule.isNotEmpty()) {
                        Spacer(modifier = Modifier.height(10.dp))

                        FilterSectionMobile(
                            title = stringResource(Res.string.schedule),
                            options = schedule,
                            selectedOptions = selectedSchedule,
                            onOptionSelected = { option ->
                                selectedSchedule = toggleSelection(selectedSchedule, option)
                            }
                        )
                    }
                }
            }
        }
    }
}
