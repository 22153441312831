package com.hyperether.goodjob.scenes.dashboard.map

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.small_gray_map
import com.hyperether.goodjob.scenes.components.CircleWithInitial
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource

@Composable
fun MapJobCard(
    title: String,
    address: String,
    status: String,
    employeeName: String? = null,
    verticalPadding: Dp = 40.dp,
    horizontalPadding: Dp = 10.dp,
    shadow: Boolean = false,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = Modifier.width(300.dp).then(modifier)
            .padding(vertical = verticalPadding, horizontal = horizontalPadding)
    ) {

        Row(
            modifier = Modifier.width(300.dp)
                .background(Color.White, shape = RoundedCornerShape(12.dp))
                .then(
                    if (shadow) Modifier.border(
                        1.dp,
                        Color.Gray,
                        shape = RoundedCornerShape(12.dp)
                    ) else Modifier
                )
                .padding(12.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(modifier = Modifier.weight(1f)) {
                Row(
                    modifier = Modifier.padding(bottom = 8.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = title,
                        color = Color.Black,
                        fontWeight = FontWeight.Bold,
                        fontSize = 18.sp,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                    if (employeeName != null) {
                        Spacer(modifier = Modifier.width(8.dp))
                        Box(
                            modifier = Modifier
                                .size(16.dp)
                                .background(color = status.let {
                                    StatusColor().getStatusColor(
                                        it
                                    )
                                } ?: Green, shape = CircleShape)
                        )
                    }
                }
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Icon(
                        painter = painterResource(Res.drawable.small_gray_map),
                        contentDescription = "Map icon",
                        modifier = Modifier.padding(end = 4.dp)
                    )
                    Text(
                        text = address,
                        fontSize = 14.sp,
                        color = Color.Gray,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                }

            }

            if (employeeName == null)
                Box(
                    modifier = Modifier
                        .size(16.dp)
                        .background(color = status.let {
                            StatusColor().getStatusColor(
                                it
                            )
                        } ?: Green, shape = CircleShape)
                )
            employeeName?.let {
                CircleWithInitial(it)
            }
        }
    }
}

@Composable
fun PlacesJobCard(
    title: String,
    status: String,
    date: String,
    siteName: String,
    employeeName: String?,
    onClick: () -> Unit
) {
    Box(
        modifier = Modifier.width(300.dp)
            .padding(vertical = 20.dp, horizontal = 10.dp)
            .clickable {
                onClick()
            }
    ) {

        Row(
            modifier = Modifier.width(300.dp)
                .background(Color.White, shape = RoundedCornerShape(12.dp))
                .border(
                    1.dp,
                    Color.Gray,
                    shape = RoundedCornerShape(12.dp)
                )
                .padding(12.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(modifier = Modifier.weight(1f)) {
                Row(
                ) {
                    Column(
                        modifier = Modifier.weight(1f)
                    ) {
                        Row (
                            verticalAlignment = Alignment.CenterVertically
                        ){
                            Text(
                                text = title,
                                color = Color.Black,
                                fontWeight = FontWeight.Bold,
                                fontSize = 18.sp,
                                maxLines = 1,
                                overflow = TextOverflow.Ellipsis
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            Box(
                                modifier = Modifier
                                    .size(16.dp)
                                    .background(color = status.let {
                                        StatusColor().getStatusColor(
                                            it
                                        )
                                    } ?: Green, shape = CircleShape)
                            )
                        }

                        Text(
                            text = siteName,
                            fontSize = 14.sp,
                            color = Color.Gray,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis
                        )
                        Text(
                            text = date,
                            fontSize = 14.sp,
                            color = Color.Gray,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis
                        )
                    }
                }
            }

            CircleWithInitial(employeeName, size = 40.dp)
        }
    }
}