package com.hyperether.planner.util

import com.hyperether.planner.model.EventStatus

class EventStatusMapper {
    fun eventStatus(status: String): EventStatus {
        return when (status.uppercase()) {
            EventStatus.COMPLETED.name -> EventStatus.COMPLETED
            EventStatus.PENDING.name -> EventStatus.PENDING
            EventStatus.DELAYED.name -> EventStatus.DELAYED
            EventStatus.IN_PROGRESS.name -> EventStatus.IN_PROGRESS
            EventStatus.REJECTED.name -> EventStatus.REJECTED
            else -> EventStatus.PENDING
        }
    }
}