package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.scheduler
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.scheduler.components.SchedulerMonthJobCard
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.planner.model.Event
import com.hyperether.planner.ui.ScheduleMonthPlanner
import com.hyperether.planner.ui.ScheduleMonthPlannerWeb
import com.hyperether.planner.ui.SchedulerDayPlanner
import com.hyperether.planner.ui.WeekPlanner
import io.ktor.http.HttpHeaders.Position
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun SchedulerScreenWeb(
    viewModel: SchedulerViewModel,
    navController: NavHostController,
    completedColor: Color = Color(172, 229, 185),
    pendingColor: Color = Color(235, 231, 116),
    delayedColor: Color = Color(229, 172, 172)
) {
    val userState by viewModel.userStateWeb
    val jobState by viewModel.jobsStateWeb

    LaunchedEffect(Unit) {
        viewModel.getJobsWeb()
        viewModel.parseSchedule(jobState, userState)
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
            .padding(bottom = 40.dp)
            .pointerInput(Unit) {
                awaitPointerEventScope {
                    while (true) {
                        val event = awaitPointerEvent()
                        val pointer = event.changes.firstOrNull()
                        if (pointer != null) {
                            //todo check this - 800 it is not good but for some reason can not find real position on screen
                            viewModel.position.value = Position(pointer.position.x.toDp()-Dp(800f), pointer.position.y.toDp())
                            println("Position on screen is ${pointer.position}")
                            println("Position on screen is ${pointer.position.x.toDp()}")
                        }
                    }
                }
            }
    ) {

        WebViewHeader(
            isAddNewJob = true,
            isBackAvailable = false,
            isAddNewContact = false,
            title = stringResource(Res.string.scheduler),
            isImportAndAddVisible = false,
            navHostController = navController
        )

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = Dp(75f), end = Dp(75f), top = Dp(20f))
                .border(width = Dp(1f), color = RegisterBorder)
                .background(color = Color.White)
        ) {

            Row(
                modifier = Modifier.fillMaxWidth().padding(horizontal = 20.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {

                PeriodSwitcher(
                    text = viewModel.currentPeriod.value,
                    prev = {
                        viewModel.previous()
                        viewModel.periodChanged()
                        viewModel.parseSchedule(jobState, userState)
                    },
                    next = {
                        viewModel.next()
                        viewModel.periodChanged()
                        viewModel.parseSchedule(jobState, userState)
                    },
                    modifier = Modifier.width(300.dp)
                )

                Row(verticalAlignment = Alignment.CenterVertically) {
                    PeriodChooser(viewModel.periodState) {
                        viewModel.periodChanged()
                        viewModel.parseSchedule(jobState, userState)
                    }
                    Spacer(Modifier.width(28.dp))
                    Row(
                        modifier = Modifier.border(
                            1.dp,
                            color = BorderColor,
                            shape = RoundedCornerShape(6.dp)
                        ).padding(6.dp).padding(horizontal = 2.dp).clickable { viewModel.showFilterDialog.value = true },
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = stringResource(Res.string.filter)
                        )
                        Spacer(Modifier.width(6.dp))
                        Icon(
                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                            contentDescription = null,
                            modifier = Modifier.size(24.dp)
                        )
                    }
                }

            }

            Divider(modifier = Modifier.height(1.dp).background(Color.LightGray.copy(alpha = 0.1f)))

            if (jobState != null && viewModel.initialLoad.value == true) {
                viewModel.initialLoad.value = false
                viewModel.parseSchedule(jobState, userState)
            }

            when (viewModel.periodState.value) {
                PeriodType.DAY -> SchedulerDayPlanner(
                    listOfPlans = viewModel.listOfPlans,
                    completedColor,
                    pendingColor,
                    delayedColor,
                    namesIconsShown = true
                )

                PeriodType.WEEK -> WeekPlanner(
                    listOfPlans = viewModel.listOfPlans,
                    completedColor, pendingColor, delayedColor,
                    weekStart = viewModel.weekSelectionStart.value,
                    weekEnd = viewModel.weekSelectionEnd.value,
                    onEventClicked = { event ->
                        val selectedJob = jobState?.firstOrNull { it.id == event.jobId }
                        viewModel.selectedJobWeekly.value = selectedJob
                        selectedJob?.let { viewModel.updateSite(it) }
                        viewModel.hoveredEvent.value = null
                        viewModel.jobClicked.value = true
                    },
                    namesIconsShown = true,
                    onEventHovered = {
                        viewModel.hoveredEvent.value = it
                    }
                )

                PeriodType.MONTH -> {
                    ScheduleMonthPlannerWeb(
                        listOfPlans = viewModel.listOfPlans,
                        viewModel.monthSelectionLocalDate.value.monthNumber,
                        viewModel.monthSelectionLocalDate.value.year,
                        completedColor, pendingColor, delayedColor,
                        viewModel.monthSelectedDay,
                        onEventClicked = {event->
                            val selectedJob = jobState?.firstOrNull { it.id == event.jobId }
                            viewModel.selectedJobWeekly.value = selectedJob
                            selectedJob?.let { viewModel.updateSite(it) }
                            viewModel.hoveredEvent.value = null
                            viewModel.jobClicked.value = true
                        },
                        onEventHovered = {event->
                            viewModel.hoveredEvent.value = event
                        }
                    )

                }
            }
        }
    }
}

data class Position(
    val x: androidx.compose.ui.unit.Dp,
    val y: androidx.compose.ui.unit.Dp
)