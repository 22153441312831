package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.CompletionReportStatus
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.remote.model.CompletionReportResponse
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.check_mark
import com.hyperether.goodjob.resources.company
import com.hyperether.goodjob.resources.completed
import com.hyperether.goodjob.resources.completion_report
import com.hyperether.goodjob.resources.contact_information
import com.hyperether.goodjob.resources.date
import com.hyperether.goodjob.resources.details
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.end_time
import com.hyperether.goodjob.resources.estimated_duration
import com.hyperether.goodjob.resources.files
import com.hyperether.goodjob.resources.job_details
import com.hyperether.goodjob.resources.location_details
import com.hyperether.goodjob.resources.mark_step_completed
import com.hyperether.goodjob.resources.mark_step_pending
import com.hyperether.goodjob.resources.minutes
import com.hyperether.goodjob.resources.schedule
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.resources.start_time
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.steps
import com.hyperether.goodjob.scenes.jobs.components.JobDetailsAssigneesView
import com.hyperether.goodjob.scenes.jobs.components.JobDetailsHeaderView
import com.hyperether.goodjob.scenes.jobs.components.JobDetailsView
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.LandingPageMobileText
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.JobStatusMap
import com.hyperether.goodjob.util.JobStatusMapEmployee
import com.hyperether.goodjob.util.UploadManager
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobDetails(
    jobId: String,
    viewModel: JobDetailsViewModel,
    navHostController: NavHostController,
    onOpenPdf: (String) -> Unit,
    onUploadDocumentClick: (Boolean) -> Unit
) {

    viewModel.jobStatusMap = if (viewModel.isEmployee()) JobStatusMapEmployee() else JobStatusMap()

    var isAboutJobView = remember { mutableStateOf(true) }
    var selectedButtonIndex by remember { mutableStateOf(0) }
    var percentCompleted by remember { mutableStateOf(0) }

    val job by viewModel.jobDetails.collectAsState()
    val contact by viewModel.contact.collectAsState()

    LaunchedEffect(Unit) {
        viewModel.loadJobDetails(jobId)
    }
    LaunchedEffect(job) {
        val fileObjectsList = job?.fileObjects
        fileObjectsList?.let {
            for (fileObject in it)
                fileObject?.let {
                    viewModel.downloadFile(fileObject)
                }
        }
    }

    println(job.toString())
    println(contact.toString())

    if (getPlatformChecker().isMobile()) {

        Box(modifier = Modifier.fillMaxSize()) {
            Column {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp, vertical = 20.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "",
                        modifier = Modifier
                            .clickable {
                                UploadManager.setFileName("")
                                UploadManager.setDocumentStatus("")
                                UploadManager.setFileId("")
                                UploadManager.removeAll()
                                navHostController.popBackStack()
                            }
                    )

                    Text(
                        text = stringResource(Res.string.job_details),
                        fontSize = 18.sp,
                        color = Color.Black,
                        textAlign = TextAlign.Center,
                        modifier = Modifier
                            .weight(1f)
                            .padding(start = 16.dp)
                    )

                    Row(
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .wrapContentWidth()
                            .padding(start = 8.dp)
                    ) {
                        val jobCompleted =
                            job?.status?.let { it == JobStatus.completed.name } == true
                        if (!viewModel.isEmployee() && !jobCompleted) {
                            Image(
                                painter = painterResource(Res.drawable.edit),
                                contentDescription = "",
                                modifier = Modifier
                                    .padding()
                                    .clickable {
                                        if (getPlatformChecker().isMobile()) {
                                            navHostController.navigate(Screen.AddNewJob.route + "/$jobId")
                                        } else {
                                            navHostController.navigate(Screen.AddNewJob.route + "?jobId=$jobId")
                                        }
                                    }
                            )
                        }
                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )
                Column(
                    modifier = Modifier
                        .background(Color.LightGray.copy(alpha = 0.2f))
                        .padding(8.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(40.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.about_job),
                            color = if (isAboutJobView.value) BlueApp else Color.Gray,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(start = 40.dp)
                                .clickable {
                                    isAboutJobView.value = true
                                }
                        )

                        Spacer(modifier = Modifier.weight(0.5f))

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.completion_report),
                            color = if (isAboutJobView.value) Color.Gray else BlueApp,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(end = 20.dp)
                                .clickable {
                                    isAboutJobView.value = false
                                }
                        )
                    }
                }
                Box(
                    modifier = Modifier
                        .height(2.dp)
                        .fillMaxWidth(0.5f)
                        .background(if (isAboutJobView.value) BlueApp else BlueApp)
                        .align(if (isAboutJobView.value) Alignment.Start else Alignment.End)
                )

                if (isAboutJobView.value) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(start = 11.dp, end = 11.dp, top = 10.dp),
                        horizontalArrangement = Arrangement.SpaceEvenly,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        val buttons = listOf(
                            stringResource(Res.string.details),
                            stringResource(Res.string.steps),
                            stringResource(Res.string.files)
                        )

                        buttons.forEachIndexed { index, label ->
                            Box(
                                modifier = Modifier
                                    .weight(1f)
                                    .padding(5.dp)
                                    .border(1.dp, Color.LightGray, RoundedCornerShape(20))
                                    .background(
                                        color = if (selectedButtonIndex == index) BlueApp else Color.Transparent,
                                        shape = RoundedCornerShape(20)
                                    )
                                    .clickable { selectedButtonIndex = index },
                                contentAlignment = Alignment.Center
                            ) {
                                Text(
                                    text = label,
                                    color = if (selectedButtonIndex == index) Color.White else Color.LightGray,
                                    modifier = Modifier.padding(vertical = 12.dp)
                                )
                            }
                        }
                    }
                    if (selectedButtonIndex == 0) {
                        Column(
                            modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState())
                        ) {
                            JobDetailsHeaderView(job, viewModel)
                            JobDetailsAssigneesView(job)
                            JobDetailsView(
                                stringResource(Res.string.contact_information),
                                firstDetailText = contact?.personName ?: "",
                                firstDescriptionText = "",
                                secondDetailText = contact?.personPhone ?: "",
                                secondDescriptionText = "",
                                thirdDetailText = contact?.personEmail ?: "",
                                thirdDescriptionText = ""
                            )
                            JobDetailsView(
                                stringResource(Res.string.location_details),
                                firstDetailText = viewModel.contactSites.firstOrNull { it.id == job?.contactSiteId }?.addressObject?.address
                                    ?: "",
                                firstDescriptionText = "",
                                secondDescriptionText = stringResource(Res.string.site) + ": ",
                                secondDetailText = viewModel.contactSites.firstOrNull { it.id == job?.contactSiteId }?.siteName
                                    ?: "",
                                thirdDescriptionText = stringResource(Res.string.company) + ": ",
                                thirdDetailText = contact?.name ?: ""
                            )
                            JobDetailsView(
                                stringResource(Res.string.schedule),
                                firstDescriptionText = stringResource(Res.string.date) + ": ",
                                firstDetailText = DateTimeUtil.formatJobDetailsDate(
                                    job?.startDate ?: ""
                                ),
                                secondDetailText = job?.start_at ?: "",
                                secondDescriptionText = stringResource(Res.string.start_time) + ": ",
                                thirdDetailText = job?.end_at ?: "",
                                thirdDescriptionText = stringResource(Res.string.end_time) + ": "
                            )
                            Spacer(modifier = Modifier.height(20.dp))
                        }

                    } else if (selectedButtonIndex == 1) {
                        val steps = job?.steps.orEmpty().filterNotNull()
                        val totalSteps = steps.size
                        val completedSteps =
                            steps.count { it.stepStatus == Constants.STEP_STATUS_COMPLETED }
                        val percentCompleted =
                            if (totalSteps > 0) (completedSteps * 100) / totalSteps else 0

                        if (steps?.isNotEmpty() == true) {
                            if (steps[0]?.stepTitle?.isNotEmpty() == true) {
                                Column {
                                    Spacer(modifier = Modifier.height(15.dp))
                                    if (viewModel.isUserAssignedToJob(job)) {
                                        LinearProgressBar(percentCompleted = percentCompleted)
                                    } else {
                                        Text(
                                            text = "${percentCompleted}% " + stringResource(Res.string.completed),
                                            color = Green,
                                            fontWeight = FontWeight.W900,
                                            fontSize = 16.sp,
                                            modifier = Modifier.padding(start = 16.dp)
                                        )
                                    }
                                    Spacer(modifier = Modifier.height(15.dp))

                                    LazyColumn(modifier = Modifier.fillMaxSize()) {
                                        if (steps.isNotEmpty()) {
                                            itemsIndexed(steps.takeIf { it.isNotEmpty() }
                                                ?: emptyList()) { index, step ->
                                                step?.let {
                                                    val stepCounter =
                                                        remember { mutableStateOf(index + 1) }
                                                    StepsItem(
                                                        step = step,
                                                        isAssignedToCurrentUser = viewModel.isUserAssignedToJob(
                                                            job
                                                        ),
                                                        number = stepCounter,
                                                        onStepUpdateCallback = { stepState ->
                                                            step.stepStatus = stepState
                                                            viewModel.updateJobSteps(jobId,
                                                                steps = steps,
                                                                onSuccess = {},
                                                                onError = {})
                                                        })
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else if (selectedButtonIndex == 2) {
                        job?.let {
                            NotesAndDocumentsView(
                                images = viewModel.imagePainters.value,
                                documents = viewModel.pdfFilePaths.value,
                                job = it,
                                isEmployee = viewModel.isEmployee(),
                                onAddNoteClick = {
                                    viewModel.showNotePopUp.value = true
                                },
                                onOpenPdfClick = { fileName ->
                                    onOpenPdf(fileName)
                                },
                                onDeleteFileClick = { fileName ->
                                    viewModel.selectedFileName.value = fileName
                                    viewModel.selectedJob.value = it
                                    viewModel.showAreYouSurePopUpDeleteFile.value = true
                                },
                                onDeleteImageClick = { imageName ->
                                    viewModel.selectedImageName.value = imageName
                                    viewModel.selectedJob.value = it
                                    viewModel.showAreYouSurePopUpDeleteImage.value = true
                                },
                                onMenuClicked = { note: Note ->
                                    if (viewModel.selectedNoteId.value == note._id && viewModel.showNoteEditDeletePopUpMenu.value) {
                                        viewModel.showNoteEditDeletePopUpMenu.value = false
                                        viewModel.selectedNoteId.value = null
                                    } else {
                                        viewModel.selectedNoteId.value = note._id
                                        viewModel.noteToEditOrDelete.value = note
                                        viewModel.showNoteEditDeletePopUpMenu.value = true
                                    }
                                },
                                onEditNote = { note ->
                                    viewModel.noteInput.value = note.noteText.orEmpty()
                                    viewModel.showNotePopUp.value = true
                                    viewModel.isEditSelectedNote.value = true
                                    viewModel.selectedNoteId.value = note._id
                                },
                                onDeleteNote = {
                                    viewModel.showAreYouSurePopUpDeleteNote.value = true
                                    viewModel.selectedNoteId.value = null
                                },
                                isPopupVisible = { note ->
                                    viewModel.selectedNoteId.value == note._id && viewModel.showNoteEditDeletePopUpMenu.value
                                }
                            )
                        }
                    }
                } else {
                    if (viewModel.isUserAssignedToJob(job)) {
                        job?.let {
                            var report: CompletionReportResponse? = null
                            job?.completionReport?.let {
                                report =
                                    Mapper().jsonAsObject<CompletionReportResponse>(job?.completionReport)
                            }

                            if (report == null || report?.status == CompletionReportStatus.rejected.name) {
                                if (report != null) {
                                    viewModel.damageState.value = report?.damage == true
                                    viewModel.healthAndSafetyState.value =
                                        report?.healthAndSafety == true
                                    viewModel.correctFunctionalityState.value =
                                        report?.correctFunctionality == true
                                    viewModel.additionalNotesText.value =
                                        report?.additionalNotes ?: ""
                                    viewModel.clientFeedbackText.value =
                                        report?.clientFeedback ?: ""
                                    viewModel.filesList.clear()
                                    report?.files?.let {
                                        viewModel.filesList.addAll(it)
                                    }
                                }
                                CompletionReportSubmit(
                                    viewModel,
                                    navHostController,
                                    job!!,
                                    onUploadDocumentClick
                                )
                            } else {
                                CompletionReportOverview(viewModel, navHostController, job!!)
                            }
                        }
                    } else {
                        job?.let {
                            CompletionReportOverview(viewModel, navHostController, it)
                        }
                    }
                }
            }
        }
    } else {
        JobDetailsWeb(
            navHostController = navHostController,
            viewModel = viewModel,
            jobId,
            onOpenPdf,
            onUploadDocumentClick
        )
    }
    JobPopups(
        jobId,
        job,
        viewModel,
        navHostController
    )
}

@Composable
fun StepsItem(
    step: Step,
    isAssignedToCurrentUser: Boolean,
    number: MutableState<Int>,
    onStepUpdateCallback: (String) -> Unit
) {
    Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
        val isCompleted = step.stepStatus.equals(other = "Completed", ignoreCase = true)

        step.stepTitle?.let {
            Row {
                NumberCircle(
                    step = step,
                    number = number.value,
                    isAssignedToCurrentUser = isAssignedToCurrentUser,
                )
                Spacer(modifier = Modifier.width(15.dp))
                Text(
                    text = it,
                    color = Color.DarkGray,
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    textDecoration = if (!isAssignedToCurrentUser) TextDecoration.None else {
                        if (step.stepStatus == Constants.STEP_STATUS_COMPLETED)
                            TextDecoration.LineThrough else TextDecoration.None
                    }
                )
            }
        }
        step.stepDescription?.let {
            Text(
                text = it,
                color = TextColor,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp,
                textDecoration = if (!isAssignedToCurrentUser) TextDecoration.None else {
                    if (step.stepStatus == Constants.STEP_STATUS_COMPLETED)
                        TextDecoration.LineThrough else TextDecoration.None
                }
            )
        }
        Row {
            Text(
                text = "${stringResource(Res.string.estimated_duration)}:",
                color = Color.LightGray,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp,
                textDecoration = if (!isAssignedToCurrentUser) TextDecoration.None else {
                    if (step.stepStatus == Constants.STEP_STATUS_COMPLETED)
                        TextDecoration.LineThrough else TextDecoration.None
                }
            )
            Spacer(modifier = Modifier.width(5.dp))
            step.estimatedDuration?.let {
                Text(
                    text = it.toString() + " " + stringResource(Res.string.minutes),
                    color = TextColor,
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp,
                    textDecoration = if (!isAssignedToCurrentUser) TextDecoration.None else {
                        if (step.stepStatus == Constants.STEP_STATUS_COMPLETED)
                            TextDecoration.LineThrough else TextDecoration.None
                    }
                )
            }
        }
        if (!isAssignedToCurrentUser) {
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = "${stringResource(Res.string.status)}:",
                    color = Color.LightGray,
                    fontWeight = FontWeight.Normal,
                    fontSize = 14.sp
                )
                Spacer(modifier = Modifier.width(5.dp))
                if (step.stepStatus == Constants.STEP_STATUS_COMPLETED) { // TODO add enum for step status
                    Image(
                        painter = painterResource(Res.drawable.check_mark),
                        contentDescription = "Completed",
                        modifier = Modifier.size(16.dp),
                        colorFilter = if (isCompleted) ColorFilter.tint(Green) else ColorFilter.tint(
                            BlueApp
                        )
                    )
                }

                Spacer(modifier = Modifier.width(5.dp))
                Text(
                    text = step.stepStatus?.capitalize()
                        ?: stringResource(Res.string.completed).capitalize(),
                    color = if (isCompleted) Green else BlueApp,
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp
                )
            }
        }
        Spacer(modifier = Modifier.height(15.dp))

        if (isAssignedToCurrentUser) {
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (step.stepStatus != Constants.STEP_STATUS_COMPLETED) {
                    FilledButton(
                        onClick = {
                            onStepUpdateCallback(Constants.STEP_STATUS_COMPLETED)
                        },
                        modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp),
                        text = stringResource(Res.string.mark_step_completed),
                        isEnabled = true
                    )
                } else {
                    OutlinedButton(
                        onClick = { onStepUpdateCallback(Constants.STEP_STATUS_PENDING) },
                        border = BorderStroke(1.dp, BlueApp),
                        shape = RoundedCornerShape(50),
                        colors = ButtonDefaults.outlinedButtonColors(
                            contentColor = Color.White, containerColor = Color.Transparent
                        ),
                        modifier = Modifier
                            .padding(start = 16.dp, end = 16.dp, top = 16.dp)
                            .fillMaxWidth()
                            .height(50.dp)
                    ) {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Text(
                                text = stringResource(Res.string.mark_step_pending),
                                color = BlueApp,
                                fontSize = 15.sp,
                                textAlign = TextAlign.Center
                            )
                        }
                    }
                }
            }
            Spacer(modifier = Modifier.height(16.dp))
        }
    }
}

@Composable
fun NumberCircle(
    step: Step,
    number: Int,
    isAssignedToCurrentUser: Boolean
) {
    var currentNumber by remember { mutableStateOf(number) }
    val isCompleted = step.stepStatus.equals(other = "Completed", ignoreCase = true)

    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .size(25.dp)
            .border(
                width = 1.dp, color = if (isCompleted) Green else BlueApp,
                shape = CircleShape
            )
            .background(
                color = if (isCompleted) Green else Color.Transparent, shape = CircleShape
            )
    ) {
        if (isCompleted && isAssignedToCurrentUser) {
            Image(
                painter = painterResource(Res.drawable.check_mark),
                contentDescription = "Completed",
                modifier = Modifier.size(16.dp),
                colorFilter = ColorFilter.tint(Color.White)
            )
        } else {
            Text(
                text = currentNumber.toString().padStart(2, '0'),
                color = if (isCompleted) Color.White else BlueApp,
                fontWeight = FontWeight.Normal,
                fontSize = 12.sp
            )
        }
    }
}

@Composable
fun LinearProgressBar(percentCompleted: Int) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp)
    ) {
        Text(
            text = "$percentCompleted%",
            color = Green,
            fontWeight = FontWeight.Bold,
            fontSize = 16.sp
        )
        Spacer(modifier = Modifier.height(4.dp))
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(10.dp)
                .clip(RoundedCornerShape(50))
                .background(LandingPageMobileText)
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth(percentCompleted / 100f)
                    .height(10.dp)
                    .clip(RoundedCornerShape(50))
                    .background(Green)
            )
        }
    }
}
