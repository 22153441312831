package com.hyperether.goodjob.scenes.history

import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController

@Composable
fun HistoryScreen(viewModel: HistoryScreenViewModel, navController: NavHostController) {
    Text("${viewModel.testData.value}")
}

