package com.hyperether.goodjob.repository.remote.model

import com.hyperether.goodjob.models.User
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoginResponse(
    @SerialName("id") var id: Long? = 0,
    @SerialName("_id") var _id: String? = null,
    @SerialName("user") var user: User? = null,
    @SerialName("token") var token: String,
    @SerialName("refreshToken") var refreshToken: String,
)