package com.hyperether.goodjob.navigation

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.avatar_male
import com.hyperether.goodjob.resources.briefcase
import com.hyperether.goodjob.resources.briefcase_unselect
import com.hyperether.goodjob.resources.calendar
import com.hyperether.goodjob.resources.calendar_unselect
import com.hyperether.goodjob.resources.contacts
import com.hyperether.goodjob.resources.dahboard
import com.hyperether.goodjob.resources.dahboard_unselect
import com.hyperether.goodjob.resources.dashboard
import com.hyperether.goodjob.resources.dropdown_arrow
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.employees_unselect
import com.hyperether.goodjob.resources.ic_logout
import com.hyperether.goodjob.resources.ic_logout_selected
import com.hyperether.goodjob.resources.ic_settings
import com.hyperether.goodjob.resources.ic_settings_selected
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.logout
import com.hyperether.goodjob.resources.map
import com.hyperether.goodjob.resources.map_unselect
import com.hyperether.goodjob.resources.places
import com.hyperether.goodjob.resources.scheduler
import com.hyperether.goodjob.resources.settings
import com.hyperether.goodjob.resources.user_check
import com.hyperether.goodjob.resources.user_check_unselect
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.PricingText
import com.hyperether.goodjob.theme.SelectedIconTint
import com.hyperether.goodjob.theme.SplashBg
import com.hyperether.goodjob.theme.UnselectedIconTint
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun WebNav(navController: NavHostController, workspaceViewModel: WorkspaceViewModel) {

    var selectedTabIndex = remember { mutableStateOf(0) }
    val expandedDropdown = remember { mutableStateOf(false) }
    val selectedWorkspace = remember { mutableStateOf<String?>(null) }
    val workspaces = workspaceViewModel.workspaces_remote.collectAsState().value

    LaunchedEffect(workspaces) {
        if (workspaces.isNotEmpty()) {
            selectedWorkspace.value = workspaces.first().name
        }
    }
    val navItems = listOf(
        WebNavItem(
            name = stringResource(Res.string.dashboard),
            iconResId = painterResource(Res.drawable.dahboard),
            unselectedIconResId = painterResource(
                Res.drawable.dahboard_unselect
            ),
            route = Screen.Dashboard.route
        ),
        WebNavItem(
            name = stringResource(Res.string.employees),
            iconResId = painterResource(Res.drawable.employees),
            unselectedIconResId = painterResource(
                Res.drawable.employees_unselect
            ),
            route = Screen.Employees.route
        ),
        WebNavItem(
            name = stringResource(Res.string.jobs),
            iconResId = painterResource(Res.drawable.briefcase),
            unselectedIconResId = painterResource(
                Res.drawable.briefcase_unselect
            ),
            route = Screen.Jobs.route
        ),
        WebNavItem(
            name = stringResource(Res.string.scheduler),
            iconResId = painterResource(Res.drawable.calendar),
            unselectedIconResId = painterResource(
                Res.drawable.calendar_unselect
            ),
            route = Screen.Dashboard.route
        ),
        WebNavItem(
            name = stringResource(Res.string.contacts),
            iconResId = painterResource(Res.drawable.user_check),
            unselectedIconResId = painterResource(
                Res.drawable.user_check_unselect
            ),
            route = Screen.Dashboard.route
        ),
        WebNavItem(
            name = stringResource(Res.string.places),
            iconResId = painterResource(Res.drawable.map),
            unselectedIconResId = painterResource(
                Res.drawable.map_unselect
            ),
            route = Screen.Dashboard.route
        ),
        WebNavItem(
            name = stringResource(Res.string.settings),
            iconResId = painterResource(Res.drawable.ic_settings_selected),
            unselectedIconResId = painterResource(
                Res.drawable.ic_settings
            ),
            route = Screen.Dashboard.route
        ),
        WebNavItem(
            name = stringResource(Res.string.logout),
            iconResId = painterResource(Res.drawable.ic_logout_selected),
            unselectedIconResId = painterResource(
                Res.drawable.ic_logout
            ),
            route = Screen.Dashboard.route
        )
    )

    Column(
        modifier = Modifier
            .fillMaxHeight()
            .background(SplashBg),
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        // Profile Section
        Column(
            modifier = Modifier.padding(30.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row {
                Image(
                    painter = painterResource(Res.drawable.avatar_male),
                    contentDescription = "Profile Picture",
                    modifier = Modifier
                        .size(60.dp)
                        .background(SplashBg)
                        .padding(4.dp)
                )
                Column(
                    modifier = Modifier.padding(start = 20.dp)
                ) {
                    Text(
                        text = workspaceViewModel.getUser()?.fullName ?: "",
                        fontWeight = FontWeight.Bold,
                        color = PricingText,
                        fontSize = 18.sp
                    )
                    Text(
                        text = workspaceViewModel.getUser()?.email ?: "",
                        color = UnselectedIconTint,
                        fontSize = 14.sp
                    )
                }

            }
            Spacer(modifier = Modifier.height(30.dp))
            WorkspaceTypeDropdown(
                selectedType = selectedWorkspace.value ?: "",
                onTypeSelected = { selected ->
                    selectedWorkspace.value = selected
                    expandedDropdown.value = false
                },
                expanded = expandedDropdown.value,
                onExpandToggle = { expandedDropdown.value = it },
                modifier = Modifier.width(250.dp),
                workspaces = workspaces
            )
        }


        // Navigation Items
        Column(
            modifier = Modifier.padding(start = 60.dp, end = 60.dp),
            verticalArrangement = Arrangement.spacedBy(20.dp)
        ) {
            navItems.subList(0, 6).forEachIndexed { index, item ->
                IconButton(onClick = {
                    navController.safeNavigate(item.route)
                    selectedTabIndex.value = index
                }) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(top = 10.dp, bottom = 10.dp)
                    ) {
                        val icon =
                            if (selectedTabIndex.value == index) item.iconResId else item.unselectedIconResId
                        Icon(
                            painter = icon,
                            contentDescription = null,
                            tint = if (selectedTabIndex.value == index) SelectedIconTint else UnselectedIconTint
                        )
                        Text(
                            modifier = Modifier.padding(start = 20.dp),
                            text = item.name,
                            fontSize = 16.sp,
                            color = if (selectedTabIndex.value == index) SelectedIconTint else UnselectedIconTint
                        )
                    }
                }
            }
        }

        // Spacer between main items and bottom items
        Spacer(modifier = Modifier.weight(1f))

        // Settings and Logout
        Column(
            modifier = Modifier.padding(start = 60.dp, end = 60.dp, bottom = 16.dp),
            verticalArrangement = Arrangement.spacedBy(4.dp)
        ) {
            navItems.subList(6, 8).forEachIndexed { index, item ->
                IconButton(onClick = {
                    navController.safeNavigate(item.route)
                    selectedTabIndex.value = index + 6
                }) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(horizontal = 10.dp, vertical = 4.dp)
                    ) {
                        val icon =
                            if (selectedTabIndex.value == index + 6) item.iconResId else item.unselectedIconResId
                        Icon(
                            painter = icon,
                            tint = if (selectedTabIndex.value == index + 6) SelectedIconTint else UnselectedIconTint,
                            contentDescription = null,
                        )
                        Text(
                            modifier = Modifier.padding(start = 20.dp),
                            text = item.name,
                            fontSize = 16.sp,
                            color = if (selectedTabIndex.value == index + 6) SelectedIconTint else UnselectedIconTint
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun WorkspaceTypeDropdown(
    selectedType: String,
    onTypeSelected: (String) -> Unit,
    expanded: Boolean,
    onExpandToggle: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
    workspaces: List<Workspace>
) {
    Column {
        Box(
            modifier = modifier
                .border(1.dp, DarkGrey, RoundedCornerShape(8.dp))
                .padding(16.dp)
                .fillMaxWidth() // Ensures the box takes the full width
                .clickable { onExpandToggle(true) },
            contentAlignment = Alignment.CenterStart
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween, // Space between text and icon
                modifier = Modifier.fillMaxWidth() // Make row fill the width
            ) {
                Text(
                    text = selectedType,
                    color = PricingText,
                    textAlign = TextAlign.Start,
                    fontSize = 14.sp,
                    modifier = Modifier.weight(1f) // Pushes icon to the end
                )
                Icon(
                    painter = painterResource(Res.drawable.dropdown_arrow),
                    contentDescription = "Dropdown arrow",
                    tint = DarkGrey,
                    modifier = Modifier // No weight on the icon keeps it at the end
                )
            }
        }
        DropdownMenu(
            modifier = Modifier.background(SplashBg).width(250.dp),
            expanded = expanded,
            onDismissRequest = { onExpandToggle(false) },
        ) {
            workspaces.forEach { workspace ->
                DropdownMenuItem(
                    modifier = Modifier.fillMaxWidth(),
                    text = { Text(text = workspace.name ?: "test", color = PricingText) },
                    onClick = { workspace.name?.let { onTypeSelected(it) } },
                )
            }
        }
    }
}

data class WebNavItem(
    val name: String,
    val iconResId: Painter,
    val unselectedIconResId: Painter,
    val route: String
)