import androidx.compose.material.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Container
import com.hyperether.goodjob.navigation.Screen
import org.jetbrains.compose.ui.tooling.preview.Preview
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel

@Composable
@Preview
fun App(
    navController: NavHostController,
    repository: Repository,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    addNewViewModel: AddNewViewModel,
    prefsManager: PrefsManager,
    currentRoute: String = Screen.Splash.route
) {
    MaterialTheme {
        Container(
            navController,
            repository,
            workspaceViewModel,
            pricingViewModel,
            registerViewModel,
            employeesViewModel,
            addNewViewModel,
            prefsManager,
            currentRoute
        )
    }
}