package com.hyperether.goodjob.scenes.pricing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Checkbox
import androidx.compose.material.Icon
import androidx.compose.material.RadioButton
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.app_logo
import com.hyperether.goodjob.resources.check_circle
import com.hyperether.goodjob.resources.day_free_trial
import com.hyperether.goodjob.resources.dedicated_support
import com.hyperether.goodjob.resources.fast_track_support
import com.hyperether.goodjob.resources.fly_high_plan_description
import com.hyperether.goodjob.resources.freebie_plan_description
import com.hyperether.goodjob.resources.galactic_plan_description
import com.hyperether.goodjob.resources.high_five_plan_description
import com.hyperether.goodjob.resources.manager
import com.hyperether.goodjob.resources.month
import com.hyperether.goodjob.resources.priority_support
import com.hyperether.goodjob.resources.support
import com.hyperether.goodjob.resources.unlimited_storage
import com.hyperether.goodjob.resources.users
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun PricingItem(
    isChecked: Boolean,
    onClick: () -> Unit,
    planName: String,
    planPrice: String,
    modifier: Modifier = Modifier
) {
    Box {
        Box(
            modifier = modifier
                .fillMaxWidth()
                .padding(8.dp)
                .border(1.dp, Color.LightGray, shape = RoundedCornerShape(12.dp))
                .background(Color.White, shape = RoundedCornerShape(12.dp))
                .padding(8.dp),
        ) {

            Row(
                modifier = Modifier
                    .fillMaxWidth()
//                .background(Color.White, shape = RoundedCornerShape(12.dp))
//                .border(1.dp, Color.LightGray, shape = RoundedCornerShape(12.dp))
                    .padding(12.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                CustomCheckbox(
                    isChecked = isChecked,
                    onClick = onClick,
                    modifier = Modifier.padding(start = 12.dp)
                )



                Spacer(modifier = Modifier.width(8.dp))

                Text(
                    text = planName,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier
                        .weight(1f)
                        .padding(start = 10.dp)
                )

                Row {
                    Text(
                        text = planPrice,
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        color = Color.DarkGray
                    )
                    if (planName != Plan.Freebie.name) {
                        Text(
                            text = stringResource(Res.string.month),
                            fontSize = 14.sp,
                            color = Color.DarkGray
                        )
                        Spacer(modifier = Modifier.width(9.dp))
                    } else {
                        Spacer(modifier = Modifier.width(9.dp))
                    }
                }
            }

            if (isChecked) {
                if (planName == Plan.Freebie.name) {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.freebie_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 1 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                } else if (planName.contains("Five")) {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.high_five_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 5 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.priority_support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                } else if (planName == Plan.Galactic.name) {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.fly_high_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 10 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.fast_track_support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                } else {
                    PlanDetailsView(
                        textDescription = stringResource(Res.string.galactic_plan_description),
                        firstTextBenefits = "1 ${stringResource(Res.string.manager)} + 20 ${
                            stringResource(
                                Res.string.users
                            )
                        }",
                        secondTextBenefits = stringResource(Res.string.dedicated_support),
                        thirdTextBenefits = stringResource(Res.string.unlimited_storage)
                    )
                }

            }
        }
        if (planName.contains("Fly")) {
            Box(
                modifier = Modifier
                    .align(Alignment.TopEnd)
                    .offset(x = (-16).dp)
                    .background(BlueApp, shape = RoundedCornerShape(30.dp))
                    .padding(horizontal = 12.dp, vertical = 4.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = "Most popular".uppercase(),
                    fontSize = 12.sp,
                    color = Color.White,
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }
}

@Composable
fun PlanDetailsView(
    textDescription: String,
    firstTextBenefits: String,
    secondTextBenefits: String,
    thirdTextBenefits: String
) {

    Column(
        modifier = Modifier.padding(top = 40.dp, start = 20.dp)
    ) {
        Text(
            text = textDescription,
            color = Color.Gray,
            lineHeight = 20.sp,
            textAlign = TextAlign.Left,
            fontSize = 14.sp,
            // modifier = Modifier.padding(top = 8.dp)
        )
        Row(modifier = Modifier.padding(top = 10.dp)) {
            Image(
                painter = painterResource(Res.drawable.check_circle),
                contentDescription = "check",
                modifier = Modifier
                    .padding(top = 5.dp)
            )
            Text(
                text = firstTextBenefits,
                color = Color.Black,
                textAlign = TextAlign.Center,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 12.dp, top = 3.dp)
            )

        }
        Row {
            Image(
                painter = painterResource(Res.drawable.check_circle),
                contentDescription = "check",
                modifier = Modifier
                    .padding(top = 5.dp)
            )
            Text(
                text = secondTextBenefits,
                color = Color.Black,
                textAlign = TextAlign.Center,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 12.dp, top = 3.dp)
            )

        }
        Row {
            Image(
                painter = painterResource(Res.drawable.check_circle),
                contentDescription = "check",
                modifier = Modifier
                 .padding(top = 5.dp)
            )
            Text(
                text = thirdTextBenefits,
                color = Color.Black,
                textAlign = TextAlign.Center,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 12.dp, top = 3.dp)
            )
        }
    }
}

@Composable
fun CustomCheckbox(
    isChecked: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = modifier
            .size(24.dp)
            .clip(CircleShape)
            .background(if (isChecked) Color(0xFF34618E) else Color.White)
            .border(1.dp, Color.LightGray, CircleShape)
            .clickable(onClick = onClick),
        contentAlignment = Alignment.Center
    ) {
        if (isChecked) {
            Icon(
                imageVector = Icons.Default.Check,
                contentDescription = "Checked",
                tint = Color.White,
                modifier = Modifier.size(16.dp)
            )
        }
    }
}
