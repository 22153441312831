package com.hyperether.goodjob.repository.remote.model

import com.hyperether.goodjob.models.AddressObject
import com.hyperether.goodjob.models.Contact.ZipSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ContactRequest(
    @SerialName("name") var name: String? = null,
    @SerialName("contactType") var contactType: String? = null,
    @SerialName("type") var type: String? = null,
    @SerialName("industry") var industry: String? = null,
    @SerialName("addressObject") var addressObject: AddressObject? = null,
    @SerialName("email") var email: String? = null,
    @SerialName("city") var city: String? = null,
    @SerialName("zip") @Serializable(with = ZipSerializer::class) var zip: String? = null,
    @SerialName("country") var country: String? = null,
    @SerialName("phone") var phone: String? = null,
    @SerialName("personName") var personName: String? = null,
    @SerialName("personEmail") var personEmail: String? = null,
    @SerialName("personAddress") var personAddress: String? = null,
    @SerialName("personPhone") var personPhone: String? = null,
    @SerialName("createdAt") var createdAt: Long? = null,
    @SerialName("workspaceId") var workspaceId: String? = null,
    @SerialName("updatedAt") var updatedAt: Long? = null
)
