package com.hyperether.goodjob.navigation

import androidx.compose.animation.EnterTransition
import androidx.compose.animation.ExitTransition
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.DrawerState
import androidx.compose.material3.DrawerValue
import androidx.compose.material3.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavGraph.Companion.findStartDestination
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import com.hyperether.goodjob.containerSize
import com.hyperether.goodjob.dpToSizeInPx
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.scenes.addNew.AddContactViewModel
import com.hyperether.goodjob.scenes.addNew.AddJobViewModel
import com.hyperether.goodjob.scenes.addNew.AddNew
import com.hyperether.goodjob.scenes.addNew.AddNewContact
import com.hyperether.goodjob.scenes.addNew.AddNewEmployee
import com.hyperether.goodjob.scenes.addNew.AddNewJobScreen
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.addNew.AddUserViewModel
import com.hyperether.goodjob.scenes.components.drawer.DrawerComponent
import com.hyperether.goodjob.scenes.contacts.ContactDetails
import com.hyperether.goodjob.scenes.contacts.ContactsScreen
import com.hyperether.goodjob.scenes.contacts.ContactsViewModel
import com.hyperether.goodjob.scenes.contacts.site.AddNewSite
import com.hyperether.goodjob.scenes.dashboard.DashboardScreen
import com.hyperether.goodjob.scenes.dashboard.DashboardViewModel
import com.hyperether.goodjob.scenes.dashboard.JobsWeb
import com.hyperether.goodjob.scenes.dashboard.map.DashboardMapScreen
import com.hyperether.goodjob.scenes.dashboard.map.DashboardMapViewModel
import com.hyperether.goodjob.scenes.employees.EmployeeDetails
import com.hyperether.goodjob.scenes.employees.EmployeeDetailsViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesScreen
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.jobs.JobDetails
import com.hyperether.goodjob.scenes.jobs.JobDetailsViewModel
import com.hyperether.goodjob.scenes.jobs.JobViewModel
import com.hyperether.goodjob.scenes.jobs.JobsScreen
import com.hyperether.goodjob.scenes.landing.LandingPage
import com.hyperether.goodjob.scenes.map.MapScreen
import com.hyperether.goodjob.scenes.map.MapViewModel
import com.hyperether.goodjob.scenes.notifications.NotificationsScreen
import com.hyperether.goodjob.scenes.notifications.NotificationsViewModel
import com.hyperether.goodjob.scenes.onboarding.OnboardingScreen
import com.hyperether.goodjob.scenes.pricing.CancelSubscriptionScreen
import com.hyperether.goodjob.scenes.pricing.PricingScreen
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.profile.ProfileSettings
import com.hyperether.goodjob.scenes.register.RegisterScreen
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.scheduler.SchedulerScreen
import com.hyperether.goodjob.scenes.scheduler.SchedulerViewModel
import com.hyperether.goodjob.scenes.splash.SplashScreen
import com.hyperether.goodjob.scenes.workspace.AddNewWorkspaceScreen
import com.hyperether.goodjob.scenes.workspace.WorkspaceScreen
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import com.hyperether.goodjob.util.Constants.DEFAULT_CONTACT_ID
import com.hyperether.goodjob.util.Constants.DEFAULT_EMPLOYEE_ID
import com.hyperether.goodjob.util.Constants.DEFAULT_JOB_ID
import io.ktor.util.PlatformUtils.IS_BROWSER

@Composable
fun NavGraph(
    navController: NavHostController,
    repository: Repository,
    modifier: Modifier,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    addNewViewModel: AddNewViewModel,
    addContactViewModel: AddContactViewModel,
    addUserViewModel: AddUserViewModel,
    addJobViewModel: AddJobViewModel,
    prefsManager: PrefsManager,
    currentRoute: String,
    drawerState: DrawerState,
    onChoosePlanClick: (Double, String) -> Unit,
    onUploadDocumentClick: (Boolean) -> Unit,
    onOpenPdf: (String) -> Unit
) {
    NavHost(
        navController = navController,
        startDestination = currentRoute,
        modifier = modifier,
        enterTransition = {
            // you can change whatever you want transition
            EnterTransition.None
        },
        exitTransition = {
            // you can change whatever you want transition
            ExitTransition.None
        }
    ) {
        composable(route = Screen.Splash.route) {
            SplashScreen(navController)
        }

        composable(
            route = Screen.Onboarding.route
        ) {
            OnboardingScreen(navController, prefsManager)
        }

        composable(
            route = Screen.Register.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                RegisterScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    registerViewModel = registerViewModel,
                    navHostController = navController,
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel,
                )
            }
        }

        composable(
            route = Screen.Pricing.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                PricingScreen(
                    isMobileOrNarrowScreen,
                    pricingViewModel,
                    navController,
                    onChoosePlanClick
                )
            }
        }

        composable(
            route = Screen.CancelSubscriptionScreen.route
        ) {
            CancelSubscriptionScreen(pricingViewModel, navController)
        }

        composable(
            route = Screen.Workspace.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                WorkspaceScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    workspaceViewModel = workspaceViewModel,
                    continueToDashboard = {
                        navController.navigate(Screen.Dashboard.route) {
                            popUpTo(Screen.Workspace.route) {
                                inclusive = true
                                saveState = false
                            }
                            launchSingleTop = true
                        }
                    },
                    addNewWorkspace = {
                        navController.navigate(Screen.AddNewWorkspace.route) {
                            popUpTo(Screen.Workspace.route) {
                                inclusive = true
                                saveState = false
                            }
                            launchSingleTop = true
                        }
                    })
            }
        }

        composable(
            route = Screen.AddNewWorkspace.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                AddNewWorkspaceScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    workspaceViewModel = workspaceViewModel,
                    onChooseExistingWorkspace = {
                        navController.navigate(Screen.Workspace.route) {
                            popUpTo(Screen.AddNewWorkspace.route) {
                                inclusive = true
                                saveState = false
                            }
                            launchSingleTop = true
                        }
                    }
                )
            }
        }
        composable(
            route = Screen.Dashboard.route
        ) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                val viewModel: DashboardViewModel = viewModel { DashboardViewModel(repository) }
                val mapViewModel: DashboardMapViewModel = viewModel { DashboardMapViewModel(repository) }
                DashboardScreen(
                    isMobileView = isMobileOrNarrowScreen,
                    navController,
                    viewModel,
                    mapViewModel,
                    drawerState = drawerState,
                    prefsManager
                )
            }
        }

        composable(Screen.JobsWeb.route) {
            val viewModel: JobViewModel = viewModel { JobViewModel(repository) }
            JobsWeb(navController = navController, viewModel = viewModel)
        }

        composable(Screen.AddNew.route) {
            AddNew(navController, addNewViewModel)
        }

        val addNewRoute = if (getPlatformChecker().isMobile()) {
            Screen.AddNewEmployee.route + "/{employeeId}"
        } else {
            Screen.AddNewEmployee.route + "?employeeId={employeeId}"
        }
        composable(addNewRoute) { backStackEntry ->
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                val employeeId: String =
                    backStackEntry.arguments?.getString("employeeId") ?: DEFAULT_EMPLOYEE_ID
                AddNewEmployee(employeeId, isMobileOrNarrowScreen, navController, addUserViewModel)
            }
        }

        composable(Screen.Employees.route) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                EmployeesScreen(
                    isMobileOrNarrowScreen,
                    employeesViewModel,
                    navController,
                    prefsManager,
                    drawerState
                )
            }
        }

        val route = if (getPlatformChecker().isMobile()) {
            Screen.EmployeeDetails.route + "/{employeeId}"  // Mobile route with path parameter
        } else {
            Screen.EmployeeDetails.route + "?employeeId={employeeId}"  // Web route with query parameter
        }

        composable(route) { backStackEntry ->
            BoxWithConstraints {
                val isMobileOrNarrowScreen = getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                val employeeId: String =
                    backStackEntry.arguments?.getString("employeeId") ?: DEFAULT_EMPLOYEE_ID

                EmployeeDetails(
                    employeeId,
                    isMobileOrNarrowScreen,
                    employeesViewModel,
                    employeeDetailsViewModel,
                    navController
                )
            }
        }


        val addNewJobRoute = if (getPlatformChecker().isMobile()) {
            Screen.AddNewJob.route + "/{jobId}"
        } else {
            Screen.AddNewJob.route + "?jobId={jobId}"
        }
        composable(addNewJobRoute) { backStackEntry ->
            val jobId: String = backStackEntry.arguments?.getString("jobId") ?: DEFAULT_JOB_ID
            AddNewJobScreen(jobId,getPlatformChecker().isMobile(), addJobViewModel, navController, onUploadDocumentClick)
        }

        composable(Screen.Jobs.route) {
            BoxWithConstraints {
                val isMobileOrNarrowScreen = remember(maxWidth) {
                    getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                }
                val viewModel: JobViewModel = viewModel { JobViewModel(repository) }
                JobsScreen(
                    isMobileOrNarrowScreen,
                    viewModel,
                    navController,
                    drawerState,
                    prefsManager
                )
            }
        }

        val addNewContactRoute = if (getPlatformChecker().isMobile()) {
            Screen.AddNewContact.route + "/{contactId}"
        } else {
            Screen.AddNewContact.route + "?contactId={contactId}"
        }
        composable(addNewContactRoute) { backStackEntry ->
            BoxWithConstraints {
                val isMobileOrNarrowScreen = remember(maxWidth) {
                    getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                }
                val contactId: String =
                    backStackEntry.arguments?.getString("contactId") ?: DEFAULT_CONTACT_ID
                AddNewContact(contactId, addContactViewModel, navController, isMobileOrNarrowScreen)
            }
        }

        val jobDetailsRoute = if (getPlatformChecker().isMobile()) {
            Screen.JobDetails.route + "/{jobId}"
        } else {
            Screen.JobDetails.route + "?jobId={jobId}"
        }
        composable(jobDetailsRoute) { backStackEntry ->
            val jobId: String = backStackEntry.arguments?.getString("jobId") ?: ""
            val viewModel: JobDetailsViewModel = viewModel { JobDetailsViewModel(repository) }
            JobDetails(jobId, viewModel, navController, onOpenPdf, onUploadDocumentClick)
        }

        composable(Screen.Contacts.route) {
            BoxWithConstraints {
                val viewModel: ContactsViewModel = viewModel { ContactsViewModel(repository) }
                val isMobileScreen = remember(maxWidth) {
                    getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                }
                ContactsScreen(navController, isMobileScreen, viewModel, drawerState, prefsManager)
            }
        }

        val addNewSiteRoute = if (getPlatformChecker().isMobile()) {
            Screen.AddNewSite.route + "/{contactId}/{siteId}"
        } else {
            Screen.AddNewSite.route + "?contactId={contactId}&siteId={siteId}"
        }
        composable(addNewSiteRoute) { backStackEntry ->
            BoxWithConstraints {
                val contactId: String = backStackEntry.arguments?.getString("contactId") ?: ""
                val siteId: String = backStackEntry.arguments?.getString("siteId") ?: ""
                val viewModel: ContactsViewModel = viewModel { ContactsViewModel(repository) }
                val isMobileScreen = remember(maxWidth) {
                    getPlatformChecker().isMobile() || maxWidth < 600.dp
                }
                AddNewSite(contactId, siteId, viewModel, navController, isMobileScreen)
            }
        }

        val contactDetailsRoute = if (getPlatformChecker().isMobile()) {
            Screen.ContactDetails.route + "/{contactId}"
        } else {
            Screen.ContactDetails.route + "?contactId={contactId}"
        }
        composable(contactDetailsRoute) { backStackEntry ->
            BoxWithConstraints {
                val contactId: String = backStackEntry.arguments?.getString("contactId") ?: ""
                val viewModel: ContactsViewModel = viewModel { ContactsViewModel(repository) }
                val isMobileScreen = remember(maxWidth) {
                    getPlatformChecker().isMobile() || maxWidth < 600.dp
                }
                ContactDetails(contactId, navController, viewModel, isMobileScreen)
            }
        }

        composable(route = Screen.Landing.route) {
            BoxWithConstraints {
                val density = LocalDensity.current
                val sizeInPx = remember(maxWidth, maxHeight) {
                    dpToSizeInPx(maxWidth, maxHeight, density)
                }
                containerSize.value = sizeInPx
                // Cache the isMobileOrNarrowScreen value using remember so it's not recomputed every time

                val isMobileOrNarrowScreen = remember(maxWidth) {
                    getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()
                }

                LandingPage(isMobileOrNarrowScreen, pricingViewModel) {
                    navController.navigate(Screen.Register.route)
                }
            }
        }

        composable(Screen.Scheduler.route) {
            val viewModel: SchedulerViewModel = viewModel { SchedulerViewModel(repository) }
            SchedulerScreen(viewModel, navController, drawerState, prefsManager, getPlatformChecker().isMobile())
        }

        composable(Screen.ProfileSettings.route) {
            ProfileSettings(navController, prefsManager)
        }

        composable(Screen.Map.route) {
            val viewModel: MapViewModel = viewModel { MapViewModel(repository) }
            MapScreen(viewModel, navController, drawerState, prefsManager)
        }

        composable(Screen.DashboardMapScreen.route) {
            val viewModel: DashboardMapViewModel = viewModel { DashboardMapViewModel(repository) }
            DashboardMapScreen(viewModel, navController)
        }

        composable(Screen.Notifications.route) {
            val viewModel: NotificationsViewModel = viewModel { NotificationsViewModel(repository) }
            NotificationsScreen(viewModel, navController, drawerState, prefsManager)
        }
    }
}

@Composable
fun Container(
    navController: NavHostController,
    repository: Repository,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    addNewViewModel: AddNewViewModel,
    addContactViewModel: AddContactViewModel,
    addUserViewModel: AddUserViewModel,
    addJobViewModel: AddJobViewModel,
    prefsManager: PrefsManager,
    currentRoute: String,
    onChoosePlanClick: (Double, String) -> Unit,
    onLogout: () -> Unit,
    onUploadDocumentClick: (Boolean) -> Unit,
    onOpenPdf: (String) -> Unit
) {
    val isBottomNavigationBarVisible = remember { mutableStateOf(true) }
    val isWebNavigationDrawerVisible = remember { mutableStateOf(true) }

    LaunchedEffect(navController) {
        navController.addOnDestinationChangedListener { _, destination, _ ->
            isBottomNavigationBarVisible.value = when (destination.route) {
                Screen.Pricing.route,
                Screen.Register.route,
                Screen.AddNewEmployee.route,
                Screen.AddNew.route,
                Screen.Splash.route,
                Screen.Landing.route,
                Screen.Workspace.route,
                Screen.AboutContact.route,
                Screen.AddNewContact.route,
                Screen.AddNewJob.route,
                Screen.AddNewWorkspace.route,
                Screen.Onboarding.route -> false

                else -> true
            }
            isWebNavigationDrawerVisible.value = when (destination.route) {
                Screen.Pricing.route,
                Screen.Register.route,
                Screen.Splash.route,
                Screen.Landing.route,
                Screen.Workspace.route,
                Screen.AddNewWorkspace.route,
                Screen.Onboarding.route -> false

                else -> true
            }
        }
    }
    BoxWithConstraints(modifier = Modifier.fillMaxSize()) {
        if (getPlatformChecker().isMobile() || getPlatformChecker().isMobileWebView()) {
            // Mobile layout

            // todo move this to main for web as checking user is on MainActivity for android
            val startRoute =
                if (IS_BROWSER) {
                    if (repository.getUser() != null && repository.getUser()?.planId?.isNotEmpty() == true && prefsManager.getWorkspaceId() == null) {
                        Screen.Workspace.route
                    } else if (repository.getUser() != null && repository.getUser()?.planId?.isEmpty() == true) {
                        Screen.Pricing.route
                    } else {
                        currentRoute
                    }
                } else {
                    currentRoute
                }
            Column {
//                NavGraph(
//                    navController = navController,
//                    repository = repository,
//                    modifier = Modifier.weight(1f),
//                    workspaceViewModel = workspaceViewModel,
//                    pricingViewModel = pricingViewModel,
//                    registerViewModel = registerViewModel,
//                    employeesViewModel = employeesViewModel,
//                    addNewViewModel = addNewViewModel,
//                    prefsManager = prefsManager,
//                    currentRoute = startRoute,
//                    onChoosePlanClick = onChoosePlanClick
//                )
                DrawerComponent(
                    navController = navController,
                    repository = repository,
                    modifier = Modifier.weight(1f),
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel,
                    registerViewModel = registerViewModel,
                    employeesViewModel = employeesViewModel,
                    employeeDetailsViewModel = employeeDetailsViewModel,
                    addNewViewModel = addNewViewModel,
                    addContactViewModel = addContactViewModel,
                    addUserViewModel = addUserViewModel,
                    addJobViewModel = addJobViewModel,
                    prefsManager = prefsManager,
                    onChoosePlanClick = onChoosePlanClick,
                    onLogout = onLogout,
                    startRoute = startRoute,
                    isBottomNavigationBarVisible = isBottomNavigationBarVisible.value,
                    onUploadDocumentClick = onUploadDocumentClick,
                    onOpenPdf = onOpenPdf
                )

//                if (isBottomNavigationBarVisible.value) {
//                    BottomNavigation(backgroundColor = Color.White) {
//                        AndroidNav(navController)
//                    }
//                }
            }
        } else {
            Row {
                if (isWebNavigationDrawerVisible.value) {
                    WebNav(navController, workspaceViewModel, repository)
                }
                NavGraph(
                    navController,
                    repository,
                    modifier = Modifier.weight(1f),
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel,
                    registerViewModel = registerViewModel,
                    employeesViewModel = employeesViewModel,
                    employeeDetailsViewModel = employeeDetailsViewModel,
                    addNewViewModel,
                    addContactViewModel,
                    addUserViewModel,
                    addJobViewModel,
                    prefsManager = prefsManager,
                    currentRoute,
                    drawerState = rememberDrawerState(initialValue = DrawerValue.Closed),
                    onChoosePlanClick,
                    onUploadDocumentClick,
                    onOpenPdf = onOpenPdf
                )
            }
        }
    }
}

fun NavController.safeNavigate(route: String) {
    currentDestination?.let { currentDestination ->
        if (currentDestination.route != route) {
            navigate(route) {
                popUpTo(graph.findStartDestination().route ?: Screen.Dashboard.route) {
                    saveState = true
                }
                launchSingleTop = true
                restoreState = true
            }
        }
    }
}


//class NavController(
//    private val onNavigate: (String) -> Unit,
//    private val onNavigateBack: () -> Unit,
//    private val shouldNavigate: Boolean
//) :
//    NavHostController() {
//
//    init {
//        navigatorProvider.addNavigator(ComposeNavigator())
//        navigatorProvider.addNavigator(DialogNavigator())
//        this.addOnDestinationChangedListener { controller, destination, arguments ->
//            if (shouldNavigate) {
//                currentDestination?.route?.let {
//                    onNavigate(
//                        it
//                    )
//                }
//            }
//        }
//
//        if (!shouldNavigate) {
//            CoroutineScope(Dispatchers.Main).launch {
//                Router.currentRoute.collect {
//                    navigate(it)
//                }
//            }
//        }
//    }
//
//
//    override fun navigateUp(): Boolean {
//        if (shouldNavigate) {
//            onNavigateBack()
//            return super.navigateUp()
//        }
//        return false
//    }
//}
//
//@Composable
//fun rememberNavController(
//    onNavigate: (String) -> Unit,
//    onNavigateBack: () -> Unit,
//    shouldNavigate: Boolean
//): NavController {
//    return remember { NavController(onNavigate, onNavigateBack, shouldNavigate) }
//}
//
//object Router {
//    val currentRoute = MutableStateFlow("/")
//}