package com.hyperether.goodjob.scenes.splash

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.navigation.NavController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.logo
import com.hyperether.goodjob.theme.SplashBg
import io.ktor.util.PlatformUtils
import kotlinx.coroutines.delay


@Composable
fun SplashScreen(
    navController: NavController
) {

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(SplashBg),
        contentAlignment = Alignment.Center
    ) {

        LaunchedEffect(Unit) {
            if (PlatformUtils.IS_BROWSER) {
                delay(700)
                navController.navigate(Screen.Landing.route)
            }
        }
        Image(
            painter = org.jetbrains.compose.resources.painterResource(Res.drawable.logo),
            contentDescription = null
        )
    }
}