package com.hyperether.goodjob.scenes.map

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.map.Marker
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.repository.Repository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class MapViewModel(val repository: Repository) : ViewModel() {
    val markers = mutableStateListOf<Marker>()
    val jobList = mutableStateListOf<Job>()
    val sitesMap = mutableStateMapOf<String, Site>()
    val filteredJobs = mutableStateListOf<Job>()
    private val _teams = MutableStateFlow<List<com.hyperether.goodjob.models.Team>>(emptyList())
    val teams: StateFlow<List<com.hyperether.goodjob.models.Team>> = _teams
    val showFilterDialog: MutableState<Boolean> = mutableStateOf(false)

    var selectedStatus = mutableStateOf(setOf<String>())
    var selectedEmployees = mutableStateOf(setOf<String>())
    var selectedTeams = mutableStateOf(setOf<String>())
    var selectedLocation = mutableStateOf(setOf<String>())
    var selectedSchedule = mutableStateOf(setOf<String>())

    var jobStatusMap: Map<String, String> = mutableMapOf()

    init {
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }
        CoroutineScope(Dispatchers.Default).launch {
            repository.getJobsFlow()?.collect {
                if (it.isNotEmpty()) {
                    jobList.clear()
                    filteredJobs.clear()
                    val siteMap = mutableMapOf<String, Site>()
                    it.forEach { job ->
                        job.contactSiteId?.let {
                            val site = getSiteById(it)
                            site?.id?.let { id -> siteMap[job.id!!] = site }
                        }
                        jobList.add(job)
                        filteredJobs.add(job)
                    }
                    sitesMap.clear()
                    sitesMap.putAll(siteMap)
                    jobList.forEach {
                        sitesMap[it.id]?.addressObject?.let { addressObject ->
                            addressObject.lat?.let { lat ->
                                addressObject.long?.let { lon ->
                                    markers.add(
                                        Marker(
                                            lat,
                                            lon,
                                            it.jobTitle ?: "",
                                            it.status ?: ""
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

    private suspend fun getSiteById(id: String): Site? {
        return repository.getSiteById(id)
    }

    fun filterJobs() {
        filteredJobs.clear()
        if (selectedStatus.value.isNullOrEmpty() &&
            selectedEmployees.value.isNullOrEmpty() &&
            selectedSchedule.value.isNullOrEmpty() &&
            selectedLocation.value.isNullOrEmpty()
        ) {
            filteredJobs.addAll(jobList)
        } else {

            val selectedSitesIds = selectedLocation.value.flatMap { selectedAddress ->
                sitesMap.filterValues { site ->
                    site.addressObject?.address.equals(selectedAddress, ignoreCase = true)
                }.values.map { it.id }
            }
            filteredJobs.addAll(jobList.filter { job ->
                selectedStatus.value.any { query ->
                    job.status?.contains(query, ignoreCase = true) == true
                } || selectedEmployees.value.any { query ->
                    job.assignees?.employees?.any { employee ->
                        employee?.getName()?.contains(query, ignoreCase = true) == true
                    } == true
                } || selectedSchedule.value.any { query ->
                    job.startDate?.contains(query, ignoreCase = true) == true
                } || selectedSitesIds.contains(job.contactSiteId)
            })
        }
    }
}