package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.UserStatus
import com.hyperether.goodjob.models.UserTeam
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.active
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_employee
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.admin
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.country
import com.hyperether.goodjob.resources.date_error
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.employee
import com.hyperether.goodjob.resources.employee_added_successfully
import com.hyperether.goodjob.resources.employee_updated_successfully
import com.hyperether.goodjob.resources.enter_employee_email
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.enter_surname
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.error_message
import com.hyperether.goodjob.resources.friday
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.inactive
import com.hyperether.goodjob.resources.manager
import com.hyperether.goodjob.resources.monday
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.photo_upload_area
import com.hyperether.goodjob.resources.profile_settings
import com.hyperether.goodjob.resources.role
import com.hyperether.goodjob.resources.saturday
import com.hyperether.goodjob.resources.select_skill
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.sunday
import com.hyperether.goodjob.resources.tap_to_upload_photo
import com.hyperether.goodjob.resources.thursday
import com.hyperether.goodjob.resources.tuesday
import com.hyperether.goodjob.resources.update
import com.hyperether.goodjob.resources.update_employee
import com.hyperether.goodjob.resources.wednesday
import com.hyperether.goodjob.resources.working_hours
import com.hyperether.goodjob.resources.zip_employee
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeInputField
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeMultiSelectField
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeTeamInputField
import com.hyperether.goodjob.scenes.components.AddressInputField
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.ZipInputField
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_EMPLOYEE_ID
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewEmployee(
    employeeId: String?,
    isMobileView: Boolean,
    navHostController: NavHostController,
    viewModel: AddUserViewModel
) {

    if (viewModel.isManager()) {
        viewModel.roleMap = mapOf(
            stringResource(Res.string.employee) to Role.employee.name
        )
    } else {
        viewModel.roleMap = mapOf(
            stringResource(Res.string.admin) to Role.admin.name,
            stringResource(Res.string.manager) to Role.manager.name,
            stringResource(Res.string.employee) to Role.employee.name,
        )
    }
    viewModel.userStatusMap = mapOf(
        stringResource(Res.string.active) to UserStatus.active.name,
        stringResource(Res.string.inactive) to UserStatus.inactive.name
    )

    val showProgressBar = remember { mutableStateOf(false) }
    val showErrorDialog = remember { mutableStateOf(false) }

    val selectedSkills by viewModel.selectedSkills.collectAsState(emptyList())
    val dropdownSkills by viewModel.availableSkills.collectAsState(emptyList())
    val preselectedSkills by viewModel.preselectedSkills.collectAsState(emptyList())

    val selectedSkillNamesState = remember { mutableStateOf(selectedSkills) }
    val preselectedSkillNamesState = remember { mutableStateOf(preselectedSkills) }
    val dropdownSkillNamesState = remember { mutableStateOf(dropdownSkills.map { it.name ?: "" }) }

    val teams by viewModel.teams.collectAsState(emptyList())
    val availableTeamNames = teams.map { it.name ?: "" }
    val dropdownTeamsItemsState = remember { mutableStateOf(emptyList<String>()) }

    val user by viewModel.selectedUser.collectAsState()

    LaunchedEffect(Unit) {
        if (!getPlatformChecker().isMobile()) {
            viewModel.getAllSkillsRemote()
        }
        viewModel.getAllTeamsRemote()
        println("########" + employeeId)
    }

    LaunchedEffect(employeeId) {
        when (employeeId) {
            null -> viewModel.clearUserValues()
            DEFAULT_EMPLOYEE_ID -> viewModel.clearUserValues()
            "" -> viewModel.clearUserValues()
            else -> viewModel.getUserById(employeeId)
        }
    }

    LaunchedEffect(availableTeamNames, teams) {
        dropdownTeamsItemsState.value = availableTeamNames
    }

    LaunchedEffect(dropdownSkills, user?.skills) {
        user?.skills?.let { skills ->
            println("userSkills: $skills")
            viewModel.setPreselectedSkills(skills)
        }
    }

    LaunchedEffect(selectedSkills) {
        selectedSkillNamesState.value = selectedSkills
    }

    LaunchedEffect(preselectedSkills) {
        preselectedSkillNamesState.value = preselectedSkills
    }

    LaunchedEffect(dropdownSkills) {
        dropdownSkillNamesState.value = dropdownSkills.map { it.name ?: "" }
    }

    LaunchedEffect(user) {
        if (employeeId != DEFAULT_EMPLOYEE_ID && employeeId != "") {
            viewModel.clearUserValues()
            user.let { it ->
                if (it != null) {
                    viewModel.roleSelected.value =
                        viewModel.roleMap.entries.find { role -> role.value == it.role }?.key
                            ?: viewModel.roleSelected.value
                    viewModel.fullNameInput.value = it.fullName ?: viewModel.fullNameInput.value
                    viewModel.phoneInput.value = it.phone ?: viewModel.phoneInput.value
                    viewModel.emailInput.value = it.email ?: viewModel.emailInput.value
                    viewModel.cityInput.value = it.city ?: viewModel.cityInput.value
                    viewModel.countryInput.value = it.country ?: viewModel.countryInput.value
                    viewModel.addressInput.value = it.address ?: viewModel.addressInput.value
                    viewModel.zipInput.value = it.zip ?: viewModel.zipInput.value
                    viewModel.status.value =
                        viewModel.userStatusMap.entries.find { entry -> entry.value == it.status }?.key
                            ?: viewModel.status.value
                    viewModel.upcomingLeaves.value =
                        it.upcomingLeave ?: viewModel.upcomingLeaves.value
                    viewModel.teamInput.value = it.teams?.firstNotNullOfOrNull { userTeam ->
                        teams.find { team -> team.name == userTeam?.value }
                    } ?: viewModel.teamInput.value
                    viewModel.setPreselectedSkills(it.skills ?: viewModel.preselectedSkills.value)
                    viewModel.setSelectedSkills(it.skills ?: viewModel.selectedSkills.value)

                    val workingHours = it.workingHours
                    workingHours?.let { hours ->
                        viewModel.daysSchedule.forEach { daySchedule ->
                            val dayHours = when (daySchedule.dayName) {
                                Res.string.monday -> hours.monday
                                Res.string.tuesday -> hours.tuesday
                                Res.string.wednesday -> hours.wednesday
                                Res.string.thursday -> hours.thursday
                                Res.string.friday -> hours.friday
                                Res.string.saturday -> hours.saturday
                                Res.string.sunday -> hours.sunday
                                else -> null
                            }

                            // Update daySchedule properties
                            dayHours?.let { dh ->
                                daySchedule.isChecked.value = dh.enabled ?: false
                                daySchedule.startTime.value = dh.from ?: "08:00"
                                daySchedule.endTime.value = dh.to ?: "16:00"
                            } ?: run {
                                daySchedule.isChecked.value = false
                            }
                        }
                    }
                }
            }
        } else {
            viewModel.clearUserValues()
            if (viewModel.roleMap.size == 1) {
                viewModel.roleSelected.value =
                    viewModel.roleMap.keys.firstOrNull() ?: viewModel.roleSelected.value
            }
        }
    }


    // TODO: get Teams
    val itemsTeams = listOf(
        UserTeam(id = "1", value = "Sales Team"),
        UserTeam(id = "2", value = "Support Team"),
        UserTeam(id = "3", value = "Technical Team")
    )
    val itemsSkills = listOf("Skill1", "Skill2")
    println("RemoteTeams: " + teams.toString())

    val isEditView = remember { mutableStateOf(false) }



    if (isMobileView) {
        Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
            MobileHeaderBackAndAction(
                title = if (employeeId?.contains(DEFAULT_EMPLOYEE_ID) == true || employeeId == "") {
                    stringResource(Res.string.add_new_employee)
                } else if (employeeId?.contains("profileSettings") == true) {
                    stringResource(Res.string.profile_settings)
                } else {
                    stringResource(Res.string.update_employee)
                },
                back = {
                    viewModel.clearUserValues()
                    navHostController.popBackStack()
                }
            )

            Spacer(modifier = Modifier.height(15.dp))

            Image(
                painter = painterResource(Res.drawable.photo_upload_area),
                contentDescription = "Avatar",
                modifier = Modifier
                    .size(80.dp)
                    .clip(CircleShape)
                    .background(BlueApp)
                    .align(Alignment.CenterHorizontally)
            )

            Text(
                text = stringResource(Res.string.tap_to_upload_photo),
                fontSize = 14.sp,
                color = TextColor,
                textAlign = TextAlign.Center,
                fontStyle = FontStyle.Italic,
                modifier = Modifier.align(Alignment.CenterHorizontally)
            )

            Column(modifier = Modifier.padding(horizontal = 16.dp)) {
                AddEmployeeInputField(
                    viewModel.roleSelected,
                    stringResource(Res.string.role),
                    placeholder = "",
                    dropdownItems = viewModel.roleMap,
                    keyboardInputEnabled = false
                )

                AppInputField(
                    viewModel.fullNameInput,
                    stringResource(Res.string.full_name),
                    placeholder = stringResource(Res.string.enter_surname)
                )
                PhoneInputField(
                    viewModel.phoneInput,
                    stringResource(Res.string.phone),
                    placeholder = "+134232525235"
                )
                AppInputField(
                    viewModel.emailInput,
                    stringResource(Res.string.email_address),
                    placeholder = stringResource(Res.string.enter_employee_email),
                    enabled = employeeId?.contains(DEFAULT_EMPLOYEE_ID) == true || employeeId == ""
                )
                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    AppInputField(
                        viewModel.cityInput,
                        stringResource(Res.string.city),
                        placeholder = stringResource(Res.string.city),
                        modifier = Modifier.weight(1f)
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    AppInputField(
                        viewModel.countryInput,
                        stringResource(Res.string.country),
                        placeholder = stringResource(Res.string.country),
                        modifier = Modifier.weight(1f)
                    )
                }
                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    AddressInputField(
                        textCountry = viewModel.countryInput,
                        textCity = viewModel.cityInput,
                        text = viewModel.addressInput,
                        label = stringResource(Res.string.address),
                        placeholder = stringResource(Res.string.enter_street),
                        modifier = Modifier.weight(3f),
                        onSearch = { viewModel.findPlace(it) },
                        onSelect = {
                            viewModel.addressInput.value = it.formattedAddress
                            viewModel.place = it
                        },
                        places = viewModel.places
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    ZipInputField(
                        viewModel.zipInput,
                        stringResource(Res.string.zip_employee),
                        placeholder = stringResource(Res.string.zip_employee),
                        modifier = Modifier.weight(1f)
                    )
                }
                AddEmployeeInputField(
                    viewModel.status,
                    stringResource(Res.string.status),
                    placeholder = "",
                    dropdownItems = viewModel.userStatusMap,
                    keyboardInputEnabled = false
                )

                AddEmployeeMultiSelectField(
                    selectedItems = selectedSkillNamesState,
                    onItemSelected = { newSelectedItems ->
                        selectedSkillNamesState.value = newSelectedItems
                        viewModel.setSelectedSkills(newSelectedItems)
                    },
                    label = stringResource(Res.string.skills),
                    placeholder = stringResource(Res.string.select_skill),
                    dropdownItems = dropdownSkillNamesState,
                    preselectedItems = preselectedSkillNamesState,
                    onAddNewValue = { name -> viewModel.addNewSkill(name) }
                )
                AddEmployeeTeamInputField(
                    itemsFromDb = teams,
                    selectedTeam = viewModel.teamInput.value,
                    onItemSelected = {
                        viewModel.teamInput.value = it
                        println("SelectedTeam: " + viewModel.teamInput.value)
                    },
                    onAddNewItem = { name ->
                        viewModel.createTeam(name) { newTeam ->
                            viewModel.teamInput.value = newTeam
                        }
                    }
                )

                OutlinedCalendarInput(
                    onClick = {
                        viewModel.isCalendar2Visible.value = true
                    },
                    viewModel.upcomingLeaves
                )

                Text(
                    text = stringResource(Res.string.working_hours),
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(top = 20.dp)
                )

                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(400.dp)
                ) {
                    WorkingHoursView(viewModel)
                }
            }


            FilledButton(
                onClick = {
                    viewModel.showLoader.value = true
                    if (employeeId == DEFAULT_EMPLOYEE_ID || employeeId == "") {
                        viewModel.addEmployee(
                            onSuccess = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpSuccess.value = true

                            },
                            onError = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpError.value = true
                            })
                    } else {
                        viewModel.updateUser(
                            onSuccess = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpSuccess.value = true

                            },
                            onError = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpError.value = true
                            })
                    }
                },
                modifier = Modifier.padding(vertical = 30.dp, horizontal = 16.dp),
                text = if (employeeId?.contains(DEFAULT_EMPLOYEE_ID) == true || employeeId == "") stringResource(
                    Res.string.add
                ) else stringResource(
                    Res.string.update
                ),
                isEnabled = true
            )
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    title = stringResource(Res.string.success).uppercase(),
                    message = if (employeeId == DEFAULT_EMPLOYEE_ID) stringResource(Res.string.employee_added_successfully) else stringResource(
                        Res.string.employee_updated_successfully
                    ),
                    onPositiveBtnClicked = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    positiveBtnText = stringResource(Res.string.ok).uppercase()
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
            if (viewModel.isCalendar2Visible.value) {
                val error = stringResource(Res.string.date_error)
                CustomCalendarView(
                    isAddEmployee = true,
                    onDismiss = { viewModel.isCalendar2Visible.value = false },
                    onDateSelect = { selectedDates ->
                        if (selectedDates.isNotEmpty()) {
                            val sortedDates = selectedDates.sorted()
                            val start = sortedDates.first()
                            val end = sortedDates.last()

                            fun formatDate(date: LocalDate): String {
                                val monthAbbreviation = date.month.name.lowercase().take(3)
                                    .replaceFirstChar { it.uppercase() }
                                return "$monthAbbreviation ${date.dayOfMonth}"
                            }

                            val formattedRange =
                                if (start.month == end.month && start.year == end.year) {
                                    // If the month and year are the same
                                    "${formatDate(start)} - ${end.dayOfMonth}, ${end.year}"
                                } else {
                                    // Full range including months
                                    "${formatDate(start)} - ${formatDate(end)}, ${end.year}"
                                }

                            viewModel.upcomingLeaves.value = formattedRange
                        }
                    },
                    showErrorMessage = {
                        viewModel.showPopUpError.value = true
                        viewModel.errorText.value = error
                    }
                )
            }
        }

    } else {
        AddNewEmployeeWeb(
            viewModel,
            navHostController,
            showProgressBar,
            showErrorDialog
        )
    }

    if (showErrorDialog.value) {
        val error = stringResource(Res.string.error)
        val message = stringResource(Res.string.error_message)
        ErrorDialog(
            onDismiss = {
                showErrorDialog.value = false
            },
            title = error,
            message = message
        )
    }
    if (showProgressBar.value) {
        FullScreenProgressBar()
    }
}

@Composable
fun DropdownMenuComponent(label: String, selectedValue: String) {
    var expanded = remember { mutableStateOf(false) }
    var selectedOption = remember { mutableStateOf(selectedValue) }

    Box {
        OutlinedTextField(
            value = selectedOption.value,
            onValueChange = {},
            label = { Text(label) },
            trailingIcon = {
                Icon(
                    imageVector = Icons.Default.ArrowDropDown,
                    contentDescription = null,
                    modifier = Modifier.clickable { expanded.value = true }
                )
            },
            readOnly = true,
            modifier = Modifier.fillMaxWidth()
        )
        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            listOf("Option 1", "Option 2", "Option 3").forEach { option ->
                DropdownMenuItem(onClick = {
                    selectedOption.value = option
                    expanded.value = false
                }, text = {
                    Text(option)
                })
            }
        }
    }
}
