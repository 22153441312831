package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_new_job_message
import com.hyperether.goodjob.resources.add_new_user_message
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.check_soon
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.job
import com.hyperether.goodjob.resources.job_deleted
import com.hyperether.goodjob.resources.job_status_completed
import com.hyperether.goodjob.resources.job_status_on_hold
import com.hyperether.goodjob.resources.job_status_in_progress
import com.hyperether.goodjob.resources.job_status_open
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.no_tasks_assigned
import com.hyperether.goodjob.resources.no_tasks_created
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.SearchField
import com.hyperether.goodjob.scenes.dashboard.JobsWeb
import com.hyperether.goodjob.scenes.jobs.components.FilterJobsDialog
import com.hyperether.goodjob.scenes.jobs.components.JobsList
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_JOB_ID
import com.hyperether.goodjob.util.FilterDialogStateManager
import com.hyperether.goodjob.util.JobStatusMap
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobsScreen(
    isMobileView: Boolean,
    viewModel: JobViewModel,
    navHostController: NavHostController,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {

    viewModel.jobStatusMap = JobStatusMap()

    LaunchedEffect(Unit) {
        FilterDialogStateManager.setDialogShowing(false)
        if (!getPlatformChecker().isMobile()) {
            viewModel.init()
        }
    }

    val employees by viewModel.users.collectAsState(initial = emptyList())
    val contacts by viewModel.contacts.collectAsState(emptyList())
    val errorMessage = remember { mutableStateOf("") }
    val showErrorDialog = remember { mutableStateOf(false) }
    val isInSearchMode = remember { mutableStateOf(false) }
    val searchQuery = remember { mutableStateOf("") }

    if (isMobileView) {
        Box(modifier = Modifier.fillMaxSize()) {
            val contactText = stringResource(Res.string.add_new_job_message)
            val userText = stringResource(Res.string.add_new_user_message)
            Column {
                MobileHeaderSearchAdd(
                    title = stringResource(Res.string.jobs),
                    drawerState = drawerState,
                    scope = rememberCoroutineScope(),
                    add = {
                        if (employees.isEmpty()) {
                            showErrorDialog.value = true
                            errorMessage.value = userText
                        } else if (contacts.isEmpty()) {
                            errorMessage.value = contactText
                            showErrorDialog.value = true
                        } else {
                            if (getPlatformChecker().isMobile()) {
                                navHostController.navigate(Screen.AddNewJob.route + "/$DEFAULT_JOB_ID")
                            } else {
                                navHostController.navigate(Screen.AddNewJob.route + "?jobId=")
                            }
                        }
                    },
                    search = {
                        isInSearchMode.value = true
                    },
                    onCancelSearch = {
                        isInSearchMode.value = false
                        searchQuery.value = ""
                    },
                    user = prefsManager.getUser()
                )
                if (isInSearchMode.value) {
                    Spacer(modifier = Modifier.height(15.dp))
                    SearchField(searchQuery)
                } else {
                    if (viewModel.filteredJobs.isNotEmpty()) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(horizontal = 16.dp, vertical = 10.dp)
                        ) {
                            if (!viewModel.isInEditMode.value) {
                                viewModel.showEditPopUp.value = false
                                Text(
                                    text = stringResource(Res.string.edit),
                                    fontSize = 14.sp,
                                    color = TextColor,
                                    modifier = Modifier
                                        .clickable {
                                            viewModel.isInEditMode.value = true
                                            viewModel.showEditPopUp.value = true
                                        }
                                        .weight(1f),
                                    textAlign = TextAlign.Start
                                )
                            } else {
                                Text(
                                    text = stringResource(Res.string.cancel),
                                    fontSize = 14.sp,
                                    color = TextColor,
                                    modifier = Modifier
                                        .clickable {
                                            viewModel.isInEditMode.value = false
                                            viewModel.showEditPopUp.value = false
                                            viewModel.selectedJobs.clear()
                                        }
                                        .weight(1f),
                                    textAlign = TextAlign.Start
                                )
                            }

                            if (!viewModel.isInEditMode.value) {
                                val leadingText =
                                    if (viewModel.filteredJobs.size == 1) stringResource(Res.string.job) else stringResource(
                                        Res.string.jobs
                                    )
                                Text(
                                    text = "${stringResource(Res.string.total)}: ${viewModel.filteredJobs.size} $leadingText",
                                    fontSize = 14.sp,
                                    color = Color.Black,
                                    maxLines = 1,
                                    fontStyle = FontStyle.Italic,
                                    textAlign = TextAlign.Center
                                )
                            }

                            Row(
                                horizontalArrangement = Arrangement.End,
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Row(
                                    modifier = Modifier.clickable {
                                        FilterDialogStateManager.setDialogShowing(true)
                                    },
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Text(
                                        text = stringResource(Res.string.filter),
                                        fontSize = 14.sp,
                                        color = TextColor,
                                        modifier = Modifier.padding(end = 8.dp),
                                        textAlign = TextAlign.End
                                    )
                                    Icon(
                                        painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                                        contentDescription = null,
                                        modifier = Modifier.size(20.dp)
                                    )
                                }
                            }
                        }
                    }
                    Divider(
                        modifier = Modifier.height(2.dp)
                            .background(Color.LightGray.copy(alpha = 0.1f))
                    )
                }
                if (viewModel.isEmployee() && viewModel.filteredJobs.size == 0) {
                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.no_tasks_created),
                        color = Color.Black,
                        fontSize = 24.sp,
                        textAlign = TextAlign.Center,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.fillMaxWidth()
                            .padding(top = 60.dp, start = 16.dp, end = 16.dp)
                    )
                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.check_soon),
                        color = Color.Gray,
                        textAlign = TextAlign.Center,
                        fontSize = 16.sp,
                        modifier = Modifier.padding(top = 20.dp, start = 16.dp, end = 16.dp)
                    )
                } else if (!viewModel.isEmployee() && viewModel.filteredJobs.size == 0) {
                    viewModel.isInEditMode.value = false
                    viewModel.showEditPopUp.value = false
                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.no_tasks_created),
                        color = Color.Black,
                        fontSize = 24.sp,
                        textAlign = TextAlign.Center,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.fillMaxWidth()
                            .padding(top = 60.dp, start = 16.dp, end = 16.dp)
                    )
                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.no_tasks_assigned),
                        color = Color.Gray,
                        textAlign = TextAlign.Center,
                        fontSize = 16.sp,
                        modifier = Modifier.padding(top = 20.dp, start = 16.dp, end = 16.dp)
                    )
                } else {

                    val filteredJobsSearch = if (searchQuery.value.isNotBlank()) {
                        viewModel.filteredJobs.filter { job ->
                            job.jobTitle?.contains(searchQuery.value, ignoreCase = true) == true ||
                                    job.contactSiteName?.contains(
                                        searchQuery.value,
                                        ignoreCase = true
                                    ) == true
                        }
                    } else {
                        viewModel.filteredJobs
                    }

                    if (filteredJobsSearch.isEmpty()) {
                        viewModel.isInEditMode.value = false
                        viewModel.showEditPopUp.value = false
                    }
                    JobsList(
                        viewModel.sites,
                        filteredJobsSearch,
                        viewModel.selectedJobs,
                        isInEditMode = viewModel.isInEditMode.value,
                        navHostController
                    )
                }
            }
            if (viewModel.showEditPopUp.value) {
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .background(Color.Black.copy(alpha = 0.05f))
                )

                Box(
                    modifier = Modifier
                        .align(Alignment.BottomEnd)
                ) {
                    EditJobsDialog(
                        showDialog = viewModel.showEditPopUp.value,
                        jobs = viewModel.selectedJobs,
                        onDelete = {
                            viewModel.deleteSelectedJobs(
                                onAllDeleted = {
                                    viewModel.showLoader.value = false
                                    viewModel.showPopUpSuccess.value = true
                                },
                                onError = {
                                    viewModel.showLoader.value = false
                                    viewModel.showPopUpError.value = true
                                })
                        },
                        onExportList = {},
                        onImportList = {},
                        isEmployee = viewModel.isEmployee()
                    )
                }
            }
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { viewModel.showPopUpSuccess.value = false },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.job_deleted),
                    onPositiveBtnClicked = { viewModel.showPopUpSuccess.value = false },
                    positiveBtnText = stringResource(Res.string.ok)
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
            if (showErrorDialog.value) {
                ErrorDialog(
                    onDismiss = { showErrorDialog.value = false },
                    title = "",
                    message = errorMessage.value
                )
            }

            val employeesList = viewModel.jobList.flatMap { job ->
                job.assignees?.employees ?: emptyList()
            }
            val names = employeesList.map { it?.getName() }
            val teams = viewModel.teams.collectAsState(emptyList())
            val teamsName = teams.value.map { it.name }
            val contactAddresses = viewModel.jobList.map { job ->
                viewModel.sites[job.id]?.addressObject?.address ?: ""
            }.distinct()
            if (FilterDialogStateManager.dialogShowing.value) {
                FilterJobsDialog(
                    viewModel.jobList.mapNotNull {
                        viewModel.jobStatusMap.entries.find { entry -> entry.value == it.status }?.key
                    }.distinct(),
                    (names + teamsName).distinct(),
                    contactAddresses,
                    viewModel.jobList.mapNotNull { it.startDate }.distinct(),
                    initialSelectedStatus = viewModel.selectedStatus.value.map { stat ->
                        viewModel.jobStatusMap.entries.find { entry -> entry.value == stat }?.key
                            ?: ""
                    }.toSet(),
                    initialSelectedEmployees = viewModel.selectedEmployees.value,
                    initialSelectedLocations = viewModel.selectedLocation.value,
                    initialSelectedSchedule = viewModel.selectedSchedule.value,
                    onDismiss = {
                        FilterDialogStateManager.setDialogShowing(false)
                    },
                    onApply = { selectedStatus, selectedEmployees, selectedLocation, selectedSchedule ->
                        FilterDialogStateManager.setDialogShowing(false)
                        viewModel.selectedStatus.value = selectedStatus.map { entry ->
                            viewModel.jobStatusMap[entry] ?: entry
                        }.toSet()
                        viewModel.selectedEmployees.value = selectedEmployees
                        viewModel.selectedLocation.value = selectedLocation
                        viewModel.selectedSchedule.value = selectedSchedule
                        viewModel.filterJobs()
                    }
                )
            }
        }
    } else {
        JobsWeb(
            navController = navHostController,
            viewModel = viewModel
        )
    }
}
