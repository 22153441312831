package com.hyperether.goodjob.scenes.register

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.ClickableText
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.app_logo
import com.hyperether.goodjob.resources.apple
import com.hyperether.goodjob.resources.contact_ic
import com.hyperether.goodjob.resources.continue_with_apple
import com.hyperether.goodjob.resources.continue_with_fb
import com.hyperether.goodjob.resources.continue_with_google
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.email_ic
import com.hyperether.goodjob.resources.fb
import com.hyperether.goodjob.resources.forgot_password
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.google
import com.hyperether.goodjob.resources.hello
import com.hyperether.goodjob.resources.join_good_job
import com.hyperether.goodjob.resources.join_us
import com.hyperether.goodjob.resources.manage_employees
import com.hyperether.goodjob.resources.pass_ic
import com.hyperether.goodjob.resources.password
import com.hyperether.goodjob.resources.ready_for
import com.hyperether.goodjob.resources.register
import com.hyperether.goodjob.resources.register_with
import com.hyperether.goodjob.resources.sign_in
import com.hyperether.goodjob.resources.sign_in_with
import com.hyperether.goodjob.resources.start_organizing
import com.hyperether.goodjob.resources.web_left_content
import com.hyperether.goodjob.resources.welcome_back
import com.hyperether.goodjob.resources.your_email_address
import com.hyperether.goodjob.resources.your_full_name
import com.hyperether.goodjob.resources.your_password
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun RegisterScreen(registerViewModel: RegisterViewModel, navHostController: NavHostController) {

    var isRegisterSelected = remember { mutableStateOf(true) }

    if (getPlatformChecker().isMobile()) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {

            Box(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Image(
                    painter = painterResource(Res.drawable.app_logo),
                    contentDescription = "app logo",
                    modifier = Modifier
                        .align(Alignment.TopCenter)
                        .padding(bottom = 16.dp, top = 30.dp)
                )

            }
            Column(
                modifier = Modifier
                    .padding(top = 20.dp, start = 16.dp, end = 16.dp)
                    .background(Color.LightGray.copy(alpha = 0.2f))
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(40.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    Text(
                        text = stringResource(Res.string.register),
                        color = if (isRegisterSelected.value) BlueApp else Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(start = 40.dp)
                            .clickable {
                                isRegisterSelected.value = true
                            }
                    )

                    Spacer(modifier = Modifier.weight(0.5f))

                    Text(
                        text = stringResource(Res.string.sign_in),
                        color = if (isRegisterSelected.value) Color.Gray else BlueApp,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(end = 40.dp)
                            .clickable {
                                isRegisterSelected.value = false
                            }
                    )
                }


            }
            val string1 = stringResource(Res.string.your_full_name)
            val string2 = stringResource(Res.string.your_email_address)
            val string3 = stringResource(Res.string.your_password)
            val nameInput = remember { mutableStateOf("") }
            val mailInput = remember { mutableStateOf("") }
            val passwordInput = remember { mutableStateOf("") }

            Box(
                modifier = Modifier
                    .height(2.dp)
                    .padding(start = 16.dp, end = 16.dp)
                    .fillMaxWidth(0.5f)
                    .background(if (isRegisterSelected.value) BlueApp else BlueApp)
                    .align(if (isRegisterSelected.value) Alignment.Start else Alignment.End)
            )

            if (isRegisterSelected.value) {
                RegisterSignInContent(
                    stringResource(Res.string.join_good_job),
                    stringResource(Res.string.start_organizing),
                    nameInput,
                    mailInput,
                    passwordInput,
                    stringResource(Res.string.register),
                    stringResource(Res.string.register_with),
                    isRegisterSelected = isRegisterSelected.value,
                    registerViewModel,
                    navHostController
                )
            } else {
                RegisterSignInContent(
                    stringResource(Res.string.welcome_back),
                    stringResource(Res.string.manage_employees),
                    nameInput,
                    mailInput,
                    passwordInput,
                    stringResource(Res.string.sign_in),
                    stringResource(Res.string.sign_in_with),
                    isRegisterSelected = isRegisterSelected.value,
                    registerViewModel,
                    navHostController
                )
            }
            IconButtonRow(
                onFbClick = { },
                onGoogleClick = { },
                onAppleClick = { }
            )
        }
    } else {
        val string1 = stringResource(Res.string.your_full_name)
        val string2 = stringResource(Res.string.your_email_address)
        val string3 = stringResource(Res.string.your_password)
        val nameInput = remember { mutableStateOf("") }
        val mailInput = remember { mutableStateOf("") }
        val passwordInput = remember { mutableStateOf("") }
        Row(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(rememberScrollState())
        ) {
            Image(
                modifier = Modifier.weight(2.5f),
                painter = painterResource(Res.drawable.web_left_content),
                contentDescription = null,
                contentScale = ContentScale.Crop,
                alignment = Alignment.CenterStart
            )
            Column(
                modifier = Modifier
                    .fillMaxHeight()
                    .weight(1.5f)
                    .padding(start = 20.dp, end = 20.dp)
            ) {
                RegisterSignInContentWeb(
                    title = if (isRegisterSelected.value) stringResource(Res.string.hello) else stringResource(
                        Res.string.welcome_back
                    ),
                    description = if (isRegisterSelected.value) stringResource(Res.string.join_us) else stringResource(
                        Res.string.ready_for
                    ),
                    firstInputText = nameInput,
                    secondInputText = mailInput,
                    thirdInputText = passwordInput,
                    btnText = if (isRegisterSelected.value) stringResource(Res.string.register) else stringResource(
                        Res.string.sign_in
                    ),
                    dividerText = if (isRegisterSelected.value) stringResource(Res.string.register_with) else stringResource(
                        Res.string.sign_in_with
                    ),
                    isRegisterSelected = isRegisterSelected,
                    viewModel = registerViewModel,
                    navHostController = navHostController
                )
            }
        }
    }
}

@Composable
fun RegisterSignInContent(
    title: String,
    description: String,
    firstInputText: MutableState<String>,
    secondInputText: MutableState<String>,
    thirdInputText: MutableState<String>,
    btnText: String,
    dividerText: String,
    isRegisterSelected: Boolean,
    viewModel: RegisterViewModel,
    navHostController: NavHostController
) {

    var showSuccessDialog = remember { mutableStateOf(false) }
    var showErrorDialog = remember { mutableStateOf(false) }
    val showProgressBar = remember { mutableStateOf(false) }


    Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
        Text(
            text = title,
            color = Color.Black,
            fontSize = 24.sp,
            fontWeight = FontWeight.Bold,
            modifier = Modifier.padding(top = 25.dp)
        )
        Text(
            text = description,
            color = Color.Gray,
            textAlign = TextAlign.Left,
            fontSize = 16.sp,
            modifier = Modifier.padding(top = 20.dp, bottom = 15.dp)
        )
        if (isRegisterSelected) {
            RegisterInputField(firstInputText, stringResource(Res.string.full_name), placeholder = stringResource(Res.string.your_full_name))
        }
        RegisterInputField(secondInputText, stringResource(Res.string.email_address), placeholder = stringResource(Res.string.your_email_address))
        RegisterInputField(thirdInputText, stringResource(Res.string.password), placeholder = stringResource(Res.string.your_password))

        if (!isRegisterSelected) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = stringResource(Res.string.forgot_password),
                    color = BlueApp,
                    modifier = Modifier
                        .padding(top = 15.dp)
                        .clickable {
                        }
                )
            }
        }

        FilledButton(
            onClick = {
                showProgressBar.value = true
                if (isRegisterSelected) {
                    viewModel.register(firstInputText.value,
                        secondInputText.value,
                        thirdInputText.value,
                        onSuccess = {
                            showProgressBar.value = false
                            showSuccessDialog.value = true
                        },
                        onError = {
                            showProgressBar.value = false
                            showErrorDialog.value = true
                        })
                } else {
                    viewModel.login(secondInputText.value, thirdInputText.value,
                        onSuccess = {
                            showProgressBar.value = false
                            navHostController.navigate(Screen.Pricing.route)
                        },
                        onError = {
                            showProgressBar.value = false
                            showErrorDialog.value = true
                        })
                }
            },
            text = btnText,
            modifier = Modifier.padding(top = 30.dp),
            isEnabled = if (isRegisterSelected) {
                firstInputText.value.isNotEmpty() && secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty()
            } else {
                secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty()
            }
        )
        Row(
            modifier = Modifier.fillMaxWidth().padding(top = 25.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(
                modifier = Modifier
                    .weight(1f)
                    .height(1.dp)
                    .background(Color.Gray)
            )

            Text(
                text = dividerText,
                modifier = Modifier
                    .padding(horizontal = 16.dp)
                    .align(Alignment.CenterVertically),
                color = Color.Gray,
                fontSize = 16.sp
            )

            Box(
                modifier = Modifier
                    .weight(1f)
                    .height(1.dp)
                    .background(Color.Gray)
            )
            if (showSuccessDialog.value) {
                PopUpDialog(
                    onDismiss = {
                        showSuccessDialog.value = false
                    },
                    title = "Confirm registration",
                    message = "Thank you for registering!\nWe’ve sent a confirmation link to your email.\nPlease check your inbox and click\nthe link to complete your registration."
                )
            }

            if (showErrorDialog.value) {
                PopUpDialog(
                    onDismiss = {
                        showErrorDialog.value = false
                    },
                    title = "Error",
                    message = "There was an error,please try again"
                )
            }
            if (showProgressBar.value) {
                FullScreenProgressBar(showProgressBar = showProgressBar)
            }
        }

    }
}

@Composable
fun RegisterSignInContentWeb(
    title: String,
    description: String,
    firstInputText: MutableState<String>,
    secondInputText: MutableState<String>,
    thirdInputText: MutableState<String>,
    btnText: String,
    dividerText: String,
    isRegisterSelected: MutableState<Boolean>,
    viewModel: RegisterViewModel,
    navHostController: NavHostController
) {

    var showSuccessDialog = remember { mutableStateOf(false) }
    var showErrorDialog = remember { mutableStateOf(false) }
    val showProgressBar = remember { mutableStateOf(false) }

    val focusManager = LocalFocusManager.current
    val focusRequester = FocusRequester()

    Box(
        modifier = Modifier.fillMaxSize()
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(bottom = 16.dp, top = 50.dp),
            verticalArrangement = Arrangement.SpaceBetween
        ) {
            Column(
                modifier = Modifier.padding(start = 16.dp, end = 16.dp).fillMaxWidth()
            ) {
                Text(
                    text = title,
                    color = Color.Black,
                    fontSize = 24.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(top = 25.dp).align(Alignment.CenterHorizontally)
                )
                Text(
                    text = description,
                    color = Color(0xFF676767),
                    textAlign = TextAlign.Left,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(top = 20.dp, bottom = 15.dp)
                        .align(Alignment.CenterHorizontally)
                )
                if (isRegisterSelected.value) {
                    RegisterInputFieldWeb(
                        firstInputText,
                        stringResource(Res.string.full_name),
                        Res.drawable.contact_ic,
                        focusManager,
                        Modifier.focusRequester(focusRequester)
                    )
                    LaunchedEffect(true) {
                        focusRequester.requestFocus()
                    }
                }

                RegisterInputFieldWeb(
                    secondInputText,
                    stringResource(Res.string.email_address),
                    Res.drawable.email_ic,
                    focusManager
                )
                RegisterInputFieldWeb(
                    thirdInputText,
                    stringResource(Res.string.password),
                    Res.drawable.pass_ic,
                    focusManager
                )

                if (!isRegisterSelected.value) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = stringResource(Res.string.forgot_password),
                            color = BlueApp,
                            modifier = Modifier
                                .padding(top = 15.dp)
                                .clickable {

                                }
                        )
                    }
                }

                FilledButton(
                    onClick = {
                        showProgressBar.value = true
                        if (isRegisterSelected.value) {
                            viewModel.register(firstInputText.value,
                                secondInputText.value,
                                thirdInputText.value,
                                onSuccess = {
                                    showProgressBar.value = false
                                    showSuccessDialog.value = true
                                },
                                onError = {
                                    showProgressBar.value = false
                                    showErrorDialog.value = true
                                })
                        } else {
                            viewModel.login(secondInputText.value, thirdInputText.value,
                                onSuccess = {
                                    showProgressBar.value = false
                                    navHostController.navigate(Screen.Pricing.route)
                                },
                                onError = {
                                    showProgressBar.value = false
                                    showErrorDialog.value = true
                                })
                        }
                    },
                    text = btnText,
                    modifier = Modifier.padding(top = 30.dp),
                    isEnabled = if (isRegisterSelected.value) {
                        firstInputText.value.isNotEmpty() && secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty()
                    } else {
                        secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty()
                    }
                )
                Row(
                    modifier = Modifier.fillMaxWidth().padding(top = 25.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Box(
                        modifier = Modifier
                            .weight(1f)
                            .height(1.dp)
                            .background(Color.Gray)
                    )

                    Text(
                        text = dividerText,
                        modifier = Modifier
                            .padding(horizontal = 16.dp)
                            .align(Alignment.CenterVertically),
                        color = Color.Gray,
                        fontSize = 16.sp
                    )

                    Box(
                        modifier = Modifier
                            .weight(1f)
                            .height(1.dp)
                            .background(Color.Gray)
                    )
                }
                RegisterWithButton(
                    onClick = {
                        // TODO
                    },
                    text = stringResource(Res.string.continue_with_google),
                    modifier = Modifier.padding(top = 30.dp),
                    isEnabled = true,
                    drawableResource = Res.drawable.google
                )
                RegisterWithButton(
                    onClick = {
                        // TODO
                    },
                    text = stringResource(Res.string.continue_with_fb),
                    modifier = Modifier.padding(top = 30.dp),
                    isEnabled = true,
                    drawableResource = Res.drawable.fb
                )
                RegisterWithButton(
                    onClick = {
                        // TODO
                    },
                    text = stringResource(Res.string.continue_with_apple),
                    modifier = Modifier.padding(top = 30.dp),
                    isEnabled = true,
                    drawableResource = Res.drawable.apple
                )
            }
            Spacer(modifier = Modifier.height(50.dp))
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(vertical = 16.dp),
                contentAlignment = Alignment.Center
            ) {
                if (isRegisterSelected.value) {
                    TextWithClickablePart(
                        nonClickableText = "Already have an account?",
                        clickableText = "Sign in",
                        onClick = { isRegisterSelected.value = false }
                    )
                } else {
                    TextWithClickablePart(
                        nonClickableText = "Need an account?",
                        clickableText = "Create one",
                        onClick = { isRegisterSelected.value = true }
                    )
                }
            }
        }

//        Box(
//            modifier = Modifier
//                .align(Alignment.BottomCenter)
//                .padding(16.dp)
//        ) {
//            if (isRegisterSelected.value) {
//                TextWithClickablePart(
//                    nonClickableText = "Already have an account?",
//                    clickableText = "Sign in",
//                    onClick = { isRegisterSelected.value = false }
//                )
//            } else {
//                TextWithClickablePart(
//                    nonClickableText = "Need an account?",
//                    clickableText = "Create one",
//                    onClick = { isRegisterSelected.value = true }
//                )
//            }
//        }

    }
    if (showSuccessDialog.value) {
        PopUpDialog(
            onDismiss = {
                showSuccessDialog.value = false
            },
            title = "Confirm registration",
            message = "Thank you for registering!\nWe’ve sent a confirmation link to your email.\nPlease check your inbox and click\nthe link to complete your registration."
        )
    }

    if (showErrorDialog.value) {
        PopUpDialog(
            onDismiss = {
                showErrorDialog.value = false
            },
            title = "Error",
            message = "There was an error,please try again"
        )
    }
    if (showProgressBar.value) {
        FullScreenProgressBar(showProgressBar = showProgressBar)
    }
}

@Composable
fun TextWithClickablePart(
    nonClickableText: String,
    clickableText: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    val annotatedText = buildAnnotatedString {
        withStyle(
            style = SpanStyle(
                color = Color(0xFF797676),
                fontSize = 16.sp
            )
        ) {
            append(nonClickableText)
        }
        append(" ")

        pushStringAnnotation(tag = "CLICKABLE", annotation = "clickable")
        withStyle(
            style = SpanStyle(
                color = BlueApp,
                textDecoration = TextDecoration.Underline,
                fontSize = 16.sp
            )
        ) {
            append(clickableText)
        }
        pop()
    }

    ClickableText(
        text = annotatedText,
        onClick = { offset ->
            annotatedText.getStringAnnotations(tag = "CLICKABLE", start = offset, end = offset)
                .firstOrNull()?.let {
                    onClick()
                }
        },
        modifier = modifier.padding(bottom = 16.dp)
    )
}