package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.job_status_completed
import com.hyperether.goodjob.resources.job_status_on_hold
import com.hyperether.goodjob.resources.job_status_in_progress
import com.hyperether.goodjob.resources.job_status_open
import com.hyperether.goodjob.resources.scheduler
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.jobs.components.FilterJobsDialog
import com.hyperether.goodjob.scenes.scheduler.components.SchedulerMonthJobCard
import com.hyperether.goodjob.util.JobStatusMap
import com.hyperether.planner.ui.ScheduleMonthPlanner
import com.hyperether.planner.ui.SchedulerDayPlanner
import com.hyperether.planner.ui.WeekPlanner
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun SchedulerScreen(
    viewModel: SchedulerViewModel,
    navHostController: NavHostController,
    drawerState: DrawerState,
    prefsManager: PrefsManager,
    isMobile: Boolean,
    completedColor: Color = Color(172, 229, 185),
    pendingColor: Color = Color(235, 231, 116),
    delayedColor: Color = Color(229, 172, 172),
) {

    viewModel.jobStatusMap = JobStatusMap()

    val userState by viewModel.userState.collectAsState()
    val jobState by viewModel.jobsState.collectAsState()

    LaunchedEffect(viewModel.jobsState) {
        viewModel.parseSchedule(jobState, userState)
    }

    if (isMobile) {

        Column {
            MobileHeaderSearchAdd(
                title = stringResource(Res.string.scheduler),
                drawerState = drawerState,
                scope = rememberCoroutineScope(),
                add = { navHostController.navigate(Screen.AddNew.route) },
                search = {},
                removeSearch = true,
                user = prefsManager.getUser()
            )

            Row(
                modifier = Modifier.fillMaxWidth().padding(horizontal = 20.dp, vertical = 12.dp),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                PeriodChooser(viewModel.periodState) {
                    viewModel.periodChanged()
                    viewModel.parseSchedule(jobState, userState)
                }
                Icon(
                    painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                    contentDescription = null,
                    modifier = Modifier.size(24.dp).clickable {
                        viewModel.showFilterDialog.value = true
                    }
                )
            }

            Divider(modifier = Modifier.height(1.dp).background(Color.LightGray.copy(alpha = 0.1f)))

            PeriodSwitcher(
                text = viewModel.currentPeriod.value,
                prev = {
                    viewModel.previous()
                    viewModel.periodChanged()
                    viewModel.parseSchedule(jobState, userState)
                },
                next = {
                    viewModel.next()
                    viewModel.periodChanged()
                    viewModel.parseSchedule(jobState, userState)
                },
                modifier = Modifier.fillMaxWidth()
            )

            if (jobState != null && viewModel.initialLoad.value == true) {
                viewModel.initialLoad.value = false
                viewModel.parseSchedule(jobState, userState)
            }

            when (viewModel.periodState.value) {
                PeriodType.DAY -> SchedulerDayPlanner(
                    listOfPlans = viewModel.listOfPlans,
                    completedColor,
                    pendingColor,
                    delayedColor,
                )

                PeriodType.WEEK -> WeekPlanner(
                    listOfPlans = viewModel.listOfPlans,
                    completedColor, pendingColor, delayedColor,
                    weekStart = viewModel.weekSelectionStart.value,
                    weekEnd = viewModel.weekSelectionEnd.value,
                    onEventClicked = { event ->
                        val selectedJob = jobState?.firstOrNull { it.id == event.jobId }
                        viewModel.selectedJobWeekly.value = selectedJob
                        selectedJob?.let { viewModel.updateSite(it) }
                        viewModel.jobClicked.value = true
                    },
                    onEventHovered = {
                        viewModel.hoveredEvent.value = it
                    }
                )

                PeriodType.MONTH -> {
                    ScheduleMonthPlanner(
                        listOfPlans = viewModel.listOfPlans,
                        viewModel.monthSelectionLocalDate.value.monthNumber,
                        viewModel.monthSelectionLocalDate.value.year,
                        completedColor, pendingColor, delayedColor,
                        viewModel.monthSelectedDay
                    ) { list, day ->
                        viewModel.filterCurrentJobs(list)
                        viewModel.monthSelectedDay.value = day
                    }

                    LazyColumn() {
                        items(viewModel.currentJobs.takeIf { it.isNotEmpty() }
                            ?: emptyList()) { job ->
                            SchedulerMonthJobCard(
                                status = job.status ?: "",
                                range = "${job.start_at} - ${job.end_at}",
                                title = job.jobTitle ?: "",
                                site = job.contactSiteName ?: "",
                                employee = job.assignees?.employees?.firstOrNull()?.getName() ?: ""
                            )
                        }
                    }
                }
            }
        }

    } else {
        SchedulerScreenWeb(viewModel, navHostController)
    }

    if (viewModel.jobClicked.value) {
        viewModel.selectedJobWeekly.value?.let {
            SchedulerJobWeeklyPopUp(
                viewModel.sites,
                job = it,
                onDismissRequest = {
                    viewModel.jobClicked.value = false
                }
            )
        }
    }
    val employeesList = viewModel.jobList.flatMap { job ->
        job.assignees?.employees ?: emptyList()
    }
    val names = employeesList.map { it?.getName() }
    val teams = viewModel.teams.collectAsState(emptyList())
    val teamsName = teams.value.map { it.name }
    val contactAddresses = viewModel.jobList.map { job ->
        viewModel.sitesMap[job.id]?.addressObject?.address ?: ""
    }.distinct()

    if (viewModel.showFilterDialog.value) {
        FilterJobsDialog(
            viewModel.jobList.mapNotNull {
                viewModel.jobStatusMap.entries.find { entry -> entry.value == it.status }?.key
            }.distinct(),
            (names + teamsName).distinct(),
            contactAddresses,
            viewModel.jobList.mapNotNull { it.startDate }.distinct(),
            onDismiss = { viewModel.showFilterDialog.value = false },
            initialSelectedStatus = viewModel.selectedStatus.value.map { stat ->
                viewModel.jobStatusMap.entries.find { entry -> entry.value == stat }?.key ?: ""
            }.toSet(),
            initialSelectedEmployees = viewModel.selectedEmployees.value,
            initialSelectedLocations = viewModel.selectedLocation.value,
            initialSelectedSchedule = viewModel.selectedSchedule.value,
            onApply = { selectedStatus, selectedEmployees, selectedLocation, selectedSchedule ->
                viewModel.showFilterDialog.value = false
                viewModel.selectedStatus.value = selectedStatus.map { entry ->
                    viewModel.jobStatusMap[entry] ?: entry
                }.toSet()
                viewModel.selectedEmployees.value = selectedEmployees
                viewModel.selectedLocation.value = selectedLocation
                viewModel.selectedSchedule.value = selectedSchedule
                viewModel.filterJobs()
            }
        )
    }

    if (!isMobile) {
        viewModel.hoveredEvent.value?.let { event ->
            val selectedJob = jobState?.firstOrNull { it.id == event.jobId }
            viewModel.selectedJobWeeklyHover.value = true
            viewModel.selectedJobWeekly.value = selectedJob
            selectedJob?.let {
                viewModel.updateSite(it)
                SchedulerJobHover(
                    viewModel.sites,
                    job = it,
                    viewModel.position.value.x,
                    viewModel.position.value.y
                )
            }
        } ?: run {
            viewModel.selectedJobWeekly.value = null
            viewModel.selectedJobWeeklyHover.value = false
        }
    }
}