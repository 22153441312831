package com.hyperether.goodjob.scenes.register

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.OutlinedButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.FocusManager
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.repository.remote.model.Place
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.apple
import com.hyperether.goodjob.resources.fb
import com.hyperether.goodjob.resources.google
import com.hyperether.goodjob.resources.password_not_visible
import com.hyperether.goodjob.resources.password_visible
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.InputBorderColor
import com.hyperether.goodjob.theme.InputIconColor
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun AppInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    paddingTop: androidx.compose.ui.unit.Dp = 10.dp,
    isMaxLinesDefined: Boolean = true
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = { newValue ->
                if (enabled && newValue.count { it == '\n' } < 1) {
                    text.value = newValue
                }
            },
            label = {
                Text(
                    text = label,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            placeholder = {
                Text(
                    text = placeholder,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            enabled = enabled,
            maxLines = if (isMaxLinesDefined) 1 else Int.MAX_VALUE
        )
    }
}

@Composable
fun ZipInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    paddingTop: androidx.compose.ui.unit.Dp = 10.dp
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = { newValue ->
                if (enabled && newValue.all { it.isDigit() } && newValue.length <= 12) {
                    text.value = newValue
                }
            },
            label = {
                Text(
                    text = label,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            placeholder = {
                Text(
                    text = placeholder,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            enabled = enabled,
            maxLines = 1,
            keyboardOptions = KeyboardOptions.Default.copy(
                keyboardType = KeyboardType.Number
            )
        )
    }
}

@Composable
fun EstimatedDurationInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier
) {
    val maxDuration = 7200
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {

        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = {
                if (it.isEmpty() || (it.toIntOrNull() != null && it.toInt() in 0..maxDuration)) {
                    text.value = ""
                    text.value = it
                }
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text(placeholder, fontSize = 14.sp, color = TextColor)
            },
            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number)
        )

    }
}

@Composable
fun SignInInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {

        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value.trim(),
            onValueChange = {
                text.value = ""
                text.value = it.trim()
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text(placeholder, fontSize = 14.sp, color = TextColor)
            }
        )

    }
}


@Composable
fun DurationInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier,
    paddingTop: androidx.compose.ui.unit.Dp = 10.dp
) {
    //limit job duration 5 days
    val maxDuration = 7200

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
    ) {

        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = {
                if (it.isEmpty() || (it.toIntOrNull() != null && it.toInt() in 0..maxDuration)) {
                    text.value = ""
                    text.value = it
                }
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text("0 h", fontSize = 14.sp, color = TextColor)
            },
            enabled = true,
            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number)
        )
    }
}

@Composable
fun RegisterInputFieldWeb(
    text: MutableState<String>,
    label: String,
    drawableResource: DrawableResource,
    focusManager: FocusManager,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.dp, bottom = 10.dp)
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth()
                .height(Dp(60f))
                .onPreviewKeyEvent {
                    when {
                        KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                            focusManager.moveFocus(FocusDirection.Next)
                            true
                        }

                        else -> false
                    }
                }
                .then(modifier),
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it.trim()
            },
            label = {
                Text(
                    text = label,
                    color = Color(0xFF676767)
                )
            },
            placeholder = {
                Text(
                    color = Color(0xFF676767),
                    text = label
                )
            },
            leadingIcon = {
                Image(
                    painter = painterResource(drawableResource),
                    contentDescription = null,
                    modifier = Modifier
                        .padding(bottom = 16.dp, top = 16.dp),
                    alignment = Alignment.CenterStart
                )
            },
            colors = OutlinedTextFieldDefaults.colors(
                focusedBorderColor = InputBorderColor,
                unfocusedBorderColor = RegisterBorder,
                disabledTextColor = TextColor
            ),
            shape = RoundedCornerShape(Dp(12f))
        )
    }
}

@Composable
fun RegisterPasswordInputFieldWeb(
    text: MutableState<String>,
    label: String,
    drawableResource: DrawableResource,
    focusManager: FocusManager,
    modifier: Modifier = Modifier
) {
    var passVisible by remember { mutableStateOf(false) }
    Box(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth()
                .height(Dp(60f))
                .onPreviewKeyEvent {
                    when {
                        KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                            focusManager.moveFocus(FocusDirection.Next)
                            true
                        }

                        else -> false
                    }
                }
                .then(modifier),
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it.trim()
            },
            label = {
                Text(
                    text = label,
                    color = Color(0xFF676767)
                )
            },
            placeholder = {
                Text(
                    color = Color(0xFF676767),
                    text = label
                )
            },
            leadingIcon = {
                Image(
                    painter = painterResource(drawableResource),
                    contentDescription = null,
                    modifier = Modifier
                        .padding(bottom = Dp(16f), top = Dp(16f)),
                    alignment = Alignment.CenterStart
                )
            },
            visualTransformation = if (passVisible) VisualTransformation.None else PasswordVisualTransformation(),
            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Password),
            trailingIcon = {
                val image =
                    if (passVisible) Res.drawable.password_visible else Res.drawable.password_not_visible
                IconButton(onClick = { passVisible = !passVisible }) {
                    Icon(
                        painter = painterResource(image),
                        "Show/Hide password",
                        tint = InputIconColor
                    )
                }
            },
            colors = OutlinedTextFieldDefaults.colors(
                focusedBorderColor = InputBorderColor,
                unfocusedBorderColor = RegisterBorder,
                disabledTextColor = TextColor
            ),
            shape = RoundedCornerShape(Dp(12f))
        )
    }
}

@Composable
fun FilledButton(
    onClick: () -> Unit,
    isEnabled: Boolean = true,
    text: String,
    modifier: Modifier = Modifier,
    color: Color = BlueApp
) {
    Button(
        onClick = onClick,
        modifier = modifier
            .fillMaxWidth()
            .height(50.dp)
            .clip(RoundedCornerShape(25.dp)),
        colors = ButtonDefaults.buttonColors(color),
        enabled = isEnabled
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            androidx.compose.material.Text(
                text = text,
                color = Color.White,
                fontSize = 15.sp,
                fontWeight = FontWeight.Normal
            )
        }
    }
}

@Composable
fun RegisterWithButton(
    onClick: () -> Unit,
    isEnabled: Boolean = true,
    text: String,
    modifier: Modifier = Modifier,
    drawableResource: DrawableResource
) {
    OutlinedButton(
        onClick = onClick,
        border = BorderStroke(1.dp, Color(0xFFE6E8E7)),
        modifier = modifier
            .height(Dp(54f))
            .fillMaxWidth(),
        shape = RoundedCornerShape(25.dp),
        colors = ButtonDefaults.outlinedButtonColors(
            backgroundColor = Color.White,
            contentColor = Color(0xFF313030)
        ),
        enabled = isEnabled
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            androidx.compose.material.Text(
                text = text,
                color = Color(0xFF313030),
                fontSize = 16.sp,
                fontWeight = FontWeight.Normal
            )
            Spacer(modifier = Modifier.width(8.dp))
            Image(
                painter = painterResource(drawableResource),
                contentDescription = null,
                modifier = Modifier
                    .size(24.dp),
                alignment = Alignment.CenterEnd
            )
        }
    }
}

@Composable
fun IconButtonRow(
    onFbClick: () -> Unit,
    onGoogleClick: () -> Unit,
    onAppleClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 30.dp),
        horizontalArrangement = Arrangement.SpaceEvenly,
        verticalAlignment = Alignment.CenterVertically
    ) {
        val icons = listOf(
            Res.drawable.fb to onFbClick,
            Res.drawable.google to onGoogleClick,
            Res.drawable.apple to onAppleClick
        )

        for ((icon, clickAction) in icons) {
            Box(
                modifier = Modifier
                    .width(100.dp)
                    .height(55.dp)
                    .border(1.dp, Color.Gray, RoundedCornerShape(8.dp))
                    .background(Color.Transparent)
                    .clickable { clickAction() },
                contentAlignment = Alignment.Center
            ) {
                Image(
                    painter = painterResource(icon),
                    contentDescription = "Icon",
                    modifier = Modifier.size(24.dp)
                )
            }
        }
    }
}