package com.hyperether.goodjob.scenes.jobs.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.clock_small
import com.hyperether.goodjob.resources.employees_job_item
import com.hyperether.goodjob.resources.map_job_item
import com.hyperether.goodjob.resources.user_check_job_item
import com.hyperether.goodjob.scenes.components.CircleWithInitial
import com.hyperether.goodjob.scenes.employees.RoundedCornerCheckbox
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource

@Composable
fun JobsItem(
    sites: Map<String, Site>,
    job: Job,
    isInEditMode: Boolean,
    selectedJobs: MutableList<Job>,
    onJobSelected: (Job, Boolean) -> Unit,
    onItemClick: () -> Unit
) {

    Column(modifier = Modifier.fillMaxWidth().padding(top = 15.dp).clickable { onItemClick() }) {
        job.startDate?.let {
            Text(
                text = DateTimeUtil.jobListDateFormatter(it),
                color = Color.Gray,
                fontSize = 12.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(bottom = 8.dp, start = 8.dp, top = 10.dp)
            )
        }
        Row {
            if (isInEditMode) {
                RoundedCornerCheckbox(
                    isChecked = selectedJobs.contains(job),
                    onCheckedChange = { checked ->
                        onJobSelected(job, checked)
                    }
                )
            }
            Spacer(modifier = Modifier.width(10.dp))

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .border(1.dp, Color.LightGray, RoundedCornerShape(8.dp))
            ) {
                Column(modifier = Modifier.padding(12.dp)) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        job.jobTitle?.let {
                            Text(
                                text = it,
                                color = Color.Black,
                                fontWeight = FontWeight.Bold,
                                fontSize = 18.sp,
                                modifier = Modifier
                                    .weight(1f)
                                    .padding(bottom = 8.dp)
                            )
                        }
                        Box(
                            modifier = Modifier
                                .size(16.dp)
                                .background(color = job.status?.let {
                                    StatusColor().getStatusColor(it)
                                } ?: Green, shape = CircleShape)
                        )
                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.user_check_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.id?.let { id ->
                            sites[id]?.let {
                                Text(
                                    text = it.siteName ?: it.personName ?: "",
                                    color = BlueApp,
                                )
                            }
                        } ?: run {
                            job.id?.let { id ->
                                sites[id]?.let {
                                    Text(
                                        text = it.siteName ?: it.personName ?: "",
                                        color = BlueApp,
                                    )
                                }
                            }
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.map_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.contactSiteId?.let {
                            Text(
                                text = sites[job.id]?.addressObject?.address ?: "",
                                color = BlueApp,
                            )
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.clock_small),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.start_at?.let { start ->
                            job.end_at?.let { end ->
                                Text(
                                    text = "$start - $end",
                                    color = Color.Black,
                                    fontWeight = FontWeight.Bold
                                )
                            }
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.employees_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        val employees = job.assignees?.employees
                        val teams = job.assignees?.teams
                        val names: List<String> =
                            (employees?.map { it?.getName() } ?: emptyList())
                                .filterNotNull()
                                .plus((teams?.map { it?.name } ?: emptyList()).filterNotNull())
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(8.dp),
                            horizontalArrangement = Arrangement.Start
                        ) {
                            names.forEach { name ->
                                CircleWithInitial(name)
                            }
                        }
                    }
                }
            }
        }
    }
}