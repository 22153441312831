package com.hyperether.goodjob.scenes.dashboard

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.map.MapComponent
import com.hyperether.goodjob.repository.remote.model.LatLng
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.dashboard
import com.hyperether.goodjob.resources.locations_agenda
import com.hyperether.goodjob.resources.locations_agenda_text
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.dashboard.map.DashboardMapViewModel
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.GrayBackground
import org.jetbrains.compose.resources.stringResource

@Composable
fun DashboardScreenWebView(
    navHostController: NavHostController,
    viewModel: DashboardMapViewModel
) {

    val currentPosition = remember { mutableStateOf<LatLng?>(null) }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
    ) {
        WebViewHeader(
            isBackAvailable = false,
            title = stringResource(Res.string.dashboard),
            isImportAndAddVisible = true,
            navHostController = navHostController
        )

        Spacer(modifier = Modifier.height(Dp(16f)))
        // TODO: add the rest of the screen
        // Scheduler and Map Section
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .weight(1.2f)
                .padding(vertical = 8.dp, horizontal = 32.dp)
        ) {
            // Scheduler
            Column(
                modifier = Modifier
                    .weight(0.6f)
                    .fillMaxHeight()
                    .padding(end = 8.dp)
                    .background(Color.White, shape = RoundedCornerShape(8.dp))
                    .border(width = 1.dp, shape = RoundedCornerShape(8.dp), color = BorderColor)
                    .padding(16.dp)
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(bottom = 16.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text("Scheduler", style = MaterialTheme.typography.titleLarge)
                    Row {
                        Text("Today", modifier = Modifier.padding(end = 8.dp))
                        Text("Select employee")
                    }
                }
                Column(
                    modifier = Modifier.fillMaxSize()
                ) {
                    // Replace with scheduler items
                    Text("08:00 Replace Electrical Meter")
                    Text("09:30 Conduct Gas Leak Inspection")
                    Text("11:00 Install New Water Line")
                }
            }

            // Map
            Box(
                modifier = Modifier
                    .weight(0.5f)
                    .fillMaxHeight()
                    .background(Color.White, shape = RoundedCornerShape(8.dp))
                    .border(width = 1.dp, shape = RoundedCornerShape(8.dp), color = BorderColor)
            ) {
                Column {

                    Column(
                        modifier = Modifier.padding(16.dp)
                    ) {
                        Text("${viewModel.jobList.size} ${stringResource(Res.string.locations_agenda)}", style = MaterialTheme.typography.titleLarge)
                        Spacer(modifier = Modifier.height(8.dp))
                        Text(stringResource(Res.string.locations_agenda_text))
                    }


                    if (viewModel.jobList.isNotEmpty()) {
                        println("Map should be updated")
                        if (viewModel.markers.isNotEmpty())
                            currentPosition.value  = LatLng(viewModel.markers[0].lat, viewModel.markers[0].lng)
                        MapComponent(
                            markers = viewModel.markers.toList(),
                            currentPosition
                        )
                    }
                }
            }
        }


        // Upcoming Tasks Section
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .weight(1f)
                .padding(horizontal = 32.dp, vertical = 8.dp)
        ) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .background(Color.White, shape = RoundedCornerShape(8.dp))
                    .border(width = 1.dp, shape = RoundedCornerShape(8.dp), color = BorderColor)
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(bottom = 8.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text("Upcoming Tasks", style = MaterialTheme.typography.titleLarge)
                    Row {
                        Text("This week", modifier = Modifier.padding(end = 8.dp))
                        Text("Select employee")
                    }
                }
                // Replace with task items
                Column {
                    Text("17 October 2024, Thursday")
                    Row {
                        Text("Install Power Meter")
                        Spacer(modifier = Modifier.weight(1f))
                        Text("09:00 - 11:00")
                        Spacer(modifier = Modifier.weight(1f))
                        Text("James Smith")
                    }
                    Row {
                        Text("Install Solar Inverter")
                        Spacer(modifier = Modifier.weight(1f))
                        Text("11:00 - 15:00")
                        Spacer(modifier = Modifier.weight(1f))
                        Text("Emily Johnson")
                    }
                }
            }
        }
    }
}