package com.hyperether.goodjob.scenes.pricing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.choose_plan
import com.hyperether.goodjob.resources.choose_the_plan
import com.hyperether.goodjob.resources.choose_the_right_plan
import com.hyperether.goodjob.resources.day_free_trial
import com.hyperether.goodjob.resources.fly_high
import com.hyperether.goodjob.resources.fly_high_description
import com.hyperether.goodjob.resources.fly_high_feature_1
import com.hyperether.goodjob.resources.fly_high_feature_2
import com.hyperether.goodjob.resources.fly_high_price
import com.hyperether.goodjob.resources.free
import com.hyperether.goodjob.resources.freebie
import com.hyperether.goodjob.resources.freebie_description
import com.hyperether.goodjob.resources.freebie_feature_1
import com.hyperether.goodjob.resources.freebie_feature_2
import com.hyperether.goodjob.resources.freebie_price
import com.hyperether.goodjob.resources.from_small_teams
import com.hyperether.goodjob.resources.galactic
import com.hyperether.goodjob.resources.galactic_description
import com.hyperether.goodjob.resources.galactic_feature_1
import com.hyperether.goodjob.resources.galactic_feature_2
import com.hyperether.goodjob.resources.galactic_price
import com.hyperether.goodjob.resources.high_five
import com.hyperether.goodjob.resources.high_five_description
import com.hyperether.goodjob.resources.high_five_feature_1
import com.hyperether.goodjob.resources.high_five_feature_2
import com.hyperether.goodjob.resources.high_five_price
import com.hyperether.goodjob.resources.logo_good_job
import com.hyperether.goodjob.resources.unlimited_storage
import com.hyperether.goodjob.scenes.landing.PricingOption
import com.hyperether.goodjob.scenes.register.FilledButton
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun PricingScreen(isMobileView: Boolean, continueToWorkspace: () -> Unit) {

    val selectedPlan = remember { mutableStateOf<String?>(Plan.FlyHigh.name) }

    if (getPlatformChecker().isMobile() || isMobileView) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(rememberScrollState())
                .background(
                    brush = Brush.verticalGradient(
                        colors = listOf(
                            Color(0xFFc7e4fc),
                            Color(0xFFFFFFFF)
                        )
                    )
                )
                .padding(16.dp)
        ) {
            Column(
                modifier = Modifier.fillMaxSize(),
            ) {
                Text(
                    text = stringResource(Res.string.choose_the_right_plan),
                    color = Color.Black,
                    fontSize = 28.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 16.dp, top = 30.dp)
                )
                PricingItem(
                    isChecked = selectedPlan.value == Plan.Freebie.name,
                    onClick = {
                        selectedPlan.value = Plan.Freebie.name
                    },
                    modifier = Modifier.padding(top = 15.dp),
                    planPrice = stringResource(Res.string.free),
                    planName = stringResource(Res.string.freebie)
                )
                PricingItem(
                    isChecked = selectedPlan.value == Plan.HighFive.name,
                    onClick = {
                        selectedPlan.value = Plan.HighFive.name
                    },
                    planPrice = "$49",
                    planName = stringResource(Res.string.high_five)
                )
                PricingItem(
                    isChecked = selectedPlan.value == Plan.FlyHigh.name,
                    onClick = {
                        selectedPlan.value = Plan.FlyHigh.name
                    },
                    modifier = Modifier.padding(top = 15.dp),
                    planPrice = "$79",
                    planName = stringResource(Res.string.fly_high)
                )
                PricingItem(
                    isChecked = selectedPlan.value == Plan.Galactic.name,
                    onClick = {
                        selectedPlan.value = Plan.Galactic.name
                    },
                    planPrice = "$129.9",
                    planName = stringResource(Res.string.galactic)
                )

                // Spacer(modifier = Modifier.weight(1f))

            }
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier
                    .align(Alignment.BottomCenter)
                    .fillMaxWidth()
                    .padding(bottom = 10.dp, top = 30.dp)
            ) {
                FilledButton(
                    onClick = {
                        continueToWorkspace()
                    },
                    text = stringResource(Res.string.choose_plan),
                    isEnabled = true
                )
                Text(
                    text = stringResource(Res.string.day_free_trial),
                    color = Color.Gray,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(top = 8.dp)
                )
            }
        }
    } else {
        Spacer(modifier = Modifier.height(60.dp))

        Column(
            modifier = Modifier
                .fillMaxHeight()
                .sizeIn(minWidth = 1200.dp, maxWidth = 2400.dp)
                .verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth(),
                contentAlignment = Alignment.Center,
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .align(Alignment.Center)
                        .padding(30.dp)
                        .background(Color.Transparent),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(bottom = 70.dp),
                        contentAlignment = Alignment.TopStart
                    ) {
                        Image(
                            alignment = Alignment.TopStart,
                            painter = painterResource(Res.drawable.logo_good_job),
                            contentDescription = null
                        )
                    }

                    Column(
                        modifier = Modifier
                            .fillMaxWidth(),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        // Pricing Section Title
                        androidx.compose.material.Text(
                            text = stringResource(Res.string.choose_the_plan),
                            fontSize = 50.sp,
                            lineHeight = 50.sp,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                            modifier = Modifier.padding(vertical = 16.dp)
                        )
                        androidx.compose.material.Text(
                            text = stringResource(Res.string.from_small_teams),
                            fontSize = 16.sp,
                            color = Color.Gray,
                            textAlign = TextAlign.Center
                        )

                        // Spacer for additional separation
                        Spacer(modifier = Modifier.height(20.dp))

                        // Pricing Cards Container
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(16.dp)
                                .shadow(8.dp, shape = RoundedCornerShape(26.dp))
                                .clip(RoundedCornerShape(26.dp))
                                .background(Color.White),
                        ) {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(16.dp),
                                horizontalArrangement = Arrangement.SpaceEvenly
                            ) {
                                PricingOption(
                                    price = stringResource(Res.string.freebie_price),
                                    planName = stringResource(Res.string.freebie),
                                    description = stringResource(Res.string.freebie_description),
                                    features = listOf(
                                        stringResource(Res.string.freebie_feature_1),
                                        stringResource(Res.string.freebie_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    continueToRegister = {
                                        continueToWorkspace()
                                    }
                                )

                                PricingOption(
                                    price = stringResource(Res.string.high_five_price),
                                    planName = stringResource(Res.string.high_five),
                                    description = stringResource(Res.string.high_five_description),
                                    features = listOf(
                                        stringResource(Res.string.high_five_feature_1),
                                        stringResource(Res.string.high_five_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    continueToRegister = {
                                        continueToWorkspace()
                                    }
                                )

                                // Highlighted Pricing Option
                                PricingOption(
                                    price = stringResource(Res.string.fly_high_price),
                                    planName = stringResource(Res.string.fly_high),
                                    description = stringResource(Res.string.fly_high_description),
                                    features = listOf(
                                        stringResource(Res.string.fly_high_feature_1),
                                        stringResource(Res.string.fly_high_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    isPopular = true,
                                    continueToRegister = {
                                        continueToWorkspace()
                                    }
                                )

                                PricingOption(
                                    price = stringResource(Res.string.galactic_price),
                                    planName = stringResource(Res.string.galactic),
                                    description = stringResource(Res.string.galactic_description),
                                    features = listOf(
                                        stringResource(Res.string.galactic_feature_1),
                                        stringResource(Res.string.galactic_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    continueToRegister = {
                                        continueToWorkspace()
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}