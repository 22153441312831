package com.hyperether.goodjob.scenes.employees

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.UserRequest
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging

class EmployeesViewModel(
    val repository: Repository
) : ViewModel() {

    val log = logging("EmployeesViewModel")

    private val _employees_remote = MutableStateFlow<List<User>>(emptyList())
    val employees_remote: StateFlow<List<User>> = _employees_remote
    val isDetailsView: MutableState<Boolean> = mutableStateOf(true)
    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val showFilterDialog: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")

    /*filter states*/
    val selectedRoles = mutableStateOf(setOf<String>())
    var selectedCities = mutableStateOf(setOf<String>())
    var selectedStatus = mutableStateOf(setOf<String>())
    var selectedTeams = mutableStateOf(setOf<String>())
    var selectedSkills = mutableStateOf(setOf<String>())

    val selectedEmployees = mutableStateListOf<User>()

    private val _users = MutableStateFlow<List<User>>(emptyList())
    val users: StateFlow<List<User>> = _users
    private val _teams = MutableStateFlow<List<Team>>(emptyList())
    val teams: StateFlow<List<Team>> = _teams
    val teamsRemote = mutableStateListOf<Team>()


    var roleMap: Map<String, String> = mutableMapOf()

    val employees = mutableStateListOf<User>()

    val filteredEmployees = mutableStateListOf<User>()

    init {
        if (getPlatformChecker().isMobile()) {
            log.d("EmployeesViewModel") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            init()
        } else {
            getAllUsersRemote()
        }
    }

    fun init() {
        viewModelScope.launch {
            repository.getUsersFlow().collect {
                _users.value = it
            }
        }
        viewModelScope.launch {
            repository.getTeamsFlow().collect {
                _teams.value = it
                teamsRemote.clear()
                teamsRemote.addAll(it.distinctBy { it._id })
            }
        }
    }

    fun getTeams() {
        viewModelScope.launch {
            repository.getTeams()
        }
    }

    fun getAllUsersRemote() {
        viewModelScope.launch {
            when (val result = repository.getUsers()) {
                is Resource.Success -> {
                    result.data?.let { _employees_remote.value = it }
                    employees.clear()
                    result.data?.let { employees.addAll(it) }
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    fun deleteSelectedUsers(
        selectedEmployees: List<User>,
        onAllDeleted: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            try {
                selectedEmployees.forEachIndexed { index, job ->
                    val isLastJob = index == selectedEmployees.size - 1

                    job._id?.let { id ->
                        val result = repository.deleteUser(id)
                        if (result is Resource.Success && isLastJob) {
                            onAllDeleted()
                        } else if (result is Resource.Error) {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError(id)
                            return@launch
                        }
                    }
                }
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }

    fun getUser(): User? {
        return repository.getUser()
    }

    fun deleteSelectedTeams(
        selectedTeams: List<Team>,
        onAllDeleted: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            try {
                selectedTeams.forEachIndexed { index, team ->
                    val isLastTeam = index == selectedTeams.size - 1

                    team._id?.let { id ->
                        val result = repository.deleteTeamById(id)
                        if (result is Resource.Success) {
                            teamsRemote.removeAll{it._id == id}
                            onAllDeleted()
                        } else if (result is Resource.Error) {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError(id)
                            return@launch
                        }
                    }
                }
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }

    fun addEmployee(user: UserRequest, onSuccess: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch {
            val result = repository.addUser(user)

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                    println("User added successfully")
                }

                is Resource.Error -> {
                    onError("Error while adding user")
                    println("Error while adding user")
                }
            }
        }
    }

    fun deleteEmployeesById(id: String) {
        viewModelScope.launch {
            showLoader.value = true
            val result = repository.deleteUser(id)
            when (result) {
                is Resource.Success -> {
                    showPopUpSuccess.value = true
                    showLoader.value = false
                    getAllUsersRemote()
                    println("Employee deleted successfully")
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showPopUpError.value = true
                    showLoader.value = false
                    println("Error while deleting employee")
                }
            }
        }
    }

    fun createTeam(name: String) {
        viewModelScope.launch {
            when (val result = repository.createTeam(name)) {
                is Resource.Success -> {
                        val newTeam = result
                        newTeam?.let {
                            it.data?.let { it1 -> teamsRemote.add(it1) }
                        }

                    println(result.data)
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    //showPopUpError.value = true
                }
            }
        }
    }

    fun filterContacts(employees: List<User>): List<User> {
        selectedEmployees.clear()
        if (selectedCities.value.isNullOrEmpty() &&
            selectedStatus.value.isNullOrEmpty() &&
            selectedRoles.value.isNullOrEmpty() &&
            selectedSkills.value.isNullOrEmpty() &&
            selectedTeams.value.isNullOrEmpty()
        ) {
            selectedEmployees.addAll(employees.toSet())
        } else {
            selectedEmployees.addAll(employees.filter { user ->
                selectedCities.value.any { query ->
                    user.city?.contains(query, ignoreCase = true) == true
                } || selectedRoles.value.any { query ->
                    user.role?.contains(query, ignoreCase = true) == true
                } || selectedStatus.value.any { query ->
                    user.status?.contains(query, ignoreCase = true) == true
                } || selectedTeams.value.any { query ->
                    user.teams?.any {
                        it?.value?.contains(
                            query,
                            ignoreCase = true
                        ) == true
                    } == true
                } || selectedSkills.value.any { query ->
                    user.skills?.any { it.contains(query, ignoreCase = true) } == true
                }
            })
        }

        return selectedEmployees
    }

    fun clearFilterValues() {
        selectedStatus.value = setOf()
        selectedTeams.value = setOf()
        selectedSkills.value = setOf()
        selectedRoles.value = setOf()
        selectedCities.value = setOf()
    }
}