package com.hyperether.goodjob.repository.prefs

class PrefsManager(val prefs: Prefs) {

    companion object {
        const val TOKEN_KEY = "token_key"
        const val REFRESH_TOKEN_KEY = "token_key"
        const val EMAIL = "email"
        const val PASSWORD = "password"
    }

    fun saveToken(token: String) {
        prefs.putString(TOKEN_KEY, token)
    }

    fun getToken(): String {
        return prefs.getString(TOKEN_KEY)
    }

    fun saveRefreshToken(token: String) {
        prefs.putString(REFRESH_TOKEN_KEY, token)
    }

    fun getRefreshToken(): String {
        return prefs.getString(REFRESH_TOKEN_KEY)
    }

    fun saveEmail(email: String) {
        prefs.putString(EMAIL, email)
    }

    fun getEmail(): String {
        return prefs.getString(EMAIL)
    }

    fun savePassword(password: String) {
        prefs.putString(PASSWORD, password)
    }

    fun getPassword(): String {
        return prefs.getString(PASSWORD)
    }
}