package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class User(
    @SerialName("id") var id: Long? = 0,
    @SerialName("_id") var _id: String? = null,
    @SerialName("fullName") var fullName: String? = null,
    @SerialName("email") var email: String? = null,
    @SerialName("password") var password: String? = null,
    @SerialName("confirmStatus") var confirmStatus: String? = null,
    @SerialName("role") var role: String? = null,
    @SerialName("hash") var hash: String? = null,
    @SerialName("type") var type: String? = null,
    @SerialName("workspace_ids") var workspace_ids: List<String>? = null
)