package com.hyperether.goodjob.scenes.landing

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.hyperether.goodjob.containerSize
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.LandingPageBg

@Composable
fun LandingPage(
    isMobileView: Boolean,
    pricingViewModel: PricingViewModel,
    continueToRegister: () -> Unit
) {

    var enteredEmail: MutableState<String> = mutableStateOf("")
    val plans = if (getPlatformChecker().isMobile()) {
        pricingViewModel.plans.collectAsState(initial = emptyList()).value
    } else {
        pricingViewModel.plans_remote.collectAsState(initial = emptyList()).value.sortedBy { it.price }
    }

    LaunchedEffect(plans) {
        if (plans.isNotEmpty()) {
            val popularPlan = plans.find { it.isPopular == true }
            pricingViewModel.selectedPlan.value = popularPlan?.name
            pricingViewModel.selectedPlanId.value = popularPlan?._id
            pricingViewModel.showLoader.value = false
        }
    }

    LaunchedEffect(isMobileView) {
        pricingViewModel.getAllPlans()
    }

    if (pricingViewModel.showLoader.value) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(LandingPageBg),
            contentAlignment = Alignment.Center
        ) {
            FullScreenProgressBar()
        }
    } else {
        if (!isMobileView) {
            LandingPageWebView(
                containerSize = containerSize,
                priceBoxSize = pricingViewModel.priceBoxSize,
                plans = plans,
                enteredEmail = enteredEmail,
                savePlanLocally = {  },
                continueToRegister = continueToRegister,
                selectedOption = pricingViewModel.selectedOption
            )
        } else {
            LandingPageMobileView(
                selectedOption = pricingViewModel.selectedOption,
                containerSize = containerSize,
                plans = plans,
                enteredEmail = enteredEmail,
                savePlanLocally = {  },
                continueToRegister = continueToRegister
            )
        }
    }
}