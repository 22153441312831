package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.CompletionReportStatus
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.job_finished
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.util.Constants.COMPLETED_STATUS
import org.jetbrains.compose.resources.stringResource

@Composable
fun CompletionReportOverview(
    viewModel: JobDetailsViewModel,
    navHostController: NavHostController,
    job: Job
) {
    LaunchedEffect(job) {
        val completionReportResponse = viewModel.completionReport.value
        if (completionReportResponse?.files?.isNotEmpty() == true) {
            completionReportResponse.files?.let {
                for (fileId in it)
                    viewModel.downloadCompletionReportFile(fileId,
                        onDownloaded = { painter ->
                            viewModel.addImagePainterCompletionReport(fileId to painter)
                        })
            }
        }
        if (completionReportResponse?.clientSignature?.isNotEmpty() == true) {
            completionReportResponse.clientSignature.let { clientSignature ->
                viewModel.downloadCompletionReportFile(clientSignature,
                    onDownloaded = { painter ->
                        viewModel.addImagePainterClientSignature(clientSignature to painter)
                    })
            }
        }
    }
    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        CompletionReportOverviewView(
            viewModel = viewModel,
            viewModel.completionReport.value,
            viewModel.isEmployee(),
            jobFinished = job.status == COMPLETED_STATUS,
            onApproveClick = {
                viewModel.showLoader.value = true
                job.id?.let {
                    viewModel.finishJob(it, JobStatus.completed.name.lowercase(), onSuccess = {
                        viewModel.showLoader.value = false
                        viewModel.showFinishedJobSuccess.value = true
                    }, onError = {
                        viewModel.showLoader.value = false
                        viewModel.showPopUpError.value = true
                    })
                }
            },
            onRejectClick = {
                viewModel.showLoader.value = true
                job.id?.let {
                    viewModel.finishJob(it, CompletionReportStatus.rejected.name, onSuccess = {
                        viewModel.showLoader.value = false
                        viewModel.showRejectedJobSuccess.value = true
                    }, onError = {
                        viewModel.showLoader.value = false
                        viewModel.showPopUpError.value = true
                    })
                }
            })
        if (viewModel.showPopUpSuccess.value) {
            PopUpDialog(
                onDismiss = {
                    viewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                },
                title = stringResource(Res.string.success).uppercase(),
                message = stringResource(Res.string.job_finished),
                onPositiveBtnClicked = {
                    viewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                },
                positiveBtnText = stringResource(Res.string.ok)
            )
        }
        if (viewModel.showPopUpError.value) {
            ErrorDialog(
                onDismiss = { viewModel.showPopUpError.value = false },
                title = stringResource(Res.string.error),
                message = viewModel.errorText.value
            )
        }
        if (viewModel.showLoader.value) {
            FullScreenProgressBar()
        }
    }
}