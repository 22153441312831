package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Checkbox
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.ContactType
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_contact
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.contact_added_successfully
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_person
import com.hyperether.goodjob.resources.contact_type
import com.hyperether.goodjob.resources.contact_type_contractor
import com.hyperether.goodjob.resources.contact_type_customer
import com.hyperether.goodjob.resources.contact_type_supplier
import com.hyperether.goodjob.resources.contact_updated_successfully
import com.hyperether.goodjob.resources.country
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.enter_contact_name
import com.hyperether.goodjob.resources.enter_email_address
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.industry
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.photo_upload_area
import com.hyperether.goodjob.resources.select_industry
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.tap_to_upload_photo
import com.hyperether.goodjob.resources.update
import com.hyperether.goodjob.resources.update_contact
import com.hyperether.goodjob.resources.use_contact_name
import com.hyperether.goodjob.resources.zip_employee
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeInputField
import com.hyperether.goodjob.scenes.components.AddressInputField
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.ZipInputField
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_CONTACT_ID
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewContact(
    contactId: String?,
    viewModel: AddContactViewModel,
    navHostController: NavHostController,
    isMobileView: Boolean
) {
    viewModel.contactTypeMap = mapOf(
        stringResource(Res.string.contact_type_customer) to ContactType.customer.name,
        stringResource(Res.string.contact_type_supplier) to ContactType.supplier.name,
        stringResource(Res.string.contact_type_contractor) to ContactType.contractor.name
    )

    val contact by viewModel.selectedContactToEdit.collectAsState()

    LaunchedEffect(contactId) {
        when (contactId) {
            null -> viewModel.clearContactValues()
            DEFAULT_CONTACT_ID -> viewModel.clearContactValues()
            "" -> viewModel.clearContactValues()
            else -> viewModel.getContactById(contactId)
        }
    }

    LaunchedEffect(Unit) {
        viewModel.getSites()
    }

    LaunchedEffect(contact) {
        if (contactId != DEFAULT_CONTACT_ID && contactId != "") {
            contact.let {
                if (it != null) {
                    viewModel.contactNameNewContact.value =
                        it.name ?: viewModel.contactNameNewContact.value
                    viewModel.contactType.value =
                        viewModel.contactTypeMap.entries.find { item -> item.value == it.contactType }?.key
                            ?: viewModel.contactType.value
                    viewModel.industry.value = it.industry ?: viewModel.industry.value
                    viewModel.contactCity.value =
                        it.addressObject?.city ?: viewModel.contactCity.value
                    viewModel.contactCountry.value =
                        it.addressObject?.country ?: viewModel.contactCountry.value
                    viewModel.contactAddress.value =
                        it.addressObject?.address ?: viewModel.contactAddress.value
                    viewModel.contactZip.value = it.addressObject?.zip ?: viewModel.contactZip.value
                    viewModel.phoneNewContact.value = it.phone ?: viewModel.phoneNewContact.value
                    viewModel.emailNewContact.value = it.email ?: viewModel.emailNewContact.value
                    viewModel.contactPersonName.value =
                        it.personName ?: viewModel.contactPersonName.value
                    viewModel.contactPersonEmail.value =
                        it.personEmail ?: viewModel.contactPersonEmail.value
                    viewModel.contactPersonPhone.value =
                        it.personPhone ?: viewModel.contactPersonPhone.value
                }
            }
        } else {
            viewModel.clearContactValues()
        }
    }

    if (isMobileView) {
        Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
            MobileHeaderBackAndAction(title = if (contactId == DEFAULT_CONTACT_ID || contactId == "") stringResource(
                Res.string.add_new_contact
            ) else stringResource(
                Res.string.update_contact
            ), back = {
                navHostController.popBackStack()
                viewModel.clearContactValues()
            })

            Spacer(modifier = Modifier.height(15.dp))

            Image(
                painter = painterResource(Res.drawable.photo_upload_area),
                contentDescription = "Avatar",
                modifier = Modifier.size(80.dp).clip(CircleShape).background(BlueApp)
                    .align(Alignment.CenterHorizontally)
            )

            Text(
                text = stringResource(Res.string.tap_to_upload_photo),
                fontSize = 14.sp,
                color = TextColor,
                textAlign = TextAlign.Center,
                fontStyle = FontStyle.Italic,
                modifier = Modifier.align(Alignment.CenterHorizontally)
            )

            Column(modifier = Modifier.padding(horizontal = 16.dp)) {
                AppInputField(
                    viewModel.contactNameNewContact,
                    stringResource(Res.string.contact_name),
                    placeholder = stringResource(Res.string.enter_contact_name)
                )
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.offset(x = (-12).dp).padding(top = 5.dp)
                ) {
                    Checkbox(
                        checked = viewModel.isCheckedContactPerson.value, onCheckedChange = {
                            viewModel.isCheckedContactPerson.value = it
                        }, modifier = Modifier.clip(RoundedCornerShape(50))
                    )
                    Spacer(modifier = Modifier.width(5.dp))
                    Text(
                        text = stringResource(Res.string.use_contact_name),
                        color = TextColor,
                        fontSize = 12.sp
                    )
                }
                AddEmployeeInputField(
                    viewModel.contactType,
                    stringResource(Res.string.contact_type),
                    placeholder = "",
                    dropdownItems = viewModel.contactTypeMap,
                    keyboardInputEnabled = false
                )
                AddEmployeeInputField(
                    viewModel.industry,
                    stringResource(Res.string.industry),
                    placeholder = stringResource(Res.string.select_industry),
                    dropdownItems = mapOf(),
                    keyboardInputEnabled = true
                )

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    AppInputField(
                        viewModel.contactCity,
                        stringResource(Res.string.city),
                        placeholder = stringResource(Res.string.city),
                        modifier = Modifier.weight(1f)
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    AppInputField(
                        viewModel.contactCountry,
                        stringResource(Res.string.country),
                        placeholder = stringResource(Res.string.country),
                        modifier = Modifier.weight(1f)
                    )
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    AddressInputField(
                        textCountry = viewModel.contactCountry,
                        textCity = viewModel.contactCity,
                        text = viewModel.contactAddress,
                        label = stringResource(Res.string.address),
                        placeholder = stringResource(Res.string.enter_street),
                        modifier = Modifier.weight(3f),
                        onSearch = { viewModel.findPlace(it) },
                        onSelect = {
                            viewModel.contactAddress.value = it.formattedAddress
                            viewModel.place = it
                        },
                        places = viewModel.places
                    )

                    Spacer(modifier = Modifier.width(8.dp))
                    ZipInputField(
                        viewModel.contactZip,
                        stringResource(Res.string.zip_employee),
                        placeholder = stringResource(Res.string.zip_employee),
                        modifier = Modifier.weight(1f)
                    )
                }
                PhoneInputField(
                    viewModel.phoneNewContact,
                    stringResource(Res.string.phone),
                    placeholder = "+134232525235"
                )
                AppInputField(
                    viewModel.emailNewContact,
                    stringResource(Res.string.email_address),
                    placeholder = stringResource(Res.string.enter_email_address),
                    enabled = contactId == DEFAULT_CONTACT_ID || contactId == ""
                )
                Spacer(modifier = Modifier.height(15.dp))

                if (viewModel.isCheckedContactPerson.value) {
                    Text(
                        text = stringResource(Res.string.contact_person),
                        fontSize = 18.sp,
                        color = TextColor,
                        fontWeight = FontWeight.SemiBold,
                        textAlign = TextAlign.Center
                    )
                    AppInputField(
                        viewModel.contactPersonName,
                        stringResource(Res.string.contact_name),
                        placeholder = stringResource(Res.string.enter_contact_name)
                    )
                    AppInputField(
                        viewModel.contactPersonEmail,
                        stringResource(Res.string.email_address),
                        placeholder = stringResource(Res.string.enter_email_address),
                    )
                    PhoneInputField(
                        viewModel.contactPersonPhone,
                        stringResource(Res.string.phone),
                        placeholder = "+123456789"
                    )
                }
                Spacer(modifier = Modifier.height(25.dp))

                FilledButton(
                    onClick = {
                        viewModel.showLoader.value = true
                        if (contactId == DEFAULT_CONTACT_ID || contactId == "") {
                            viewModel.addContact(onSuccess = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpSuccess.value = true
                            }, onError = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpError.value = true
                            })
                        } else {
                            viewModel.updateContact(onSuccess = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpSuccess.value = true
                            }, onError = {
                                viewModel.showLoader.value = false
                                viewModel.showPopUpError.value = true
                            })
                        }
                    },
                    text = if (contactId == DEFAULT_CONTACT_ID || contactId == "") stringResource(
                        Res.string.add
                    ) else stringResource(
                        Res.string.update
                    ),
                    isEnabled = true
                )
                Spacer(modifier = Modifier.height(25.dp))
                if (viewModel.showPopUpSuccess.value) {
                    PopUpDialog(onDismiss = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                        title = stringResource(Res.string.success).uppercase(),
                        message = if (contactId == DEFAULT_CONTACT_ID || contactId == "") stringResource(
                            Res.string.contact_added_successfully
                        ) else stringResource(
                            Res.string.contact_updated_successfully
                        ),
                        positiveBtnText = stringResource(Res.string.ok),
                        onPositiveBtnClicked = {
                            viewModel.showPopUpSuccess.value = false
                            navHostController.popBackStack()
                        })
                }
                if (viewModel.showPopUpError.value) {
                    ErrorDialog(
                        onDismiss = { viewModel.showPopUpError.value = false },
                        title = stringResource(Res.string.error).uppercase(),
                        message = viewModel.errorText.value
                    )
                }
                if (viewModel.showLoader.value) {
                    FullScreenProgressBar()
                }
            }
        }
    } else {
        AddNewContactWebScreen(navHostController, viewModel, contactId)
    }
}