package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ConfirmationResponse(
    @SerialName("_id") val id: String?,
    @SerialName("fullName") val fullName: String?,
    @SerialName("email") val email: String?,
    @SerialName("password") val password: String?,
    @SerialName("confirmStatus") val confirmStatus: String?,
    @SerialName("hash") val hash: String?,
    @SerialName("type") val type: String?,
    @SerialName("createdAt") val createdAt: Long?,
    @SerialName("updatedAt") val updatedAt: Long?,
)