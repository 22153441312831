package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.PaintingStyle
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_more
import com.hyperether.goodjob.resources.additional_notes
import com.hyperether.goodjob.resources.baseline_file_present_24
import com.hyperether.goodjob.resources.client_feedback
import com.hyperether.goodjob.resources.client_signature
import com.hyperether.goodjob.resources.comment
import com.hyperether.goodjob.resources.damage_desc
import com.hyperether.goodjob.resources.damage_title
import com.hyperether.goodjob.resources.enter_feedback
import com.hyperether.goodjob.resources.feedback
import com.hyperether.goodjob.resources.final_inspection
import com.hyperether.goodjob.resources.final_inspection_desc
import com.hyperether.goodjob.resources.final_inspection_fail
import com.hyperether.goodjob.resources.max_size
import com.hyperether.goodjob.resources.no
import com.hyperether.goodjob.resources.no_damage
import com.hyperether.goodjob.resources.safety_requirements
import com.hyperether.goodjob.resources.safety_requirements_desc
import com.hyperether.goodjob.resources.safety_requirements_title
import com.hyperether.goodjob.resources.submit
import com.hyperether.goodjob.resources.trash
import com.hyperether.goodjob.resources.try_another_document
import com.hyperether.goodjob.resources.upload_cloud
import com.hyperether.goodjob.resources.upload_documents
import com.hyperether.goodjob.resources.upload_signature
import com.hyperether.goodjob.resources.was_damage_caused
import com.hyperether.goodjob.resources.yes
import com.hyperether.goodjob.resources.your_comment
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.util.Constants
import com.hyperether.goodjob.util.UploadManager
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun CompletionReportSubmitView(
    viewModel: JobDetailsViewModel,
    submit: () -> Unit,
    images: SnapshotStateList<String>,
    onUploadDocumentClick: (Boolean) -> Unit
) {
    val pathEffectLine =
        PathEffect.dashPathEffect(floatArrayOf(10f, 10f), phase = 0f)
    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .align(Alignment.BottomCenter),
            verticalArrangement = Arrangement.spacedBy(16.dp)
        ) {
            // Title
            Text(
                text = "Upload photos of the completed job",
                style = MaterialTheme.typography.h6,
                fontSize = 16.sp,
                modifier = Modifier.padding(bottom = 8.dp)
            )

            // Images Row
            if (images.isEmpty()) {
                Box(
                    modifier = Modifier.fillMaxWidth().drawBehind {
                        val paint = Paint().apply {
                            color = BlueApp
                            style = PaintingStyle.Stroke
                            strokeWidth = 2.dp.toPx()
                            pathEffect = pathEffectLine
                        }

                        drawIntoCanvas { canvas ->
                            canvas.drawRoundRect(
                                0f,
                                0f,
                                size.width,
                                size.height,
                                radiusX = 20f,
                                radiusY = 20f,
                                paint
                            )
                        }
                    }.padding(24.dp),
                    contentAlignment = Alignment.Center
                ) {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier.clickable {
                            onUploadDocumentClick(false)
                        }) {
                        if (viewModel.documentStatus.value == "") {
                            Image(
                                painter = painterResource(Res.drawable.upload_cloud),
                                contentDescription = "",
                                modifier = Modifier.size(40.dp)
                            )

                            Spacer(modifier = Modifier.height(8.dp))

                            Text(
                                text = stringResource(Res.string.upload_documents),
                                color = Color.Black,
                                fontWeight = FontWeight.Bold
                            )

                            Spacer(modifier = Modifier.height(4.dp))

                            Text(
                                text = stringResource(Res.string.max_size),
                                color = Color.LightGray
                            )
                        } else if (viewModel.documentStatus.value == Constants.DOCUMENT_LOADING) {
                            viewModel.showDocumentLoader.value = true
                            if (viewModel.showDocumentLoader.value) {
                                CircularProgressIndicator(
                                    modifier = Modifier
                                        .size(25.dp)
                                        .padding(end = 5.dp),
                                    color = BlueApp,
                                    strokeWidth = 2.dp
                                )
                            }
                        } else if (viewModel.documentStatus.value == Constants.DOCUMENT_UPLOAD_ERROR) {
                            viewModel.showDocumentLoader.value = false
                            Text(
                                text = stringResource(Res.string.try_another_document),
                                color = Color.Gray,
                                fontWeight = FontWeight.Normal
                            )
                        } else {
                            viewModel.showDocumentLoader.value = false
                            Image(
                                painter = painterResource(Res.drawable.baseline_file_present_24),
                                contentDescription = "",
                                modifier = Modifier.padding(top = 24.dp).size(40.dp)
                            )
                            Column(
                                modifier = Modifier
                                    .padding(horizontal = 10.dp)
                            ) {
                                for (fileName in viewModel.fileIds.value) {
                                    Row(
                                        horizontalArrangement = Arrangement.SpaceBetween,
                                        verticalAlignment = Alignment.CenterVertically,
                                        modifier = Modifier.fillMaxWidth()
                                    ) {
                                        Text(
                                            text = fileName ?: "",
                                            color = DarkGrey,
                                            fontWeight = FontWeight.Normal,
                                            style = MaterialTheme.typography.body1,
                                            maxLines = 1,
                                            overflow = TextOverflow.Ellipsis,
                                            modifier = Modifier
                                                .weight(1f)
                                                .padding(end = 8.dp)
                                        )

                                        Image(
                                            painter = painterResource(Res.drawable.trash),
                                            contentDescription = null,
                                            modifier = Modifier
                                                .size(20.dp)
                                                .clickable {
                                                    fileName?.let { viewModel.deleteUploadedFile(it) }
                                                }
                                        )
                                    }
                                    Spacer(modifier = Modifier.height(10.dp))
                                }
                            }
                            Spacer(modifier = Modifier.height(12.dp))
                            Text(
                                text = stringResource(Res.string.add_more),
                                color = BlueApp,
                                fontWeight = FontWeight.Bold,
                                fontSize = 18.sp,
                                modifier = Modifier
                                    .padding(bottom = 24.dp)
                                    .clickable {
                                        onUploadDocumentClick(false)
                                    }
                            )
                        }
                    }
                }
            } else {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.spacedBy(16.dp)
                ) {
                    viewModel.filesList.let {
                        it.forEach { fileId ->
                            ImageCard(fileId)
                        }
                    }
                }
            }

            // Status Check Section
            CheckBoxMultipleItemBoldTitle(
                question = stringResource(Res.string.was_damage_caused),
                answerList = arrayListOf(
                    stringResource(Res.string.no_damage),
                    stringResource(Res.string.yes)
                ),
                checkedList = arrayListOf(
                    !viewModel.damageState.value,
                    viewModel.damageState.value
                ),
                onSelectedAnswer = { answerIndex ->
                    when (answerIndex) {
                        0 -> {
                            viewModel.damageState.value = false
                        }

                        1 -> {
                            viewModel.damageState.value = true
                        }
                    }
                }
            )
            if (viewModel.damageState.value == true) {
                AppInputField(
                    viewModel.damageTextState,
                    stringResource(Res.string.damage_title),
                    stringResource(Res.string.damage_desc)
                )
            }

            CheckBoxMultipleItemBoldTitle(
                question = stringResource(Res.string.final_inspection),
                answerList = arrayListOf(
                    stringResource(Res.string.yes),
                    stringResource(Res.string.no)
                ),
                checkedList = arrayListOf(
                    viewModel.correctFunctionalityState.value,
                    !viewModel.correctFunctionalityState.value
                ),
                onSelectedAnswer = { answerIndex ->
                    when (answerIndex) {
                        0 -> {
                            viewModel.correctFunctionalityState.value = true
                        }

                        1 -> {
                            viewModel.correctFunctionalityState.value = false
                        }
                    }
                }
            )
            if (viewModel.correctFunctionalityState.value == false) {
                AppInputField(
                    viewModel.correctFunctionalityTextState,
                    stringResource(Res.string.final_inspection_fail),
                    stringResource(Res.string.final_inspection_desc)
                )
            }

            // Client Feedback Section
            Text(
                stringResource(Res.string.client_feedback),
                style = MaterialTheme.typography.h6,
                fontSize = 16.sp
            )

            AppInputField(
                viewModel.clientFeedbackText,
                stringResource(Res.string.feedback),
                stringResource(Res.string.enter_feedback)
            )

            Text(
                stringResource(Res.string.client_signature),
                style = MaterialTheme.typography.h6,
                fontSize = 16.sp
            )
            // Placeholder for Signature
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(100.dp)
                    .border(1.dp, Color.Gray)
                    .clickable {
                        onUploadDocumentClick(true)
                    },
                contentAlignment = Alignment.Center
            ) {
                if (UploadManager.signatureStatus.value == "") {
                    Text(
                        text = stringResource(Res.string.upload_signature),
                        modifier = Modifier.align(Alignment.Center),
                        style = MaterialTheme.typography.caption,
                        color = Color.Gray,
                        textAlign = TextAlign.Center
                    )
                } else if (UploadManager.signatureStatus.value == Constants.DOCUMENT_LOADING) {
                    viewModel.showSignatureLoader.value = true
                    if (viewModel.showSignatureLoader.value) {
                        CircularProgressIndicator(
                            modifier = Modifier
                                .size(25.dp)
                                .padding(end = 5.dp),
                            color = BlueApp,
                            strokeWidth = 2.dp
                        )
                    }
                } else if (UploadManager.signatureStatus.value == Constants.DOCUMENT_UPLOAD_ERROR) {
                    viewModel.showSignatureLoader.value = false
                    Text(
                        text = stringResource(Res.string.try_another_document),
                        color = Color.Gray,
                        fontWeight = FontWeight.Normal
                    )
                } else {
                    viewModel.showSignatureLoader.value = false
                    Column(
                        modifier = Modifier.align(Alignment.Center),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.baseline_file_present_24),
                            contentDescription = "",
                            modifier = Modifier.size(40.dp)
                        )
                        Text(
                            text = viewModel.clientSignature.value,
                            color = BlueApp,
                            fontSize = 12.sp,
                            fontWeight = FontWeight.Normal,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis,
                            textAlign = TextAlign.Center,
                            modifier = Modifier.padding(start = 40.dp, end = 40.dp)
                        )
                    }
                }
            }

            Text(
                stringResource(Res.string.your_comment),
                style = MaterialTheme.typography.h6,
                fontSize = 16.sp
            )
            // Additional Notes Section
            AppInputField(
                viewModel.additionalNotesText,
                stringResource(Res.string.comment),
                stringResource(Res.string.additional_notes)
            )

            // Health and Safety Confirmation Section
            CheckBoxMultipleItemBoldTitle(
                question = stringResource(Res.string.safety_requirements),
                answerList = arrayListOf(
                    stringResource(Res.string.yes),
                    stringResource(Res.string.no)
                ),
                checkedList = arrayListOf(
                    viewModel.healthAndSafetyState.value,
                    !viewModel.healthAndSafetyState.value
                ),
                onSelectedAnswer = { answerIndex ->
                    when (answerIndex) {
                        0 -> {
                            viewModel.healthAndSafetyState.value = true
                        }

                        1 -> {
                            viewModel.healthAndSafetyState.value = false
                        }
                    }
                }
            )
            if (viewModel.healthAndSafetyState.value == false) {
                AppInputField(
                    viewModel.healthAndSafetyTextState,
                    stringResource(Res.string.safety_requirements_title),
                    stringResource(Res.string.safety_requirements_desc)
                )
            }

            FilledButton(
                onClick = { submit() },
                modifier = Modifier.fillMaxWidth(),
                text = stringResource(Res.string.submit)
            )
        }
    }
}