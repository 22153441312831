package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoginResponse(
    @SerialName("id") var id: Long? = 0,
    @SerialName("_id") var _id: String? = null,
    @SerialName("fullName") val fullName: String?,
    @SerialName("email") val email: String?,
    @SerialName("confirmStatus") val confirmStatus: String?,
    @SerialName("hash") var hash: String? = null,
    @SerialName("type") val type: String?,
    @SerialName("createdAt") val createdAt: Long?,
    @SerialName("updatedAt") val updatedAt: Long?,
    @SerialName("token") val token: String,
    @SerialName("refreshToken") val refreshToken: String,
)