package com.hyperether.goodjob.models

import com.hyperether.goodjob.database.Contact
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Contact(
    @SerialName("_id") var id: String? = null,
    @SerialName("name") var name: String? = null,
    @SerialName("contactType") var contactType: String? = null,
    @SerialName("type") var type: String? = null,
    @SerialName("industry") var industry: String? = null,
    @SerialName("address") var address: String? = null,
    @SerialName("email") var email: String? = null,
    @SerialName("city") var city: String? = null,
    @SerialName("zip") var zip: Int? = null,
    @SerialName("country") var country: String? = null,
    @SerialName("phone") var phone: String? = null,
    @SerialName("personName") var personName: String? = null,
    @SerialName("personEmail") var personEmail: String? = null,
    @SerialName("personAddress") var personAddress: String? = null,
    @SerialName("personPhone") var personPhone: String? = null,
    @SerialName("createdAt") var createdAt: Long? = null,
    @SerialName("updatedAt") var updatedAt: Long? = null
){
    fun toContactDb(contactDb: Contact): com.hyperether.goodjob.database.Contact {
        return com.hyperether.goodjob.database.Contact (
            _id = this.id,
            id = contactDb.id,
            name = this.name,
            contactType = this.contactType,
            type = this.type,
            industry = this.industry,
            address = this.address,
            email = this.email,
            city = this.city,
            zip = this.zip?.toLong(),
            country = this.country,
            phone = this.phone,
            personName = this.personName,
            personEmail = this.personEmail,
            personAddress = this.personAddress,
            personPhone = this.personPhone
        )
    }
}
