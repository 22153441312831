package com.hyperether.goodjob.database

import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import com.hyperether.goodjob.models.Workspace
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow

class WorkspaceDaoImpl(private val sharedDatabase: SharedDatabase) : WorkspaceDao {

    override suspend fun deleteAllWorkspaces() {
        sharedDatabase { database ->
            database.appDatabaseQueries.deleteAllWorkspaces()
        }
    }

    override suspend fun insertWorkspace(workspace: Workspace) {
        sharedDatabase { database ->
            database.appDatabaseQueries.transaction {
                database.appDatabaseQueries.insertWorkspace(
                    _id = workspace._id,
                    name = workspace.name,
                    type = workspace.type,
                    plan = workspace.planId,
                    user_id = workspace.userId,
                    workspaceType = workspace.workspaceType,
                    lastAccessed = workspace.lastAccessed
                )
            }
        }
    }

    override suspend fun updateWorkspaceById(workspace: Workspace) {
        sharedDatabase { database ->
            database.appDatabaseQueries.transaction {
                database.appDatabaseQueries.updateWorkspaceByID(
                    id = workspace._id,
                    _id = workspace._id,
                    name = workspace.name,
                    type = workspace.type,
                    plan = workspace.planId,
                    user_id = workspace.userId,
                    workspaceType = workspace.workspaceType,
                    lastAccessed = workspace.lastAccessed
                )
            }
        }
    }

    override suspend fun getWorkspacesFlow(): Flow<List<com.hyperether.goodjob.database.Workspace>> {
        return sharedDatabase { database ->
            database.appDatabaseQueries
                .getWorkspaces()
                .asFlow()
                .mapToList(Dispatchers.Default)
        }
    }

    override suspend fun getWorkspaceById(id: String): com.hyperether.goodjob.database.Workspace? {
        return sharedDatabase { database ->
            database.appDatabaseQueries.getWorkspaceById(id).awaitAsOneOrNull()
        }
    }

    override suspend fun deleteWorkspaceById(id: Long) {
        sharedDatabase { database ->
            database.appDatabaseQueries.deleteWorkspaceById(id)
        }
    }
}