package com.hyperether.goodjob.scenes.components

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.hyperether.goodjob.models.User

@Composable
fun CircleWithInitialUser(user: User?, size: Dp = 32.dp, modifier: Modifier = Modifier) {
    val initial = user?.getName().orEmpty()
    CircleWithInitial(initial, size, modifier)
}
