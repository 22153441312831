package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.Serializable

@Serializable
data class PlacesResponse(
    val places: List<Place>
)

@Serializable
data class Place(
    val formattedAddress: String,
    val location: LatLng
)

@Serializable
data class LatLng(
    val latitude: Double,
    val longitude: Double
)