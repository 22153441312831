package com.hyperether.goodjob.scenes.register

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.ForgotPasswordRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

class RegisterViewModel(
    val repository: Repository,
) : ViewModel() {

    val errorText: MutableState<String> = mutableStateOf("")
    val emailInput: MutableState<String> = mutableStateOf("")
    val showSendResetLinkMessage: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)

    fun register(
        name: String,
        email: String,
        password: String,
        onSuccess: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch(Dispatchers.Default) {

            when (val result =
                repository.register(name, email, password)) {
                is Resource.Success -> {
                    repository.saveEmail(email)
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError(result.text ?: "An unknown error occurred")
                }

                else -> {
                    onError("Unexpected error occurred")
                }
            }
        }
    }

    fun forgotPassword() {
        viewModelScope.launch {
            when (val result = repository.forgotPassword(ForgotPasswordRequest(emailInput.value))) {
                is Resource.Success -> {
                    println(result)
                    showSendResetLinkMessage.value = true
                    repository.saveEmail(emailInput.value)
                }

                is Resource.Error -> {
                    showPopUpError.value = true
                    errorText.value = result.text ?: "An unknown error occurred"
                    println(result)
                }
            }
        }
    }

    suspend fun getAllUsers() {
        val result = repository.getUsers()

        when (result) {
            is Resource.Success -> {
                println(result)
            }

            is Resource.Error -> {
                println(result)
            }
        }
    }

    fun login(email: String, password: String, onSuccess: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch(Dispatchers.Main) {

            when (val result =
                repository.login(email, password)) {
                is Resource.Success -> {
                    // TODO check for place for this method
                    //getAllContacts()
                    //getAllUsers()
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError(result.text ?: "An unknown error occurred")
                }

                else -> {
                    onError("Unexpected error occurred")
                }
            }
        }
    }

    fun getUser(): User? {
        return repository.getUser()
    }

    fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

    fun isManager(): Boolean {
        return repository.getUser()?.role.equals(Role.manager.name, ignoreCase = true)
    }

    fun isSuperAdmin(): Boolean {
        return repository.getUser()?.role.equals(Role.superAdmin.name, ignoreCase = true)
    }
}