package com.hyperether.goodjob.models

import com.hyperether.goodjob.models.Contact.ZipSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AddressObject(
    @SerialName("city") var city: String? = null,
    @SerialName("zip") @Serializable(with = ZipSerializer::class) var zip: String? = null,
    @SerialName("address") var address: String? = null,
    @SerialName("country") var country: String? = null,
    @SerialName("long") var long: Double? = null,
    @SerialName("lat") var lat: Double? = null,
)