package com.hyperether.goodjob.models

import com.hyperether.goodjob.repository.remote.model.ContactRequest
import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable
data class Contact(
    @SerialName("_id") var id: String? = null,
    @SerialName("name") var name: String? = null,
    @SerialName("contactType") var contactType: String? = null,
    @SerialName("industry") var industry: String? = null,
    @SerialName("addressObject") var addressObject: AddressObject? = null,
    @SerialName("email") var email: String? = null,
    @SerialName("phone") var phone: String? = null,
    @SerialName("personName") var personName: String? = null,
    @SerialName("personEmail") var personEmail: String? = null,
    @SerialName("personAddress") var personAddress: String? = null,
    @SerialName("personPhone") var personPhone: String? = null,
    @SerialName("createdAt") var createdAt: Long? = null,
    @SerialName("workspaceId") var workspaceId: String? = null,
    @SerialName("updatedAt") var updatedAt: Long? = null
) {

    object ZipSerializer : KSerializer<String?> {
        override val descriptor: SerialDescriptor =
            PrimitiveSerialDescriptor("Zip", PrimitiveKind.STRING)

        override fun serialize(encoder: Encoder, value: String?) {
            encoder.encodeString(value ?: "")
        }

        override fun deserialize(decoder: Decoder): String? {
            return try {
                decoder.decodeString()
            } catch (e: SerializationException) {
                decoder.decodeInt().toString()
            }
        }
    }

    fun toContactRequest(): ContactRequest {
        return ContactRequest(
            name = this.name,
            contactType = this.contactType,
            industry = this.industry,
            addressObject = this.addressObject,
            email = this.email,
            phone = this.phone,
            personName = this.personName,
            personEmail = this.personEmail,
            personAddress = this.personAddress,
            personPhone = this.personPhone,
            createdAt = this.createdAt,
            workspaceId = this.workspaceId,
            updatedAt = this.updatedAt
        )
    }
}
