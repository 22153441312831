package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.focus.FocusManager
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.UserTeam
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.avatar_female
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.choose_team
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.email_capital
import com.hyperether.goodjob.resources.enter_full_name
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.ic_active_card
import com.hyperether.goodjob.resources.ic_calendar
import com.hyperether.goodjob.resources.ic_leave
import com.hyperether.goodjob.resources.ic_mail
import com.hyperether.goodjob.resources.ic_map_pin
import com.hyperether.goodjob.resources.ic_members_card
import com.hyperether.goodjob.resources.ic_skills
import com.hyperether.goodjob.resources.ic_teams_card
import com.hyperether.goodjob.resources.ic_user
import com.hyperether.goodjob.resources.ic_users
import com.hyperether.goodjob.resources.ic_work_scheduler
import com.hyperether.goodjob.resources.name
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.phone_number
import com.hyperether.goodjob.resources.select_dates
import com.hyperether.goodjob.resources.set_weekly_hours
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.start_typing_address
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.upcoming_leaves
import com.hyperether.goodjob.resources.work_schedule
import com.hyperether.goodjob.scenes.addNew.CustomDropdownMenu
import com.hyperether.goodjob.scenes.addNew.CustomInputRowEmployee
import com.hyperether.goodjob.scenes.addNew.CustomNumberInputRowEmployee
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.EmployeeDetailsText
import com.hyperether.goodjob.theme.EmployeesBorder
import com.hyperether.goodjob.theme.FooterText
import com.hyperether.goodjob.theme.HeaderText
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.UnselectedPage
import com.hyperether.goodjob.util.UserStatusHelper
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun StatsSection(teams: Int, employeesNumber: Int) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dp(124f))
            .padding(horizontal = Dp(75f))
            .background(Color.White)
            .border(
                width = Dp(1f),
                color = EmployeesBorder,
                shape = RoundedCornerShape(Dp(4f))
            ),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        StatCard(
            title = "Teams",
            value = teams.toString(),
            change = "+6 this month",
            resource = Res.drawable.ic_teams_card
        )
        VerticalDivider()
        StatCard(
            title = "Members",
            value = employeesNumber.toString(),
            change = "-1 this month",
            resource = Res.drawable.ic_members_card
        )
        VerticalDivider()
        StatCard(
            title = "Active this Month",
            value = "120",
            change = "",
            resource = Res.drawable.ic_active_card
        )
    }
}

@Composable
fun StatCard(title: String, value: String, change: String, resource: DrawableResource) {
    Column(
        modifier = Modifier
            .background(Color.White, shape = RoundedCornerShape(Dp(8f)))
            .padding(horizontal = Dp(56f)),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.padding(vertical = Dp(16f))
        ) {
            Icon(
                modifier = Modifier.height(Dp(69f)).width(Dp(69f)),
                painter = painterResource(resource),
                contentDescription = null,
                tint = BlueApp
            )
            Spacer(modifier = Modifier.width(Dp(32f)))
            Column {
                Text(
                    text = title,
                    fontSize = Sp(16f),
                    color = Color.Gray,
                    fontWeight = FontWeight.W400
                )
                Spacer(modifier = Modifier.height(Dp(8f)))
                Text(text = value, fontSize = Sp(32f), fontWeight = FontWeight.W700)
                // TODO: add CHANGE when api is ready
//                if (change.isNotEmpty()) {
//                    Text(
//                        text = change,
//                        fontSize = 16.sp,
//                        color = if (change.startsWith("+")) Color.Green else Color.Red
//                    )
//                }
            }
        }
    }
}

@Composable
fun VerticalDivider() {
    Box(
        modifier = Modifier
            .fillMaxHeight(0.8f)
            .width(Dp(1f))
            .padding(top = Dp(20f))
            .background(EmployeesBorder)
    )
}

@Composable
fun <T> Pagination(
    list: List<T>,
    onPageChange: (Int) -> Unit,
    currentPage: Int,
    itemsPerPage: Int = 6
) {
    val totalPages =
        (list.size + itemsPerPage - 1) / itemsPerPage // Calculate total pages

    // Pagination Logic Adjustment for Small Lists
    val visiblePages = mutableListOf<Int>()
    if (totalPages <= 3) {
        for (page in 1..totalPages) visiblePages.add(page) // Show 1, 1 2, or 1 2 3
    } else {
        // Use the dynamic logic for larger lists
        when {
            // Start (first few pages)
            currentPage <= 3 -> {
                visiblePages.addAll(1..3)
                visiblePages.add(-1) // Ellipsis at 4th place
                visiblePages.add(totalPages)
            }

            // Middle Pages
            currentPage in 4..(totalPages - 3) -> {
                visiblePages.add(currentPage - 1)
                visiblePages.add(currentPage)
                visiblePages.add(currentPage + 1)
                visiblePages.add(-1) // Ellipsis at 4th place
                visiblePages.add(totalPages)
            }

            // Near the End
            else -> {
                visiblePages.add(1)
                visiblePages.add(-1) // Ellipsis at 2nd place
                visiblePages.addAll((totalPages - 2)..totalPages)
            }
        }
    }

    val startIndex = (currentPage - 1) * itemsPerPage + 1
    val endIndex = (currentPage * itemsPerPage).coerceAtMost(list.size)


    // Render Pagination
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = Dp(17f), bottom = Dp(32f), start = Dp(40f), end = Dp(40f)),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            fontSize = Sp(14f),
            text = "$startIndex - $endIndex of ${list.size}",
            color = FooterText,
            modifier = Modifier.align(Alignment.CenterVertically)
        )
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.End,
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Previous Page Button
            Text(
                modifier = Modifier
                    .align(Alignment.CenterVertically)
                    .size(Dp(24f))
                    .clip(RoundedCornerShape(Dp(4f)))
                    .background(UnselectedPage)
                    .clickable(enabled = currentPage > 1) { onPageChange(currentPage - 1) },
                fontSize = Sp(12f),
                text = "<",
                color = Color.Gray,
                fontWeight = FontWeight.W500,
                textAlign = TextAlign.Center
            )

            Spacer(modifier = Modifier.width(Dp(12f)))

            visiblePages.distinct().forEachIndexed { index, page ->
                if (page == -1) {
                    // Ellipsis
                    Text(
                        modifier = Modifier
                            .align(Alignment.CenterVertically)
                            .size(Dp(24f))
                            .clip(RoundedCornerShape(Dp(4f))),
                        text = "...",
                        fontSize = Sp(12f),
                        color = Color.Gray,
                        fontWeight = FontWeight.Normal,
                        textAlign = TextAlign.Center
                    )
                } else {
                    val isSelected = currentPage == page
                    Text(
                        modifier = Modifier
                            .size(Dp(24f))
                            .clip(RoundedCornerShape(Dp(4f)))
                            .background(if (isSelected) BlueApp else UnselectedPage)
                            .clickable { onPageChange(page) },
                        fontSize = Sp(12f),
                        text = page.toString(),
                        color = if (isSelected) Color.White else Color.Black,
                        fontWeight = FontWeight.W500,
                        textAlign = TextAlign.Center
                    )
                }
                Spacer(modifier = Modifier.width(Dp(12f)))
            }

            // Next Page Button
            Text(
                modifier = Modifier
                    .align(Alignment.CenterVertically)
                    .height(Dp(24f))
                    .width(Dp(27f))
                    .clip(RoundedCornerShape(Dp(4f)))
                    .background(UnselectedPage)
                    .clickable(enabled = currentPage < totalPages) {
                        onPageChange(currentPage + 1)
                    },
                text = ">",
                fontSize = Sp(12f),
                color = Color.Gray,
                fontWeight = FontWeight.W500,
                textAlign = TextAlign.Center
            )
        }
    }
}


@Composable
fun EmployeeRowHeader(
    allSelected: Boolean,
    onSelectAllChange: (Boolean) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .background(Color.White)
            .drawBehind {
                val strokeWidth = Dp(1f).toPx()
                drawLine(
                    color = com.hyperether.goodjob.theme.Divider,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(horizontal = Dp(40f)),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Checkbox(
            checked = allSelected,
            onCheckedChange = onSelectAllChange,
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = com.hyperether.goodjob.theme.Divider
            )
        )
        Text(
            text = stringResource(Res.string.name),
            modifier = Modifier.width(Dp(123f)),
            fontSize = Sp(14f),
            color = HeaderText
        )
        Spacer(modifier = Modifier.width(Dp(40f)))
        Text(
            text = stringResource(Res.string.team),
            modifier = Modifier.width(Dp(113f)),
            fontSize = Sp(14f),
            color = HeaderText
        )
        Spacer(modifier = Modifier.width(Dp(40f)))
        Text(
            text = stringResource(Res.string.phone_number),
            modifier = Modifier.width(Dp(115f)),
            fontSize = Sp(14f),
            color = HeaderText
        )
        Spacer(modifier = Modifier.width(Dp(40f)))
        Text(
            text = stringResource(Res.string.email),
            modifier = Modifier.width(Dp(177f)),
            fontSize = Sp(14f),
            color = HeaderText
        )
        Spacer(modifier = Modifier.width(Dp(40f)))
        Text(
            text = stringResource(Res.string.city),
            modifier = Modifier.width(Dp(98f)),
            fontSize = Sp(14f),
            color = HeaderText
        )
        Spacer(modifier = Modifier.width(Dp(40f)))
        Text(
            text = stringResource(Res.string.status),
            modifier = Modifier.width(Dp(94f)),
            fontSize = Sp(14f),
            color = HeaderText
        )
    }
}

@Composable
fun EmployeeRow(
    employee: User,
    isSelected: Boolean,
    onSelectionChange: (Boolean) -> Unit,
    modifier: Modifier
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .background(Color.White)
            .padding(horizontal = Dp(40f))
            .drawBehind {
                val strokeWidth = Dp(1f).toPx()
                drawLine(
                    color = com.hyperether.goodjob.theme.Divider,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(vertical = Dp(16f)),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Checkbox(
            checked = isSelected,
            onCheckedChange = onSelectionChange,
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = com.hyperether.goodjob.theme.Divider
            )
        )
        Text(
            text = employee.getName(),
            modifier = Modifier.width(Dp(123f)),
            fontSize = Sp(14f),
            color = BlueApp,
            fontWeight = FontWeight.Bold
        )
        Spacer(modifier = Modifier.width(Dp(30f)))
        val teams = employee.teams
        if (teams?.isNotEmpty() == true) {
            for (team in teams) {
                Text(
                    text = team?.value ?: " ",
                    modifier = Modifier.width(Dp(113f)),
                    fontSize = Sp(14f),
                )
            }
        }
        Spacer(modifier = Modifier.width(Dp(30f)))
        Text(
            text = employee.phone ?: " ",
            modifier = Modifier.width(Dp(115f)),
            fontSize = Sp(14f),
        )
        Spacer(modifier = Modifier.width(Dp(30f)))
        Text(
            text = employee.email ?: " ",
            modifier = Modifier.width(Dp(185f)),
            fontSize = Sp(14f),
        )
        Spacer(modifier = Modifier.width(Dp(20f)))
        Text(
            text = employee.city ?: " ",
            modifier = Modifier.width(Dp(98f)),
            fontSize = Sp(14f),
        )
        // Outlined status text
        Spacer(modifier = Modifier.width(Dp(30f)))
        Box(
            modifier = Modifier
                .height(Dp(25f))
                .width(Dp(94f))
                .border(
                    width = Dp(1f),
                    color = UserStatusHelper.getStatusColor(confirmStatus = employee.confirmStatus, status = employee.status),
                    shape = RoundedCornerShape(Dp(4f))
                ),
            contentAlignment = Alignment.Center
        ) {
            Text(
                text = UserStatusHelper.getStatusText(confirmStatus = employee.confirmStatus, status = employee.status),
                color = UserStatusHelper.getStatusColor(confirmStatus = employee.confirmStatus, status = employee.status),
                fontSize = Sp(14f)
            )
        }
    }
}

@Composable
fun StatusRowEmployee(
    user: User,
    modifier: Modifier = Modifier,
    labelColor: Color = EmployeeDetailsText
) {
    Row(
        modifier = modifier
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            text = stringResource(Res.string.status),
            color = labelColor,
            fontSize = Sp(14f),
        )
        Text(
            modifier = Modifier
                .background(
                    shape = RoundedCornerShape(Dp(10f)),
                    color = UserStatusHelper.getStatusColor(confirmStatus = user.confirmStatus, status = user.status)
                )
                .padding(horizontal = Dp(13f), vertical = Dp(3f)),
            text = UserStatusHelper.getStatusText(confirmStatus = user.confirmStatus, status = user.status),
            color = Color.White,
            fontSize = Sp(14f),
            lineHeight = Sp(18f),
            fontWeight = FontWeight.W500,
            textAlign = TextAlign.Center
        )
    }
}

@Composable
fun CustomDisplayRowEmployee(
    painterResource: DrawableResource,
    labelText: String,
    displayText: String,
    modifier: Modifier = Modifier,
    textColor: Color,
    labelColor: Color = EmployeeDetailsText
) {
    Row(
        modifier = modifier
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        // Icon and Label
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                modifier = Modifier.size(Dp(20f)),
                painter = painterResource(painterResource),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = labelColor,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }
        // Display Text
        Box(
            contentAlignment = Alignment.TopEnd,
            modifier = Modifier
                .weight(2f)
        ) {
            Text(
                text = displayText,
                color = textColor,
                fontSize = Sp(14f),
                fontWeight = FontWeight.Bold
            )
        }
    }
}

@Composable
fun ProfilePictureSection(
    isEditView: MutableState<Boolean>,
    btnText: String,
    onUploadClick: () -> Unit
) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
    ) {
        Box(
            modifier = Modifier
                .padding(10.dp),
            contentAlignment = Alignment.Center
        ) {
            Image(
                painterResource(Res.drawable.avatar_female),
                contentDescription = null
            )
        }
        Spacer(modifier = Modifier.height(10.dp))
        if (isEditView.value) {
            OutlinedButton(
                border = BorderStroke(1.dp, BlueApp),
                shape = RoundedCornerShape(4.dp),
                onClick = {
                    onUploadClick()
                }) {
                Text(
                    text = btnText,
                    color = BlueApp,
                    fontSize = 14.sp
                )
            }
        }
    }
}

@Composable
fun WorkSchedulerInput(
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_work_scheduler),
                contentDescription = null,
            )
            Text(
                text = stringResource(Res.string.work_schedule),
                color = EmployeeDetailsText,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }
        Row(
            modifier = Modifier
                .weight(2f)
                .padding(end = 16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = stringResource(Res.string.set_weekly_hours),
                color = BlueApp,
                fontSize = 14.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier
                    .fillMaxWidth()
                    .clickable { /* TODO: */ }
            )
        }
    }
}

@Composable
fun UpcomingLeave() {
    var expanded = remember { mutableStateOf(false) }

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = Dp(8f)),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_leave),
                contentDescription = null
            )
            Text(
                text = stringResource(Res.string.upcoming_leaves),
                color = EmployeeDetailsText,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }

        Box(
            modifier = Modifier
                .weight(2f)
                .border(
                    width = Dp(1f),
                    color = RegisterBorder,
                    shape = RoundedCornerShape(Dp(8f))
                )
                .clickable { expanded.value = true }
        ) {
            Text(
                text = stringResource(Res.string.select_dates),
                color = EmployeeDetailsText,
                fontSize = Sp(14f),
                modifier = Modifier.padding(Dp(12f))
            )
            Icon(
                painter = painterResource(Res.drawable.ic_calendar),
                contentDescription = null,
                tint = Color(0xFF676767),
                modifier = Modifier
                    .align(Alignment.CenterEnd)
                    .padding(end = Dp(20f))
            )

            // TODO: add calendar picker
        }
    }
}

@Composable
fun SaveOrCancelBtnVertical(
    navHostController: NavHostController,
    isEditView: MutableState<Boolean>,
    updateEmployee: (() -> Unit)? = null,
    addEmployee: (() -> Unit)? = null
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 16.dp) // Add some space before buttons
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            verticalArrangement = Arrangement.spacedBy(16.dp) // Space between buttons
        ) {
            Button(
                onClick = {
                    if (isEditView.value) {
                        updateEmployee?.invoke()
                    } else {
                        addEmployee?.invoke()
                    }
                },
                modifier = Modifier
                    .fillMaxWidth(),
                shape = RoundedCornerShape(56.dp),
                colors = ButtonDefaults.buttonColors(BlueApp),
            ) {
                Text(
                    modifier = Modifier.padding(vertical = 8.dp),
                    text = "Save Changes",
                    color = Color.White,
                )
            }
            androidx.compose.material.OutlinedButton(
                onClick = {
                    isEditView.value = !isEditView.value
                    if (isEditView.value)
                        navHostController.popBackStack()
                },
                border = BorderStroke(1.dp, EmployeeDetailsText),
                modifier = Modifier
                    .fillMaxWidth(),
                shape = RoundedCornerShape(25.dp),
                colors = ButtonDefaults.outlinedButtonColors(
                    backgroundColor = Color.White,
                    contentColor = Color.Black
                ),
            ) {
                Text(
                    modifier = Modifier.padding(vertical = 8.dp),
                    text = stringResource(Res.string.cancel),
                    color = Color.Black,
                )
            }
        }
    }
}

@Composable
fun EmployeeEditView(
    navHostController: NavHostController,
    isEditView: MutableState<Boolean>,
    modifier: Modifier,
    focusRequester: FocusRequester,
    fullNameInput: MutableState<String>,
    addressInput: MutableState<String>,
    phoneInput: MutableState<String>,
    emailInput: MutableState<String>,
    selectedTeam: MutableState<UserTeam?>,
    selectedSkill: MutableState<String?>,
    focusManager: FocusManager,
    updateEmployee: (() -> Unit)? = null,
    addEmployee: (() -> Unit)? = null
) {
    // TODO: get Teams
    val itemsTeams = listOf(
        UserTeam(id = "1", value = "Sales Team"),
        UserTeam(id = "2", value = "Support Team"),
        UserTeam(id = "3", value = "Technical Team")
    )
    val itemsSkills = listOf("Skill1", "Skill2")

    Column(
        modifier = modifier,
    ) {
        LaunchedEffect(true) {
            focusRequester.requestFocus()
        }
        CustomInputRowEmployee(
            painterResource = Res.drawable.ic_user,
            labelText = stringResource(Res.string.full_name),
            placeholderText = stringResource(Res.string.enter_full_name),
            inputValue = fullNameInput.value,
            onValueChange = {
                fullNameInput.value = ""
                fullNameInput.value = it
            },
            focusManager = focusManager,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(24f)))
        CustomInputRowEmployee(
            painterResource = Res.drawable.ic_map_pin,
            labelText = stringResource(Res.string.address),
            placeholderText = stringResource(Res.string.start_typing_address),
            inputValue = addressInput.value,
            onValueChange = {
                addressInput.value = ""
                addressInput.value = it
            },
            focusManager = focusManager,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(24f)))
        CustomNumberInputRowEmployee(
            painterResource = Res.drawable.phone,
            labelText = stringResource(Res.string.phone),
            placeholderText = "+1 555 123 4567",
            inputValue = phoneInput.value,
            onValueChange = {
                phoneInput.value = ""
                phoneInput.value = it
            },
            focusManager = focusManager,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(24f)))
        CustomInputRowEmployee(
            painterResource = Res.drawable.ic_mail,
            labelText = stringResource(Res.string.email_capital),
            placeholderText = stringResource(Res.string.enter_your_email),
            inputValue = emailInput.value,
            onValueChange = {
                emailInput.value = ""
                emailInput.value = it
            },
            focusManager = focusManager,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        Divider(
            modifier = Modifier.height(1.dp)
                .background(Color.LightGray.copy(alpha = 0.1f))
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        val chooseTeam = stringResource(Res.string.choose_team)
        CustomDropdownMenu(
            iconRes = Res.drawable.ic_users,
            labelText = stringResource(Res.string.teams),
            items = itemsTeams,
            selectedValue = selectedTeam.value
                ?: UserTeam(),
            onValueSelected = {
                selectedTeam.value = it
            },
            displayText = { team -> team.value ?: chooseTeam },
            focusManager = focusManager,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        val skills = stringResource(Res.string.skills)
        CustomDropdownMenu(
            iconRes = Res.drawable.ic_skills,
            labelText = stringResource(Res.string.skills),
            items = itemsSkills,
            selectedValue = selectedSkill.value
                ?: skills,
            onValueSelected = {
                selectedSkill.value = it
            },
            displayText = { it },
            focusManager = focusManager,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        WorkSchedulerInput()
        Spacer(modifier = Modifier.height(Dp(16f)))
        UpcomingLeave()
        Spacer(modifier = Modifier.height(Dp(32f)))
        SaveOrCancelBtnVertical(
            navHostController = navHostController,
            isEditView,
            addEmployee = addEmployee,
            updateEmployee = updateEmployee
        )
    }
}

@Composable
fun EmployeeDetailsWebView(
    user: User,
    focusRequester: FocusRequester,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    modifier: Modifier
) {
    Column(
        modifier = modifier,
    ) {
        LaunchedEffect(true) {
            focusRequester.requestFocus()
        }
        StatusRowEmployee(
            user = user,
            modifier = Modifier
        )
        Spacer(modifier = Modifier.height(Dp(24f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.ic_user,
            labelText = stringResource(Res.string.full_name),
            displayText = employeeDetailsViewModel.fullNameInput.value,
            textColor = Color.Black,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(24f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.ic_map_pin,
            labelText = stringResource(Res.string.address),
            displayText = employeeDetailsViewModel.addressInput.value,
            textColor = BlueApp,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(24f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.phone,
            labelText = stringResource(Res.string.phone),
            displayText = employeeDetailsViewModel.phoneInput.value,
            textColor = Color.Black,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(24f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.ic_mail,
            labelText = stringResource(Res.string.email_capital),
            displayText = employeeDetailsViewModel.emailInput.value,
            textColor = Color.Black,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        Divider(
            modifier = Modifier.height(1.dp)
                .background(Color.LightGray.copy(alpha = 0.1f))
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.ic_users,
            labelText = stringResource(Res.string.teams),
            displayText = employeeDetailsViewModel.selectedTeam.value?.value
                ?: "",
            textColor = Color.Black,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.ic_skills,
            labelText = stringResource(Res.string.skills),
            displayText = employeeDetailsViewModel.selectedSkill.value ?: "",
            textColor = Color.Black,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.ic_work_scheduler,
            labelText = stringResource(Res.string.work_schedule),
            displayText = "", // TODO
            textColor = Color.Black,
            modifier = Modifier.focusRequester(focusRequester)
        )
        Spacer(modifier = Modifier.height(Dp(16f)))
        CustomDisplayRowEmployee(
            painterResource = Res.drawable.ic_leave,
            labelText = stringResource(Res.string.upcoming_leaves),
            displayText = employeeDetailsViewModel.upcomingLeaves.value,
            textColor = Color.Black,
            modifier = Modifier.focusRequester(focusRequester)
        )
    }

}