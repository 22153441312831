package com.hyperether.goodjob.repository.remote

import com.hyperether.goodjob.log
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.model.RefreshTokenResponse
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.HttpSend
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.plugins.plugin
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json

object ClientProvider {

    var prefsManager: PrefsManager? = null

    val client: HttpClient by lazy {
        HttpClient {
            install(ContentNegotiation) {
                json(Json {
                    prettyPrint = true
                    isLenient = true
                    ignoreUnknownKeys = true
                })
            }

            install(Logging) {
                logger = object : io.ktor.client.plugins.logging.Logger {
                    override fun log(message: String) {
                        println(message)
                    }
                }
                level = LogLevel.ALL
            }

        }
    }

    fun initialize(prefsManager: PrefsManager, logout: () -> Unit) {
        this.prefsManager = prefsManager
        client.plugin(HttpSend).intercept { request ->
            if (!request.url.pathSegments.contains("refresh-token")) {
                val originalCall = execute(request)
                println("Original request: ${originalCall.request.content}")
                println("Original response: ${originalCall.response}")
                if (originalCall.response.status.value == 403) {
                    val token = refreshToken(logout)
                    request.headers["x-access-token"] = token
                    val req = execute(request)
                    req
                } else {
                    originalCall
                }
            } else {
                execute(request)
            }
        }
    }

    suspend fun refreshToken(logout: () -> Unit): String {
        val response = try {
            client.post("${ApiConfig.baseURL}/api/v1/user/refresh-token") {
                headers {
                    //todo check this when backend is ready
                    append("x-refresh-access-token", prefsManager?.getRefreshToken().toString())
                    contentType(ContentType.Application.Json)
                }
            }
        } catch (e: Exception) {
            println("refresh error $e")
            logout()
            null
        }
        if (response?.status == HttpStatusCode.OK) {
            val apiResponse: RefreshTokenResponse = response.body()
            log.d("Token refreshed") { "New token received" }
            prefsManager?.saveToken(apiResponse.token ?: "")
            prefsManager?.saveRefreshToken(apiResponse.refreshToken ?: "")
            return apiResponse?.token ?: ""
        } else {
            logout()
            return ""
        }

    }
}