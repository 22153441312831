package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Text
import androidx.compose.material.TextFieldDefaults
import androidx.compose.material3.Checkbox
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.em
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.map.MapComponent
import com.hyperether.goodjob.map.Marker
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.remote.model.LatLng
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.about_this_contact
import com.hyperether.goodjob.resources.add_job
import com.hyperether.goodjob.resources.add_new
import com.hyperether.goodjob.resources.add_new_job
import com.hyperether.goodjob.resources.add_note
import com.hyperether.goodjob.resources.add_site
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.briefcase
import com.hyperether.goodjob.resources.briefcase_unselect
import com.hyperether.goodjob.resources.building
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.contact_deteils
import com.hyperether.goodjob.resources.contact_information
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_name_and_type
import com.hyperether.goodjob.resources.contact_person
import com.hyperether.goodjob.resources.contact_type
import com.hyperether.goodjob.resources.contacts_share
import com.hyperether.goodjob.resources.country
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.email_capital
import com.hyperether.goodjob.resources.email_ic
import com.hyperether.goodjob.resources.email_send_icon
import com.hyperether.goodjob.resources.employee
import com.hyperether.goodjob.resources.end_time
import com.hyperether.goodjob.resources.files_notes
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.handshake_thin_svgrepo_com_1
import com.hyperether.goodjob.resources.ic_map_pin
import com.hyperether.goodjob.resources.ic_search
import com.hyperether.goodjob.resources.ic_user
import com.hyperether.goodjob.resources.industry
import com.hyperether.goodjob.resources.job_title
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.location
import com.hyperether.goodjob.resources.mail
import com.hyperether.goodjob.resources.notes
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.search
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.resources.site_contact_person
import com.hyperether.goodjob.resources.sites
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.steps
import com.hyperether.goodjob.resources.time
import com.hyperether.goodjob.resources.title
import com.hyperether.goodjob.resources.user
import com.hyperether.goodjob.resources.world_2_svgrepo_com_1
import com.hyperether.goodjob.resources.zip
import com.hyperether.goodjob.scenes.addNew.DropdownSortBy
import com.hyperether.goodjob.scenes.components.JobListGroupedByDate
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BlueBackground
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants.DEFAULT_CONTACT_ID
import com.hyperether.goodjob.util.Constants.DEFAULT_SITE_ID
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ContactDetailsWeb(
    navHostController: NavHostController, viewModel: ContactsViewModel, contactId: String
) {

    var selectedIndex by remember { mutableStateOf(0) }
    val searchQuery = remember { mutableStateOf("") }
    val contact = viewModel.remoteContact.value

    LaunchedEffect(contactId) {
        viewModel.init()
        viewModel.fetchContactById(contactId)
    }
    LaunchedEffect(contact) {
        viewModel.getSitesUnderContact(SiteRequest(contactId))
    }

    Column(
        modifier = Modifier.fillMaxSize().background(DashboardBg)
    ) {
        WebViewHeader(
            isAddNewJob = false,
            isBackAvailable = false,
            isAddNewContact = true,
            title = contact?.name ?: "",
            isImportAndAddVisible = true,
            navHostController = navHostController
        )
        Spacer(modifier = Modifier.height(Dp(15f)))
        Box(
            modifier = Modifier.fillMaxSize().background(color = Color(0xfffcfcfc)).verticalScroll(
                rememberScrollState()
            ).padding(start = Dp(75f), end = Dp(75f), top = Dp(15f))
        ) {

            Box(
                modifier = Modifier
                    //.align(alignment = Alignment.TopCenter)
                    .fillMaxWidth().requiredHeight(height = Dp(773f))
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth().requiredHeight(height = Dp(789f))
                        .background(color = Color.White)
                        .border(width = Dp(1f), color = RegisterBorder)
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.Top,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        Column(modifier = Modifier.weight(1f)) {
                            Column(modifier = Modifier.width(Dp(210f)).fillMaxHeight()
                                .padding(top = 10.dp).drawBehind {}) {
                                Box(modifier = Modifier.fillMaxWidth().height(Dp(48f))
                                    .then(if (selectedIndex == 0) Modifier.background(
                                        BlueBackground
                                    ).drawBehind {
                                        drawLine(
                                            color = BlueApp,
                                            strokeWidth = 6.dp.toPx(),
                                            start = Offset(3.dp.toPx(), 0f),
                                            end = Offset(3.dp.toPx(), size.height)
                                        )
                                    }
                                    else Modifier).clickable { selectedIndex = 0 },
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                                        textAlign = TextAlign.Start,
                                        text = stringResource(Res.string.contact_deteils),
                                        color = if (selectedIndex == 0) BlueApp else TextColor
                                    )
                                }

//                                Box(modifier = Modifier.fillMaxWidth().height(Dp(48f))
//                                    .then(if (selectedIndex == 1) Modifier.background(
//                                        BlueBackground
//                                    ).drawBehind {
//                                        drawLine(
//                                            color = BlueApp,
//                                            strokeWidth = 6.dp.toPx(),
//                                            start = Offset(3.dp.toPx(), 0f),
//                                            end = Offset(3.dp.toPx(), size.height)
//                                        )
//                                    }
//                                    else Modifier).clickable { selectedIndex = 1 },
//                                    contentAlignment = Alignment.Center
//                                ) {
//                                    Text(
//                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
//                                        textAlign = TextAlign.Start,
//                                        text = stringResource(Res.string.files_notes),
//                                        color = if (selectedIndex == 1) BlueApp else TextColor
//                                    )
//                                }

                                Box(modifier = Modifier.fillMaxWidth().height(Dp(48f))
                                    .then(if (selectedIndex == 2) Modifier.background(
                                        BlueBackground
                                    ).drawBehind {
                                        drawLine(
                                            color = BlueApp,
                                            strokeWidth = 6.dp.toPx(),
                                            start = Offset(3.dp.toPx(), 0f),
                                            end = Offset(3.dp.toPx(), size.height)
                                        )
                                    }
                                    else Modifier).clickable { selectedIndex = 2 },
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                                        textAlign = TextAlign.Start,
                                        text = stringResource(Res.string.sites),
                                        color = if (selectedIndex == 2) BlueApp else TextColor
                                    )
                                }
                                Box(modifier = Modifier.fillMaxWidth().height(Dp(48f))
                                    .then(if (selectedIndex == 3) Modifier.background(
                                        BlueBackground
                                    ).drawBehind {
                                        drawLine(
                                            color = BlueApp,
                                            strokeWidth = 6.dp.toPx(),
                                            start = Offset(3.dp.toPx(), 0f),
                                            end = Offset(3.dp.toPx(), size.height)
                                        )
                                    }
                                    else Modifier).clickable { selectedIndex = 3 },
                                    contentAlignment = Alignment.Center
                                ) {
                                    Text(
                                        modifier = Modifier.fillMaxWidth().padding(start = 20.dp),
                                        textAlign = TextAlign.Start,
                                        text = stringResource(Res.string.jobs),
                                        color = if (selectedIndex == 2) BlueApp else TextColor
                                    )

                                }

                            }
                        }

                        Divider(
                            modifier = Modifier.width(1.dp).fillMaxHeight()
                                .background(RegisterBorder)
                        )
                        Column(modifier = Modifier.fillMaxWidth().weight(5f)) {
                            Box(
                                modifier = Modifier.fillMaxWidth().requiredHeight(height = Dp(50f))
                            ) {
                                Box(
                                    modifier = Modifier.fillMaxWidth()
                                        .requiredHeight(height = Dp(50f)).clip(
                                            shape = RoundedCornerShape(
                                                topStart = Dp(4f), topEnd = Dp(4f)
                                            )
                                        ).background(color = Color.White)
                                )

                                if (selectedIndex == 0) {
                                    Row(
                                        verticalAlignment = Alignment.Top,
                                        modifier = Modifier.height(110.dp)
                                            .padding(horizontal = 23.dp)
                                    ) {
                                        Text(
                                            modifier = Modifier.weight(1f).padding(top = 15.dp),
                                            text = stringResource(Res.string.about_this_contact),
                                            fontSize = 24.sp,
                                            fontWeight = FontWeight.W700,
                                        )

                                        Spacer(modifier = Modifier.weight(1f))
                                        Row(modifier = Modifier.clickable {
                                            navHostController.navigate(Screen.AddNewContact.route + "?contactId=${contact?.id}")
                                        }) {

                                            Image(
                                                painter = painterResource(Res.drawable.edit),
                                                contentDescription = "",
                                                modifier = Modifier.padding(
                                                    end = 16.dp,
                                                    top = 10.dp
                                                )
                                            )

                                            Text(
                                                modifier = Modifier.padding(top = 10.dp),
                                                text = stringResource(Res.string.edit),
                                                fontSize = 18.sp,
                                                fontWeight = FontWeight.Normal,
                                            )
                                        }
                                    }
                                }
                            }
                            //TAB DETAILS
                            if (selectedIndex == 0) {
                                Row(
                                    modifier = Modifier.fillMaxWidth()
                                        .padding(end = 20.dp, bottom = 20.dp)
                                ) {
                                    Column(modifier = Modifier.fillMaxWidth().weight(1.5f)) {
                                        ContactDetailsRow(
                                            Res.drawable.handshake_thin_svgrepo_com_1,
                                            stringResource(Res.string.contact_type),
                                            contact?.contactType ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.ic_user,
                                            stringResource(Res.string.contact_name),
                                            contact?.name ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.email_send_icon,
                                            stringResource(Res.string.email_capital),
                                            contact?.email ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.phone,
                                            stringResource(Res.string.phone),
                                            contact?.phone ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.building,
                                            stringResource(Res.string.city),
                                            contact?.addressObject?.city ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.world_2_svgrepo_com_1,
                                            stringResource(Res.string.country),
                                            contact?.addressObject?.country ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.mail,
                                            stringResource(Res.string.zip),
                                            contact?.addressObject?.zip ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.ic_map_pin,
                                            stringResource(Res.string.address),
                                            contact?.addressObject?.address ?: "",
                                            detailsTextColor = BlueApp
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.briefcase_unselect,
                                            stringResource(Res.string.industry),
                                            contact?.industry ?: ""
                                        )
                                        Spacer(modifier = Modifier.height(10.dp))
                                        Divider(
                                            modifier = Modifier.height(1.dp)
                                                .background(Color.LightGray.copy(alpha = 0.1f))
                                                .padding(start = Dp(15f), end = Dp(15f))
                                        )
                                        Spacer(modifier = Modifier.height(10.dp))

                                        Text(
                                            modifier = Modifier.weight(2f)
                                                .padding(start = Dp(23f), top = Dp(23f)),
                                            text = stringResource(Res.string.contact_person),
                                            fontSize = Sp(20f),
                                            fontWeight = FontWeight.W700,
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.ic_user,
                                            stringResource(Res.string.contact_person),
                                            contact?.personName ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.email_send_icon,
                                            stringResource(Res.string.email),
                                            contact?.personEmail ?: ""
                                        )
                                        ContactDetailsRow(
                                            Res.drawable.phone,
                                            stringResource(Res.string.phone),
                                            contact?.personPhone ?: ""
                                        )
                                    }
                                    Box(
                                        modifier = Modifier.weight(1f).fillMaxHeight().background(
                                            Color.LightGray, shape = RoundedCornerShape(8.dp)
                                        ).border(
                                            width = 1.dp,
                                            shape = RoundedCornerShape(8.dp),
                                            color = BorderColor
                                        ).padding(top = 20.dp, bottom = 20.dp, end = 20.dp)
                                    ) {
                                        MapComponent(
                                            listOf(
                                                Marker(
                                                    contact?.addressObject?.lat ?: 0.0,
                                                    contact?.addressObject?.long ?: 0.0,
                                                    "Nikola"
                                                )
                                            ), mutableStateOf(
                                                LatLng(0.0, 0.0)
                                            )
                                        )
                                    }
                                }
                            }

                            //TAB SITES
                            if (selectedIndex == 2) {
                                Row(
                                    verticalAlignment = Alignment.Top,
                                    modifier = Modifier.height(110.dp).padding(horizontal = 23.dp)
                                ) {
                                    Text(
                                        modifier = Modifier.weight(1f).padding(top = 15.dp),
                                        text = stringResource(Res.string.sites),
                                        fontSize = 24.sp,
                                        fontWeight = FontWeight.W700,
                                    )

                                    Spacer(modifier = Modifier.weight(1f))

                                    Row(
                                        horizontalArrangement = Arrangement.End,
                                        verticalAlignment = Alignment.CenterVertically,
                                        modifier = Modifier.background(
                                            color = BlueApp,
                                            shape = RoundedCornerShape(32.dp)
                                        ).padding(horizontal = 12.dp, vertical = 10.dp)
                                    ) {
                                        Text(text = stringResource(Res.string.add_site),
                                            color = Color.White,
                                            fontSize = 14.sp,
                                            fontWeight = FontWeight.W400,
                                            modifier = Modifier.clickable {
                                                if (getPlatformChecker().isMobile()) {
                                                    navHostController.navigate(Screen.AddNewSite.route + "/$contactId/$DEFAULT_SITE_ID")
                                                } else {
                                                    navHostController.navigate(Screen.AddNewSite.route + "?contactId=$contactId&siteId=")
                                                }
                                            })
                                    }

                                    DropdownSortBy(
                                        shouldAppendSortBy = false,
                                        items = listOf("Newest"),
                                        selectedValue = "Newest",
                                        onValueSelected = { },
                                        displayText = { "Sort by: $it" },
                                        placeholderColor = BorderColor,
                                        textColor = Color.Black,
                                        modifier = Modifier.height(Dp(45f)).padding(start = 10.dp)
                                    )
                                }
                                Row(
                                    modifier = Modifier.fillMaxWidth().padding(horizontal = Dp(45f))
                                        .requiredHeight(height = Dp(38f)),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Row(
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.Start,
                                        modifier = Modifier.weight(0.9f)
                                    ) {
                                        Checkbox(checked = false, onCheckedChange = { })

                                        Text(
                                            text = stringResource(Res.string.site_contact_person),
                                            color = Color(0xff797676),
                                            lineHeight = 1.5.em,
                                            fontWeight = FontWeight.W700,
                                            style = TextStyle(fontSize = Sp(14f)),
                                        )
                                    }
                                    Text(
                                        text = stringResource(Res.string.address),
                                        color = Color(0xff797676),
                                        lineHeight = 1.5.em,
                                        fontWeight = FontWeight.W700,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier.weight(1.5f)
                                    )
                                    Text(
                                        text = stringResource(Res.string.contact_information),
                                        color = Color(0xff797676),
                                        lineHeight = 1.5.em,
                                        fontWeight = FontWeight.W700,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier.weight(1.5f)
                                    )
                                }
                                Divider(
                                    modifier = Modifier.height(1.dp)
                                        .background(Color.LightGray.copy(alpha = 0.1f))
                                        .padding(start = Dp(15f), end = Dp(15f))
                                )
                                SiteListWeb(viewModel.currentSitesForContact, navHostController, onEditSiteClick = { siteId ->
                                    navHostController.navigate(Screen.AddNewSite.route + "?contactId=$contactId&siteId=$siteId")
                                })
                            }
                            //TAB JOBS
                            if (selectedIndex == 3) {
                                Row(
                                    verticalAlignment = Alignment.Top,
                                    modifier = Modifier.height(110.dp).padding(horizontal = 23.dp)
                                ) {
                                    Text(
                                        modifier = Modifier.weight(1f).padding(top = 15.dp),
                                        text = stringResource(Res.string.jobs),
                                        fontSize = 24.sp,
                                        fontWeight = FontWeight.W700,
                                    )

                                    Spacer(modifier = Modifier.weight(1f))

                                    Row(
                                        horizontalArrangement = Arrangement.End,
                                        verticalAlignment = Alignment.CenterVertically,
                                        modifier = Modifier.background(
                                            color = BlueApp,
                                            shape = RoundedCornerShape(32.dp)
                                        ).padding(horizontal = 12.dp, vertical = 10.dp)
                                    ) {
                                        Text(text = stringResource(Res.string.add_job),
                                            color = Color.White,
                                            fontSize = 14.sp,
                                            fontWeight = FontWeight.W400,
                                            modifier = Modifier.clickable {

                                            })
                                    }
                                    Spacer(modifier = Modifier.width(Dp(16f)))

                                    OutlinedTextField(
                                        textStyle = TextStyle(fontSize = Sp(12f)),
                                        value = searchQuery.value,
                                        onValueChange = { searchQuery.value = it },
                                        placeholder = {
                                            Text(
                                                text = stringResource(Res.string.search),
                                                fontSize = Sp(12f),
                                                fontWeight = FontWeight.W400,
                                                modifier = Modifier.align(Alignment.CenterVertically)
                                                    .padding(top = 20.dp)
                                            )
                                        },
                                        modifier = Modifier
                                            .width(Dp(226f))
                                            .height(Dp(45f))
                                            .border(
                                                width = Dp(1f),
                                                color = BorderColor,
                                                shape = RoundedCornerShape(Dp(8f))
                                            ),
                                        singleLine = true,
                                        leadingIcon = {
                                            Image(
                                                modifier = Modifier
                                                    .height(Dp(24f))
                                                    .width(Dp(24f)),
                                                painter = painterResource(Res.drawable.ic_search),
                                                contentDescription = "Search"
                                            )
                                        },
                                        colors = TextFieldDefaults.textFieldColors(
                                            backgroundColor = Color.White,
                                            focusedIndicatorColor = Color.Transparent,
                                            unfocusedIndicatorColor = Color.Transparent
                                        )
                                    )
                                    Spacer(modifier = Modifier.width(Dp(16f)))

                                    Row(
                                        modifier = Modifier
                                            .height(Dp(45f)).width(Dp(100f))
                                            .border(
                                                width = Dp(1f),
                                                color = BorderColor,
                                                shape = RoundedCornerShape(Dp(8f))
                                            )
                                            .clickable { viewModel.showFilterDialog.value = true },
                                        horizontalArrangement = Arrangement.Center,
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Text(
                                            text = stringResource(Res.string.filter),
                                            fontSize = Sp(14f),
                                            fontWeight = FontWeight.W400,
                                        )
                                        Image(
                                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                                            contentDescription = "Filter",
                                            modifier = Modifier.width(Dp(24f)).height(Dp(24f))
                                        )
                                    }

                                    DropdownSortBy(
                                        shouldAppendSortBy = false,
                                        items = listOf("Newest"),
                                        selectedValue = "Newest",
                                        onValueSelected = { },
                                        displayText = { "Sort by: $it" },
                                        placeholderColor = BorderColor,
                                        textColor = Color.Black,
                                        modifier = Modifier.height(Dp(45f)).padding(start = 10.dp)
                                    )
                                }
                                Row(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .background(color = Color(0xfffafafa))
                                        .padding(horizontal = Dp(45f))
                                        .requiredHeight(height = Dp(38f)),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Text(
                                        text = stringResource(Res.string.title),
                                        color = Color(0xff797676),
                                        lineHeight = 1.5.em,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier.weight(0.9f)
                                    )
                                    Text(
                                        text = stringResource(Res.string.site),
                                        color = Color(0xff797676),
                                        lineHeight = 1.5.em,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier
                                            .weight(0.9f)
                                    )
                                    Text(
                                        text = stringResource(Res.string.location),
                                        color = Color(0xff797676),
                                        lineHeight = 1.5.em,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier
                                            .weight(1f)
                                    )
                                    Text(
                                        text = stringResource(Res.string.time),
                                        color = Color(0xff797676),
                                        lineHeight = 1.5.em,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier
                                            .weight(0.7f)
                                    )
                                    Text(
                                        text = stringResource(Res.string.employee),
                                        color = Color(0xff797676),
                                        lineHeight = 1.5.em,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier
                                            .weight(0.7f)
                                    )
                                    Text(
                                        text = stringResource(Res.string.status),
                                        color = Color(0xff797676),
                                        textAlign = TextAlign.Center,
                                        lineHeight = 1.5.em,
                                        style = TextStyle(
                                            fontSize = Sp(14f)
                                        ),
                                        modifier = Modifier
                                            .weight(0.5f)
                                    )
                                }
                                val filteredJobs =
                                    viewModel.jobList.filter { it.contactParentId == contactId }
                                JobListGroupedByDate(
                                    viewModel = null,
                                    jobs = filteredJobs,
                                    navHostController = navHostController,
                                    location = contact?.addressObject?.address ?: "",
                                    searchQuery = searchQuery
                                )
                            }
                        }
                    }
                }
            }

        }
    }
}