package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EmployeeRequest(
    @SerialName("fullName") val fullName: String,
    @SerialName("email") val email: String,
    @SerialName("phone") val phone: String,
    @SerialName("zip") val zip: String,
    @SerialName("country") val country: String,
    @SerialName("city") val city: String,
    @SerialName("address") val address: String,
    @SerialName("status") val status: String,
    @SerialName("workspaceId") val workspaceId: String,
    @SerialName("skils") val skills: List<String>,
    @SerialName("teams") val teams: List<Team>
)