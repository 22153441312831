package com.hyperether.goodjob.components

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Text
import androidx.compose.material.TextField
import androidx.compose.material.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.subscribe
import com.hyperether.goodjob.theme.LightBlue
import org.jetbrains.compose.resources.stringResource

@Composable
fun InputFieldLanding(
    isMobileView: Boolean = false,
    input: MutableState<String>,
    placeHolder: String,
    modifier: Modifier = Modifier,
    keyboardOptions: KeyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
    enabled: Boolean = true
) {
    TextField(
        value = input.value,
        onValueChange = { newValue ->
            val filteredValue =
                newValue.filter { it.isLetterOrDigit() || it.isWhitespace() || it in "!@#$%^&*()-_=+[]{}|;:'\",.<>?/`~" }
            input.value = filteredValue
        },
        placeholder = {
            Text(
                placeHolder,
                color = Color.LightGray,
            )
        },
        maxLines = 1,
        textStyle = TextStyle(
            fontSize = 18.sp,
            color = LightBlue,
            textAlign = TextAlign.Start
        ),
        modifier = modifier
            .shadow(
                elevation = 2.dp,
                shape = RoundedCornerShape(60.dp),
                clip = true,
                spotColor = Color(0xFFEEF5FD)
            )
            .clip(RoundedCornerShape(60.dp))
            .fillMaxWidth(),
        enabled = enabled,
        keyboardOptions = keyboardOptions,
        colors = TextFieldDefaults.textFieldColors(
            backgroundColor = Color.White,
            focusedIndicatorColor = Color.Transparent,
            unfocusedIndicatorColor = Color.Transparent
        ),
        trailingIcon = {
            if (!isMobileView) {
                Button(
                    onClick = {},
                    modifier = Modifier
                        .padding(horizontal = 8.dp)
                        .height(50.dp)
                        .width(150.dp)
                        .clip(RoundedCornerShape(56.dp)),
                    colors = ButtonDefaults.buttonColors(Color(0xFF34618E)),
                ) {
                    Text(
                        text = stringResource(Res.string.subscribe),
                        color = Color.White
                    )
                }
            }
        }
    )
}