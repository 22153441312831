package com.hyperether.goodjob.scenes.checkouts

import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import com.hyperether.goodjob.repository.Repository

class CheckoutsViewModel(val repository: Repository) : ViewModel() {
    val loading = mutableStateOf(false)
    init {
        repository.saveToken("Token......")
    }
}
