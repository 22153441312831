package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.PaintingStyle
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobRequest
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_job
import com.hyperether.goodjob.resources.add_notes
import com.hyperether.goodjob.resources.add_steps
import com.hyperether.goodjob.resources.assign_employee
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.custom
import com.hyperether.goodjob.resources.enter_job_title
import com.hyperether.goodjob.resources.every_2_weeks
import com.hyperether.goodjob.resources.every_day
import com.hyperether.goodjob.resources.every_month
import com.hyperether.goodjob.resources.every_week
import com.hyperether.goodjob.resources.files
import com.hyperether.goodjob.resources.job_duration
import com.hyperether.goodjob.resources.job_status
import com.hyperether.goodjob.resources.job_title
import com.hyperether.goodjob.resources.max_size
import com.hyperether.goodjob.resources.never
import com.hyperether.goodjob.resources.only_support
import com.hyperether.goodjob.resources.provide_information
import com.hyperether.goodjob.resources.repeat
import com.hyperether.goodjob.resources.select_employee
import com.hyperether.goodjob.resources.select_job_status
import com.hyperether.goodjob.resources.upload_cloud
import com.hyperether.goodjob.resources.upload_documents
import com.hyperether.goodjob.scenes.jobs.JobViewModel
import com.hyperether.goodjob.scenes.register.DurationInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.PopUpDialog
import com.hyperether.goodjob.scenes.register.RegisterInputField
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.planner.model.EventStatus
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewJob(viewModel: AddNewViewModel, navHostController: NavHostController) {

    val employees by viewModel.employees.collectAsState(initial = emptyList())

    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 20.dp)
        ) {
            Image(
                painter = painterResource(Res.drawable.back_arrow),
                contentDescription = "back",
                modifier = Modifier.clickable {
                    navHostController.popBackStack()
                }
            )

            Spacer(modifier = Modifier.weight(1f))

            Text(
                text = stringResource(Res.string.add_new_job),
                fontSize = 18.sp,
                color = TextColor,
                textAlign = TextAlign.Center
            )

            Spacer(modifier = Modifier.weight(1f))
        }
        Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
        Spacer(modifier = Modifier.height(15.dp))

        Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
            androidx.compose.material3.Text(
                text = stringResource(Res.string.about_job),
                color = Color.Black,
                fontSize = 24.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(top = 10.dp)
            )
            RegisterInputField(
                viewModel.jobTitleInput,
                stringResource(Res.string.job_title),
                placeholder = stringResource(Res.string.enter_job_title)
            )
            val items = listOf(EventStatus.PENDING.name, EventStatus.COMPLETED.name, EventStatus.DELAYED.name)
            AddNewJobDropdownInput(
                viewModel.jobStatusValue,
                stringResource(Res.string.job_status),
                placeholder = stringResource(Res.string.select_job_status),
                dropdownItems = items,
                painter = painterResource(Res.drawable.baseline_arrow_drop_down_24)
            )
            EmployeesDropdownInput(
                viewModel.assignEmployeeValue,
                stringResource(Res.string.assign_employee),
                placeholder = stringResource(Res.string.select_employee),
                dropdownItems = employees,
                painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                onContactSelected = { employee ->
                    viewModel.selectedEmployee.value = employee
                }
            )
            OutlinedCalendarInput(
                onClick = {
                    viewModel.isCalendar1Visible.value = true
                },
                viewModel.dateInputValue
            )

            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                OutlinedButton(
                    onClick = { viewModel.isTimePicker1Visible.value = true },
                    border = BorderStroke(1.dp, Color.DarkGray),
                    shape = RoundedCornerShape(8),
                    colors = ButtonDefaults.outlinedButtonColors(
                        contentColor = Color.White, containerColor = Color.Transparent
                    ),
                    modifier = Modifier
                        .height(73.dp)
                        .weight(1f).padding(top = 15.dp)
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = viewModel.startInputValue.value,
                            color = Color.DarkGray,
                            fontSize = 13.sp,
                            textAlign = TextAlign.Start,
                            modifier = Modifier.weight(4f).padding(top = 5.dp).offset(x = (-10).dp)
                        )
                    }
                }

                Spacer(modifier = Modifier.width(8.dp))

                OutlinedButton(
                    onClick = { viewModel.isTimePicker2Visible.value = true },
                    border = BorderStroke(1.dp, Color.DarkGray),
                    shape = RoundedCornerShape(8),
                    colors = ButtonDefaults.outlinedButtonColors(
                        contentColor = Color.White, containerColor = Color.Transparent
                    ),
                    modifier = Modifier
                        .height(73.dp)
                        .weight(1f).padding(top = 15.dp)
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = viewModel.endInputValue.value,
                            color = Color.DarkGray,
                            fontSize = 13.sp,
                            textAlign = TextAlign.Start,
                            modifier = Modifier.weight(4f).padding(top = 5.dp).offset(x = (-10).dp)
                        )
                    }
                }
            }
            val itemsRepeat =
                listOf(
                    stringResource(Res.string.never),
                    stringResource(Res.string.every_day),
                    stringResource(Res.string.every_week),
                    stringResource(Res.string.every_2_weeks),
                    stringResource(Res.string.every_month),
                    stringResource(Res.string.custom)
                )

            AddNewJobDropdownInput(
                viewModel.repeatValue,
                stringResource(Res.string.repeat),
                placeholder = "Never",
                dropdownItems = itemsRepeat,
                painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
            )
            OutlinedCalendarInput(
                onClick = {
                    viewModel.isCalendar2Visible.value = true
                },
                viewModel.dueDateValue
            )

            DurationInputField(
                viewModel.durationText,
                stringResource(Res.string.job_duration),
                placeholder = viewModel.durationText.value
            )
            Spacer(modifier = Modifier.height(15.dp))

            Text(
                text = stringResource(Res.string.add_steps),
                fontSize = 18.sp,
                color = TextColor,
                textAlign = TextAlign.Center
            )
            Spacer(modifier = Modifier.height(15.dp))

            StepInputFields(viewModel.steps)

            Text(
                text = stringResource(Res.string.files),
                fontSize = 18.sp,
                color = TextColor,
                textAlign = TextAlign.Center
            )
            RegisterInputField(
                viewModel.notesValue,
                stringResource(Res.string.add_notes),
                placeholder = stringResource(Res.string.provide_information)
            )
            Spacer(modifier = Modifier.height(15.dp))
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(200.dp)
                    .align(Alignment.CenterHorizontally)
                    .drawBehind {
                        val paint = Paint().apply {
                            color = BlueApp
                            style = PaintingStyle.Stroke
                            strokeWidth = 2.dp.toPx()
                            pathEffect = PathEffect.dashPathEffect(floatArrayOf(10f, 10f), 0f)
                        }
                        drawIntoCanvas { canvas ->
                            canvas.drawRoundRect(
                                left = 0f,
                                top = 0f,
                                right = size.width,
                                bottom = size.height,
                                radiusX = 16.dp.toPx(),
                                radiusY = 16.dp.toPx(),
                                paint = paint
                            )
                        }
                    },
                contentAlignment = Alignment.Center
            ) {
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center,
                    modifier = Modifier.fillMaxSize()
                ) {
                    Image(
                        painter = painterResource(Res.drawable.upload_cloud),
                        contentDescription = "",
                        modifier = Modifier.size(40.dp)
                    )

                    Spacer(modifier = Modifier.height(8.dp))

                    Text(
                        text = stringResource(Res.string.upload_documents),
                        color = Color.Black,
                        fontWeight = FontWeight.Bold
                    )

                    Spacer(modifier = Modifier.height(4.dp))

                    Text(
                        text = stringResource(Res.string.max_size),
                        color = Color.LightGray
                    )
                }
            }

            Text(
                text = stringResource(Res.string.only_support),
                fontSize = 12.sp,
                color = Color.LightGray,
                textAlign = TextAlign.Center
            )

            val stepsList: List<Step> = viewModel.steps.map { stepData ->
                Step(
                    stepTitle = stepData.title.value,
                    stepDescription = stepData.description.value,
                    estimatedDuration = stepData.estimation.value.toIntOrNull(),
                    stepStatus = stepData.stepStatus.value
                )
            }
            val job = JobRequest(
                jobTitle = viewModel.jobTitleInput.value,
                start_at = viewModel.startInputValue.value,
                end_at = viewModel.endInputValue.value,
                steps = stepsList,
                dueDate = viewModel.dueDateValue.value,
                employees = listOf(viewModel.selectedEmployee.value?._id),
                status = viewModel.jobStatusValue.value,
                repeat = viewModel.repeatValue.value,
                jobDuration = viewModel.durationInMinutes.value.toLong(),
                contactId = viewModel.contactId.value,
                userId = viewModel.getUser()?._id,
                workspaceId = viewModel.getWorkspaceId(),
                contactSiteName = viewModel.selectedSiteName.value,
                contactParentId = viewModel.contactParentId.value,
                creatorId = ""
            )
            FilledButton(
                onClick = {
                    viewModel.showLoader.value = true
                    viewModel.addJob(job,
                        onSuccess = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpSuccess.value = true
                        },
                        onError = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpError.value = true
                        })
                },
                modifier = Modifier.padding(
                    start = 16.dp,
                    end = 16.dp,
                    top = 30.dp,
                    bottom = 20.dp
                ),
                text = stringResource(Res.string.add),
                isEnabled = true
            )
            if (viewModel.isTimePicker1Visible.value) {
                CustomTimePicker(
                    time = viewModel.startInputValue,
                    showTimePicker = viewModel.isTimePicker1Visible,
                    onTimeSelected = { viewModel.startInputValue.value = it })
            }
            if (viewModel.isTimePicker2Visible.value) {
                CustomTimePicker(
                    time = viewModel.endInputValue,
                    showTimePicker = viewModel.isTimePicker2Visible,
                    onTimeSelected = {
                        viewModel.endInputValue.value = it
                        viewModel.calculateDuration()
                        println("$$$$$" + viewModel.durationInMinutes.value)
                    })
            }
            if (viewModel.isCalendar1Visible.value) {
                CustomCalendarView(
                    onDismiss = { viewModel.isCalendar1Visible.value = false },
                    onDateSelect = {
                        viewModel.dateInputValue.value = it.toString()
                    })
            }
            if (viewModel.isCalendar2Visible.value) {
                CustomCalendarView(
                    onDismiss = { viewModel.isCalendar2Visible.value = false },
                    onDateSelect = {
                        viewModel.dueDateValue.value = it.toString()
                    })
            }
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { viewModel.showPopUpSuccess.value = false },
                    title = "SUCCESS",
                    message = "Job added successfully"
                )
            }
            if (viewModel.showPopUpError.value) {
                PopUpDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = "ERROR",
                    message = "There was an error while adding job"
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar(showProgressBar = viewModel.showLoader)
            }
        }

    }
}