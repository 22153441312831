package com.hyperether.goodjob.scenes.contacts.site

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.map.MapComponent
import com.hyperether.goodjob.map.Marker
import com.hyperether.goodjob.models.AddressObject
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.repository.remote.model.LatLng
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about
import com.hyperether.goodjob.resources.about_site
import com.hyperether.goodjob.resources.add_site
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.contact_added_successfully
import com.hyperether.goodjob.resources.contact_deleted
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_updated_successfully
import com.hyperether.goodjob.resources.delete_contact
import com.hyperether.goodjob.resources.delete_site
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.email_send_icon_24
import com.hyperether.goodjob.resources.enter_contact_name
import com.hyperether.goodjob.resources.enter_email_address
import com.hyperether.goodjob.resources.enter_site_name
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.location_plus
import com.hyperether.goodjob.resources.map_pin
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.phone_24
import com.hyperether.goodjob.resources.save_changes
import com.hyperether.goodjob.resources.save_new_site
import com.hyperether.goodjob.resources.site_added
import com.hyperether.goodjob.resources.site_deleted
import com.hyperether.goodjob.resources.site_name
import com.hyperether.goodjob.resources.site_updated
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.user
import com.hyperether.goodjob.scenes.components.AddressInputField
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.contacts.ContactsViewModel
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.RegisterBorder
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewSiteWeb(
    viewModel: ContactsViewModel,
    navHostController: NavHostController,
    contactId: String,
    siteId: String
) {

    LaunchedEffect(siteId) {
        viewModel.fetchSiteById(siteId)
        viewModel.fetchContactById(contactId)
    }
    LaunchedEffect(true) {
        println("CONTACT_ID" + contactId)
        println("SITE_ID" + siteId)
        println("SITE" + viewModel.remoteSite.value.toString())
    }
    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
            .verticalScroll(rememberScrollState())
            .padding(bottom = 40.dp)
    ) {

        WebViewHeader(
            isAddNewJob = false,
            isBackAvailable = false,
            title = stringResource(Res.string.add_site),
            isImportAndAddVisible = false,
            navHostController = navHostController
        )
        Spacer(modifier = Modifier.height(Dp(15f)))
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = Dp(75f), end = Dp(75f), top = Dp(40f))
                .border(width = Dp(1f), color = RegisterBorder)
                .background(color = Color.White)
                .padding(start = Dp(20f), top = Dp(40f))
                .padding(horizontal = 16.dp)
                .padding(end = Dp(40f))
        ) {


            Text(
                text = stringResource(Res.string.about_site),
                fontSize = Sp(24f),
                fontWeight = FontWeight.W700,
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(color = Color.White)
                    .padding(end = Dp(20f))
            ) {
                Column(
                    modifier = Modifier.weight(1f).padding(end = Dp(20f))
                ) {
                    Column(modifier = Modifier, verticalArrangement = Arrangement.spacedBy(10.dp)) {
                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.location_plus),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.site_name))
                            }
                            Box(modifier = Modifier.weight(3f)) {
                                AppInputField(
                                    viewModel.siteNameInput,
                                    stringResource(Res.string.site_name),
                                    placeholder = stringResource(Res.string.enter_site_name),
                                    paddingTop = 0.dp

                                )
                            }

                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.user),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.contact_name))
                            }
                            Box(modifier = Modifier.weight(3f)) {
                                AppInputField(
                                    viewModel.siteContactNameInput,
                                    stringResource(Res.string.contact_name),
                                    placeholder = stringResource(Res.string.enter_contact_name),
                                    paddingTop = 0.dp
                                )
                            }
                        }


                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.email_send_icon_24),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.email_address))
                            }

                            Box(modifier = Modifier.weight(3f)) {
                                AppInputField(
                                    viewModel.siteEmailInput,
                                    stringResource(Res.string.email_address),
                                    placeholder = stringResource(Res.string.enter_email_address),
                                    enabled = true,
                                    paddingTop = 0.dp
                                )
                            }
                        }


                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.map_pin),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.address))
                            }

                            Box(modifier = Modifier.weight(3f)) {
                                AddressInputField(
                                    text = viewModel.siteAddressInput,
                                    label = stringResource(Res.string.address),
                                    placeholder = stringResource(Res.string.enter_street),
                                    onSearch = { viewModel.findPlace(it) },
                                    onSelect = {
                                        viewModel.siteAddressInput.value = it.formattedAddress
                                        viewModel.place.value = it
                                    },
                                    places = viewModel.places,
                                    modifier = Modifier
                                )
                            }
                        }

                        Row(
                            modifier = Modifier.fillMaxWidth().padding(top = 10.dp),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Icon(
                                    painter = painterResource(Res.drawable.phone_24),
                                    "icon"
                                )
                                Spacer(modifier = Modifier.width(4.dp))
                                Text(stringResource(Res.string.phone))
                            }
                            Box(modifier = Modifier.weight(3f)) {
                                PhoneInputField(
                                    viewModel.sitePhoneInput,
                                    stringResource(Res.string.phone),
                                    placeholder = "+123456789",
                                    paddingTop = 0.dp
                                )
                            }
                        }
                        Spacer(modifier = Modifier.height(25.dp))

                        val newSite = Site(
                            siteName = viewModel.siteNameInput.value,
                            parentName = viewModel.remoteContact.value?.name,
                            parentId = contactId,
                            addressObject = AddressObject(
                                address = viewModel.siteAddressInput.value,
                                lat = viewModel.place.value?.location?.latitude,
                                long = viewModel.place.value?.location?.longitude
                            ),
                            personName = viewModel.siteContactNameInput.value,
                            personPhone = viewModel.sitePhoneInput.value,
                            personEmail = viewModel.siteEmailInput.value
                        )
                        val textSiteUpdated = stringResource(Res.string.site_updated)
                        FilledButton(
                            onClick = {
                                viewModel.showLoader.value = true
                                if (siteId.isEmpty()) {
                                    viewModel.addSite(newSite)
                                } else {
                                    viewModel.updateSite(siteId, newSite,
                                        onSuccess = {
                                            viewModel.showLoader.value = false
                                            viewModel.showPopUpSuccess.value = true
                                            viewModel.successApiText.value = textSiteUpdated
                                        },
                                        onError = {
                                            viewModel.showLoader.value = false
                                            viewModel.showPopUpError.value = true

                                        })
                                }

                            },
                            text = if (siteId.isNotEmpty()) {
                                stringResource(Res.string.save_changes)
                            } else {
                                stringResource(Res.string.save_new_site)
                            },
                            isEnabled = true
                        )
                        Spacer(modifier = Modifier.height(25.dp))
                        if (viewModel.showPopUpSuccess.value) {
                            PopUpDialog(onDismiss = {
                                viewModel.showPopUpSuccess.value = false
                                navHostController.popBackStack()
                            },
                                title = stringResource(Res.string.success).uppercase(),
                                message = if (siteId.isNotEmpty()) stringResource(Res.string.site_updated) else stringResource(
                                    Res.string.site_added
                                ),
                                positiveBtnText = stringResource(Res.string.ok),
                                onPositiveBtnClicked = {
                                    viewModel.showPopUpSuccess.value = false
                                    navHostController.popBackStack()
                                })
                        }
                        if (viewModel.showPopUpSuccessDeleted.value) {
                            PopUpDialog(onDismiss = {
                                viewModel.showPopUpSuccessDeleted.value = false
                                navHostController.popBackStack()
                            },
                                title = stringResource(Res.string.success).uppercase(),
                                message = stringResource(Res.string.site_deleted),
                                positiveBtnText = stringResource(Res.string.ok),
                                onPositiveBtnClicked = {
                                    viewModel.showPopUpSuccessDeleted.value = false
                                    navHostController.popBackStack()
                                })
                        }
                        if (viewModel.showPopUpError.value) {
                            ErrorDialog(
                                onDismiss = { viewModel.showPopUpError.value = false },
                                title = stringResource(Res.string.error).uppercase(),
                                message = viewModel.errorText.value
                            )
                        }
                        if (viewModel.showLoader.value) {
                            FullScreenProgressBar()
                        }
                    }
                }
                val offset =
                    if (siteId.isNotEmpty()) {
                        (-10).dp
                    } else {
                        0.dp
                    }
                val padding =
                    if (siteId.isNotEmpty()) {
                        0.dp
                    } else {
                        20.dp
                    }

                Column(
                    modifier = Modifier.width(Dp(500f)).height(Dp(800f)).offset(y = offset)
                        .padding(top = padding)
                ) {
                    if (siteId.isNotEmpty()) {
                        Text(
                            text = stringResource(Res.string.delete_site),
                            color = Color.Red,
                            fontSize = 18.sp,
                            modifier = Modifier.align(Alignment.End).padding(bottom = 10.dp)
                                .clickable {
                                    viewModel.showLoader.value = true
                                    viewModel.deleteSite(siteId, onSuccess = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpSuccessDeleted.value = true
                                    }, onError = {
                                        viewModel.showLoader.value = false
                                        viewModel.showPopUpError.value = true
                                    })
                                }
                        )
                    }
                    MapComponent(
                        listOf(
                            Marker(
                                viewModel.remoteSite.value?.addressObject?.lat ?: 0.0,
                                viewModel.remoteSite.value?.addressObject?.long ?: 0.0,
                                "Nikola"
                            )
                        ), mutableStateOf(
                            LatLng(0.0, 0.0)
                        )
                    )
                }
            }
        }
    }
}