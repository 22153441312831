package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Step(
    @SerialName("stepTitle") var stepTitle: String? = null,
    @SerialName("stepDescription") var stepDescription: String? = null,
    @SerialName("estimatedDuration") var estimatedDuration: Int? = null,
    @SerialName("stepStatus") var stepStatus: String? = null
) {
}