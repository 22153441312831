package com.hyperether.goodjob.repository.remote

import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.remote.model.ConfirmationRequest
import com.hyperether.goodjob.repository.remote.model.ConfirmationResponse
import com.hyperether.goodjob.repository.remote.model.LoginRequest
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import com.hyperether.goodjob.repository.remote.model.RegisterRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.WorkspaceRequest
import io.ktor.client.call.body
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import org.lighthousegames.logging.logging

class GoodJobApiImpl() : GoodJobApi {

    var baseURL: String = ApiConfig.baseURL
    val log = logging("GoodJobApiImpl")

    override suspend fun register(
        name: String,
        email: String,
        password: String
    ): Resource<User> {


        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/register") {
                    contentType(ContentType.Application.Json)
                    setBody(RegisterRequest(name, email, password))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to register: ${response.status}"
                println(response.bodyAsText())
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun login(email: String, password: String): Resource<LoginResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/login") {
                    contentType(ContentType.Application.Json)
                    setBody(LoginRequest(email, password))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: LoginResponse = response.body()
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to login: ${response.status}"
                println(response.bodyAsText())
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun confirmation(hash: String, email: String, password: String, onSuccess: () -> Unit, onError: () -> Unit): Resource<ConfirmationResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/confirmation") {
                    contentType(ContentType.Application.Json)
                    setBody(ConfirmationRequest(hash))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: ConfirmationResponse = response.body()
                when (val loginResult = login(email, password)) {
                    is Resource.Success -> {
                        onSuccess()
                        Resource.Success(apiResponse)
                    }
                    is Resource.Error -> {
                        onError()
                        Resource.Error("Login failed after confirmation: ${loginResult.data}")
                    }
                }
            } else {
                onError()
                val errorMessage = "Failed to confirm email: ${response.status}"
                println(response.bodyAsText())
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun addWorkspace(name: String, type: String, token: String): Resource<Workspace> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/workspace") {
                headers {
                    append("x-access-token", token)
                    contentType(ContentType.Application.Json)
                    setBody(WorkspaceRequest(name, type))
                }
            }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Workspace = response.body()
                log.d { "Add workspace SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorMessage = "Failed to add workspace: ${response.status}"
                log.d { "Failed to add workspace: ${(response.bodyAsText())}" }
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d { "Failed to add workspace: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }
}