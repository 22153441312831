package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Skills
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.UserTeam
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Place
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.WorkingHours
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.friday
import com.hyperether.goodjob.resources.monday
import com.hyperether.goodjob.resources.saturday
import com.hyperether.goodjob.resources.sunday
import com.hyperether.goodjob.resources.thursday
import com.hyperether.goodjob.resources.tuesday
import com.hyperether.goodjob.resources.wednesday
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging

class AddUserViewModel(val repository: Repository) : ViewModel() {

    val log = logging("AddUserViewModel")

    /*Add new employee*/
    val fullNameInput: MutableState<String> = mutableStateOf("")
    val phoneInput: MutableState<String> = mutableStateOf("")
    val emailInput: MutableState<String> = mutableStateOf("")
    val zipInput: MutableState<String> = mutableStateOf("")
    val cityInput: MutableState<String> = mutableStateOf("")
    val countryInput: MutableState<String> = mutableStateOf("")
    val addressInput: MutableState<String> = mutableStateOf("")
    val status: MutableState<String> = mutableStateOf("")
    val roleSelected: MutableState<String> = mutableStateOf("")
    val sites = mutableStateListOf<Site>()

    private val _selectedSkills = MutableStateFlow<List<String>>(emptyList())
    val selectedSkills: StateFlow<List<String>> = _selectedSkills.asStateFlow()

    private val _availableSkills = MutableStateFlow<List<Skills>>(emptyList())
    val availableSkills: StateFlow<List<Skills>> = _availableSkills.asStateFlow()

    private val _teams = MutableStateFlow<List<Team>>(emptyList())
    val teams: StateFlow<List<Team>> = _teams
    var places = mutableStateListOf<Place>()
    var place: Place? = null


    val selectedTeam: MutableState<UserTeam?> = mutableStateOf(null)
    val teamInput: MutableState<Team?> = mutableStateOf(null)
    val selectedSkill: MutableState<String?> = mutableStateOf(null)
    val upcomingLeaves: MutableState<String> = mutableStateOf("Upcoming leaves")

    private val _selectedUser = MutableStateFlow<com.hyperether.goodjob.models.User?>(null)
    val selectedUser: StateFlow<com.hyperether.goodjob.models.User?> = _selectedUser

    /* Edit user */
    private val _preselectedSkills = MutableStateFlow<List<String>>(emptyList())
    val preselectedSkills: StateFlow<List<String>> = _preselectedSkills.asStateFlow()

    val isCalendar2Visible: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")

    var roleMap: Map<String, String> = mutableMapOf()
    var userStatusMap: Map<String, String> = mutableMapOf()

    init {
        if (getPlatformChecker().isMobile()) {
            log.d("PricingViewModel") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            init()
        }
    }

    fun init() {
        getAllSkillsRemote()
    }

    fun getTeams() {
        viewModelScope.launch {
            repository.getTeamsFlow().collect {
                _teams.value = it
            }
        }
    }

    fun setPreselectedSkills(preselectedSkills: List<String>) {
        _preselectedSkills.value = preselectedSkills
    }

    fun setSelectedSkills(selectedSkills: List<String>) {
        _selectedSkills.value = selectedSkills
    }

    val showErrorDialog = mutableStateOf(false)

    val daysSchedule = listOf(
        DaySchedule(
            dayName = Res.string.monday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.tuesday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.wednesday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.thursday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.friday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.saturday,
            isChecked = mutableStateOf(false),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.sunday,
            isChecked = mutableStateOf(false),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        )
    )

    val workingHours = derivedStateOf {
        WorkingHours(
            monday = daysSchedule.find { it.dayName == Res.string.monday }?.toDayHours(),
            tuesday = daysSchedule.find { it.dayName == Res.string.tuesday }?.toDayHours(),
            wednesday = daysSchedule.find { it.dayName == Res.string.wednesday }?.toDayHours(),
            thursday = daysSchedule.find { it.dayName == Res.string.thursday }?.toDayHours(),
            friday = daysSchedule.find { it.dayName == Res.string.friday }?.toDayHours(),
            saturday = daysSchedule.find { it.dayName == Res.string.saturday }?.toDayHours(),
            sunday = daysSchedule.find { it.dayName == Res.string.sunday }?.toDayHours()
        )
    }

    fun clearWorkingHours() {
        daysSchedule.forEach { day ->
            day.isChecked.value = when (day.dayName) {
                Res.string.monday, Res.string.tuesday, Res.string.wednesday,
                Res.string.thursday, Res.string.friday -> true

                else -> false
            }
            day.startTime.value = "08:00"
            day.endTime.value = "16:00"
        }
    }

    fun addEmployee(onSuccess: () -> Unit, onError: () -> Unit) {
        // Validate inputs before proceeding
        val errorMessage = when {
            roleSelected.value.isBlank() -> "Role is required."
//            fullNameInput.value.isBlank() -> "Full Name is required."
//            phoneInput.value.isBlank() -> "Phone number is required."
            emailInput.value.isBlank() -> "Email is required."
//            cityInput.value.isBlank() -> "City is required."
//            countryInput.value.isBlank() -> "Country is required."
//            addressInput.value.isBlank() -> "Address is required."
//            zipInput.value.isBlank() -> "ZIP code is required."
            status.value.isBlank() -> "Status is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return
        }

        // If validation passes, create the employee object
        val user = com.hyperether.goodjob.models.User(
            fullName = fullNameInput.value,
            email = emailInput.value,
            address = addressInput.value,
            city = cityInput.value,
            zip = zipInput.value,
            country = countryInput.value,
            status = userStatusMap[status.value],
            teams = getUserTeam(),
            skills = selectedSkills.value,
            role = roleMap[roleSelected.value],
            phone = phoneInput.value,
            workspaceId = repository.getWorkspaceId(),
            upcomingLeave = upcomingLeaves.value,
            workingHours = workingHours.value
        )

        viewModelScope.launch {
            val result = repository.addUser(user.toEmployeeRequest())

            when (result) {
                is Resource.Success -> {
                    println("User added successfully")
                    clearUserValues()
                    onSuccess()
                }

                is Resource.Error -> {
                    println("Error while adding user")
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun updateUser(onSuccess: () -> Unit, onError: () -> Unit) {
        val errorMessage = when {
            roleSelected.value.isBlank() -> "Role is required."
            emailInput.value.isBlank() -> "Email is required."
            status.value.isBlank() -> "Status is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return
        }

        // If validation passes, create the employee object
        val user = com.hyperether.goodjob.models.User(
            fullName = fullNameInput.value,
            email = emailInput.value,
            address = addressInput.value,
            city = cityInput.value,
            zip = zipInput.value,
            country = countryInput.value,
            status = userStatusMap[status.value],
            teams = getUserTeam(),
            skills = selectedSkills.value,
            role = roleMap[roleSelected.value],
            phone = phoneInput.value,
            workspaceId = repository.getWorkspaceId(),
            upcomingLeave = upcomingLeaves.value,
            workingHours = workingHours.value
        )

        viewModelScope.launch {
            selectedUser.value?._id?.let {
                when (val result = repository.updateUser(it, user.toEmployeeRequest())) {
                    is Resource.Success -> {
                        println("User updated successfully")
                        clearUserValues()
                        onSuccess()
                    }

                    is Resource.Error -> {
                        println("Error while updating user")
                        errorText.value = result.text ?: "An unknown error occurred"
                        onError()
                    }
                }
            }
        }
    }

    private fun getUserTeam(): List<UserTeam> {
        teamInput.value?.let {
            return listOf(UserTeam(id = it._id, value = it.name))
        }
        return listOf()
    }

    fun clearUserValues() {
        fullNameInput.value = ""
        emailInput.value = ""
        addressInput.value = ""
        zipInput.value = ""
        countryInput.value = ""
        cityInput.value = ""
        status.value = ""
        phoneInput.value = ""
        upcomingLeaves.value = "Selected dates"
        roleSelected.value = ""
        _selectedSkills.value = emptyList()
        _preselectedSkills.value = emptyList()
        teamInput.value = null
        clearWorkingHours()
    }

    fun createTeam(name: String, onTeamCreated: (Team?) -> Unit) {
        viewModelScope.launch {
            when (val result = repository.createTeam(name)) {
                is Resource.Success -> {
                    println(result.data)
                    getTeams()
                    // Wait for teams to update and find the newly created team
                    onTeamCreated(result.data)
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showPopUpError.value = true
                }
            }
        }
    }

    fun addNewSkill(skillName: String) {
        viewModelScope.launch {
            when (val result = repository.addSkill(Skills(name = skillName, workspaceId = repository.getWorkspaceId()))) {
                is Resource.Success -> {

                }
                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showPopUpError.value = true
                }
            }
        }
    }

    fun getAllSkillsRemote() {
        viewModelScope.launch {
            when (val result = repository.getSkillsByWorkspace()) {
                is Resource.Success -> {
                    _availableSkills.value = result.data ?: emptyList()
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    fun getAllTeamsRemote() {
        viewModelScope.launch {
            when (val result = repository.getTeams()) {
                is Resource.Success -> {
                    result.data?.let { teamsList ->
                        _teams.value = teamsList
                    }
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    fun removeSkills() {
        viewModelScope.launch {
            _availableSkills.value = emptyList()
            _selectedSkills.value = emptyList()
        }
    }

    fun getUserById(userId: String) {
        if (userId.contains("profileSettings")) {
            val user = repository.getUser()
            user?._id = "profileSettings-${user?._id}"
            _selectedUser.value = user
        } else {
            viewModelScope.launch {
                repository.getUsersFlow().collect { users ->
                    val user = users.firstOrNull { it._id == userId }
                    _selectedUser.value = user
                }
            }
        }
    }

    fun findPlace(name: String) {
        viewModelScope.launch {
            val list = repository.findPLace(name)
            places.clear()
            places.addAll(list?.toList() ?: emptyList())
        }
    }

    fun isManager(): Boolean {
        return repository.getUser()?.role.equals(Role.manager.name, ignoreCase = true)
    }
}