package com.hyperether.goodjob

import App
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import androidx.navigation.compose.rememberNavController
import com.hyperether.goodjob.database.DriverFactory
import com.hyperether.goodjob.database.SharedDatabase
import com.hyperether.goodjob.database.WorkspaceDaoImpl
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.WebRepositoryImpl
import com.hyperether.goodjob.repository.prefs.Preferences
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.ClientProvider
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.url.URLSearchParams

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    val sharedDatabase = SharedDatabase(DriverFactory())
    val workspaceDao = WorkspaceDaoImpl(sharedDatabase)
    val mapper = Mapper()
    ClientProvider.initialize()
    val queryParams = URLSearchParams(document.location?.search ?: "")
    val hash = queryParams.get("hash")

    val prefsManager = PrefsManager(Preferences())
    val goodJobApiImpl = GoodJobApiImpl()

    val repository = WebRepositoryImpl(prefsManager, goodJobApiImpl)
    onWasmReady {
        CanvasBasedWindow("Good job") {
            val navController = rememberNavController()
            App(navController, repository)
            handleDeepLink(
                hash,
                goodJobApiImpl,
                prefsManager,
                navigate = { route ->
                    navController.navigate(route)
                }
            )
        }
    }
}

fun handleDeepLink(
    hash: String?,
    goodJobApiImpl: GoodJobApiImpl,
    prefsManager: PrefsManager,
    navigate: (route: String) -> Unit
) {
    CoroutineScope(Dispatchers.Default).launch {
        delay(1000L)
        hash?.let {
            goodJobApiImpl.confirmation(
                hash,
                prefsManager.getEmail(),
                prefsManager.getPassword(),
                onSuccess = {
                    prefsManager.savePassword("")
                    navigate(Screen.Pricing.route)
                },
                onError = {
                    navigate(Screen.Landing.route)
                    println("Confirmation error")
                }
            )
        } ?: run {
            navigate(Screen.Landing.route)
            println("No hash found in URL query parameters.")
        }
    }
}
