package com.hyperether.goodjob.scenes.history

import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.models.Employee
import com.hyperether.goodjob.repository.Repository
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch

class HistoryScreenViewModel(val repository: Repository) :
    ViewModel() {

    val loading = mutableStateOf(false)
    val testData = mutableStateOf("")

    init {
        viewModelScope.launch {
            getData()
            insertTestWorkspace()
        }
    }

    private fun getData() {
        viewModelScope.launch {
           // testData.value = repository.getToken()
        // repository.getWorkspaces()
        }
    }

    val employees: StateFlow<List<com.hyperether.goodjob.database.Employee>> = repository.getEmployees()
        .stateIn(
            scope = viewModelScope,
            started = SharingStarted.WhileSubscribed(5000),
            initialValue = emptyList()
        )

    private fun insertTestWorkspace() {
        viewModelScope.launch {
            //repository.insertWorkspace(Workspace(1, "Test1", "TestType", "1", "TestPlan"))
            repository.insertEmployee(Employee(
                full_name = "Test",
                    email = "tets@email.com",
                    address = "fdfdffdf",
                    city_zip_country = "21000, Novi Sad",
                    status = "Active",
                    team = "sales",
                    avatar = "",
                    role = "user",
                    phone_number = "1234567"

            ))
        }
    }

    fun getAllWorkspaces(){
        viewModelScope.launch {
            repository.getWorkspaces().collect {
                testData.value = it.toString()
            }
        }
    }
}