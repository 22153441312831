package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.AssigneesRequest
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.models.Repeat
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.FileObject
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.UploadManager
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.lighthousegames.logging.logging
import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

class AddJobViewModel(val repository: Repository) : ViewModel() {
    val log = logging("AddJobViewModel")

    var contactName: MutableState<String> = mutableStateOf("")
    var contactSite: MutableState<String> = mutableStateOf("")
    val contactSites = mutableStateListOf<Site>()

    private val _contacts = MutableStateFlow<List<Contact>>(emptyList())
    val contacts: StateFlow<List<Contact>> = _contacts
    private val _users = MutableStateFlow<List<User>>(emptyList())
    val users: StateFlow<List<User>> = _users
    private val _teams = MutableStateFlow<List<Team>>(emptyList())
    val teams: StateFlow<List<Team>> = _teams
    private val _selectedJob = MutableStateFlow<Job?>(null)
    val selectedJob: StateFlow<Job?> = _selectedJob

    val fileId = UploadManager.fileId
    val documentStatus = UploadManager.documentStatus
    val fileName = UploadManager.fileName
    val fileIds = UploadManager.fileIds
    val fileNames = UploadManager.fileNames

    val jobTitleInput: MutableState<String> = mutableStateOf("")
    val jobStatusValue: MutableState<String> = mutableStateOf("")
    val jobStatusPlaceholderValue: MutableState<String> = mutableStateOf("")
    val assignEmployeeValue: MutableState<String> = mutableStateOf("")
    val startDateInputValue: MutableState<String> = mutableStateOf("")
    val startDateInputPlaceholder: MutableState<String> = mutableStateOf("")
    val startInputValue: MutableState<String> = mutableStateOf("")
    val startInputPlaceholder: MutableState<String> = mutableStateOf("")
    val endInputValue: MutableState<String> = mutableStateOf("")
    val endInputPlaceholder: MutableState<String> = mutableStateOf("")
    val repeatValue: MutableState<String> = mutableStateOf("")
    val dueDateValue: MutableState<String> = mutableStateOf("")
    val dueDatePlaceholder: MutableState<String> = mutableStateOf("")
    val jobDurationValue: MutableState<Int> = mutableStateOf(0)
    val notesValue: MutableState<String> = mutableStateOf("")
    val contactSiteId: MutableState<String> = mutableStateOf("")
    val selectedSiteName: MutableState<String> = mutableStateOf("")
    val contactParentId: MutableState<String> = mutableStateOf("")
    val jobSelectedEmployeeList: MutableState<List<User>> = mutableStateOf(arrayListOf())
    val jobSelectedTeamList: MutableState<List<Team>> = mutableStateOf(arrayListOf())
    val durationText: MutableState<String> = mutableStateOf("")
    val durationInMinutes: MutableState<Int> = mutableStateOf(0)

    /* Edit job */
    val preselectedUsers = mutableStateListOf<User>()
    val preselectedTeamsJob = mutableStateListOf<Team>()
    val notesListValue: MutableState<List<Note>> = mutableStateOf(emptyList())

    val isTimePicker1Visible: MutableState<Boolean> = mutableStateOf(false)
    val isTimePicker2Visible: MutableState<Boolean> = mutableStateOf(false)
    val isCalendar1Visible: MutableState<Boolean> = mutableStateOf(false)
    val isCalendar2Visible: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val showDocumentLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")
    val steps = mutableStateListOf(StepData())
    val showErrorDialog = mutableStateOf(false)

    var jobStatusMap: Map<String, String> = mutableMapOf()
    var repeatMap: Map<String, String> = mutableMapOf()

    val showAreYouSurePopUpDeleteJob: MutableState<Boolean> = mutableStateOf(false)
    val selectedJobIdToDelete = mutableStateOf<String?>(null)

    init {
        if (getPlatformChecker().isMobile()) {
            log.d("getContactsFlow AddJobViewModel") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            init()
        }
    }

    fun init() {
        viewModelScope.launch {
            log.d("getContactsFlow AddJobViewModel") { "init" }
            repository.getContactsFlow().collect {
                _contacts.value = it
            }
        }
        viewModelScope.launch {
            repository.getUsersFlow().collect {
                _users.value = it
            }
        }
        viewModelScope.launch {
            repository.getTeamsFlow().collect {
                _teams.value = it
            }
        }
    }

    @OptIn(ExperimentalUuidApi::class)
    fun addJob(onSuccess: () -> Unit, onError: () -> Unit) {
        notesListValue.value = arrayListOf(
            Note(
                _id = Uuid.random().toString(),
                noteText = notesValue.value,
                createdBy = getUser()?.getName(),
                userId = getUser()?._id,
                createdAt = DateTimeUtil.formatInstantToCustomFormat(Clock.System.now())
            )
        )

        val jobRequest = validateJobRequest {
            onError()
        }

        jobRequest?.let {
            viewModelScope.launch {
                val result = repository.createJob(jobRequest)

                when (result) {
                    is Resource.Success -> {
                        clearJobValues()
                        clearContactAndSiteValues()
                        onSuccess()
                    }

                    is Resource.Error -> {
                        errorText.value = result.text ?: "An unknown error occurred"
                        onError()
                    }
                }
            }
        }
    }

    fun updateJob(jobId: String, onSuccess: () -> Unit, onError: () -> Unit) {
        val jobRequest = validateJobRequest {
            onError()
        }

        jobRequest?.let {
            viewModelScope.launch {
                when (val result = repository.updateJob(jobId, jobRequest)) {
                    is Resource.Success -> {
                        clearJobValues()
                        clearContactAndSiteValues()
                        onSuccess()
                    }

                    is Resource.Error -> {
                        errorText.value = result.text ?: "An unknown error occurred"
                        onError()
                    }
                }
            }
        }
    }

    private fun validateJobRequest(onError: () -> Unit): JobRequest? {
        val errorMessage = when {
            jobTitleInput.value.isBlank() -> "Job title is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return null
        }

        if (jobStatusValue.value.isEmpty() ||
            jobStatusValue.value == jobStatusPlaceholderValue.value ||
            contactSiteId.value.isEmpty() ||
            (jobSelectedEmployeeList.value.isEmpty() && jobSelectedTeamList.value.isEmpty())
        ) {
            jobStatusValue.value = JobStatus.draft.name
        }

        if (startDateInputValue.value.isEmpty()) {
            startDateInputValue.value =
                Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date.toString()
        }

        if (repeatValue.value.isEmpty()) {
            repeatValue.value = Repeat.never.name
        }

        val stepsList: List<Step> = steps.map { stepData ->
            Step(
                stepTitle = stepData.title.value,
                stepDescription = stepData.description.value,
                estimatedDuration = stepData.estimation.value.toIntOrNull(),
                stepStatus = stepData.stepStatus.value
            )
        }

        val jobRequest = JobRequest(
            jobTitle = jobTitleInput.value,
            steps = stepsList,
            start_at = startInputValue.value.takeIf { it != startInputPlaceholder.value },
            end_at = endInputValue.value.takeIf { it != endInputPlaceholder.value },
            startDate = startDateInputValue.value.takeIf { it != startDateInputPlaceholder.value },
            dueDate = dueDateValue.value.takeIf { it != dueDatePlaceholder.value },
            assignees = AssigneesRequest(
                employees = jobSelectedEmployeeList.value.map { it._id }.distinct(),
                teams = jobSelectedTeamList.value.map { it._id }.distinct()
            ),
            status = jobStatusValue.value.takeIf { it != jobStatusPlaceholderValue.value }
                ?.let { jobStatusMap[jobStatusValue.value] } ?: JobStatus.draft.name,
            repeat = repeatMap[repeatValue.value],
            jobDuration = durationText.value.toLongOrNull(),
            contactSiteId = contactSiteId.value,
            userId = getUser()?._id,
            workspaceId = getWorkspaceId(),
            contactSiteName = selectedSiteName.value,
            contactParentId = contactParentId.value,
            creatorId = "",
            fileId = fileId.value,
            files = fileIds.value,
            fileObjects = fileIds.value.map { fileId ->
                FileObject(
                    documentFileId = fileId,
                    createdBy = getUser()?.getName(),
                    profileImageFileId = null, // TODO: update if profile image becomes available
                    createdAt = DateTimeUtil.formatInstantToCustomFormat(Clock.System.now()),
                    userId = getUser()?._id
                )
            },
            noteObjects = notesListValue.value
        )

        return jobRequest
    }

    fun deleteUploadedFile(fileName: String) {
        viewModelScope.launch {
            showLoader.value = true
            when (val result = repository.deleteUploadedFile(fileName)) {
                is Resource.Success -> {
                    UploadManager.removeFileByName(fileName)
                    showLoader.value = false
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showLoader.value = false
                    showPopUpError.value = true
                }
            }
        }
    }

    fun clearJobValues() {
        jobTitleInput.value = ""
        startInputValue.value = ""
        endInputValue.value = ""
        dueDateValue.value = ""
        jobSelectedEmployeeList.value = arrayListOf()
        jobSelectedTeamList.value = arrayListOf()
        repeatValue.value = ""
        durationText.value = ""
        durationInMinutes.value = 0
        notesValue.value = ""
        assignEmployeeValue.value = ""
        startDateInputValue.value = ""
        preselectedUsers.clear()
        preselectedTeamsJob.clear()
        UploadManager.setFileName("")
        UploadManager.setDocumentStatus("")
        UploadManager.setFileId("")
        UploadManager.removeAll()
        steps.clear()
        _selectedJob.value = null
    }

    fun clearContactAndSiteValues() {
        contactSiteId.value = ""
        contactParentId.value = ""
        selectedSiteName.value = ""
        contactName.value = ""
        contactSite.value = ""
    }

    fun getWorkspaceId(): String = repository.getWorkspaceId()

    fun getUser(): User? {
        return repository.getUser()
    }

    fun getJobById(jobId: String) {
        viewModelScope.launch {
            repository.getJobByIdFlow(jobId)
                .collect { job ->
                    _selectedJob.value = job
                }
        }
    }

    fun getSitesUnderContact(siteRequest: SiteRequest) {
        viewModelScope.launch {
            val result = repository.getSitesUnderContact(siteRequest)
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                contactSites.clear()
                contactSites.addAll(result.data ?: emptyList())
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }
    }

    val contactListWeb = mutableStateListOf<Contact>()
    fun getContactsForWeb() {
        viewModelScope.launch {
            val result = repository.getContactsByWorkspaceId()
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                contactListWeb.clear()
                result.data?.let { contactListWeb.addAll(it) }
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }

    }

    fun deleteJob(jobId: String, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.deleteJobById(jobId)
            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    onError()
                }
            }
        }
    }
}