package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.TextField
import androidx.compose.material.TextFieldDefaults
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all_employees
import com.hyperether.goodjob.resources.avatar_female
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.ic_active_card
import com.hyperether.goodjob.resources.ic_back
import com.hyperether.goodjob.resources.ic_calendar
import com.hyperether.goodjob.resources.ic_leave
import com.hyperether.goodjob.resources.ic_members_card
import com.hyperether.goodjob.resources.ic_search
import com.hyperether.goodjob.resources.ic_teams_card
import com.hyperether.goodjob.resources.ic_work_scheduler
import com.hyperether.goodjob.resources.name
import com.hyperether.goodjob.resources.phone_number
import com.hyperether.goodjob.resources.search
import com.hyperether.goodjob.resources.select_dates
import com.hyperether.goodjob.resources.set_weekly_hours
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.resources.unselect_all
import com.hyperether.goodjob.resources.upcoming_leaves
import com.hyperether.goodjob.resources.work_schedule
import com.hyperether.goodjob.scenes.addNew.CustomDropdown
import com.hyperether.goodjob.theme.AddEmployeeInputColor
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.EmployeeDetailsText
import com.hyperether.goodjob.theme.EmployeesBorder
import com.hyperether.goodjob.theme.FooterText
import com.hyperether.goodjob.theme.GreenActive
import com.hyperether.goodjob.theme.GreenActiveBorder
import com.hyperether.goodjob.theme.HeaderText
import com.hyperether.goodjob.theme.RedInactive
import com.hyperether.goodjob.theme.SelectedCardBg
import com.hyperether.goodjob.theme.UnselectedPage
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun StatsSection() {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(150.dp)
            .padding(start = 70.dp, end = 70.dp)
            .background(Color.White)
            .border(
                width = 1.dp,
                color = EmployeesBorder,
                shape = RoundedCornerShape(4.dp)
            ),
        horizontalArrangement = Arrangement.SpaceEvenly
    ) {
        StatCard(
            title = "Teams",
            value = "15",
            change = "+6 this month",
            resource = Res.drawable.ic_teams_card
        )
        VerticalDivider()
        StatCard(
            title = "Members",
            value = "123",
            change = "-1 this month",
            resource = Res.drawable.ic_members_card
        )
        VerticalDivider()
        StatCard(
            title = "Active this Month",
            value = "120",
            change = "",
            resource = Res.drawable.ic_active_card
        )
    }
}

@Composable
fun StatCard(title: String, value: String, change: String, resource: DrawableResource) {
    Column(
        modifier = Modifier
            .background(Color.White, shape = RoundedCornerShape(8.dp))
            .padding(start = 45.dp, end = 45.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.padding(top = 20.dp, bottom = 20.dp)
        ) {
            Icon(
                painter = painterResource(resource),
                contentDescription = null,
                tint = BlueApp,
                modifier = Modifier.padding(end = 20.dp)
            )
            Column {
                Text(text = title, fontSize = 16.sp, color = Color.Gray)
                Spacer(modifier = Modifier.height(8.dp))
                Text(text = value, fontSize = 32.sp, fontWeight = FontWeight.Bold)
                // TODO: add CHANGE when api is ready
//                if (change.isNotEmpty()) {
//                    Text(
//                        text = change,
//                        fontSize = 16.sp,
//                        color = if (change.startsWith("+")) Color.Green else Color.Red
//                    )
//                }
            }
        }
    }
}

@Composable
fun VerticalDivider() {
    Box(
        modifier = Modifier
            .fillMaxHeight()
            .width(1.dp)
            .padding(top = 20.dp, bottom = 20.dp)
            .background(EmployeesBorder)
    )
}

@Composable
fun EmployeeList(
    allEmployees: List<User>,
    employeeList: List<User>,
    sortBy: MutableState<String?>,
    searchText: MutableState<String>,
    currentPage: MutableState<Int>,
    itemsPerPage: Int,
    showFilterDialog: MutableState<Boolean>,
    deleteEmployeeById: (String) -> Unit,
    saveSelectedEmployee: (String) -> Unit
) {
    val selectedEmployees = remember { mutableStateListOf<String>() }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(start = 70.dp, end = 70.dp, bottom = 20.dp)
            .background(Color.White)
            .border(width = 1.dp, color = EmployeesBorder, shape = RoundedCornerShape(4.dp))
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(20.dp)
        ) {
            Column(modifier = Modifier.weight(2f)) {
                Text(
                    text = stringResource(Res.string.all_employees),
                    fontSize = 24.sp,
                    fontWeight = FontWeight.Bold
                )
                if (selectedEmployees.isNotEmpty()) {
                    Row(
                        modifier = Modifier,
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = stringResource(Res.string.unselect_all),
                            fontSize = 14.sp,
                            modifier = Modifier
                                .padding(8.dp)
                                .clickable {
                                    selectedEmployees.clear()
                                }
                        )
                        Text(
                            text = stringResource(Res.string.edit),
                            fontSize = 14.sp,
                            modifier = Modifier
                                .padding(8.dp)
                                .clickable {
                                    // Handle edit logic
                                }
                        )
                        Text(
                            text = stringResource(Res.string.export_list),
                            fontSize = 14.sp,
                            modifier = Modifier
                                .padding(8.dp)
                                .clickable {
                                    // Handle export logic
                                }
                        )
                        Text(
                            text = stringResource(Res.string.delete),
                            fontSize = 14.sp,
                            color = Color.Red,
                            modifier = Modifier
                                .padding(8.dp)
                                .clickable {
                                    for (id in selectedEmployees) {
                                        deleteEmployeeById(id)
                                    }
                                }
                        )
                    }
                } else {
                    Spacer(modifier = Modifier.height(20.dp))
                }
            }

            Row(modifier = Modifier.weight(1f)) {
                val fieldWidth = 300.dp // Ensure consistent width for search and dropdown
                TextField(
                    value = searchText.value,
                    onValueChange = { searchText.value = it },
                    placeholder = { Text(text = stringResource(Res.string.search), fontSize = 12.sp) },
                    modifier = Modifier
                        .width(fieldWidth)
                        .height(55.dp)
                        .border(
                            width = 1.dp,
                            color = BorderColor,
                            shape = RoundedCornerShape(8.dp)
                        ),
                    singleLine = true,
                    leadingIcon = {
                        Image(
                            painter = painterResource(Res.drawable.ic_search),
                            contentDescription = "Search"
                        )
                    },
                    colors = TextFieldDefaults.textFieldColors(
                        backgroundColor = Color.White,
                        focusedIndicatorColor = Color.Transparent,
                        unfocusedIndicatorColor = Color.Transparent
                    )
                )
            }
            Spacer(modifier = Modifier.width(16.dp))

            OutlinedButton(
                modifier = Modifier.height(55.dp),
                border = BorderStroke(1.dp, BorderColor),
                shape = RoundedCornerShape(8.dp),
                onClick = {
                    showFilterDialog.value = true
                }) {
                Text(
                    text = stringResource(Res.string.filter),
                    fontSize = 14.sp,
                    modifier = Modifier.padding(end = 20.dp)
                )
                Image(
                    painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                    contentDescription = "Filter"
                )
            }
            Spacer(modifier = Modifier.width(16.dp))

            // TODO: get options
            CustomDropdown(
                items = listOf("Newest", "Oldest"),
                selectedValue = sortBy.value ?: "Newest",
                onValueSelected = { sortBy.value = it },
                displayText = { it },
                placeholderColor = BorderColor,
                textColor = Color.Black,
                modifier = Modifier.height(55.dp)
            )
        }

        EmployeeListScreen(
            allEmployees = allEmployees,
            employees = employeeList,
            currentPage,
            itemsPerPage,
            selectedEmployees = selectedEmployees,
            saveSelectedEmployee = {id -> saveSelectedEmployee(id) }
        )
    }
}

@Composable
fun EmployeeListScreen(
    allEmployees: List<User>,
    employees: List<User>,
    currentPage: MutableState<Int>,
    itemsPerPage: Int,
    selectedEmployees: SnapshotStateList<String>,
    saveSelectedEmployee: (String) -> Unit
) {
    val allSelected = employees.all { selectedEmployees.contains(it._id) }

    Column(modifier = Modifier.fillMaxSize()) {
        // Employee list with LazyColumn
        LazyColumn(
            modifier = Modifier.weight(1f)
        ) {
            // Add the header as the first item
            item {
                EmployeeRowHeader(
                    allSelected = allSelected,
                    onSelectAllChange = { selectAll ->
                        if (selectAll) {
                            selectedEmployees.addAll(employees.mapNotNull { it._id })
                        } else {
                            selectedEmployees.removeAll(employees.mapNotNull { it._id })
                        }
                    }
                )
            }

            // Add employee rows
            items(employees) { employee ->
                EmployeeRow(
                    employee = employee,
                    isSelected = selectedEmployees.contains(employee._id),
                    onSelectionChange = { isSelected ->
                        if (isSelected) {
                            selectedEmployees.add(employee._id!!)
                        } else {
                            selectedEmployees.remove(employee._id)
                        }
                    },
                    modifier = Modifier.clickable {
                        saveSelectedEmployee(employee._id!!)
                    }
                )
            }
        }

        // Pagination controls
        Pagination(
            employeeList = allEmployees,
            currentPage = currentPage.value,
            itemsPerPage = itemsPerPage,
            onPageChange = { page -> currentPage.value = page }
        )
    }
}


@Composable
fun Pagination(
    employeeList: List<User>,
    onPageChange: (Int) -> Unit,
    currentPage: Int,
    itemsPerPage: Int = 6
) {
    val totalPages =
        (employeeList.size + itemsPerPage - 1) / itemsPerPage // Calculate total pages

    // Pagination Logic Adjustment for Small Lists
    val visiblePages = mutableListOf<Int>()
    if (totalPages <= 3) {
        for (page in 1..totalPages) visiblePages.add(page) // Show 1, 1 2, or 1 2 3
    } else {
        // Use the dynamic logic for larger lists
        when {
            // Start (first few pages)
            currentPage <= 3 -> {
                visiblePages.addAll(1..3)
                visiblePages.add(-1) // Ellipsis at 4th place
                visiblePages.add(totalPages)
            }

            // Middle Pages
            currentPage in 4..(totalPages - 3) -> {
                visiblePages.add(currentPage - 1)
                visiblePages.add(currentPage)
                visiblePages.add(currentPage + 1)
                visiblePages.add(-1) // Ellipsis at 4th place
                visiblePages.add(totalPages)
            }

            // Near the End
            else -> {
                visiblePages.add(1)
                visiblePages.add(-1) // Ellipsis at 2nd place
                visiblePages.addAll((totalPages - 2)..totalPages)
            }
        }
    }

    val startIndex = (currentPage - 1) * itemsPerPage + 1
    val endIndex = (currentPage * itemsPerPage).coerceAtMost(employeeList.size)


    // Render Pagination
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 16.dp),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            fontSize = 14.sp,
            text = "$startIndex - $endIndex of ${employeeList.size}",
            color = FooterText,
            modifier = Modifier.align(Alignment.CenterVertically).padding(start = 15.dp)
        )
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 16.dp, horizontal = 40.dp),
            horizontalArrangement = Arrangement.End,
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Previous Page Button
            Box(
                modifier = Modifier
                    .size(30.dp)
                    .clip(RoundedCornerShape(8.dp))
                    .background(UnselectedPage)
                    .clickable(enabled = currentPage > 1) { onPageChange(currentPage - 1) },
                contentAlignment = Alignment.Center
            ) {
                Text(
                    fontSize = 12.sp,
                    text = "<",
                    color = Color.Gray,
                    fontWeight = FontWeight.Bold
                )
            }

            Spacer(modifier = Modifier.width(8.dp))

            visiblePages.distinct().forEachIndexed { index, page ->
                if (page == -1) {
                    // Ellipsis
                    Box(
                        modifier = Modifier
                            .size(30.dp)
                            .clip(RoundedCornerShape(8.dp)),
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            text = "...",
                            fontSize = 12.sp,
                            color = Color.Gray,
                            fontWeight = FontWeight.Normal
                        )
                    }
                } else {
                    val isSelected = currentPage == page
                    Box(
                        modifier = Modifier
                            .size(30.dp)
                            .clip(RoundedCornerShape(8.dp))
                            .background(if (isSelected) BlueApp else UnselectedPage)
                            .clickable { onPageChange(page) },
                        contentAlignment = Alignment.Center
                    ) {
                        Text(
                            fontSize = 12.sp,
                            text = page.toString(),
                            color = if (isSelected) Color.White else Color.Black,
                            fontWeight = if (isSelected) FontWeight.Bold else FontWeight.Normal
                        )
                    }
                }
                Spacer(modifier = Modifier.width(8.dp))
            }

            // Next Page Button
            Box(
                modifier = Modifier
                    .size(30.dp)
                    .clip(RoundedCornerShape(8.dp))
                    .background(UnselectedPage)
                    .clickable(enabled = currentPage < totalPages) {
                        onPageChange(
                            currentPage + 1
                        )
                    },
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = ">",
                    color = Color.Gray,
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }
}


@Composable
fun EmployeeRowHeader(
    allSelected: Boolean,
    onSelectAllChange: (Boolean) -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .background(Color.White)
            .drawBehind {
                val strokeWidth = 1.dp.toPx()
                drawLine(
                    color = com.hyperether.goodjob.theme.Divider,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(start = 30.dp, end = 30.dp, bottom = 15.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Checkbox(
            checked = allSelected,
            onCheckedChange = onSelectAllChange,
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = com.hyperether.goodjob.theme.Divider
            )
        )
        Text(
            text = stringResource(Res.string.name),
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
            color = HeaderText
        )
        Text(
            text = stringResource(Res.string.team),
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
            color = HeaderText
        )
        Text(
            text = stringResource(Res.string.phone_number),
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
            color = HeaderText
        )
        Text(
            text = stringResource(Res.string.email),
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
            color = HeaderText
        )
        Text(
            text = stringResource(Res.string.city),
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
            color = HeaderText
        )
        Text(
            text = stringResource(Res.string.status),
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
            color = HeaderText
        )
    }
}

@Composable
fun EmployeeRow(
    employee: User,
    isSelected: Boolean,
    onSelectionChange: (Boolean) -> Unit,
    modifier: Modifier
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .background(Color.White)
            .padding(start = 30.dp, end = 30.dp)
            .drawBehind {
                val strokeWidth = 1.dp.toPx()
                drawLine(
                    color = com.hyperether.goodjob.theme.Divider,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(bottom = 15.dp, top = 15.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Checkbox(
            checked = isSelected,
            onCheckedChange = onSelectionChange,
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = com.hyperether.goodjob.theme.Divider
            )
        )
        Text(
            text = employee.fullName ?: "",
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
            color = BlueApp,
            fontWeight = FontWeight.Bold
        )
        Text(
            text = employee.teams?.get(0)?.value ?: "",
            modifier = Modifier.weight(1f),
            fontSize = 14.sp
        )
        Text(
            text = employee.phone ?: "",
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
        )
        Text(
            text = employee.email ?: "",
            modifier = Modifier.weight(1f),
            fontSize = 14.sp,
        )
        Text(
            text = employee.city ?: "",
            modifier = Modifier.weight(1f),
            fontSize = 14.sp
        )
        // Outlined status text
        Box(
            modifier = Modifier
                .weight(1f),
            contentAlignment = Alignment.Center
        ) {
            Box(
                modifier = Modifier
                    .padding(horizontal = 10.dp),
            )
            Box(
                modifier = Modifier
                    .width(100.dp)
                    .border(
                        width = 2.dp,
                        color = if (employee.status == "Active") GreenActiveBorder else RedInactive,
                        shape = RoundedCornerShape(8.dp)
                    )
                    .padding(horizontal = 12.dp, vertical = 4.dp),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    text = if (employee.status.isNullOrEmpty()) "Inactive" else employee.status!!,
                    color = if (employee.status == "Active") GreenActive else RedInactive,
                    fontSize = 14.sp
                )
            }
        }
    }
}

@Composable
fun MainTitle(
    title: String,
    onBackClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .background(Color.White)
            .fillMaxWidth()
            .drawBehind {
                val strokeWidth = 1.dp.toPx()
                drawLine(
                    color = BorderColor,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(start = 70.dp, end = 70.dp, top = 20.dp, bottom = 20.dp),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Image(
            painter = painterResource(Res.drawable.ic_back),
            contentDescription = null,
            modifier = Modifier
                .padding(end = 30.dp)
                .clickable {
                    onBackClick()
                }
        )
        Text(
            text = title,
            fontSize = 32.sp,
            fontWeight = FontWeight.Bold,
            lineHeight = 35.sp,
        )
    }
}

@Composable
fun CustomDisplayRowEmployee(
    painterResource: DrawableResource,
    labelText: String,
    displayText: String,
    modifier: Modifier = Modifier,
    textColor: Color,
    labelColor: Color = EmployeeDetailsText
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .padding(vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        // Icon and Label
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(painterResource),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = labelColor,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }
        // Display Text
        Box(
            contentAlignment = Alignment.TopEnd,
            modifier = Modifier
                .weight(2f)
                .padding(6.dp)
        ) {
            Text(
                text = displayText,
                color = textColor,
                fontSize = 14.sp,
                fontWeight = FontWeight.Bold
            )
        }
    }
}

@Composable
fun ProfilePictureSection(
    isEditView: MutableState<Boolean>,
    btnText: String,
    onUploadClick: () -> Unit
) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
    ) {
        Box(
            modifier = Modifier
                .padding(10.dp),
            contentAlignment = Alignment.Center
        ) {
            Image(
                painterResource(Res.drawable.avatar_female),
                contentDescription = null
            )
        }
        Spacer(modifier = Modifier.height(10.dp))
        if (isEditView.value) {
            OutlinedButton(
                border = BorderStroke(1.dp, BlueApp),
                shape = RoundedCornerShape(4.dp),
                onClick = {
                    onUploadClick()
                }) {
                Text(
                    text = btnText,
                    color = BlueApp,
                    fontSize = 14.sp
                )
            }
        }
    }
}

@Composable
fun WorkSchedulerInput(
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_work_scheduler),
                contentDescription = null,
            )
            Text(
                text = stringResource(Res.string.work_schedule),
                color = EmployeeDetailsText,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }
        Row(
            modifier = Modifier
                .weight(2f)
                .padding(end = 16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = stringResource(Res.string.set_weekly_hours),
                color = BlueApp,
                fontSize = 14.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier
                    .fillMaxWidth()
                    .clickable { /* TODO: */ }
            )
        }
    }
}

@Composable
fun UpcomingLeave() {
    var expanded = remember { mutableStateOf(false) }

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(Res.drawable.ic_leave),
                contentDescription = null
            )
            Text(
                text = stringResource(Res.string.upcoming_leaves),
                color = EmployeeDetailsText,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }

        Box(
            modifier = Modifier
                .weight(2f)
                .border(
                    width = 1.dp,
                    color = AddEmployeeInputColor,
                    shape = RoundedCornerShape(4.dp)
                )
                .clickable { expanded.value = true }
        ) {
            Text(
                text = stringResource(Res.string.select_dates),
                color = EmployeeDetailsText,
                fontSize = 14.sp,
                modifier = Modifier.padding(12.dp)
            )
            Icon(
                painter = painterResource(Res.drawable.ic_calendar),
                contentDescription = null,
                tint = Color(0xFF676767),
                modifier = Modifier
                    .align(Alignment.CenterEnd)
                    .padding(end = 20.dp)
            )

            // TODO: add calendar picker
        }
    }
}

@Composable
fun SaveOrCancelBtnVertical(
    isEditView: MutableState<Boolean>
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(vertical = 16.dp) // Add some space before buttons
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            verticalArrangement = Arrangement.spacedBy(16.dp) // Space between buttons
        ) {
            Button(
                onClick = {
                    // TODO: edit
                },
                modifier = Modifier
                    .fillMaxWidth(),
                shape = RoundedCornerShape(56.dp),
                colors = ButtonDefaults.buttonColors(BlueApp),
            ) {
                Text(
                    modifier = Modifier.padding(vertical = 8.dp),
                    text = "Save Changes",
                    color = Color.White,
                )
            }
            androidx.compose.material.OutlinedButton(
                onClick = {
                    isEditView.value = !isEditView.value
                },
                border = BorderStroke(1.dp, EmployeeDetailsText),
                modifier = Modifier
                    .fillMaxWidth(),
                shape = RoundedCornerShape(25.dp),
                colors = ButtonDefaults.outlinedButtonColors(
                    backgroundColor = Color.White,
                    contentColor = Color.Black
                ),
            ) {
                Text(
                    modifier = Modifier.padding(vertical = 8.dp),
                    text = stringResource(Res.string.cancel),
                    color = Color.Black,
                )
            }
        }
    }
}

@Composable
fun DetailsCards(
    modifier: Modifier,
    selectedCard: MutableState<String>
) {
    Column(
        modifier = modifier
            .padding(top = 20.dp)
    ) {
        // First Text
        Text(
            text = "Details",
            fontSize = 16.sp,
            color = if (selectedCard.value == "Details") BlueApp else Color.Gray,
            modifier = Modifier
                .background(if (selectedCard.value == "Details") SelectedCardBg else Color.White)
                .fillMaxWidth()
                .clickable { selectedCard.value = "Details" }
                .drawBehind {
                    if (selectedCard.value == "Details") {
                        drawLine(
                            color = BlueApp,
                            start = Offset(0f, 0f),
                            end = Offset(0f, size.height),
                            strokeWidth = 2f
                        )
                    }
                }
                .padding(16.dp)
        )

        Text(
            text = "Daily Activities",
            fontSize = 16.sp,
            color = if (selectedCard.value == "Daily activities") BlueApp else Color.Gray,
            modifier = Modifier
                .background(if (selectedCard.value == "Daily activities") SelectedCardBg else Color.White)
                .fillMaxWidth()
                .clickable { selectedCard.value = "Daily activities" }
                .drawBehind {
                    if (selectedCard.value == "Daily activities") {
                        drawLine(
                            color = BlueApp,
                            start = Offset(0f, 0f),
                            end = Offset(0f, size.height),
                            strokeWidth = 2f
                        )
                    }
                }
                .padding(16.dp)
        )
    }
}