package com.hyperether.goodjob.scenes.landing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.toSize
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all_rights_reserved
import com.hyperether.goodjob.resources.and_we_will
import com.hyperether.goodjob.resources.effortless_efficiency
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.free
import com.hyperether.goodjob.resources.free_hundred
import com.hyperether.goodjob.resources.ic_check_white
import com.hyperether.goodjob.resources.its
import com.hyperether.goodjob.resources.landing_img
import com.hyperether.goodjob.resources.login_register
import com.hyperether.goodjob.resources.logo_dark_landing
import com.hyperether.goodjob.resources.managers
import com.hyperether.goodjob.resources.optimize_your
import com.hyperether.goodjob.resources.pricing
import com.hyperether.goodjob.resources.privacy_policy
import com.hyperether.goodjob.resources.subscribe
import com.hyperether.goodjob.resources.terms_of_service
import com.hyperether.goodjob.resources.transform
import com.hyperether.goodjob.resources.transparent_flexible
import com.hyperether.goodjob.resources.users
import com.hyperether.goodjob.resources.whether
import com.hyperether.goodjob.scenes.components.RoundedTextFieldWithButton
import com.hyperether.goodjob.scenes.components.SubscriptionToggle
import com.hyperether.goodjob.scenes.pricing.PricingOption
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.LandingPageBg
import com.hyperether.goodjob.theme.LandingPageMainTitle
import com.hyperether.goodjob.theme.LandingPageMobileText
import com.hyperether.goodjob.theme.LandingPageNavColor
import com.hyperether.goodjob.theme.LandingPageSmallTitle
import com.hyperether.goodjob.theme.LandingPageText
import com.hyperether.goodjob.theme.LandingSmallTitleMobile
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun LandingPageMobileView(
    selectedOption: MutableState<String>,
    containerSize: MutableState<Size>,
    plans: List<Plan>,
    enteredEmail: MutableState<String>,
    savePlanLocally: (String) -> Unit,
    continueToRegister: () -> Unit
) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(LandingPageBg)
            .onSizeChanged { size ->
                containerSize.value = size.toSize()
            }
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .verticalScroll(rememberScrollState())
        ) {
            Box(
                contentAlignment = Alignment.TopCenter
            ) {
                Image(
                    painter = painterResource(Res.drawable.landing_img),
                    contentDescription = null,
                    modifier = Modifier
                        .height(Dp(310f))
                        .padding(horizontal = Dp(25f))
                        .fillMaxWidth()
                        .alpha(0.1f),
                    contentScale = ContentScale.Crop
                )
                Column {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(LandingPageNavColor.copy(alpha = 0.9f))
                            .height(Dp(60f))
                            .padding(horizontal = Dp(16f), vertical = Dp(12f)),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(
                            alignment = Alignment.TopStart,
                            painter = painterResource(Res.drawable.logo_dark_landing),
                            contentDescription = "Logo",
                            modifier = Modifier
                                .width(Dp(95f))
                                .height(Dp(25f))
                        )
                        Button(
                            onClick = { continueToRegister() },
                            modifier = Modifier
                                .height(Dp(32f))
                                .width(Dp(123f))
                                .clip(RoundedCornerShape(Dp(56f))),
                            colors = ButtonDefaults.buttonColors(Color.White),
                        ) {
                            Text(
                                text = stringResource(Res.string.login_register),
                                color = BlueApp,
                                fontWeight = FontWeight.Bold,
                                fontSize = Sp(14f),
                                lineHeight = Sp(21f)
                            )
                        }
                    }

                    // Text sections
                    Spacer(modifier = Modifier.height(Dp(45f)))
                    Text(
                        textAlign = TextAlign.Center,
                        modifier = Modifier.fillMaxWidth().padding(horizontal = Dp(16f)),
                        text = stringResource(Res.string.effortless_efficiency),
                        fontSize = Sp(12f),
                        lineHeight = Sp(18f),
                        color = LandingSmallTitleMobile,
                    )
                    Spacer(modifier = Modifier.height(Dp(5f)))
                    Text(
                        textAlign = TextAlign.Center,
                        text = stringResource(Res.string.transform),
                        fontSize = Sp(24f),
                        fontWeight = FontWeight.W700,
                        lineHeight = Sp(36f),
                        color = LandingPageMainTitle,
                        modifier = Modifier.fillMaxWidth().padding(horizontal = Dp(16f))
                    )
                    Spacer(modifier = Modifier.height(Dp(8f)))
                    Text(
                        textAlign = TextAlign.Center,
                        text = stringResource(Res.string.optimize_your),
                        fontSize = Sp(16f),
                        lineHeight = Sp(24f),
                        fontWeight = FontWeight.W400,
                        color = LandingPageMobileText,
                        modifier = Modifier.fillMaxWidth().padding(horizontal = Dp(16f))
                    )
                }
            }
            // RoundedTextFieldWithButton
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(horizontal = Dp(16f))
            ) {
                RoundedTextFieldWithButton(
                    isMobileView = true,
                    input = enteredEmail,
                    placeHolder = stringResource(Res.string.enter_your_email),
                    modifier = Modifier.padding(top = Dp(20f)),
                    onSubscribe = { /* TODO */ }
                )

                Spacer(modifier = Modifier.height(Dp(16f)))
                Button(
                    onClick = {
                        // TODO
                    },
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(Dp(40f))
                        .clip(RoundedCornerShape(Dp(56f)))
                        .shadow(Dp(10f)),
                    colors = ButtonDefaults.buttonColors(BlueApp),
                ) {
                    Text(
                        text = stringResource(Res.string.subscribe),
                        color = LandingPageMainTitle,
                        fontSize = Sp(16f),
                        lineHeight = Sp(20f)
                    )
                }
                Spacer(modifier = Modifier.height(Dp(12f)))
                Row(
                    modifier = Modifier.fillMaxWidth()
                        .padding(horizontal = Dp(16f)),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(
                        painter = painterResource(Res.drawable.ic_check_white),
                        contentDescription = "Feature icon",
                        modifier = Modifier
                            .size(Dp(22f))
                    )
                    Spacer(modifier = Modifier.width(Dp(9f)))
                    Text(
                        text = buildAnnotatedString {
                            append(stringResource(Res.string.its))
                            withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
                                append(stringResource(Res.string.free_hundred))
                            }
                            append(stringResource(Res.string.and_we_will))
                        },
                        color = LandingPageText,
                        fontSize = Sp(14f),
                        lineHeight = Sp(21f)
                    )
                }
                Spacer(modifier = Modifier.height(Dp(60f)))
                Text(
                    modifier = Modifier.fillMaxWidth().align(Alignment.CenterHorizontally),
                    text = stringResource(Res.string.pricing),
                    fontSize = Sp(12f),
                    lineHeight = Sp(8f),
                    fontWeight = FontWeight.W700,
                    color = LandingPageSmallTitle,
                    textAlign = TextAlign.Center
                )
                Spacer(modifier = Modifier.height(Dp(3f)))
                Text(
                    modifier = Modifier.fillMaxWidth().align(Alignment.CenterHorizontally),
                    text = stringResource(Res.string.transparent_flexible),
                    fontSize = Sp(24f),
                    color = LandingPageMainTitle,
                    lineHeight = Sp(36f),
                    fontWeight = FontWeight.W900,
                    textAlign = TextAlign.Center,
                )
                Text(
                    modifier = Modifier.fillMaxWidth().align(Alignment.CenterHorizontally),
                    text = stringResource(Res.string.whether),
                    fontSize = Sp(16f),
                    lineHeight = Sp(24f),
                    color = LandingPageMobileText,
                    textAlign = TextAlign.Center
                )
                Spacer(modifier = Modifier.height(Dp(23f)))

                SubscriptionToggle(
                    isDark = true,
                    isMobile = true,
                    selectedOption = selectedOption.value,
                    onOptionSelected = {
                        // TODO: show corresponding prices
                        selectedOption.value = it
                    }
                )

                // Spacer for additional separation
                Spacer(modifier = Modifier.height(Dp(30f)))

                // Pricing Cards Container
                plans.forEach { plan ->
                    PricingOption(
                        isLanding = true,
                        price = if (plan.price == 0.0) stringResource(Res.string.free) else "$${plan.price}",
                        planName = plan.name.orEmpty(),
                        description = plan.description.orEmpty(),
                        features = listOf(
                            plan.storage.orEmpty(),
                            plan.planSupport.orEmpty(),
                            plan.limitManagers.toString() + " " + stringResource(Res.string.managers) + "+" +
                                    plan.limitUsers + " " + stringResource(Res.string.users)
                        ),
                        continueToRegister = {
                            savePlanLocally(plan._id.orEmpty())
                            continueToRegister()
                        },
                        isPopular = plan.isPopular ?: false,
                        width = containerSize.value.width,
                        isMobileView = true
                    )
                    Spacer(modifier = Modifier.height(Dp(32f)))
                }
                Spacer(modifier = Modifier.height(Dp(20f)))
            }

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(LandingPageNavColor)
                    .height(Dp(104f))
                    .padding(horizontal = Dp(22f), vertical = Dp(18f))
            ) {
                Column {
                    Text(
                        text = stringResource(Res.string.all_rights_reserved),
                        fontSize = Sp(14f),
                        lineHeight = Sp(16f),
                        fontWeight = FontWeight.W400,
                        color = LandingPageMainTitle,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.fillMaxWidth()
                    )
                    Spacer(modifier = Modifier.height(Dp(16f)))
                    Row {
                        Text(
                            text = stringResource(Res.string.privacy_policy),
                            fontSize = Sp(15f),
                            lineHeight = Sp(18f),
                            color = LandingPageMainTitle,
                            textAlign = TextAlign.Start,
                            fontWeight = FontWeight.W900,
                            modifier = Modifier.weight(1f)
                        )
                        Text(
                            text = stringResource(Res.string.terms_of_service),
                            fontSize = Sp(15f),
                            lineHeight = Sp(18f),
                            color = LandingPageMainTitle,
                            textAlign = TextAlign.End,
                            fontWeight = FontWeight.W900,
                            modifier = Modifier
                                .weight(1f)
                        )
                    }
                }
            }
        }
    }
}