package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class WorkingHours(
    @SerialName("Monday") val monday: DayHours? = null,
    @SerialName("Tuesday") val tuesday: DayHours? = null,
    @SerialName("Wednesday") val wednesday: DayHours? = null,
    @SerialName("Thursday") val thursday: DayHours? = null,
    @SerialName("Friday") val friday: DayHours? = null,
    @SerialName("Saturday") val saturday: DayHours? = null,
    @SerialName("Sunday") val sunday: DayHours? = null
)