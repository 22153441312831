package com.hyperether.goodjob.scenes.profile

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.model.WorkingHours
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.leave_details
import com.hyperether.goodjob.resources.no_hours_available
import com.hyperether.goodjob.resources.profile_settings
import com.hyperether.goodjob.resources.working_hours
import com.hyperether.goodjob.scenes.employees.EmployeeContactsView
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.DateTimeUtil.formatWorkingHoursText
import com.hyperether.goodjob.util.DateTimeUtil.groupContiguousWorkingHours
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ProfileSettings(navHostController: NavHostController, prefsManager: PrefsManager) {

    Box(modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState()).background(Color.White.copy(alpha = 0.2f))) {

        println("USER" + prefsManager.getUser().toString())
        Column {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(72.dp)
                    .padding(horizontal = 16.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.back_arrow),
                    contentDescription = "",
                    modifier = Modifier
                        .align(Alignment.CenterStart)
                        .clickable {
                            navHostController.popBackStack()
                        }
                )

                Text(
                    text = stringResource(Res.string.profile_settings),
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.align(Alignment.Center)
                )


                Image(
                    painter = painterResource(Res.drawable.edit),
                    contentDescription = "",
                    modifier = Modifier
                        .align(Alignment.CenterEnd)
                        .clickable {
                            navHostController.navigate(Screen.AddNewEmployee.route + "/profileSettings-${prefsManager.getUser()?._id}")
                        }
                )
            }
            Divider(modifier = Modifier.height(2.dp).background(BorderColor.copy(alpha = 0.1f)))
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(top = 24.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                ProfileHeaderView(prefsManager.getUser())
                Spacer(modifier = Modifier.height(16.dp))
                val user = prefsManager.getUser()
                EmployeeContactsView(isProfileView = true, user = user)
                val workingHours: WorkingHours? = user?.workingHours
                val workingHoursText = workingHours?.let {
                    val groupedSchedule = groupContiguousWorkingHours(it)
                    formatWorkingHoursText(groupedSchedule)
                } ?: stringResource(Res.string.no_hours_available)
                Spacer(modifier = Modifier.height(16.dp))
                ProfileDetails(stringResource(Res.string.working_hours), workingHoursText)
                Spacer(modifier = Modifier.height(16.dp))
                ProfileDetails(stringResource(Res.string.leave_details), DateTimeUtil.formatDateRange(user?.upcomingLeave ?: ""))
            }
        }
    }
}