package com.hyperether.goodjob.scenes.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Checkbox
import androidx.compose.material.CheckboxDefaults
import androidx.compose.material.Icon
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.TabRowDefaults.Divider
import androidx.compose.material.Text
import androidx.compose.material.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.em
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.ic_search
import com.hyperether.goodjob.resources.name
import com.hyperether.goodjob.resources.search
import com.hyperether.goodjob.resources.search_to_find
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.unselect_all
import com.hyperether.goodjob.scenes.addNew.DropdownSortBy
import com.hyperether.goodjob.scenes.employees.EmployeeRow
import com.hyperether.goodjob.scenes.employees.EmployeeRowHeader
import com.hyperether.goodjob.scenes.employees.Pagination
import com.hyperether.goodjob.scenes.jobs.JobViewModel
import com.hyperether.goodjob.scenes.employees.TeamItemWeb
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.EmployeesBorder
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.SelectedCardBg
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.StatusColor
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun <T> WebUserListView(
    isEmployeeList: Boolean,
    title: String,
    searchTextTeam: MutableState<String>,
    allItems: List<T>,
    filteredItems: List<T>,
    sortBy: MutableState<String?>,
    searchText: MutableState<String>,
    currentPage: MutableState<Int>,
    itemsPerPage: Int,
    showFilterDialog: MutableState<Boolean>,
    deleteItemById: (String) -> Unit,
    saveSelectedItem: (String) -> Unit,
    teams: List<Team>,
    selectedTeams: MutableState<Map<String, Boolean>>,
    onCheckedChange: (String, Boolean) -> Unit,
    onTeamCreated: (String) -> Unit,
    onDeleteTeamClick: () -> Unit
) {
    val selectedEmployees = remember { mutableStateListOf<String>() }
    var selectedTab by remember { mutableStateOf(0) }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(start = Dp(75f), end = Dp(75f), bottom = Dp(27f))
            .background(Color.White)
            .border(width = Dp(1f), color = EmployeesBorder, shape = RoundedCornerShape(Dp(4f)))
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(
                top = Dp(24f),
                end = Dp(40f),
                start = Dp(24f),
                bottom = Dp(27f)
            )
        ) {
            Row {
                Column(modifier = Modifier.height(Dp(90f)).weight(2f)) {
                    Row {
                        Text(
                            text = title,
                            fontSize = Sp(20f),
                            color = if (selectedTab == 0) BlueApp else Color.LightGray,
                            modifier = Modifier
                                .border(
                                    2.dp,
                                    if (selectedTab == 0) BlueApp else Color.LightGray,
                                    RoundedCornerShape(12.dp)
                                )
                                .padding(horizontal = 16.dp, vertical = 8.dp)
                                .clickable { selectedTab = 0 }
                        )
                        Spacer(modifier = Modifier.width(20.dp))
                        Text(
                            text = stringResource(Res.string.teams),
                            fontSize = Sp(20f),
                            color = if (selectedTab == 1) BlueApp else Color.LightGray,
                            modifier = Modifier
                                .border(
                                    2.dp,
                                    if (selectedTab == 1) BlueApp else Color.LightGray,
                                    RoundedCornerShape(12.dp)
                                )
                                .padding(horizontal = 16.dp, vertical = 8.dp)
                                .clickable { selectedTab = 1 }
                        )
                    }
                    if (selectedTab == 1) {
                        Text(
                            text = stringResource(Res.string.delete),
                            fontSize = Sp(14f),
                            fontWeight = FontWeight.W400,
                            color = Color.Red,
                            modifier = Modifier.padding(top = Dp(25f)).clickable {
                                onDeleteTeamClick()
                            }
                        )
                    }
                    if (selectedEmployees.isNotEmpty() && selectedTab == 0) {
                        Row(
                            modifier = Modifier.padding(top = Dp(16f)),
                            horizontalArrangement = Arrangement.SpaceBetween,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Text(
                                text = stringResource(Res.string.unselect_all),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        selectedEmployees.clear()
                                    }
                            )
                            Text(
                                text = stringResource(Res.string.edit),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        // Handle edit logic
                                    }
                            )
                            Text(
                                text = stringResource(Res.string.export_list),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        // Handle export logic
                                    }
                            )
                            Text(
                                text = stringResource(Res.string.delete),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                color = Color.Red,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        for (id in selectedEmployees) {
                                            deleteItemById(id)
                                        }
                                    }
                            )
                        }
                    } else {
                        Spacer(modifier = Modifier.height(Dp(32f)))
                    }
                }

                if (selectedTab == 0) {
                    OutlinedTextField(
                        textStyle = TextStyle(fontSize = Sp(12f)),
                        value = searchText.value,
                        onValueChange = { searchText.value = it },
                        placeholder = {
                            Text(
                                text = stringResource(Res.string.search),
                                fontSize = Sp(12f),
                                fontWeight = FontWeight.W400
                            )
                        },
                        modifier = Modifier
                            .width(Dp(226f))
                            .height(Dp(53f))
                            .border(
                                width = Dp(1f),
                                color = BorderColor,
                                shape = RoundedCornerShape(Dp(8f))
                            ),
                        singleLine = true,
                        leadingIcon = {
                            Image(
                                modifier = Modifier
                                    .height(Dp(24f))
                                    .width(Dp(24f)),
                                painter = painterResource(Res.drawable.ic_search),
                                contentDescription = "Search"
                            )
                        },
                        colors = TextFieldDefaults.textFieldColors(
                            backgroundColor = Color.White,
                            focusedIndicatorColor = Color.Transparent,
                            unfocusedIndicatorColor = Color.Transparent
                        )
                    )
                    Spacer(modifier = Modifier.width(Dp(16f)))

                    Row(
                        modifier = Modifier
                            .height(Dp(55f)).width(Dp(100f))
                            .border(
                                width = Dp(1f),
                                color = BorderColor,
                                shape = RoundedCornerShape(Dp(8f))
                            )
                            .clickable { showFilterDialog.value = true },
                        horizontalArrangement = Arrangement.Center,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = stringResource(Res.string.filter),
                            fontSize = Sp(14f),
                            fontWeight = FontWeight.W400,
                        )
                        Image(
                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                            contentDescription = "Filter",
                            modifier = Modifier.width(Dp(24f)).height(Dp(24f))
                        )
                    }
                    Spacer(modifier = Modifier.width(Dp(16f)))

                    // TODO: get options
                    DropdownSortBy(
                        shouldAppendSortBy = true,
                        items = listOf("Newest", "Oldest"),
                        selectedValue = sortBy.value ?: "Newest",
                        onValueSelected = { sortBy.value = it },
                        displayText = { it },
                        placeholderColor = BorderColor,
                        textColor = Color.Black,
                        modifier = Modifier.height(Dp(55f))
                    )
                } else {
                    OutlinedTextField(
                        textStyle = TextStyle(fontSize = Sp(12f)),
                        value = searchTextTeam.value,
                        onValueChange = { searchTextTeam.value = it },
                        placeholder = {
                            Text(
                                text = stringResource(Res.string.search_to_find),
                                fontSize = Sp(12f),
                                fontWeight = FontWeight.W400
                            )
                        },
                        modifier = Modifier
                            .width(Dp(226f))
                            .height(Dp(53f))
                            .onKeyEvent { event ->
                                if (event.type == KeyEventType.KeyUp && event.key == Key.Enter) {
                                    if (searchTextTeam.value.isNotEmpty()) {
                                        onTeamCreated(searchTextTeam.value)
                                    }
                                    true
                                } else {
                                    false
                                }
                            }
                            .border(
                                width = Dp(1f),
                                color = BorderColor,
                                shape = RoundedCornerShape(Dp(8f))
                            ),
                        singleLine = true,
                        leadingIcon = {
                            Image(
                                modifier = Modifier
                                    .height(Dp(24f))
                                    .width(Dp(24f)),
                                painter = painterResource(Res.drawable.ic_search),
                                contentDescription = "Search"
                            )
                        },
                        keyboardOptions = KeyboardOptions.Default.copy(
                            imeAction = ImeAction.Done
                        ),
                        keyboardActions = KeyboardActions(
                            onDone = {
                                if (searchTextTeam.value.isNotEmpty()) {
                                    onTeamCreated(searchTextTeam.value)
                                }
                            }
                        ),
                        colors = TextFieldDefaults.textFieldColors(
                            backgroundColor = Color.White,
                            focusedIndicatorColor = Color.Transparent,
                            unfocusedIndicatorColor = Color.Transparent
                        )
                    )
                }
            }
        }
        if (selectedTab == 1) {
            val filteredTeams = if (searchTextTeam.value.isNotBlank()) {
                teams.filter { team ->
                    team.name?.contains(searchTextTeam.value, ignoreCase = true) == true
                }
            } else {
                teams
            }
            val allSelected = selectedTeams.value.values.all { it } && selectedTeams.value.isNotEmpty()

            Column(
                modifier = Modifier.fillMaxSize()
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(start = 35.dp, end = 8.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Checkbox(
                        checked = allSelected,
                        onCheckedChange = { isChecked ->
                            selectedTeams.value = teams
                                .mapNotNull { team -> team._id?.let { it to isChecked } }
                                .toMap()
                        },
                        colors = CheckboxDefaults.colors(
                            checkedColor = BlueApp,
                            uncheckedColor = Color.Gray,
                            checkmarkColor = Color.White
                        )
                    )
                    Spacer(modifier = Modifier.width(25.dp))
                    Text(
                        text = stringResource(Res.string.name),
                        modifier = Modifier.weight(1f)
                    )

                }
                Divider(
                    color = Color.LightGray,
                    thickness = Dp(1f),
                    modifier = Modifier.fillMaxWidth()
                )
                val startIndex = (currentPage.value - 1) * itemsPerPage
                val endIndex = (startIndex + itemsPerPage).coerceAtMost(filteredTeams.size)
                val paginatedTeams = filteredTeams.subList(startIndex, endIndex)
                LazyColumn(
                    modifier = Modifier.fillMaxWidth()
                        .height(400.dp),
                    userScrollEnabled = false
                ) {
                    if (paginatedTeams.isNotEmpty()) {
                        items(paginatedTeams) { team ->
                            TeamItemWeb(
                                text = team.name.orEmpty(),
                                isChecked = selectedTeams.value[team._id] == true,
                                onCheckedChange = { isChecked ->
                                    team._id?.let { onCheckedChange(it, isChecked) }
                                }
                            )
                            Divider(
                                color = Color.LightGray,
                                thickness = Dp(1f),
                                modifier = Modifier.fillMaxWidth()
                                    .padding(start = 50.dp, end = 50.dp)
                            )
                        }
                    }
                }
                Pagination(
                    list = teams,
                    currentPage = currentPage.value,
                    itemsPerPage = itemsPerPage,
                    onPageChange = { page -> currentPage.value = page}
                )
            }
        }

        if (selectedTab == 0) {
            if (isEmployeeList) {
                GenericListScreen(
                    allItems = allItems,
                    items = filteredItems,
                    currentPage = currentPage,
                    itemsPerPage = itemsPerPage,
                    selectedItems = selectedEmployees,
                    saveSelectedItem = { itemId -> saveSelectedItem(itemId) },
                    getItemId = { (it as? User)?._id },
                    ItemRowHeader = { allSelected, onSelectAllChange ->
                        EmployeeRowHeader(
                            allSelected = allSelected,
                            onSelectAllChange = onSelectAllChange
                        )
                    },
                    ItemRow = { item, isSelected, onSelectionChange, modifier ->
                        EmployeeRow(
                            employee = item as User,
                            isSelected = isSelected,
                            onSelectionChange = onSelectionChange,
                            modifier = modifier
                        )
                    },
                    PaginationControls = { allItems, currentPage, itemsPerPage, onPageChange ->
                        Pagination(
                            list = allItems,
                            currentPage = currentPage,
                            itemsPerPage = itemsPerPage,
                            onPageChange = onPageChange
                        )
                    }
                )
            } else {
                // TODO: FOR CONTACTS
                GenericListScreen(
                    allItems = allItems,
                    items = filteredItems,
                    currentPage = currentPage,
                    itemsPerPage = itemsPerPage,
                    selectedItems = selectedEmployees,
                    saveSelectedItem = { itemId -> saveSelectedItem(itemId) },
                    getItemId = { (it as? Contact)?.id },
                    ItemRowHeader = { allSelected, onSelectAllChange ->
                        EmployeeRowHeader(
                            allSelected = allSelected,
                            onSelectAllChange = onSelectAllChange
                        )
                    },
                    ItemRow = { item, isSelected, onSelectionChange, modifier ->
//                    EmployeeRow(
//                        employee = item as Contact,
//                        isSelected = isSelected,
//                        onSelectionChange = onSelectionChange,
//                        modifier = modifier
//                    )
                    },
                    PaginationControls = { allItems, currentPage, itemsPerPage, onPageChange ->
                        Pagination(
                            list = allItems,
                            currentPage = currentPage,
                            itemsPerPage = itemsPerPage,
                            onPageChange = onPageChange
                        )
                    }
                )
            }
        }
    }
}

@Composable
fun <T> GenericListScreen(
    allItems: List<T>,
    items: List<T>,
    currentPage: MutableState<Int>,
    itemsPerPage: Int,
    selectedItems: SnapshotStateList<String>,
    saveSelectedItem: (String) -> Unit,
    getItemId: (T) -> String?,
    ItemRowHeader: @Composable (Boolean, (Boolean) -> Unit) -> Unit,
    ItemRow: @Composable (T, Boolean, (Boolean) -> Unit, Modifier) -> Unit,
    PaginationControls: @Composable (List<T>, Int, Int, (Int) -> Unit) -> Unit
) {
    val allSelected = items.all { getItemId(it)?.let { id -> selectedItems.contains(id) } == true }

    Column(modifier = Modifier.fillMaxSize()) {
        // Item list with LazyColumn
        LazyColumn(
            modifier = Modifier.weight(1f)
        ) {
            // Add the header as the first item
            item {
                ItemRowHeader(
                    allSelected
                ) { selectAll ->
                    if (selectAll) {
                        selectedItems.addAll(items.mapNotNull { getItemId(it) })
                    } else {
                        selectedItems.removeAll(items.mapNotNull { getItemId(it) })
                    }
                }
            }

            // Add item rows
            items(items.takeIf { it.isNotEmpty() } ?: emptyList()) { item ->
                val itemId = getItemId(item)
                if (itemId != null) {
                    ItemRow(
                        item,
                        selectedItems.contains(itemId),
                        { isSelected ->
                            if (isSelected) {
                                selectedItems.add(itemId)
                            } else {
                                selectedItems.remove(itemId)
                            }
                        },
                        Modifier.clickable {
                            saveSelectedItem(itemId)
                        }
                    )
                }
            }
        }

        // Pagination controls
        PaginationControls(
            allItems,
            currentPage.value,
            itemsPerPage
        ) { page -> currentPage.value = page }
    }
}

@Composable
fun DetailsCards(
    titles: List<String>,
    modifier: Modifier,
    selectedCard: MutableState<String>
) {
    Column(
        modifier = modifier
            .padding(top = Dp(10f))
    ) {
        for (title in titles) {
            Text(
                text = title,
                fontSize = Sp(16f),
                fontWeight = if (selectedCard.value == title) FontWeight.W700 else FontWeight.W400,
                color = if (selectedCard.value == title) BlueApp else Color.Gray,
                modifier = modifier
                    .background(if (selectedCard.value == title) SelectedCardBg else Color.White)
                    .clickable { selectedCard.value = title }
                    .drawBehind {
                        if (selectedCard.value == title) {
                            drawLine(
                                color = BlueApp,
                                start = Offset(0f, 0f),
                                end = Offset(0f, size.height),
                                strokeWidth = 2f
                            )
                        }
                    }
                    .padding(Dp(14f))
            )
        }
    }
}

@Composable
fun EditToggleButton(
    isEditView: MutableState<Boolean>
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.clickable {
            isEditView.value = !isEditView.value
        }
    ) {
        if (!isEditView.value) {
            Icon(
                painter = painterResource(Res.drawable.edit),
                contentDescription = stringResource(Res.string.edit),
                tint = Color(0xFF515151),
                modifier = Modifier.size(Dp(24f))
            )
            Spacer(modifier = Modifier.width(Dp(4f)))
        }
        Text(
            text = if (isEditView.value) stringResource(Res.string.cancel) else stringResource(
                Res.string.edit
            ),
            color = if (isEditView.value) Color.Red else Color(0xFF515151),
            fontSize = Sp(18f)
        )
    }
}

@Composable
fun JobHeader(createdAt: String) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .requiredHeight(Dp(42f))
            .background(color = Color.White)
            .drawBehind {
                val strokeWidth = Dp(1f).toPx()
                drawLine(
                    color = RegisterBorder,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(horizontal = Dp(23f), vertical = Dp(10f))
    ) {
        Text(
            text = createdAt,
            color = Color(0xff323232),
            lineHeight = 1.71.em,
            style = TextStyle(
                fontSize = Sp(14f),
                fontWeight = FontWeight.Bold
            ),
            modifier = Modifier
                .align(alignment = Alignment.TopStart)
                .wrapContentHeight(align = Alignment.Bottom)
                .fillMaxWidth()
        )
    }
    Spacer(modifier = Modifier.height(Dp(8f)))
}

@Composable
fun JobListGroupedByDate(
    isJobList: Boolean = false,
    viewModel: JobViewModel?,
    jobs: List<Job>,
    navHostController: NavHostController,
    location: String = "",
    searchQuery: MutableState<String>
) {
    // Group jobs by their date (ignoring the time)
    val groupedJobs = jobs.groupBy { job ->
        job.createdAt?.let { DateTimeUtil.extractDate(it) } ?: "Unknown Date"
    }


    Column(modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState())) {
        groupedJobs.forEach { (date, jobsForDate) ->
            val formattedDate = if (date is LocalDate) {
                DateTimeUtil.formatDateForDisplay(date)
            } else {
                date.toString()
            }
            val filteredJobsSearch = if (searchQuery.value.isNotBlank()) {
                jobsForDate.filter { job ->
                    job.jobTitle?.contains(searchQuery.value, ignoreCase = true) == true ||
                            job.status?.contains(
                                searchQuery.value,
                                ignoreCase = true
                            ) == true
                }
            } else {
                jobsForDate
            }

            // Display the header for this date
            if (filteredJobsSearch.isNotEmpty()) {
                JobHeader(createdAt = formattedDate)

                // Display all jobs under the same date header
                filteredJobsSearch.forEachIndexed { index, rowItem ->
                    JobListRow(
                        navHostController = navHostController,
                        rowItem = rowItem,
                        isEvenRow = index % 2 == 0,
                        if (isJobList) viewModel?.sites?.get(rowItem.id)?.addressObject?.address ?: "" else location
                    )
                }
            }

            // Add spacing between groups
            Spacer(modifier = Modifier.height(Dp(16f)))
        }
    }
}


@Composable
fun JobListRow(
    navHostController: NavHostController,
    rowItem: Job,
    isEvenRow: Boolean,
    location: String = ""
) {
    val backgroundColor = if (isEvenRow) Color(0xFFF9F9F9) else Color.White
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dp(48f))
            .padding(horizontal = Dp(22f))
            .background(backgroundColor)
            .clip(MaterialTheme.shapes.medium),
        verticalArrangement = Arrangement.Center
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(horizontal = Dp(24f)).clickable {
                navHostController.navigate(Screen.JobDetails.route + "?jobId=${rowItem.id}")
            }
        ) {
            // Row Details
            Text(
                text = rowItem.jobTitle ?: " ",
                color = Color(0xFF34618E),
                fontSize = Sp(16f),
                fontWeight = FontWeight.Bold,
                modifier = Modifier
                    .weight(0.9f)
            )
            Text(
                text = rowItem.contactSiteName ?: " ",
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.9f)
            )
            Text(
                text = location,
                color = Color(0xFF555555),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(1f)
            )
            Text(
                text = rowItem.start_at?.let { start ->
                    rowItem.end_at?.let { end ->
                        "$start - $end"
                    }
                } ?: "",
                color = Color(0xFF555555),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.7f)
            )
            Text(
                text = rowItem.assignees?.employees?.mapNotNull { it?.getName() }
                    ?.joinToString(", ")
                    ?: "",
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.7f)
            )
            Column(
                modifier = Modifier
                    .weight(0.5f)
            ) {
                Box(
                    modifier = Modifier
                        .size(Dp(16f))
                        .clip(CircleShape)
                        .align(Alignment.CenterHorizontally)
                        .background(StatusColor().getStatusColor(rowItem.status ?: "On hold"))
                )
            }
        }
    }
}

@Composable
fun JobListGroupedByDateWithCheckboxes(
    isJobList: Boolean = false,
    viewModel: JobViewModel?,
    jobs: List<Job>,
    navHostController: NavHostController,
    location: String = "",
    searchQuery: MutableState<String>,
    isAllSelected: Boolean,
    onAllSelectedChange: (Boolean) -> Unit,
    selectedJobs: List<Job>,
    onJobSelectedChange: (Job, Boolean) -> Unit
) {
    val groupedJobs = jobs.groupBy { job ->
        job.createdAt?.let { DateTimeUtil.extractDate(it) } ?: "Unknown Date"
    }

    Column(modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState())) {
        groupedJobs.forEach { (date, jobsForDate) ->
            val formattedDate = if (date is LocalDate) {
                DateTimeUtil.formatDateForDisplay(date)
            } else {
                date.toString()
            }
            val filteredJobsSearch = if (searchQuery.value.isNotBlank()) {
                jobsForDate.filter { job ->
                    job.jobTitle?.contains(searchQuery.value, ignoreCase = true) == true ||
                            job.status?.contains(searchQuery.value, ignoreCase = true) == true
                }
            } else {
                jobsForDate
            }

            if (filteredJobsSearch.isNotEmpty()) {
                JobHeader(createdAt = formattedDate)
                filteredJobsSearch.forEachIndexed { index, rowItem ->
                    JobListRowWithCheckbox(
                        navHostController = navHostController,
                        rowItem = rowItem,
                        isEvenRow = index % 2 == 0,
                        location = if (isJobList) viewModel?.sites?.get(rowItem.id)?.addressObject?.address ?: "" else location,
                        isChecked = selectedJobs.contains(rowItem),
                        onCheckedChange = { isChecked -> rowItem.let { onJobSelectedChange(it, isChecked) } }
                    )
                }
            }
            Spacer(modifier = Modifier.height(Dp(16f)))
        }
    }
}

@Composable
fun JobListRowWithCheckbox(
    navHostController: NavHostController,
    rowItem: Job,
    isEvenRow: Boolean,
    location: String = "",
    isChecked: Boolean,
    onCheckedChange: (Boolean) -> Unit
) {
    val backgroundColor = if (isEvenRow) Color(0xFFF9F9F9) else Color.White
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .height(Dp(48f))
            .padding(horizontal = Dp(22f))
            .background(backgroundColor)
            .clip(MaterialTheme.shapes.medium)
            .clickable { navHostController.navigate(Screen.JobDetails.route + "?jobId=${rowItem.id}") }
    ) {
        Checkbox(
            checked = isChecked,
            onCheckedChange = onCheckedChange,
            modifier = Modifier.padding(end = Dp(8f)),
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = com.hyperether.goodjob.theme.Divider
            )
        )
        Text(
            text = rowItem.jobTitle ?: " ",
            color = Color(0xFF34618E),
            fontSize = Sp(16f),
            fontWeight = FontWeight.Bold,
            modifier = Modifier.weight(0.9f)
        )
        Text(
            text = rowItem.contactSiteName ?: " ",
            color = Color(0xFF323232),
            fontSize = Sp(14f),
            modifier = Modifier.weight(0.9f)
        )
        Text(
            text = location,
            color = Color(0xFF555555),
            fontSize = Sp(14f),
            modifier = Modifier.weight(1f)
        )
        Text(
            text = rowItem.start_at?.let { start ->
                rowItem.end_at?.let { end -> "$start - $end" }
            } ?: "",
            color = Color(0xFF555555),
            fontSize = Sp(14f),
            modifier = Modifier.weight(0.7f)
        )
        Text(
            text = rowItem.assignees?.employees?.mapNotNull { it?.getName() }?.joinToString(", ") ?: "",
            color = Color(0xFF323232),
            fontSize = Sp(14f),
            modifier = Modifier.weight(0.7f)
        )
        Column(modifier = Modifier.weight(0.5f)) {
            Box(
                modifier = Modifier
                    .size(Dp(16f))
                    .clip(CircleShape)
                    .align(Alignment.CenterHorizontally)
                    .background(StatusColor().getStatusColor(rowItem.status ?: "On hold"))
            )
        }
    }
}