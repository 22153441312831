package com.hyperether.goodjob.scenes.pricing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.choose_plan
import com.hyperether.goodjob.resources.choose_the_plan
import com.hyperether.goodjob.resources.choose_the_right_plan
import com.hyperether.goodjob.resources.day_free_trial
import com.hyperether.goodjob.resources.free
import com.hyperether.goodjob.resources.from_small_teams
import com.hyperether.goodjob.resources.logo_dark_landing
import com.hyperether.goodjob.scenes.landing.PricingOption
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.PopUpDialog
import com.hyperether.goodjob.theme.LandingPageBg
import com.hyperether.goodjob.theme.LandingPageMainTitle
import com.hyperether.goodjob.theme.LandingPageText
import com.hyperether.goodjob.theme.PricingBoxBg
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun PricingScreen(
    isMobileView: Boolean,
    pricingViewModel: PricingViewModel,
    continueToWorkspace: () -> Unit
) {
    var showErrorDialog = remember { mutableStateOf(false) }
    val showProgressBar = remember { mutableStateOf(false) }

    val plans = if (getPlatformChecker().isMobile()) {
        pricingViewModel.plans.collectAsState().value
    } else {
        pricingViewModel.plans_remote.collectAsState().value.sortedBy { it.price }
    }

    val selectedPlan = remember { mutableStateOf<String?>(null) }
    val selectedPlanId = remember { mutableStateOf<String?>(null) }

    LaunchedEffect(plans) {
        if (plans.isNotEmpty()) {
            val popularPlan = plans.find { it.isPopular == true }
            selectedPlan.value = popularPlan?.name
            selectedPlanId.value = popularPlan?._id
        }
    }

    if (plans.isEmpty()) {
        showErrorDialog.value = true
    }

    LaunchedEffect(isMobileView) {
        pricingViewModel.getAllPlans()
    }
    if (isMobileView) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(rememberScrollState())
                .background(
                    brush = Brush.verticalGradient(
                        colors = listOf(
                            Color(0xFFc7e4fc),
                            Color(0xFFFFFFFF)
                        )
                    )
                )
                .padding(16.dp)
        ) {
            Column(
                modifier = Modifier.fillMaxSize(),
            ) {
                Text(
                    text = stringResource(Res.string.choose_the_right_plan),
                    color = Color.Black,
                    fontSize = 28.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 16.dp, top = 30.dp)
                )

                plans.forEach { plan ->
                    PricingItem(
                        isChecked = selectedPlan.value == plan.name,
                        onClick = {
                            selectedPlan.value = plan.name
                            selectedPlanId.value = plan._id
                        },
                        modifier = Modifier.padding(top = 10.dp),
                        planPrice = if (plan.price == 0.0) stringResource(Res.string.free) else "$${plan.price}",
                        planName = plan.name.orEmpty()
                    )
                }
            }
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier
                    //.align(Alignment.BottomCenter)
                    .fillMaxWidth()
                    .padding(bottom = 10.dp)
            ) {

                FilledButton(
                    onClick = {
                        showProgressBar.value = true
                        selectedPlanId.value?.let {
                            pricingViewModel.addPlan(
                                it,
                                onSuccess = {
                                    showProgressBar.value = false
                                    continueToWorkspace()
                                },
                                onError = {
                                    showProgressBar.value = false
                                    showErrorDialog.value = true
                                })
                        } ?: kotlin.run {
                            showProgressBar.value = false
                            showErrorDialog.value = true
                        }
                    },
                    modifier = Modifier.padding(top = 20.dp),
                    text = stringResource(Res.string.choose_plan),
                    isEnabled = true
                )
                Spacer(modifier = Modifier.height(10.dp))
                Text(
                    text = stringResource(Res.string.day_free_trial),
                    color = Color.Gray,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(top = 8.dp)
                )
            }
        }
    } else {
        Spacer(modifier = Modifier.height(60.dp))

        Column(
            modifier = Modifier
                .fillMaxHeight()
                .sizeIn(minWidth = 1200.dp, maxWidth = 2400.dp)
                .background(LandingPageBg)
                .verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth(),
                contentAlignment = Alignment.Center,
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .align(Alignment.Center)
                        .padding(30.dp)
                        .background(Color.Transparent),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(bottom = 70.dp),
                        contentAlignment = Alignment.TopStart
                    ) {
                        Image(
                            alignment = Alignment.TopStart,
                            painter = painterResource(Res.drawable.logo_dark_landing),
                            contentDescription = null
                        )
                    }

                    Column(
                        modifier = Modifier
                            .fillMaxWidth(),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        androidx.compose.material.Text(
                            text = stringResource(Res.string.choose_the_plan),
                            fontSize = 50.sp,
                            lineHeight = 50.sp,
                            color = LandingPageMainTitle,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                            modifier = Modifier.padding(vertical = 16.dp)
                        )
                        androidx.compose.material.Text(
                            text = stringResource(Res.string.from_small_teams),
                            fontSize = 16.sp,
                            color = LandingPageText,
                            textAlign = TextAlign.Center
                        )

                        Spacer(modifier = Modifier.height(20.dp))

                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(16.dp)
                                .shadow(8.dp, shape = RoundedCornerShape(26.dp))
                                .clip(RoundedCornerShape(26.dp))
                                .background(PricingBoxBg),
                        ) {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(16.dp),
                                horizontalArrangement = Arrangement.SpaceEvenly
                            ) {
                                // Loop over each plan in the list and create PricingOption components
                                plans.forEach { plan ->
                                    PricingOption(
                                        price = "$${plan.price}",
                                        planName = plan.name.orEmpty(),
                                        description = plan.description.orEmpty(),
                                        features = listOf(
                                            plan.storage.orEmpty(),
                                            plan.planSupport.orEmpty(),
                                            plan.limits.orEmpty()
                                        ),
                                        continueToRegister = {
                                            showProgressBar.value = true
                                            plan._id?.let {
                                                pricingViewModel.addPlan(
                                                    it,
                                                    onSuccess = {
                                                        showProgressBar.value = false
                                                        continueToWorkspace()
                                                    },
                                                    onError = {
                                                        showProgressBar.value = false
                                                        showErrorDialog.value = true
                                                    })
                                            }
                                        },
                                        isPopular = plan.isPopular!!,
                                        width = 300.dp
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (showErrorDialog.value) {
        PopUpDialog(
            onDismiss = {
                showErrorDialog.value = false
            },
            title = "Error",
            message = "There was an error, please try again"
        )
    }
    if (showProgressBar.value) {
        FullScreenProgressBar(showProgressBar = showProgressBar)
    }
}