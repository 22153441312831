package com.hyperether.goodjob.scenes.landing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Card
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.paint
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.components.InputFieldLanding
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all_rights_reserved
import com.hyperether.goodjob.resources.background_shape
import com.hyperether.goodjob.resources.check_circle
import com.hyperether.goodjob.resources.choose_plan
import com.hyperether.goodjob.resources.contact_us
import com.hyperether.goodjob.resources.effortless_efficiency
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.fly_high
import com.hyperether.goodjob.resources.fly_high_description
import com.hyperether.goodjob.resources.fly_high_feature_1
import com.hyperether.goodjob.resources.fly_high_feature_2
import com.hyperether.goodjob.resources.fly_high_price
import com.hyperether.goodjob.resources.free_100
import com.hyperether.goodjob.resources.freebie
import com.hyperether.goodjob.resources.freebie_description
import com.hyperether.goodjob.resources.freebie_feature_1
import com.hyperether.goodjob.resources.freebie_feature_2
import com.hyperether.goodjob.resources.freebie_price
import com.hyperether.goodjob.resources.galactic
import com.hyperether.goodjob.resources.galactic_description
import com.hyperether.goodjob.resources.galactic_feature_1
import com.hyperether.goodjob.resources.galactic_feature_2
import com.hyperether.goodjob.resources.galactic_price
import com.hyperether.goodjob.resources.high_five
import com.hyperether.goodjob.resources.high_five_description
import com.hyperether.goodjob.resources.high_five_feature_1
import com.hyperether.goodjob.resources.high_five_feature_2
import com.hyperether.goodjob.resources.high_five_price
import com.hyperether.goodjob.resources.landing_img
import com.hyperether.goodjob.resources.login_register
import com.hyperether.goodjob.resources.logo_good_job
import com.hyperether.goodjob.resources.most_popular
import com.hyperether.goodjob.resources.optimize_your
import com.hyperether.goodjob.resources.premium
import com.hyperether.goodjob.resources.pricing
import com.hyperether.goodjob.resources.privacy_policy
import com.hyperether.goodjob.resources.subscribe
import com.hyperether.goodjob.resources.terms_of_service
import com.hyperether.goodjob.resources.transform
import com.hyperether.goodjob.resources.transparent_flexible
import com.hyperether.goodjob.resources.unlimited_storage
import com.hyperether.goodjob.resources.whether
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.LightGrey
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun LandingPage(isMobileView: Boolean, continueToRegister: () -> Unit) {

    var enteredEmail: MutableState<String> = mutableStateOf("")

    if (!isMobileView) {
        Spacer(modifier = Modifier.height(60.dp))
        Image(
            contentDescription = null,
            modifier = Modifier
                .sizeIn(minWidth = 400.dp, maxWidth = 600.dp)
                .height(800.dp),
            contentScale = ContentScale.Fit,
            painter = painterResource(Res.drawable.background_shape),
        )

        Column(
            modifier = Modifier
                .fillMaxHeight()
                .sizeIn(minWidth = 1200.dp, maxWidth = 2400.dp)
                .verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth(),
                contentAlignment = Alignment.Center,
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .align(Alignment.Center)
                        .padding(30.dp)
                        .background(Color.Transparent),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(bottom = 100.dp),
                        contentAlignment = Alignment.TopStart
                    ) {
                        Image(
                            alignment = Alignment.TopStart,
                            painter = painterResource(Res.drawable.logo_good_job),
                            contentDescription = null
                        )

                        TextButton(
                            onClick = {
                                // TODO
                            },
                            modifier = Modifier
                                .align(Alignment.TopEnd)
                                .height(50.dp)
                                .padding(end = 180.dp)
                                .width(150.dp)
                                .clip(RoundedCornerShape(26.dp)),
                        ) {
                            Text(
                                text = stringResource(Res.string.contact_us),
                                color = Color(0xFF616161),
                                fontWeight = FontWeight.Bold
                            )
                        }

                        Button(
                            onClick = {
                                continueToRegister()
                            },
                            modifier = Modifier
                                .align(Alignment.TopEnd)
                                .height(50.dp)
                                .width(150.dp)
                                .clip(RoundedCornerShape((56.dp))),
                            colors = ButtonDefaults.buttonColors(Color(0xFF10283F)),
                        ) {
                            Text(
                                text = stringResource(Res.string.login_register),
                                color = Color.White,
                                fontWeight = FontWeight.Bold
                            )
                        }
                    }

                    Row(
                        modifier = Modifier
                            .fillMaxHeight(),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        Column(
                            modifier = Modifier
                                .fillMaxHeight()
                                .weight(1f),
                            verticalArrangement = Arrangement.Center,
                        ) {
                            Text(
                                text = stringResource(Res.string.effortless_efficiency),
                                fontSize = 16.sp,
                                color = Color(0xFF34618E)
                            )
                            Spacer(modifier = Modifier.height(20.dp))

                            Text(
                                text = stringResource(Res.string.transform),
                                fontSize = 50.sp,
                                lineHeight = 50.sp,
                                modifier = Modifier
                                    .fillMaxWidth(),
                            )
                            Text(
                                text = stringResource(Res.string.optimize_your),
                                fontSize = 18.sp,
                                color = DarkGrey,
                                modifier = Modifier
                                    .padding(top = 20.dp)
                            )
                            InputFieldLanding(
                                isMobileView = false,
                                input = enteredEmail,
                                placeHolder = stringResource(Res.string.enter_your_email),
                                modifier = Modifier
                                    .padding(top = 20.dp)
                            )
                            Text(
                                text = stringResource(Res.string.free_100),
                                modifier = Modifier
                                    .padding(top = 20.dp)
                            )
                        }
                        Image(
                            modifier = Modifier
                                .weight(1f),
                            alignment = Alignment.TopEnd,
                            painter = painterResource(Res.drawable.landing_img),
                            contentDescription = null
                        )
                    }

                    Spacer(modifier = Modifier.height(80.dp))

                    Column(
                        modifier = Modifier
                            .fillMaxWidth(),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text(
                            text = stringResource(Res.string.pricing),
                            fontSize = 18.sp,
                            color = Color(0xFF34618E),
                            textAlign = TextAlign.Center
                        )
                        Spacer(modifier = Modifier.height(20.dp))

                        // Pricing Section Title
                        Text(
                            text = stringResource(Res.string.transparent_flexible),
                            fontSize = 50.sp,
                            lineHeight = 50.sp,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                            modifier = Modifier.padding(vertical = 16.dp)
                        )
                        Text(
                            text = stringResource(Res.string.whether),
                            fontSize = 16.sp,
                            color = Color.Gray,
                            textAlign = TextAlign.Center
                        )

                        // Spacer for additional separation
                        Spacer(modifier = Modifier.height(20.dp))

                        // Pricing Cards Container
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(16.dp)
                                .shadow(8.dp, shape = RoundedCornerShape(26.dp))
                                .clip(RoundedCornerShape(26.dp))
                                .background(Color.White),
                        ) {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(16.dp),
                                horizontalArrangement = Arrangement.SpaceEvenly
                            ) {
                                PricingOption(
                                    price = stringResource(Res.string.freebie_price),
                                    planName = stringResource(Res.string.freebie),
                                    description = stringResource(Res.string.freebie_description),
                                    features = listOf(
                                        stringResource(Res.string.freebie_feature_1),
                                        stringResource(Res.string.freebie_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    continueToRegister = continueToRegister
                                )

                                PricingOption(
                                    price = stringResource(Res.string.high_five_price),
                                    planName = stringResource(Res.string.high_five),
                                    description = stringResource(Res.string.high_five_description),
                                    features = listOf(
                                        stringResource(Res.string.high_five_feature_1),
                                        stringResource(Res.string.high_five_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    continueToRegister = continueToRegister
                                )

                                // Highlighted Pricing Option
                                PricingOption(
                                    price = stringResource(Res.string.fly_high_price),
                                    planName = stringResource(Res.string.fly_high),
                                    description = stringResource(Res.string.fly_high_description),
                                    features = listOf(
                                        stringResource(Res.string.fly_high_feature_1),
                                        stringResource(Res.string.fly_high_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    isPopular = true,
                                    continueToRegister = continueToRegister
                                )

                                PricingOption(
                                    price = stringResource(Res.string.galactic_price),
                                    planName = stringResource(Res.string.galactic),
                                    description = stringResource(Res.string.galactic_description),
                                    features = listOf(
                                        stringResource(Res.string.galactic_feature_1),
                                        stringResource(Res.string.galactic_feature_2),
                                        stringResource(Res.string.unlimited_storage)
                                    ),
                                    continueToRegister = continueToRegister
                                )
                            }
                        }
                    }
                }
            }

            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(color = Color(0xFFFAFAFA))
                    .padding(vertical = 20.dp)
            ) {
                Text(
                    text = stringResource(Res.string.all_rights_reserved),
                    fontSize = 15.sp,
                    color = Color(0xFF7E7E7E),
                    textAlign = TextAlign.End,
                    modifier = Modifier.weight(4f)
                )
                Text(
                    text = stringResource(Res.string.privacy_policy),
                    fontSize = 15.sp,
                    color = Color(0xFF10283F),
                    textAlign = TextAlign.End,
                    modifier = Modifier.weight(1f)
                )
                Text(
                    text = stringResource(Res.string.terms_of_service),
                    fontSize = 15.sp,
                    color = Color(0xFF10283F),
                    textAlign = TextAlign.End,
                    modifier = Modifier
                        .weight(1f)
                        .padding(end = 20.dp)
                )
            }
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .padding(start = 20.dp, end = 20.dp)
                .sizeIn(minWidth = 1200.dp, maxWidth = 2400.dp)
                .verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 20.dp),
                contentAlignment = Alignment.TopStart
            ) {
                Image(
                    alignment = Alignment.TopStart,
                    painter = painterResource(Res.drawable.logo_good_job),
                    contentDescription = null
                )
                Button(
                    onClick = {
                        continueToRegister()
                    },
                    modifier = Modifier
                        .align(Alignment.TopEnd)
                        .height(50.dp)
                        .width(150.dp)
                        .clip(RoundedCornerShape((56.dp))),
                    colors = ButtonDefaults.buttonColors(Color(0xFF10283F)),
                ) {
                    Text(
                        text = stringResource(Res.string.login_register),
                        color = Color.White,
                        fontWeight = FontWeight.Bold
                    )
                }
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .padding(top = 100.dp),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Column(
                        modifier = Modifier
                            .paint(
                                painterResource(Res.drawable.landing_img),
                                alpha = 0.2f
                            )
                            .fillMaxSize()
                            .padding(top = 30.dp),
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        Text(
                            text = stringResource(Res.string.effortless_efficiency),
                            fontSize = 16.sp,
                            color = Color(0xFF34618E),
                        )
                        Spacer(modifier = Modifier.height(20.dp))

                        Text(
                            text = stringResource(Res.string.transform),
                            fontSize = 50.sp,
                            fontWeight = FontWeight.Bold,
                            lineHeight = 50.sp,
                            modifier = Modifier
                                .fillMaxWidth(),
                        )
                        Text(
                            text = stringResource(Res.string.optimize_your),
                            fontSize = 18.sp,
                            color = DarkGrey,
                            modifier = Modifier
                                .padding(top = 20.dp)
                        )
                        InputFieldLanding(
                            isMobileView = true,
                            input = enteredEmail,
                            placeHolder = stringResource(Res.string.enter_your_email),
                            modifier = Modifier
                                .padding(top = 20.dp)
                        )
                    }
                    Button(
                        onClick = {
                            // TODO
                        },
                        modifier = Modifier
                            .padding(horizontal = 8.dp)
                            .height(50.dp)
                            .fillMaxWidth()
                            .clip(RoundedCornerShape((56.dp)))
                            .shadow(8.dp),
                        colors = ButtonDefaults.buttonColors(Color(0xFF34618E)),
                    ) {
                        Text(
                            text = stringResource(Res.string.subscribe),
                            color = Color.White
                        )
                    }
                    Row(
                        modifier = Modifier.fillMaxWidth()
                    ) {
                        Icon(
                            painter = painterResource(Res.drawable.check_circle),
                            contentDescription = "Feature icon",
                            tint = Color.White,
                            modifier = Modifier
                                .size(16.dp)
                                .padding(top = 20.dp)
                        )
                        Text(
                            color = LightGrey,
                            text = stringResource(Res.string.free_100),
                            modifier = Modifier
                                .padding(top = 20.dp)
                        )
                    }
                    Spacer(modifier = Modifier.height(100.dp))

                    Text(
                        text = stringResource(Res.string.pricing),
                        fontSize = 18.sp,
                        color = Color(0xFF34618E),
                        textAlign = TextAlign.Center
                    )
                    // Pricing Section Title
                    Text(
                        text = stringResource(Res.string.transparent_flexible),
                        fontSize = 50.sp,
                        lineHeight = 50.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.padding(vertical = 16.dp)
                    )
                    Text(
                        text = stringResource(Res.string.whether),
                        fontSize = 16.sp,
                        color = Color.Gray,
                        textAlign = TextAlign.Center
                    )

                    // Spacer for additional separation
                    Spacer(modifier = Modifier.height(20.dp))

                    // Pricing Cards Container
                    Card(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(16.dp),
                        shape = RoundedCornerShape(26.dp),
                        elevation = 8.dp
                    ) {
                        PricingOption(
                            price = stringResource(Res.string.freebie_price),
                            planName = stringResource(Res.string.freebie),
                            description = stringResource(Res.string.freebie_description),
                            features = listOf(
                                stringResource(Res.string.freebie_feature_1),
                                stringResource(Res.string.freebie_feature_2),
                                stringResource(Res.string.unlimited_storage)
                            ),
                            continueToRegister = continueToRegister
                        )
                    }
                    Card(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(16.dp),
                        shape = RoundedCornerShape(26.dp),
                        elevation = 8.dp
                    ) {
                        PricingOption(
                            price = stringResource(Res.string.high_five_price),
                            planName = stringResource(Res.string.high_five),
                            description = stringResource(Res.string.high_five_description),
                            features = listOf(
                                stringResource(Res.string.high_five_feature_1),
                                stringResource(Res.string.high_five_feature_2),
                                stringResource(Res.string.unlimited_storage)
                            ),
                            continueToRegister = continueToRegister
                        )
                    }
                    Card(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(16.dp)
                            .paint(painterResource(Res.drawable.premium)),
                        shape = RoundedCornerShape(26.dp),
                        elevation = 8.dp
                    ) {
                        PricingOption(
                            price = stringResource(Res.string.fly_high_price),
                            planName = stringResource(Res.string.fly_high),
                            description = stringResource(Res.string.fly_high_description),
                            features = listOf(
                                stringResource(Res.string.fly_high_feature_1),
                                stringResource(Res.string.fly_high_feature_2),
                                stringResource(Res.string.unlimited_storage)
                            ),
                            isPopular = true,
                            continueToRegister = continueToRegister
                        )
                    }
                    Card(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(16.dp),
                        shape = RoundedCornerShape(26.dp),
                        elevation = 8.dp
                    ) {
                        PricingOption(
                            price = stringResource(Res.string.galactic_price),
                            planName = stringResource(Res.string.galactic),
                            description = stringResource(Res.string.galactic_description),
                            features = listOf(
                                stringResource(Res.string.galactic_feature_1),
                                stringResource(Res.string.galactic_feature_2),
                                stringResource(Res.string.unlimited_storage)
                            ),
                            continueToRegister = continueToRegister
                        )
                    }

                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(color = Color(0xFFFAFAFA))
                    ) {
                        Text(
                            text = stringResource(Res.string.all_rights_reserved),
                            fontSize = 15.sp,
                            color = Color(0xFF7E7E7E),
                            textAlign = TextAlign.Center,
                            modifier = Modifier.fillMaxWidth().padding(bottom = 10.dp)
                        )
                        Row {
                            Text(
                                text = stringResource(Res.string.privacy_policy),
                                fontSize = 15.sp,
                                color = Color(0xFF10283F),
                                textAlign = TextAlign.Start,
                                modifier = Modifier.weight(1f)
                            )
                            Text(
                                text = stringResource(Res.string.terms_of_service),
                                fontSize = 15.sp,
                                color = Color(0xFF10283F),
                                textAlign = TextAlign.End,
                                modifier = Modifier
                                    .weight(1f)
                                    .padding(end = 20.dp, bottom = 20.dp)
                            )
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun PricingOption(
    price: String,
    planName: String,
    description: String,
    features: List<String>,
    isPopular: Boolean = false,
    continueToRegister: () -> Unit
) {
    Box(
        modifier = if (isPopular) Modifier
            .shadow(8.dp, shape = RoundedCornerShape(26.dp))
            .clip(RoundedCornerShape((26.dp)))
            .width(300.dp)
            .background(
                shape = RoundedCornerShape(26.dp),
                color = Color(0xFF34618E)
            )
            .paint(
                painter = painterResource(Res.drawable.premium),
                alignment = Alignment.CenterEnd
            )
            .padding(start = 20.dp, end = 20.dp)
        else {
            Modifier
                .width(300.dp)
                .background(
                    color = Color.White,
                    shape = RoundedCornerShape(12.dp)
                )
                .padding(16.dp)
        }
    ) {
        Column {
            Box(
            ) {
                Column(
                    modifier = if (isPopular) Modifier
                        .fillMaxWidth()
                        .align(Alignment.CenterStart)
                        .padding(top = 35.dp, bottom = 30.dp, end = 20.dp)
                    else Modifier
                        .fillMaxWidth()
                        .padding(bottom = 64.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    if (isPopular) {
                        Text(
                            text = stringResource(Res.string.most_popular),
                            fontSize = 10.sp,
                            color = Color.White,
                            fontWeight = FontWeight.Bold,
                            textAlign = TextAlign.Center,
                            modifier = Modifier
                                .background(Color(0xFF183E65), shape = RoundedCornerShape(26.dp))
                                .padding(horizontal = 8.dp, vertical = 4.dp)
                                .align(Alignment.End)
                        )
                    }
                    Text(
                        text = buildAnnotatedString {
                            val parts = price.split("/")
                            val mainPrice = parts.getOrNull(0) ?: ""
                            val period = parts.getOrNull(1) ?: ""

                            withStyle(
                                style = SpanStyle(
                                    fontSize = 36.sp,
                                    fontWeight = FontWeight.Bold,
                                    color = if (isPopular) Color.White else Color.Black
                                )
                            ) {
                                append(mainPrice)
                            }

                            if (period.isNotEmpty()) {
                                withStyle(
                                    style = SpanStyle(
                                        fontSize = 17.sp,
                                        color = if (isPopular) Color.White else Color(0xFF8B8B8B)
                                    )
                                ) {
                                    append(" /$period")
                                }
                            }
                        },
                        modifier = Modifier
                            .padding(top = 8.dp, bottom = 15.dp)
                            .align(Alignment.Start)
                    )
                    Text(
                        text = planName,
                        fontSize = 28.sp,
                        fontWeight = FontWeight.SemiBold,
                        color = if (isPopular) Color.White else Color.Black,
                        modifier = Modifier
                            .padding(top = 4.dp, bottom = 4.dp)
                            .align(Alignment.Start)
                    )
                    Text(
                        text = description,
                        fontSize = 15.sp,
                        color = if (isPopular) Color.White else Color(0xFF8B8B8B),
                        modifier = Modifier
                            .padding(top = 8.dp, bottom = 20.dp)
                            .align(Alignment.Start)
                    )
                    features.forEach { feature ->
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 4.dp)
                                .align(Alignment.Start),
                            verticalAlignment = Alignment.CenterVertically // Aligns icon and text vertically centered
                        ) {
                            Icon(
                                painter = painterResource(Res.drawable.check_circle), // Replace with your drawable resource ID
                                contentDescription = "Feature icon",
                                tint = if (isPopular) Color.White else Color(0xFF34618E),
                                modifier = Modifier.size(16.dp) // Adjust size as needed
                            )

                            Spacer(modifier = Modifier.width(4.dp)) // Adds space between icon and text

                            Text(
                                text = feature,
                                fontSize = 15.sp,
                                color = if (isPopular) Color.White else Color(0xFF8B8B8B)
                            )
                        }
                    }
                }
            }
            Box(
                contentAlignment = Alignment.BottomCenter
            ) {
                Button(
                    onClick = { continueToRegister() },
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .padding(8.dp)
                        .height(50.dp)
                        .fillMaxWidth()
                        .clip(RoundedCornerShape((56.dp))),
                    colors = ButtonDefaults.buttonColors(
                        backgroundColor = if (isPopular) Color.White else Color(0xFFebeff4),
                        contentColor = Color(0xFF34618E)
                    )
                ) {
                    Text(
                        fontWeight = FontWeight.Bold,
                        text = stringResource(Res.string.choose_plan)
                    )
                }
            }
        }
    }
}