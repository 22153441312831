@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.hyperether.goodjob.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val app_logo: DrawableResource by 
      lazy { init_app_logo() }

  public val apple: DrawableResource by 
      lazy { init_apple() }

  public val back_arrow: DrawableResource by 
      lazy { init_back_arrow() }

  public val background_shape: DrawableResource by 
      lazy { init_background_shape() }

  public val baseline_arrow_drop_down_24: DrawableResource by 
      lazy { init_baseline_arrow_drop_down_24() }

  public val baseline_person_24: DrawableResource by 
      lazy { init_baseline_person_24() }

  public val briefcase: DrawableResource by 
      lazy { init_briefcase() }

  public val briefcase_unselect: DrawableResource by 
      lazy { init_briefcase_unselect() }

  public val calendar: DrawableResource by 
      lazy { init_calendar() }

  public val calendar_unselect: DrawableResource by 
      lazy { init_calendar_unselect() }

  public val check_circle: DrawableResource by 
      lazy { init_check_circle() }

  public val check_circle_1: DrawableResource by 
      lazy { init_check_circle_1() }

  public val chevron_left: DrawableResource by 
      lazy { init_chevron_left() }

  public val chevron_right: DrawableResource by 
      lazy { init_chevron_right() }

  public val clipboard_small: DrawableResource by 
      lazy { init_clipboard_small() }

  public val clock_small: DrawableResource by 
      lazy { init_clock_small() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val contact_ic: DrawableResource by 
      lazy { init_contact_ic() }

  public val dahboard: DrawableResource by 
      lazy { init_dahboard() }

  public val dahboard_unselect: DrawableResource by 
      lazy { init_dahboard_unselect() }

  public val dropdown_arrow: DrawableResource by 
      lazy { init_dropdown_arrow() }

  public val email_ic: DrawableResource by 
      lazy { init_email_ic() }

  public val employees: DrawableResource by 
      lazy { init_employees() }

  public val employees_small: DrawableResource by 
      lazy { init_employees_small() }

  public val employees_unselect: DrawableResource by 
      lazy { init_employees_unselect() }

  public val fb: DrawableResource by 
      lazy { init_fb() }

  public val file_text__1_: DrawableResource by 
      lazy { init_file_text__1_() }

  public val filter_svgrepo_com_1: DrawableResource by 
      lazy { init_filter_svgrepo_com_1() }

  public val google: DrawableResource by 
      lazy { init_google() }

  public val icon: DrawableResource by 
      lazy { init_icon() }

  public val landing_img: DrawableResource by 
      lazy { init_landing_img() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val logo_good_job: DrawableResource by 
      lazy { init_logo_good_job() }

  public val map: DrawableResource by 
      lazy { init_map() }

  public val map_pin: DrawableResource by 
      lazy { init_map_pin() }

  public val map_small: DrawableResource by 
      lazy { init_map_small() }

  public val map_unselect: DrawableResource by 
      lazy { init_map_unselect() }

  public val onboarding_image1: DrawableResource by 
      lazy { init_onboarding_image1() }

  public val onboarding_image2: DrawableResource by 
      lazy { init_onboarding_image2() }

  public val pass_ic: DrawableResource by 
      lazy { init_pass_ic() }

  public val phone: DrawableResource by 
      lazy { init_phone() }

  public val plus: DrawableResource by 
      lazy { init_plus() }

  public val premium: DrawableResource by 
      lazy { init_premium() }

  public val team: DrawableResource by 
      lazy { init_team() }

  public val user_check: DrawableResource by 
      lazy { init_user_check() }

  public val user_check_small: DrawableResource by 
      lazy { init_user_check_small() }

  public val user_check_unselect: DrawableResource by 
      lazy { init_user_check_unselect() }

  public val user_plus: DrawableResource by 
      lazy { init_user_plus() }

  public val web_left_content: DrawableResource by 
      lazy { init_web_left_content() }
}

public val Res.drawable.app_logo: DrawableResource
  get() = CommonMainDrawable0.app_logo

private fun init_app_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/app_logo.xml", -1, -1),
    )
)

public val Res.drawable.apple: DrawableResource
  get() = CommonMainDrawable0.apple

private fun init_apple(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:apple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/apple.xml", -1, -1),
    )
)

public val Res.drawable.back_arrow: DrawableResource
  get() = CommonMainDrawable0.back_arrow

private fun init_back_arrow(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:back_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/back_arrow.xml", -1, -1),
    )
)

public val Res.drawable.background_shape: DrawableResource
  get() = CommonMainDrawable0.background_shape

private fun init_background_shape(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:background_shape",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/background_shape.png", -1, -1),
    )
)

public val Res.drawable.baseline_arrow_drop_down_24: DrawableResource
  get() = CommonMainDrawable0.baseline_arrow_drop_down_24

private fun init_baseline_arrow_drop_down_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_arrow_drop_down_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/baseline_arrow_drop_down_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_person_24: DrawableResource
  get() = CommonMainDrawable0.baseline_person_24

private fun init_baseline_person_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_person_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/baseline_person_24.xml", -1, -1),
    )
)

public val Res.drawable.briefcase: DrawableResource
  get() = CommonMainDrawable0.briefcase

private fun init_briefcase(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:briefcase",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/briefcase.xml", -1, -1),
    )
)

public val Res.drawable.briefcase_unselect: DrawableResource
  get() = CommonMainDrawable0.briefcase_unselect

private fun init_briefcase_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:briefcase_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/briefcase_unselect.xml", -1, -1),
    )
)

public val Res.drawable.calendar: DrawableResource
  get() = CommonMainDrawable0.calendar

private fun init_calendar(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:calendar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/calendar.xml", -1, -1),
    )
)

public val Res.drawable.calendar_unselect: DrawableResource
  get() = CommonMainDrawable0.calendar_unselect

private fun init_calendar_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:calendar_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/calendar_unselect.xml", -1, -1),
    )
)

public val Res.drawable.check_circle: DrawableResource
  get() = CommonMainDrawable0.check_circle

private fun init_check_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:check_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/check_circle.xml", -1, -1),
    )
)

public val Res.drawable.check_circle_1: DrawableResource
  get() = CommonMainDrawable0.check_circle_1

private fun init_check_circle_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:check_circle_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/check_circle_1.xml", -1, -1),
    )
)

public val Res.drawable.chevron_left: DrawableResource
  get() = CommonMainDrawable0.chevron_left

private fun init_chevron_left(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chevron_left",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/chevron_left.xml", -1, -1),
    )
)

public val Res.drawable.chevron_right: DrawableResource
  get() = CommonMainDrawable0.chevron_right

private fun init_chevron_right(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chevron_right",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/chevron_right.xml", -1, -1),
    )
)

public val Res.drawable.clipboard_small: DrawableResource
  get() = CommonMainDrawable0.clipboard_small

private fun init_clipboard_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:clipboard_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/clipboard_small.xml", -1, -1),
    )
)

public val Res.drawable.clock_small: DrawableResource
  get() = CommonMainDrawable0.clock_small

private fun init_clock_small(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clock_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/clock_small.xml", -1, -1),
    )
)

public val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

public val Res.drawable.contact_ic: DrawableResource
  get() = CommonMainDrawable0.contact_ic

private fun init_contact_ic(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:contact_ic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/contact_ic.xml", -1, -1),
    )
)

public val Res.drawable.dahboard: DrawableResource
  get() = CommonMainDrawable0.dahboard

private fun init_dahboard(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:dahboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/dahboard.xml", -1, -1),
    )
)

public val Res.drawable.dahboard_unselect: DrawableResource
  get() = CommonMainDrawable0.dahboard_unselect

private fun init_dahboard_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:dahboard_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/dahboard_unselect.xml", -1, -1),
    )
)

public val Res.drawable.dropdown_arrow: DrawableResource
  get() = CommonMainDrawable0.dropdown_arrow

private fun init_dropdown_arrow(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:dropdown_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/dropdown_arrow.xml", -1, -1),
    )
)

public val Res.drawable.email_ic: DrawableResource
  get() = CommonMainDrawable0.email_ic

private fun init_email_ic(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:email_ic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/email_ic.xml", -1, -1),
    )
)

public val Res.drawable.employees: DrawableResource
  get() = CommonMainDrawable0.employees

private fun init_employees(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/employees.xml", -1, -1),
    )
)

public val Res.drawable.employees_small: DrawableResource
  get() = CommonMainDrawable0.employees_small

private fun init_employees_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:employees_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/employees_small.xml", -1, -1),
    )
)

public val Res.drawable.employees_unselect: DrawableResource
  get() = CommonMainDrawable0.employees_unselect

private fun init_employees_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:employees_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/employees_unselect.xml", -1, -1),
    )
)

public val Res.drawable.fb: DrawableResource
  get() = CommonMainDrawable0.fb

private fun init_fb(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:fb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/fb.xml", -1, -1),
    )
)

public val Res.drawable.file_text__1_: DrawableResource
  get() = CommonMainDrawable0.file_text__1_

private fun init_file_text__1_(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:file_text__1_",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/file_text__1_.xml", -1, -1),
    )
)

public val Res.drawable.filter_svgrepo_com_1: DrawableResource
  get() = CommonMainDrawable0.filter_svgrepo_com_1

private fun init_filter_svgrepo_com_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:filter_svgrepo_com_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/filter_svgrepo_com_1.xml", -1, -1),
    )
)

public val Res.drawable.google: DrawableResource
  get() = CommonMainDrawable0.google

private fun init_google(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/google.xml", -1, -1),
    )
)

public val Res.drawable.icon: DrawableResource
  get() = CommonMainDrawable0.icon

private fun init_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/icon.xml", -1, -1),
    )
)

public val Res.drawable.landing_img: DrawableResource
  get() = CommonMainDrawable0.landing_img

private fun init_landing_img(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:landing_img",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/landing_img.xml", -1, -1),
    )
)

public val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/logo.xml", -1, -1),
    )
)

public val Res.drawable.logo_good_job: DrawableResource
  get() = CommonMainDrawable0.logo_good_job

private fun init_logo_good_job(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_good_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/logo_good_job.xml", -1, -1),
    )
)

public val Res.drawable.map: DrawableResource
  get() = CommonMainDrawable0.map

private fun init_map(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:map",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map.xml", -1, -1),
    )
)

public val Res.drawable.map_pin: DrawableResource
  get() = CommonMainDrawable0.map_pin

private fun init_map_pin(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:map_pin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map_pin.xml", -1, -1),
    )
)

public val Res.drawable.map_small: DrawableResource
  get() = CommonMainDrawable0.map_small

private fun init_map_small(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:map_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map_small.xml", -1, -1),
    )
)

public val Res.drawable.map_unselect: DrawableResource
  get() = CommonMainDrawable0.map_unselect

private fun init_map_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:map_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/map_unselect.xml", -1, -1),
    )
)

public val Res.drawable.onboarding_image1: DrawableResource
  get() = CommonMainDrawable0.onboarding_image1

private fun init_onboarding_image1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:onboarding_image1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/onboarding_image1.png", -1, -1),
    )
)

public val Res.drawable.onboarding_image2: DrawableResource
  get() = CommonMainDrawable0.onboarding_image2

private fun init_onboarding_image2(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:onboarding_image2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/onboarding_image2.png", -1, -1),
    )
)

public val Res.drawable.pass_ic: DrawableResource
  get() = CommonMainDrawable0.pass_ic

private fun init_pass_ic(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pass_ic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/pass_ic.xml", -1, -1),
    )
)

public val Res.drawable.phone: DrawableResource
  get() = CommonMainDrawable0.phone

private fun init_phone(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/phone.xml", -1, -1),
    )
)

public val Res.drawable.plus: DrawableResource
  get() = CommonMainDrawable0.plus

private fun init_plus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/plus.xml", -1, -1),
    )
)

public val Res.drawable.premium: DrawableResource
  get() = CommonMainDrawable0.premium

private fun init_premium(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:premium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/premium.xml", -1, -1),
    )
)

public val Res.drawable.team: DrawableResource
  get() = CommonMainDrawable0.team

private fun init_team(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/team.xml", -1, -1),
    )
)

public val Res.drawable.user_check: DrawableResource
  get() = CommonMainDrawable0.user_check

private fun init_user_check(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_check",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_check.xml", -1, -1),
    )
)

public val Res.drawable.user_check_small: DrawableResource
  get() = CommonMainDrawable0.user_check_small

private fun init_user_check_small(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_check_small",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_check_small.xml", -1, -1),
    )
)

public val Res.drawable.user_check_unselect: DrawableResource
  get() = CommonMainDrawable0.user_check_unselect

private fun init_user_check_unselect(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_check_unselect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_check_unselect.xml", -1, -1),
    )
)

public val Res.drawable.user_plus: DrawableResource
  get() = CommonMainDrawable0.user_plus

private fun init_user_plus(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:user_plus",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/user_plus.xml", -1, -1),
    )
)

public val Res.drawable.web_left_content: DrawableResource
  get() = CommonMainDrawable0.web_left_content

private fun init_web_left_content(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:web_left_content",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/drawable/web_left_content.xml", -1, -1),
    )
)
