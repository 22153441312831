package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.AlertDialog
import androidx.compose.material.Button
import androidx.compose.material.Checkbox
import androidx.compose.material.Divider
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Switch
import androidx.compose.material.SwitchDefaults
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.FocusManager
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.UserTeam
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.assigned
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.calendar__1_
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.clock_small
import com.hyperether.goodjob.resources.confirm
import com.hyperether.goodjob.resources.continue_with_next_step
import com.hyperether.goodjob.resources.describe_the_process
import com.hyperether.goodjob.resources.done_selecting_dates
import com.hyperether.goodjob.resources.dropdown_arrow
import com.hyperether.goodjob.resources.enter_step_title
import com.hyperether.goodjob.resources.enter_time
import com.hyperether.goodjob.resources.estimated_duration
import com.hyperether.goodjob.resources.hour
import com.hyperether.goodjob.resources.minute
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.select_team
import com.hyperether.goodjob.resources.sort_by
import com.hyperether.goodjob.resources.step
import com.hyperether.goodjob.resources.step_description
import com.hyperether.goodjob.resources.step_title
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.trash
import com.hyperether.goodjob.resources.users_title
import com.hyperether.goodjob.scenes.components.noRippleClickable
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.EstimatedDurationInputField
import com.hyperether.goodjob.theme.AddEmployeeInputColor
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.EmployeeDetailsText
import com.hyperether.goodjob.theme.HeaderText
import com.hyperether.goodjob.theme.InputBorderColor
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants
import io.wojciechosak.calendar.config.SelectionMode
import io.wojciechosak.calendar.config.rememberCalendarState
import io.wojciechosak.calendar.range.RangeConfig
import io.wojciechosak.calendar.range.UnderlineIllustrator
import io.wojciechosak.calendar.utils.Pallete
import io.wojciechosak.calendar.view.HorizontalCalendarView
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.plus
import kotlinx.datetime.todayIn
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewJobDropdownInput(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: Map<String, String>,
    painter: Painter?,
    modifier: Modifier = Modifier,
    paddingTop: androidx.compose.ui.unit.Dp = 10.dp
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
            .noRippleClickable {
                expanded.value = !expanded.value
            }
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),

            trailingIcon = {
                if (painter != null) {
                    Icon(
                        painter = painter,
                        contentDescription = "Dropdown"
                    )
                }
            },
            colors = OutlinedTextFieldDefaults.colors(
                disabledBorderColor = InputBorderColor,
                unfocusedBorderColor = InputBorderColor,
                disabledTextColor = TextColor
            ),
            enabled = false
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(onClick = {
                    text.value = item.key
                    expanded.value = false
                }, text = {
                    Text(item.key)
                }
                )
            }
        }
    }
}

//@Composable
//fun EmployeesDropdownInput(
//    text: MutableState<String>,
//    label: String,
//    placeholder: String,
//    dropdownItems: List<User>,
//    onContactSelected: (User) -> Unit,
//    painter: Painter?,
//    modifier: Modifier = Modifier
//) {
//    var expanded = remember { mutableStateOf(false) }
//
//    Box(
//        modifier = modifier
//            .fillMaxWidth()
//            .padding(top = 10.dp)
//            .noRippleClickable {
//                expanded.value = !expanded.value
//            }
//    ) {
//        OutlinedTextField(
//            value = text.value,
//            onValueChange = {
//                text.value = ""
//                text.value = it
//            },
//            colors = OutlinedTextFieldDefaults.colors(
//                disabledBorderColor = InputBorderColor,
//                unfocusedBorderColor = InputBorderColor,
//                disabledTextColor = TextColor
//            ),
//            enabled = false,
//            label = { Text(label, fontSize = 14.sp, color = TextColor) },
//            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
//            modifier = Modifier
//                .fillMaxWidth(),
//
//            trailingIcon = {
//                if (painter != null) {
//                    Icon(
//                        painter = painter,
//                        contentDescription = "Dropdown"
//                    )
//                }
//            }
//        )
//
//        DropdownMenu(
//            expanded = expanded.value,
//            onDismissRequest = { expanded.value = false }
//        ) {
//            dropdownItems.forEach { item ->
//                DropdownMenuItem(onClick = {
//                    if (item != null) {
//                        text.value = item.fullName.toString()
//                        onContactSelected(item)
//                    }
//                    expanded.value = false
//                }, text = {
//                    if (item != null) {
//                        item.fullName?.let { Text(it) }
//                    }
//                }
//                )
//            }
//        }
//    }
//}

@Composable
fun MultiSelectDropdown(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    users: List<User>,
    teams: List<Team>,
    preSelectedUsers: List<User> = listOf(),
    preSelectedTeams: List<Team> = listOf(),
    onContactSelected: (List<User>, List<Team>) -> Unit,
    painter: Painter?,
    modifier: Modifier = Modifier,
    paddingTop: androidx.compose.ui.unit.Dp = 10.dp
) {
    // States to track selection
    var expanded by remember { mutableStateOf(false) }

    val selectedUsers = remember { mutableStateMapOf<User, Boolean>() }
    val selectedTeams = remember { mutableStateMapOf<Team, Boolean>() }

    if (preSelectedUsers.isNotEmpty() || preSelectedTeams.isNotEmpty()) {
        LaunchedEffect(Unit) {
            preSelectedUsers.forEach {
                selectedUsers[it] = true
            }
            preSelectedTeams.forEach {
                selectedTeams[it] = true
            }
        }
    }

    Box(modifier = modifier
        .fillMaxWidth()
        .padding(top = 10.dp)
        .noRippleClickable {
            expanded = !expanded
        }) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            colors = OutlinedTextFieldDefaults.colors(
                disabledBorderColor = InputBorderColor,
                unfocusedBorderColor = InputBorderColor,
                disabledTextColor = TextColor
            ),
            enabled = false,
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),
            trailingIcon = {
                if (painter != null) {
                    Icon(
                        painter = painter,
                        contentDescription = "Dropdown"
                    )
                }
            }
        )

        DropdownMenu(
            expanded = expanded,
            onDismissRequest = { expanded = false },
            modifier = Modifier.fillMaxWidth()
        ) {
            val buttonText = if (selectedTeams.isEmpty() && selectedUsers.isEmpty()) {
                "Assign"
            } else {
                stringResource(Res.string.assigned)
            }
            Button(
                colors = androidx.compose.material.ButtonDefaults.buttonColors(BlueApp),
                shape = RoundedCornerShape(Dp(32f)),
                modifier = Modifier.fillMaxWidth()
                    .padding(start = 32.dp, top = 8.dp, end = 32.dp, bottom = 8.dp),
                onClick = {
                    text.value = buttonText
                    expanded = !expanded
                    onContactSelected(
                        selectedUsers.filter { it.value }.keys.toList(),
                        selectedTeams.filter { it.value }.keys.toList()
                    )
                }) {
                Text(
                    text = stringResource(Res.string.confirm),
                    modifier = Modifier.padding(vertical = 8.dp),
                    color = Color.White
                )
            }
            // User List Section
            Text(
                text = stringResource(Res.string.users_title),
                modifier = Modifier.padding(8.dp),
                style = MaterialTheme.typography.subtitle1
            )
            users.forEach { user ->
                DropdownMenuItem(
                    text = {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Checkbox(
                                checked = selectedUsers[user] == true,
                                onCheckedChange = { isChecked ->
                                    if (isChecked) {
                                        selectedUsers[user] = true
                                    } else {
                                        selectedUsers.remove(user)
                                    }
                                }
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            Text(text = user.getName())
                        }
                    },
                    onClick = {
                        val isCurrentlySelected = selectedUsers[user] ?: false
                        if (isCurrentlySelected) {
                            selectedUsers.remove(user)
                        } else {
                            selectedUsers[user] = true
                        }
                    }
                )
            }

            Divider()

            // Team List Section
            Text(
                text = stringResource(Res.string.teams),
                modifier = Modifier.padding(8.dp),
                style = MaterialTheme.typography.subtitle1
            )
            teams.forEach { team ->
                DropdownMenuItem(
                    text = {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Checkbox(
                                checked = selectedTeams[team] == true,
                                onCheckedChange = { isChecked ->
                                    if (isChecked) {
                                        selectedTeams[team] = true
                                    } else {
                                        selectedTeams.remove(team)
                                    }
                                }
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            Text(text = team.name!!)
                        }
                    },
                    onClick = {
                        val isCurrentlySelected = selectedTeams[team] ?: false
                        if (isCurrentlySelected) {
                            selectedTeams.remove(team)
                        } else {
                            selectedTeams[team] = true
                        }
                    }
                )
            }
        }
    }
}

@Composable
fun NoteInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = { newValue ->
                text.value = newValue
            },
            label = {
                androidx.compose.material3.Text(
                    text = label,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            placeholder = {
                androidx.compose.material3.Text(
                    text = placeholder,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            enabled = enabled
        )
    }
}

@Composable
fun AboutContactDropdown(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<Contact>,
    painter: Painter?,
    onContactSelected: (String) -> Unit,
    modifier: Modifier = Modifier,
    paddingTop: androidx.compose.ui.unit.Dp = 10.dp
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
            .noRippleClickable {
                expanded.value = !expanded.value
            }
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier.fillMaxWidth(),
            trailingIcon = {
                if (painter != null) {
                    Icon(
                        painter = painter,
                        contentDescription = "Dropdown"
                    )
                }
            },
            colors = OutlinedTextFieldDefaults.colors(
                disabledBorderColor = InputBorderColor,
                unfocusedBorderColor = InputBorderColor,
                disabledTextColor = TextColor
            ),
            enabled = false
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(
                    onClick = {
                        text.value = item.name.orEmpty()
                        item.id?.let { onContactSelected(it) }
                        expanded.value = false
                    },
                    text = { Text(item.name.orEmpty()) }
                )
            }
        }
    }
}

@Composable
fun AboutSiteDropdown(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<Site>,
    onSiteSelected: (Site) -> Unit,
    painter: Painter?,
    modifier: Modifier = Modifier,
    paddingTop: androidx.compose.ui.unit.Dp = 10.dp
) {
    var expanded = remember { mutableStateOf(false) }

    if (dropdownItems.size == 1) {
        val singleSite = dropdownItems.first()
        text.value = singleSite.siteName.toString()
        onSiteSelected(singleSite)
    }

    val showDropdown = dropdownItems.size > 1

    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
            .then(if (showDropdown) Modifier.noRippleClickable { expanded.value = !expanded.value } else Modifier)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier.fillMaxWidth(),
            trailingIcon = {
                if (showDropdown && painter != null) {
                    Icon(painter = painter, contentDescription = "Dropdown")
                }
            },
            colors = OutlinedTextFieldDefaults.colors(
                disabledBorderColor = InputBorderColor,
                unfocusedBorderColor = InputBorderColor,
                disabledTextColor = TextColor
            ),
            enabled = false
        )

        if (showDropdown) {
            if (expanded.value) {
                text.value = ""
            }
            DropdownMenu(
                expanded = expanded.value,
                onDismissRequest = { expanded.value = false }
            ) {
                dropdownItems.forEach { item ->
                    DropdownMenuItem(onClick = {
                        text.value = item.siteName.toString()
                        onSiteSelected(item)
                        expanded.value = false
                    }, text = {
                        Text(item.siteName.toString())
                    })
                }
            }
        }
    }
}


@Composable
fun CustomTimePicker(
    time: MutableState<String>,
    onTimeSelected: (String) -> Unit,
    showTimePicker: MutableState<Boolean>
) {
    var hour = remember { mutableStateOf("") }
    var minute = remember { mutableStateOf("") }

    val hourFocusRequester = remember { FocusRequester() }
    val minuteFocusRequester = remember { FocusRequester() }

    var isHourFocused = remember { mutableStateOf(false) }
    var isMinuteFocused = remember { mutableStateOf(false) }
    val isValidTime = remember { mutableStateOf(true) }

    AlertDialog(
        onDismissRequest = { showTimePicker.value = false },
        title = {
            Text(
                text = stringResource(Res.string.enter_time),
                modifier = Modifier.padding(start = 8.dp),
                color = Color.Gray,
                fontWeight = FontWeight.W500,
                fontSize = 12.sp
            )
        },
        buttons = {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(8.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(
                    painter = painterResource(Res.drawable.clock_small),
                    contentDescription = "Time Icon",
                    modifier = Modifier.size(24.dp).offset(x = 20.dp)
                )
                Row {
                    TextButton(onClick = { showTimePicker.value = false }) {
                        Text(text = stringResource(Res.string.cancel), color = Color.Red)
                    }
                    TextButton(
                        onClick = {
                            if (isValidTime.value) {
                                val formattedHour = hour.value.padStart(2, '0')
                                val formattedMinute = minute.value.padStart(2, '0')
                                time.value = "$formattedHour:$formattedMinute"
                                onTimeSelected(time.value)
                                showTimePicker.value = false
                            }
                        },
                    ) {
                        Text(text = stringResource(Res.string.ok), color = BlueApp)
                    }
                }
            }
        },
        text = {
            Column(
                modifier = Modifier.padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.fillMaxWidth().height(75.dp)
                ) {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier.weight(1f)
                    ) {
                        BasicTextField(
                            value = hour.value,
                            onValueChange = { newHour ->
                                hour.value = newHour.takeIf { it.length <= 2 } ?: hour.value
                                isValidTime.value = isValidTime(hour.value, minute.value)
                                if (newHour.length == 2) {
                                    minuteFocusRequester.requestFocus()
                                }
                            },
                            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                            modifier = Modifier
                                .weight(1f)
                                .height(48.dp)
                                .focusRequester(hourFocusRequester)
                                .onFocusChanged { focusState ->
                                    isHourFocused.value = focusState.isFocused
                                }
                                .background(Color(0xFFE9F1FF), RoundedCornerShape(8.dp))
                                .border(
                                    width = if (isHourFocused.value) 2.dp else 0.dp,
                                    color = if (isHourFocused.value) BlueApp else Color.Transparent,
                                    shape = RoundedCornerShape(8.dp)
                                )
                                .padding(horizontal = 8.dp, vertical = 8.dp),
                            singleLine = true,
                            textStyle = TextStyle(
                                fontSize = 28.sp,
                                color = BlueApp,
                                lineHeight = 30.sp,
                                textAlign = TextAlign.Center
                            ),
                            decorationBox = { innerTextField ->
                                innerTextField()
                            }
                        )

                        Text(
                            stringResource(Res.string.hour),
                            color = Color.Gray,
                            fontSize = 12.sp,
                            fontWeight = FontWeight.W400,
                            modifier = Modifier.align(Alignment.Start)
                        )
                    }

                    Text(
                        text = ":",
                        fontSize = 40.sp,
                        color = Color.Black,
                        modifier = Modifier.padding(bottom = 30.dp)
                    )

                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier.weight(1f)
                    ) {
                        BasicTextField(
                            value = minute.value,
                            onValueChange = { newMinute ->
                                minute.value = newMinute.takeIf { it.length <= 2 } ?: minute.value
                                isValidTime.value = isValidTime(hour.value, newMinute)
                                if (newMinute.length == 2) {
                                    hourFocusRequester.requestFocus()
                                }
                            },
                            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                            modifier = Modifier
                                .weight(1f)
                                .focusRequester(minuteFocusRequester)
                                .onFocusChanged { focusState ->
                                    isMinuteFocused.value = focusState.isFocused
                                }
                                .background(Color(0xFFE9F1FF), RoundedCornerShape(8.dp))
                                .border(
                                    width = if (isMinuteFocused.value) 2.dp else 0.dp,
                                    color = if (isMinuteFocused.value) BlueApp else Color.Transparent,
                                    shape = RoundedCornerShape(4.dp)
                                )
                                .padding(horizontal = 8.dp, vertical = 8.dp),
                            singleLine = true,
                            textStyle = TextStyle(
                                fontSize = 28.sp,
                                color = BlueApp,
                                lineHeight = 30.sp,
                                textAlign = TextAlign.Center
                            ),
                            decorationBox = { innerTextField ->
                                innerTextField()
                            }
                        )
                        Text(
                            stringResource(Res.string.minute),
                            color = Color.Gray,
                            fontSize = 12.sp,
                            fontWeight = FontWeight.W400,
                            modifier = Modifier.align(Alignment.Start)
                        )
                    }
                }
            }
        }
    )
}

private fun isValidTime(hour: String?, minute: String?): Boolean {
    return hour?.toIntOrNull() in 0..23 && minute?.toIntOrNull() in 0..59
}

@Composable
fun StepInputFields(steps: MutableList<StepData>) {

    Column(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        steps.forEachIndexed { index, step ->
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .shadow(2.dp, shape = RoundedCornerShape(7.dp))
                    .clip(RoundedCornerShape(7.dp))
                    .background(Color.White)
                    .padding(16.dp)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = "${index + 1}. ${stringResource(Res.string.step)}",
                        fontSize = 18.sp,
                        color = Color.Black,
                        modifier = Modifier.weight(1f)
                    )
                    if (index > 0) {
                        Image(
                            painter = painterResource(Res.drawable.trash),
                            contentDescription = null,
                            modifier = Modifier.clickable {
                                steps.removeAt(index)
                            }
                        )
                    }
                }
                AppInputField(
                    step.title,
                    "${index + 1} ${stringResource(Res.string.step_title)}",
                    placeholder = stringResource(Res.string.enter_step_title)
                )
                AppInputField(
                    step.description,
                    "${index + 1} ${stringResource(Res.string.step_description)}",
                    placeholder = stringResource(Res.string.describe_the_process),
                    isMaxLinesDefined = false
                )
                EstimatedDurationInputField(
                    step.estimation,
                    stringResource(Res.string.estimated_duration),
                    placeholder = "30 min"
                )
            }

            Spacer(modifier = Modifier.height(10.dp))
        }

        Text(
            text = "+ ${stringResource(Res.string.continue_with_next_step)}",
            fontSize = 16.sp,
            color = BlueApp,
            textAlign = TextAlign.Center,
            modifier = Modifier
                .clickable {
                    steps.add(StepData())
                }
                .padding(8.dp)
        )
    }
}

data class StepData(
    val title: MutableState<String> = mutableStateOf(""),
    val description: MutableState<String> = mutableStateOf(""),
    val estimation: MutableState<String> = mutableStateOf(""),
    val stepStatus: MutableState<String> = mutableStateOf(Constants.STEP_STATUS_PENDING)
)

@Composable
fun CustomCalendarView(
    isAddEmployee: Boolean,
    onDismiss: () -> Unit,
    onDateSelect: (List<LocalDate>) -> Unit,
    showErrorMessage: () -> Unit
) {

    Dialog(
        onDismissRequest = { onDismiss() },
    ) {
        val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
        val selectedDates = remember { mutableStateListOf<LocalDate>() }

        Column(
            modifier = Modifier
                .background(Color.White),
        ) {
            HorizontalCalendarView(startDate = today) { monthOffset ->
                RangeCalendarView(
                    config = rememberCalendarState(
                        startDate = today,
                        monthOffset = monthOffset,
                        selectedDates = selectedDates,
                    ),
                    selectionMode = if (isAddEmployee) SelectionMode.Range else determineSelectionMode(
                        selectedDates
                    ),
                    onDateSelected = { dates ->
                        val validDates = dates.filter { it >= today }
                        if (validDates.isNotEmpty()) {
                            selectedDates.clear()
                            selectedDates.addAll(validDates)
                            onDateSelect(validDates)
                            if (!isAddEmployee)
                                onDismiss()
                        } else {
                            showErrorMessage()
                        }
                    },
                    rangeConfig = RangeConfig(rangeIllustrator = UnderlineIllustrator(Pallete.LightGreen)),
                )
            }
            if (isAddEmployee) {
                Box(
                    modifier = Modifier.fillMaxWidth().padding(8.dp),
                    contentAlignment = Alignment.CenterEnd
                ) {
                    TextButton(
                        onClick = {
                            onDateSelect(selectedDates)
                            onDismiss()
                        },
                    ) {
                        Text(
                            stringResource(Res.string.done_selecting_dates),
                            fontSize = 14.sp,
                            fontWeight = FontWeight.W500,
                            color = BlueApp
                        )
                    }
                }
            }
//            if (selectedDates.size == 2) {
//                androidx.compose.material3.Text("From:\n${selectedDates.last()} to ${selectedDates.first()}")
//            }
        }
    }
}

private fun determineSelectionMode(selectedDates: List<LocalDate>): SelectionMode {
    return when {
        selectedDates.size == 1 -> SelectionMode.Single
        isConsecutiveDates(selectedDates) -> SelectionMode.Range
        else -> SelectionMode.Multiply()
    }
}

private fun isConsecutiveDates(dates: List<LocalDate>): Boolean {
    if (dates.size < 2) return false
    val sortedDates = dates.sorted()
    return sortedDates.zipWithNext().all { (current, next) ->
        next == current.plus(1, DateTimeUnit.DAY)
    }
}

@Composable
fun OutlinedCalendarInput(
    onClick: () -> Unit,
    input: MutableState<String>,
    modifier: Modifier = Modifier,
    paddingTop: androidx.compose.ui.unit.Dp = 15.dp
) {
    OutlinedButton(
        onClick = { onClick() },
        border = BorderStroke(1.dp, Color.DarkGray),
        shape = RoundedCornerShape(8),
        colors = ButtonDefaults.outlinedButtonColors(
            contentColor = Color.White, containerColor = Color.Transparent
        ),
        modifier = modifier
            .fillMaxWidth()
            .height(73.dp)
            .padding(top = paddingTop)
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = input.value,
                color = Color.DarkGray,
                fontSize = 13.sp,
                textAlign = TextAlign.Start,
                modifier = Modifier.weight(5f).padding(top = 5.dp).offset(x = (-10).dp)
            )
            Icon(
                painter = painterResource(Res.drawable.calendar__1_),
                contentDescription = "",
                modifier = Modifier.offset(x = 10.dp)

            )
        }
    }
}

@Composable
fun AddEmployeeTeamField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<UserTeam>,
    onTeamSelected: (UserTeam) -> Unit
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),
            trailingIcon = {
                Icon(
                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                    contentDescription = "Dropdown",
                    modifier = Modifier.clickable { expanded.value = !expanded.value }
                )
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { team ->
                DropdownMenuItem(onClick = {
                    text.value = team.value ?: ""
                    expanded.value = false
                    onTeamSelected(team)  // Pass the selected team
                }, text = {
                    Text(team.value ?: "")
                })
            }
        }
    }
}

@Composable
fun CustomInputRowEmployee(
    painterResource: DrawableResource,
    labelText: String,
    placeholderText: String,
    inputValue: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    placeholderColor: Color = AddEmployeeInputColor,
    focusManager: FocusManager
) {
    Row(
        modifier = modifier
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .then(modifier)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(painterResource),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = textColor,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }
        OutlinedTextField(
            value = inputValue,
            onValueChange = onValueChange,
            textStyle = TextStyle(fontSize = Sp(14f)),
            placeholder = {
                Text(
                    text = placeholderText,
                    color = placeholderColor,
                    fontSize = 14.sp
                )
            },
            modifier = Modifier
                .weight(2f),
            colors = OutlinedTextFieldDefaults.colors(
                focusedBorderColor = InputBorderColor,
                unfocusedBorderColor = RegisterBorder,
                disabledTextColor = TextColor
            ),
            shape = RoundedCornerShape(Dp(8f))
        )
    }
}

@Composable
fun InputCityCountryZipWeb(
    painterResource: DrawableResource,
    labelText: String,
    placeholderCity: String,
    placeholderCountry: String,
    placeholderZip: String,
    inputValueCity: String,
    inputValueCountry: String,
    inputValueZip: String,
    onCityChange: (String) -> Unit,
    onCountryChange: (String) -> Unit,
    onZipChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    placeholderColor: Color = AddEmployeeInputColor,
    focusManager: FocusManager
) {
    Row(
        modifier = modifier
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(8.dp) // Adds spacing between fields
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(painterResource),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = textColor,
                fontSize = 14.sp,
                modifier = Modifier.padding(start = 20.dp)
            )
        }

        OutlinedTextField(
            value = inputValueCity,
            onValueChange = onCityChange,
            textStyle = TextStyle(fontSize = 14.sp),
            placeholder = { Text(text = placeholderCity, color = placeholderColor) },
            modifier = Modifier.weight(0.8f),
            colors = OutlinedTextFieldDefaults.colors(
                focusedBorderColor = InputBorderColor,
                unfocusedBorderColor = RegisterBorder,
                disabledTextColor = TextColor
            ),
            shape = RoundedCornerShape(8.dp)
        )

        // Country Input
        OutlinedTextField(
            value = inputValueCountry,
            onValueChange = onCountryChange,
            textStyle = TextStyle(fontSize = 14.sp),
            placeholder = { Text(text = placeholderCountry, color = placeholderColor) },
            modifier = Modifier.weight(0.8f),
            colors = OutlinedTextFieldDefaults.colors(
                focusedBorderColor = InputBorderColor,
                unfocusedBorderColor = RegisterBorder,
                disabledTextColor = TextColor
            ),
            shape = RoundedCornerShape(8.dp)
        )

        OutlinedTextField(
            value = inputValueZip,
            onValueChange = onZipChange,
            textStyle = TextStyle(fontSize = 14.sp),
            placeholder = { Text(text = placeholderZip, color = placeholderColor) },
            modifier = Modifier.weight(0.4f),
            colors = OutlinedTextFieldDefaults.colors(
                focusedBorderColor = InputBorderColor,
                unfocusedBorderColor = RegisterBorder,
                disabledTextColor = TextColor
            ),
            shape = RoundedCornerShape(8.dp)
        )
    }
}

@Composable
fun WorkingHoursView(viewModel: AddUserViewModel) {
    LazyColumn(modifier = Modifier.fillMaxWidth()) {
        if (viewModel.daysSchedule.isNotEmpty()) {
            items(viewModel.daysSchedule.takeIf { it.isNotEmpty() } ?: emptyList()) { daySchedule ->
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .weight(1.5f)
                            .padding(top = 8.dp)
                    ) {
                        Switch(
                            checked = daySchedule.isChecked.value,
                            onCheckedChange = {
                                daySchedule.isChecked.value = it
                                if (!it) {
                                    daySchedule.startTime.value = "08:00"
                                    daySchedule.endTime.value = "16:00"
                                }
                            },
                            colors = SwitchDefaults.colors(
                                checkedThumbColor = BlueApp,
                                checkedTrackColor = BlueApp,
                                uncheckedThumbColor = Color.Gray,
                                uncheckedTrackColor = Color.LightGray
                            )
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        Text(
                            text = stringResource(daySchedule.dayName),
                            color = Color.DarkGray,
                            fontSize = 13.sp
                        )
                    }

                    if (daySchedule.isChecked.value) {
                        Spacer(modifier = Modifier.width(8.dp))

                        OutlinedButton(
                            onClick = { daySchedule.isStartTimePickerVisible.value = true },
                            border = BorderStroke(1.dp, Color.DarkGray),
                            shape = RoundedCornerShape(8),
                            colors = ButtonDefaults.outlinedButtonColors(
                                contentColor = Color.White, containerColor = Color.Transparent
                            ),
                            modifier = Modifier
                                .height(58.dp)
                                .weight(1f)
                                .padding(top = 15.dp)
                        ) {
                            Text(
                                text = daySchedule.startTime.value,
                                color = Color.DarkGray,
                                fontSize = 13.sp
                            )
                        }

                        Spacer(modifier = Modifier.width(8.dp))

                        OutlinedButton(
                            onClick = { daySchedule.isEndTimePickerVisible.value = true },
                            border = BorderStroke(1.dp, Color.DarkGray),
                            shape = RoundedCornerShape(8),
                            colors = ButtonDefaults.outlinedButtonColors(
                                contentColor = Color.White, containerColor = Color.Transparent
                            ),
                            modifier = Modifier
                                .height(58.dp)
                                .weight(1f)
                                .padding(top = 15.dp)
                        ) {
                            Text(
                                text = daySchedule.endTime.value,
                                color = Color.DarkGray,
                                fontSize = 13.sp
                            )
                        }
                    }
                }

                if (daySchedule.isChecked.value && daySchedule.isStartTimePickerVisible.value) {
                    CustomTimePicker(
                        time = daySchedule.startTime,
                        showTimePicker = daySchedule.isStartTimePickerVisible,
                        onTimeSelected = { daySchedule.startTime.value = it }
                    )
                }
                if (daySchedule.isChecked.value && daySchedule.isEndTimePickerVisible.value) {
                    CustomTimePicker(
                        time = daySchedule.endTime,
                        showTimePicker = daySchedule.isEndTimePickerVisible,
                        onTimeSelected = { daySchedule.endTime.value = it }
                    )
                }
            }
        }
    }
}

@Composable
fun CustomNumberInputRowEmployee(
    painterResource: DrawableResource,
    labelText: String,
    placeholderText: String,
    inputValue: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    placeholderColor: Color = AddEmployeeInputColor,
    focusManager: FocusManager
) {
    Row(
        modifier = modifier
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .then(modifier)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Box(modifier = Modifier.size(Dp(20f))) {
                Image(
                    painter = painterResource(painterResource),
                    contentDescription = null
                )
            }
            Text(
                text = labelText,
                color = textColor,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }
        OutlinedTextField(
            value = inputValue,
            onValueChange = { newValue ->
                // Allow only numeric characters or "+" as the first character
                if (newValue.all { it.isDigit() || it == '+' } &&
                    (newValue.count { it == '+' } <= 1 && (newValue.indexOf('+') <= 0))) {
                    onValueChange(newValue)
                }
            },
            textStyle = TextStyle(fontSize = Sp(14f)),
            placeholder = {
                Text(
                    text = placeholderText,
                    color = placeholderColor,
                    fontSize = Sp(14f)
                )
            },
            modifier = Modifier
                .weight(2f),
            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Phone),
            colors = OutlinedTextFieldDefaults.colors(
                focusedBorderColor = InputBorderColor,
                unfocusedBorderColor = RegisterBorder,
                disabledTextColor = TextColor
            ),
            shape = RoundedCornerShape(Dp(8f))
        )
    }
}


@Composable
fun <T> CustomDropdownMenu(
    iconRes: DrawableResource,
    labelText: String,
    items: List<T>,
    selectedValue: T,
    onValueSelected: (T) -> Unit,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    placeholderColor: Color = AddEmployeeInputColor,
    displayText: (T) -> String,
    focusManager: FocusManager
) {
    var expanded = remember { mutableStateOf(false) }
    var selectedOption = remember { mutableStateOf(selectedValue) }
    val density = LocalDensity.current
    val inputFieldWidth = remember { mutableStateOf(0.dp) }

    Row(
        modifier = modifier
            .fillMaxWidth()
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .then(modifier),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Box(modifier = Modifier.size(Dp(20f))) {
                Image(
                    painter = painterResource(iconRes),
                    contentDescription = null
                )
            }
            Text(
                text = labelText,
                color = textColor,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }

        Box(
            modifier = Modifier
                .weight(2f)
                .height(50.dp)
                .border(
                    width = 1.dp,
                    color = RegisterBorder,
                    shape = RoundedCornerShape(Dp(8f))
                )
                .padding(horizontal = Dp(20f), vertical = Dp(10f))
                .onGloballyPositioned { coordinates ->
                    inputFieldWidth.value = with(density) { coordinates.size.width.toDp() }
                }
                .clickable { expanded.value = true }
        ) {
            Text(
                text = displayText(selectedOption.value),
                color = if (selectedOption == selectedValue) placeholderColor else textColor,
                fontSize = Sp(14f),
            )
            Icon(
                painter = painterResource(Res.drawable.dropdown_arrow),
                contentDescription = null,
                tint = Color(0xFF676767),
                modifier = Modifier
                    .align(Alignment.CenterEnd)
            )

            DropdownMenu(
                expanded = expanded.value,
                onDismissRequest = { expanded.value = false }
            ) {
                items.forEach { option ->
                    DropdownMenuItem(
                        modifier = Modifier.width(inputFieldWidth.value),
                        onClick = {
                            selectedOption.value = option
                            onValueSelected(option)
                            expanded.value = false
                        },
                        text = {
                            Text(displayText(option))
                        }
                    )
                }
            }
        }
    }
}

@Composable
fun <T> TeamsDropdownMenu(
    iconRes: DrawableResource,
    labelText: String,
    selectedValue: T,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    itemsFromDb: List<Team>,
    selectedTeam: Team?,
    onItemSelected: (Team) -> Unit,
    onAddNewItem: (String) -> Unit
) {
    var expanded = remember { mutableStateOf(false) }
    var selectedOption = remember { mutableStateOf(selectedValue) }
    val density = LocalDensity.current
    val inputFieldWidth = remember { mutableStateOf(0.dp) }

    var text by remember { mutableStateOf(selectedTeam?.name ?: "") }
    val focusManager = LocalFocusManager.current

    LaunchedEffect(selectedTeam) {
        text = selectedTeam?.name ?: ""
    }


    Row(
        modifier = modifier
            .fillMaxWidth()
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .then(modifier),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Box(modifier = Modifier.size(Dp(20f))) {
                Image(
                    painter = painterResource(iconRes),
                    contentDescription = null
                )
            }
            Text(
                text = labelText,
                color = textColor,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }

        Box(
            modifier = Modifier
                .weight(2f)
                .height(50.dp)
                .border(
                    width = 1.dp,
                    color = RegisterBorder,
                    shape = RoundedCornerShape(8.dp)
                )
                .padding(horizontal = 20.dp, vertical = 10.dp)
                .onGloballyPositioned { coordinates ->
                    inputFieldWidth.value = with(density) { coordinates.size.width.toDp() }
                }
                .clickable { expanded.value = true }
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                BasicTextField(
                    value = text,
                    onValueChange = { text = it },
                    modifier = Modifier
                        .weight(2f)
                        .clickable { expanded.value = true },
                    singleLine = true,
                    textStyle = TextStyle(
                        color = if (selectedOption == selectedValue) AddEmployeeInputColor else textColor,
                        fontSize = 14.sp
                    ),
                    decorationBox = { innerTextField ->
                        if (text.isEmpty()) {
                            Text(
                                text = stringResource(Res.string.select_team),
                                color = Color.Gray,
                                fontSize = 14.sp
                            )
                        }
                        innerTextField()
                    },
                    keyboardOptions = KeyboardOptions.Default.copy(
                        imeAction = ImeAction.Done
                    ),
                    keyboardActions = KeyboardActions(
                        onDone = {
                            if (text.isNotEmpty()) {
                                onAddNewItem(text)
                            }
                            focusManager.moveFocus(FocusDirection.Next)
                        }
                    )
                )
                IconButton(onClick = { expanded.value = !expanded.value }) {
                    Icon(
                        painter = painterResource(Res.drawable.dropdown_arrow),
                        contentDescription = "Dropdown Icon",
                        modifier = Modifier.padding(start = 30.dp),
                        tint = Color(0xFF676767)
                    )
                }
            }
            DropdownMenu(
                expanded = expanded.value,
                onDismissRequest = { expanded.value = false }
            ) {
                itemsFromDb.forEach { item ->
                    DropdownMenuItem(
                        onClick = {
                            text = item.name ?: ""
                            onItemSelected(item)
                            expanded.value = false
                        },
                        text = {
                            Text(text = item.name ?: "")
                        }
                    )
                }
            }

        }
    }
}


@Composable
fun <T> SkillsDropdownMenu(
    iconRes: DrawableResource,
    labelText: String,
    selectedValue: T,
    modifier: Modifier = Modifier,
    textColor: Color = EmployeeDetailsText,
    selectedItems: MutableState<List<String>>,
    onItemSelected: (List<String>) -> Unit,
    dropdownItems: MutableState<List<String>>,
    preselectedItems: MutableState<List<String>>,
    onAddNewValue: (String) -> Unit
) {
    var expanded = remember { mutableStateOf(false) }
    var selectedOption = remember { mutableStateOf(selectedValue) }
    val density = LocalDensity.current
    val inputFieldWidth = remember { mutableStateOf(0.dp) }

    val newItemText = remember { mutableStateOf("") }
    val focusManager = LocalFocusManager.current

    LaunchedEffect(preselectedItems.value) {
        preselectedItems.value.forEach { preselectedItem ->
            if (preselectedItem in dropdownItems.value && preselectedItem !in selectedItems.value) {
                selectedItems.value += preselectedItem
                onItemSelected(selectedItems.value)
            }
        }
    }


    Row(
        modifier = modifier
            .fillMaxWidth()
            .onPreviewKeyEvent {
                when {
                    KeyEventType.KeyUp == it.type && Key.Tab == it.key -> {
                        focusManager.moveFocus(FocusDirection.Next)
                        true
                    }

                    else -> false
                }
            }
            .then(modifier),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Box(modifier = Modifier.size(Dp(20f))) {
                Image(
                    painter = painterResource(iconRes),
                    contentDescription = null
                )
            }
            Text(
                text = labelText,
                color = textColor,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }

        Box(
            modifier = Modifier
                .weight(2f)
                .height(50.dp)
                .border(
                    width = 1.dp,
                    color = RegisterBorder,
                    shape = RoundedCornerShape(8.dp)
                )
                .padding(horizontal = 20.dp, vertical = 10.dp)
                .onGloballyPositioned { coordinates ->
                    inputFieldWidth.value = with(density) { coordinates.size.width.toDp() }
                }
                .clickable { expanded.value = true }
        ) {
            Column {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    BasicTextField(
                        value = selectedItems.value.joinToString(", ") + newItemText.value,
                        onValueChange = {
                            newItemText.value = it
                        },
                        modifier = Modifier
                            .weight(2f),
                        readOnly = selectedItems.value.isNotEmpty(),
                        keyboardOptions = KeyboardOptions.Default.copy(imeAction = ImeAction.Done),
                        keyboardActions = KeyboardActions(
                            onDone = {
                                if (newItemText.value.isNotBlank()) {
                                    val newItem = newItemText.value.trim()
                                    if (newItem !in dropdownItems.value) {
                                        dropdownItems.value += newItem
                                    }
                                    selectedItems.value += newItem
                                    newItemText.value = ""

                                    onAddNewValue(newItem)
                                    focusManager.moveFocus(FocusDirection.Next)
                                }
                            }
                        )
                    )
                    IconButton(onClick = { expanded.value = !expanded.value }) {
                        Icon(
                            painter = painterResource(Res.drawable.dropdown_arrow),
                            contentDescription = "Dropdown Icon",
                            modifier = Modifier.padding(start = 30.dp),
                            tint = Color(0xFF676767)
                        )
                    }
                }
            }

            DropdownMenu(
                expanded = expanded.value,
                onDismissRequest = { expanded.value = false }
            ) {
                dropdownItems.value.forEach { item ->
                    DropdownMenuItem(onClick = {
                        if (item !in selectedItems.value) {
                            selectedItems.value += item
                        }
                        expanded.value = false
                    }, text = {
                        Row(
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            androidx.compose.material3.Checkbox(
                                checked = item in selectedItems.value,
                                onCheckedChange = { isChecked ->
                                    selectedItems.value = if (isChecked) {
                                        selectedItems.value + item
                                    } else {
                                        selectedItems.value - item
                                    }
                                    onItemSelected(selectedItems.value)
                                },
                                colors = CheckboxDefaults.colors(
                                    checkedColor = BlueApp,
                                    uncheckedColor = com.hyperether.goodjob.theme.Divider
                                )
                            )
                            Text(item, fontSize = 14.sp)
                        }
                    })
                }
            }

        }
    }
}


@Composable
fun <T> DropdownSortBy(
    shouldAppendSortBy: Boolean,
    placeholderColor: Color,
    items: List<T>,
    selectedValue: T,
    displayText: (T) -> String,
    textColor: Color,
    onValueSelected: (T) -> Unit,
    modifier: Modifier
) {
    val expanded = remember { mutableStateOf(false) }
    val selectedOption = remember { mutableStateOf(selectedValue) }
    val inputFieldWidth = remember { mutableStateOf(0.dp) }
    val density = LocalDensity.current

    Row(
        modifier = modifier
            .height(Dp(55f))
            .width(Dp(180f))
            .border(
                width = Dp(1f),
                color = placeholderColor,
                shape = RoundedCornerShape(Dp(8f))
            )
            .onGloballyPositioned { coordinates ->
                inputFieldWidth.value = with(density) { coordinates.size.width.toDp() }
            }
            .clickable { expanded.value = true }
            .padding(horizontal = Dp(12f), vertical = Dp(8f)),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .weight(1f)
                .padding(start = Dp(4f))
        ) {
            Text(
                text = buildAnnotatedString {
                    if (shouldAppendSortBy) {
                        withStyle(
                            style = SpanStyle(
                                fontWeight = FontWeight.W400,
                                color = HeaderText
                            )
                        ) {
                            append(stringResource(Res.string.sort_by) + " ")
                        }
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black
                        )
                    ) {
                        append(displayText(selectedOption.value))
                    }
                },
                color = if (selectedOption.value == selectedValue) placeholderColor else textColor,
                fontSize = Sp(14f)
            )
        }

        Icon(
            painter = painterResource(Res.drawable.dropdown_arrow),
            contentDescription = null,
            tint = Color(0xFF676767),
            modifier = Modifier.padding(start = Dp(8f))
        )
    }

    DropdownMenu(
        expanded = expanded.value,
        onDismissRequest = { expanded.value = false },
        modifier = Modifier.width(inputFieldWidth.value)
    ) {
        items.forEach { option ->
            DropdownMenuItem(
                onClick = {
                    selectedOption.value = option
                    onValueSelected(option)
                    expanded.value = false
                },
                text = { Text(displayText(option)) }
            )
        }
    }
}