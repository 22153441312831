@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.hyperether.goodjob.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val access_dashboard: StringResource by 
      lazy { init_access_dashboard() }

  public val active: StringResource by 
      lazy { init_active() }

  public val add: StringResource by 
      lazy { init_add() }

  public val add_new: StringResource by 
      lazy { init_add_new() }

  public val add_new_employee: StringResource by 
      lazy { init_add_new_employee() }

  public val add_new_job: StringResource by 
      lazy { init_add_new_job() }

  public val add_new_workspace: StringResource by 
      lazy { init_add_new_workspace() }

  public val address: StringResource by 
      lazy { init_address() }

  public val all: StringResource by 
      lazy { init_all() }

  public val all_rights_reserved: StringResource by 
      lazy { init_all_rights_reserved() }

  public val already_have_an_account: StringResource by 
      lazy { init_already_have_an_account() }

  public val already_have_workspace: StringResource by 
      lazy { init_already_have_workspace() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val back: StringResource by 
      lazy { init_back() }

  public val built_for: StringResource by 
      lazy { init_built_for() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val choose_category: StringResource by 
      lazy { init_choose_category() }

  public val choose_plan: StringResource by 
      lazy { init_choose_plan() }

  public val choose_the_plan: StringResource by 
      lazy { init_choose_the_plan() }

  public val choose_the_right_plan: StringResource by 
      lazy { init_choose_the_right_plan() }

  public val choose_your_workspace: StringResource by 
      lazy { init_choose_your_workspace() }

  public val click_here: StringResource by 
      lazy { init_click_here() }

  public val contact_us: StringResource by 
      lazy { init_contact_us() }

  public val continue_with_apple: StringResource by 
      lazy { init_continue_with_apple() }

  public val continue_with_fb: StringResource by 
      lazy { init_continue_with_fb() }

  public val continue_with_google: StringResource by 
      lazy { init_continue_with_google() }

  public val create: StringResource by 
      lazy { init_create() }

  public val create_new_task: StringResource by 
      lazy { init_create_new_task() }

  public val create_workspace: StringResource by 
      lazy { init_create_workspace() }

  public val dashboard: StringResource by 
      lazy { init_dashboard() }

  public val day_free_trial: StringResource by 
      lazy { init_day_free_trial() }

  public val dedicated_support: StringResource by 
      lazy { init_dedicated_support() }

  public val delete: StringResource by 
      lazy { init_delete() }

  public val edit: StringResource by 
      lazy { init_edit() }

  public val effortless_efficiency: StringResource by 
      lazy { init_effortless_efficiency() }

  public val email_address: StringResource by 
      lazy { init_email_address() }

  public val employees: StringResource by 
      lazy { init_employees() }

  public val enter_employee_email: StringResource by 
      lazy { init_enter_employee_email() }

  public val enter_street: StringResource by 
      lazy { init_enter_street() }

  public val enter_surname: StringResource by 
      lazy { init_enter_surname() }

  public val enter_workspace_name: StringResource by 
      lazy { init_enter_workspace_name() }

  public val enter_your_email: StringResource by 
      lazy { init_enter_your_email() }

  public val enter_zip: StringResource by 
      lazy { init_enter_zip() }

  public val export_list: StringResource by 
      lazy { init_export_list() }

  public val fast_track: StringResource by 
      lazy { init_fast_track() }

  public val fast_track_support: StringResource by 
      lazy { init_fast_track_support() }

  public val filter: StringResource by 
      lazy { init_filter() }

  public val fly_high: StringResource by 
      lazy { init_fly_high() }

  public val fly_high_description: StringResource by 
      lazy { init_fly_high_description() }

  public val fly_high_feature_1: StringResource by 
      lazy { init_fly_high_feature_1() }

  public val fly_high_feature_2: StringResource by 
      lazy { init_fly_high_feature_2() }

  public val fly_high_plan_description: StringResource by 
      lazy { init_fly_high_plan_description() }

  public val fly_high_price: StringResource by 
      lazy { init_fly_high_price() }

  public val forgot_password: StringResource by 
      lazy { init_forgot_password() }

  public val free: StringResource by 
      lazy { init_free() }

  public val free_100: StringResource by 
      lazy { init_free_100() }

  public val freebie: StringResource by 
      lazy { init_freebie() }

  public val freebie_description: StringResource by 
      lazy { init_freebie_description() }

  public val freebie_feature_1: StringResource by 
      lazy { init_freebie_feature_1() }

  public val freebie_feature_2: StringResource by 
      lazy { init_freebie_feature_2() }

  public val freebie_plan_description: StringResource by 
      lazy { init_freebie_plan_description() }

  public val freebie_price: StringResource by 
      lazy { init_freebie_price() }

  public val from_small_teams: StringResource by 
      lazy { init_from_small_teams() }

  public val full_name: StringResource by 
      lazy { init_full_name() }

  public val galactic: StringResource by 
      lazy { init_galactic() }

  public val galactic_description: StringResource by 
      lazy { init_galactic_description() }

  public val galactic_feature_1: StringResource by 
      lazy { init_galactic_feature_1() }

  public val galactic_feature_2: StringResource by 
      lazy { init_galactic_feature_2() }

  public val galactic_plan_description: StringResource by 
      lazy { init_galactic_plan_description() }

  public val galactic_price: StringResource by 
      lazy { init_galactic_price() }

  public val hello: StringResource by 
      lazy { init_hello() }

  public val high_five: StringResource by 
      lazy { init_high_five() }

  public val high_five_description: StringResource by 
      lazy { init_high_five_description() }

  public val high_five_feature_1: StringResource by 
      lazy { init_high_five_feature_1() }

  public val high_five_feature_2: StringResource by 
      lazy { init_high_five_feature_2() }

  public val high_five_plan_description: StringResource by 
      lazy { init_high_five_plan_description() }

  public val high_five_price: StringResource by 
      lazy { init_high_five_price() }

  public val import_list: StringResource by 
      lazy { init_import_list() }

  public val inactive: StringResource by 
      lazy { init_inactive() }

  public val jobs_overview: StringResource by 
      lazy { init_jobs_overview() }

  public val join_good_job: StringResource by 
      lazy { init_join_good_job() }

  public val join_us: StringResource by 
      lazy { init_join_us() }

  public val last_accessed: StringResource by 
      lazy { init_last_accessed() }

  public val login_register: StringResource by 
      lazy { init_login_register() }

  public val manage_employees: StringResource by 
      lazy { init_manage_employees() }

  public val manager: StringResource by 
      lazy { init_manager() }

  public val month: StringResource by 
      lazy { init_month() }

  public val most_popular: StringResource by 
      lazy { init_most_popular() }

  public val need_an_account: StringResource by 
      lazy { init_need_an_account() }

  public val new_workspace: StringResource by 
      lazy { init_new_workspace() }

  public val next: StringResource by 
      lazy { init_next() }

  public val onboarding_description1: StringResource by 
      lazy { init_onboarding_description1() }

  public val onboarding_description2: StringResource by 
      lazy { init_onboarding_description2() }

  public val optimize_your: StringResource by 
      lazy { init_optimize_your() }

  public val password: StringResource by 
      lazy { init_password() }

  public val phone: StringResource by 
      lazy { init_phone() }

  public val plan: StringResource by 
      lazy { init_plan() }

  public val powerful_tools: StringResource by 
      lazy { init_powerful_tools() }

  public val pricing: StringResource by 
      lazy { init_pricing() }

  public val priority_support: StringResource by 
      lazy { init_priority_support() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val ready_for: StringResource by 
      lazy { init_ready_for() }

  public val register: StringResource by 
      lazy { init_register() }

  public val register_with: StringResource by 
      lazy { init_register_with() }

  public val role: StringResource by 
      lazy { init_role() }

  public val select_skill: StringResource by 
      lazy { init_select_skill() }

  public val select_team: StringResource by 
      lazy { init_select_team() }

  public val select_your_workspace: StringResource by 
      lazy { init_select_your_workspace() }

  public val set_up_workspace: StringResource by 
      lazy { init_set_up_workspace() }

  public val sign_in: StringResource by 
      lazy { init_sign_in() }

  public val sign_in_with: StringResource by 
      lazy { init_sign_in_with() }

  public val skills: StringResource by 
      lazy { init_skills() }

  public val skip: StringResource by 
      lazy { init_skip() }

  public val start_organizing: StringResource by 
      lazy { init_start_organizing() }

  public val status: StringResource by 
      lazy { init_status() }

  public val subscribe: StringResource by 
      lazy { init_subscribe() }

  public val support: StringResource by 
      lazy { init_support() }

  public val tap_to_upload_photo: StringResource by 
      lazy { init_tap_to_upload_photo() }

  public val team: StringResource by 
      lazy { init_team() }

  public val teams: StringResource by 
      lazy { init_teams() }

  public val terms_of_service: StringResource by 
      lazy { init_terms_of_service() }

  public val today: StringResource by 
      lazy { init_today() }

  public val today_schedule: StringResource by 
      lazy { init_today_schedule() }

  public val total: StringResource by 
      lazy { init_total() }

  public val transform: StringResource by 
      lazy { init_transform() }

  public val transparent_flexible: StringResource by 
      lazy { init_transparent_flexible() }

  public val unlimited_storage: StringResource by 
      lazy { init_unlimited_storage() }

  public val user: StringResource by 
      lazy { init_user() }

  public val users: StringResource by 
      lazy { init_users() }

  public val welcome: StringResource by 
      lazy { init_welcome() }

  public val welcome_back: StringResource by 
      lazy { init_welcome_back() }

  public val whether: StringResource by 
      lazy { init_whether() }

  public val workspace_name: StringResource by 
      lazy { init_workspace_name() }

  public val workspace_type: StringResource by 
      lazy { init_workspace_type() }

  public val your_email_address: StringResource by 
      lazy { init_your_email_address() }

  public val your_full_name: StringResource by 
      lazy { init_your_full_name() }

  public val your_password: StringResource by 
      lazy { init_your_password() }

  public val zip: StringResource by 
      lazy { init_zip() }
}

public val Res.string.access_dashboard: StringResource
  get() = CommonMainString0.access_dashboard

private fun init_access_dashboard(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:access_dashboard", "access_dashboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10, 48),
    )
)

public val Res.string.active: StringResource
  get() = CommonMainString0.active

private fun init_active(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:active", "active",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 59, 22),
    )
)

public val Res.string.add: StringResource
  get() = CommonMainString0.add

private fun init_add(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add", "add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 273, 15),
    )
)

public val Res.string.add_new: StringResource
  get() = CommonMainString0.add_new

private fun init_add_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new", "add_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 217, 27),
    )
)

public val Res.string.add_new_employee: StringResource
  get() = CommonMainString0.add_new_employee

private fun init_add_new_employee(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_new_employee", "add_new_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 82, 48),
    )
)

public val Res.string.add_new_job: StringResource
  get() = CommonMainString0.add_new_job

private fun init_add_new_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new_job", "add_new_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 131, 35),
    )
)

public val Res.string.add_new_workspace: StringResource
  get() = CommonMainString0.add_new_workspace

private fun init_add_new_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_new_workspace", "add_new_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 167, 49),
    )
)

public val Res.string.address: StringResource
  get() = CommonMainString0.address

private fun init_address(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:address", "address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 245, 27),
    )
)

public val Res.string.all: StringResource
  get() = CommonMainString0.all

private fun init_all(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:all", "all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 405, 15),
    )
)

public val Res.string.all_rights_reserved: StringResource
  get() = CommonMainString0.all_rights_reserved

private fun init_all_rights_reserved(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:all_rights_reserved", "all_rights_reserved",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 289, 115),
    )
)

public val Res.string.already_have_an_account: StringResource
  get() = CommonMainString0.already_have_an_account

private fun init_already_have_an_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_an_account", "already_have_an_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 421, 75),
    )
)

public val Res.string.already_have_workspace: StringResource
  get() = CommonMainString0.already_have_workspace

private fun init_already_have_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_workspace", "already_have_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 497, 66),
    )
)

public val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 564, 28),
    )
)

public val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 593, 20),
    )
)

public val Res.string.built_for: StringResource
  get() = CommonMainString0.built_for

private fun init_built_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:built_for", "built_for",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 614, 125),
    )
)

public val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 740, 22),
    )
)

public val Res.string.choose_category: StringResource
  get() = CommonMainString0.choose_category

private fun init_choose_category(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_category", "choose_category",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 763, 51),
    )
)

public val Res.string.choose_plan: StringResource
  get() = CommonMainString0.choose_plan

private fun init_choose_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_plan", "choose_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 815, 35),
    )
)

public val Res.string.choose_the_plan: StringResource
  get() = CommonMainString0.choose_the_plan

private fun init_choose_the_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_the_plan", "choose_the_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 851, 87),
    )
)

public val Res.string.choose_the_right_plan: StringResource
  get() = CommonMainString0.choose_the_right_plan

private fun init_choose_the_right_plan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:choose_the_right_plan", "choose_the_right_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 939, 69),
    )
)

public val Res.string.choose_your_workspace: StringResource
  get() = CommonMainString0.choose_your_workspace

private fun init_choose_your_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:choose_your_workspace", "choose_your_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1009, 89),
    )
)

public val Res.string.click_here: StringResource
  get() = CommonMainString0.click_here

private fun init_click_here(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:click_here", "click_here",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1099, 50),
    )
)

public val Res.string.contact_us: StringResource
  get() = CommonMainString0.contact_us

private fun init_contact_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_us", "contact_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1150, 34),
    )
)

public val Res.string.continue_with_apple: StringResource
  get() = CommonMainString0.continue_with_apple

private fun init_continue_with_apple(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_apple", "continue_with_apple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1185, 55),
    )
)

public val Res.string.continue_with_fb: StringResource
  get() = CommonMainString0.continue_with_fb

private fun init_continue_with_fb(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_fb", "continue_with_fb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1241, 56),
    )
)

public val Res.string.continue_with_google: StringResource
  get() = CommonMainString0.continue_with_google

private fun init_continue_with_google(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_google", "continue_with_google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1298, 56),
    )
)

public val Res.string.create: StringResource
  get() = CommonMainString0.create

private fun init_create(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create", "create",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1528, 22),
    )
)

public val Res.string.create_new_task: StringResource
  get() = CommonMainString0.create_new_task

private fun init_create_new_task(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create_new_task", "create_new_task",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1355, 115),
    )
)

public val Res.string.create_workspace: StringResource
  get() = CommonMainString0.create_workspace

private fun init_create_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_workspace", "create_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1471, 56),
    )
)

public val Res.string.dashboard: StringResource
  get() = CommonMainString0.dashboard

private fun init_dashboard(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dashboard", "dashboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1551, 29),
    )
)

public val Res.string.day_free_trial: StringResource
  get() = CommonMainString0.day_free_trial

private fun init_day_free_trial(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:day_free_trial", "day_free_trial",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1581, 66),
    )
)

public val Res.string.dedicated_support: StringResource
  get() = CommonMainString0.dedicated_support

private fun init_dedicated_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:dedicated_support", "dedicated_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1648, 69),
    )
)

public val Res.string.delete: StringResource
  get() = CommonMainString0.delete

private fun init_delete(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete", "delete",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1718, 22),
    )
)

public val Res.string.edit: StringResource
  get() = CommonMainString0.edit

private fun init_edit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:edit", "edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1741, 20),
    )
)

public val Res.string.effortless_efficiency: StringResource
  get() = CommonMainString0.effortless_efficiency

private fun init_effortless_efficiency(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:effortless_efficiency", "effortless_efficiency",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1762, 57),
    )
)

public val Res.string.email_address: StringResource
  get() = CommonMainString0.email_address

private fun init_email_address(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email_address", "email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1820, 41),
    )
)

public val Res.string.employees: StringResource
  get() = CommonMainString0.employees

private fun init_employees(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:employees", "employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1862, 29),
    )
)

public val Res.string.enter_employee_email: StringResource
  get() = CommonMainString0.enter_employee_email

private fun init_enter_employee_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_employee_email", "enter_employee_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1892, 68),
    )
)

public val Res.string.enter_street: StringResource
  get() = CommonMainString0.enter_street

private fun init_enter_street(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_street", "enter_street",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1961, 72),
    )
)

public val Res.string.enter_surname: StringResource
  get() = CommonMainString0.enter_surname

private fun init_enter_surname(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_surname", "enter_surname",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2034, 53),
    )
)

public val Res.string.enter_workspace_name: StringResource
  get() = CommonMainString0.enter_workspace_name

private fun init_enter_workspace_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_workspace_name", "enter_workspace_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2088, 72),
    )
)

public val Res.string.enter_your_email: StringResource
  get() = CommonMainString0.enter_your_email

private fun init_enter_your_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_email", "enter_your_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2161, 48),
    )
)

public val Res.string.enter_zip: StringResource
  get() = CommonMainString0.enter_zip

private fun init_enter_zip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_zip", "enter_zip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2210, 49),
    )
)

public val Res.string.export_list: StringResource
  get() = CommonMainString0.export_list

private fun init_export_list(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:export_list", "export_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2260, 35),
    )
)

public val Res.string.fast_track: StringResource
  get() = CommonMainString0.fast_track

private fun init_fast_track(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fast_track", "fast_track",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2367, 62),
    )
)

public val Res.string.fast_track_support: StringResource
  get() = CommonMainString0.fast_track_support

private fun init_fast_track_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fast_track_support", "fast_track_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2296, 70),
    )
)

public val Res.string.filter: StringResource
  get() = CommonMainString0.filter

private fun init_filter(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:filter", "filter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2430, 22),
    )
)

public val Res.string.fly_high: StringResource
  get() = CommonMainString0.fly_high

private fun init_fly_high(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fly_high", "fly_high",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2897, 28),
    )
)

public val Res.string.fly_high_description: StringResource
  get() = CommonMainString0.fly_high_description

private fun init_fly_high_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_description", "fly_high_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2453, 136),
    )
)

public val Res.string.fly_high_feature_1: StringResource
  get() = CommonMainString0.fly_high_feature_1

private fun init_fly_high_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_feature_1", "fly_high_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2590, 54),
    )
)

public val Res.string.fly_high_feature_2: StringResource
  get() = CommonMainString0.fly_high_feature_2

private fun init_fly_high_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_feature_2", "fly_high_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2645, 70),
    )
)

public val Res.string.fly_high_plan_description: StringResource
  get() = CommonMainString0.fly_high_plan_description

private fun init_fly_high_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_plan_description", "fly_high_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2716, 141),
    )
)

public val Res.string.fly_high_price: StringResource
  get() = CommonMainString0.fly_high_price

private fun init_fly_high_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fly_high_price", "fly_high_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2858, 38),
    )
)

public val Res.string.forgot_password: StringResource
  get() = CommonMainString0.forgot_password

private fun init_forgot_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forgot_password", "forgot_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2926, 47),
    )
)

public val Res.string.free: StringResource
  get() = CommonMainString0.free

private fun init_free(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free", "free",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3530, 20),
    )
)

public val Res.string.free_100: StringResource
  get() = CommonMainString0.free_100

private fun init_free_100(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free_100", "free_100",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2974, 108),
    )
)

public val Res.string.freebie: StringResource
  get() = CommonMainString0.freebie

private fun init_freebie(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:freebie", "freebie",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3502, 27),
    )
)

public val Res.string.freebie_description: StringResource
  get() = CommonMainString0.freebie_description

private fun init_freebie_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_description", "freebie_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3083, 135),
    )
)

public val Res.string.freebie_feature_1: StringResource
  get() = CommonMainString0.freebie_feature_1

private fun init_freebie_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_feature_1", "freebie_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3219, 49),
    )
)

public val Res.string.freebie_feature_2: StringResource
  get() = CommonMainString0.freebie_feature_2

private fun init_freebie_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_feature_2", "freebie_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3269, 57),
    )
)

public val Res.string.freebie_plan_description: StringResource
  get() = CommonMainString0.freebie_plan_description

private fun init_freebie_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_plan_description", "freebie_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3327, 140),
    )
)

public val Res.string.freebie_price: StringResource
  get() = CommonMainString0.freebie_price

private fun init_freebie_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:freebie_price", "freebie_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3468, 33),
    )
)

public val Res.string.from_small_teams: StringResource
  get() = CommonMainString0.from_small_teams

private fun init_from_small_teams(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:from_small_teams", "from_small_teams",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3551, 232),
    )
)

public val Res.string.full_name: StringResource
  get() = CommonMainString0.full_name

private fun init_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:full_name", "full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3784, 29),
    )
)

public val Res.string.galactic: StringResource
  get() = CommonMainString0.galactic

private fun init_galactic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:galactic", "galactic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4258, 28),
    )
)

public val Res.string.galactic_description: StringResource
  get() = CommonMainString0.galactic_description

private fun init_galactic_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_description", "galactic_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3814, 136),
    )
)

public val Res.string.galactic_feature_1: StringResource
  get() = CommonMainString0.galactic_feature_1

private fun init_galactic_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_feature_1", "galactic_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3951, 54),
    )
)

public val Res.string.galactic_feature_2: StringResource
  get() = CommonMainString0.galactic_feature_2

private fun init_galactic_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_feature_2", "galactic_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4006, 70),
    )
)

public val Res.string.galactic_plan_description: StringResource
  get() = CommonMainString0.galactic_plan_description

private fun init_galactic_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_plan_description", "galactic_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4077, 141),
    )
)

public val Res.string.galactic_price: StringResource
  get() = CommonMainString0.galactic_price

private fun init_galactic_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:galactic_price", "galactic_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4219, 38),
    )
)

public val Res.string.hello: StringResource
  get() = CommonMainString0.hello

private fun init_hello(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hello", "hello",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4287, 21),
    )
)

public val Res.string.high_five: StringResource
  get() = CommonMainString0.high_five

private fun init_high_five(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:high_five", "high_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4730, 33),
    )
)

public val Res.string.high_five_description: StringResource
  get() = CommonMainString0.high_five_description

private fun init_high_five_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_description", "high_five_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4309, 125),
    )
)

public val Res.string.high_five_feature_1: StringResource
  get() = CommonMainString0.high_five_feature_1

private fun init_high_five_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_feature_1", "high_five_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4435, 55),
    )
)

public val Res.string.high_five_feature_2: StringResource
  get() = CommonMainString0.high_five_feature_2

private fun init_high_five_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_feature_2", "high_five_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4491, 71),
    )
)

public val Res.string.high_five_plan_description: StringResource
  get() = CommonMainString0.high_five_plan_description

private fun init_high_five_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_plan_description", "high_five_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4563, 126),
    )
)

public val Res.string.high_five_price: StringResource
  get() = CommonMainString0.high_five_price

private fun init_high_five_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:high_five_price", "high_five_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4690, 39),
    )
)

public val Res.string.import_list: StringResource
  get() = CommonMainString0.import_list

private fun init_import_list(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:import_list", "import_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4764, 35),
    )
)

public val Res.string.inactive: StringResource
  get() = CommonMainString0.inactive

private fun init_inactive(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:inactive", "inactive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4800, 28),
    )
)

public val Res.string.jobs_overview: StringResource
  get() = CommonMainString0.jobs_overview

private fun init_jobs_overview(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:jobs_overview", "jobs_overview",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4829, 41),
    )
)

public val Res.string.join_good_job: StringResource
  get() = CommonMainString0.join_good_job

private fun init_join_good_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_good_job", "join_good_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4871, 41),
    )
)

public val Res.string.join_us: StringResource
  get() = CommonMainString0.join_us

private fun init_join_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_us", "join_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4913, 55),
    )
)

public val Res.string.last_accessed: StringResource
  get() = CommonMainString0.last_accessed

private fun init_last_accessed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:last_accessed", "last_accessed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4969, 41),
    )
)

public val Res.string.login_register: StringResource
  get() = CommonMainString0.login_register

private fun init_login_register(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_register", "login_register",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5011, 42),
    )
)

public val Res.string.manage_employees: StringResource
  get() = CommonMainString0.manage_employees

private fun init_manage_employees(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:manage_employees", "manage_employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5054, 100),
    )
)

public val Res.string.manager: StringResource
  get() = CommonMainString0.manager

private fun init_manager(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:manager", "manager",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5155, 27),
    )
)

public val Res.string.month: StringResource
  get() = CommonMainString0.month

private fun init_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:month", "month",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5183, 21),
    )
)

public val Res.string.most_popular: StringResource
  get() = CommonMainString0.most_popular

private fun init_most_popular(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:most_popular", "most_popular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5205, 36),
    )
)

public val Res.string.need_an_account: StringResource
  get() = CommonMainString0.need_an_account

private fun init_need_an_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:need_an_account", "need_an_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5242, 59),
    )
)

public val Res.string.new_workspace: StringResource
  get() = CommonMainString0.new_workspace

private fun init_new_workspace(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_workspace", "new_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5302, 41),
    )
)

public val Res.string.next: StringResource
  get() = CommonMainString0.next

private fun init_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next", "next",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5344, 20),
    )
)

public val Res.string.onboarding_description1: StringResource
  get() = CommonMainString0.onboarding_description1

private fun init_onboarding_description1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onboarding_description1", "onboarding_description1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5365, 139),
    )
)

public val Res.string.onboarding_description2: StringResource
  get() = CommonMainString0.onboarding_description2

private fun init_onboarding_description2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onboarding_description2", "onboarding_description2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5505, 167),
    )
)

public val Res.string.optimize_your: StringResource
  get() = CommonMainString0.optimize_your

private fun init_optimize_your(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:optimize_your", "optimize_your",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5673, 169),
    )
)

public val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5843, 28),
    )
)

public val Res.string.phone: StringResource
  get() = CommonMainString0.phone

private fun init_phone(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:phone", "phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5872, 21),
    )
)

public val Res.string.plan: StringResource
  get() = CommonMainString0.plan

private fun init_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plan", "plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5894, 20),
    )
)

public val Res.string.powerful_tools: StringResource
  get() = CommonMainString0.powerful_tools

private fun init_powerful_tools(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:powerful_tools", "powerful_tools",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5915, 42),
    )
)

public val Res.string.pricing: StringResource
  get() = CommonMainString0.pricing

private fun init_pricing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pricing", "pricing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5958, 27),
    )
)

public val Res.string.priority_support: StringResource
  get() = CommonMainString0.priority_support

private fun init_priority_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:priority_support", "priority_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5986, 68),
    )
)

public val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6055, 42),
    )
)

public val Res.string.ready_for: StringResource
  get() = CommonMainString0.ready_for

private fun init_ready_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ready_for", "ready_for",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6098, 61),
    )
)

public val Res.string.register: StringResource
  get() = CommonMainString0.register

private fun init_register(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register", "register",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6206, 28),
    )
)

public val Res.string.register_with: StringResource
  get() = CommonMainString0.register_with

private fun init_register_with(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register_with", "register_with",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6160, 45),
    )
)

public val Res.string.role: StringResource
  get() = CommonMainString0.role

private fun init_role(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:role", "role",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6235, 20),
    )
)

public val Res.string.select_skill: StringResource
  get() = CommonMainString0.select_skill

private fun init_select_skill(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_skill", "select_skill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6256, 52),
    )
)

public val Res.string.select_team: StringResource
  get() = CommonMainString0.select_team

private fun init_select_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_team", "select_team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6309, 47),
    )
)

public val Res.string.select_your_workspace: StringResource
  get() = CommonMainString0.select_your_workspace

private fun init_select_your_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_your_workspace", "select_your_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6357, 57),
    )
)

public val Res.string.set_up_workspace: StringResource
  get() = CommonMainString0.set_up_workspace

private fun init_set_up_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:set_up_workspace", "set_up_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6415, 104),
    )
)

public val Res.string.sign_in: StringResource
  get() = CommonMainString0.sign_in

private fun init_sign_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in", "sign_in",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6561, 27),
    )
)

public val Res.string.sign_in_with: StringResource
  get() = CommonMainString0.sign_in_with

private fun init_sign_in_with(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in_with", "sign_in_with",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6520, 40),
    )
)

public val Res.string.skills: StringResource
  get() = CommonMainString0.skills

private fun init_skills(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skills", "skills",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6589, 22),
    )
)

public val Res.string.skip: StringResource
  get() = CommonMainString0.skip

private fun init_skip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skip", "skip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6612, 20),
    )
)

public val Res.string.start_organizing: StringResource
  get() = CommonMainString0.start_organizing

private fun init_start_organizing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_organizing", "start_organizing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6633, 68),
    )
)

public val Res.string.status: StringResource
  get() = CommonMainString0.status

private fun init_status(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:status", "status",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6702, 22),
    )
)

public val Res.string.subscribe: StringResource
  get() = CommonMainString0.subscribe

private fun init_subscribe(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscribe", "subscribe",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6725, 29),
    )
)

public val Res.string.support: StringResource
  get() = CommonMainString0.support

private fun init_support(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:support", "support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6755, 47),
    )
)

public val Res.string.tap_to_upload_photo: StringResource
  get() = CommonMainString0.tap_to_upload_photo

private fun init_tap_to_upload_photo(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tap_to_upload_photo", "tap_to_upload_photo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6803, 55),
    )
)

public val Res.string.team: StringResource
  get() = CommonMainString0.team

private fun init_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:team", "team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6881, 20),
    )
)

public val Res.string.teams: StringResource
  get() = CommonMainString0.teams

private fun init_teams(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:teams", "teams",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6859, 21),
    )
)

public val Res.string.terms_of_service: StringResource
  get() = CommonMainString0.terms_of_service

private fun init_terms_of_service(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:terms_of_service", "terms_of_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6902, 48),
    )
)

public val Res.string.today: StringResource
  get() = CommonMainString0.today

private fun init_today(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:today", "today",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6998, 21),
    )
)

public val Res.string.today_schedule: StringResource
  get() = CommonMainString0.today_schedule

private fun init_today_schedule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:today_schedule", "today_schedule",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6951, 46),
    )
)

public val Res.string.total: StringResource
  get() = CommonMainString0.total

private fun init_total(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:total", "total",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7020, 21),
    )
)

public val Res.string.transform: StringResource
  get() = CommonMainString0.transform

private fun init_transform(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:transform", "transform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7042, 73),
    )
)

public val Res.string.transparent_flexible: StringResource
  get() = CommonMainString0.transparent_flexible

private fun init_transparent_flexible(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:transparent_flexible", "transparent_flexible",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7116, 96),
    )
)

public val Res.string.unlimited_storage: StringResource
  get() = CommonMainString0.unlimited_storage

private fun init_unlimited_storage(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unlimited_storage", "unlimited_storage",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7213, 49),
    )
)

public val Res.string.user: StringResource
  get() = CommonMainString0.user

private fun init_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:user", "user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7285, 20),
    )
)

public val Res.string.users: StringResource
  get() = CommonMainString0.users

private fun init_users(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:users", "users",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7263, 21),
    )
)

public val Res.string.welcome: StringResource
  get() = CommonMainString0.welcome

private fun init_welcome(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome", "welcome",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7347, 39),
    )
)

public val Res.string.welcome_back: StringResource
  get() = CommonMainString0.welcome_back

private fun init_welcome_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome_back", "welcome_back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7306, 40),
    )
)

public val Res.string.whether: StringResource
  get() = CommonMainString0.whether

private fun init_whether(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:whether", "whether",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7387, 195),
    )
)

public val Res.string.workspace_name: StringResource
  get() = CommonMainString0.workspace_name

private fun init_workspace_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:workspace_name", "workspace_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7583, 42),
    )
)

public val Res.string.workspace_type: StringResource
  get() = CommonMainString0.workspace_type

private fun init_workspace_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:workspace_type", "workspace_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7626, 42),
    )
)

public val Res.string.your_email_address: StringResource
  get() = CommonMainString0.your_email_address

private fun init_your_email_address(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:your_email_address", "your_email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7669, 50),
    )
)

public val Res.string.your_full_name: StringResource
  get() = CommonMainString0.your_full_name

private fun init_your_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_full_name", "your_full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7720, 42),
    )
)

public val Res.string.your_password: StringResource
  get() = CommonMainString0.your_password

private fun init_your_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_password", "your_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7763, 41),
    )
)

public val Res.string.zip: StringResource
  get() = CommonMainString0.zip

private fun init_zip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zip", "zip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7805, 35),
    )
)
