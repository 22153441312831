package com.hyperether.goodjob.repository

import androidx.compose.ui.graphics.painter.BitmapPainter
import com.hyperether.goodjob.managers.FileManager
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Skills
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.models.SuperAdmin
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import com.hyperether.goodjob.repository.remote.model.AddCardRequest
import com.hyperether.goodjob.repository.remote.model.AddCardResponse
import com.hyperether.goodjob.repository.remote.model.AddPlanRequest
import com.hyperether.goodjob.repository.remote.model.AddPlanResponse
import com.hyperether.goodjob.repository.remote.model.AllJobReportsRequest
import com.hyperether.goodjob.repository.remote.model.AllUsersByRoleRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanResponse
import com.hyperether.goodjob.repository.remote.model.CompletionReportRequest
import com.hyperether.goodjob.repository.remote.model.CompletionReportResponse
import com.hyperether.goodjob.repository.remote.model.ConfirmationResponse
import com.hyperether.goodjob.repository.remote.model.ContactRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyResponse
import com.hyperether.goodjob.repository.remote.model.FileResult
import com.hyperether.goodjob.repository.remote.model.FinishJobRequest
import com.hyperether.goodjob.repository.remote.model.FinishJobResponse
import com.hyperether.goodjob.repository.remote.model.ForgotPasswordRequest
import com.hyperether.goodjob.repository.remote.model.ForgotPasswordResponse
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.repository.remote.model.JobStepsUpdateRequest
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import com.hyperether.goodjob.repository.remote.model.MessageResponse
import com.hyperether.goodjob.repository.remote.model.Place
import com.hyperether.goodjob.repository.remote.model.ResetPasswordRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SetPasswordRequest
import com.hyperether.goodjob.repository.remote.model.SetPasswordResponse
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.repository.remote.model.SkillRequest
import com.hyperether.goodjob.repository.remote.model.TeamRequest
import com.hyperether.goodjob.repository.remote.model.TeamsByWorkspaceRequest
import com.hyperether.goodjob.repository.remote.model.UploadFileResponse
import com.hyperether.goodjob.repository.remote.model.UserRequest
import com.hyperether.goodjob.util.FileUtil
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.flow
import org.lighthousegames.logging.logging

class WebRepositoryImpl(
    private val prefs: PrefsManager,
    private val goodJobApiImpl: GoodJobApiImpl,
    private val fileManager: FileManager
) : Repository {
    val log = logging("WebRepositoryImpl")

    override suspend fun deleteAllWorkspaces() {
        // TODO not needed, we delete it on logout in app (keep it on BE)
    }

    override suspend fun insertWorkspace(workspace: Workspace) {

    }

    override suspend fun getWorkspacesFlow(): Flow<List<Workspace>> {
        return flow {
            if (prefs.getUser()?.role.equals(Role.superAdmin.name, ignoreCase = true)) {
                val resource = getAllWorkspaces()
                if (resource is Resource.Success) {
                    val response = resource.data
                    if (response != null) {
                        log.d("getWorkspacesFlow") { "data: ${response}" }
                        emit(resource.data.distinct())
                    } else {
                        emit(emptyList())
                        log.d("getWorkspacesFlow") { "response null" }
                    }
                } else {
                    emit(emptyList())
                }
            } else {
                val resource = getUserWorkspaces()
                if (resource is Resource.Success) {
                    val response = resource.data
                    if (response != null) {
                        log.d("getWorkspacesFlow") { "data: ${response}" }
                        emit(resource.data.distinct())
                    } else {
                        emit(emptyList())
                        log.d("getWorkspacesFlow") { "response null" }
                    }
                } else {
                    emit(emptyList())
                }
            }
        }.catch { exception ->
            log.d("getWorkspacesFlow") { "exception: $exception" }
            emit(emptyList())
        }
    }

    override suspend fun getWorkspaceById(id: String): Workspace? {
        var workspace: Workspace? = null
        getWorkspacesFlow().collect { workspaceList ->
            workspace = workspaceList.find { it._id == id }
        }
        return workspace
    }

    override suspend fun deleteWorkspaceById(id: Long) {
        // TODO
    }

    override suspend fun addWorkspace(name: String, workspaceType: String): Resource<Workspace> {
        return goodJobApiImpl.addWorkspace(name, workspaceType, getToken())
    }

    override suspend fun getUserWorkspaces(): Resource<List<Workspace>> {
        return try {
            val apiResponse = goodJobApiImpl.getUserWorkspaces(getToken())

            when (apiResponse) {
                is Resource.Success -> {
                    val workspaceResponse = apiResponse.data?.toMutableList()
                    //todo this is workaround it return one user
                    try {
                        val responseEmployee = goodJobApiImpl.getAllWorkspacesId(getToken())
                        responseEmployee.data?.let { workspaceResponse?.addAll(it) }
                    } catch (e: Exception) {

                    }

                    workspaceResponse?.forEach { workspace ->
                        try {
                            val remoteId = workspace._id
                            if (remoteId.isNullOrBlank()) {
                                log.d("Workspace Sync Error") { "Skipping workspace with null or blank ID: ${workspace.name}" }
                                return@forEach
                            }
                        } catch (e: Exception) {
                            log.d("Workspace Sync Error") {
                                "Failed to sync workspace: ${workspace._id}, error: ${e.message}"
                            }
                        }
                    }
                    Resource.Success(workspaceResponse)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllWorkspaces(): Resource<List<Workspace>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllWorkspaces(getToken())

            when (apiResponse) {
                is Resource.Success -> {
                    val workspaceResponse = apiResponse.data?.toMutableList()
//                    //todo this is workaround it return one user
//                    try {
//                        val responseEmployee = goodJobApiImpl.getAllWorkspacesId(getToken())
//                        responseEmployee.data?.let { workspaceResponse?.addAll(it) }
//                    } catch (e: Exception) {
//
//                    }

                    workspaceResponse?.forEach { workspace ->
                        try {
                            val remoteId = workspace._id
                            if (remoteId.isNullOrBlank()) {
                                log.d("Workspace Sync Error") { "Skipping workspace with null or blank ID: ${workspace.name}" }
                                return@forEach
                            }
                        } catch (e: Exception) {
                            log.d("Workspace Sync Error") {
                                "Failed to sync workspace: ${workspace._id}, error: ${e.message}"
                            }
                        }
                    }
                    Resource.Success(workspaceResponse)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllPlans(): Resource<List<Plan>> {
        return goodJobApiImpl.getAllPlans(getToken())
    }

    override suspend fun addPlan(addPlanRequest: AddPlanRequest): Resource<AddPlanResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.addPlan(getToken(), addPlanRequest)) {
                is Resource.Success -> {
                    val data = apiResponse.data
                    if (data != null) {
                        log.d("addPlan") { "addPlan SUCCESS ${data.user.planId}" }
                        val currentUser = prefs.getUser()
                        currentUser?.planId = data.user.planId
                        currentUser?.let { saveUser(it) }
                        Resource.Success(data)
                    } else {
                        log.d("addPlan") { "addPlan ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "addPlan An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "addPlan Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun choosePlan(choosePlanRequest: ChoosePlanRequest): Resource<ChoosePlanResponse> {
        return goodJobApiImpl.choosePlan(getToken(), choosePlanRequest)
    }

    override suspend fun getPlan(id: String): Resource<Plan> {
        return goodJobApiImpl.getPlanById(getToken(), id)
    }

    override suspend fun confirmation(hash: String): Resource<ConfirmationResponse> {
        val result = goodJobApiImpl.confirmation(hash)
        if (result is Resource.Success) {
            result.data?.let {
                saveToken(it.token ?: "")
                saveRefreshToken(it.refreshToken ?: "")
                val user = Mapper().confirmationResponseToUser(it)
                saveUser(user)
            }
        }
        return result
    }

    override fun saveUser(user: User) {
        prefs.saveUser(user)
    }

    override fun getUser(): User? {
        return prefs.getUser()
    }

    override suspend fun logoutUser() {
        prefs.saveEmail("")
        prefs.saveToken("")
        prefs.saveRefreshToken("")
        prefs.saveUser(null)
        prefs.savePlanIdLocally("")
        prefs.saveWorkspaceId("")
        prefs.removeAll()
    }

    override suspend fun forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Resource<ForgotPasswordResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.forgotPassword(forgotPasswordRequest)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("forgotPassword") { "SUCCESS $data" }
                        Resource.Success(data)
                    } else {
                        log.d("forgotPassword") { "ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "forgotPassword Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun resetPassword(resetPasswordRequest: ResetPasswordRequest): Resource<ForgotPasswordResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.resetPassword(resetPasswordRequest)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("resetPassword") { "SUCCESS $data" }
                        Resource.Success(data)
                    } else {
                        log.d("resetPassword") { "ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "resetPassword Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun setPassword(setPasswordRequest: SetPasswordRequest): Resource<SetPasswordResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.setPassword(setPasswordRequest)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("setPassword") { "SUCCESS $data" }
                        Resource.Success(data)
                    } else {
                        log.d("setPassword") { "ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "setPassword Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun getEphemeralKey(ephemeralKeyRequest: EphemeralKeyRequest): Resource<EphemeralKeyResponse> {
        return goodJobApiImpl.getEphemeralKey(
            getToken(),
            EphemeralKeyRequest(getUser()?.customerId)
        )
    }

    override suspend fun cancelSubscription(subscriptionId: String): Resource<MessageResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.cancelSubscription(getToken(), subscriptionId)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun addCard(addCardRequest: AddCardRequest): Resource<AddCardResponse> {
        return goodJobApiImpl.addCard(getToken(), addCardRequest)
    }

    override suspend fun insertPlan(plan: Plan) {

    }

    override suspend fun getPlansFlow(): Flow<List<Plan>> {
        return flow {
            val resource = goodJobApiImpl.getAllPlans(getToken())
            if (resource is Resource.Success && resource.data != null) {
                emit(resource.data)
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            emit(emptyList())
        }
    }

    override suspend fun getPlanById(id: String): Plan? {
        return when (val result = getPlan(id)) {
            is Resource.Success -> result.data
            is Resource.Error -> {
                log.d("getPlanById") { "Error fetching plan: ${result.text}" }
                null
            }
        }
    }

    override suspend fun deletePlanById(id: String) {
        // TODO
    }

    override suspend fun insertUser(user: User) {

    }

    override suspend fun getUsersFlow(): Flow<List<User>> {
        return flow {
            val resource = getUsers()
            if (resource is Resource.Success) {
                val response = resource.data
                if (response != null) {
                    log.d("getUsersFlow") { "data: ${response}" }
                    emit(resource.data.distinct())
                } else {
                    emit(emptyList())
                    log.d("getUsersFlow") { "response null" }
                }
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            log.d("getUsersFlow") { "exception: $exception" }
            emit(emptyList())
        }
    }

    override suspend fun deleteUsersByIds(ids: List<Long>) {
        //  TODO: not needed, we use deleteUser in for loop
    }

    override suspend fun deleteUser(employeeId: String): Resource<String> {
        return goodJobApiImpl.deleteUser(getToken(), employeeId)
    }

    override suspend fun getUserById(userId: String): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.getEmployeeById(getToken(), userId)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("getEmployeeById") { "getEmployeeById SUCCESS $user" }
                    Resource.Success(user)
                }

                is Resource.Error -> {
                    log.d("getEmployeeById") { "getEmployeeById ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("getEmployeeById") { "getEmployeeById EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun addUser(userRequest: UserRequest): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.addUser(getToken(), userRequest)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("addEmployee") { "addEmployee SUCCESS $user" }
                    user?.let { insertUser(it) }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    log.d("addEmployee") { "addEmployee ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("addEmployee") { "addEmployee EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun updateUser(
        userId: String,
        userRequest: UserRequest
    ): Resource<User> {
        return try {
            when (val apiResponse =
                goodJobApiImpl.updateUserById(getToken(), userId, userRequest)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("updateEmployee") { "updateEmployee SUCCESS $user" }
                    user?.let { insertUser(it) }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    log.d("updateEmployee") { "updateEmployee ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("updateEmployee") { "updateEmployee EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getUsers(): Resource<List<User>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllUsersByRole(
                getToken(), AllUsersByRoleRequest(prefs.getWorkspaceId())
            )

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getContactsFlow(): Flow<List<Contact>> {
        return flow {
            val resource = goodJobApiImpl.getAllContactsByWorkspace(getWorkspaceId(), getToken())
            if (resource is Resource.Success) {
                val response = resource.data
                if (response != null) {
                    log.d("getContactsFlow") { "data: ${response}" }
                    emit(resource.data.distinct())
                } else {
                    emit(emptyList())
                    log.d("getContactsFlow") { "response null" }
                }
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            log.d("getContactsFlow") { "exception: $exception" }
            emit(emptyList())
        }
    }

    override suspend fun updateContact(
        contactId: String,
        customer: ContactRequest
    ): Resource<Contact> {
        return try {
            when (val apiResponse = goodJobApiImpl.updateContact(contactId, getToken(), customer)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun deleteContactById(id: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteContact(getToken(), id)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getContactById(id: String): Contact? {
        return when (val result = getContact(id)) {
            is Resource.Success -> {
                result.data
            }

            is Resource.Error -> {
                null
            }
        }
    }

    override suspend fun getContact(contactId: String): Resource<Contact> {
        return try {
            when (val apiResponse = goodJobApiImpl.getContactById(contactId, getToken())) {
                is Resource.Success -> {
                    val contactResponse = apiResponse.data
                    Resource.Success(contactResponse)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getSiteById(id: String): Site? {
        return when (val result = goodJobApiImpl.getSiteById(siteId = id, token = getToken())) {
            is Resource.Success -> {
                log.d("getSiteById") { "data: ${result.data}" }
                result.data
            }

            is Resource.Error -> {
                log.d("getSiteById") { "error: ${result.text}" }
                null
            }
        }
    }

    override suspend fun getContactsByWorkspaceId(): Resource<List<Contact>> {
        return try {
            val apiResponse = goodJobApiImpl.getContactsByWorkspaceId(
                getToken(), AllUsersByRoleRequest(getWorkspaceId())
            )

            when (apiResponse) {
                is Resource.Success -> {
                    val contactResponse = apiResponse.data
                    Resource.Success(contactResponse)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun addSite(site: Site): Resource<Site> {
        return try {
            when (val apiResponse = goodJobApiImpl.addSite(getToken(), site)) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun deleteSiteById(id: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteSiteById(getToken(), id)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun updateSite(siteId: String, site: Site): Resource<Site> {
        return try {
            val apiResponse = goodJobApiImpl.updateSite(getToken(), siteId, site)

            when (apiResponse) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getSitesFlow(): Flow<List<Site>> = flow {
        val result = getContactsByWorkspaceId()
        if (result is Resource.Success) {
            val contacts = result.data.orEmpty()
            val sites = mutableListOf<Site>()

            contacts.forEach { contact ->
                contact.id?.let {
                    when (val siteResult = getSitesUnderContact(SiteRequest(contactId = it))) {
                        is Resource.Success -> sites.addAll(siteResult.data.orEmpty())
                        is Resource.Error -> emit(emptyList())
                    }
                }
            }
            log.d("getSitesFlow") { "data: ${sites}" }
            emit(sites)
        } else {
            log.d("getSitesFlow") { "error: empty" }
            emit(emptyList())
        }
    }

    override suspend fun addContact(contact: Contact): Resource<Contact> {
        return try {
            val apiResponse = goodJobApiImpl.addContact(getToken(), contact)

            when (apiResponse) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getJobsFlow(): Flow<List<Job>> {
        return flow {
            val resource = getAllJobsForWorkspace()
            if (resource is Resource.Success) {
                val response = resource.data
                if (response != null) {
                    log.d("getJobsFlow") { "data: ${response}" }
                    emit(resource.data.distinct())
                } else {
                    emit(emptyList())
                    log.d("getJobsFlow") { "response null" }
                }
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            log.d("getJobsFlow") { "exception: $exception" }
            emit(emptyList())
        }
    }

    override suspend fun getJobByIdFlow(jobId: String): Flow<Job?> {
        return flow {
            val resource = getJobById(jobId)
            if (resource is Resource.Success) {
                val response = resource.data
                if (response != null) {
                    log.d("getJobByIdFlow") { "data: $response" }
                    emit(resource.data)
                } else {
                    emit(null)
                    log.d("getJobByIdFlow") { "response null" }
                }
            } else {
                emit(null)
            }
        }.catch { exception ->
            log.d("getJobByIdFlow") { "exception: $exception" }
            emit(null)
        }
    }

    override suspend fun getJobById(jobId: String): Resource<Job> {
        return try {
            when (val apiResponse = goodJobApiImpl.getJobById(token = getToken(), jobId = jobId)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getSitesUnderContact(siteRequest: SiteRequest): Resource<List<Site>> {
        return try {
            when (val apiResponse = goodJobApiImpl.getSitesUnderContacts(getToken(), siteRequest)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllJobsForSpecificContact(contactParentId: String): Resource<List<Job>> {
        return try {
            when (val apiResponse = goodJobApiImpl.getAllJobsForSpecificContact(
                token = getToken(),
                contactParentId = contactParentId
            )) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getJobsByContactIdFlow(id: String): Flow<List<Job>> {
        return flow {
            val resource = getAllJobsForSpecificContact(id)
            if (resource is Resource.Success) {
                val response = resource.data
                if (response != null) {
                    log.d("getJobsByContactIdFlow") { "data: $response" }
                    emit(resource.data)
                } else {
                    emit(emptyList())
                    log.d("getJobsByContactIdFlow") { "response null" }
                }
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            log.d("getJobsByContactIdFlow") { "exception: $exception" }
            emit(emptyList())
        }
    }

    override suspend fun createJob(job: JobRequest): Resource<Job> {
        return try {
            when (val apiResponse = goodJobApiImpl.createJob(getToken(), job)) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    if (response != null) {
                        log.d("createJob") { "Create job SUCCESS ${response.jobTitle}" }
                    }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    log.d("createJob") { "Create job ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("createJob") { "Create job  EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllJobsForWorkspace(): Resource<List<Job>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllJobsForSpecificWorkspace(
                getToken(),
                getWorkspaceId()
            )

            when (apiResponse) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllJobsForUser(): Resource<List<Job>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllJobsForSpecificUser(getToken())

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    if (response?.isNotEmpty() == true) {
                        getAllJobReports()
                    }
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllJobReports(): Resource<List<CompletionReportResponse>> {
        return try {
            val apiResponse =
                goodJobApiImpl.getAllJobReports(getToken(), AllJobReportsRequest(getWorkspaceId()))

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun deleteJobById(id: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteJobById(getToken(), id)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun submitCompletionReport(request: CompletionReportRequest): Resource<CompletionReportResponse> {
        return try {
            val apiResponse = goodJobApiImpl.submitCompletionReport(getToken(), request)

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getJobReportByJobId(jobId: String): Resource<CompletionReportResponse> {
        return try {
            val apiResponse = goodJobApiImpl.getJobReportByJobId(token = getToken(), jobId = jobId)
            when (apiResponse) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun finishJob(finishJobRequest: FinishJobRequest): Resource<FinishJobResponse> {
        return try {
            val apiResponse = goodJobApiImpl.finishJob(getToken(), finishJobRequest)

            when (apiResponse) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun updateJob(
        jobId: String,
        jobRequest: JobRequest
    ): Resource<Job> {
        return try {
            when (val apiResponse = goodJobApiImpl.updateJob(getToken(), jobId, jobRequest)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun updateJobSteps(
        jobId: String,
        steps: List<Step>
    ): Resource<Job> {
        return try {
            when (val apiResponse =
                goodJobApiImpl.updateJobSteps(
                    getToken(),
                    JobStepsUpdateRequest(jobId, steps)
                )) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun insertSkill(skills: Skills) {
        // TODO: not needed
    }

    override suspend fun getSkillsFlow(): Flow<List<Skills>> {
        return flow {
            val resource = getSkillsByWorkspace()
            if (resource is Resource.Success) {
                val response = resource.data
                if (response != null) {
                    log.d("getSkillsFlow") { "data: ${response}" }
                    emit(resource.data.distinct())
                } else {
                    emit(emptyList())
                    log.d("getSkillsFlow") { "response null" }
                }
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            log.d("getSkillsFlow") { "exception: $exception" }
            emit(emptyList())
        }
    }

    override suspend fun deleteAllSkills() {
        // TODO not needed, we delete it on logout in app (keep it on BE)
    }

    override suspend fun addSkill(skill: Skills): Resource<Skills> {
        return try {
            when (val apiResponse = goodJobApiImpl.addSkill(
                getToken(),
                getWorkspaceId(),
                SkillRequest(name = skill.name, workspaceId = getWorkspaceId())
            )) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getSkillsByWorkspace(): Resource<List<Skills>> {
        return try {
            val apiResponse = goodJobApiImpl.getSkillsByWorkspace(getToken(), getWorkspaceId())

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getTeamsFlow(): Flow<List<Team>> {
        return flow {
            val resource = getTeams()
            if (resource is Resource.Success) {
                val response = resource.data
                if (response != null) {
                    log.d("getSkillsFlow") { "data: ${response}" }
                    emit(resource.data.distinct())
                } else {
                    emit(emptyList())
                    log.d("getSkillsFlow") { "response null" }
                }
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            log.d("getSkillsFlow") { "exception: $exception" }
            emit(emptyList())
        }
    }

    override suspend fun createTeam(name: String): Resource<Team> {
        return try {
            when (val apiResponse = goodJobApiImpl.createTeam(
                getToken(), teamRequest = TeamRequest(
                    name = name, workspaceId = getWorkspaceId(), employees = emptyList()
                )
            )) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getTeams(): Resource<List<Team>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllTeamsByWorkspace(
                getToken(),
                TeamsByWorkspaceRequest(getWorkspaceId())
            )

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun deleteTeamById(id: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteTeam(getToken(), id)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    //LOCAL STORAGE
    // TODO for token we should save it in cookie for web, it is safer
    override fun saveToken(token: String) {
        log.d("saveToken") { "saveToken" }
        prefs.saveToken(token)
    }

    override fun getToken() = prefs.getToken()

    override fun saveRefreshToken(refreshToken: String) {
        prefs.saveRefreshToken(refreshToken)
    }

    override fun getRefreshToken(): String {
        return prefs.getRefreshToken()
    }

    override fun saveEmail(email: String) {
        prefs.saveEmail(email)
    }

    override fun getEmail(): String {
        return prefs.getEmail()
    }

    override fun saveWorkspaceId(workspaceId: String) {
        prefs.saveWorkspaceId(workspaceId)
    }

    override fun getWorkspaceId(): String {
        return prefs.getWorkspaceId()
    }

    override suspend fun updateWorkspaceLastAccessedById(
        lastAccessed: String,
        workspaceId: String
    ): Resource<Workspace> {
        return try {
            val workspace = getWorkspaceById(workspaceId)
            workspace?.let {
                workspace.lastAccessed = lastAccessed
                val request = Mapper().toUpdateWorkspaceRequest(workspace)
                val apiResponse = goodJobApiImpl.updateWorkspace(request, workspaceId, getToken())
                when (apiResponse) {
                    is Resource.Success -> {
                        val workspaceResponse = apiResponse.data
                        Resource.Success(workspaceResponse)
                    }

                    is Resource.Error -> {
                        log.d("updateWorkspaceLastAccessedById") {
                            "Failed to update workspace: $workspaceId, error: ${apiResponse.text}"
                        }
                        Resource.Error("${apiResponse.text}")
                    }
                }
            } ?: run {
                log.d("updateWorkspaceLastAccessedById") { "Workspace with ID $workspaceId not found" }
                Resource.Error("Workspace with ID $workspaceId not found")
            }
        } catch (e: Exception) {
            log.d("updateWorkspaceLastAccessedById") {
                "An unexpected error occurred: ${e.message}"
            }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun register(
        name: String,
        email: String,
        password: String
    ): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.register(name, email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("register") { "Register SUCCESS ${data.email}" }
                        saveUser(data)
                        Resource.Success(data)
                    } else {
                        log.d("register") { "Register ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.d("register") { "Register An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("register") { "Register Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun login(email: String, password: String): Resource<LoginResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.login(email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("login") { "Login SUCCESS ${data.user?.email}" }
                        saveToken(data.token)
                        saveRefreshToken(data.refreshToken)
                        val user = data.user
                        user?.let {
                            saveUser(user)
                        }
                        if (user?.planId?.isEmpty() == true && getUser()?.planId?.isNotEmpty() == true) {
                            addPlan(AddPlanRequest(getUser()?.planId, ""))
                        }
                        Resource.Success(data)
                    } else {
                        log.d("login") { "Login ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "Login ERROR An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "Login Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun setWorkspace(
        workspaceId: String,
        token: String
    ): Resource<SuperAdmin> {
        return goodJobApiImpl.setWorkspace(workspaceId, token)
    }

    override suspend fun getFile(fileId: String): Resource<FileResult> {
        return try {
            when (val apiResponse = goodJobApiImpl.getFile(fileId, getToken())) {
                is Resource.Success -> {
                    val data = apiResponse.data
                    if (data != null) {
                        log.d("getFile") { "getFile SUCCESS ${data.size}" }
                        val fileType = FileUtil().getFileType(fileId)
                        fileManager.saveFile(fileId, data)
                        Resource.Success(FileResult(data, fileType))
                    } else {
                        log.e { "getFile ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "getFile ERROR An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "getFile Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override fun getImage(imageName: String): BitmapPainter? {
        return fileManager.getBitmap(imageName)
    }

    override fun getImageFromByteArray(byteArray: ByteArray): BitmapPainter? {
        return fileManager.getBitmapFromByteArray(byteArray)
    }

    override fun getSavedFilePath(fileId: String): String? {
        return fileManager.getFilePath(fileId)
    }

    override suspend fun findPLace(name: String): List<Place>? {
        return goodJobApiImpl.findPlace(name)
    }

    override suspend fun uploadFile(
        fileData: ByteArray,
        fileName: String
    ): Resource<UploadFileResponse> {
        return goodJobApiImpl.uploadFile(getToken(), fileData, fileName)
    }

    override suspend fun deleteFileFromJob(jobId: String, fileId: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteFile(getToken(), fileId)) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    if (response != null) {
                        // Delete the file from local storage
                        // TODO !!!!!   fileManager.deleteFile(fileId)
                    }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun deleteUploadedFile(fileId: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteFile(getToken(), fileId)) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    if (response != null) {
                        // TODO !!!!!   fileManager.deleteFile(fileId)
                    }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }
}