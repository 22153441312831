package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.database.Employee
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.icon
import com.hyperether.goodjob.resources.plus
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmployeesScreen(employeesViewModel: EmployeesViewModel) {


    var isAllSelected = remember { mutableStateOf(true) }
    val isInEditMode = remember { mutableStateOf(false) }
    val employees = remember { mutableStateListOf<Employee>() }  // To hold the list of employees

    val selectedEmployees = remember { mutableStateListOf<Employee>() }
    var showPopup = remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        employeesViewModel.employees.collect { employeeList ->
            employees.clear()
            employees.addAll(employeeList)
        }
    }
    Box(modifier = Modifier.fillMaxSize()) {

        Column {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 20.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.baseline_person_24),
                    contentDescription = "Avatar",
                    modifier = Modifier
                        .size(48.dp)
                        .clip(CircleShape)
                        .background(Color.LightGray)
                )

                Text(
                    text = stringResource(Res.string.employees),
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier
                        .weight(1f)
                        .padding(start = 16.dp)
                )

                Row(
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .wrapContentWidth()
                        .padding(start = 8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.icon),
                        contentDescription = "",
                        modifier = Modifier
                            .padding(end = 20.dp)
                    )
                    Image(
                        painter = painterResource(Res.drawable.plus),
                        contentDescription = "",
                        modifier = Modifier
                            .padding()
                            .clickable {
                            }
                    )
                }
            }
            Column(
                modifier = Modifier
                    .padding(top = 5.dp)
                    .background(Color.LightGray.copy(alpha = 0.2f))
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(40.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.all),
                        color = if (isAllSelected.value) BlueApp else Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(start = 40.dp)
                            .clickable {
                                isAllSelected.value = true
                            }
                    )

                    Spacer(modifier = Modifier.weight(0.5f))

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.teams),
                        color = if (isAllSelected.value) Color.Gray else BlueApp,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(end = 40.dp)
                            .clickable {
                                isAllSelected.value = false
                            }
                    )
                }
            }
            Box(
                modifier = Modifier
                    .height(2.dp)
                    .fillMaxWidth(0.5f)
                    .background(if (isAllSelected.value) BlueApp else BlueApp)
                    .align(if (isAllSelected.value) Alignment.Start else Alignment.End)
            )
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 10.dp)
            ) {
                if (!isInEditMode.value) {
                    Text(
                        text = stringResource(Res.string.edit),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier
                            .clickable {
                                isInEditMode.value = true
                                showPopup.value = true
                            }
                            .weight(1f),
                        textAlign = TextAlign.Start
                    )
                } else {
                    Text(
                        text = "Cancel",
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier
                            .clickable {
                                isInEditMode.value = false
                                showPopup.value = false
                            }
                            .weight(1f),
                        textAlign = TextAlign.Start
                    )
                }

                if (!isInEditMode.value) {
                    Text(
                        text = "${stringResource(Res.string.total)}:7 Employees",
                        fontSize = 14.sp,
                        color = Color.Black,
                        maxLines = 1,
                        fontStyle = FontStyle.Italic,
                        textAlign = TextAlign.Center
                    )
                }

                Row(
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.weight(1f)
                ) {
                    Text(
                        text = stringResource(Res.string.filter),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier.padding(end = 8.dp),
                        textAlign = TextAlign.End
                    )
                    Icon(
                        painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                        contentDescription = null,
                        modifier = Modifier.size(20.dp)
                    )
                }
            }
            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))

            //change with employee id
            EmployeesList(
                employees = employees,
                isInEditMode = isInEditMode.value,
                selectedEmployees = selectedEmployees
            )
        }
        if (showPopup.value) {
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .background(Color.Black.copy(alpha = 0.05f))
            )

            Box(
                modifier = Modifier
                    .align(Alignment.BottomCenter)
                    .fillMaxWidth()
            ) {
                EditDialog(
                    showDialog = showPopup.value,
                    employees = selectedEmployees,
                    onDelete = {
                        employeesViewModel.deleteEmployeeById(selectedEmployees.map { it.id })
                        employeesViewModel.viewModelScope.launch {
                            employeesViewModel.employees.collect { employeeList ->
                                employees.clear()
                                employees.addAll(employeeList)
                            }
                        }
                    }
                )
            }
        }
    }
}

@Composable
fun EmployeesList(
    employees: List<Employee>,
    selectedEmployees: MutableList<Employee>,
    isInEditMode: Boolean
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize()
    ) {
        items(employees) { employee ->
            EmployeesItem(
                employee = employee,
                isInEditMode = isInEditMode,
                onEmployeeSelected = { employee, isSelected ->
                    if (isSelected) {
                        selectedEmployees.add(employee)
                    } else {
                        selectedEmployees.remove(employee)
                    }
                }
            )
            Divider(
                //modifier = Modifier.padding(),
                color = Color.Gray,
                thickness = 0.5.dp
            )
        }
    }
}

