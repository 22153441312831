package com.hyperether.goodjob.scenes.dashboard

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.planListDashboard
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.chevron_left
import com.hyperether.goodjob.resources.chevron_right
import com.hyperether.goodjob.resources.clipboard_small
import com.hyperether.goodjob.resources.clock_small
import com.hyperether.goodjob.resources.dashboard
import com.hyperether.goodjob.resources.employees_small
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.jobs_overview
import com.hyperether.goodjob.resources.map_pin
import com.hyperether.goodjob.resources.map_small
import com.hyperether.goodjob.resources.plus
import com.hyperether.goodjob.resources.today
import com.hyperether.goodjob.resources.today_schedule
import com.hyperether.goodjob.resources.user_check_small
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.planner.ui.DashboardDayPlanner
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun DashboardScreen(navHostController: NavHostController) {

    var isJobsOverviewSelected = remember { mutableStateOf(true) }



    Column {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 20.dp)
        ) {
            Image(
                painter = painterResource(Res.drawable.baseline_person_24),
                contentDescription = "Avatar",
                modifier = Modifier
                    .size(48.dp)
                    .clip(CircleShape)
                    .background(Color.LightGray)
            )

            Text(
                text = stringResource(Res.string.dashboard),
                fontSize = 18.sp,
                color = Color.Black,
                textAlign = TextAlign.Center,
                modifier = Modifier
                    .weight(1f)
                    .padding(start = 16.dp)
            )

            Row(
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .wrapContentWidth()
                    .padding(start = 8.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.map_pin),
                    contentDescription = "",
                    modifier = Modifier
                        .padding(end = 20.dp)
                )
                Image(
                    painter = painterResource(Res.drawable.plus),
                    contentDescription = "",
                    modifier = Modifier
                        .padding()
                        .clickable {
                            navHostController.navigate(Screen.AddNew.route)
                        }
                )
            }
        }
        Column(
            modifier = Modifier
                .padding(top = 5.dp)
                .background(Color.LightGray.copy(alpha = 0.2f))
                .padding(8.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().height(40.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {

                androidx.compose.material3.Text(
                    text = stringResource(Res.string.today_schedule),
                    color = if (isJobsOverviewSelected.value) Color.Gray else BlueApp,
                    fontSize = 16.sp,
                    modifier = Modifier
                        .padding(start = 30.dp)
                        .clickable {
                            isJobsOverviewSelected.value = false
                        }
                )

                Spacer(modifier = Modifier.weight(0.5f))

                androidx.compose.material3.Text(
                    text = stringResource(Res.string.jobs_overview),
                    color = if (isJobsOverviewSelected.value) BlueApp else Color.Gray,
                    fontSize = 16.sp,
                    modifier = Modifier
                        .padding(end = 30.dp)
                        .clickable {
                            isJobsOverviewSelected.value = true
                        }
                )
            }
        }
        Box(
            modifier = Modifier
                .height(2.dp)
                .fillMaxWidth(0.5f)
                .background(if (isJobsOverviewSelected.value) BlueApp else BlueApp)
                .align(if (isJobsOverviewSelected.value) Alignment.End else Alignment.Start)
        )

        if (isJobsOverviewSelected.value) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 16.dp),
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = stringResource(Res.string.filter),
                    fontSize = 14.sp,
                    color = Color.Gray
                )
                Icon(
                    painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                    contentDescription = "",
                    modifier = Modifier
                        .padding(start = 8.dp)
                        .size(20.dp)
                )
            }
        }
        if (isJobsOverviewSelected.value) {
            DashboardOverviewItem()
        } else {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                IconButton(onClick = {}) {
                    Icon(
                        painter = painterResource(Res.drawable.chevron_left),
                        contentDescription = "Left Icon"
                    )
                }
                Text(
                    text = stringResource(Res.string.today),
                    modifier = Modifier.padding(horizontal = 8.dp),
                    textAlign = TextAlign.Center,
                    style = TextStyle(fontSize = 16.sp, color = Color.DarkGray)
                )
                IconButton(onClick = {}) {
                    Icon(
                        painter = painterResource(Res.drawable.chevron_right),
                        contentDescription = "Right Icon"
                    )
                }
            }
            DashboardDayPlanner(listOfPlans = planListDashboard.toMutableList())
            //SchedulerDayPlanner(listOfPlans = planList.toMutableList())
        }
    }
}


@Composable
fun DashboardOverviewItem() {
    Text(
        text = "Thursday, 25 July 2024",
        fontSize = 14.sp,
        color = Color.DarkGray,
        textAlign = TextAlign.Left,
        modifier = Modifier
            .padding(start = 16.dp)
    )
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 16.dp, end = 16.dp, top = 8.dp)
            .border(1.dp, Color.LightGray, shape = RoundedCornerShape(12.dp))
            .background(Color.White, shape = RoundedCornerShape(12.dp))
            .padding(16.dp),
    ) {
        Column {
            Text(
                text = "Install Power Meter",
                fontSize = 18.sp,
                color = Color.Black,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Left
            )
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Image(
                    painter = painterResource(Res.drawable.user_check_small),
                    contentDescription = "check",
                    modifier = Modifier
                        .padding(top = 5.dp)
                )
                Text(
                    text = "Green energy solutions",
                    color = BlueApp,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(start = 12.dp)
                )

            }
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Image(
                    painter = painterResource(Res.drawable.map_small),
                    contentDescription = "check",
                    modifier = Modifier
                        .padding(top = 5.dp)
                )
                Text(
                    text = "123 Main St, Springfield",
                    color = BlueApp,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(start = 12.dp)
                )

            }
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Image(
                    painter = painterResource(Res.drawable.clipboard_small),
                    contentDescription = "check",
                    modifier = Modifier
                        .padding(top = 5.dp)
                )
                Text(
                    text = "Install a new smart power meter and ensure it is calibrated for accurate readings.",
                    color = Color.Black,
                    textAlign = TextAlign.Left,
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(start = 12.dp)
                )

            }
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Image(
                    painter = painterResource(Res.drawable.clock_small),
                    contentDescription = "check",
                    modifier = Modifier
                        .padding(top = 5.dp)
                )
                Text(
                    text = "09:00 AM - 11:00 AM",
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(start = 12.dp)
                )

            }
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Image(
                    painter = painterResource(Res.drawable.employees_small),
                    contentDescription = "check",
                    modifier = Modifier
                        .padding(top = 5.dp)
                )
                Text(
                    text = "James Smith",
                    color = BlueApp,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(start = 12.dp)
                )

            }
        }
    }
}