package com.hyperether.planner.ui

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.hyperether.planner.model.Event
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.isoDayNumber
import kotlinx.datetime.plus
import kotlinx.datetime.until

fun monthDays(year: Int, month: Int): Int {
    val start = LocalDate(year, month, 1)
    val end = start.plus(1, DateTimeUnit.MONTH)
    return start.until(end, DateTimeUnit.DAY)
}

data class CalendarField(
    val day: Int,
    var fromCurrentMonth: Boolean
)

@Composable
fun ScheduleMonthPlanner(
    listOfPlans: MutableList<Plan>,
    month: Int,
    year: Int,
    completedColor: Color,
    pendingColor: Color,
    delayedColor: Color,
    selectedDay: State<Int?>,
    borderPathColor: Color = Color(231, 231, 231),
    onClick: (List<Event>, Int) -> Unit
) {
    val list = mutableStateListOf<CalendarField>()
    val numberOfCells = 35
    val eventMap = groupEventsByDay(listOfPlans)

    val currentMonthDays = monthDays(year, month)
    val firstDayOfWeek = LocalDate(year, month, 1).dayOfWeek
    val prevMonth: Int
    val prevYear: Int
    if (month == 1) {
        prevMonth = 12
        prevYear = year - 1
    } else {
        prevMonth = month - 1
        prevYear = year
    }
    val previousMonthDays = monthDays(prevYear, prevMonth)

    val startOffset = (firstDayOfWeek.isoDayNumber - 1) % 7

    for (i in 0 until startOffset) {
        list.add(CalendarField(previousMonthDays - startOffset + i + 1, false))
    }
    for (i in 1..currentMonthDays) {
        list.add(CalendarField(i, true))
    }
    val remainingCells = numberOfCells - list.size
    for (i in 1..remainingCells) {
        list.add(CalendarField(i, false))
    }

    Column {
        Box(modifier = Modifier.fillMaxWidth().height(1.dp).background(borderPathColor))
        Spacer(modifier = Modifier.height(30.dp))
        Row(modifier = Modifier.fillMaxWidth()) {
            Text("Mon", textAlign = TextAlign.Center, modifier = Modifier.weight(1f))
            Text("Tue", textAlign = TextAlign.Center, modifier = Modifier.weight(1f))
            Text("Wed", textAlign = TextAlign.Center, modifier = Modifier.weight(1f))
            Text("Thu", textAlign = TextAlign.Center, modifier = Modifier.weight(1f))
            Text("Fri", textAlign = TextAlign.Center, modifier = Modifier.weight(1f))
            Text("Sat", textAlign = TextAlign.Center, modifier = Modifier.weight(1f))
            Text("Sun", textAlign = TextAlign.Center, modifier = Modifier.weight(1f))
        }

        LazyVerticalGrid(
            columns = GridCells.Fixed(7),
            modifier = Modifier.fillMaxWidth().padding(top = 38.dp)
        ) {
            if (list.isNotEmpty()) {
                println("list: $list")
                itemsIndexed(list.takeIf { it.isNotEmpty() } ?: emptyList()) { index, day ->
                    Box(
                        contentAlignment = Alignment.Center,
                        modifier = Modifier.fillMaxWidth().clickable {
                            if (index > startOffset - 1 && index < list.size - remainingCells)
                                onClick(eventMap[day.day] ?: emptyList(), day.day)
                        }) {
                        Column(
                            modifier = Modifier.height(50.dp).padding(2.dp),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            Box(
                                modifier = Modifier.size(40.dp),
                                contentAlignment = Alignment.Center
                            ) {
                                val selected =
                                    selectedDay.value == day.day && index > startOffset - 1 && index < list.size - remainingCells
                                Box(
                                    modifier = Modifier
                                        .size(30.dp)
                                        .then(
                                            if (selected) Modifier.background(
                                                Color(0xFF34618E),
                                                RoundedCornerShape(6.dp)
                                            ) else Modifier
                                        ),
                                    contentAlignment = Alignment.Center // Centers the content within the Box
                                ) {
                                    Text(
                                        text = "${day.day}",
                                        color = if (selected) Color.White else Color.Black,
                                        textAlign = TextAlign.Center // Aligns the text horizontally
                                    )
                                }
                            }
                            if (day.fromCurrentMonth) {
                                val eventList: List<Event>? = eventMap.get(day.day)
                                if (eventList?.isNotEmpty() == true) {
                                    Row {
                                        eventList.forEach { event ->
                                            when (event.status) {
                                                EventStatus.DELAYED,
                                                EventStatus.REJECTED -> {
                                                    Spacer(modifier = Modifier.width(2.dp))
                                                    Box(
                                                        modifier = Modifier.size(4.dp)
                                                            .clip(CircleShape)
                                                            .background(delayedColor)
                                                    )
                                                }

                                                EventStatus.COMPLETED -> {
                                                    Spacer(modifier = Modifier.width(2.dp))
                                                    Box(
                                                        modifier = Modifier.size(4.dp)
                                                            .clip(CircleShape)
                                                            .background(completedColor)
                                                    )
                                                }

                                                EventStatus.PENDING,
                                                EventStatus.IN_PROGRESS -> {
                                                    Spacer(modifier = Modifier.width(2.dp))
                                                    Box(
                                                        modifier = Modifier.size(4.dp)
                                                            .clip(CircleShape)
                                                            .background(pendingColor)
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun groupEventsByDay(listOfPlans: List<Plan>): Map<Int, List<Event>> {
    return listOfPlans.flatMap { plan ->
        plan.events // Extract all events from each plan
    }.groupBy { event ->
        event.from.dayOfMonth // Group events by their day of the month
    }
}