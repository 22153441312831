package com.hyperether.goodjob.repository

import com.hyperether.goodjob.models.Employee
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.Flow
import org.lighthousegames.logging.logging

class WebRepositoryImpl(
    private val prefs: PrefsManager,
    private val goodJobApiImpl: GoodJobApiImpl
) : Repository {
    val log = logging("WebRepositoryImpl")

    //TODO Use for web, dont use database here, we will show data only from API

    override suspend fun getTestData(): String {
        TODO("Not yet implemented")
    }

    override suspend fun deleteAllWorkspaces() {
        TODO("Not yet implemented")
    }

    override suspend fun insertWorkspace(workspace: Workspace) {
        TODO("Not yet implemented")
    }

    override  fun getWorkspaces(): Flow<List<Workspace>> {
        TODO("Not yet implemented")
    }

    override suspend fun getWorkspaceById(id: Long): Flow<Workspace?> {
        TODO("Not yet implemented")
    }

    override suspend fun deleteWorkspaceById(id: Long) {
        TODO("Not yet implemented")
    }

    override suspend fun addWorkspace(name: String, type: String): Resource<Workspace> {
        TODO("Not yet implemented")
    }

    override suspend fun insertUser(user: User) {
        TODO("Not yet implemented")
    }

    override suspend fun insertEmployee(employee: Employee) {
        TODO("Not yet implemented")
    }

    override fun getEmployees(): Flow<List<com.hyperether.goodjob.database.Employee>> {
        TODO("Not yet implemented")
    }

    override suspend fun deleteEmployeesByIds(ids: List<Long>) {
        TODO("Not yet implemented")
    }


    //LOCAL STORAGE
    // TODO for token we should save it in cookie for web, it is safer
    override fun saveToken(token: String) {
        log.d { "saveToken" }
        prefs.saveToken(token)
    }

    override fun getToken() = prefs.getToken()

    override fun saveRefreshToken(refreshToken: String) {
        prefs.saveRefreshToken(refreshToken)
    }

    override fun getRefreshToken(): String {
        return prefs.getRefreshToken()
    }

    override fun saveEmail(email: String) {
        prefs.saveEmail(email)
    }

    override fun getEmail(): String {
        return prefs.getEmail()
    }

    override fun savePassword(password: String) {
        prefs.savePassword(password)
    }

    override fun getPassword(): String {
        return prefs.getPassword()
    }

    override suspend fun register(
        name: String,
        email: String,
        password: String
    ): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.register(name, email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d { "Register SUCCESS ${data.email}" }
                        Resource.Success(data)
                    } else {
                        log.e { "Register ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "Register An error occurred" }
                    Resource.Error(apiResponse.text?.toString() ?: "An error occurred")
                }
            }
        } catch (e: Exception) {
            log.e { "Register Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun login(email: String, password: String): Resource<LoginResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.login(email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d { "Login SUCCESS ${data.email}" }
                        saveToken(data.token)
                        saveRefreshToken(data.refreshToken)
                        Resource.Success(data)
                    } else {
                        log.e { "Login ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "Login ERROR An error occurred" }
                    Resource.Error(apiResponse.text?.toString() ?: "An error occurred")
                }
            }
        } catch (e: Exception) {
            log.e { "Login Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }
}