package com.hyperether.goodjob

import App
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import androidx.navigation.compose.rememberNavController
import com.hyperether.goodjob.database.DriverFactory
import com.hyperether.goodjob.database.SharedDatabase
import com.hyperether.goodjob.database.WorkspaceDaoImpl
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.navigation.availableRoutes
import com.hyperether.goodjob.repository.WebRepositoryImpl
import com.hyperether.goodjob.repository.prefs.Preferences
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.ClientProvider
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.skiko.wasm.onWasmReady
import org.lighthousegames.logging.Logger
import org.lighthousegames.logging.logging
import org.w3c.dom.url.URLSearchParams

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    val sharedDatabase = SharedDatabase(DriverFactory())
    val workspaceDao = WorkspaceDaoImpl(sharedDatabase)
    val mapper = Mapper()
    ClientProvider.initialize()
    val queryParams = URLSearchParams(document.location?.search ?: "")
    val hash = queryParams.get("hash")

    val prefsManager = PrefsManager(Preferences())
    val goodJobApiImpl = GoodJobApiImpl()

    val repository = WebRepositoryImpl(prefsManager, goodJobApiImpl)
    val workspaceViewModel = WorkspaceViewModel(repository)
    val pricingViewModel = PricingViewModel(repository)
    val registerViewModel = RegisterViewModel(repository)
    val employeesViewModel = EmployeesViewModel(repository)
    val addNewViewModel = AddNewViewModel(repository)
    val log = logging("WEB MAIN")

    onWasmReady {
        CanvasBasedWindow("Good job") {
            val navController = rememberNavController()
            App(
                navController,
                repository,
                workspaceViewModel,
                pricingViewModel,
                registerViewModel,
                employeesViewModel,
                addNewViewModel,
                prefsManager,
                getCurrentRoute()
            )
            handleDeepLink(
                hash,
                goodJobApiImpl,
                prefsManager,
                navigate = { route ->
                    navController.navigate(route)
                }
            )
            navController.addOnDestinationChangedListener { _, destination, _ ->
                window.history.pushState(null, "", destination.route!!.split("?")[0])
                log.d("addOnDestinationChangedListener") { "$destination" }
            }

            LaunchedEffect(Unit) {
                val listener: (org.w3c.dom.events.Event) -> Unit = {
                    log.d("current route:") { getCurrentRoute() }
                    navController.navigate(getCurrentRoute())
                }
                window.addEventListener("popstate", listener)
            }

        }
    }
}

fun getCurrentRoute(): String {
    val route = window.location.pathname.replace("/", "")
    if (route.isNotEmpty() && availableRoutes.contains(route)) {
        return route
    }
    return "splash"
}

fun handleDeepLink(
    hash: String?,
    goodJobApiImpl: GoodJobApiImpl,
    prefsManager: PrefsManager,
    navigate: (route: String) -> Unit
) {
    CoroutineScope(Dispatchers.Default).launch {
        delay(1000L)
        hash?.let {
            goodJobApiImpl.confirmation(
                hash,
                prefsManager.getEmail(),
                prefsManager.getPassword(),
                onSuccess = {
                    prefsManager.savePassword("")
                    navigate(Screen.Pricing.route)
                },
                onError = {
                    navigate(Screen.Landing.route)
                    println("Confirmation error")
                }
            )
        } ?: run {
            println("No hash found in URL query parameters.")
        }
    }
}
