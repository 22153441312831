package com.hyperether.goodjob.scenes.addNew.components

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.select_team
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddEmployeeTeamInputField(
    itemsFromDb: List<Team>,
    selectedTeam: Team?,
    onItemSelected: (Team) -> Unit,
    onAddNewItem: (String) -> Unit
) {
    var text by remember { mutableStateOf(selectedTeam?.name ?: "") }
    var expanded by remember { mutableStateOf(false) }
    val focusManager = LocalFocusManager.current

    LaunchedEffect(selectedTeam) {
        text = selectedTeam?.name ?: ""
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        Column {
            OutlinedTextField(
                value = text,
                onValueChange = { text = it },
                modifier = Modifier.fillMaxWidth(),
                placeholder = { Text(stringResource(Res.string.select_team), fontSize = 12.sp) },
                singleLine = true,
                label = {
                    Text(
                        stringResource(Res.string.team),
                        fontSize = 14.sp,
                        color = TextColor
                    )
                },
                trailingIcon = {
                    IconButton(onClick = { expanded = !expanded }) {
                        Icon(Icons.Default.ArrowDropDown, contentDescription = "Dropdown Icon")
                    }
                },
                keyboardOptions = KeyboardOptions.Default.copy(
                    imeAction = ImeAction.Done
                ),
                keyboardActions = KeyboardActions(
                    onDone = {
                        if (text.isNotEmpty()) {
                            onAddNewItem(text)
                            //text = ""
                        }
                        focusManager.moveFocus(FocusDirection.Next)
                    }
                )
            )
            DropdownMenu(
                expanded = expanded,
                onDismissRequest = { expanded = false }
            ) {
                itemsFromDb.forEach { item ->
                    DropdownMenuItem(
                        onClick = {
                            text = item.name ?: ""
                            onItemSelected(item)
                            expanded = false
                        },
                        text = {
                            Text(text = item.name ?: "")
                        }
                    )
                }
            }
        }
    }
}