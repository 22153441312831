package com.hyperether.goodjob.scenes.jobs

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging

class JobViewModel(
    val repository: Repository,
) : ViewModel() {
    val log = logging("JobViewModel")

    val showEditPopUp: MutableState<Boolean> = mutableStateOf(false)
    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")
    val showAreYouSurePopUpDeleteJob: MutableState<Boolean> = mutableStateOf(false)

    val jobList = mutableStateListOf<Job>()
    val sites = mutableStateMapOf<String, Site>()
    val selectedJobs = mutableStateListOf<Job>()
    val filteredJobs = mutableStateListOf<Job>()

    private val contactsMap = mutableStateMapOf<String, Contact>()

    private val _contacts = MutableStateFlow<List<Contact>>(emptyList())
    val contacts: StateFlow<List<Contact>> = _contacts
    private val _users = MutableStateFlow<List<User>>(emptyList())
    val users: StateFlow<List<User>> = _users

    var selectedStatus = mutableStateOf(setOf<String>())
    var selectedEmployees = mutableStateOf(setOf<String>())
    var selectedTeams = mutableStateOf(setOf<String>())
    var selectedLocation = mutableStateOf(setOf<String>())
    var selectedSchedule = mutableStateOf(setOf<String>())

    private val _teams = MutableStateFlow<List<com.hyperether.goodjob.models.Team>>(emptyList())
    val teams: StateFlow<List<com.hyperether.goodjob.models.Team>> = _teams

    var jobStatusMap: Map<String, String> = mutableMapOf()

    // Web
    var jobSearchText = mutableStateOf("")
    var jobSortBy: MutableState<String?> = mutableStateOf(null)
    val showFilterDialog: MutableState<Boolean> = mutableStateOf(false)


    init {
        if (getPlatformChecker().isMobile()) {
            log.d("getContactsFlow JobViewModel") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            init()
        }
    }

    fun init() {
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }
        viewModelScope.launch {
            repository.getTeamsFlow().collect {
                _teams.value = it
            }
        }
        viewModelScope.launch {
            repository.getContactsFlow().collect {
                _contacts.value = it
            }
        }
        viewModelScope.launch {
            repository.getUsersFlow().collect {
                _users.value = it
            }
        }
        CoroutineScope(Dispatchers.Default).launch {
            repository.getJobsFlow()?.collect {
                jobList.clear()
                filteredJobs.clear()
                val contactMap = mutableMapOf<String, Contact>()
                val siteMap = mutableMapOf<String, Site>()
                it.forEach { job ->
                    job.contactParentId?.let {
                        val contact = repository.getContactById(it)
                        contact?.id?.let { id ->
                            contactMap[id] = contact
                        }
                    }
                    job.contactSiteId?.let {
                        val site = getSiteById(it)
                        site?.id?.let { id -> siteMap[job.id!!] = site }
                    }
                    filteredJobs.add(job)
                    jobList.add(job)
                }
                sites.clear()
                sites.putAll(siteMap)
                contactMap.clear()
                contactMap.putAll(contactMap)

            }
        }
    }

    fun deleteSelectedJobs(
        onAllDeleted: () -> Unit,
        onError: (String) -> Unit
    ) {
        if (selectedJobs.isNotEmpty()) {
            showLoader.value = true
            viewModelScope.launch {
                try {
                    selectedJobs.forEachIndexed { index, job ->
                        val isLastJob = index == selectedJobs.size - 1

                        job.id?.let { id ->
                            val result = repository.deleteJobById(id)
                            if (result is Resource.Success && isLastJob) {
                                onAllDeleted()
                            } else if (result is Resource.Error) {
                                errorText.value = result.text ?: "An unknown error occurred"
                                onError(id)
                                return@launch
                            }
                        }
                    }
                    selectedJobs.clear()
                } catch (e: Exception) {
                    onError("Unexpected error: ${e.message}")
                }
            }
        }
    }

    fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

    fun filterJobs() {
        filteredJobs.clear()
        if (selectedStatus.value.isNullOrEmpty() &&
            selectedEmployees.value.isNullOrEmpty() &&
            selectedSchedule.value.isNullOrEmpty() &&
            selectedLocation.value.isNullOrEmpty()
        ) {
            filteredJobs.addAll(jobList)
        } else {

            val selectedSitesIds = selectedLocation.value.flatMap { selectedAddress ->
                sites.filterValues { site ->
                    site.addressObject?.address.equals(selectedAddress, ignoreCase = true)
                }.values.map { it.id }
            }
            filteredJobs.addAll(jobList.filter { job ->
                selectedStatus.value.any { query ->
                    job.status?.contains(query, ignoreCase = true) == true
                } || selectedEmployees.value.any { query ->
                    job.assignees?.employees?.any { employee ->
                        employee?.getName()?.contains(query, ignoreCase = true) == true
                    } == true
                } || selectedSchedule.value.any { query ->
                    job.startDate?.contains(query, ignoreCase = true) == true
                } || selectedSitesIds.contains(job.contactSiteId)
            })
        }
    }

    private suspend fun getSiteById(id: String): Site? {
        return repository.getSiteById(id)
    }
}