package com.hyperether.planner.model

import kotlinx.datetime.LocalDateTime

data class Plan(
    val person: String,
    val events: List<Event>?
)

data class Event(
    val status: EventStatus,
    val from: LocalDateTime,
    val to: LocalDateTime,
    val title: String? = null
)

enum class EventStatus {
    COMPLETED,
    PENDING,
    DELAYED
}