package com.hyperether.goodjob.mapper

import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.database.Workspace
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json

class Mapper {

    fun toWorkspace(workspace: Workspace): com.hyperether.goodjob.models.Workspace {
        return com.hyperether.goodjob.models.Workspace(
            id = workspace.id,
            name = workspace.name,
            planId = workspace.plan,
            type = workspace.type,
            userId = workspace.user_id
        )
    }

    fun toWorkspace(workspace: com.hyperether.goodjob.models.Workspace): Workspace {
        return Workspace(
            id = workspace.id ?: 0,
            name = workspace.name,
            plan = workspace.planId,
            type = workspace.type,
            user_id = workspace.userId
        )
    }

    fun toUser(user: User): com.hyperether.goodjob.models.User {
        return com.hyperether.goodjob.models.User(
            id = user.id,
            fullName = user.full_name,
            email = user.email,
            password = user.password,
            role = user.role,
            workspace_ids = user.workspace_ids?.let { workspaceIdsAsList(it) }
        )
    }

    fun toUser(workspace: com.hyperether.goodjob.models.Workspace): Workspace {
        return Workspace(
            id = workspace.id ?: 0,
            name = workspace.name,
            plan = workspace.planId,
            type = workspace.type,
            user_id = workspace.userId
        )
    }

    fun workspaceIdsAsJson(workspace_ids: List<String>?): String? = workspace_ids?.let {
        Json.encodeToString(ListSerializer(String.serializer()), it)
    }

    fun workspaceIdsAsList(workspace_ids: String): List<String> {
        return Json.decodeFromString<List<String>>(workspace_ids)
    }
}