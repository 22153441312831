package com.hyperether.goodjob.scenes.dashboard

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobStatus
import com.hyperether.goodjob.models.Role
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.planner.model.DashboardEvent
import com.hyperether.planner.util.EventStatusMapper
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.todayIn
import org.lighthousegames.logging.logging

class DashboardViewModel(
    val repository: Repository,
) : ViewModel() {

    val log = logging("DashboardViewModel")

    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val jobList = mutableStateListOf<Job>()
    val contacts = mutableStateMapOf<String, Contact>()
    val sites = mutableStateMapOf<String, Site>()

    private val _contact = MutableStateFlow<Contact?>(null)
    val contact: StateFlow<Contact?> = _contact

    val showFilterDialog: MutableState<Boolean> = mutableStateOf(false)
    val filteredJobs = mutableStateListOf<Job>()
    var selectedStatus = mutableStateOf(setOf<String>())
    var selectedEmployees = mutableStateOf(setOf<String>())
    var selectedTeams = mutableStateOf(setOf<String>())
    var selectedLocation = mutableStateOf(setOf<String>())
    var selectedSchedule = mutableStateOf(setOf<String>())

    private val _teams = MutableStateFlow<List<Team>>(emptyList())
    val teams: StateFlow<List<Team>> = _teams

    private var selectedDay: LocalDate = Clock.System.todayIn(TimeZone.currentSystemDefault())
    var dashboardEventList = mutableStateListOf<DashboardEvent>()

    var jobStatusMap: Map<String, String> = mutableMapOf()

    init {
        if (getPlatformChecker().isMobile()) {
            log.d("DashboardViewModel") { "init isMobile: ${getPlatformChecker().isMobile()}" }
            init()
        }
    }

    fun init() {
        viewModelScope.launch {
            repository.getTeamsFlow().collect {
                _teams.value = it
            }
        }
        viewModelScope.launch {
            getAllUsers()
            getAllContacts()
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }

        CoroutineScope(Dispatchers.Default).launch {
            repository.getJobsFlow()?.collect { list ->
                jobList.clear()
                filteredJobs.clear()
                val siteMap = mutableMapOf<String, Site>()
                list.forEach { job ->
                    job.contactParentId?.let {
                        val contact = getContactForJob(it)
                        contact?.id?.let { id -> contacts[id] = contact }
                    }
                    job.contactSiteId?.let {
                        val site = getSiteById(it)
                        site?.id?.let { id -> siteMap[job.id!!] = site }
                    }
                    jobList.add(job)
                    filteredJobs.add(job)
                }
                sites.clear()
                sites.putAll(siteMap)
            }
        }

        parseSchedule()
    }

    suspend fun getContactForJob(id: String): Contact? {
        return repository.getContactById(id)
    }

    suspend fun getAllUsers() {
        val result = repository.getUsers()

        when (result) {
            is Resource.Success -> {
                println(result)
            }

            is Resource.Error -> {
                println(result)
            }
        }
    }

    private suspend fun getAllContacts() {
        val result = repository.getContactsByWorkspaceId()

        when (result) {
            is Resource.Success -> {
                println(result)
            }

            is Resource.Error -> {
                println(result)
            }
        }
    }

    fun isEmployee(): Boolean {
        return repository.getUser()?.role.equals(Role.employee.name, ignoreCase = true)
    }

    fun filterJobs() {
        filteredJobs.clear()
        if (selectedStatus.value.isNullOrEmpty() &&
            selectedEmployees.value.isNullOrEmpty() &&
            selectedSchedule.value.isNullOrEmpty() &&
            selectedLocation.value.isNullOrEmpty()
        ) {
            filteredJobs.addAll(jobList)
        } else {

            val selectedSitesIds = selectedLocation.value.flatMap { selectedAddress ->
                sites.filterValues { site ->
                    site.addressObject?.address.equals(selectedAddress, ignoreCase = true)
                }.values.map { it.id }
            }
            filteredJobs.addAll(jobList.filter { job ->
                selectedStatus.value.any { query ->
                    job.status?.contains(query, ignoreCase = true) == true
                } || selectedEmployees.value.any { query ->
                    job.assignees?.employees?.any { employee ->
                        employee?.getName()?.contains(query, ignoreCase = true) == true
                    } == true
                } || selectedSchedule.value.any { query ->
                    job.startDate?.contains(query, ignoreCase = true) == true
                } || selectedSitesIds.contains(job.contactSiteId)
            })
        }
    }

    fun parseSchedule() {
        viewModelScope.launch {
            repository.getJobsFlow()?.collect { jobs ->
                val jobsForEvents =
                    jobs.filter { job ->
                        var startDateTime: LocalDateTime? = null
                        try {
                            val dt = job.startDate + "T" + job.start_at
                            startDateTime = LocalDateTime.parse(dt)
                        } catch (e: Exception) {

                        }
                        var endDateTime: LocalDateTime? = null
                        try {
                            val dt = job.startDate + "T" + job.end_at
                            endDateTime = LocalDateTime.parse(dt)
                        } catch (e: Exception) {

                        }

                        var date: LocalDate? = null
                        try {
                            date = startDateTime?.date
                        } catch (e: Exception) {

                        }
                        date == selectedDay &&
                                startDateTime != null &&
                                endDateTime != null
                    }

                dashboardEventList.clear()
                jobsForEvents.forEach { job ->
                    var startDateTime: LocalDateTime? = null
                    try {
                        val dt = job.startDate + "T" + job.start_at
                        startDateTime = LocalDateTime.parse(dt)
                    } catch (e: Exception) {

                    }
                    var endDateTime: LocalDateTime? = null
                    try {
                        val dt = job.startDate + "T" + job.end_at
                        endDateTime = LocalDateTime.parse(dt)
                    } catch (e: Exception) {

                    }
                    dashboardEventList.add(
                        DashboardEvent(
                            //todo change status when enum is finished from api side
                            EventStatusMapper().eventStatus(job.status ?: JobStatus.draft.name),
                            startDateTime!!,
                            endDateTime!!,
                            job.jobTitle
                        )
                    )
                }
            }
        }
    }

    private suspend fun getSiteById(id: String): Site? {
        return repository.getSiteById(id)
    }
}