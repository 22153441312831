package com.hyperether.goodjob.scenes.dashboard

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Text
import androidx.compose.material.TextFieldDefaults
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.em
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.are_you_sure
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.confirmation
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.ic_search
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.search
import com.hyperether.goodjob.scenes.addNew.DropdownSortBy
import com.hyperether.goodjob.scenes.components.JobListGroupedByDateWithCheckboxes
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.jobs.JobViewModel
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.RegisterBorder
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobsWeb(
    navController: NavHostController,
    viewModel: JobViewModel
) {

    val employees by viewModel.users.collectAsState(initial = emptyList())
    val contacts by viewModel.contacts.collectAsState(emptyList())
    val errorMessage = remember { mutableStateOf("") }
    val showErrorDialog = remember { mutableStateOf(false) }
    val isInSearchMode = remember { mutableStateOf(false) }
    val searchQuery = remember { mutableStateOf("") }
    val isAllSelected = remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
    ) {
        WebViewHeader(
            isAddNewJob = true,
            isBackAvailable = false,
            title = stringResource(Res.string.jobs),
            isImportAndAddVisible = true,
            navHostController = navController
        )
        Spacer(modifier = Modifier.height(Dp(15f)))
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(color = Color(0xfffcfcfc))
                .padding(start = Dp(75f), end = Dp(75f), top = Dp(15f))
        ) {
            Box(
                modifier = Modifier
                    .align(alignment = Alignment.TopCenter)
                    .fillMaxWidth()
                    .requiredHeight(height = Dp(773f))
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .requiredHeight(height = Dp(789f))
                        .background(color = Color.White)
                        .border(width = Dp(1f), color = RegisterBorder)
                ) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .requiredHeight(height = Dp(110f))
                    ) {
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .requiredHeight(height = Dp(110f))
                                .clip(
                                    shape = RoundedCornerShape(
                                        topStart = Dp(4f),
                                        topEnd = Dp(4f)
                                    )
                                )
                                .background(color = Color.White)
                        )
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier
                                .height(Dp(110f))
                                .drawBehind {
                                    val strokeWidth = Dp(1f).toPx()
                                    drawLine(
                                        color = RegisterBorder,
                                        start = Offset(0f, size.height - strokeWidth / 2),
                                        end = Offset(size.width, size.height - strokeWidth / 2),
                                        strokeWidth = strokeWidth
                                    )
                                }
                                .padding(horizontal = Dp(23f))
                        ) {
                            Column(
                                modifier = Modifier.weight(2f),
                            ) {
                                Text(
                                    text = "Upcoming jobs",
                                    fontSize = Sp(24f),
                                    fontWeight = FontWeight.W700,
                                )
                                if (viewModel.selectedJobs.isNotEmpty()) {
                                    Text(
                                        text = stringResource(Res.string.delete).lowercase().capitalize(),
                                        color = Color.Red,
                                        fontSize = Sp(16f),
                                        modifier = Modifier
                                            .padding(top = Dp(12f))
                                            .clickable {
                                                viewModel.showAreYouSurePopUpDeleteJob.value = true
                                            }
                                    )
                                }
                            }

                            OutlinedTextField(
                                textStyle = TextStyle(fontSize = Sp(12f)),
                                value = viewModel.jobSearchText.value,
                                onValueChange = { viewModel.jobSearchText.value = it },
                                placeholder = {
                                    Text(
                                        text = stringResource(Res.string.search),
                                        fontSize = Sp(12f),
                                        fontWeight = FontWeight.W400
                                    )
                                },
                                modifier = Modifier
                                    .width(Dp(226f))
                                    .height(Dp(53f))
                                    .border(
                                        width = Dp(1f),
                                        color = BorderColor,
                                        shape = RoundedCornerShape(Dp(8f))
                                    ),
                                singleLine = true,
                                leadingIcon = {
                                    Image(
                                        modifier = Modifier
                                            .height(Dp(24f))
                                            .width(Dp(24f)),
                                        painter = painterResource(Res.drawable.ic_search),
                                        contentDescription = "Search"
                                    )
                                },
                                colors = TextFieldDefaults.textFieldColors(
                                    backgroundColor = Color.White,
                                    focusedIndicatorColor = Color.Transparent,
                                    unfocusedIndicatorColor = Color.Transparent
                                )
                            )
                            Spacer(modifier = Modifier.width(Dp(16f)))

                            Row(
                                modifier = Modifier
                                    .height(Dp(55f)).width(Dp(100f))
                                    .border(
                                        width = Dp(1f),
                                        color = BorderColor,
                                        shape = RoundedCornerShape(Dp(8f))
                                    )
                                    .clickable { viewModel.showFilterDialog.value = true },
                                horizontalArrangement = Arrangement.Center,
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    text = stringResource(Res.string.filter),
                                    fontSize = Sp(14f),
                                    fontWeight = FontWeight.W400,
                                )
                                Image(
                                    painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                                    contentDescription = "Filter",
                                    modifier = Modifier.width(Dp(24f)).height(Dp(24f))
                                )
                            }
                            Spacer(modifier = Modifier.width(Dp(16f)))

                            // TODO: get options
                            DropdownSortBy(
                                shouldAppendSortBy = false,
                                items = listOf("This week"),
                                selectedValue = viewModel.jobSortBy.value ?: "This week",
                                onValueSelected = { viewModel.jobSortBy.value = it },
                                displayText = { it },
                                placeholderColor = BorderColor,
                                textColor = Color.Black,
                                modifier = Modifier.height(Dp(55f))
                            )
                        }
                    }
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .background(color = Color(0xfffafafa))
                            .padding(horizontal = Dp(45f))
                            .requiredHeight(height = Dp(38f)),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Checkbox(
                            modifier = Modifier.padding(end = Dp(8f)),
                            checked = isAllSelected.value,
                            onCheckedChange = { checked ->
                                isAllSelected.value = checked
                                viewModel.selectedJobs.clear()
                                if (checked) {
                                    viewModel.selectedJobs.addAll(viewModel.jobList)
                                }
                            },
                            colors = CheckboxDefaults.colors(
                                checkedColor = BlueApp,
                                uncheckedColor = com.hyperether.goodjob.theme.Divider
                            )
                        )
                        Text(
                            text = "Title",
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier.weight(0.9f)
                        )
                        Text(
                            text = "Site",
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.9f)
                        )
                        Text(
                            text = "Location",
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(1f)
                        )
                        Text(
                            text = "Time",
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.7f)
                        )
                        Text(
                            text = "Employee",
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.7f)
                        )
                        Text(
                            text = "Status",
                            color = Color(0xff797676),
                            textAlign = TextAlign.Center,
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.5f)
                        )
                    }
                    JobListGroupedByDateWithCheckboxes(
                        viewModel = viewModel,
                        isJobList = true,
                        jobs = viewModel.jobList,
                        navHostController = navController,
                        searchQuery = searchQuery,
                        isAllSelected = isAllSelected.value,
                        onAllSelectedChange = { isChecked ->
                            isAllSelected.value = isChecked
                            viewModel.selectedJobs.clear()
                            if (isChecked) {
                                viewModel.selectedJobs.addAll(viewModel.jobList)
                            }
                        },
                        selectedJobs = viewModel.selectedJobs,
                        onJobSelectedChange = { jobId, isChecked ->
                            if (isChecked) {
                                viewModel.selectedJobs.add(jobId)
                            } else {
                                viewModel.selectedJobs.remove(jobId)
                            }
                            isAllSelected.value = viewModel.selectedJobs.size == viewModel.jobList.size
                        }
                    )
                }
            }
        }
        if (viewModel.showAreYouSurePopUpDeleteJob.value) {
            PopUpDialog(
                onDismiss = { viewModel.showAreYouSurePopUpDeleteJob.value = false },
                title = stringResource(Res.string.confirmation),
                message = stringResource(Res.string.are_you_sure),
                onPositiveBtnClicked = {
                    viewModel.deleteSelectedJobs(
                        onAllDeleted = {
                            viewModel.init()
                        },
                        onError = {

                        })
                    viewModel.showAreYouSurePopUpDeleteJob.value = false
                },
                onNegativeBtnClicked = {
                    viewModel.showAreYouSurePopUpDeleteJob.value = false
                },
                positiveBtnText = stringResource(Res.string.delete),
                negativeBtnText = stringResource(Res.string.cancel)
            )
        }
    }

    if (viewModel.showFilterDialog.value) {
        // TODO
    }
}