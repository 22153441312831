package com.hyperether.goodjob.scenes.pricing

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class PricingViewModel(
    val repository: Repository
) : ViewModel() {
    private val _plans = MutableStateFlow<List<Plan>>(emptyList())
    val plans: StateFlow<List<Plan>> = _plans

    private val _plans_remote = MutableStateFlow<List<Plan>>(emptyList())
    val plans_remote: StateFlow<List<Plan>> = _plans_remote

    fun getAllPlans() {
        viewModelScope.launch {
            val result = repository.getAllPlans()
            if (result.data != null) {
                val localPlaIds = plans.value.map { it._id }.toSet()

                val newPlans =
                    result.data.filter { it._id != null && it._id !in localPlaIds }

                if (newPlans.isNotEmpty() && getPlatformChecker().isMobile()) {
                    newPlans.forEach { repository.insertPlan(it) }
                    getAllWorkspacesLocal()
                }
                _plans_remote.value = result.data
            }
        }
    }

    private suspend fun getAllWorkspacesLocal() {
        repository.getPlans().collect { planList ->
            _plans.value = planList
        }
    }

    fun addPlan(planId: String, onSuccess: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch {
            when (val result =
                repository.addPlan(planId)) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    onError(result.text.toString() ?: "An unknown error occurred")
                }
            }
        }
    }
}