package com.hyperether.goodjob.util

import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf

object FilterDialogStateManager {

    private val _dialogShowing = mutableStateOf(false)
    val dialogShowing: State<Boolean> get() = _dialogShowing

    fun setDialogShowing(value: Boolean) {
        _dialogShowing.value = value
    }

}