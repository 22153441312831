package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Checkbox
import androidx.compose.material.CheckboxDefaults
import androidx.compose.material.Divider
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.repository.remote.model.FileObject
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_file
import com.hyperether.goodjob.resources.add_note
import com.hyperether.goodjob.resources.add_note_here
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.contact_and_site_details
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_person
import com.hyperether.goodjob.resources.deadline_and_duration
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.document
import com.hyperether.goodjob.resources.due_date
import com.hyperether.goodjob.resources.duration
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.images
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.job_schedule
import com.hyperether.goodjob.resources.newest
import com.hyperether.goodjob.resources.notes
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.oldest
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.selected
import com.hyperether.goodjob.resources.sort_by
import com.hyperether.goodjob.resources.start_date
import com.hyperether.goodjob.resources.status_check_icon
import com.hyperether.goodjob.resources.time
import com.hyperether.goodjob.resources.trash
import com.hyperether.goodjob.scenes.addNew.DropdownSortBy
import com.hyperether.goodjob.scenes.components.CircleWithInitial
import com.hyperether.goodjob.scenes.components.NoteEditDialog
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EditJobsDialog(
    showDialog: Boolean, jobs: List<Job>,
    onDelete: () -> Unit,
    onExportList: () -> Unit,
    onImportList: () -> Unit,
    isEmployee: Boolean
) {
    if (showDialog) {
        Box(
            modifier = Modifier
                .width(300.dp)
                .padding(16.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp))
            //.shadow(1.dp, RoundedCornerShape(8.dp))
        ) {
            Column() {
                Text(
                    text = "${jobs.size} " + stringResource(Res.string.selected),
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    if (!isEmployee) {
                        Text(
                            text = stringResource(Res.string.delete),
                            fontSize = 14.sp,
                            color = Color.Red,
                            modifier = Modifier.clickable {
                                onDelete()
                            }
                        )
                    }
//                    Text(
//                        text = stringResource(Res.string.export_list),
//                        fontSize = 14.sp,
//                        color = BlueApp,
//                        modifier = Modifier.clickable {
//                            onExportList()
//                        })
//                    Text(
//                        text = stringResource(Res.string.import_list),
//                        fontSize = 14.sp,
//                        color = BlueApp,
//                        modifier = Modifier.clickable {
//                            onImportList()
//                        })
                }
            }
        }
    }
}

@Composable
fun ImageCard(imageDescription: String) {
    Box(
        modifier = Modifier
            .height(150.dp)
            .clip(RoundedCornerShape(8.dp))
            .background(Color.LightGray),
        contentAlignment = Alignment.Center
    ) {
        Text(text = imageDescription, color = Color.Gray)
    }
}

@Composable
fun SectionTitle(title: String) {
    Text(
        text = title,
        style = MaterialTheme.typography.h6,
        modifier = Modifier.padding(vertical = 8.dp)
    )
}

@Composable
fun CheckBoxItem(
    question: String,
    answer: String,
    checked: Boolean,
    modifier: Modifier = Modifier,
    onCheckedChange: (Boolean) -> Unit
) {
    Column {
        Text(text = question, style = MaterialTheme.typography.subtitle1, color = Color.Black)
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = modifier.padding(vertical = 4.dp)
        ) {
            Checkbox(
                checked = checked,
                onCheckedChange = onCheckedChange,
                colors = CheckboxDefaults.colors(checkedColor = Green)
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(
                text = answer,
                style = MaterialTheme.typography.body1,
                color = Color.Black,
                fontWeight = FontWeight.Bold
            )
        }
    }
}

@Composable
fun IssueReportView(
    answer: String
) {
    Column {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(vertical = 4.dp)
        ) {
            Image(
                painter = painterResource(Res.drawable.status_check_icon),
                contentDescription = null,
                modifier = Modifier.size(25.dp)
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(
                text = answer,
                style = MaterialTheme.typography.body1,
                color = Color.Black,
                fontWeight = FontWeight.Bold
            )
        }
    }
}

@Composable
fun CheckBoxItemBoldTitle(
    question: String,
    answer: String,
    checked: Boolean,
    onCheckedChange: (Boolean) -> Unit
) {
    Column {
        Text(text = question, style = MaterialTheme.typography.h6, fontSize = 16.sp)
        Row(
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Checkbox(
                checked = checked,
                onCheckedChange = onCheckedChange,
                colors = CheckboxDefaults.colors(checkedColor = Green)
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(text = answer, style = MaterialTheme.typography.body1, color = Color.Gray)
        }
    }
}

@Composable
fun CheckBoxMultipleItemBoldTitle(
    question: String,
    answerList: List<String>,
    checkedList: List<Boolean>,
    onSelectedAnswer: (Int) -> Unit
) {
    Column {
        Text(text = question, style = MaterialTheme.typography.h6, fontSize = 16.sp)
        for (answer in answerList) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Checkbox(
                    checked = checkedList.get(answerList.indexOf(answer)),
                    onCheckedChange = {
                        onSelectedAnswer(answerList.indexOf(answer))
                    },
                    colors = CheckboxDefaults.colors(checkedColor = Green)
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(text = answer, style = MaterialTheme.typography.body1, color = Color.Gray)
            }
        }
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun NotesAndDocumentsView(
    isWebView: Boolean = false,
    images: List<Pair<FileObject, BitmapPainter>>,
    documents: List<Pair<FileObject, String>>,
    job: Job,
    isEmployee: Boolean,
    onAddNoteClick: () -> Unit,
    onOpenPdfClick: (String) -> Unit,
    onDeleteFileClick: (String) -> Unit,
    onDeleteImageClick: (String) -> Unit,
    onMenuClicked: (Note) -> Unit,
    onEditNote: (Note) -> Unit,
    onDeleteNote: (Note) -> Unit,
    isPopupVisible: (Note) -> Boolean
) {
    val sortBy = remember { mutableStateOf<String?>(null) }
    val sortOptions = listOf(
        stringResource(Res.string.newest),
        stringResource(Res.string.oldest)
    )


    Column(modifier = Modifier.padding(16.dp).verticalScroll(rememberScrollState())) {
        // Notes Section
        SectionHeader(
            isSortByVisible = isWebView,
            sortBy = sortBy,
            items = sortOptions,
            title = stringResource(Res.string.notes),
            actionText = stringResource(Res.string.add_note),
            onAddNoteClick = { onAddNoteClick() },
            onSortChange = {
                sortBy.value = it
            }
        )
        job.noteObjects?.let { noteList ->
            if (noteList.isNotEmpty()) {
                var filteredNotes = noteList.filter { it.noteText?.isNotBlank() == true }
                filteredNotes = when (sortBy.value) {
                    stringResource(Res.string.newest) -> filteredNotes.sortedByDescending { it.createdAt }
                    stringResource(Res.string.oldest) -> filteredNotes.sortedBy { it.createdAt }
                    else -> filteredNotes
                }
                if (filteredNotes.isNotEmpty()) {
                    Spacer(modifier = Modifier.height(12.dp))
                    if (!isWebView) {
                        Column(
                            modifier = Modifier
                                .fillMaxWidth(),
                            verticalArrangement = Arrangement.spacedBy(8.dp)
                        ) {
                            filteredNotes.forEach { note ->
                                NoteRow(
                                    note = note,
                                    isPopupVisible = isPopupVisible(note),
                                    onMenuClicked = {
                                        onMenuClicked(note)
                                    },
                                    onEdit = {
                                        onEditNote(note)
                                    },
                                    onDelete = {
                                        onDeleteNote(note)
                                    }
                                )
                                Spacer(modifier = Modifier.height(4.dp))
                            }
                        }
                    } else {
                        FlowRow(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalArrangement = Arrangement.spacedBy(24.dp),
                            verticalArrangement = Arrangement.spacedBy(24.dp)
                        ) {
                            filteredNotes.forEach { note ->
                                Box(
                                    modifier = Modifier
                                        .width(200.dp) // Set a fixed width
                                        .wrapContentHeight()
                                ) {
                                    NoteRow(
                                        isWebView = true,
                                        note = note,
                                        isPopupVisible = isPopupVisible(note),
                                        onMenuClicked = { onMenuClicked(note) },
                                        onEdit = { onEditNote(note) },
                                        onDelete = { onDeleteNote(note) }
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
        Spacer(modifier = Modifier.height(16.dp))

        // Document Section
        if (documents.isNotEmpty()) {
            SectionHeader(
                isSortByVisible = false,
                sortBy = null,
                items = null,
                title = stringResource(Res.string.document),
                actionText = stringResource(Res.string.add_file),
                onAddNoteClick = {},
                onSortChange = {}
            )
            Spacer(modifier = Modifier.height(24.dp))
            Column(verticalArrangement = Arrangement.spacedBy(8.dp)) {
                for ((fileObject, filePath) in documents) {
                    DocumentRow(
                        isEmployee = isEmployee,
                        documentName = fileObject.documentFileId
                            ?: filePath.substringAfterLast("/"),
                        author = fileObject.createdBy.orEmpty(),
                        date = fileObject.createdAt ?: "",
                        openPdf = { onOpenPdfClick(filePath) },
                        onDeleteFileClick = { onDeleteFileClick(fileObject.documentFileId ?: "") }
                    )
                }
            }
        }
        Spacer(modifier = Modifier.height(32.dp))

        // Images Section
        if (images.isNotEmpty()) {
            SectionHeader(
                isSortByVisible = false,
                sortBy = null,
                items = null,
                title = stringResource(Res.string.images),
                actionText = "",
                onAddNoteClick = {},
                onSortChange = {}
            )
            Spacer(modifier = Modifier.height(11.dp))
            if (!getPlatformChecker().isMobile()) {
                FlowRow(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.spacedBy(16.dp),
                    verticalArrangement = Arrangement.spacedBy(16.dp)
                ) {
                    for ((fileObject, imagePainter) in images) {
                        Column(
                            modifier = Modifier.width(200.dp)
                        ) {
                            ImageRow(
                                isEmployee = isEmployee,
                                imageName = fileObject.documentFileId.orEmpty(),
                                imagePainter = imagePainter,
                                onDeleteImageClick = {
                                    onDeleteImageClick(fileObject.documentFileId ?: "")
                                }
                            )
                        }
                    }
                }
            } else {
                Column(
                    modifier = Modifier.fillMaxWidth().padding(16.dp)
                ) {
                    for ((fileObject, imagePainter) in images) {
                        Row(
                            horizontalArrangement = Arrangement.spacedBy(8.dp),
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            ImageRow(
                                isEmployee = isEmployee,
                                imageName = fileObject.documentFileId.orEmpty(),
                                imagePainter = imagePainter,
                                onDeleteImageClick = {
                                    onDeleteImageClick(fileObject.documentFileId ?: "")
                                }
                            )
                        }
                        Spacer(modifier = Modifier.height(4.dp))
                    }
                }
            }
        }
    }
}

@Composable
fun SectionHeader(
    isSortByVisible: Boolean = false,
    sortBy: MutableState<String?>?,
    items: List<String>?,
    title: String,
    actionText: String,
    onAddNoteClick: () -> Unit,
    onSortChange: (String) -> Unit
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = title,
            style = MaterialTheme.typography.h6,
            fontWeight = FontWeight.Bold
        )
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(8.dp)
        ) {
            if (actionText.isNotEmpty()) {
                Text(
                    text = actionText,
                    color = BlueApp,
                    style = MaterialTheme.typography.body1,
                    modifier = Modifier.border(1.dp, BlueApp, RoundedCornerShape(12.dp))
                        .padding(horizontal = 12.dp, vertical = 10.dp).clickable {
                            onAddNoteClick()
                        }
                )
            }
            val sortByString = stringResource(Res.string.sort_by)
            if (isSortByVisible) {
                DropdownSortBy(
                    shouldAppendSortBy = false,
                    items = items ?: emptyList(),
                    selectedValue = sortBy?.value ?: items?.get(0),
                    onValueSelected = { selectedSort ->
                        sortBy?.value = selectedSort
                        selectedSort?.let { onSortChange(it) }
                    },
                    displayText = { it ?: sortByString },
                    placeholderColor = BorderColor,
                    textColor = Color.Black,
                    modifier = Modifier.height(Dp(55f))
                )
            }
        }
    }
}

@Composable
fun NoteRow(
    isWebView: Boolean = false,
    note: Note,
    isPopupVisible: Boolean,
    onMenuClicked: (Note) -> Unit,
    onEdit: () -> Unit,
    onDelete: () -> Unit
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .border(1.dp, BorderColor, RoundedCornerShape(4.dp))
            .padding(16.dp)
    ) {
        Column {
            Row(
                horizontalArrangement = Arrangement.SpaceBetween,
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = note.noteText.orEmpty(),
                    style = MaterialTheme.typography.body1
                )
                Icon(
                    imageVector = Icons.Default.MoreVert,
                    contentDescription = "Menu",
                    modifier = Modifier.size(24.dp)
                        .clickable {
                            onMenuClicked(note)
                        },
                    tint = Color(0xFF605E5E)
                )
            }
            Spacer(modifier = Modifier.height(16.dp))
            if (!isWebView) {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        CircleWithInitial(note.createdBy.orEmpty())
                        Spacer(modifier = Modifier.width(8.dp))
                        Text(
                            text = note.createdBy.orEmpty(),
                            style = MaterialTheme.typography.body2,
                            color = Color(0xFF484646)
                        )
                    }
                    Text(
                        text = note.createdAt.orEmpty(),
                        style = MaterialTheme.typography.body2,
                        color = Color.Gray
                    )
                }
            } else {
                Column(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Text(
                        text = note.createdAt.orEmpty(),
                        style = MaterialTheme.typography.body2,
                        color = Color.Gray
                    )
                    Spacer(modifier = Modifier.height(8.dp))
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        CircleWithInitial(size = 25.dp, name = note.createdBy.orEmpty())
                        Spacer(modifier = Modifier.width(8.dp))
                        Text(
                            text = note.createdBy.orEmpty(),
                            style = MaterialTheme.typography.body2,
                            color = Color(0xFF484646)
                        )
                    }

                }
            }
        }
        if (isPopupVisible) {
            Box(
                modifier = Modifier
                    .align(Alignment.CenterEnd)
            ) {
                NoteEditDialog(
                    onDelete = onDelete,
                    onEdit = onEdit
                )
            }
        }
    }
}

@Composable
fun DocumentRow(
    documentName: String,
    author: String,
    date: String,
    isEmployee: Boolean,
    openPdf: () -> Unit,
    onDeleteFileClick: (String) -> Unit
) {
    Column {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = documentName,
                color = BlueApp,
                style = MaterialTheme.typography.body1,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier
                    .weight(1f)
                    .padding(end = 8.dp)
                    .clickable { openPdf() }
            )
            if (!isEmployee) {
                Image(
                    painter = painterResource(Res.drawable.trash),
                    contentDescription = null,
                    modifier = Modifier
                        .size(20.dp)
                        .clickable {
                            onDeleteFileClick(documentName)
                        }
                )
            }
        }
        Spacer(modifier = Modifier.height(2.dp))
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = author,
                style = MaterialTheme.typography.body2,
                color = Color.Gray
            )
            Text(
                text = date,
                style = MaterialTheme.typography.body2,
                color = Color.Gray
            )
        }
    }
}

@Composable
fun ImageRow(
    isDeleteVisible: Boolean = true,
    imageName: String,
    imagePainter: Painter?,
    isEmployee: Boolean,
    onDeleteImageClick: (String) -> Unit
) {
    Column {
        if (imagePainter != null) {
            Image(
                painter = imagePainter,
                contentDescription = null,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(150.dp)
                    .clip(RoundedCornerShape(8.dp))
            )
        }
        Spacer(modifier = Modifier.height(8.dp))
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = imageName,
                style = MaterialTheme.typography.body2,
                color = Color.Gray,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier
                    .weight(1f)
                    .padding(end = 8.dp)
            )
            Spacer(modifier = Modifier.width(14.dp))
            if (!isEmployee) {
                if (isDeleteVisible) {
                    Image(
                        painter = painterResource(Res.drawable.trash),
                        contentDescription = null,
                        modifier = Modifier
                            .clip(RoundedCornerShape(4.dp))
                            .size(20.dp)
                            .clickable {
                                onDeleteImageClick(imageName)
                            }
                    )
                }
            }
        }
    }
}

@Composable
fun NoteInputDialog(
    initialText: MutableState<String>,
    onDismiss: () -> Unit,
    onConfirm: () -> Unit
) {

    Dialog(onDismissRequest = onDismiss) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .clip(RoundedCornerShape(16.dp))
                .background(Color.White)
        ) {
            Column(
                modifier = Modifier.padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    text = stringResource(Res.string.add_note_here),
                    fontSize = 20.sp,
                    color = BlueApp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 8.dp)
                )

                OutlinedTextField(
                    value = initialText.value,
                    onValueChange = { initialText.value = it },
                    label = { Text("Enter text") },
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(bottom = 16.dp)
                )

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.End
                ) {
                    TextButton(onClick = onDismiss) {
                        Text(
                            stringResource(Res.string.cancel), color = Color.LightGray
                        )
                    }
                    Spacer(modifier = Modifier.width(8.dp))
                    TextButton(onClick = {
                        onConfirm()
                    }
                    ) {
                        Text(stringResource(Res.string.ok).uppercase(), color = BlueApp)
                    }
                }
            }
        }
    }
}

@Composable
fun AppInputFieldWithUpdate(
    note: Note,
    onNoteChange: (Note) -> Unit,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier,
    enabled: Boolean = true
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = note.noteText.orEmpty(),
            onValueChange = { newText ->
                if (enabled) {
                    onNoteChange(note.copy(noteText = newText))
                }
            },
            label = {
                Text(
                    text = label,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            placeholder = {
                Text(
                    text = placeholder,
                    fontSize = 14.sp,
                    color = if (enabled) TextColor else Color.Gray
                )
            },
            enabled = enabled
        )
    }
}

@Composable
fun StatusBadge(status: String) {
    Box(
        modifier = Modifier.background(
            StatusColor().getStatusColor(status).copy(alpha = 0.1f),
            RoundedCornerShape(Dp(8f))
        )
            .width(Dp(125f)).height(Dp(35f))
            .padding(horizontal = Dp(8f), vertical = Dp(4f))
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(text = status.lowercase().capitalize(), fontSize = Sp(16f), color = Color.Black)
            Box(
                modifier = Modifier
                    .size(Dp(16f))
                    .background(
                        color = StatusColor().getStatusColor(status),
                        shape = CircleShape
                    )
            )
        }
    }
}

@Composable
fun JobScheduleSection(
    job: Job
) {
    Card(
        modifier = Modifier.fillMaxWidth(),
        shape = RoundedCornerShape(8.dp),
        colors = CardDefaults.cardColors(containerColor = Color.White)
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(vertical = 8.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Column(modifier = Modifier.weight(1f)) {
                    Text(
                        stringResource(Res.string.job_schedule),
                        fontWeight = FontWeight.Bold,
                        fontSize = 20.sp
                    )
                    Spacer(modifier = Modifier.height(25.dp))
                    Row {
                        Column {
                            Text(
                                stringResource(Res.string.start_date),
                                color = Color.Black,
                                fontWeight = FontWeight.Bold
                            )
                            Text(job.startDate ?: "")
                        }
                        Spacer(modifier = Modifier.width(16.dp))
                        Column {
                            Text(
                                stringResource(Res.string.time),
                                color = Color.Black,
                                fontWeight = FontWeight.Bold
                            )
                            Text("${job.start_at} - ${job.end_at}")
                        }
                    }
                    Spacer(modifier = Modifier.height(8.dp))

                }

                Divider(
                    modifier = Modifier
                        .width(1.dp)
                        .height(100.dp)
                        .background(BorderColor)
                )

                Spacer(modifier = Modifier.width(50.dp))
                Column(
                    modifier = Modifier.weight(1f),
                    horizontalAlignment = Alignment.Start
                ) {
                    Text(
                        stringResource(Res.string.deadline_and_duration),
                        fontWeight = FontWeight.Bold,
                        fontSize = 20.sp
                    )
                    Spacer(modifier = Modifier.height(25.dp))
                    Row {
                        Column {
                            Text(
                                stringResource(Res.string.due_date),
                                color = Color.Black,
                                fontWeight = FontWeight.Bold
                            )
                            Text(job.dueDate ?: "")
                        }
                        Spacer(modifier = Modifier.width(16.dp))
                        Column {
                            Text(
                                stringResource(Res.string.duration),
                                color = Color.Black,
                                fontWeight = FontWeight.Bold
                            )
                            Text(if (job.jobDuration != null) job.jobDuration.toString() else "")
                        }
                    }
                    Spacer(modifier = Modifier.height(8.dp))
                }
            }
        }
        Divider()
    }
}


@Composable
fun ContactDetailsSection(
    contact: Contact
) {
    Card(
        modifier = Modifier.fillMaxWidth(),
        shape = RoundedCornerShape(8.dp),
        colors = CardDefaults.cardColors(containerColor = Color.White)
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                stringResource(Res.string.contact_and_site_details),
                fontWeight = FontWeight.Bold,
                fontSize = 20.sp
            )
            Spacer(modifier = Modifier.height(24.dp))
            Row {
                Column {
                    Text(
                        stringResource(Res.string.contact_name),
                        fontWeight = FontWeight.Bold,
                        fontSize = 16.sp
                    )
                    Spacer(modifier = Modifier.height(12.dp))
                    Text(contact.name ?: "", color = BlueApp, fontSize = 16.sp)
                }
                Spacer(modifier = Modifier.width(46.dp))
                Column {
                    Text(
                        stringResource(Res.string.contact_person),
                        fontWeight = FontWeight.Bold,
                        fontSize = 16.sp
                    )
                    Spacer(modifier = Modifier.height(12.dp))
                    Text(contact.personName ?: "", fontSize = 16.sp)
                }
            }
            Spacer(modifier = Modifier.height(24.dp))
            Row {
                Column {
                    Text(
                        stringResource(Res.string.phone),
                        fontWeight = FontWeight.Bold,
                        fontSize = 16.sp
                    )
                    Spacer(modifier = Modifier.height(12.dp))
                    Text(contact.phone ?: "")
                }
                Spacer(modifier = Modifier.width(46.dp))
                Column {
                    Text(stringResource(Res.string.email), fontWeight = FontWeight.Bold)
                    Spacer(modifier = Modifier.height(12.dp))
                    Text(contact.email ?: "", color = BlueApp)
                }
            }
        }
    }
}