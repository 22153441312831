package com.hyperether.goodjob.map

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import com.hyperether.goodjob.repository.remote.model.LatLng

@Composable
expect fun MapComponent(markers: List<Marker>, currentPosition: State<LatLng?>)


data class Marker(
    val lat: Double,
    val lng: Double,
    val title: String,
    val status: String = "On hold",
    var selected: Boolean = false
)
