package com.hyperether.goodjob.scenes.workspace

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.access_dashboard
import com.hyperether.goodjob.resources.choose_your_workspace
import com.hyperether.goodjob.resources.create_workspace
import com.hyperether.goodjob.resources.last_accessed
import com.hyperether.goodjob.resources.logo_good_job
import com.hyperether.goodjob.resources.new_workspace
import com.hyperether.goodjob.resources.plan
import com.hyperether.goodjob.resources.plus
import com.hyperether.goodjob.resources.role
import com.hyperether.goodjob.resources.select_your_workspace
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.LightGrey
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun WorkspaceScreen(
    isMobileView: Boolean,
    workspaceViewModel: WorkspaceViewModel,
    continueToDashboard: () -> Unit,
    addNewWorkspace: () -> Unit) {

    val selectedWorkspace = remember { mutableStateOf<String?>(null) }
    val workspaces = workspaceViewModel.workspaces.collectAsState()

    Column(
        modifier = Modifier
            .fillMaxHeight()
            .sizeIn(minWidth = if (isMobileView) 0.dp else 1200.dp, maxWidth = if (isMobileView) Dp.Unspecified else 2400.dp)
            .verticalScroll(rememberScrollState())
            .padding(16.dp),
        horizontalAlignment = if (isMobileView) Alignment.Start else Alignment.CenterHorizontally
    ) {
        if (!isMobileView) {
            LogoHeader()
        }
        TitleSection(isMobileView)

        Spacer(modifier = Modifier.height(30.dp))

        WorkspaceList(
            workspaces = workspaces.value,
            selectedWorkspace.value,
            onWorkspaceSelected = { selectedWorkspace.value = it }
        )

        Spacer(modifier = Modifier.height(30.dp))

        FilledButton(
            onClick = { continueToDashboard() },
            isEnabled = true,
            text = stringResource(Res.string.access_dashboard),
            modifier = Modifier.width(530.dp)
        )

        if (!isMobileView) {
            Spacer(modifier = Modifier.height(30.dp))
            DividerLine()
        }
        CreateWorkspaceSection(isMobileView, addNewWorkspace)
    }
}

@Composable
fun LogoHeader() {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(bottom = 70.dp),
        contentAlignment = Alignment.TopStart
    ) {
        Image(
            painter = painterResource(Res.drawable.logo_good_job),
            contentDescription = null
        )
    }
}

@Composable
fun TitleSection(isMobileView: Boolean) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = if (isMobileView) Alignment.Start else Alignment.CenterHorizontally
    ) {
        if (isMobileView)
            Spacer(modifier = Modifier.height(50.dp))
        Text(
            fontWeight = FontWeight.Bold,
            text = stringResource(Res.string.select_your_workspace),
            color = Color.Black,
            textAlign = if (!isMobileView) TextAlign.Center else TextAlign.Start,
            fontSize = if (!isMobileView) 50.sp else 24.sp,
            lineHeight = if (!isMobileView) 50.sp else 24.sp
        )
        Spacer(modifier = Modifier.height(15.dp))
        Text(
            text = stringResource(Res.string.choose_your_workspace),
            color = DarkGrey,
            textAlign = TextAlign.Start,
            fontSize = 18.sp
        )
    }
}

@Composable
fun WorkspaceList(
    workspaces: List<Workspace>,
    selectedWorkspace: String?,
    onWorkspaceSelected: (String) -> Unit) {
    // TODO
    workspaces.forEach { workspace ->
        WorkspaceItem(
            title = workspace.name!!,
            role = "", //workspace.role,
            lastAccessed =  "", //workspace.lastAccessed,
            plan = "", //workspace.plan!!,
            isSelected = selectedWorkspace == workspace.name,
            onSelect = { onWorkspaceSelected(workspace.name!!) }
        )
    }
}

@Composable
fun DividerLine() {
    Spacer(
        modifier = Modifier
            .height(2.dp)
            .background(LightGrey)
            .width(530.dp)
    )
}

@Composable
fun CreateWorkspaceSection(
    isMobileView: Boolean,
    addNewWorkspace: () -> Unit
) {
    Spacer(modifier = Modifier.height(20.dp))
    Text(
        text = stringResource(Res.string.create_workspace),
        color = Color.Black,
        fontWeight = FontWeight.Bold,
        textAlign = if (!isMobileView) TextAlign.Center else TextAlign.Start,
        fontSize = 18.sp
    )
    Spacer(modifier = Modifier.height(10.dp))
    Row(
        modifier = Modifier.clickable { addNewWorkspace() },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            painter = painterResource(Res.drawable.plus),
            contentDescription = null
        )
        Text(
            text = stringResource(Res.string.new_workspace),
            color = BlueApp,
            textAlign = TextAlign.Center,
            fontSize = 18.sp,
            modifier = Modifier.padding(start = 15.dp)
        )
    }
}


@Composable
fun WorkspaceItem(
    title: String,
    role: String,
    lastAccessed: String,
    plan: String,
    isSelected: Boolean,
    onSelect: () -> Unit
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 8.dp)
            .border(
                width = if (isSelected) 2.dp else 1.dp,
                color = if (isSelected) BlueApp else Color.LightGray,
                shape = RoundedCornerShape(8.dp)
            )
            .background(Color.White, shape = RoundedCornerShape(8.dp))
            .clickable { onSelect() }
            .padding(16.dp)
    ) {
        Column {
            Text(
                text = title,
                fontSize = 18.sp,
                color = Color.Black,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Left
            )
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Text(
                    text = stringResource(Res.string.role),
                    color = DarkGrey,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp
                )
                Text(
                    text = role,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp
                )
            }
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Text(
                    text = stringResource(Res.string.last_accessed),
                    color = DarkGrey,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                )
                Text(
                    text = lastAccessed,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp
                )
            }
            Row(modifier = Modifier.padding(top = 5.dp)) {
                Text(
                    text = stringResource(Res.string.plan),
                    color = LightGrey,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                )
                Text(
                    text = plan,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                )
            }

        }
    }
}
