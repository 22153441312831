package com.hyperether.goodjob.scenes.workspace

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class WorkspaceViewModel(
    val repository: Repository
) : ViewModel() {

    private val _workspaces = MutableStateFlow<List<Workspace>>(emptyList())
    val workspaces: StateFlow<List<Workspace>> = _workspaces

    private val _workspaces_remote = MutableStateFlow<List<Workspace>>(emptyList())
    val workspaces_remote: StateFlow<List<Workspace>> = _workspaces_remote

    var workspaceId: MutableState<String> = mutableStateOf("")


    fun addNewWorkspace(workspace: Workspace) {
        viewModelScope.launch {
            val result = workspace.name?.let {
                workspace.workspaceType?.let { it1 ->
                    repository.addWorkspace(
                        it,
                        it1
                    )
                }
            }
            if (result?.data != null && getPlatformChecker().isMobile()) {
                repository.insertWorkspace(result.data)
                getAllWorkspaces()
            }
        }
    }

    fun getAllWorkspaces() {
        viewModelScope.launch {
            when (val result = repository.getAllWorkspaces()) {
                is Resource.Success -> {
                    result.data?.let { _workspaces_remote.value = it }
                    getAllWorkspacesLocal()
                }
                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    private suspend fun getAllWorkspacesLocal() {
        repository.getWorkspaces().collect { workspaceList ->
            _workspaces.value = workspaceList
        }
    }

    fun getAllJobsForWorkspace() {
        viewModelScope.launch {
            val result = repository.getAllJobsForWorkspace()

            when (result) {
                is Resource.Success -> {
                    println(result)
                }
                is Resource.Error -> {
                    println(result)
                }
            }
        }
    }

    fun getUser(): User? {
        return repository.getUser()
    }

    fun saveWorkspaceId(workspaceId: String) {
        return repository.saveWorkspaceId(workspaceId)
    }
}