package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material3.Checkbox
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.contacts_mail
import com.hyperether.goodjob.resources.contacts_map_pin
import com.hyperether.goodjob.resources.contacts_share
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.email_send_icon_24
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.sites
import com.hyperether.goodjob.scenes.employees.RoundedCornerCheckbox
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.ContactDetailsText
import com.hyperether.goodjob.theme.EmployeeDetailsText
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ContactsItem(
    contact: Contact,
    sites: List<Site>,
    isInEditMode: Boolean,
    onContactSelected: (Contact, Boolean) -> Unit,
    onItemClick: () -> Unit
) {
    var isChecked = remember { mutableStateOf(false) }

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp)
            .clickable {
                onItemClick()
            }
    ) {
        if (isInEditMode) {
            RoundedCornerCheckbox(
                isChecked = isChecked.value,
                onCheckedChange = { checked ->
                    isChecked.value = checked
                    onContactSelected(contact, checked)
                }
            )
        }

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 8.dp)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 4.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                contact.name?.let {
                    Text(
                        text = it,
                        fontSize = 16.sp,
                        color = BlueApp,
                        fontWeight = FontWeight.Bold
                    )
                }
                Spacer(modifier = Modifier.width(15.dp))
                contact.personName?.let {
                    Text(
                        text = it,
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.contacts_mail),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(
                    text = contact.email ?: "",
                    fontSize = 14.sp,
                    color = ContactDetailsText
                )
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.contacts_map_pin),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                contact.addressObject?.address?.let {
                    Text(
                        text = it,
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.phone),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                contact.phone?.let {
                    Text(
                        text = it.toString(),
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.contacts_share),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                contact.industry?.let {
                    Text(
                        text = it.toString(),
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                }
            }
            Spacer(modifier = Modifier.height(4.dp))

            Text(
                text = sites.size.toString() + " " + stringResource(Res.string.sites).lowercase(),
                fontSize = 14.sp,
                color = BlueApp
            )
        }
    }
}

@Composable
fun ContactsList(
    contacts: List<Contact>,
    contactSites: List<Site>,
    selectedContacts: MutableList<Contact>,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize()
    ) {
        if (contacts.isNotEmpty()) {
            items(contacts.takeIf { it.isNotEmpty() } ?: emptyList()) { contact ->
                ContactsItem(
                    contact = contact,
                    isInEditMode = isInEditMode,
                    onContactSelected = { selectedContact, isSelected ->
                        if (isSelected) {
                            selectedContacts.add(selectedContact)
                        } else {
                            selectedContacts.remove(selectedContact)
                        }
                    },
                    onItemClick = {
                        if (getPlatformChecker().isMobile()) {
                            navHostController.navigate(Screen.ContactDetails.route + "/${contact.id}")
                        } else {
                            navHostController.navigate(Screen.ContactDetails.route + "?contactId=${contact.id}")
                        }
                    },
                    sites = contactSites.filter { it.parentId == contact.id }
                )
                Divider(
                    //modifier = Modifier.padding(),
                    color = Color.Gray,
                    thickness = 0.5.dp
                )
            }
        }
    }
}

@Composable
fun EditContactsDialog(
    showDialog: Boolean,
    contactList: List<Contact>,
    onDelete: () -> Unit,
    onExportList: () -> Unit,
    onImportList: () -> Unit
) {
    if (showDialog) {
        Box(
            modifier = Modifier
                .width(300.dp)
                .padding(16.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp))
            //.shadow(1.dp, RoundedCornerShape(8.dp))
        ) {
            Column() {
                Text(
                    text = "${contactList.size} selected",
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    Text(
                        text = stringResource(Res.string.delete),
                        fontSize = 14.sp,
                        color = Color.Red,
                        modifier = Modifier.clickable {
                            onDelete()
                        })
//                    Text(
//                        text = stringResource(Res.string.export_list),
//                        fontSize = 14.sp,
//                        color = BlueApp,
//                        modifier = Modifier.clickable {
//                            onExportList()
//                        })
//                    Text(
//                        text = stringResource(Res.string.import_list),
//                        fontSize = 14.sp,
//                        color = BlueApp,
//                        modifier = Modifier.clickable {
//                            onImportList()
//                        })
                }
            }
        }
    }
}

@Composable
fun DetailsTextItem(header: String, detailsText: String, color: Color = ContactDetailsText) {
    Column {
        Text(
            text = header,
            color = EmployeeDetailsText,
            fontWeight = FontWeight.W400,
            fontSize = 14.sp,
            modifier = Modifier.padding(start = 16.dp, end = 16.dp)

        )
        Text(
            text = detailsText,
            color = color,
            fontWeight = FontWeight.W500,
            fontSize = 14.sp,
            modifier = Modifier.padding(start = 16.dp, end = 16.dp)
        )
        Spacer(modifier = Modifier.height(12.dp))
    }
}

@Composable
fun SitesList(
    sites: SnapshotStateList<Site>,
    onEditSiteClick: (String?) -> Unit
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize().padding(start = 16.dp, end = 16.dp)
    ) {
        if (sites.isNotEmpty()) {
            items(sites.takeIf { it.isNotEmpty() } ?: emptyList()) { site ->
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(top = 15.dp)
                        .border(1.dp, Color.LightGray, RoundedCornerShape(8.dp))
                ) {
                    Column(modifier = Modifier.padding(12.dp)) {
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(top = 4.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Text(
                                text = site.siteName ?: "",
                                fontSize = 16.sp,
                                fontWeight = FontWeight.Bold,
                                color = BlueApp
                            )
                            Spacer(modifier = Modifier.width(15.dp))

                            Text(
                                text = site.personName ?: "",
                                fontSize = 14.sp,
                                color = ContactDetailsText
                            )
                            Spacer(modifier = Modifier.weight(1f))
                            Image(
                                painter = painterResource(Res.drawable.edit),
                                contentDescription = null,
                                modifier = Modifier.clickable {
                                    onEditSiteClick(site.id)
                                }
                            )
                        }
                        Text(
                            text = site.addressObject?.address ?: "",
                            fontSize = 14.sp,
                            color = ContactDetailsText
                        )
                        Text(
                            text = site.personEmail ?: "",
                            fontSize = 14.sp,
                            color = ContactDetailsText
                        )
                        Text(
                            text = site.personPhone ?: "",
                            fontSize = 14.sp,
                            color = ContactDetailsText
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun ContactList(
    contacts: List<Contact>,
    navHostController: NavHostController,
    searchQuery: MutableState<String>
) {

    val filteredContactsSearch = if (searchQuery.value.isNotBlank()) {
        contacts.filter { contact ->
            contact.name?.contains(searchQuery.value, ignoreCase = true) == true ||
                    contact.personName?.contains(
                        searchQuery.value,
                        ignoreCase = true
                    ) == true
        }
    } else {
        contacts
    }.sortedByDescending { it.createdAt }

    LazyColumn(
        modifier = Modifier.fillMaxSize(),
        contentPadding = PaddingValues(vertical = 16.dp)
    ) {
        items(filteredContactsSearch) { rowItem ->
            ContactListRow(
                navHostController = navHostController,
                rowItem = rowItem
            )
        }
    }
}


@Composable
fun ContactListRow(
    navHostController: NavHostController,
    rowItem: Contact,
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dp(58f))
            .padding(horizontal = Dp(22f))
            .clip(MaterialTheme.shapes.medium),
        verticalArrangement = Arrangement.Center
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(horizontal = Dp(24f)).clickable {
                navHostController.navigate(Screen.ContactDetails.route + "?contactId=${rowItem.id}")
            }
        ) {

            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start,
                modifier = Modifier.weight(0.9f)
            ) {
                Checkbox(
                    checked = false,
                    onCheckedChange = { }
                )
                Column(
                    modifier = Modifier
                        .weight(0.9f)
                ) {
                    Text(
                        text = rowItem.name ?: " ",
                        color = Color(0xFF34618E),
                        fontSize = Sp(16f),
                        fontWeight = FontWeight.Bold,

                        )
                    Text(
                        text = rowItem.contactType ?: " ",
                        color = Color(0xFF323232),
                        fontSize = Sp(16f),
                    )
                }
            }
            Text(
                text = rowItem.email ?: " ",
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.9f)
            )
            Text(
                text = rowItem.addressObject?.address ?: " ",
                color = Color(0xFF555555),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(1.5f)
            )
            Text(
                text = rowItem.phone ?: "",
                color = Color(0xFF555555),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.8f)
            )
            Text(
                text = rowItem.industry ?: "",
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.8f)
            )
            Text(
                text = rowItem.personName ?: "",
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.3f)
            )
        }
        Divider(
            modifier = Modifier.fillMaxWidth().height(1.dp)
                .background(Color.LightGray.copy(alpha = 0.1f))
        )
    }
}


@Composable
fun ContactDetailsRow(
    painterResource: DrawableResource,
    labelText: String,
    detailsText: String,
    modifier: Modifier = Modifier,
    detailsTextColor: Color = Color.Black,
    textColor: Color = EmployeeDetailsText,
) {
    Row(
        modifier = modifier
            .padding(Dp(15f)),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.weight(1f)
        ) {
            Image(
                painter = painterResource(painterResource),
                contentDescription = null
            )
            Text(
                text = labelText,
                color = textColor,
                fontSize = Sp(14f),
                modifier = Modifier.padding(start = Dp(20f))
            )
        }
        Text(
            text = detailsText,
            color = detailsTextColor,
            fontWeight = FontWeight.Bold,
            fontSize = Sp(14f),
            modifier = Modifier.padding(start = Dp(20f))
        )
    }
}

@Composable
fun SiteListWeb(
    sites: List<Site>,
    navHostController: NavHostController,
    onEditSiteClick: (String?) -> Unit
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize(),
        contentPadding = PaddingValues(vertical = 16.dp)
    ) {
        items(sites) { rowItem ->
            SiteListRow(
                rowItem = rowItem,
                onEditSiteClick = { onEditSiteClick(rowItem.id) }
            )
        }
    }
}


@Composable
fun SiteListRow(
    rowItem: Site,
    onEditSiteClick: (String?) -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dp(58f))
            .padding(horizontal = Dp(22f))
            .clip(MaterialTheme.shapes.medium),
        verticalArrangement = Arrangement.Center
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(horizontal = Dp(24f)).clickable {
            }
        ) {

            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start,
                modifier = Modifier.weight(0.9f)
            ) {
                Checkbox(
                    checked = false,
                    onCheckedChange = { }
                )
                Column(
                    modifier = Modifier
                        .weight(0.9f)
                ) {
                    Text(
                        text = rowItem.siteName ?: " ",
                        color = Color(0xFF34618E),
                        fontSize = Sp(16f),
                        fontWeight = FontWeight.Bold,
                    )
                    Text(
                        text = rowItem.personName ?: " ",
                        color = Color(0xFF323232),
                        fontSize = Sp(16f),
                    )
                }
            }
            Text(
                text = rowItem.addressObject?.address ?: " ",
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(1.5f)
            )
            Row(
                modifier = Modifier
                    .weight(1.5f),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Column(
                    modifier = Modifier.weight(1f)
                ) {
                    Text(
                        text = rowItem.personPhone ?: " ",
                        color = Color(0xFF323232),
                        fontSize = Sp(14f),

                        )
                    Text(
                        text = rowItem.personEmail ?: " ",
                        color = Color(0xFF555555),
                        fontSize = Sp(14f),
                        modifier = Modifier
                            .weight(1.5f)
                    )
                }
                Image(
                    painter = painterResource(Res.drawable.edit),
                    contentDescription = "",
                    modifier = Modifier.padding(
                        start = 30.dp,
                        top = 10.dp
                    ).clickable {
                        onEditSiteClick(rowItem.id)
                    }.weight(1f)
                )
            }
        }
        Divider(
            modifier = Modifier.fillMaxWidth().height(1.dp)
                .background(Color.LightGray.copy(alpha = 0.1f))
        )
    }
}
