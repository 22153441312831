package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RegisterRequest(
    @SerialName("fullName") val fullName: String,
    @SerialName("email") val email: String,
    @SerialName("password")val password: String
)