package com.hyperether.goodjob.util

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import com.hyperether.goodjob.models.UserStatus
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.user_status_active
import com.hyperether.goodjob.resources.user_status_inactive
import com.hyperether.goodjob.resources.user_status_pending
import com.hyperether.goodjob.theme.GreenActiveBorder
import com.hyperether.goodjob.theme.RedInactive
import com.hyperether.goodjob.theme.YellowPending
import com.hyperether.goodjob.theme.YellowUserBg
import org.jetbrains.compose.resources.stringResource

class UserStatusHelper {
    companion object {
        @Composable
        private fun getMappedStatus(statusText: String?): String {
            val userStatusMap = getUserStatusMap()
            return userStatusMap.entries.find { it.value == statusText }?.key ?: stringResource(Res.string.user_status_active)
        }

        private fun getStatusColor(status: String): Color {
            return when (status) {
                UserStatus.pending.name -> YellowPending
                UserStatus.active.name -> GreenActiveBorder
                UserStatus.inactive.name -> RedInactive
                else -> Color.Gray
            }
        }
        @Composable
        fun getStatusText(confirmStatus: String?, status: String?): String {
            return if (confirmStatus.equals(UserStatus.pending.name, ignoreCase = true)) {
                getMappedStatus(confirmStatus)
            } else {
                getMappedStatus(status)
            }
        }

        @Composable
        fun getStatusColor(confirmStatus: String?, status: String?): Color {
            return if (confirmStatus.equals(UserStatus.pending.name, ignoreCase = true)) {
                getStatusColor(getMappedStatus(confirmStatus))
            } else {
                getStatusColor(getMappedStatus(status))
            }
        }

        @Composable
        fun getStatusColorBasedOnConfirmStatus(confirmStatus: String?): Color {
            return if (confirmStatus.equals(UserStatus.pending.name, ignoreCase = true)) {
                YellowUserBg
            } else {
                Color.Transparent
            }
        }
    }
}

@Composable
fun getUserStatusMap(): Map<String, String> {
    return mapOf(
        stringResource(resource = Res.string.user_status_inactive) to UserStatus.inactive.name,
        stringResource(resource = Res.string.user_status_active) to UserStatus.active.name,
        stringResource(resource = Res.string.user_status_pending) to UserStatus.pending.name,
    )
}