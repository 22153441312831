package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Text
import androidx.compose.material.TextFieldDefaults
import androidx.compose.material3.Checkbox
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.em
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.all_contacts
import com.hyperether.goodjob.resources.contact_name_and_type
import com.hyperether.goodjob.resources.contacts
import com.hyperether.goodjob.resources.email_capital
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.ic_search
import com.hyperether.goodjob.resources.industry
import com.hyperether.goodjob.resources.phone_number
import com.hyperether.goodjob.resources.search
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.scenes.addNew.DropdownSortBy
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.RegisterBorder
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ContactsWeb(
    navController: NavHostController,
    viewModel: ContactsViewModel
) {

    val searchQuery = remember { mutableStateOf("") }

    LaunchedEffect(Unit) {
        viewModel.init()
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
    ) {
        WebViewHeader(
            isAddNewJob = false,
            isBackAvailable = false,
            isAddNewContact = true,
            title = stringResource(Res.string.contacts),
            isImportAndAddVisible = true,
            navHostController = navController
        )
        Spacer(modifier = Modifier.height(Dp(15f)))
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(color = Color(0xfffcfcfc))
                .padding(start = Dp(75f), end = Dp(75f), top = Dp(15f))
        ) {
            Box(
                modifier = Modifier
                    .align(alignment = Alignment.TopCenter)
                    .fillMaxWidth()
                    .requiredHeight(height = Dp(773f))
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .requiredHeight(height = Dp(789f))
                        .background(color = Color.White)
                        .border(width = Dp(1f), color = RegisterBorder)
                ) {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .requiredHeight(height = Dp(110f))
                    ) {
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .requiredHeight(height = Dp(110f))
                                .clip(
                                    shape = RoundedCornerShape(
                                        topStart = Dp(4f),
                                        topEnd = Dp(4f)
                                    )
                                )
                                .background(color = Color.White)
                        )
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier
                                .height(Dp(110f))
                                .drawBehind {
                                    val strokeWidth = Dp(1f).toPx()
                                    drawLine(
                                        color = RegisterBorder,
                                        start = Offset(0f, size.height - strokeWidth / 2),
                                        end = Offset(size.width, size.height - strokeWidth / 2),
                                        strokeWidth = strokeWidth
                                    )
                                }
                                .padding(horizontal = Dp(23f))
                        ) {
                            Text(
                                modifier = Modifier.weight(2f),
                                text = stringResource(Res.string.all_contacts),
                                fontSize = Sp(24f),
                                fontWeight = FontWeight.W700,
                            )

                            OutlinedTextField(
                                textStyle = TextStyle(fontSize = Sp(12f)),
                                value = searchQuery.value,
                                onValueChange = { searchQuery.value = it },
                                placeholder = {
                                    Text(
                                        text = stringResource(Res.string.search),
                                        fontSize = Sp(12f),
                                        fontWeight = FontWeight.W400
                                    )
                                },
                                modifier = Modifier
                                    .width(Dp(226f))
                                    .height(Dp(53f))
                                    .border(
                                        width = Dp(1f),
                                        color = BorderColor,
                                        shape = RoundedCornerShape(Dp(8f))
                                    ),
                                singleLine = true,
                                leadingIcon = {
                                    Image(
                                        modifier = Modifier
                                            .height(Dp(24f))
                                            .width(Dp(24f)),
                                        painter = painterResource(Res.drawable.ic_search),
                                        contentDescription = "Search"
                                    )
                                },
                                colors = TextFieldDefaults.textFieldColors(
                                    backgroundColor = Color.White,
                                    focusedIndicatorColor = Color.Transparent,
                                    unfocusedIndicatorColor = Color.Transparent
                                )
                            )
                            Spacer(modifier = Modifier.width(Dp(16f)))

                            Row(
                                modifier = Modifier
                                    .height(Dp(55f)).width(Dp(100f))
                                    .border(
                                        width = Dp(1f),
                                        color = BorderColor,
                                        shape = RoundedCornerShape(Dp(8f))
                                    )
                                    .clickable { viewModel.showFilterDialog.value = true },
                                horizontalArrangement = Arrangement.Center,
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    text = stringResource(Res.string.filter),
                                    fontSize = Sp(14f),
                                    fontWeight = FontWeight.W400,
                                )
                                Image(
                                    painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                                    contentDescription = "Filter",
                                    modifier = Modifier.width(Dp(24f)).height(Dp(24f))
                                )
                            }
                            Spacer(modifier = Modifier.width(Dp(16f)))

                            // TODO: get options
                            DropdownSortBy(
                                shouldAppendSortBy = false,
                                items = listOf("Newest"),
                                selectedValue =  "Newest",
                                onValueSelected = {  },
                                displayText = { "Sort by: $it" },
                                placeholderColor = BorderColor,
                                textColor = Color.Black,
                                modifier = Modifier.height(Dp(55f))
                            )
                        }
                    }
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = Dp(45f))
                            .requiredHeight(height = Dp(38f)),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Start,
                            modifier = Modifier.weight(0.9f)
                        ) {
                            Checkbox(
                                checked = false,
                                onCheckedChange = {  }
                            )

                            Text(
                                text = stringResource(Res.string.contact_name_and_type),
                                color = Color(0xff797676),
                                lineHeight = 1.5.em,
                                style = TextStyle(fontSize = Sp(14f)),
                            )
                        }
                        Text(
                            text = stringResource(Res.string.email_capital),
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.9f)
                        )
                        Text(
                            text = stringResource(Res.string.address),
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(1.5f)
                        )
                        Text(
                            text = stringResource(Res.string.phone_number),
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.7f)
                        )
                        Text(
                            text = stringResource(Res.string.industry),
                            color = Color(0xff797676),
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.7f)
                        )
                        Text(
                            text = stringResource(Res.string.site),
                            color = Color(0xff797676),
                            textAlign = TextAlign.Center,
                            lineHeight = 1.5.em,
                            style = TextStyle(
                                fontSize = Sp(14f)
                            ),
                            modifier = Modifier
                                .weight(0.5f)
                        )
                    }
                    Divider(modifier = Modifier.height(1.dp).background(Color.LightGray.copy(alpha = 0.1f)))
                    ContactList(viewModel.contactList, navHostController = navController, searchQuery)
                }
            }
        }
    }

}