package com.hyperether.goodjob.scenes.components

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.ime
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.relocation.BringIntoViewRequester
import androidx.compose.foundation.relocation.bringIntoViewRequester
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.repository.remote.model.Place
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.password_not_visible
import com.hyperether.goodjob.resources.password_visible
import com.hyperether.goodjob.theme.InputIconColor
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource

@Composable
fun PasswordInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        var passVisible by remember { mutableStateOf(false) }
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text(placeholder, fontSize = 14.sp, color = TextColor)
            },
            visualTransformation = if (passVisible) VisualTransformation.None else PasswordVisualTransformation(),
            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Password),
            trailingIcon = {
                val image =
                    if (passVisible) Res.drawable.password_visible else Res.drawable.password_not_visible
                IconButton(onClick = { passVisible = !passVisible }) {
                    Icon(
                        painter = painterResource(image),
                        "Show/Hide password",
                        tint = InputIconColor
                    )
                }
            }
        )

    }
}

@Composable
fun PhoneInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier,
    paddingTop: Dp = 10.dp
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = { newValue ->
                if (newValue.length <= 15 && (newValue.isEmpty() || newValue.all { it.isDigit() } ||
                            (newValue.startsWith("+") && newValue.drop(1).all { it.isDigit() }))) {
                    text.value = newValue
                }
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text(placeholder, fontSize = 14.sp, color = TextColor)
            },
            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Phone)
        )
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun AddressInputField(
    textCity: MutableState<String>? = null,
    textCountry: MutableState<String>? = null,
    paddingTop: Dp = 10.dp,
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier,
    places: SnapshotStateList<Place>,
    onSearch: (String) -> Unit,
    onSelect: (Place) -> Unit
) {
    var isTyping by remember { mutableStateOf(false) }

    val bringIntoViewRequester = remember { BringIntoViewRequester() }
    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(places.size) {
        if (places.isNotEmpty()) {
            coroutineScope.launch {
                bringIntoViewRequester.bringIntoView()
            }
        }
    }

    LaunchedEffect(text.value) {
        if (isTyping) {
            var textToSearch = text.value
            if (textCity != null) {
                textToSearch = textToSearch.plus(", ${textCity.value}")
            }
            if (textCountry != null) {
                textToSearch = textToSearch.plus(", ${textCountry.value}")
            }
            isTyping = false
            delay(500) // Wait for user to stop typing
            onSearch(textToSearch) // Call API after delay
        }
    }

    Column(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = paddingTop)
    ) {

        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
                isTyping = true
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text(placeholder, fontSize = 14.sp, color = TextColor)
            }
        )

        if (places.isNotEmpty()) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(Color.White)
                    .bringIntoViewRequester(bringIntoViewRequester)
                    .border(1.dp, Color.Gray, RoundedCornerShape(4.dp)).padding(10.dp)
            ) {
                Text(places[0].formattedAddress, modifier = Modifier.clickable {
                    onSelect(places[0])
                    places.clear()
                })
            }
        }

    }
}