package com.hyperether.goodjob.scenes.scheduler.components

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.scenes.components.CircleWithInitial
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.stringResource

@Composable
fun SchedulerMonthJobCard(
    status: String,
    range: String,
    title: String,
    site: String,
    employee: String
) {
    Box(
        modifier = Modifier.fillMaxWidth()
            .padding(vertical = 10.dp, horizontal = 20.dp)
    ) {

        Row(
            modifier = Modifier.fillMaxWidth()
                .background(Color.White, shape = RoundedCornerShape(12.dp))
                .border(
                    1.dp,
                    Color.Gray,
                    shape = RoundedCornerShape(12.dp)
                )
                .padding(12.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {

            Column(modifier = Modifier.weight(1f)) {
                Row(
                ) {
                    Column(
                        modifier = Modifier.weight(1f)
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically
                        ) {

                            Box(
                                modifier = Modifier
                                    .size(16.dp)
                                    .background(color = status.let {
                                        StatusColor().getStatusColor(
                                            it
                                        )
                                    } ?: Green, shape = CircleShape)
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            Text(
                                text = range,
                                fontSize = 14.sp,
                                color = Color.Gray,
                                maxLines = 1,
                                overflow = TextOverflow.Ellipsis
                            )
                        }

                        Text(
                            text = title,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis
                        )
                        Text(
                            text = "${stringResource(Res.string.site)}: ${site}",
                            fontSize = 14.sp,
                            color = Color.Gray,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis
                        )
                    }
                }
            }

            CircleWithInitial(employee, size = 40.dp)
        }
    }
}