package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SetPasswordRequest(
    @SerialName("password") var password: String? = null,
    @SerialName("confirm_password") var confirm_password: String? = null,
    @SerialName("hash") var hash: String? = null
)