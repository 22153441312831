package com.hyperether.goodjob.repository

import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Employee
import com.hyperether.goodjob.models.EmployeeRequest
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.JobRequest
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.SiteRequest
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import com.hyperether.goodjob.repository.remote.model.AddPlanResponse
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOf
import org.lighthousegames.logging.logging

class WebRepositoryImpl(
    private val prefs: PrefsManager,
    private val goodJobApiImpl: GoodJobApiImpl
) : Repository {
    val log = logging("WebRepositoryImpl")

    //TODO Use for web, dont use database here, we will show data only from API

    override suspend fun getTestData(): String {
        TODO("Not yet implemented")
    }

    override suspend fun deleteAllWorkspaces() {
        TODO("Not yet implemented")
    }

    override suspend fun insertWorkspace(workspace: Workspace) {

    }

    override fun getWorkspaces(): Flow<List<Workspace>> {
        return flow {
            val resource = getAllWorkspaces()
            if (resource is Resource.Success && resource.data != null) {
                emit(resource.data)
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            emit(emptyList())
        }
    }

    override suspend fun getWorkspaceById(id: String): Workspace? {
        TODO("Not yet implemented")
    }

    override suspend fun deleteWorkspaceById(id: Long) {
        TODO("Not yet implemented")
    }

    override suspend fun addWorkspace(name: String, workspaceType: String): Resource<Workspace> {
        return goodJobApiImpl.addWorkspace(name, workspaceType, getToken())
    }

    override suspend fun getAllWorkspaces(): Resource<List<Workspace>> {
        return goodJobApiImpl.getAllWorkspaces(getToken())
    }

    override suspend fun getAllPlans(): Resource<List<Plan>> {
        return goodJobApiImpl.getAllPlans(getToken())
    }

    override suspend fun addPlan(planId: String): Resource<AddPlanResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.addPlan(getToken(), planId)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("addPlan") { "addPlan SUCCESS ${data.user.planId}" }
                        val currentUser = prefs.getUser()
                        currentUser?.planId = data.user.planId
                        currentUser?.let { saveUser(it) }
                        Resource.Success(data)
                    } else {
                        log.d("addPlan") { "addPlan ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "addPlan An error occurred" }
                    Resource.Error(apiResponse.text?.toString() ?: "An error occurred")
                }
            }
        } catch (e: Exception) {
            log.e { "addPlan Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override fun saveUser(user: User) {
        prefs.saveUser(user)
    }

    override fun getUser(): User? {
        return prefs.getUser()
    }

    override suspend fun insertPlan(plan: Plan) {

    }

    override fun getPlans(): Flow<List<Plan>> {
        return flow {
            val resource = getAllPlans()
            if (resource is Resource.Success && resource.data != null) {
                emit(resource.data)
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            emit(emptyList())
        }
    }

    override suspend fun getPlanById(id: String): Plan? {
        TODO("Not yet implemented")
    }

    override suspend fun deletePlanById(id: String) {
        TODO("Not yet implemented")
    }

    override suspend fun insertUser(user: User) {

    }

    override fun getUsers(): Flow<List<com.hyperether.goodjob.database.User>> {
        // TODO
        return flowOf(emptyList())
    }

    override suspend fun deleteUsersByIds(ids: List<Long>) {
        TODO("Not yet implemented")
    }

    override suspend fun deleteEmployee(employeeId: String): Resource<String> {
        return goodJobApiImpl.deleteEmployee(getToken(), employeeId)
    }

    override suspend fun getEmployeeById(userId: String): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.getEmployeeById(getToken(), userId)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("getEmployeeById") { "getEmployeeById SUCCESS $user" }
                    Resource.Success(user)
                }

                is Resource.Error -> {
                    log.d("getEmployeeById") { "getEmployeeById ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("getEmployeeById") { "getEmployeeById EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun addEmployee(employeeRequest: EmployeeRequest): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.addEmployee(getToken(), employeeRequest)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("addEmployee") { "addEmployee SUCCESS $user" }
                    user?.let { insertUser(it) }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    log.d("addEmployee") { "addEmployee ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("addEmployee") { "addEmployee EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllEmployees(): Resource<List<User>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllEmployees(getToken())

            when (apiResponse) {
                is Resource.Success -> {
                    val employeesResponse = apiResponse.data
                    if (employeesResponse?.isNotEmpty() == true) {

                        employeesResponse.forEach { employee ->
                            try {
                                val remoteId = employee._id
                                if (remoteId.isNullOrBlank()) {
                                    log.d("Users Sync Error") { "Skipping contact with null or blank ID: ${employee.fullName}" }
                                    return@forEach
                                }
                            } catch (e: Exception) {
                                log.d("Users Sync Error") {
                                    "Failed to sync users: ${employee._id}, error: ${e.message}"
                                }
                            }
                        }
                    }
                    Resource.Success(employeesResponse)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override fun getContactsFromLocal(): Flow<List<Contact>> {
        return flowOf(emptyList())
    }

    override suspend fun getAllContacts(): Resource<List<com.hyperether.goodjob.models.Contact>> {
        //  TODO("Not yet implemented")
        return Resource.Success(emptyList())
    }

    override suspend fun addContact(contact: com.hyperether.goodjob.models.Contact): Resource<com.hyperether.goodjob.models.Contact> {
        TODO("Not yet implemented")
    }

    override suspend fun insertEmployee(employee: Employee) {

    }

    override fun getEmployeesFromLocal(): Flow<List<com.hyperether.goodjob.database.Employee>> {
        // TODO
        return flowOf(emptyList())
    }

    override suspend fun deleteEmployeesByIds(ids: List<Long>) {
        TODO("Not yet implemented")
    }

    override suspend fun insertJob(job: Job) {

    }

    override fun getJobs(): Flow<List<com.hyperether.goodjob.database.Job>> {
        // TODO("Not yet implemented")
        return flowOf(emptyList())
    }

    override suspend fun getSitesUnderContact(siteRequest: SiteRequest): Resource<List<Site>> {
        // TODO("Not yet implemented")
        return Resource.Success(emptyList())

    }

    override suspend fun createJob(job: JobRequest): Resource<Job> {
        TODO("Not yet implemented")
    }

    override suspend fun getAllJobsForWorkspace(): Resource<List<Job>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllJobsForSpecificWorkspace(getToken(), "67372737d744fafc112ffe92")

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    if (response?.isNotEmpty() == true) {
                        response.forEach { job ->
                            try {
                                val remoteId = job.id
                                if (remoteId.isNullOrBlank()) {
                                    log.d("Contact Sync Error") { "Skipping contact with null or blank ID: ${job.jobTitle}" }
                                    return@forEach
                                }
                            } catch (e: Exception) {
                                log.d("Contact Sync Error") {
                                    "Failed to sync contact: ${job.id}, error: ${e.message}"
                                }
                            }
                        }
                    }
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun deleteJobById(id: String): Resource<String> {
        TODO("Not yet implemented")
    }

    //LOCAL STORAGE
    // TODO for token we should save it in cookie for web, it is safer
    override fun saveToken(token: String) {
        log.d("saveToken") { "saveToken" }
        prefs.saveToken(token)
    }

    override fun getToken() = prefs.getToken()

    override fun saveRefreshToken(refreshToken: String) {
        prefs.saveRefreshToken(refreshToken)
    }

    override fun getRefreshToken(): String {
        return prefs.getRefreshToken()
    }

    override fun saveEmail(email: String) {
        prefs.saveEmail(email)
    }

    override fun getEmail(): String {
        return prefs.getEmail()
    }

    override fun savePassword(password: String) {
        prefs.savePassword(password)
    }

    override fun getPassword(): String {
        return prefs.getPassword()
    }

    override fun saveWorkspaceId(workspaceId: String) {
        prefs.saveWorkspaceId(workspaceId)
    }

    override fun getWorkspaceId(): String {
        return prefs.getWorkspaceId()
    }

    override suspend fun register(
        name: String,
        email: String,
        password: String
    ): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.register(name, email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("register") { "Register SUCCESS ${data.email}" }
                        saveUser(data)
                        Resource.Success(data)
                    } else {
                        log.d("register") { "Register ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.d("register") { "Register An error occurred" }
                    Resource.Error(apiResponse.text?.toString() ?: "An error occurred")
                }
            }
        } catch (e: Exception) {
            log.d("register") { "Register Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun login(email: String, password: String): Resource<LoginResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.login(email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("login") { "Login SUCCESS ${data.email}" }
                        saveToken(data.token)
                        saveRefreshToken(data.refreshToken)
                        saveUser(Mapper().loginResponseToUser(data))
                        Resource.Success(data)
                    } else {
                        log.d("login") { "Login ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "Login ERROR An error occurred" }
                    Resource.Error(apiResponse.text?.toString() ?: "An error occurred")
                }
            }
        } catch (e: Exception) {
            log.e { "Login Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }
}