package com.hyperether.goodjob.mapper

import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.database.Workspace
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.serializer

class Mapper {

    fun toWorkspace(workspace: Workspace): com.hyperether.goodjob.models.Workspace {
        return com.hyperether.goodjob.models.Workspace(
            _id = workspace._id,
            id = workspace.id,
            name = workspace.name,
            planId = workspace.plan,
            type = workspace.type,
            userId = workspace.user_id
        )
    }

    fun toWorkspaceEntity(workspace: com.hyperether.goodjob.models.Workspace): Workspace {
        return Workspace(
            _id = workspace._id,
            id = workspace.id ?: 0,
            name = workspace.name,
            plan = workspace.planId,
            type = workspace.type,
            user_id = workspace.userId,
            workspaceType = workspace.workspaceType
        )
    }

    fun toContactModel(contact: Contact): com.hyperether.goodjob.models.Contact {
        return com.hyperether.goodjob.models.Contact (
            id = contact._id,
            name = contact.name,
            contactType = contact.contactType,
            type = contact.type,
            industry = contact.industry,
            address = contact.address,
            email = contact.email,
            city = contact.city,
            zip = contact.zip?.toInt(),
            country = contact.country,
            phone = contact.phone,
            personName = contact.personName,
            personEmail = contact.personEmail,
            personAddress = contact.personAddress,
            personPhone = contact.personPhone
        )
    }

//    fun toUser(user: User): com.hyperether.goodjob.models.User {
//        return com.hyperether.goodjob.models.User(
//            id = user.id,
//            fullName = user.fullName,
//            email = user.email,
//            password = user.password,
//            role = user.role,
//            workspace_ids = user.workspace_ids?.let { workspaceIdsAsList(it) }
//        )
//    }

    fun mapDbUserToSerializableUser(dbUser: User): com.hyperether.goodjob.models.User {
        val jsonParser = Json { ignoreUnknownKeys = true }

        return com.hyperether.goodjob.models.User(
            _id = dbUser._id,
            fullName = dbUser.fullName,
            email = dbUser.email,
            password = dbUser.password,
            confirmStatus = dbUser.confirmStatus,
            role = dbUser.role,
            hash = null,
            type = null,
            workspaceId = dbUser.workspace_ids,
            planId = dbUser.planId,
            phone = dbUser.phone,
            zip = dbUser.zip,
            country = dbUser.contry,
            city = dbUser.city,
            address = dbUser.address,
            status = dbUser.status,
            skills = dbUser.skills?.split(",")?.filter { it.isNotEmpty() },
            teams = dbUser.teams?.let {
                try {
                    jsonParser.decodeFromString<List<Team>>(it)
                } catch (e: Exception) {
                    emptyList()
                }
            }
        )
    }

    fun loginResponseToUser(loginResponse: LoginResponse): com.hyperether.goodjob.models.User {
        return com.hyperether.goodjob.models.User(
            _id = loginResponse._id,
            fullName = loginResponse.fullName,
            email = loginResponse.email,
            confirmStatus = loginResponse.confirmStatus,
            role = loginResponse.role,
            hash = loginResponse.hash,
            type = loginResponse.type
        )
    }

    fun toPlan(plan: com.hyperether.goodjob.database.Plan): Plan {
        return Plan(
            id = plan.id,
            _id = plan._id,
            name = plan.name,
            price = plan.price,
            description = plan.description,
            storage = plan.storage,
            planSupport = plan.planSupport,
            limits = plan.limits,
            isPopular = if (plan.isPopular == 1L) true else false
        )
    }

    fun workspaceIdsAsJson(workspace_ids: List<String>?): String? = workspace_ids?.let {
        Json.encodeToString(ListSerializer(String.serializer()), it)
    }

    fun workspaceIdsAsList(workspace_ids: String): List<String> {
        return Json.decodeFromString<List<String>>(workspace_ids)
    }


    inline fun <reified T> listAsJson(list: List<T>?): String? = list?.let {
        Json.encodeToString(ListSerializer(serializer()), it)
    }

    inline fun <reified T> jsonAsList(json: String): List<T> {
        return Json.decodeFromString(ListSerializer(serializer()), json)
    }

    fun listAsJsonSteps(steps: List<Step?>): String {
        return Json.encodeToString(steps)
    }

    fun jsonToListSteps(jsonString: String): List<Step> {
        return Json.decodeFromString(jsonString)
    }

    fun listAsJsonEmployees(users: List<com.hyperether.goodjob.models.User?>): String {
        return Json.encodeToString(users)
    }

    fun jsonToListEmployees(jsonString: String): List<com.hyperether.goodjob.models.User> {
        return Json.decodeFromString(jsonString)
    }


    fun extractValues(json: String): List<String> {
        return try {
            val teams: List<Team> = Json.decodeFromString(json)
            teams.mapNotNull { it.value }
        } catch (e: Exception) {
            emptyList()
        }
    }
}