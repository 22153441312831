package com.hyperether.goodjob.scenes.jobs

import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.AssigneesRequest
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Note
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.are_you_sure
import com.hyperether.goodjob.resources.are_you_sure_note
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.confirmation
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.file_deleted
import com.hyperether.goodjob.resources.job_finished
import com.hyperether.goodjob.resources.job_rejected
import com.hyperether.goodjob.resources.note_added_successfully
import com.hyperether.goodjob.resources.note_updated_successfully
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.util.DateTimeUtil
import kotlinx.datetime.Clock
import org.jetbrains.compose.resources.stringResource
import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

@OptIn(ExperimentalUuidApi::class)
@Composable
fun JobPopups(
    jobId: String,
    job: Job?,
    viewModel: JobDetailsViewModel,
    navHostController: NavHostController
) {
    if (viewModel.showAreYouSurePopUpDeleteNote.value) {
        PopUpDialog(
            onDismiss = { viewModel.showAreYouSurePopUpDeleteNote.value = false },
            title = stringResource(Res.string.confirmation),
            message = stringResource(Res.string.are_you_sure_note),
            onPositiveBtnClicked = {
                val assignees = job?.assignees
                val assigneesRequest = AssigneesRequest(
                    employees = assignees?.employees?.map { it?._id }?.distinct(),
                    teams = assignees?.teams?.map { it?._id }?.distinct(),
                )
                val jobModel =
                    job?.let {
                        JobRequest(
                            jobTitle = job?.jobTitle,
                            status = job?.status,
                            assignees = assigneesRequest,
                            creatorId = job?.contactSiteId,
                            workspaceId = job?.workspaceId,
                            start_at = job?.start_at,
                            end_at = job?.end_at,
                            repeat = job?.repeat,
                            dueDate = job?.dueDate,
                            jobDuration = job?.jobDuration,
                            steps = job?.steps ?: emptyList(),
                            userId = job?.userId,
                            contactSiteId = job?.contactSiteId,
                            contactParentId = job?.contactParentId,
                            contactSiteName = job?.contactSiteName,
                            notes = viewModel.noteInput.value,
                            fileId = job?.fileId,
                            fileObjects = job?.fileObjects,
                            noteObjects = job?.noteObjects?.filter { it != viewModel.noteToEditOrDelete.value }
                        )
                    }
                if (jobModel != null) {
                    job?.id?.let {
                        viewModel.updateJob(it, jobModel, onSuccess = {
                            viewModel.showAreYouSurePopUpDeleteNote.value = false
                            viewModel.showLoader.value = false
                        },
                            onError = {
                                viewModel.showAreYouSurePopUpDeleteNote.value = false
                                viewModel.showLoader.value = false
                                viewModel.showPopUpError.value = false
                            })
                    }
                }
            },
            onNegativeBtnClicked = {
                viewModel.showAreYouSurePopUpDeleteNote.value = false
            },
            positiveBtnText = stringResource(Res.string.delete),
            negativeBtnText = stringResource(Res.string.cancel)
        )
    }

    if (viewModel.showAreYouSurePopUpDeleteImage.value) {
        PopUpDialog(
            onDismiss = { viewModel.showAreYouSurePopUpDeleteImage.value = false },
            title = stringResource(Res.string.confirmation),
            message = stringResource(Res.string.are_you_sure),
            onPositiveBtnClicked = {
                viewModel.selectedJob.value?.id?.let { jobId ->
                    viewModel.selectedImageName.value?.let { imageName ->
                        viewModel.deleteImage(jobId, imageName)
                    }
                }
                viewModel.showAreYouSurePopUpDeleteImage.value = false
            },
            onNegativeBtnClicked = {
                viewModel.showAreYouSurePopUpDeleteImage.value = false
            },
            positiveBtnText = stringResource(Res.string.delete),
            negativeBtnText = stringResource(Res.string.cancel)
        )
    }

    if (viewModel.showAreYouSurePopUpDeleteFile.value) {
        PopUpDialog(
            onDismiss = { viewModel.showAreYouSurePopUpDeleteFile.value = false },
            title = stringResource(Res.string.confirmation),
            message = stringResource(Res.string.are_you_sure),
            onPositiveBtnClicked = {
                viewModel.selectedJob.value?.id?.let { jobId ->
                    viewModel.selectedFileName.value?.let { fileName ->
                        viewModel.deleteFileFromJob(jobId, fileName.substringAfterLast("/"))
                    }
                }
                viewModel.showAreYouSurePopUpDeleteFile.value = false
            },
            onNegativeBtnClicked = {
                viewModel.showAreYouSurePopUpDeleteFile.value = false
            },
            positiveBtnText = stringResource(Res.string.delete),
            negativeBtnText = stringResource(Res.string.cancel)
        )
    }
    if (viewModel.showPopUpError.value) {
        ErrorDialog(
            onDismiss = { viewModel.showPopUpError.value = false },
            title = stringResource(Res.string.error).uppercase(),
            message = viewModel.errorText.value
        )
    }
    if (viewModel.showLoader.value) {
        FullScreenProgressBar()
    }
    if (viewModel.showPopUpSuccess.value) {
        PopUpDialog(
            onDismiss = {
                viewModel.isEditSelectedNote.value = false
                viewModel.showPopUpSuccess.value = false
            },
            title = stringResource(Res.string.success).uppercase(),
            message = if (!viewModel.isEditSelectedNote.value) stringResource(Res.string.note_added_successfully)
            else stringResource(Res.string.note_updated_successfully),
            onPositiveBtnClicked = {
                viewModel.isEditSelectedNote.value = false
                viewModel.showPopUpSuccess.value = false
            },
            positiveBtnText = stringResource(Res.string.ok).uppercase()
        )
    }
    if (viewModel.showFileDeleteSuccess.value) {
        PopUpDialog(
            onDismiss = {
                viewModel.showFileDeleteSuccess.value = false
            },
            title = stringResource(Res.string.success).uppercase(),
            message = stringResource(Res.string.file_deleted),
            onPositiveBtnClicked = {
                viewModel.showFileDeleteSuccess.value = false
            },
            positiveBtnText = stringResource(Res.string.ok).uppercase()
        )
    }
    if (viewModel.showFinishedJobSuccess.value) {
        PopUpDialog(
            onDismiss = {
                viewModel.showPopUpSuccess.value = false
                navHostController.popBackStack()
            },
            title = stringResource(Res.string.success).uppercase(),
            message = stringResource(Res.string.job_finished),
            onPositiveBtnClicked = {
                viewModel.showPopUpSuccess.value = false
                navHostController.popBackStack()
            },
            positiveBtnText = stringResource(Res.string.ok).uppercase()
        )
    }
    if (viewModel.showRejectedJobSuccess.value) {
        PopUpDialog(
            onDismiss = {
                viewModel.showPopUpSuccess.value = false
                navHostController.popBackStack()
            },
            title = stringResource(Res.string.success).uppercase(),
            message = stringResource(Res.string.job_rejected),
            onPositiveBtnClicked = {
                viewModel.showPopUpSuccess.value = false
                navHostController.popBackStack()
            },
            positiveBtnText = stringResource(Res.string.ok).uppercase()
        )
    }
    if (viewModel.showNotePopUp.value) {
        NoteInputDialog(
            initialText = viewModel.noteInput,
            onDismiss = {
                viewModel.showNotePopUp.value = false
            },
            onConfirm = {
                viewModel.showLoader.value = true
                val assignees = job?.assignees
                val assigneesRequest = AssigneesRequest(
                    employees = assignees?.employees?.map { it?._id }?.distinct(),
                    teams = assignees?.teams?.map { it?._id }?.distinct(),
                )
                val jobModel =
                    job?.let {
                        JobRequest(
                            jobTitle = job?.jobTitle,
                            status = job?.status,
                            assignees = assigneesRequest,
                            creatorId = job?.contactSiteId,
                            workspaceId = job?.workspaceId,
                            start_at = job?.start_at,
                            end_at = job?.end_at,
                            repeat = job?.repeat,
                            dueDate = job?.dueDate,
                            jobDuration = job?.jobDuration,
                            steps = job?.steps ?: emptyList(),
                            userId = job?.userId,
                            contactSiteId = job?.contactSiteId,
                            contactParentId = job?.contactParentId,
                            contactSiteName = job?.contactSiteName,
                            notes = viewModel.noteInput.value,
                            fileId = job?.fileId,
                            fileObjects = job?.fileObjects,
                            noteObjects = job?.noteObjects?.toMutableList()?.apply {
                                if (!viewModel.isEditSelectedNote.value) {
                                    add(
                                        Note(
                                            _id = Uuid.random().toString(),
                                            noteText = viewModel.noteInput.value,
                                            createdBy = viewModel.getUser()?.getName(),
                                            profileImageFileId = null, // TODO: when we get profile image change this
                                            userId = viewModel.getUser()?._id,
                                            createdAt = DateTimeUtil.formatInstantToCustomFormat(
                                                Clock.System.now()
                                            )
                                        )
                                    )
                                } else {
                                    val selectedNoteIndex =
                                        indexOfFirst { it._id == viewModel.selectedNoteId.value }
                                    if (selectedNoteIndex != -1) {
                                        this[selectedNoteIndex] =
                                            this[selectedNoteIndex].copy(
                                                noteText = viewModel.noteInput.value,
                                                createdBy = viewModel.getUser()
                                                    ?.getName(),
                                                profileImageFileId = null, // TODO: when we get profile image change this
                                                userId = viewModel.getUser()?._id,
                                                createdAt = DateTimeUtil.formatInstantToCustomFormat(
                                                    Clock.System.now()
                                                )
                                            )
                                    }
                                }
                            }
                        )
                    }

                if (jobModel != null) {
                    viewModel.updateJob(jobId, jobModel, onSuccess = {
                        viewModel.noteInput.value = ""
                        viewModel.showNotePopUp.value = false
                        viewModel.showNoteEditDeletePopUpMenu.value = false
                        viewModel.showLoader.value = false
                        viewModel.showPopUpSuccess.value = true
                        viewModel.selectedNoteId.value = null
                        viewModel.loadJobDetails(jobId)
                    },
                        onError = {
                            viewModel.showNotePopUp.value = false
                            viewModel.showNoteEditDeletePopUpMenu.value = false
                            viewModel.showLoader.value = false
                            viewModel.showPopUpError.value = false
                            viewModel.selectedNoteId.value = null
                        })
                }
            }
        )
    }
}