package com.hyperether.goodjob.scenes.register

import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

class RegisterViewModel(
    val repository: Repository,
) : ViewModel() {

    fun register(
        name: String,
        email: String,
        password: String,
        onSuccess: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch(Dispatchers.Default) {

            when (val result =
                repository.register(name, email, password)) {
                is Resource.Success -> {
                    repository.saveEmail(email)
                    repository.savePassword(password)
                    onSuccess()
                }

                is Resource.Error -> {
                    onError(result.text.toString() ?: "An unknown error occurred")
                }

                else -> {
                    onError("Unexpected error occurred")
                }
            }
        }
    }

    suspend fun getAllContacts() {
        val result = repository.getAllContacts()

        when (result) {
            is Resource.Success -> {
                println(result)
            }

            is Resource.Error -> {
                println(result)
            }
        }
    }

    suspend fun getAllUsers() {
        val result = repository.getAllEmployees()

        when (result) {
            is Resource.Success -> {
                println(result)
            }

            is Resource.Error -> {
                println(result)
            }
        }
    }

    fun login(email: String, password: String, onSuccess: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch(Dispatchers.Main) {

            when (val result =
                repository.login(email, password)) {
                is Resource.Success -> {
                    // TODO check for place for this method
                    getAllContacts()
                    getAllUsers()
                    onSuccess()
                }

                is Resource.Error -> {
                    onError(result.text.toString() ?: "An unknown error occurred")
                }

                else -> {
                    onError("Unexpected error occurred")
                }
            }
        }
    }
}